import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import { useCallback, useMemo } from 'react';
import BSCard from 'react-bootstrap/Card';
import BSForm from 'react-bootstrap/Form';
import { BsKey, BsPersonCheck } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import IconButton from '../../components/button/icon-button';
import ConfirmationDialog from '../../components/dialog/confirmation-dialog';
import RbacElement from '../../components/role-based-access-control/role-based-access-control-element';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import { SmartTable } from '../../components/smart-table/smart-table';
import SmartTableButton from '../../components/smart-table/smart-table-button';
import SmartTableFilters from '../../components/smart-table/smart-table-filters/smart-table-filters';
import {
  SmartTableFilterControlTypesEnum,
  SmartTableFilterProps,
} from '../../components/smart-table/smart-table-filters/table-filter.types';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import Title from '../../components/title';
import {
  ApiMultiElementResponse,
  ClassTypesEnum,
  formatBool,
  mapearERemoverElementosNulosERepetidos,
} from '../../helpers';
import { DialogProps } from '../../hooks/dialog/dialog-context';
import { useDialog } from '../../hooks/dialog/use-dialog';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import { loadPapeisUsuarios, selectObjectTodosPapeisUsuarios } from '../papeis-usuarios/papeis-usuarios.redux';
import { selectSessionUsuario } from '../session-usuario/session-usuario.redux';
import AtualizarPapelUsuarioUsuarioDialog from './atualizar-papel-usuario-usuario-dialog';
import AtualizarSenhaUsuarioDialog from './atualizar-senha-usuario-dialog';
import CadastrarUsuarioDialog from './cadastrar-usuario-dialog';
import {
  loadUsuarios,
  selectLoadingStateByFiltersUsuarios,
  selectTotalUsuariosByFilters,
  selectUsuarios,
  updateUsuarioPapelUsuario,
  updateUsuarioSenha,
  updateUsuarioStatus,
} from './usuarios.redux';

const smartFilters = (): SmartTableFilterProps[] => [
  {
    label: 'Nome',
    attribute: 'username',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
  },
  {
    label: 'E-mail',
    attribute: 'email',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
  },
];

const smartColumns = ({
  closeDialog,
  papeisUsuarios,
  onUpdatePapelUsuarioUsuario,
  onUpdateSenhaUsuario,
  onUpdateStatusUsuarioHandler,
  sessionUsuario,
  showDialog,
}: {
  papeisUsuarios: any;
  closeDialog: () => void;
  onUpdatePapelUsuarioUsuario: (usuario: any) => (data: any) => void;
  onUpdateSenhaUsuario: (usuario: any) => (data: any) => void;
  onUpdateStatusUsuarioHandler: (usuarioId: string, data: any) => void;
  sessionUsuario: any;
  showDialog: (dialogProps: DialogProps) => void;
}): SmartTableColumnProps[] => [
  {
    label: 'Nome',
    attribute: 'username',
    className: 'text-center',
    sortable: true,
  },
  {
    label: 'E-mail',
    attribute: 'email',
    className: 'text-center',
    sortable: true,
  },
  {
    label: 'Papel',
    attribute: 'papelUsuario',
    className: 'text-center',
    format: (papelUsuarioId: any) => {
      return (
        <Link onClick={(e) => e.stopPropagation()} to={`/papeis-usuarios/${papelUsuarioId}`}>
          {papeisUsuarios[papelUsuarioId]?.descricao}
        </Link>
      );
    },
  },
  {
    label: 'Ativo',
    className: 'text-center',
    format: (_, usuario) => {
      if (
        !sessionUsuario?.papelUsuario?.permissoes?.ALTERACAO_STATUS_USUARIO ||
        usuario?._id === sessionUsuario?.usuario
      ) {
        return formatBool(usuario?.ativo);
      }

      return (
        <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.ALTERACAO_STATUS_USUARIO}>
          <BSForm.Switch
            checked={usuario.ativo}
            name="ativo"
            type="switch"
            onChange={() => {
              showDialog({
                component: (
                  <ConfirmationDialog
                    onConfirmation={() => onUpdateStatusUsuarioHandler(usuario._id, { ativo: !usuario.ativo })}
                    message={`Tem certeza que deseja alterar o status de ativação do usuário: ${usuario.username}?`}
                    confirmationLabel="Alterar"
                    title="Alterar ativação do usuário"
                    onHide={closeDialog}
                  />
                ),
              });
            }}
          />
        </RbacElement>
      );
    },
  },
  {
    label: 'Ações',
    className: 'text-center',
    format: (_, usuario) => {
      return (
        <div className="d-flex justify-content-center">
          <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.ALTERACAO_PAPEL_USUARIO_USUARIO}>
            <div className="mx-1">
              <IconButton
                Icon={BsPersonCheck}
                type={ClassTypesEnum.PRIMARY}
                onClick={() => {
                  showDialog({
                    component: (
                      <AtualizarPapelUsuarioUsuarioDialog
                        usuario={usuario}
                        closeDialog={closeDialog}
                        onSubmitHandler={onUpdatePapelUsuarioUsuario(usuario)}
                      />
                    ),
                  });
                }}
              />
            </div>
          </RbacElement>
          <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.ALTERACAO_SENHA_USUARIO}>
            <div className="mx-1">
              <IconButton
                Icon={BsKey}
                type={ClassTypesEnum.PRIMARY}
                onClick={() => {
                  showDialog({
                    component: (
                      <AtualizarSenhaUsuarioDialog
                        usuario={usuario}
                        closeDialog={closeDialog}
                        onSubmitHandler={onUpdateSenhaUsuario(usuario)}
                      />
                    ),
                  });
                }}
              />
            </div>
          </RbacElement>
        </div>
      );
    },
  },
];

const UsuariosPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const { query, maxItemsQuery } = useQuerystring();
  const { showDialog, closeDialog } = useDialog();
  const { showToast } = useToasts();

  const filters = useMemo(() => ({ ...query }), [query]);
  const total = useSelector((state) => selectTotalUsuariosByFilters(state, filters));
  const usuarios = useSelector((state) => selectUsuarios(state, filters));
  const loadingState = useSelector((state) => selectLoadingStateByFiltersUsuarios(state, filters));
  const papeisUsuarios = useSelector((state) => selectObjectTodosPapeisUsuarios(state));
  const [sessionUsuario] = useSelector((state) => selectSessionUsuario(state, {})) ?? [];

  const loadEntidadesComplementares = useCallback(
    (usuarios: any[]) => {
      if (!usuarios) {
        return;
      }
      const papeisUsuarios = mapearERemoverElementosNulosERepetidos(usuarios, 'papelUsuario');

      dispatch(loadPapeisUsuarios({ query: { _id: { in: papeisUsuarios }, ...maxItemsQuery } })).catch(
        (error: Error) => error
      );
    },
    [dispatch, maxItemsQuery]
  );

  const loadItems = useCallback(
    () =>
      dispatch(loadUsuarios({ query: filters }))
        .then(({ payload: { data } }: ApiMultiElementResponse) => loadEntidadesComplementares(data))
        .catch((error: Error) => error),
    [dispatch, loadEntidadesComplementares, filters]
  );

  const onUpdatePapelUsuarioUsuario = useCallback(
    (usuario: any) => (data: any) => {
      dispatch(updateUsuarioPapelUsuario({ params: { usuarioId: usuario._id }, data }))
        .then(() => {
          closeDialog();
          showToast({ message: 'Papel do usuário alterado com sucesso', type: ClassTypesEnum.SUCCESS });

          return loadItems();
        })
        .catch((error: Error) => {
          showToast({ message: error.message, type: ClassTypesEnum.DANGER });
        });
    },
    [closeDialog, dispatch, loadItems, showToast]
  );

  const onUpdateSenhaUsuario = useCallback(
    (usuario: any) => (data: any) => {
      dispatch(updateUsuarioSenha({ params: { usuarioId: usuario._id }, data }))
        .then(() => {
          closeDialog();
          showToast({ message: 'Senha alterada com sucesso', type: ClassTypesEnum.SUCCESS });

          return loadItems();
        })
        .catch((error: Error) => {
          showToast({ message: error.message, type: ClassTypesEnum.DANGER });
        });
    },
    [closeDialog, dispatch, loadItems, showToast]
  );

  const onNovoUsuarioClickHandler = () => {
    showDialog({ component: <CadastrarUsuarioDialog closeDialog={closeDialog} reload={loadItems} /> });
  };

  const onUpdateStatusUsuarioHandler = (usuarioId: string, data: any) => {
    if (!data) {
      return;
    }

    dispatch(updateUsuarioStatus({ params: { usuarioId }, data }))
      .then(() => {
        closeDialog();

        showToast({
          message: 'Ativação do usuário atualizada com sucesso',
          type: ClassTypesEnum.SUCCESS,
        });

        return loadItems();
      })
      .catch((error: Error) => {
        showToast({
          message: error.message,
          type: ClassTypesEnum.DANGER,
        });
      });
  };

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_USUARIO}>
      <div className="d-flex flex-column h-100">
        <Title>Usuários</Title>

        <div className="row">
          <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-12 mb-4">
            <SmartTableFilters filters={smartFilters()} />
          </div>

          <div className="col-xxl-10 col-xl-9 col-lg-8 col-md-12 mb-4">
            <BSCard>
              <BSCard.Body>
                <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.CRIACAO_USUARIO}>
                  <div className="d-flex align-items-center mb-2">
                    <SmartTableButton onClick={onNovoUsuarioClickHandler}>Novo usuário</SmartTableButton>
                  </div>
                </RbacElement>
                <SmartTable
                  emptyMessage="Nenhum usuário encontrado"
                  errorMessage="Erro na listagem de usuários"
                  usePagination={true}
                  loadItems={loadItems}
                  columns={smartColumns({
                    closeDialog,
                    papeisUsuarios,
                    onUpdatePapelUsuarioUsuario,
                    onUpdateSenhaUsuario,
                    onUpdateStatusUsuarioHandler,
                    sessionUsuario,
                    showDialog,
                  })}
                  items={usuarios}
                  loadingState={loadingState}
                  size={total}
                />
              </BSCard.Body>
            </BSCard>
          </div>
        </div>
      </div>
    </RbacPage>
  );
};

export default UsuariosPage;

import qs from 'qs';

export type LoadingStateType = 'loading' | 'loaded' | 'failure';

export const mapearERemoverElementosNulosERepetidos = (elementos: any[], atributo: string) =>
  [...new Set(elementos.map((elem) => elem[atributo]))].filter((elem) => elem);

export const awaitForAsyncTask = (task: (...args: any[]) => any, callback: (...args: any[]) => any) => {
  return () => {
    const res = task();

    if (!res || !res.then) {
      callback();

      return;
    }

    res.then(callback);
  };
};

export const removeEmptyFields = (obj: { [key: string]: any }) => {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => ![null, undefined, ''].includes(v)));
};

export const getOnlyNumbers = (str: string) => str.replace(/\D/g, '');

export const generateUniqueId = (): string => {
  return `${Math.random().toString(16).slice(2)}${Date.now().toString(16)}`;
};

export const alphabeticalSort = (a: string, b: string) => a.localeCompare(b);

export const serializeFilters = (filters?: Record<string, unknown>): string => {
  return qs.stringify(filters ?? {}, {
    arrayFormat: 'brackets',
    sort: (a: string, b: string) => a.localeCompare(b),
  });
};

export const camelToSnakeUpperCase = (input: string): string => {
  return input.replace(/[A-Z]/g, (match) => `_${match.toLowerCase()}`).toUpperCase();
};

import HelpTooltip from './help-tooltip';

type Props = {
  children: React.ReactNode;
  dica?: string | JSX.Element;
};

const Title: React.FC<Props> = ({ children, dica }) => {
  return (
    <h4 className="mb-5 d-flex align-items-center">
      {children} {dica && <HelpTooltip dica={dica} />}
    </h4>
  );
};

export default Title;

import { Route, Routes } from 'react-router-dom';
import DetalhesProtocoloProcessamentoContaPage from './detalhes-processamento-conta-page';
import ProtocolosProcessamentoContaPage from './protocolos-processamento-conta-page';

const ProtocolosProcessamentoContaRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<ProtocolosProcessamentoContaPage />} />
      <Route path=":protocoloProcessamentoContaId" element={<DetalhesProtocoloProcessamentoContaPage />} />
    </Routes>
  );
};

export default ProtocolosProcessamentoContaRoutes;

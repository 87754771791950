import { useCallback, useMemo } from 'react';
import BSCard from 'react-bootstrap/Card';
import { BsPencilSquare } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import IconButton from '../../components/button/icon-button';
import RbacElement from '../../components/role-based-access-control/role-based-access-control-element';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import ExportCSVButton from '../../components/smart-table/export-csv-button';
import { SmartTable } from '../../components/smart-table/smart-table';
import SmartTableButton from '../../components/smart-table/smart-table-button';
import SmartTableFilters from '../../components/smart-table/smart-table-filters/smart-table-filters';
import {
  SmartTableFilterControlTypesEnum,
  SmartTableFilterProps,
} from '../../components/smart-table/smart-table-filters/table-filter.types';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import Title from '../../components/title';
import { ClassTypesEnum, SizesEnum } from '../../helpers';
import { DialogProps } from '../../hooks/dialog/dialog-context';
import { useDialog } from '../../hooks/dialog/use-dialog';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import {
  loadParametrosGeracaoBoleto,
  selectParametrosGeracaoBoletoByFilters,
  selectParametrosGeracaoBoletoLoadingStateByFilters,
  selectTotalParametrosGeracaoBoletoByFilters,
  updateParametroGeracaoBoleto,
} from './parametros-geracao-boleto.redux';

import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import CadastrarParametroGeracaoBoletoDialog from '../parametros-geracao-boleto/cadastrar-parametro-geracao-boleto-dialog';
import AtualizarParametroGeracaoBoletoDialog from './atualizar-parametro-geracao-boleto-dialog';

const smartColumns = ({
  showDialog,
  closeDialog,
  onUpdateParametroGeracaoBoletoHandler,
}: {
  showDialog: (dialogProps: DialogProps) => void;
  closeDialog: () => void;
  onUpdateParametroGeracaoBoletoHandler: (parametroGeracaoBoletoId: string, data: any) => void;
}): SmartTableColumnProps[] => [
  { label: 'Código', attribute: 'codigoBancoCedente', sortable: true, className: 'text-center' },
  { label: 'Cedente', attribute: 'nomeBancoCedente', sortable: true, className: 'text-center' },
  { label: 'Agência', attribute: 'agenciaBeneficiario', sortable: true, className: 'text-center' },
  { label: 'Conta', attribute: 'contaBeneficiario', sortable: true, className: 'text-center' },
  { label: 'Descrição', attribute: 'descricaoBeneficiario', sortable: true, className: 'text-center' },
  {
    label: 'Ações',
    format: (_, parametroGeracaoBoleto) => {
      return (
        <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.ALTERACAO_PARAMETROS_GERACAO_BOLETO}>
          <div className="d-flex justify-content-center">
            <div className="mx-1">
              <IconButton
                Icon={BsPencilSquare}
                type={ClassTypesEnum.PRIMARY}
                onClick={() => {
                  showDialog({
                    component: (
                      <AtualizarParametroGeracaoBoletoDialog
                        closeDialog={closeDialog}
                        parametroGeracaoBoleto={parametroGeracaoBoleto}
                        onUpdateHandler={onUpdateParametroGeracaoBoletoHandler}
                      />
                    ),
                    size: SizesEnum.EXTRA_LARGE,
                  });
                }}
              />
            </div>
          </div>
        </RbacElement>
      );
    },
  },
];

const smartFilters: SmartTableFilterProps[] = [
  {
    label: 'Código do banco cedente',
    attribute: 'codigoBancoCedente',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
  },
  {
    label: 'Nome do banco cedente',
    attribute: 'nomeBancoCedente',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
  },
  {
    label: 'Agência beneficiário',
    attribute: 'agenciaBeneficiario',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
  },
  {
    label: 'Conta beneficiário',
    attribute: 'contaBeneficiario',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
  },
];
const csvHeaders = [
  { label: 'Aceite', key: 'aceite' },
  { label: 'Agencia do Beneficiário', key: 'agenciaBeneficiario' },
  { label: 'Carteira', key: 'carteira' },
  { label: 'Cidade', key: 'cidade' },
  { label: 'Cip', key: 'cip' },
  { label: 'CNPJ', key: 'cnpj' },
  { label: 'Código de baixa devolução', key: 'codigoBaixaDevolucao' },
  { label: 'Código do banco cedente', key: 'codigoBancoCedente' },
  { label: 'Convênio cedente', key: 'convenioCedente' },
  { label: 'Código da empresa', key: 'codigoEmpresa' },
  { label: 'Conta do beneficiário', key: 'contaBeneficiario' },
  { label: 'Descrição do beneficiário', key: 'descricaoBeneficiario' },
  { label: 'Dígito da conta do beneficiário', key: 'digitoContaBeneficiario' },
  { label: 'Endereço', key: 'endereco' },
  { label: 'Espécie do documento', key: 'especieDocumento' },
  { label: 'Local do pagamento', key: 'localPagamento' },
  { label: 'Moeda', key: 'moeda' },
  { label: 'Nome do banco cedente', key: 'nomeBancoCedente' },
  { label: 'Pagamento parcial', key: 'pagamentoParcial' },
  { label: 'Subtipo Cnab  ', key: 'subtipoCnab' },
  { label: 'Tipo Cnab', key: 'tipoCnab' },
];
const ParametrosGeracaoBoletoPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const { query } = useQuerystring();
  const navigate = useNavigate();
  const { closeDialog, showDialog } = useDialog();
  const { showToast } = useToasts();

  const onNovoParametroGeracaoBoletoHandler = () => {
    showDialog({
      component: <CadastrarParametroGeracaoBoletoDialog closeDialog={closeDialog} reload={_loadItems} />,
      size: SizesEnum.EXTRA_LARGE,
    });
  };

  const onUpdateParametroGeracaoBoletoHandler = (parametroGeracaoBoletoId: string, data: any) => {
    if (!data) {
      return;
    }

    dispatch(updateParametroGeracaoBoleto({ params: { parametroGeracaoBoletoId }, data }))
      .then(() => {
        closeDialog();

        showToast({
          message: 'Parâmetro de geração de boleto atualizado com sucesso',
          type: ClassTypesEnum.SUCCESS,
        });

        return _loadItems();
      })
      .catch((error: Error) => {
        showToast({
          message: error.message,
          type: ClassTypesEnum.DANGER,
        });
      });
  };

  const filters = useMemo(() => ({ ...query }), [query]);
  const parametrosGeracaoBoleto = useSelector((state) => selectParametrosGeracaoBoletoByFilters(state, filters));
  const loadingState = useSelector((state) => selectParametrosGeracaoBoletoLoadingStateByFilters(state, filters));
  const total = useSelector((state) => selectTotalParametrosGeracaoBoletoByFilters(state, filters));

  const _loadItems = useCallback(() => {
    dispatch(loadParametrosGeracaoBoleto({ query: filters })).catch((error: Error) => error);
  }, [dispatch, filters]);

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_PARAMETROS_GERACAO_BOLETO}>
      <div className="d-flex flex-column h-100">
        <Title>Parâmetros de geração de boleto</Title>

        <div className="row">
          <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-12 mb-4">
            <SmartTableFilters filters={smartFilters} />
          </div>

          <div className="col-xxl-10 col-xl-9 col-lg-8 col-md-12 mb-4">
            <BSCard>
              <BSCard.Body>
                <div className="d-flex align-items-center mb-2">
                  <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.CRIACAO_PARAMETROS_GERACAO_BOLETO}>
                    <SmartTableButton onClick={onNovoParametroGeracaoBoletoHandler}>Novo parâmetro</SmartTableButton>
                  </RbacElement>

                  <ExportCSVButton
                    data={parametrosGeracaoBoleto}
                    filename="parametros-geracao-boleto"
                    headers={csvHeaders}
                  />
                </div>
                <SmartTable
                  emptyMessage="Nenhum parâmetro de geração de boleto encontrado"
                  errorMessage="Erro ao listar parâmetros de geração de boleto"
                  loadItems={_loadItems}
                  usePagination={true}
                  columns={smartColumns({
                    closeDialog,
                    showDialog,
                    onUpdateParametroGeracaoBoletoHandler,
                  })}
                  items={parametrosGeracaoBoleto}
                  loadingState={loadingState}
                  onRowClick={(doc) => navigate(`/parametros-geracao-boleto/${doc._id}/`)}
                  size={total}
                />
              </BSCard.Body>
            </BSCard>
          </div>
        </div>
      </div>
    </RbacPage>
  );
};

export default ParametrosGeracaoBoletoPage;

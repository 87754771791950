import { formatValue, FormatValueEnum } from '../../helpers';
import { SmartTableColumnProps } from './smart-table-header';

type SmartTableBodyProps = {
  columns: SmartTableColumnProps[];
  docs: any[];
  onRowClick?: (doc: any, docIndex?: number) => void;
};

export const SmartTableBody: React.FC<SmartTableBodyProps> = ({ columns, docs, onRowClick }) => {
  const renderFormattedValue = (column: SmartTableColumnProps, doc: any): React.ReactNode => {
    const formatIsFunction = column.format instanceof Function;

    if (!column.attribute) {
      if (formatIsFunction) {
        const formatFunction = column.format as Function;

        return formatFunction?.(undefined, doc);
      }

      return <></>;
    }

    if (column.format && !formatIsFunction) {
      return formatValue({
        format: column.format as FormatValueEnum,
        value: doc[column.attribute],
        map: column.map,
      });
    }

    if (column.format) {
      const formatFunction = column.format as Function;

      return formatFunction?.(doc[column.attribute], doc);
    }

    return doc[column.attribute];
  };

  return (
    <tbody data-testid="smart-table-body">
      {docs.map((doc: any, docIndex: number) => (
        <tr key={docIndex}>
          {columns.map((column, columnIndex) => (
            <td
              key={columnIndex}
              className={column.className}
              role={column.selectable ? '' : onRowClick ? 'button' : ''}
              onClick={(event: React.MouseEvent) => {
                if (column.selectable) {
                  return;
                }

                event.stopPropagation();
                event.preventDefault();

                onRowClick?.(doc, docIndex);
              }}
            >
              {renderFormattedValue(column, doc)}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

import { useCallback } from 'react';
import BSBadge from 'react-bootstrap/Badge';
import BSButton from 'react-bootstrap/Button';
import BSForm from 'react-bootstrap/Form';
import BSModal from 'react-bootstrap/Modal';
import { useFieldArray, useForm } from 'react-hook-form';
import { BsTrash } from 'react-icons/bs';

import IconButton from '../../components/button/icon-button';
import { Form } from '../../components/form/form';
import { FormControl } from '../../components/form/form-control';
import { ClassTypesEnum } from '../../helpers';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch } from '../../store/hooks-redux';
import { createTopicoPerguntasFrequentes } from './perguntas-frequentes.redux';

type CadastrarTopicosPerguntasFrequentesFormFields = {
  assunto: string;
  tagInput: string;
  tags: { tag: string }[];
};

type CadastrarTopicosPerguntasFrequentesDialogProps = {
  closeDialog: () => void;
  perguntaFrequenteId: any;
  reload: () => void;
};

const CadastrarTopicosPerguntasFrequentesDialog: React.FC<CadastrarTopicosPerguntasFrequentesDialogProps> = ({
  closeDialog,
  perguntaFrequenteId,
  reload,
}) => {
  const dispatch = useAppDispatch();
  const { showToast } = useToasts();

  const form = useForm<CadastrarTopicosPerguntasFrequentesFormFields>();
  const { control, setValue } = form;
  const { append, fields, remove } = useFieldArray({ control, name: 'tags' });

  const onSubmitHandler = useCallback(
    (data: CadastrarTopicosPerguntasFrequentesFormFields) => {
      const dados = {
        assunto: data.assunto,
        tags: (data.tags ?? []).map(({ tag }) => tag),
      };

      dispatch(createTopicoPerguntasFrequentes({ params: { perguntaFrequenteId }, data: dados }))
        .then(() => {
          closeDialog();
          showToast({ message: 'Tópico cadastrado com sucesso', type: ClassTypesEnum.SUCCESS });
          reload();
        })
        .catch((error: Error) => {
          showToast({ message: error.message, type: ClassTypesEnum.DANGER });
        });
    },
    [closeDialog, dispatch, perguntaFrequenteId, reload, showToast]
  );

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    const { key } = event;
    const { value } = event.target as HTMLInputElement;

    if (key === 'Enter' && value.length !== 0) {
      event.preventDefault();

      append({ tag: value });
      setValue('tagInput', '');
    }
  };

  const removeTag = (index: number): void => {
    remove(index);
  };

  return (
    <>
      <BSModal.Header closeButton>
        <BSModal.Title>Novo tópico</BSModal.Title>
      </BSModal.Header>

      <BSModal.Body>
        <Form id="form" form={form} onSubmit={onSubmitHandler}>
          <FormControl control={control} className="mb-3" label="Assunto" name="assunto" rules={{ required: true }} />

          {fields?.length > 0 && <BSForm.Label>Tags</BSForm.Label>}

          <div className="d-flex mb-2 flex-wrap">
            {fields.map(({ tag }, index) => (
              <div key={index} className="m-1">
                <h5>
                  <BSBadge key={index} bg={ClassTypesEnum.PRIMARY} pill>
                    <div className="d-flex align-items-center">
                      <span className="me-2">{tag}</span>
                      <IconButton Icon={BsTrash} type={ClassTypesEnum.DANGER} onClick={() => removeTag(index)} />
                    </div>
                  </BSBadge>
                </h5>
              </div>
            ))}
          </div>

          <FormControl control={control} className="mb-3" label="Tags" name="tagInput" onKeyDown={onKeyDown} />
        </Form>
      </BSModal.Body>

      <BSModal.Footer>
        <BSButton variant="light" onClick={closeDialog}>
          Fechar
        </BSButton>
        <BSButton variant="primary" type="submit" form="form">
          Cadastrar
        </BSButton>
      </BSModal.Footer>
    </>
  );
};

export default CadastrarTopicosPerguntasFrequentesDialog;

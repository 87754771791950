import React from 'react';
import BSButton from 'react-bootstrap/Button';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Form } from '../../components/form/form';
import { FormControl } from '../../components/form/form-control';
import { ClassTypesEnum } from '../../helpers';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch } from '../../store/hooks-redux';
import { updateProduto } from './produtos.redux';

type AtualizarBloqueiosProdutoFormFields = {
  diasToleranciaParaBloqueioAtraso: number;
  diasParaBloqueioPreCreli: number;
  diasParaBloqueioCreli: number;
  diasParaBloqueioPrejuizo: number;
};

type AtualizarBloqueiosProdutoTabProps = {
  produto: any;
};

const AtualizarBloqueiosProdutoTab: React.FC<AtualizarBloqueiosProdutoTabProps> = ({ produto }) => {
  const dispatch = useAppDispatch();
  const { showToast } = useToasts();
  const navigate = useNavigate();

  const form = useForm<AtualizarBloqueiosProdutoFormFields>({
    defaultValues: {
      diasParaBloqueioCreli: produto.diasParaBloqueioCreli,
      diasParaBloqueioPreCreli: produto.diasParaBloqueioPreCreli,
      diasParaBloqueioPrejuizo: produto.diasParaBloqueioPrejuizo,
      diasToleranciaParaBloqueioAtraso: produto.diasToleranciaParaBloqueioAtraso,
    },
  });
  const { control } = form;

  const onSubmitHandler = (data: AtualizarBloqueiosProdutoFormFields) => {
    if (Object.keys(data).length === 0) {
      return;
    }

    dispatch(updateProduto({ params: { produtoId: produto._id }, data }))
      .then(() => {
        showToast({
          message: 'Produto atualizado com sucesso',
          type: ClassTypesEnum.SUCCESS,
        });

        return navigate('/produtos/');
      })
      .catch((error: Error) => {
        showToast({
          message: error.message,
          type: ClassTypesEnum.DANGER,
        });
      });
  };

  return (
    <>
      <Form form={form} id="form" onSubmit={onSubmitHandler}>
        <div className="row">
          <FormControl
            control={control}
            className="col-xl-4 col-md-6 col-sm-12 mb-3"
            label="Dias de tolerância para o atraso da conta"
            name="diasToleranciaParaBloqueioAtraso"
            type="number"
          />

          <FormControl
            control={control}
            className="col-xl-4 col-md-6 col-sm-12 mb-3"
            label="Dias necessários para bloqueio Pré-Creli"
            name="diasParaBloqueioPreCreli"
            type="number"
          />

          <FormControl
            control={control}
            className="col-xl-4 col-md-6 col-sm-12 mb-3"
            label="Dias necessários para bloqueio Creli"
            name="diasParaBloqueioCreli"
            type="number"
          />

          <FormControl
            control={control}
            className="col-xl-4 col-md-6 col-sm-12 mb-3"
            label="Dias necessários para bloqueio Prejuízo"
            name="diasParaBloqueioPrejuizo"
            type="number"
          />

          <div className="d-flex justify-content-end">
            <BSButton variant="primary" type="submit" form="form">
              Atualizar
            </BSButton>
          </div>
        </div>
      </Form>
    </>
  );
};
export default AtualizarBloqueiosProdutoTab;

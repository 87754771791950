import { Route, Routes } from 'react-router-dom';
import DetalhesQMR from './detalhes-qmr';
import QmrPage from './qmr-page';
const QmrRoutes: React.FC = () => {
  return (
    <Routes>
      <Route index element={<QmrPage />} />
      <Route path="/:qmrId" element={<DetalhesQMR />} />
    </Routes>
  );
};

export default QmrRoutes;

import { Route, Routes } from 'react-router-dom';
import HistoricoAtividadesExternasPage from './historico-atividades-externas-page';
import DetalhesHistoricoAtividadeExternaPage from './detalhes-historico-atividade-externa-page';

const HistoricosAtividadesExternasRoutes: React.FC = () => {
  return (
    <Routes>
      <Route index element={<HistoricoAtividadesExternasPage />} />
      <Route path=":historicoAtividadeExternaId" element={<DetalhesHistoricoAtividadeExternaPage />} />
    </Routes>
  );
};

export default HistoricosAtividadesExternasRoutes;

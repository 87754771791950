import { CSVLink } from 'react-csv';
import { ClassTypesEnum } from '../../helpers';
import { useToasts } from '../../hooks/toast/use-toasts';

type ExportCSVButtonProps = {
  variant?: string;
  className?: string;
  data?: any[];
  filename?: string;
  label?: string;
  headers?: any[];
};

const ExportCSVButton: React.FC<ExportCSVButtonProps> = ({
  data,
  filename,
  headers,
  className,
  variant = 'outline-primary',
  label = 'Exportar CSV',
}) => {
  const { showToast } = useToasts();

  const onClickHandler = () => {
    if (!data || data?.length === 0) {
      showToast({
        message: 'Não existem dados na página',
        type: ClassTypesEnum.DANGER,
      });

      return false;
    }
  };

  return (
    <CSVLink
      data-testid="export-csv-button"
      className={`btn btn-${variant} ${className ?? 'me-1 mb-1'}`}
      data={data || []}
      filename={filename}
      onClick={onClickHandler}
      title="Exportar dados"
      headers={headers}
    >
      {label}
    </CSVLink>
  );
};

export default ExportCSVButton;

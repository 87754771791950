import { reducerFactory, actionFactory, selectorFactory } from '../../store';

const context = 'historicosCliente';

const { loadMany, loadOne } = actionFactory(context, {
  buildUrlLoadMany: ({ clienteId }) => `/api/clientes/${clienteId}/historicos`,
  buildUrlLoadOne: ({ historicoClienteId }) => `/api/historicos-cliente/${historicoClienteId}`,
});

const { selectOneById, selectManyByFilters, selectTotalByFilters, selectLoadingStateByFilters } = selectorFactory({
  context,
});

export const loadHistoricoCliente = loadMany;
export const loadHistorico = loadOne;

export const selectHistoricoClienteById = selectOneById;
export const selectHistoricoClienteByFilters = selectManyByFilters;
export const selectTotalHistoricoClienteByFilters = selectTotalByFilters;
export const selectHistoricoClienteLoadingStateByFilters = selectLoadingStateByFilters;

export const historicosCliente = reducerFactory({ context });

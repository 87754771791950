import { reducerFactory, actionFactory, selectorFactory } from '../../store';

const context = 'parametrosGeracaoBoleto';

const { createOne, loadMany, loadOne, updateOne } = actionFactory(context, {
  buildUrlCreateOne: () => '/api/parametros-geracao-boleto',
  buildUrlLoadOne: ({ parametroGeracaoBoletoId }) => `/api/parametros-geracao-boleto/${parametroGeracaoBoletoId}`,
  buildUrlLoadMany: () => '/api/parametros-geracao-boleto',
  buildUrlUpdateOne: ({ parametroGeracaoBoletoId }) => `/api/parametros-geracao-boleto/${parametroGeracaoBoletoId}`,
});

const { selectOneById, selectAll, selectManyByFilters, selectTotalByFilters, selectLoadingStateByFilters } =
  selectorFactory({ context });

export const createParametroGeracaoBoleto = createOne;
export const loadParametrosGeracaoBoleto = loadMany;
export const loadParametroGeracaoBoleto = loadOne;
export const updateParametroGeracaoBoleto = updateOne;

export const selectParametroGeracaoBoletoById = selectOneById;
export const selectTodosParametrosGeracaoBoleto = selectAll;
export const selectParametrosGeracaoBoletoByFilters = selectManyByFilters;
export const selectTotalParametrosGeracaoBoletoByFilters = selectTotalByFilters;
export const selectParametrosGeracaoBoletoLoadingStateByFilters = selectLoadingStateByFilters;

export const parametrosGeracaoBoleto = reducerFactory({ context });

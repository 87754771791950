import { actionFactory, reducerFactory, selectorFactory } from '../../store';

const context = 'saldoTotalDevedorContaCartao';

const { loadOne } = actionFactory(context, {
  buildUrlLoadOne: ({ contaCartaoId }) => `/api/contas-cartao/${contaCartaoId}/saldo-total-devedor`,
});

const { selectOneById } = selectorFactory({ context });

export const loadSaldoTotalDevedorContaCartao = loadOne;
export const selectSaldoTotalDevedorContaCartaoById = selectOneById;

export const saldoTotalDevedorContaCartao = reducerFactory({ context });

import BSButton from 'react-bootstrap/Button';
import BSModal from 'react-bootstrap/Modal';

import { useForm } from 'react-hook-form';
import { Form } from '../../components/form/form';
import { FormControl } from '../../components/form/form-control';
import { FormSelect } from '../../components/form/form-select';
import { tiposOperacaoBeneficioLabelMap } from '../../helpers';

type AtualizarFaixaMccFormFields = {
  descricao: string;
  descricaoBandeira: string;
  inicioMcc: string;
  fimMcc: string;
  operacaoBeneficio: string;
};

type AtualizarFaixaMccDialogProps = {
  faixaMcc: any;
  closeDialog: () => void;
  onUpdateHandler: (faixaMccId: string, data: any) => void;
};

const AtualizarFaixaMccDialog: React.FC<AtualizarFaixaMccDialogProps> = ({
  closeDialog,
  faixaMcc,
  onUpdateHandler,
}) => {
  const form = useForm<AtualizarFaixaMccFormFields>({ defaultValues: faixaMcc });
  const { control } = form;

  const onSubmitHandler = (data: AtualizarFaixaMccFormFields) => {
    onUpdateHandler(faixaMcc._id, data);
  };

  return (
    <>
      <BSModal.Header closeButton>
        <BSModal.Title>Atualizar faixa de MCC - {faixaMcc.descricao}</BSModal.Title>
      </BSModal.Header>

      <BSModal.Body>
        <Form id="form" form={form} onSubmit={onSubmitHandler}>
          <FormControl
            control={control}
            className="mb-3"
            label="Descrição"
            name="descricao"
            rules={{ required: true }}
          />

          <FormControl
            control={control}
            className="mb-3"
            label="Descrição da bandeira"
            name="descricaoBandeira"
            rules={{ required: true }}
          />

          <FormControl
            control={control}
            className="mb-3"
            label="Início da faixa"
            name="inicioMcc"
            rules={{ required: true }}
          />

          <FormControl
            control={control}
            className="mb-3"
            label="Fim da faixa"
            name="fimMcc"
            rules={{ required: true }}
          />

          <FormSelect
            control={control}
            className="col-md-6 mb-3"
            label="Operação do benefício"
            name="operacaoBeneficio"
            placeholder="Selecione a operação"
            options={Object.entries(tiposOperacaoBeneficioLabelMap).map((elem) => ({
              label: elem[1],
              value: elem[0],
            }))}
            rules={{ required: true }}
          />
        </Form>
      </BSModal.Body>

      <BSModal.Footer>
        <BSButton variant="light" onClick={closeDialog}>
          Fechar
        </BSButton>
        <BSButton variant="primary" type="submit" form="form">
          Atualizar faixa de MCC
        </BSButton>
      </BSModal.Footer>
    </>
  );
};

export default AtualizarFaixaMccDialog;

import { SmartTable } from '../../components/smart-table/smart-table';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import { tipoTransacaoLabelMap } from '../../helpers';
import { useAppSelector } from '../../store/hooks-redux';
import { selectProdutoById } from './produtos.redux';

const smartColumns = (): SmartTableColumnProps[] => [
  {
    label: 'Tipos de transações',
    className: 'text-center',
    format: (_, operacao) => tipoTransacaoLabelMap[operacao as keyof typeof tipoTransacaoLabelMap],
  },
];

type OperacoesPermitidasProdutoTabProps = {
  produtoId: string;
};

const OperacoesPermitidasProdutoTab: React.FC<OperacoesPermitidasProdutoTabProps> = ({ produtoId }) => {
  const useSelector = useAppSelector;
  const { operacoesPermitidas } = useSelector((state) => selectProdutoById(state, produtoId));

  return (
    <SmartTable
      emptyMessage="Nenhuma operação permitida encontrada"
      errorMessage="Erro ao listar operações permitidas"
      usePagination={true}
      columns={smartColumns()}
      items={operacoesPermitidas}
      size={operacoesPermitidas.length}
    />
  );
};

export default OperacoesPermitidasProdutoTab;

import { Navigate, Route, Routes } from 'react-router-dom';
import DetalhesProtocoloProcessamentoLotePage from './detalhes-protocolo-processamento-lote-page';
import ProtocolosProcessamentoLoteArquivosPage from './protocolos-processamento-lote-arquivos-page';
import ProtocolosProcessamentoLoteRelatoriosOperacaoPage from './protocolos-processamento-lote-relatorios-operacao-page';
import ProtocolosProcessamentoLoteRotinasFrequentesPage from './protocolos-processamento-lote-rotinas-frequentes-page';
import ProtocolosProcessamentoLoteRotinasPage from './protocolos-processamento-lote-rotinas-page';

const ProtocolosProcessamentoLoteRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="rotinas" />} />
      <Route path="relatorios-operacao" element={<ProtocolosProcessamentoLoteRelatoriosOperacaoPage />} />
      <Route path="rotinas" element={<ProtocolosProcessamentoLoteRotinasPage />} />
      <Route path="rotinas-frequentes" element={<ProtocolosProcessamentoLoteRotinasFrequentesPage />} />
      <Route path="arquivos" element={<ProtocolosProcessamentoLoteArquivosPage />} />
      <Route path=":protocoloProcessamentoLoteId" element={<DetalhesProtocoloProcessamentoLotePage />} />
    </Routes>
  );
};

export default ProtocolosProcessamentoLoteRoutes;

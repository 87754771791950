import { TipoCreditoEnum } from '@tamborineapps/lib-enums';
import { useCallback, useEffect } from 'react';
import BSButton from 'react-bootstrap/Button';
import BSModal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { Form } from '../../components/form/form';
import { FormControl } from '../../components/form/form-control';
import { FormSelect } from '../../components/form/form-select';
import { ClassTypesEnum } from '../../helpers';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import { loadProdutos, selectObjectTodosProdutos } from '../produtos/produtos.redux';
import { saveCalendarioCorte } from './calendarios-corte.redux';

type CadastrarCalendarioCorteFormFields = {
  produto: string;
  dataPrevia: string;
  dataCorte: string;
  dataVencimento: string;
};

type CadastrarCalendarioCorteDialogProps = {
  closeDialog: () => void;
  reload: () => void;
};

const CadastrarCalendarioCorteDialog: React.FC<CadastrarCalendarioCorteDialogProps> = ({ closeDialog, reload }) => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;
  const { showToast } = useToasts();

  const form = useForm<CadastrarCalendarioCorteFormFields>();
  const { control, watch } = form;

  const produtos = useSelector((state) => selectObjectTodosProdutos(state));

  const produtoId = watch('produto');
  const ehProdutoConsignado = produtos?.[produtoId]?.tipoCredito === TipoCreditoEnum.CONSIGNADO;

  const onSubmitHandler = useCallback(
    (data: CadastrarCalendarioCorteFormFields) => {
      dispatch(saveCalendarioCorte({ data }))
        .then(() => {
          closeDialog();
          showToast({ message: 'Calendário de corte cadastrado com sucesso', type: ClassTypesEnum.SUCCESS });
          reload();
        })
        .catch((error: Error) => {
          showToast({ message: error.message, type: ClassTypesEnum.DANGER });
        });
    },
    [closeDialog, dispatch, reload, showToast]
  );

  useEffect(() => {
    dispatch(loadProdutos()).catch((error: Error) => error);
  }, [dispatch]);

  return (
    <>
      <BSModal.Header closeButton>
        <BSModal.Title>Novo calendário de corte</BSModal.Title>
      </BSModal.Header>

      <BSModal.Body>
        <Form id="form" form={form} onSubmit={onSubmitHandler}>
          <FormSelect
            control={control}
            className="mb-3"
            label="Produto"
            name="produto"
            placeholder="Selecione o produto"
            options={Object.values(produtos ?? {})?.map((produto: any) => ({
              key: produto._id,
              value: produto._id,
              label: `${produto.codigo} - ${produto.nome}`,
            }))}
            rules={{ required: true }}
          />

          {ehProdutoConsignado && (
            <FormControl
              control={control}
              className="mb-3"
              label="Data de prévia"
              name="dataPrevia"
              type="date"
              rules={{ required: true }}
            />
          )}

          <FormControl
            control={control}
            className="mb-3"
            label="Data de corte"
            name="dataCorte"
            type="date"
            rules={{ required: true }}
          />

          <FormControl
            control={control}
            className="mb-3"
            label="Data de vencimento"
            name="dataVencimento"
            type="date"
            rules={{ required: true }}
          />
        </Form>
      </BSModal.Body>

      <BSModal.Footer>
        <BSButton variant="light" onClick={closeDialog}>
          Fechar
        </BSButton>
        <BSButton variant="primary" type="submit" form="form">
          Cadastrar calendário de corte
        </BSButton>
      </BSModal.Footer>
    </>
  );
};

export default CadastrarCalendarioCorteDialog;

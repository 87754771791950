import React, { useCallback } from 'react';
import BSButton from 'react-bootstrap/Button';
import BSModal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';

import { OperacaoTransacaoEnum } from '@tamborineapps/lib-enums';
import { Form } from '../../components/form/form';
import { FormSelect } from '../../components/form/form-select';
import { ClassTypesEnum, tipoTransacaoLabelMap } from '../../helpers';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch } from '../../store/hooks-redux';
import { incluirOperacaoPermitidaProduto } from './produtos.redux';

type IncluirOperacaoPermitidaProdutoFormFields = {
  operacao: string;
};

type IncluirOperacaoPermitidaProdutoDialogProps = {
  produto: any;
  closeDialog: () => void;
};

export const operacoesDisponiveis = [
  OperacaoTransacaoEnum.COMPRA_CREDITO_A_VISTA,
  OperacaoTransacaoEnum.COMPRA_CREDITO_A_VISTA_INTERNACIONAL,
  OperacaoTransacaoEnum.COMPRA_CREDITO_PARCELADA,
  OperacaoTransacaoEnum.COMPRA_CREDITO_PARCELADA_EMISSOR,
  OperacaoTransacaoEnum.ESTORNO_COMPRA_CREDITO_A_VISTA,
  OperacaoTransacaoEnum.ESTORNO_COMPRA_CREDITO_A_VISTA_INTERNACIONAL,
  OperacaoTransacaoEnum.ESTORNO_COMPRA_CREDITO_PARCELADA,
];

const IncluirOperacaoPermitidaProdutoDialog: React.FC<IncluirOperacaoPermitidaProdutoDialogProps> = ({
  produto,
  closeDialog,
}) => {
  const dispatch = useAppDispatch();
  const { showToast } = useToasts();

  const form = useForm<IncluirOperacaoPermitidaProdutoFormFields>();
  const { control } = form;

  const operacoesSelecionaveis = operacoesDisponiveis.filter(
    (operacaoDisponivel) => !produto?.operacoesPermitidas?.includes(operacaoDisponivel)
  );

  const onSubmitHandler = useCallback(
    (data: IncluirOperacaoPermitidaProdutoFormFields) => {
      dispatch(incluirOperacaoPermitidaProduto({ params: { produtoId: produto._id }, data }))
        .then(() => {
          showToast({
            message: 'Operação incluída com sucesso',
            type: ClassTypesEnum.SUCCESS,
          });
          closeDialog();
        })
        .catch((error: Error) => {
          showToast({
            message: error.message,
            type: ClassTypesEnum.DANGER,
          });
        });
    },
    [closeDialog, dispatch, produto._id, showToast]
  );

  return (
    <>
      <BSModal.Header closeButton>
        <BSModal.Title>Incluir operação permitida - produto {produto.nome}</BSModal.Title>
      </BSModal.Header>

      <BSModal.Body>
        <Form id="formOperacoesPermitidas" form={form} onSubmit={onSubmitHandler}>
          <FormSelect
            control={control}
            className="mb-3"
            name="operacao"
            label="Operação"
            placeholder="Selecione a operação"
            options={operacoesSelecionaveis.map((operacao: string) => ({
              key: operacao.toString(),
              value: operacao,
              label: tipoTransacaoLabelMap[operacao as keyof typeof tipoTransacaoLabelMap],
            }))}
            rules={{ required: true }}
          />
        </Form>
      </BSModal.Body>

      <BSModal.Footer>
        <BSButton variant="light" onClick={closeDialog}>
          Fechar
        </BSButton>
        <BSButton variant="primary" type="submit" form="formOperacoesPermitidas">
          Incluir operação
        </BSButton>
      </BSModal.Footer>
    </>
  );
};

export default IncluirOperacaoPermitidaProdutoDialog;

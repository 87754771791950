import BSSpinner from 'react-bootstrap/Spinner';
import { LoadingStateType } from '../helpers';

export type LoadingProps = {
  loadingState?: LoadingStateType;
  notFoundMessage?: string;
};

export const Loading: React.FC<LoadingProps> = ({ loadingState, notFoundMessage }) => {
  if (loadingState && loadingState === 'failure') {
    return (
      <div className="row justify-content-center mt-5">
        <h5>{notFoundMessage || 'Não encontrado'}</h5>
      </div>
    );
  }

  if (loadingState && loadingState === 'loaded') {
    return <></>;
  }

  return <BSSpinner animation="grow" variant="primary" />;
};

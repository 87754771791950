import { reducerFactory, actionFactory, selectorFactory } from '../../store';

const context = 'tiposBloqueioContaCartao';

const { createOne, loadMany, loadOne, updateOne } = actionFactory(context, {
  buildUrlCreateOne: () => '/api/tipos-bloqueio-conta-cartao',
  buildUrlLoadMany: () => '/api/tipos-bloqueio-conta-cartao',
  buildUrlLoadOne: ({ tipoBloqueioContaCartaoId }) => `/api/tipos-bloqueio-conta-cartao/${tipoBloqueioContaCartaoId}`,
  buildUrlUpdateOne: ({ tipoBloqueioContaCartaoId }) => `/api/tipos-bloqueio-conta-cartao/${tipoBloqueioContaCartaoId}`,
});

const {
  selectOneById,
  selectAll,
  selectObjectAllIds,
  selectManyByFilters,
  selectTotalByFilters,
  selectLoadingStateByFilters,
} = selectorFactory({
  context,
});

export const createTipoBloqueioContaCartao = createOne;
export const loadTiposBloqueiosContaCartao = loadMany;
export const loadTipoBloqueioContaCartao = loadOne;
export const updateTipoBloqueioContaCartao = updateOne;

export const selectTiposBloqueioContaCartaoById = selectOneById;
export const selectObjectTodosTiposBloqueioContaCartao = selectObjectAllIds;
export const selectTodosTiposBloqueioContaCartao = selectAll;
export const selectTiposBloqueioContaCartaoByFilters = selectManyByFilters;
export const selectTotalTiposBloqueioContaCartaoByFilters = selectTotalByFilters;
export const selectTiposBloqueioContaCartaoLoadingStateByFilters = selectLoadingStateByFilters;

export const tiposBloqueioContaCartao = reducerFactory({ context });

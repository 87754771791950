import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import { useEffect, useState } from 'react';
import { selectSessionUsuario } from '../../pages/session-usuario/session-usuario.redux';
import { useAppSelector } from '../../store/hooks-redux';

export type RbacElementProps = {
  acoesPermissao: AcaoPermissaoPapelUsuarioEnum | AcaoPermissaoPapelUsuarioEnum[];
  children: React.ReactNode;
  componentToRender?: JSX.Element;
};

const RbacElement: React.FC<RbacElementProps> = ({ acoesPermissao, children, componentToRender }) => {
  const useSelector = useAppSelector;

  const [sessionUsuario] = useSelector((state) => selectSessionUsuario(state, {})) ?? [];
  const [possuiPermissao, setPossuiPermissao] = useState<boolean>();

  useEffect(() => {
    if (!acoesPermissao || !acoesPermissao?.length) {
      return setPossuiPermissao(true);
    }

    if (sessionUsuario) {
      const usuarioPossuiAcessoAAcaoPermissao = Object.entries(sessionUsuario?.papelUsuario?.permissoes || {}).some(
        ([acao, acesso]) =>
          acesso &&
          (Array.isArray(acoesPermissao)
            ? acoesPermissao.includes(acao as AcaoPermissaoPapelUsuarioEnum)
            : (acao as AcaoPermissaoPapelUsuarioEnum) === acoesPermissao)
      );

      return setPossuiPermissao(usuarioPossuiAcessoAAcaoPermissao);
    }

    return setPossuiPermissao(false);
  }, [acoesPermissao, sessionUsuario, sessionUsuario?.papelUsuario?.permissoes]);

  if (possuiPermissao === undefined) {
    return <></>;
  }

  if (!possuiPermissao) {
    return componentToRender ? componentToRender : <></>;
  }

  return <>{children}</>;
};

export default RbacElement;

import { useEffect } from 'react';
import BSButton from 'react-bootstrap/Button';
import BSTab from 'react-bootstrap/Tab';
import BSTabs from 'react-bootstrap/Tabs';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import { DetailCard } from '../../components/details/datail-card';
import DetailSubTitle from '../../components/details/detail-subtitle';
import DetailTitle from '../../components/details/detail-title';
import { Form } from '../../components/form/form';
import { FormCheck } from '../../components/form/form-check';
import { FormControl, MasksFormControlEnum } from '../../components/form/form-control';
import { FormSelect } from '../../components/form/form-select';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import { ClassTypesEnum } from '../../helpers';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import {
  loadTiposBloqueiosCartao,
  selectTodosTiposBloqueioCartao,
} from '../tipos-bloqueio-cartao/tipos-bloqueio-cartao.redux';
import { loadEmissor, selectEmissor, updateEmissor } from './emissor.redux';

type AtualizarEmissorFormFields = {
  codigo: string;
  codigoProcessadora: string;
  nome: string;
  endereco: string;
  cidade: string;
  cnpj: string;
  cnpjProcessadora: string;
  aliquotaIofAdicional: number;
  aliquotaIofDiario: number;
  aliquotaIofSaqueCredito: number;
  aliquotaMaximaIofDiarioAcumulado: number;
  bloqueioInicialCartao: string;
  aliquotaIofInternacional: number;
  uf: string;
  codigoAplicacao: string;
  filial: string;
  codigoEmpresa: string;
  geraLancamentosContabeisDetalhados: boolean;
  estornarIof: boolean;
  estornarIofExterior: boolean;
  percentualSpreadCotacaoDolar: number;
  validadeCertificadoSeguranca: string;
  limiteErrosSenhaDiario: number;
  limiteErrosCVCDiario: number;
  codificacaoQuebraLinha: string;
  numeroEmissorMastercard: string;
  zerarContadorAposSenhaCorreta: boolean;
  desbloqueioAutomaticoCartaoPorSenhaIncorreta: boolean;
  desbloqueioAutomaticoCartaoPorCVCIncorreto: boolean;
  gerarNovaDataVencimentoSegundaViaCartao: boolean;
  percentualMultaAtraso: number;
  taxaJurosMoraDiario: number;
  prazoRenovacaoCartaoEmDias: number;
  nomeResponsavelContabil: string;
  emailResponsavelContabil: string;
  telefoneResponsavelContabil: string;
  cep: string;
  decred: {
    nomeResponsavelPreenchimento: string;
    cpfResponsavelPreenchimento: string;
    dddResponsavelPreenchimento: string;
    telefoneResponsavelPreenchimento: string;
    ramalResponsavelPreenchimento: string;
    nomeRepresentanteLegal: string;
    cpfRepresentanteLegal: string;
    telefoneRepresentanteLegal: string;
    ramalRepresentanteLegal: string;
    dddRepresentanteLegal: string;
  };
};

const codificacoesQuebraLinha = ['Windows', 'Unix', 'Macintoch'];

const AtualizarEmissorPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;
  const { maxItemsQuery } = useQuerystring();

  const navigate = useNavigate();
  const { showToast } = useToasts();

  const [emissor] = useSelector((state) => selectEmissor(state, {})) ?? [];
  const bloqueioInicialCartao = useSelector((state) => selectTodosTiposBloqueioCartao(state));

  const form = useForm<AtualizarEmissorFormFields>({ defaultValues: emissor });
  const { control } = form;

  const onUpdateEmissorHandler = (emissorId: string, data: any) => {
    if (!data) {
      return;
    }

    dispatch(updateEmissor({ params: { emissorId }, data }))
      .then(() => {
        showToast({
          message: 'Emissor atualizado com sucesso',
          type: ClassTypesEnum.SUCCESS,
        });

        return navigate('/emissor/');
      })
      .catch((error: Error) => {
        showToast({
          message: error.message,
          type: ClassTypesEnum.DANGER,
        });
      });
  };

  const onSubmitHandler = (data: AtualizarEmissorFormFields) => {
    onUpdateEmissorHandler(emissor._id, data);
  };

  useEffect(() => {
    dispatch(loadEmissor({})).catch((error: Error) => error);
    dispatch(loadTiposBloqueiosCartao({ query: maxItemsQuery })).catch((error: Error) => error);
  }, [dispatch, maxItemsQuery]);

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.ALTERACAO_EMISSOR}>
      <div className="mb-5">
        <DetailCard>
          <div className="mb-5">
            <DetailTitle>Atualizar emissor: </DetailTitle>
          </div>
          <Form id="form" form={form} onSubmit={onSubmitHandler}>
            <BSTabs defaultActiveKey="dadosGerais" fill>
              <BSTab eventKey="dadosGerais" title="Dados gerais">
                <div className="row">
                  <FormControl
                    control={control}
                    className="col-xl-4 col-md-6 col-sm-12 mb-3"
                    label="Nome do emissor"
                    name="nome"
                    type="text"
                    rules={{ required: true }}
                  />

                  <FormControl
                    control={control}
                    className="col-xl-4 col-md-6 col-sm-12 mb-3"
                    label="Emissor na Mastercard"
                    name="numeroEmissorMastercard"
                    type="text"
                  />

                  <FormControl
                    control={control}
                    className="col-xl-4 col-md-6 col-sm-12 mb-3"
                    label="CNPJ"
                    name="cnpj"
                    type="text"
                    rules={{ minLength: 11, maxLength: 14 }}
                    mask={MasksFormControlEnum.CPF_CNPJ}
                  />

                  <FormControl
                    control={control}
                    className="col-xl-4 col-md-6 col-sm-12 mb-3"
                    label="CNPJ processadora"
                    name="cnpjProcessadora"
                    type="text"
                    rules={{ minLength: 11, maxLength: 14 }}
                    mask={MasksFormControlEnum.CPF_CNPJ}
                  />

                  <FormControl
                    control={control}
                    className="col-xl-4 col-md-6 col-sm-12 mb-3"
                    label="Val. certificado segurança"
                    name="validadeCertificadoSeguranca"
                    type="date"
                  />
                </div>
              </BSTab>

              <BSTab eventKey="codigos" title="Códigos">
                <div className="row">
                  <FormControl
                    control={control}
                    className="col-xl-4 col-md-6 col-sm-12 mb-3"
                    label="Emissor"
                    name="codigo"
                    type="text"
                    rules={{ required: true }}
                  />

                  <FormControl
                    control={control}
                    className="col-xl-4 col-md-6 col-sm-12 mb-3"
                    label="Empresa"
                    name="codigoEmpresa"
                    type="text"
                  />

                  <FormControl
                    control={control}
                    className="col-xl-4 col-md-6 col-sm-12 mb-3"
                    label="Aplicação"
                    name="codigoAplicacao"
                    type="text"
                  />

                  <FormControl
                    control={control}
                    className="col-xl-4 col-md-6 col-sm-12 mb-3"
                    label="Processadora"
                    name="codigoProcessadora"
                    type="text"
                  />

                  <FormControl
                    control={control}
                    className="col-xl-4 col-md-6 col-sm-12 mb-3"
                    label="Filial"
                    name="filial"
                    type="text"
                  />
                </div>
              </BSTab>

              <BSTab eventKey="parametros" title="Parâmetros">
                <div className="row">
                  <div className="mb-5">
                    <DetailSubTitle>Diários: </DetailSubTitle>
                  </div>

                  <FormControl
                    control={control}
                    className="col-xl-4 col-md-6 col-sm-12 mb-3"
                    label="Erros por senha"
                    name="limiteErrosSenhaDiario"
                    type="number"
                  />

                  <FormCheck
                    control={control}
                    className="mb-3"
                    name="zerarContadorAposSenhaCorreta"
                    label="Zerar contador após senha correta"
                    type="switch"
                  />

                  <FormControl
                    control={control}
                    className="col-xl-4 col-md-6 col-sm-12 mb-3"
                    label="Limite erros CVC diário"
                    name="limiteErrosCVCDiario"
                    type="number"
                  />

                  <div className="mb-5">
                    <DetailSubTitle>Gerais: </DetailSubTitle>
                  </div>

                  <FormSelect
                    control={control}
                    className="col-xl-4 col-md-6 col-sm-12 mb-3"
                    label="Bloqueio inicial cartão"
                    name="bloqueioInicialCartao"
                    placeholder="Selecione o bloqueio inicial do cartão"
                    options={bloqueioInicialCartao?.map((elem: any, index: any) => ({
                      key: index,
                      label: `${elem.codigo} - ${elem.descricao}`,
                      value: elem._id,
                    }))}
                  />

                  <FormControl
                    control={control}
                    className="col-xl-4 col-md-6 col-sm-12 mb-3"
                    label="Spread cotação dólar"
                    name="percentualSpreadCotacaoDolar"
                    type="number"
                  />

                  <FormControl
                    control={control}
                    className="col-xl-4 col-md-6 col-sm-12 mb-3"
                    label="Multa atraso"
                    name="percentualMultaAtraso"
                    type="number"
                  />

                  <FormControl
                    control={control}
                    className="col-xl-4 col-md-6 col-sm-12 mb-3"
                    label="Renovação cartão em dias"
                    name="prazoRenovacaoCartaoEmDias"
                    type="number"
                  />

                  <FormSelect
                    control={control}
                    className="col-xl-4 col-md-6 col-sm-12 mb-3"
                    label="Codificação quebra de linha"
                    name="codificacaoQuebraLinha"
                    placeholder="Selecione a codificação de quebra de linha"
                    options={Object.entries(codificacoesQuebraLinha).map(([key, value]) => ({
                      key,
                      label: value,
                      value: value,
                    }))}
                  />
                </div>
              </BSTab>

              <BSTab eventKey="permissoes" title="Permissões">
                <div className="row">
                  <FormCheck
                    control={control}
                    className="mb-3"
                    name="desbloqueioAutomaticoCartaoPorSenhaIncorreta"
                    label="Desbloqueio de cartão automático bloqueado por senha incorreta"
                    type="switch"
                  />

                  <FormCheck control={control} className="mb-3" name="estornarIof" label="Estornar IOF" type="switch" />

                  <FormCheck
                    control={control}
                    className="mb-3"
                    name="desbloqueioAutomaticoCartaoPorCVCIncorreto"
                    label="Desbloqueio de cartão automático bloqueado por CVC incorreto"
                    type="switch"
                  />

                  <FormCheck
                    control={control}
                    className="mb-3"
                    name="geraLancamentosContabeisDetalhados"
                    label="Gerar lançamentos contábeis detalhados"
                    type="switch"
                  />

                  <FormCheck
                    control={control}
                    className="mb-3"
                    name="gerarNovaDataVencimentoSegundaViaCartao"
                    label="Gerar nova data vencimento segunda via cartão"
                    type="switch"
                  />

                  <FormCheck
                    control={control}
                    className="mb-3"
                    name="estornarIofExterior"
                    label="Estornar IOF exterior"
                    type="switch"
                  />
                </div>
              </BSTab>

              <BSTab eventKey="endereco" title="Endereço">
                <div className="row">
                  <FormControl
                    control={control}
                    className="col-xl-4 col-md-6 col-sm-12 mb-3"
                    label="Logradouro"
                    name="endereco"
                    type="text"
                  />

                  <FormControl
                    control={control}
                    className="col-xl-4 col-md-6 col-sm-12 mb-3"
                    label="Logradouro"
                    name="endereco"
                    type="text"
                  />

                  <FormControl
                    control={control}
                    className="col-xl-4 col-md-6 col-sm-12 mb-3"
                    label="Cidade"
                    name="cidade"
                    type="text"
                  />

                  <FormControl
                    control={control}
                    className="col-xl-4 col-md-6 col-sm-12 mb-3"
                    label="UF"
                    name="uf"
                    type="text"
                  />

                  <FormControl
                    control={control}
                    className="col-xl-4 col-md-6 col-sm-12 mb-3"
                    label="CEP"
                    name="cep"
                    type="text"
                  />
                </div>
              </BSTab>

              <BSTab eventKey="contabil" title="Contábil">
                <div className="row">
                  <FormControl
                    control={control}
                    className="col-xl-4 col-md-6 col-sm-12 mb-3"
                    label="Nome do responsável"
                    name="nomeResponsavelContabil"
                    type="text"
                  />

                  <FormControl
                    control={control}
                    className="col-xl-4 col-md-6 col-sm-12 mb-3"
                    label="Email do responsável"
                    name="emailResponsavelContabil"
                    type="text"
                  />

                  <FormControl
                    control={control}
                    className="col-xl-4 col-md-6 col-sm-12 mb-3"
                    label="Telefone do responsável"
                    name="telefoneResponsavelContabil"
                    type="text"
                  />
                </div>
              </BSTab>

              <BSTab eventKey="decred" title="Decred">
                <div className="row">
                  <div className="mb-5">
                    <DetailSubTitle>Responsável pelo preenchimento arquivo decred: </DetailSubTitle>
                  </div>

                  <FormControl
                    control={control}
                    className="col-xl-4 col-md-6 col-sm-12 mb-3"
                    label="Nome"
                    name="decred.nomeResponsavelPreenchimento"
                    type="text"
                  />

                  <FormControl
                    control={control}
                    className="col-xl-4 col-md-6 col-sm-12 mb-3"
                    label="CPF"
                    name="decred.cpfResponsavelPreenchimento"
                    type="text"
                    rules={{ minLength: 11, maxLength: 14 }}
                    mask={MasksFormControlEnum.CPF_CNPJ}
                  />

                  <FormControl
                    control={control}
                    className="col-xl-4 col-md-6 col-sm-12 mb-3"
                    label="DDD"
                    name="decred.dddResponsavelPreenchimento"
                    type="text"
                  />

                  <FormControl
                    control={control}
                    className="col-xl-4 col-md-6 col-sm-12 mb-3"
                    label="Telefone"
                    name="decred.telefoneResponsavelPreenchimento"
                    type="text"
                  />

                  <FormControl
                    control={control}
                    className="col-xl-4 col-md-6 col-sm-12 mb-3"
                    label="Ramal"
                    name="decred.ramalResponsavelPreenchimento"
                    type="text"
                  />

                  <div className="mb-5">
                    <DetailSubTitle>Representante legal: </DetailSubTitle>
                  </div>

                  <FormControl
                    control={control}
                    className="col-xl-4 col-md-6 col-sm-12 mb-3"
                    label="Nome"
                    name="decred.nomeRepresentanteLegal"
                    type="text"
                  />

                  <FormControl
                    control={control}
                    className="col-xl-4 col-md-6 col-sm-12 mb-3"
                    label="CPF"
                    name="decred.cpfRepresentanteLegal"
                    type="text"
                    rules={{ minLength: 11, maxLength: 14 }}
                    mask={MasksFormControlEnum.CPF_CNPJ}
                  />

                  <FormControl
                    control={control}
                    className="col-xl-4 col-md-6 col-sm-12 mb-3"
                    label="DDD"
                    name="decred.dddRepresentanteLegal"
                    type="text"
                  />

                  <FormControl
                    control={control}
                    className="col-xl-4 col-md-6 col-sm-12 mb-3"
                    label="Telefone"
                    name="decred.telefoneRepresentanteLegal"
                    type="text"
                  />

                  <FormControl
                    control={control}
                    className="col-xl-4 col-md-6 col-sm-12 mb-3"
                    label="Ramal"
                    name="decred.ramalRepresentanteLegal"
                    type="text"
                  />
                </div>
              </BSTab>

              <BSTab eventKey="aliquotas" title="Alíquotas">
                <div className="row">
                  <FormControl
                    control={control}
                    className="col-xl-4 col-md-6 col-sm-12 mb-3"
                    label="IOF diário"
                    name="aliquotaIofDiario"
                    type="number"
                    rules={{ required: true }}
                  />

                  <FormControl
                    control={control}
                    className="col-xl-4 col-md-6 col-sm-12 mb-3"
                    label="IOF saque crédito"
                    name="aliquotaIofSaqueCredito"
                    type="number"
                  />

                  <FormControl
                    control={control}
                    className="col-xl-4 col-md-6 col-sm-12 mb-3"
                    label="IOF internacional"
                    name="aliquotaIofInternacional"
                    type="number"
                  />

                  <FormControl
                    control={control}
                    className="col-xl-4 col-md-6 col-sm-12 mb-3"
                    label="IOF adicional"
                    name="aliquotaIofAdicional"
                    type="number"
                  />

                  <FormControl
                    control={control}
                    className="col-xl-4 col-md-6 col-sm-12 mb-3"
                    label="IOF diário acumulado"
                    name="aliquotaMaximaIofDiarioAcumulado"
                    type="number"
                  />
                </div>
              </BSTab>
            </BSTabs>
            <div className="d-flex justify-content-end">
              <div className="m-2">
                <BSButton variant="light" onClick={() => window.history.back()}>
                  Cancelar
                </BSButton>
              </div>

              <div className="m-2">
                <BSButton variant="primary" type="submit" form="form">
                  Atualizar
                </BSButton>
              </div>
            </div>
          </Form>
        </DetailCard>
      </div>
    </RbacPage>
  );
};
export default AtualizarEmissorPage;

import BSButton from 'react-bootstrap/Button';

import { ButtonProps } from 'react-bootstrap/Button';
import { IconType } from 'react-icons';
import { SizesEnum } from '../../helpers';

type IconLabelButtonProps = ButtonProps & { icon: IconType };

const IconLabelButton: React.FC<IconLabelButtonProps> = ({ icon: Icon, children, ...props }) => {
  const sizeToNumber = (size?: 'sm' | 'lg' | undefined): number => {
    switch (size) {
      case SizesEnum.SMALL:
        return 10;
      default:
        return 20;
    }
  };

  return (
    <BSButton data-testid="icon-label-button" {...props}>
      <div className="d-flex align-items-center">
        <div className="d-flex align-items-center me-2">
          <Icon size={sizeToNumber(props.size)} />
        </div>
        {children}
      </div>
    </BSButton>
  );
};

export default IconLabelButton;

import { reducerFactory, actionFactory, selectorFactory } from '../../store';

const context = 'historicosAtividadesExternas';

const { loadMany, loadOne } = actionFactory(context, {
  buildUrlLoadMany: () => '/api/historicos-atividades-externas',
  buildUrlLoadOne: ({ historicoAtividadeExternaId }) =>
    `/api/historicos-atividades-externas/${historicoAtividadeExternaId}`,
});

const { selectManyByFilters, selectOneById, selectTotalByFilters, selectLoadingStateByFilters } = selectorFactory({
  context,
});

export const loadHistoricosAtividadesExternas = loadMany;
export const loadHistoricoAtividadeExterna = loadOne;

export const selectHistoricoAtividadeExternaById = selectOneById;
export const selectHistoricoAtividadeExternaByFilters = selectManyByFilters;
export const selectTotalHistoricoAtividadeExternaByFilters = selectTotalByFilters;
export const selectHistoricoAtividadeExternaLoadingStateByFilters = selectLoadingStateByFilters;

export const historicosAtividadesExternas = reducerFactory({ context });

import { useCallback, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { SmartTable } from '../../components/smart-table/smart-table';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import { ApiMultiElementResponse, FormatValueEnum, mapearERemoverElementosNulosERepetidos } from '../../helpers';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import {
  loadDoacaoCartaoFatura,
  selectDoacoesCartaoByFilters,
  selectDoacoesCartaoLoadingStateByFilters,
  selectTotalDoacoesCartaoByFilters,
} from '../doacoes-cartao/doacoes-cartao.redux';
import { loadTiposTransacao, selectObjectTodosTiposTransacao } from '../tipos-transacao/tipos-transacao.redux';

const smartColumns = ({ tiposTransacao }: { tiposTransacao: any }): SmartTableColumnProps[] => [
  {
    label: 'Data',
    attribute: 'data',
    format: FormatValueEnum.DATA,
    sortable: true,
    className: 'text-center',
  },
  {
    label: 'Descrição',
    attribute: 'descricao',
    sortable: true,
  },
  {
    label: 'Tipo de transação',
    attribute: 'tipoTransacao',
    format(tipoTransacao) {
      if (!tipoTransacao) {
        return '';
      }

      return (
        <Link onClick={(e) => e.stopPropagation()} to={`/tipos-transacao/${tipoTransacao}`}>
          {tiposTransacao[tipoTransacao]?.descricao}
        </Link>
      );
    },
  },
  {
    label: 'Valor (R$)',
    attribute: 'valor',
    format: FormatValueEnum.BRL,
    sortable: true,
    className: 'text-end',
  },
  {
    label: 'Pendente',
    attribute: 'pendente',
    format: FormatValueEnum.BOOL,
    sortable: true,
    className: 'text-center',
  },
  {
    label: 'Cancelada',
    attribute: 'cancelada',
    format: FormatValueEnum.BOOL,
    sortable: true,
    className: 'text-center',
  },
];

type DoacoesCartaoTabProps = {
  faturaId: string;
};

const DoacoesCartaoFaturaTab: React.FC<DoacoesCartaoTabProps> = ({ faturaId }) => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const navigate = useNavigate();
  const { query, maxItemsQuery } = useQuerystring();

  const filters = useMemo(() => ({ ...query, faturaId }), [faturaId, query]);
  const total = useSelector((state) => selectTotalDoacoesCartaoByFilters(state, filters));
  const doacoesCartaoFatura = useSelector((state) => selectDoacoesCartaoByFilters(state, filters));
  const loadingState = useSelector((state) => selectDoacoesCartaoLoadingStateByFilters(state, filters));
  const tiposTransacao = useSelector((state) => selectObjectTodosTiposTransacao(state));

  const _loadTiposTransacao = useCallback(
    (doacoesCartao: any[]) => {
      const tiposTransacao = mapearERemoverElementosNulosERepetidos(doacoesCartao, 'tipoTransacao');

      dispatch(loadTiposTransacao({ query: { _id: { in: tiposTransacao }, ...maxItemsQuery } })).catch(
        (error: Error) => error
      );
    },
    [dispatch, maxItemsQuery]
  );

  const loadItems = useCallback(
    () =>
      dispatch(loadDoacaoCartaoFatura({ params: { faturaId }, query }))
        .then(({ payload: { data } }: ApiMultiElementResponse) => _loadTiposTransacao(data))
        .catch((error: Error) => error),
    [dispatch, faturaId, query, _loadTiposTransacao]
  );

  return (
    <SmartTable
      emptyMessage="Nenhuma doação encontrada"
      errorMessage="Erro ao listar doações"
      loadItems={loadItems}
      usePagination={true}
      size={total}
      columns={smartColumns({ tiposTransacao })}
      items={doacoesCartaoFatura}
      loadingState={loadingState}
      onRowClick={(doc) => navigate(`/doacoes-cartao/${doc._id}/`)}
    />
  );
};

export default DoacoesCartaoFaturaTab;

import { Outlet } from 'react-router-dom';
import AppContent from './app-content';
import AppFooter from './app-footer';
import AppHeader from './app-header';

const AppLayout: React.FC = () => {
  return (
    <>
      <AppHeader />
      <AppContent>
        <Outlet />
      </AppContent>
      <AppFooter />
    </>
  );
};

export default AppLayout;

import { useCallback, useEffect, useState } from 'react';
import BSButton from 'react-bootstrap/Button';
import BSCard from 'react-bootstrap/Card';
import BSListGroup from 'react-bootstrap/ListGroup';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { DetailCard } from '../../components/details/datail-card';
import DetailElement from '../../components/details/detail-element';
import DetailSubTitle from '../../components/details/detail-subtitle';
import DetailTitle from '../../components/details/detail-title';
import ConfirmationDialog from '../../components/dialog/confirmation-dialog';
import { Form } from '../../components/form/form';
import { FormControl, MasksFormControlEnum } from '../../components/form/form-control';
import Page from '../../components/page';
import {
  ApiSingleElementResponse,
  ClassTypesEnum,
  FormatValueEnum,
  PayloadDataResponseObject,
  formatBRL,
} from '../../helpers';
import { useDialog } from '../../hooks/dialog/use-dialog';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import { loadCliente, selectClienteById } from '../clientes/clientes.redux';
import { loadContaCartao, selectContaCartaoById } from '../contas-cartao/contas-cartao.redux';
import { loadFaturaAberta, loadUltimaFaturaFechada } from '../faturas/faturas.redux';
import { simularParcelamentoFatura } from './simulador-parcelamento-fatura.redux';
import { saveSolicitacaoParcelamentoFatura } from './solicitacoes-parcelamento.redux';

type ParcelaCollapseProps = {
  parcelas: number[];
};

const ParcelaCollapse: React.FC<ParcelaCollapseProps> = ({ parcelas }) => {
  const [show, setShow] = useState(false);
  const tamanhoMaximoListaParcelas = 10;

  const gruposParcelas = Array.from({
    length: Math.ceil(parcelas.length / tamanhoMaximoListaParcelas),
  }).reduce(
    (acc: number[][], _, index: number) => [
      ...acc,
      parcelas.slice(index * tamanhoMaximoListaParcelas, (index + 1) * tamanhoMaximoListaParcelas),
    ],
    []
  );

  const onClickHandler = () => {
    setShow((prev) => !prev);
  };

  return (
    <div>
      <span className="me-2">
        <strong>Parcelas:</strong>
      </span>

      <span className="text-primary" onClick={onClickHandler} role="button">
        {show ? 'Ocultar' : 'Exibir'} parcelas
      </span>

      {show && (
        <div className="d-flex mt-3">
          {gruposParcelas.map((grupo: number[], index: number) => (
            <BSListGroup key={index} className="mx-2">
              {grupo.map((valorParcela: number, indexParcela: number) => (
                <BSListGroup.Item key={indexParcela + index}>
                  <strong>{indexParcela + index * tamanhoMaximoListaParcelas + 1}º:</strong> {formatBRL(valorParcela)}
                </BSListGroup.Item>
              ))}
            </BSListGroup>
          ))}
        </div>
      )}
    </div>
  );
};

type SimulacaoParcelamentoFaturaFormFields = {
  valorEntrada: string;
};

const SimulacaoParcelamentoFaturaPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const params = useParams();
  const contaCartaoId = params.contaCartaoId as string;

  const { showToast } = useToasts();
  const { showDialog, closeDialog } = useDialog();

  const form = useForm<SimulacaoParcelamentoFaturaFormFields>();
  const { control, reset } = form;

  const [opcoes, setOpcoes] = useState<any[]>([]);
  const [faturaFechada, setFaturaFechada] = useState<any>({});
  const [faturaAberta, setFaturaAberta] = useState<any>({});

  const _loadEntidadesRelacionadas = useCallback(
    (contaCartao: any) => {
      dispatch(loadCliente({ clienteId: contaCartao.cliente })).catch((error: Error) => error);
      dispatch(loadFaturaAberta({ contaCartaoId: contaCartao._id }))
        .then(({ payload: { data } }: ApiSingleElementResponse) => setFaturaAberta(data))
        .catch((error: Error) => error);
      dispatch(loadUltimaFaturaFechada({ contaCartaoId: contaCartao._id }))
        .then(({ payload: { data } }: ApiSingleElementResponse) => setFaturaFechada(data))
        .catch((error: Error) => error);
    },
    [dispatch]
  );

  const _loadEntidades = useCallback(
    () =>
      dispatch(loadContaCartao({ contaCartaoId }))
        .then(({ payload: { data } }: ApiSingleElementResponse) => _loadEntidadesRelacionadas(data))
        .catch((error: Error) => error),
    [_loadEntidadesRelacionadas, contaCartaoId, dispatch]
  );

  useEffect(() => {
    _loadEntidades();
  }, [_loadEntidades]);

  const contaCartao = useSelector((state) => selectContaCartaoById(state, contaCartaoId));
  const cliente = useSelector((state) => selectClienteById(state, contaCartao?.cliente));

  const onSubmitHandler = (data: any) => {
    const { valorEntrada } = data;

    dispatch(simularParcelamentoFatura({ params: { contaCartaoId }, data: { valorEntrada } }))
      .then(({ payload: { data } }: ApiSingleElementResponse) => {
        const { opcoes } = data;

        return setOpcoes(opcoes as PayloadDataResponseObject[]);
      })
      .catch((error: Error) => {
        showToast({ message: error.message, type: ClassTypesEnum.DANGER });
      });
  };

  const onCancelHandler = () => {
    setOpcoes([]);
    reset();
  };

  const valorBaseParcelamento =
    faturaFechada.valorTotal - faturaFechada.valorEmDisputa - faturaAberta.valorSubtotalCreditos;

  const onSolicitacaoConfrmationHandler = useCallback(
    (opcaoParcelamento: any) => {
      const {
        valorEntrada,
        parcelas,
        numeroParcelas,
        taxaJuros: taxaMensalJuros,
        valorTotal: valorTotalParcelamento,
      } = opcaoParcelamento;
      const [valorPrimeiraParcela, valorSegundaParcela] = parcelas;

      const dadosSolicitacaoParcelamento = {
        contaCartao: contaCartaoId,
        numeroParcelas: numeroParcelas,
        valorEntrada,
        valorPrimeiraParcela,
        valorSegundaParcela,
        taxaMensalJuros,
        valorBaseParcelamento,
        valorTotalParcelamento,
      };

      return dispatch(
        saveSolicitacaoParcelamentoFatura({
          params: { faturaId: faturaFechada._id },
          data: dadosSolicitacaoParcelamento,
        })
      )
        .then(() => {
          showToast({
            message: 'Solicitação de parcelamento efetuada com sucesso',
            type: ClassTypesEnum.SUCCESS,
          });

          window.history.back();
        })
        .catch((error: Error) => {
          showToast({ message: error.message, type: ClassTypesEnum.DANGER });
        });
    },
    [contaCartaoId, dispatch, faturaFechada._id, showToast, valorBaseParcelamento]
  );

  return (
    <Page>
      <div className="mb-5">
        <DetailCard>
          <div className="mb-5">
            <DetailTitle>Simulação de parcelamento de fatura</DetailTitle>
          </div>

          <div className="row mb-4">
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="mb-3">
                <DetailSubTitle>Conta</DetailSubTitle>
              </div>

              <DetailElement descricao="Matricula" valor={contaCartao?.matricula} />
              <DetailElement descricao="Conta" valor={contaCartao?.numero} format={FormatValueEnum.DATA} />
              <DetailElement descricao="Cliente" valor={cliente?.nome} />
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <div className="mb-3">
                <DetailSubTitle>Fatura fechada</DetailSubTitle>
              </div>

              <DetailElement
                descricao="Data de início"
                valor={faturaFechada?.dataInicioPeriodo}
                format={FormatValueEnum.DATA}
              />
              <DetailElement descricao="Data de corte" valor={faturaFechada?.dataCorte} format={FormatValueEnum.DATA} />
              <DetailElement
                descricao="Data de vencimento"
                valor={faturaFechada?.dataVencimento}
                format={FormatValueEnum.DATA}
              />
              <DetailElement descricao="Valor total" valor={faturaFechada?.valorTotal} format={FormatValueEnum.BRL} />
              <DetailElement
                descricao="Valor pagamento mínimo"
                valor={faturaFechada?.valorPagamentoMinimo}
                format={FormatValueEnum.BRL}
              />
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <div className="mb-3">
                <DetailSubTitle>Fatura aberta</DetailSubTitle>
              </div>

              <DetailElement
                descricao="Data de início"
                valor={faturaAberta?.dataInicioPeriodo}
                format={FormatValueEnum.DATA}
              />
              <DetailElement
                descricao="Data de corte"
                valor={faturaAberta?.valorSubtotalCreditos}
                format={FormatValueEnum.BRL}
              />
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <div className="mb-3">
                <DetailSubTitle>Simular parcelamento</DetailSubTitle>
              </div>

              <DetailElement
                descricao="Valor base para parcelamento"
                valor={valorBaseParcelamento}
                format={FormatValueEnum.BRL}
              />
              <DetailElement
                descricao="Valor mínimo da entrada"
                valor={faturaFechada.valorEntradaMinimoParcelamentoFatura}
                format={FormatValueEnum.BRL}
              />

              <Form form={form} onSubmit={(data) => onSubmitHandler(data)}>
                <div className="mb-2 d-inline-flex flex-column mb-3">
                  <FormControl
                    control={control}
                    name="valorEntrada"
                    label="Valor da entrada"
                    rules={{ required: true }}
                    mask={MasksFormControlEnum.BRL}
                  />
                </div>

                <div className="d-flex">
                  <BSButton className="mx-1" variant="light" onClick={onCancelHandler}>
                    Cancelar
                  </BSButton>
                  <BSButton className="mx-1 px-4" variant="primary" type="submit">
                    Simular
                  </BSButton>
                </div>
              </Form>
            </div>
          </div>

          <div className="row">
            {opcoes?.map((opcao: any, index: number) => {
              return (
                <div key={index} className="col-xl-6 col-lg-12 mb-4">
                  <BSCard className="p-5">
                    <div className="mb-4">
                      <DetailSubTitle>
                        Opção {index + 1} - Entrada de {formatBRL(opcao.valorEntrada)} + 1 de{' '}
                        {formatBRL(opcao.parcelas[0])} + {opcao.numeroParcelas - 1}x de {formatBRL(opcao.parcelas[1])}
                      </DetailSubTitle>
                    </div>

                    <div>
                      <DetailElement
                        descricao="Valor total pago"
                        valor={opcao.valorTotal}
                        format={FormatValueEnum.BRL}
                      />
                      <DetailElement
                        descricao="Valor de entrada"
                        valor={opcao.valorEntrada}
                        format={FormatValueEnum.BRL}
                      />
                      <DetailElement
                        descricao="Valor parcelado"
                        valor={opcao.valorParcelado}
                        format={FormatValueEnum.BRL}
                      />
                      <DetailElement descricao="Valor IOF" valor={opcao.valorIof} format={FormatValueEnum.BRL} />
                      <DetailElement
                        descricao="Valor IOF adicional"
                        valor={opcao.valorIofAdicional}
                        format={FormatValueEnum.BRL}
                      />
                      <DetailElement descricao="Número de parcelas" valor={opcao.numeroParcelas} />
                      <DetailElement
                        descricao="Taxa de juros"
                        valor={opcao.taxaJuros}
                        format={FormatValueEnum.PERCENT}
                      />

                      <div className="mb-4">
                        <ParcelaCollapse parcelas={opcao.parcelas} />
                      </div>

                      <BSButton
                        variant="primary"
                        onClick={() => {
                          const message = (
                            <span>
                              Tem certeza que deseja selecionar a <strong>Opção {index + 1}</strong> para parcelamento
                              da fatura?
                            </span>
                          );

                          showDialog({
                            component: (
                              <ConfirmationDialog
                                onConfirmation={() => onSolicitacaoConfrmationHandler(opcao)}
                                onHide={closeDialog}
                                confirmationLabel="Realizar solicitação de parcelamento"
                                message={message}
                              />
                            ),
                          });
                        }}
                      >
                        Confirmar solicitação de parcelamento
                      </BSButton>
                    </div>
                  </BSCard>
                </div>
              );
            })}
          </div>
        </DetailCard>
      </div>
    </Page>
  );
};

export default SimulacaoParcelamentoFaturaPage;

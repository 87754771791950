import { useCallback, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { SmartTable } from '../../components/smart-table/smart-table';
import SmartTableFilters from '../../components/smart-table/smart-table-filters/smart-table-filters';
import {
  SmartTableFilterControlTypesEnum,
  SmartTableFilterProps,
} from '../../components/smart-table/smart-table-filters/table-filter.types';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import {
  ApiMultiElementResponse,
  FormatValueEnum,
  mapearERemoverElementosNulosERepetidos,
  naturezaTransacaoLabelMap,
} from '../../helpers';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import { loadTiposTransacao, selectObjectTodosTiposTransacao } from '../tipos-transacao/tipos-transacao.redux';
import {
  loadTransacoesFatura,
  selectTotalTransacoesByFilters,
  selectTransacoesByFilters,
  selectTransacoesLoadingStateByFilters,
} from '../transacoes/transacoes.redux';

const smartColumns = ({ tiposTransacao }: { tiposTransacao: any }): SmartTableColumnProps[] => [
  {
    label: 'Data transação',
    attribute: 'dataHoraTransacao',
    sortable: true,
    className: 'text-center',
    format: FormatValueEnum.DATE_TIME,
  },
  {
    label: 'Descrição',
    attribute: 'descricao',
    className: 'text-center',
  },
  {
    label: 'Estabelecimento',
    attribute: 'nomeEstabelecimento',
    className: 'text-center',
  },
  {
    label: 'Natureza',
    attribute: 'natureza',
    sortable: true,
    className: 'text-center',
    format: FormatValueEnum.ENUM,
    map: naturezaTransacaoLabelMap,
  },
  {
    label: 'Tipo de transação',
    attribute: 'tipoTransacao',
    className: 'text-center',
    format(tipoTransacao: keyof typeof tiposTransacao) {
      if (!tipoTransacao) {
        return '';
      }

      return (
        <Link onClick={(e) => e.stopPropagation()} to={`/tipos-transacao/${tipoTransacao.toString()}`}>
          {tiposTransacao[tipoTransacao]?.descricao}
        </Link>
      );
    },
  },
  {
    label: 'Valor (R$)',
    attribute: 'valor',
    sortable: true,
    className: 'text-end',
    format: FormatValueEnum.BRL,
  },
];
const smartFilters = (): SmartTableFilterProps[] => [
  {
    label: 'Final do cartão',
    attribute: 'cartao',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
  },
];

type TransacoesFaturaTabProps = {
  faturaId: string;
};

const TransacoesFaturaTab: React.FC<TransacoesFaturaTabProps> = ({ faturaId }) => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const navigate = useNavigate();
  const { query, maxItemsQuery } = useQuerystring();

  if (!query.sort) {
    query.sort = 'dataHoraTransacao';
  }

  const filter = useMemo(() => ({ ...query, faturaId }), [faturaId, query]);
  const total = useSelector((state) => selectTotalTransacoesByFilters(state, filter));
  const transacoes = useSelector((state) => selectTransacoesByFilters(state, filter));
  const loadingState = useSelector((state) => selectTransacoesLoadingStateByFilters(state, filter));

  const tiposTransacao = useSelector((state) => selectObjectTodosTiposTransacao(state));

  const loadDetalhesTransacoes = useCallback(
    (transacoes: any[]) => {
      const tiposTransacao = mapearERemoverElementosNulosERepetidos(transacoes, 'tipoTransacao');

      dispatch(loadTiposTransacao({ query: { _id: { in: tiposTransacao }, ...maxItemsQuery } })).catch(
        (error: Error) => error
      );
    },
    [dispatch, maxItemsQuery]
  );

  const loadItems = useCallback(
    () =>
      dispatch(loadTransacoesFatura({ params: { faturaId }, query }))
        .then(({ payload: { data } }: ApiMultiElementResponse) => loadDetalhesTransacoes(data))
        .catch((error: Error) => error),
    [dispatch, faturaId, loadDetalhesTransacoes, query]
  );

  return (
    <div className="row">
      <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-12 mb-4">
        <SmartTableFilters filters={smartFilters()} />
      </div>
      <div className="col-xxl-10 col-xl-9 col-lg-8 col-md-12 mb-4">
        <SmartTable
          emptyMessage="Nenhuma transação encontrada"
          errorMessage="Erro ao listar transações"
          loadItems={loadItems}
          usePagination={true}
          size={total}
          columns={smartColumns({ tiposTransacao })}
          items={transacoes}
          loadingState={loadingState}
          onRowClick={(doc) => navigate(`transacoes/${doc._id}`)}
        />
      </div>
    </div>
  );
};

export default TransacoesFaturaTab;

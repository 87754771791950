import { useCallback, useMemo } from 'react';
import { BsShieldPlus, BsShieldX } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import IconButton from '../../components/button/icon-button';
import ConfirmationDialog from '../../components/dialog/confirmation-dialog';
import InfoDialog from '../../components/dialog/info-dialog';
import { SmartTable } from '../../components/smart-table/smart-table';
import SmartTableFilters from '../../components/smart-table/smart-table-filters/smart-table-filters';
import {
  SmartTableFilterControlTypesEnum,
  SmartTableFilterProps,
} from '../../components/smart-table/smart-table-filters/table-filter.types';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import { ClassTypesEnum, FormatValueEnum, sexoLabelMap, tipoCartaoLabelMap } from '../../helpers';
import { DialogProps } from '../../hooks/dialog/dialog-context';
import { useDialog } from '../../hooks/dialog/use-dialog';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import {
  loadCartoesContaCartao,
  selectCartaoLoadingStateByFilters,
  selectCartoesByFilters,
  selectTotalCartoesByFilters,
  updateBloqueioCartao,
  updateDesbloqueioCartao,
} from '../cartoes/cartoes.redux';
import IncluirBloqueioCartaoDialog from '../cartoes/incluir-bloqueio-cartao-dialog';
import {
  loadTiposBloqueiosCartao,
  selectTiposBloqueioCartaoByFilters,
} from '../tipos-bloqueio-cartao/tipos-bloqueio-cartao.redux';

const smartColumns = ({
  tiposBloqueioCartaoIdsEnum,
  showDialog,
  closeDialog,
  onBloqueioHandler,
  onDesbloqueioHandler,
}: {
  tiposBloqueioCartaoIdsEnum: any[];
  showDialog: (dialogProps: DialogProps) => void;
  closeDialog: () => void;
  onBloqueioHandler: (cartaoId: string, data: any) => void;
  onDesbloqueioHandler: (cartaoId: string) => void;
}): SmartTableColumnProps[] => [
  {
    label: 'Número',
    attribute: 'numeroTruncado',
    format: FormatValueEnum.CARTAO,
    className: 'text-center align-middle',
  },
  {
    label: 'Tipo',
    attribute: 'tipoCartao',
    format: FormatValueEnum.ENUM,
    map: tipoCartaoLabelMap,
    className: 'text-center align-middle',
    sortable: true,
  },
  {
    label: 'Vencimento',
    attribute: 'dataVencimento',
    format: FormatValueEnum.DATA,
    className: 'text-center align-middle',
  },
  {
    label: 'CPF',
    attribute: 'portadorCpf',
    format: FormatValueEnum.CPF_CNPJ,
    className: 'text-center align-middle',
  },
  {
    label: 'Sexo',
    attribute: 'portadorSexo',
    format: FormatValueEnum.ENUM,
    map: sexoLabelMap,
    className: 'text-center align-middle',
  },
  {
    label: 'Data de nascimento',
    attribute: 'portadorDataNascimento',
    format: FormatValueEnum.DATA,
    className: 'text-center align-middle',
  },
  {
    label: 'Bloqueio',
    attribute: 'tipoBloqueio',
    className: 'text-center align-middle',
    format: (tipoBloqueio: any) => {
      if (!tipoBloqueio) {
        return '-';
      }
      const bloqueio = tiposBloqueioCartaoIdsEnum[tipoBloqueio];

      return bloqueio ? (
        <Link onClick={(e: React.MouseEvent) => e.stopPropagation()} to={`/tipos-bloqueio-cartao/${tipoBloqueio}`}>
          {`${bloqueio}`}
        </Link>
      ) : (
        <></>
      );
    },
  },
  {
    label: 'Ações',
    format: (_, cartao) => {
      const cartaoPossuiSegundaVia = cartao?.substituidoPor;
      const cartaoPossuiBloqueio = cartao?.tipoBloqueio;

      const selectComponent = ({
        cartaoPossuiBloqueio,
        cartaoPossuiSegundaVia,
      }: {
        cartaoPossuiBloqueio: boolean;
        cartaoPossuiSegundaVia: boolean;
      }) => {
        if (cartaoPossuiBloqueio && cartaoPossuiSegundaVia) {
          return (
            <InfoDialog
              message="Não é possível remover bloqueios de cartões que possuem segunda-via"
              onHide={closeDialog}
            />
          );
        }

        if (cartaoPossuiBloqueio) {
          return (
            <ConfirmationDialog
              onConfirmation={() => onDesbloqueioHandler(cartao._id)}
              message="Tem certeza que deseja remover o bloqueio do cartão?"
              confirmationLabel="Remover bloqueio"
              title="Remover bloqueio"
              onHide={closeDialog}
            />
          );
        }

        return (
          <IncluirBloqueioCartaoDialog cartao={cartao} closeDialog={closeDialog} onUpsertHandler={onBloqueioHandler} />
        );
      };

      return (
        <div className="d-flex justify-content-center">
          <IconButton
            Icon={cartaoPossuiBloqueio ? BsShieldX : BsShieldPlus}
            type={cartaoPossuiBloqueio && ClassTypesEnum.DANGER}
            onClick={() => {
              showDialog({
                component: selectComponent({
                  cartaoPossuiBloqueio,
                  cartaoPossuiSegundaVia,
                }),
              });
            }}
          />
        </div>
      );
    },
  },
];

const smartFilters = ({ tiposBloqueioCartaoIdsEnum }: { tiposBloqueioCartaoIdsEnum: any }): SmartTableFilterProps[] => [
  {
    attribute: 'tipoCartao',
    label: 'Tipo de cartão',
    controlType: SmartTableFilterControlTypesEnum.SELECT,
    map: tipoCartaoLabelMap,
  },
  {
    attribute: 'tipoBloqueio',
    label: 'Tipo de bloqueio',
    controlType: SmartTableFilterControlTypesEnum.SELECT,
    map: tiposBloqueioCartaoIdsEnum,
  },
];

type CartoesContaCartaoTabProps = {
  contaCartaoId: string;
};

export const CartoesContaCartaoTab: React.FC<CartoesContaCartaoTabProps> = ({ contaCartaoId }) => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const navigate = useNavigate();
  const { query, maxItemsQuery } = useQuerystring();
  const { showToast } = useToasts();
  const { showDialog, closeDialog } = useDialog();

  if (!query.sort) {
    query.sort = '-dataVencimento';
  }

  const filters = useMemo(() => ({ contaCartaoId, ...query }), [contaCartaoId, query]);
  const total = useSelector((state) => selectTotalCartoesByFilters(state, filters));
  const cartoes = useSelector((state) => selectCartoesByFilters(state, filters));
  const loadingState = useSelector((state) => selectCartaoLoadingStateByFilters(state, filters));
  const tiposBloqueioCartao = useSelector((state) => selectTiposBloqueioCartaoByFilters(state, maxItemsQuery));

  const tiposBloqueioCartaoIdsEnum = (tiposBloqueioCartao || []).reduce(
    (obj: any, tipoBloqueioCartao: any) => ({
      ...obj,
      [tipoBloqueioCartao._id]: tipoBloqueioCartao.descricao,
    }),
    {}
  );

  const onBloqueioHandler = (cartaoId: string, data: any) => {
    if (!data) {
      return;
    }

    return dispatch(updateBloqueioCartao({ params: { cartaoId }, data }))
      .then(() => {
        showToast({
          message: 'Bloqueio alterado com sucesso',
          type: ClassTypesEnum.SUCCESS,
        });

        closeDialog();

        return loadItems();
      })
      .catch((error: Error) => {
        showToast({
          message: error.message,
          type: ClassTypesEnum.DANGER,
        });
      });
  };

  const onDesbloqueioHandler = (cartaoId: string) => {
    return dispatch(updateDesbloqueioCartao({ params: { cartaoId }, data: {} }))
      .then(() => {
        showToast({
          message: 'Desbloqueio com sucesso',
          type: ClassTypesEnum.SUCCESS,
        });

        closeDialog();

        return loadItems();
      })
      .catch((error: Error) => {
        showToast({
          message: error.message,
          type: ClassTypesEnum.DANGER,
        });
      });
  };

  const loadItems = useCallback(() => {
    dispatch(loadCartoesContaCartao({ params: { contaCartaoId }, query })).catch((error: Error) => error);
    dispatch(loadTiposBloqueiosCartao({ query: maxItemsQuery })).catch((error: Error) => error);
  }, [contaCartaoId, dispatch, maxItemsQuery, query]);

  return (
    <>
      <div className="row">
        <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-12 mb-4">
          <SmartTableFilters filters={smartFilters({ tiposBloqueioCartaoIdsEnum })} />
        </div>

        <div className="col-xxl-10 col-xl-9 col-lg-8 col-md-12 mb-4">
          <SmartTable
            emptyMessage="Nenhum cartão encontrado"
            errorMessage="Erro ao listar cartões"
            size={total}
            columns={smartColumns({
              tiposBloqueioCartaoIdsEnum,
              showDialog,
              closeDialog,
              onBloqueioHandler,
              onDesbloqueioHandler,
            })}
            items={cartoes}
            loadItems={loadItems}
            loadingState={loadingState}
            usePagination={true}
            onRowClick={(doc) => navigate(`/contas-cartao/${doc.contaCartao}/cartoes/${doc._id}`)}
          />
        </div>
      </div>
    </>
  );
};

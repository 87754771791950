import qs from 'qs';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useQuerystring = () => {
  const [searchParams] = useSearchParams();

  const tab = searchParams.get('_tab');

  const maxItemsQuery = useMemo(() => ({ page: '1', pageSize: '1000' }), []);

  const query = useMemo(() => {
    searchParams.delete('_tab');

    return qs.parse(searchParams.toString());
  }, [searchParams]);

  return { query, tab, maxItemsQuery };
};

import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { SmartTable } from '../../components/smart-table/smart-table';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import { FormatValueEnum, statusSolicitacaoParcelamentoLabelMap } from '../../helpers';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import {
  loadSolicitacoesParcelamentoPorFatura,
  selectSolicitacoesParcelamentoByFilters,
  selectSolicitacoesParcelamentoLoadingStateByFilters,
  selectTotalSolicitacoesParcelamentoByFilters,
} from '../solicitacoes-parcelamento/solicitacoes-parcelamento.redux';

const smartColumns = (): SmartTableColumnProps[] => [
  {
    label: 'Data da solicitação',
    attribute: 'dataSolicitacao',
    format: FormatValueEnum.DATE_TIME,
    sortable: true,
    className: 'text-center',
  },
  {
    label: 'Número de parcelas',
    attribute: 'numeroParcelas',
    sortable: true,
    className: 'text-center',
  },
  {
    label: 'Valor parcelado',
    attribute: 'valorBaseParcelamento',
    format: FormatValueEnum.BRL,
    sortable: true,
    className: 'text-center',
  },
  {
    label: 'Total parcelamento',
    attribute: 'valorTotalParcelamento',
    format: FormatValueEnum.BRL,
    sortable: true,
    className: 'text-center',
  },
  {
    label: 'Status',
    attribute: 'status',
    format: FormatValueEnum.ENUM,
    map: statusSolicitacaoParcelamentoLabelMap,
    sortable: true,
    className: 'text-start',
  },
];

type SolicitacoesParcelamentoTabProps = {
  faturaId: string;
};

const SolicitacoesParcelamentoTab: React.FC<SolicitacoesParcelamentoTabProps> = ({ faturaId }) => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const navigate = useNavigate();
  const { query } = useQuerystring();

  if (!query.sort) {
    query.sort = '-dataSolicitacao';
  }

  const filters = useMemo(() => ({ ...query, faturaId }), [faturaId, query]);
  const loadingState = useSelector((state) => selectSolicitacoesParcelamentoLoadingStateByFilters(state, filters));
  const solicitacoesParcelamento = useSelector((state) => selectSolicitacoesParcelamentoByFilters(state, filters));
  const total = useSelector((state) => selectTotalSolicitacoesParcelamentoByFilters(state, filters));

  const loadItems = useCallback(() => {
    dispatch(loadSolicitacoesParcelamentoPorFatura({ params: { faturaId }, query })).catch((error: Error) => error);
  }, [dispatch, faturaId, query]);

  return (
    <SmartTable
      emptyMessage="Nenhuma solicitação de parcelamento encontrada"
      errorMessage="Erro ao listar solicitações de parcelamento"
      loadItems={loadItems}
      usePagination={true}
      size={total}
      columns={smartColumns()}
      items={solicitacoesParcelamento}
      loadingState={loadingState}
      onRowClick={(doc) => navigate(`solicitacoes-parcelamento/${doc._id}`)}
    />
  );
};

export default SolicitacoesParcelamentoTab;

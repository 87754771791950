import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import { useCallback, useMemo } from 'react';
import BSCard from 'react-bootstrap/Card';
import RbacElement from '../../components/role-based-access-control/role-based-access-control-element';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import { SmartTable } from '../../components/smart-table/smart-table';
import SmartTableButton from '../../components/smart-table/smart-table-button';
import SmartTableFilters from '../../components/smart-table/smart-table-filters/smart-table-filters';
import {
  SmartTableFilterControlTypesEnum,
  SmartTableFilterProps,
} from '../../components/smart-table/smart-table-filters/table-filter.types';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import Title from '../../components/title';
import { FormatValueEnum } from '../../helpers';
import { useDialog } from '../../hooks/dialog/use-dialog';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import CadastrarTiposSolicitacaoClienteDialog from '../tipos-solicitacao-cliente/cadastrar-tipos-solicitacao-cliente-dialog';
import {
  loadTiposSolicitacoesClientes,
  selectTipoSolicitacaoClienteLoadingStateByFilters,
  selectTiposSolicitacaoClienteByFilters,
  selectTotalTiposSolicitacaoClienteByFilters,
} from './tipo-solicitacao-cliente.redux';

const smartFilters: SmartTableFilterProps[] = [
  {
    label: 'Código',
    attribute: 'codigo',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
  },
  {
    label: 'Descrição',
    attribute: 'descricao',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
  },
];

const smartColumns = (): SmartTableColumnProps[] => [
  {
    label: 'Código',
    attribute: 'codigo',
    className: 'text-center',
    sortable: true,
  },
  {
    label: 'Descrição',
    attribute: 'descricao',
    className: 'text-center',
    sortable: true,
  },
  {
    label: 'Vinculado à cartão',
    attribute: 'vinculadoACartao',
    className: 'text-center',
    format: FormatValueEnum.BOOL,
    sortable: true,
  },
  {
    label: 'Resolução imediata',
    attribute: 'resolucaoImediata',
    className: 'text-center',
    format: FormatValueEnum.BOOL,
    sortable: true,
  },
  {
    label: 'Permite ajuste financeiro',
    attribute: 'permiteAjusteFinanceiro',
    className: 'text-center',
    format: FormatValueEnum.BOOL,
    sortable: true,
  },
  {
    label: 'Prazo para resolução em dias úteis',
    attribute: 'prazoParaResolucaoEmDiasUteis',
    className: 'text-center',
    format: (value) => value ?? '-',
    sortable: true,
  },
];

const TiposSolicitacaoClientePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const { query } = useQuerystring();
  const { closeDialog, showDialog } = useDialog();

  if (!query.sort) {
    query.sort = 'codigo';
  }

  const onNovoTipoSolicitacaoClienteHandler = () => {
    showDialog({
      component: <CadastrarTiposSolicitacaoClienteDialog closeDialog={closeDialog} reload={loadItems} />,
    });
  };

  const filters = useMemo(() => ({ ...query }), [query]);
  const total = useSelector((state) => selectTotalTiposSolicitacaoClienteByFilters(state, filters));
  const tiposSolicitacao = useSelector((state) => selectTiposSolicitacaoClienteByFilters(state, filters));
  const loadingState = useSelector((state) => selectTipoSolicitacaoClienteLoadingStateByFilters(state, filters));

  const loadItems = useCallback(() => {
    dispatch(loadTiposSolicitacoesClientes({ query: filters })).catch((error: Error) => error);
  }, [dispatch, filters]);

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_TIPO_SOLICITACAO_CLIENTE}>
      <div className="d-flex flex-column h-100">
        <Title>Tipos de solicitação do cliente</Title>

        <div className="row">
          <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-12 mb-4">
            <SmartTableFilters filters={smartFilters} />
          </div>

          <div className="col-xxl-10 col-xl-9 col-lg-8 col-md-12 mb-4">
            <BSCard>
              <BSCard.Body>
                <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.CRIACAO_TIPO_SOLICITACAO_CLIENTE}>
                  <div className="d-flex align-items-center mb-2">
                    <SmartTableButton onClick={onNovoTipoSolicitacaoClienteHandler}>
                      Novo tipo de solicitação
                    </SmartTableButton>
                  </div>
                </RbacElement>
                <SmartTable
                  emptyMessage="Nenhum tipo de solicitação do cliente encontrado"
                  errorMessage="Erro na listagem de tipos de solicitação do cliente"
                  usePagination={true}
                  loadItems={loadItems}
                  columns={smartColumns()}
                  items={tiposSolicitacao}
                  loadingState={loadingState}
                  size={total}
                />
              </BSCard.Body>
            </BSCard>
          </div>
        </div>
      </div>
    </RbacPage>
  );
};

export default TiposSolicitacaoClientePage;

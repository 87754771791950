import { Route, Routes } from 'react-router-dom';
import AtualizarEmissorPage from './atualizar-emissor-page';
import DetalhesEmissorPage from './detalhes-emissor-page';

const EmissorRoutes: React.FC = () => {
  return (
    <Routes>
      <Route index element={<DetalhesEmissorPage />} />
      <Route path="atualizar" element={<AtualizarEmissorPage />} />
    </Routes>
  );
};

export default EmissorRoutes;

import React from 'react';

const AccessDeniedTab: React.FC = () => {
  return (
    <div className="d-flex justify-content-center align-items-center my-4">
      <span>
        <strong>Você não tem permissão para acessar este recurso.</strong>
      </span>
    </div>
  );
};

export default AccessDeniedTab;

import { useCallback, useMemo } from 'react';

import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import BSCard from 'react-bootstrap/Card';
import { BsPencilSquare } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import IconButton from '../../components/button/icon-button';
import RbacElement from '../../components/role-based-access-control/role-based-access-control-element';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import ExportCSVButton from '../../components/smart-table/export-csv-button';
import { SmartTable } from '../../components/smart-table/smart-table';
import SmartTableFilters from '../../components/smart-table/smart-table-filters/smart-table-filters';
import {
  SmartTableFilterControlTypesEnum,
  SmartTableFilterProps,
} from '../../components/smart-table/smart-table-filters/table-filter.types';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import Title from '../../components/title';
import {
  ClassTypesEnum,
  boolLabelMap,
  formatEnum,
  tipoCreditoLabelMap,
  tipoProcessamentoCalendarioCorteLabelMap,
} from '../../helpers';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import {
  loadProdutos,
  selectProdutosByFilters,
  selectProdutosLoadingStateByFilters,
  selectTotalProdutosByFilters,
} from './produtos.redux';

const smartFilters: SmartTableFilterProps[] = [
  {
    attribute: 'codigo',
    label: 'Código',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
  },
  {
    attribute: 'nome',
    label: 'Nome',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
  },
  {
    attribute: 'bin',
    label: 'BIN',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
  },
  {
    attribute: 'codigoNoEmissor',
    label: 'Código no emissor',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
  },
];

const csvHeaders = [
  { label: 'Bin', key: 'bin' },
  {
    label: 'Ciclos rotativo para parcelamento automático da fatura',
    key: 'ciclosRotativoParaParcelamentoAutomaticoFatura',
  },
  { label: 'Cobrar tarifa nova via cartão?', key: 'cobrarTarifaNovaViaCartao' },
  { label: 'Cobrar tarifa de overlimit?', key: 'cobrarTarifaOverlimit' },
  { label: 'Cobrar tarifa saque crédito? ', key: 'cobrarTarifaSaqueCredito' },
  { label: 'Dia corte', key: 'diaCorte' },
  { label: 'Dia prévia', key: 'diaPrevia' },
  { label: 'Dia vencimento', key: 'diaVencimento' },
  { label: 'Dias para bloqueio creli', key: 'diasParaBloqueioCreli' },
  { label: 'Dias para bloqueio pré creli', key: 'diasParaBloqueioPreCreli' },
  { label: 'Dias para bloqueio prejuízo', key: 'diasParaBloqueioPrejuizo' },
  { label: 'Dias de tolerância para bloqueio atraso', key: 'diasToleranciaParaBloqueioAtraso' },
  { label: 'Distância corte do vencimento em dias', key: 'distanciaCorteVencimentoEmDias' },
  { label: 'Exclusivo para clientes pré aprovados?', key: 'exclusivoClientesPreAprovados' },
  { label: 'Geração automática de calendários de corte?', key: 'geracaoAutomaticaCalendariosCorte' },
  { label: 'Identidade visual', key: 'identidadeVisual' },
  { label: 'IOF parcelamento da fatura é financiável?', key: 'iofParcelamentoFaturaFinanciavel' },
  { label: 'Limite máximo de cartões adicionais por conta', key: 'limiteMaximoCartoesAdicionaisPorConta' },
  { label: 'Máximo de parcelamentos simultâneos', key: 'maximoParcelamentosSimultaneos' },
  { label: 'Meses de permanência do dia do vencimento', key: 'mesesPermanenciaDiaVencimento' },
  { label: 'Modelo de calculo para pagamento minimo', key: 'modeloCalculoPagamentoMinimo' },
  { label: 'Modelo do plástico', key: 'modeloPlastico' },
  { label: 'Nome', key: 'nome' },
  { label: 'Número máximo de faturas com atraso overlimit', key: 'numeroMaximoFaturasAtrasoOverlimit' },
  { label: 'Origem de recursos', key: 'origemRecursos' },
  { label: 'Percentual indexador', key: 'percentualIndexador' },
  { label: 'Percental limite crédito saque', key: 'percentualLimiteCreditoSaque' },
  { label: 'Percentual de pagamento mínimo', key: 'percentualPagamentoMinimo' },
  {
    label: 'Percentual de pagamento mínimo quando bloqueio é definitivo',
    key: 'percentualPagamentoMinimoBloqueioDefinitivo',
  },
  { label: 'Percentual de tolerância de saldo devido', key: 'percentualToleranciaSaldoDevido' },
  { label: 'Percentual de utilização acima do limite', key: 'percentualUtilizacaoAcimaLimite' },
  { label: 'Permite doação do arredondamento da compra?', key: 'permiteDoacaoArredondamentoCompra' },
  { label: 'Permite doação do arredondamento da fatura? ', key: 'permiteDoacaoArredondamentoFatura' },
  { label: 'Permite doação espontânea?', key: 'permiteDoacaoEspontanea' },
  { label: 'Permite doação recorrente mensal? ', key: 'permiteDoacaoRecorrenteMensal' },
  { label: 'Permite overlimit?', key: 'permiteOverlimit' },
  { label: 'Permite parcelamento automático da fatura?', key: 'permiteParcelamentoAutomaticoFatura' },
  { label: 'Permite saque autorizado?', key: 'permiteSaqueAutorizado' },
  { label: 'Permite saque bandeira?', key: 'permiteSaqueBandeira' },
  { label: 'Permite saque bandeira?', key: 'permiteSaqueBandeira' },
  { label: 'Permite saque complementar?', key: 'permiteSaqueComplementar' },
  { label: 'Prazo máximo para parcelamento da fatura', key: 'prazoMaximoParcelamentoFatura' },
  { label: 'Prazo de validade do cartão em anos', key: 'prazoValidadeCartaoEmAnos' },
  { label: 'Saldo mínimo para cobrança', key: 'saldoMinimoParaCobranca' },
  { label: 'Sub-origem', key: 'subOrigem' },
  { label: 'Sub-taxa', key: 'subTaxa' },
  { label: 'Tamanho do número do cartão', key: 'tamanhoNumeroCartao' },
  { label: 'Taxa efetiva anual', key: 'taxaEfetivaAnual' },
  { label: 'Taxa de juros compra parcelada emissor', key: 'taxaJurosCompraParceladaEmissor' },
  { label: 'Taxa de juros rotativos', key: 'taxaJurosRotativo' },
  { label: 'Taxa de juros saque', key: 'taxaJurosSaque' },
  { label: 'Taxa referencial', key: 'taxaReferencial' },
  { label: 'Tipo de crédito', key: 'tipoCredito' },
  { label: 'Tipo de processamento', key: 'tipoProcessamento' },
  { label: 'Valor mínimo de desconto em folha', key: 'valorMinimoDescontoEmFolha' },
  { label: 'Valor mínimo de envio da fatura', key: 'valorMinimoEnvioFatura' },
  { label: 'Valor mínimo do pagamento mínimo', key: 'valorMinimoPagamentoMinimo' },
  { label: 'Valor mínimo da parcela com parcelamento de fatura', key: 'valorMinimoParcelaParcelamentoFatura' },
  { label: 'Valor de tolerância de saldo devido', key: 'valorToleranciaSaldoDevido' },
  { label: 'Valor total de doações', key: 'valorTotalDoacoes' },
];
const ProdutosPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const { query } = useQuerystring();
  const navigate = useNavigate();

  const smartColumns = (): SmartTableColumnProps[] => [
    {
      attribute: 'codigo',
      label: 'Código',
      sortable: true,
      className: 'text-center',
    },
    {
      attribute: 'nome',
      label: 'Nome',
      sortable: true,
      className: 'text-center',
    },
    {
      label: 'Tipo de crédito',
      attribute: 'tipoCredito',
      sortable: true,
      className: 'text-center',
      map: tipoCreditoLabelMap,
    },
    {
      attribute: 'codigoNoEmissor',
      label: 'Código no emissor',
      sortable: true,
      className: 'text-center',
    },
    {
      label: 'Ações',
      format: (_, produto) => {
        return (
          <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.ALTERACAO_PRODUTO}>
            <div className="d-flex justify-content-center">
              <div className="mx-1">
                <IconButton
                  Icon={BsPencilSquare}
                  type={ClassTypesEnum.PRIMARY}
                  onClick={() => {
                    navigate(`/produtos/${produto?._id}/atualizar`);
                  }}
                />
              </div>
            </div>
          </RbacElement>
        );
      },
    },
  ];

  const filters = useMemo(() => ({ ...query }), [query]);
  const total = useSelector((state) => selectTotalProdutosByFilters(state, filters));
  const produtos = useSelector((state) => selectProdutosByFilters(state, filters));
  const loadingState = useSelector((state) => selectProdutosLoadingStateByFilters(state, filters));

  const loadItems = useCallback(() => {
    dispatch(loadProdutos({ query })).catch((error: Error) => error);
  }, [dispatch, query]);

  const produtosExcel = produtos?.map(
    ({
      _id,
      operacoesPermitidas,
      opcoesParcelamentoFatura,
      parametrosGeracaoBoleto,
      codigo,
      ...dadosProdutos
    }: any) => {
      let novosDados = dadosProdutos;
      novosDados['cobrarTarifaNovaViaCartao'] = formatEnum(dadosProdutos.cobrarTarifaNovaViaCartao, boolLabelMap);
      novosDados['cobrarTarifaOverlimit'] = formatEnum(dadosProdutos.cobrarTarifaOverlimit, boolLabelMap);
      novosDados['cobrarTarifaSaqueCredito'] = formatEnum(dadosProdutos.cobrarTarifaSaqueCredito, boolLabelMap);
      novosDados['exclusivoClientesPreAprovados'] = formatEnum(
        dadosProdutos.exclusivoClientesPreAprovados,
        boolLabelMap
      );
      novosDados['geracaoAutomaticaCalendariosCorte'] = formatEnum(
        dadosProdutos.geracaoAutomaticaCalendariosCorte,
        boolLabelMap
      );
      novosDados['iofParcelamentoFaturaFinanciavel'] = formatEnum(
        dadosProdutos.iofParcelamentoFaturaFinanciavel,
        boolLabelMap
      );
      novosDados['permiteDoacaoArredondamentoCompra'] = formatEnum(
        dadosProdutos.permiteDoacaoArredondamentoCompra,
        boolLabelMap
      );
      novosDados['permiteDoacaoArredondamentoFatura'] = formatEnum(
        dadosProdutos.permiteDoacaoArredondamentoFatura,
        boolLabelMap
      );
      novosDados['permiteDoacaoEspontanea'] = formatEnum(dadosProdutos.permiteDoacaoEspontanea, boolLabelMap);
      novosDados['permiteDoacaoRecorrenteMensal'] = formatEnum(
        dadosProdutos.permiteDoacaoRecorrenteMensal,
        boolLabelMap
      );
      novosDados['permiteOverlimit'] = formatEnum(dadosProdutos.permiteOverlimit, boolLabelMap);
      novosDados['permiteParcelamentoAutomaticoFatura'] = formatEnum(
        dadosProdutos.permiteParcelamentoAutomaticoFatura,
        boolLabelMap
      );
      novosDados['permiteSaqueAutorizado'] = formatEnum(dadosProdutos.permiteSaqueAutorizado, boolLabelMap);
      novosDados['permiteSaqueBandeira'] = formatEnum(dadosProdutos.permiteSaqueBandeira, boolLabelMap);
      novosDados['permiteSaqueComplementar'] = formatEnum(dadosProdutos.permiteSaqueComplementar, boolLabelMap);
      novosDados['tipoProcessamento'] =
        tipoProcessamentoCalendarioCorteLabelMap[
          dadosProdutos.tipoProcessamento as keyof typeof tipoProcessamentoCalendarioCorteLabelMap
        ];
      return novosDados;
    }
  );

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_PRODUTO}>
      <div className="d-flex flex-column h-100">
        <Title>Produtos</Title>

        <div className="row">
          <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-12 mb-4">
            <SmartTableFilters filters={smartFilters} />
          </div>

          <div className="col-xxl-10 col-xl-9 col-lg-8 col-md-12 mb-4">
            <BSCard>
              <BSCard.Body>
                <div className="d-flex align-items-center mb-2">
                  <ExportCSVButton data={produtosExcel} filename="produtos" headers={csvHeaders} />
                </div>
                <SmartTable
                  emptyMessage="Nenhum produto encontrado"
                  errorMessage="Erro na listagem de produtos"
                  usePagination={true}
                  loadItems={loadItems}
                  columns={smartColumns()}
                  items={produtos}
                  loadingState={loadingState}
                  onRowClick={(doc) => navigate(`/produtos/${doc._id}`)}
                  size={total}
                />
              </BSCard.Body>
            </BSCard>
          </div>
        </div>
      </div>
    </RbacPage>
  );
};

export default ProdutosPage;

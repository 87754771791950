import { useCallback, useEffect, useMemo, useState } from 'react';
import BSCard from 'react-bootstrap/Card';
import { Link, useParams } from 'react-router-dom';
import { DetailCard } from '../../components/details/datail-card';
import DetailElement from '../../components/details/detail-element';
import DetailSubTitle from '../../components/details/detail-subtitle';
import DetailTitle from '../../components/details/detail-title';
import { Loading } from '../../components/loading';
import Page from '../../components/page';
import {
  ApiSingleElementResponse,
  FormatValueEnum,
  acaoEnvioFaturaLabelMap,
  contaCartaoRename,
  formaEnvioFaturaLabelMap,
  funcaoAtivaContaCartaoLabelMap,
  origemComercialLabelMap,
  tipoArredondamentoDoacaoLabelMap,
  tipoContaBancariaLabelMap,
} from '../../helpers';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import { loadCliente, selectClienteById } from '../clientes/clientes.redux';
import { loadContaBancaria, selectContaBancariaById } from '../contas-bancarias/contas-bancarias.redux';
import { loadContaCartao, selectContaCartaoById } from '../contas-cartao/contas-cartao.redux';
import { loadProduto, selectProdutoById } from '../produtos/produtos.redux';
import {
  loadTiposBloqueiosContaCartao,
  selectObjectTodosTiposBloqueioContaCartao,
} from '../tipos-bloqueio-conta-cartao/tipos-bloqueio-conta-cartao.redux';
import {
  loadHistorico,
  selectHistoricoContaCartaoById,
  selectHistoricoContaCartaoLoadingStateByFilters,
} from './historico-conta-cartao.redux';
import { loadHistoricoNoDiaContaCartao } from './recuperar-historico-dia.redux';

const DetalhesHistoricoContaCartaoPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const { maxItemsQuery } = useQuerystring();

  const params = useParams();
  const historicoContaCartaoId = params.historicoContaCartaoId as string;

  const [dadosAnteriores, setDadosAnteriores] = useState<any>();

  const filters = useMemo(() => ({ historicoContaCartaoId }), [historicoContaCartaoId]);
  const historicoContaCartao = useSelector((state) => selectHistoricoContaCartaoById(state, historicoContaCartaoId));
  const loadingState = useSelector((state) => selectHistoricoContaCartaoLoadingStateByFilters(state, filters));
  const contaCartao = useSelector((state) => selectContaCartaoById(state, historicoContaCartao?.contaCartaoId));
  const cliente = useSelector((state) => selectClienteById(state, contaCartao?.cliente));
  const produto = useSelector((state) => selectProdutoById(state, contaCartao?.produto));
  const contaBancaria = useSelector((state) => selectContaBancariaById(state, contaCartao?.contaBancaria));
  const tiposBloqueiosContaCartao = useSelector((state) => selectObjectTodosTiposBloqueioContaCartao(state));

  const loadEntidadesComplementares = useCallback(
    (historico: any) => {
      dispatch(loadHistoricoNoDiaContaCartao({ historicoContaCartaoId: historico._id }))
        .then(({ payload: { data } }: ApiSingleElementResponse) => setDadosAnteriores(data))
        .catch((error: Error) => error);
      dispatch(loadContaCartao({ contaCartaoId: historico.contaCartaoId })).catch((error: Error) => error);
      dispatch(loadCliente({ clienteId: contaCartao?.cliente })).catch((error: Error) => error);
      dispatch(loadProduto({ produtoId: contaCartao?.produto })).catch((error: Error) => error);
      dispatch(loadTiposBloqueiosContaCartao({ query: maxItemsQuery })).catch((error: Error) => error);

      if (contaCartao?.contaBancaria) {
        dispatch(loadContaBancaria({ contaBancariaId: contaCartao?.contaBancaria })).catch((error: Error) => error);
      }
    },
    [contaCartao?.cliente, contaCartao?.contaBancaria, contaCartao?.produto, dispatch, maxItemsQuery]
  );

  const _loadHistorico = useCallback(
    () =>
      dispatch(loadHistorico({ historicoContaCartaoId }))
        .then(({ payload: { data } }: ApiSingleElementResponse) => loadEntidadesComplementares(data))
        .catch((error: Error) => error),
    [dispatch, historicoContaCartaoId, loadEntidadesComplementares]
  );

  useEffect(() => {
    _loadHistorico();
  }, [_loadHistorico]);

  function filterSameKeys(entidade: any, alteracao: any) {
    if (!entidade || !alteracao) {
      return {};
    }

    const keysAlteracao = Object.keys(alteracao);

    return Object.entries(entidade).reduce((sameKeys, entry) => {
      const [chave, valor] = entry;

      if (keysAlteracao.includes(chave)) {
        return { ...sameKeys, [chave]: valor };
      }

      return sameKeys;
    }, {});
  }

  const renderDetailElement = (atributo: string, valor: any): JSX.Element | undefined => {
    const nomeAtributo = contaCartaoRename[atributo as keyof typeof contaCartaoRename];

    if (!nomeAtributo) {
      return;
    }

    function isBoolean() {
      return <DetailElement key={atributo} descricao={nomeAtributo} valor={valor} format={FormatValueEnum.BOOL} />;
    }

    function isBloqueios() {
      if (!valor.length) {
        return <DetailElement key={atributo} descricao="Bloqueios" valor="Conta sem bloqueios" />;
      }

      dispatch(loadTiposBloqueiosContaCartao({ query: maxItemsQuery })).catch((error: Error) => error);

      const qtdeBloqueiosAnteriores = dadosAnteriores?.bloqueios.length;
      const bloqueiosAnteriores = dadosAnteriores?.bloqueios;

      if (qtdeBloqueiosAnteriores < valor.length) {
        if (qtdeBloqueiosAnteriores.length === 0) {
          return;
        }

        const bloqueioAlteracao = valor.find(
          (bloqueio: any) =>
            !bloqueiosAnteriores.some((_bloqueio: any) => _bloqueio.tipoBloqueio === bloqueio.tipoBloqueio)
        );

        return (
          <div>
            <DetailElement
              key={atributo}
              descricao="Bloqueio Incluído"
              valor={tiposBloqueiosContaCartao?.[bloqueioAlteracao.tipoBloqueio]?.descricao}
            />
          </div>
        );
      }

      if (qtdeBloqueiosAnteriores > valor.length) {
        const isBloqueioExcluido = bloqueiosAnteriores.filter(
          (tipoBloqueio: any) => !valor.some((_tipoBloqueio: any) => _tipoBloqueio === tipoBloqueio)
        );

        return (
          <div>
            <DetailElement
              key={atributo}
              descricao="Bloqueio Excluído"
              valor={tiposBloqueiosContaCartao?.[isBloqueioExcluido[0].tipoBloqueio]?.descricao}
            />
          </div>
        );
      }

      return (
        <div>
          <DetailElement
            descricao="Bloqueios"
            valor={bloqueiosAnteriores
              ?.map((bloqueio: any) => tiposBloqueiosContaCartao?.[bloqueio.tipoBloqueio]?.descricao)
              .join(', ')}
          />
        </div>
      );
    }

    function isBloqueioPrioritario() {
      if (!valor) {
        return;
      }

      return (
        <DetailElement key={atributo} descricao={nomeAtributo} valor={tiposBloqueiosContaCartao?.[valor]?.descricao} />
      );
    }

    function isCliente() {
      return <DetailElement key={atributo} descricao={nomeAtributo} valor={cliente?.nome} />;
    }

    function isContaBancaria() {
      return (
        <div key={atributo}>
          <DetailElement descricao="Número da conta bancária" valor={contaBancaria?.numeroConta} />
          <DetailElement descricao="Código do banco" valor={contaBancaria?.codigoBanco} />
          <DetailElement descricao="Dígito da conta" valor={contaBancaria?.digitoConta} />
          <DetailElement descricao="Número da agência" valor={contaBancaria?.numeroAgencia} />
          <DetailElement descricao="Dígito da agência" valor={contaBancaria?.digitoAgencia} />
          <DetailElement
            descricao="Tipo da conta"
            valor={contaBancaria?.tipoConta}
            format={FormatValueEnum.ENUM}
            map={enums}
          />
        </div>
      );
    }

    function isData() {
      return <DetailElement key={atributo} descricao={nomeAtributo} valor={valor} format={FormatValueEnum.DATA} />;
    }

    function isDataTime() {
      return <DetailElement key={atributo} descricao={nomeAtributo} valor={valor} format={FormatValueEnum.DATE_TIME} />;
    }

    function isElement() {
      return <DetailElement key={atributo} descricao={nomeAtributo} valor={valor} />;
    }

    function isEnum() {
      return (
        <DetailElement
          key={atributo}
          descricao={nomeAtributo}
          valor={valor}
          format={FormatValueEnum.ENUM}
          map={enums}
        />
      );
    }

    function isMonetary() {
      return <DetailElement key={atributo} descricao={nomeAtributo} valor={valor} format={FormatValueEnum.BRL} />;
    }

    function isProduto() {
      return (
        <DetailElement
          key={atributo}
          descricao={nomeAtributo}
          valor={`(Código: ${produto.codigo}) - ${produto.nome}`}
        />
      );
    }

    const enums = {
      acaoEnvioFatura: acaoEnvioFaturaLabelMap,
      formaEnvioFatura: formaEnvioFaturaLabelMap,
      funcaoAtiva: funcaoAtivaContaCartaoLabelMap,
      origemComercial: origemComercialLabelMap,
      tipoArredondamentoDoacaoFatura: tipoArredondamentoDoacaoLabelMap,
      contaBancaria: tipoContaBancariaLabelMap,
    }[atributo];

    const cases = {
      acaoEnvioFatura: isEnum,
      bloqueioPrioritario: isBloqueioPrioritario,
      bloqueios: isBloqueios,
      comparaValorMesAnterior: isBoolean,
      contaBancaria: isContaBancaria,
      cliente: isCliente,
      dataAtivacao: isDataTime,
      dataCriacao: isData,
      dataInicioAtraso: isData,
      dataInicioRotativo: isData,
      dataProposta: isData,
      ehAtualizacaoEmissor: isBoolean,
      formaEnvioFatura: isEnum,
      funcaoAtiva: isEnum,
      limiteCredito: isMonetary,
      limiteCreditoDisponivel: isMonetary,
      limiteCreditoDisponivelSaque: isMonetary,
      limiteCreditoSaque: isMonetary,
      origemComercial: isEnum,
      parceria: isBoolean,
      permiteDoacaoArredondamentoFatura: isBoolean,
      permiteDoacaoRecorrenteMensal: isBoolean,
      produto: isProduto,
      status: isEnum,
      tipoArredondamentoDoacaoFatura: isEnum,
      valorDoacaoRecorrenteMensal: isMonetary,
      valorMargemConsignada: isMonetary,
      valorTotalDoado: isMonetary,
    };

    return Reflect.has(cases, atributo) ? cases[atributo as keyof typeof cases]() : isElement();
  };

  if (!historicoContaCartao) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Loading notFoundMessage="Registro de histórico não econtrado" loadingState={loadingState} />
      </div>
    );
  }

  return (
    <Page>
      <div className="mb-5">
        <DetailCard>
          <div className="mb-5">
            <DetailTitle>
              Registro de histórico de alteração
              {Boolean(contaCartao) && (
                <>
                  {' '}
                  - <Link to={`/contas-cartao/${contaCartao._id}`}>Conta {contaCartao.numero}</Link>
                </>
              )}
            </DetailTitle>
          </div>

          <div className="row mb-4">
            <div className="col-lg-4 col-md-6 mb-4">
              <DetailElement descricao="Cliente" valor={cliente?.nome} link={`/clientes/${cliente?._id}`} />
              <DetailElement
                descricao="Data da alteração"
                valor={historicoContaCartao.dataAlteracao}
                format={FormatValueEnum.DATE_TIME}
              />
              <DetailElement descricao="Origem da alteração" valor={historicoContaCartao.origemAlteracao} />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 col-lg-6 mb-4 d-flex flex-column">
              <div className="mb-4">
                <DetailSubTitle>Valores alterados</DetailSubTitle>
              </div>

              <BSCard className="flex-grow-1">
                <BSCard.Body className="p-5">
                  {historicoContaCartao.alteracao && (
                    <>
                      {Object.entries(historicoContaCartao.alteracao).map(([chave, valor]) => {
                        return renderDetailElement(chave, valor);
                      })}
                    </>
                  )}
                </BSCard.Body>
              </BSCard>
            </div>

            <div className="col-md-12 col-lg-6 mb-4 d-flex flex-column">
              <div className="mb-4">
                <DetailSubTitle>Valores anteriores</DetailSubTitle>
              </div>

              <BSCard className="flex-grow-1">
                <BSCard.Body className="p-5">
                  {dadosAnteriores ? (
                    <>
                      {Object.entries(filterSameKeys(dadosAnteriores, historicoContaCartao.alteracao)).map(
                        ([chave, valor]) => {
                          return renderDetailElement(chave, valor);
                        }
                      )}
                    </>
                  ) : (
                    <p>Sem alterações anteriores</p>
                  )}
                </BSCard.Body>
              </BSCard>
            </div>
          </div>
        </DetailCard>
      </div>
    </Page>
  );
};

export default DetalhesHistoricoContaCartaoPage;

import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import { useCallback, useEffect, useState } from 'react';
import BSButton from 'react-bootstrap/Button';
import BSSpinner from 'react-bootstrap/Spinner';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { DetailCard } from '../../components/details/datail-card';
import DetailTitle from '../../components/details/detail-title';
import { Form } from '../../components/form/form';
import { FormControl } from '../../components/form/form-control';
import { FormSelect } from '../../components/form/form-select';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import { ApiSingleElementResponse, ClassTypesEnum, geracaoArquivoMap } from '../../helpers';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import { loadProdutos, selectObjectTodosProdutos } from '../produtos/produtos.redux';
import { gerarArquivo } from './gerar-arquivo.redux';

type GerarArquivoFormFields = {
  tipoArquivo: string;
  dataExecucao: string;
  dataInicial: string;
  dataFinal: string;
  semestre: string;
  trimestre: string;
  ano: string;
  dados: {
    produtoId: string;
    operacao: string;
  };
};

const GerarArquivoPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const navigate = useNavigate();
  const { showToast } = useToasts();

  const [geracaoArquivoEmProcessamento, setGeracaoArquivoEmProcessamento] = useState<boolean>(false);

  const form = useForm<GerarArquivoFormFields>();
  const { control, reset, watch } = form;
  const tipoArquivo = watch('tipoArquivo');

  const propsArquivo = geracaoArquivoMap[tipoArquivo as keyof typeof geracaoArquivoMap];
  const produtos = useSelector((state) => selectObjectTodosProdutos(state));

  const onSubmitHandler = useCallback(
    (data: GerarArquivoFormFields) => {
      setGeracaoArquivoEmProcessamento(true);

      dispatch(gerarArquivo({ data }))
        .then(({ payload: { data } }: ApiSingleElementResponse) => {
          setGeracaoArquivoEmProcessamento(false);
          showToast({ message: 'Arquivo executado com sucesso', type: ClassTypesEnum.SUCCESS });

          return navigate(`/protocolos-processamento-lote/${data._id}`);
        })
        .catch((error: Error) => {
          setGeracaoArquivoEmProcessamento(false);
          showToast({ message: error.message, type: ClassTypesEnum.DANGER });
        });
    },
    [dispatch, navigate, showToast]
  );

  const onCancelHandler = () => {
    reset();
  };

  useEffect(() => {
    dispatch(loadProdutos()).catch((error: Error) => error);
  }, [dispatch]);

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.GERACAO_ARQUIVO}>
      <DetailCard>
        <div className="mb-5">
          <DetailTitle renderBackButton={false}>Gerar arquivo</DetailTitle>
        </div>

        <Form form={form} onSubmit={onSubmitHandler}>
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-3">
              <FormSelect
                control={control}
                name="tipoArquivo"
                label="Arquivo"
                placeholder="Selecione o arquivo"
                options={Object.entries(geracaoArquivoMap)?.map(([key, value]) => ({
                  key,
                  value: key,
                  label: value.nome,
                }))}
                rules={{ required: true }}
              />
            </div>

            {propsArquivo?.periodicidade && renderInputElement(propsArquivo?.periodicidade)}

            {tipoArquivo === 'abu' && (
              <div className="col-lg-4 col-md-6 mb-3">
                <FormControl
                  className="mb-3"
                  control={control}
                  name="dados.operacao"
                  label="Operação"
                  rules={{ required: true }}
                />
              </div>
            )}

            {['cadoc3040', 'cadoc3050', 'iofAnalitico', 'iofEstorno', 'iofSinteticoDiario'].includes(tipoArquivo) && (
              <div className="col-lg-4 col-md-6 mb-3">
                <FormSelect
                  className="mb-3"
                  control={control}
                  name="dados.produtoId"
                  label="Produto"
                  placeholder="Selecione o produto"
                  options={Object.entries(produtos ?? {})?.map(([key, value]: any) => ({
                    key,
                    value: key,
                    label: value.nome,
                  }))}
                  rules={{ required: true }}
                />
              </div>
            )}

            <div className="col-12 d-flex mt-4">
              <BSButton
                className="mx-1"
                variant="light"
                onClick={onCancelHandler}
                disabled={geracaoArquivoEmProcessamento}
              >
                Cancelar
              </BSButton>
              <BSButton className="mx-1 px-4" variant="primary" type="submit" disabled={geracaoArquivoEmProcessamento}>
                Gerar arquivo
              </BSButton>
            </div>
          </div>
        </Form>

        {geracaoArquivoEmProcessamento && (
          <div className="d-flex justify-content-center align-items-center mt-5">
            <div className="me-3">
              <BSSpinner variant="primary" />
            </div>
            <strong>Geração de arquivo em processamento...</strong>
          </div>
        )}
      </DetailCard>
    </RbacPage>
  );

  function renderInputElement(periocidade: string) {
    if (!periocidade) {
      return;
    }

    function inputAno() {
      return (
        <div className="col-lg-4 col-md-6 mb-3">
          <FormControl
            control={control}
            name="ano"
            label="Ano"
            placeholder="- - - -"
            rules={{ required: true, min: 2000, max: 2050 }}
          />
        </div>
      );
    }

    function inputDataExecucao() {
      return (
        <div className="col-lg-4 col-md-6 mb-3">
          <FormControl
            control={control}
            name="dataExecucao"
            label="Data de execução"
            rules={{ required: true }}
            type="date"
          />
        </div>
      );
    }

    function inputMesAno() {
      return (
        <div className="col-lg-4 col-md-6 mb-3">
          <FormControl
            control={control}
            name="dataExecucao"
            label="Mês/ano de execução"
            rules={{ required: true }}
            type="month"
          />
        </div>
      );
    }

    function inputRangeData() {
      return (
        <>
          <div className="col-lg-4 col-md-6 mb-3">
            <FormControl
              control={control}
              name="dataInicial"
              label="Data incial"
              rules={{ required: true }}
              type="date"
            />
          </div>

          <div className="col-lg-4 col-md-6 mb-3">
            <FormControl control={control} name="dataFinal" label="Data final" rules={{ required: true }} type="date" />
          </div>
        </>
      );
    }

    function inputSemestral() {
      return (
        <>
          <div className="col-lg-4 col-md-6 mb-3">
            <FormSelect
              control={control}
              className="mb-3"
              name="semestre"
              label="Semestre"
              placeholder="Selecione o semestre"
              options={['Primeiro', 'Segundo'].map((semestre: string) => ({
                key: semestre,
                value: semestre,
                label: semestre,
              }))}
              rules={{ required: true }}
            />
          </div>
          {inputAno()}
        </>
      );
    }

    function inputTrimestral() {
      return (
        <>
          <div className="col-lg-4 col-md-6 mb-3">
            <FormSelect
              control={control}
              className="mb-3"
              name="trimestre"
              label="Trimestre"
              placeholder="Selecione o trimestre"
              options={['Primeiro', 'Segundo', 'Terceiro', 'Quarto'].map((trimestre: string) => ({
                key: trimestre,
                value: trimestre,
                label: trimestre,
              }))}
              rules={{ required: true }}
            />
          </div>
          {inputAno()}
        </>
      );
    }

    return {
      anual: inputAno(),
      demanda: inputRangeData(),
      diario: inputDataExecucao(),
      mensal: inputMesAno(),
      semanal: inputDataExecucao(),
      semestral: inputSemestral(),
      trimestral: inputTrimestral(),
    }[periocidade];
  }
};

export default GerarArquivoPage;

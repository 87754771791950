import { useQuerystring } from '../../hooks/router/use-querystring';
import { getPaginationProps, limitadorContadorQuery } from './smart-table.utils';

type QueryResultProps = {
  size?: number;
};

export const QueryResult: React.FC<QueryResultProps> = ({ size = 0 }) => {
  const { query: routerQuery, maxItemsQuery } = useQuerystring();

  const query = { ...maxItemsQuery, ...routerQuery };
  const { page, pageSize } = getPaginationProps({ page: query.page, pageSize: query.pageSize, size });

  const inicioIntervalo = (page - 1) * pageSize + 1;
  const fimIntervalo = Math.min(page * pageSize, size);

  const queryResultStr =
    size >= limitadorContadorQuery
      ? `${inicioIntervalo} - ${fimIntervalo} de muitos registros`
      : `${inicioIntervalo} - ${fimIntervalo} de ${size} registro(s)`;

  return (
    <div data-testid="query-result" className="d-flex justify-content-center">
      <span className="small text-secondary">{queryResultStr}</span>
    </div>
  );
};

import { reducerFactory, actionFactory, selectorFactory } from '../../store';

const context = 'taxasCambio';

const { loadMany, createOne } = actionFactory(context, {
  buildUrlLoadMany: () => '/api/taxas-cambio',
  buildUrlCreateOne: () => 'api/taxas-cambio',
});

const { selectOneById, selectAll, selectManyByFilters, selectTotalByFilters, selectLoadingStateByFilters } =
  selectorFactory({ context });

export const loadTaxasCambio = loadMany;
export const saveTaxaCambio = createOne;

export const selectTaxaCambioById = selectOneById;
export const selectTodosTaxasCambios = selectAll;
export const selectTaxasCambiosByFilters = selectManyByFilters;
export const selectTotalTaxasCambiosByFilters = selectTotalByFilters;
export const selectTaxasCambiosLoadingStateByFilters = selectLoadingStateByFilters;

export const taxasCambio = reducerFactory({ context });

import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import { DetailCard } from '../../components/details/datail-card';
import DetailElement from '../../components/details/detail-element';
import DetailTitle from '../../components/details/detail-title';
import { Loading } from '../../components/loading';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import {
  loadGrupoCredenciaisCliente,
  selectGrupoCredencialClienteById,
  selectGruposCredenciaisClienteLoadingStateByFilters,
} from './grupo-credenciais-cliente.redux';
import { FormatValueEnum } from '../../helpers';
import { CredenciaisClientesTable } from './credenciais-cliente-tab';
import DetailSubTitle from '../../components/details/detail-subtitle';
const DetalhesGrupoCredencialPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;
  const params = useParams();
  const grupoCredencialClienteId = params.grupoCredencialId as string;
  const filters = useMemo(() => ({ grupoCredencialClienteId }), [grupoCredencialClienteId]);
  const grupoClienteCredential = useSelector((state) =>
    selectGrupoCredencialClienteById(state, grupoCredencialClienteId)
  );
  const loadingState = useSelector((state) => selectGruposCredenciaisClienteLoadingStateByFilters(state, filters));

  useEffect(() => {
    dispatch(loadGrupoCredenciaisCliente({ grupoCredencialClienteId })).catch((error: Error) => error);
  }, [dispatch, grupoCredencialClienteId]);

  if (!grupoClienteCredential) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Loading notFoundMessage="Grupo credencial cliente não encontrado" loadingState={loadingState} />
      </div>
    );
  }

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_CLIENT_CREDENTIALS}>
      <div className="mb-5">
        <DetailCard>
          <>
            <div className="row mb-5">
              <DetailTitle>Detalhes do grupo {grupoClienteCredential?.nome}</DetailTitle>
            </div>

            <div className="row mb-5">
              <div className="col-lg-4 col-md-6 mb-4">
                <DetailElement descricao="Ativo" valor={grupoClienteCredential?.ativo} format={FormatValueEnum.BOOL} />
              </div>
            </div>

            <div className="mb-3">
              <DetailSubTitle>Credenciais de clientes</DetailSubTitle>
            </div>

            <CredenciaisClientesTable grupoId={grupoCredencialClienteId} />
          </>
        </DetailCard>
      </div>
    </RbacPage>
  );
};

export default DetalhesGrupoCredencialPage;

import { SmartTable } from '../../components/smart-table/smart-table';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import { FormatValueEnum, naturezaTransacaoLabelMap } from '../../helpers';
import { useAppSelector } from '../../store/hooks-redux';
import { selectObjectTodosTiposTransacao } from '../tipos-transacao/tipos-transacao.redux';
import { selectObjectTodasTransacoes } from '../transacoes/transacoes.redux';
import { selectSolicitacaoClienteById } from './solicitacao-cliente.redux';

const smartColumns = ({ tiposTransacao }: { tiposTransacao: any }): SmartTableColumnProps[] => [
  {
    label: 'Data referência',
    attribute: 'dataReferencia',
    className: 'text-center',
    format: FormatValueEnum.DATE_TIME,
  },
  {
    label: 'Tipo de transação',
    attribute: 'tipoTransacao',
    className: 'text-center',
    format: (tipoTransacao: keyof typeof tiposTransacao) => {
      if (!tipoTransacao) {
        return '';
      }

      return tiposTransacao[tipoTransacao]?.descricao;
    },
  },
  {
    label: 'Estabelecimento',
    attribute: 'nomeEstabelecimento',
    className: 'text-center',
  },
  {
    label: 'Natureza',
    attribute: 'natureza',
    className: 'text-center',
    format: FormatValueEnum.ENUM,
    map: naturezaTransacaoLabelMap,
  },
  {
    label: 'Valor (R$)',
    attribute: 'valor',
    className: 'text-end',
    format: FormatValueEnum.BRL,
  },
];

type TransacaoOrigemAjusteFinanceiroControlProps = {
  setTransacaoOrigem: React.Dispatch<any>;
  solicitacaoClienteId: string;
};

const TransacaoOrigemAjusteFinanceiroControl: React.FC<TransacaoOrigemAjusteFinanceiroControlProps> = ({
  setTransacaoOrigem,
  solicitacaoClienteId,
}) => {
  const useSelector = useAppSelector;

  const solicitacaoCliente = useSelector((state) => selectSolicitacaoClienteById(state, solicitacaoClienteId));
  const todasTransacoes = useSelector((state) => selectObjectTodasTransacoes(state));
  const tiposTransacao = useSelector((state) => selectObjectTodosTiposTransacao(state));

  const transacoes = solicitacaoCliente?.transacoes?.map((transacaoId: any): any[] => todasTransacoes?.[transacaoId]);

  const onTransacaoOrigemClickHandler = (transacao: any) => {
    setTransacaoOrigem({ ...transacao, tipoTransacao: tiposTransacao?.[transacao.tipoTransacao] });
  };

  return (
    <SmartTable
      columns={smartColumns({ tiposTransacao })}
      emptyMessage="Nenhuma transação selecionada"
      errorMessage="Erro ao listar transações"
      items={transacoes}
      onRowClick={onTransacaoOrigemClickHandler}
    />
  );
};

export default TransacaoOrigemAjusteFinanceiroControl;

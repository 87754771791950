import { AcaoPermissaoPapelUsuarioEnum, TipoCreditoEnum } from '@tamborineapps/lib-enums';
import { useCallback, useEffect, useMemo, useState } from 'react';
import BSAlert from 'react-bootstrap/Alert';
import BSBadge from 'react-bootstrap/Badge';
import BSButton from 'react-bootstrap/Button';
import BSNav from 'react-bootstrap/Nav';
import BSTab from 'react-bootstrap/Tab';
import BSTabs from 'react-bootstrap/Tabs';
import { Link, useParams, useSearchParams } from 'react-router-dom';

import { DetailCard } from '../../components/details/datail-card';
import DetailElement from '../../components/details/detail-element';
import DetailSubTitle from '../../components/details/detail-subtitle';
import DetailTitle from '../../components/details/detail-title';
import { Loading } from '../../components/loading';
import RbacElement from '../../components/role-based-access-control/role-based-access-control-element';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import {
  acaoEnvioFaturaLabelMap,
  ApiSingleElementResponse,
  FormatValueEnum,
  SizesEnum,
  formaEnvioFaturaLabelMap,
  funcaoAtivaContaCartaoLabelMap,
  origemComercialLabelMap,
  statusContaCartaoColorMap,
  statusContaCartaoLabelMap,
  tipoContaBancariaLabelMap,
} from '../../helpers';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useDialog } from '../../hooks/dialog/use-dialog';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import AccessDeniedTab from '../access-denied/access-denied-tab';
import { loadCartoesContaCartao } from '../cartoes/cartoes.redux';
import { loadCliente, selectClienteById } from '../clientes/clientes.redux';
import { loadContaBancaria, selectContaBancariaById } from '../contas-bancarias/contas-bancarias.redux';
import { loadProduto, selectProdutoById } from '../produtos/produtos.redux';
import {
  loadTipoBloqueioContaCartao,
  selectTiposBloqueioContaCartaoById,
} from '../tipos-bloqueio-conta-cartao/tipos-bloqueio-conta-cartao.redux';
import AtualizarDiaVencimentoDialog from './atualizar-dia-vencimento-dialog';
import AtualizarLimiteCreditoUsuarioDialog from './atualizar-limite-credito-dialog';
import BeneficiosContaCartaoTab from './beneficios-conta-cartao-tab';
import BloqueiosContaCartaoTab from './bloqueios-conta-cartao-tab';
import { CadastrarSolicitacaoClienteDialog } from './cadastrar-solicitacao-cliente-dialog';
import { CartoesContaCartaoTab } from './cartoes-conta-cartao-tab';
import { loadContaCartao, selectContaCartaoById, selectContasCartaoLoadingStateByFilters } from './contas-cartao.redux';
import { loadDiasAtrasoContaCartao, selectDiasAtrasoContaCartaoById } from './dias-atraso-conta-cartao.redux';
import DoacoesCartaoCanceladasTab from './doacoes-cartao-canceladas-tab';
import DoacoesCartaoPendentesTab from './doacoes-cartao-pendentes-tab';
import FaturasContaCartaoTab from './faturas-conta-cartao-tab';
import HistoricoContaCartaoTab from './historico-conta-cartao.tab';
import PedidosAutorizacaoContaCartaoTab from './pedidos-autorizacao-conta-cartao-tab';
import { ProtocolosProcessamentoContaTab } from './protocolos-processamento-conta-tab';
import {
  loadSaldoTotalDevedorContaCartao,
  selectSaldoTotalDevedorContaCartaoById,
} from './saldo-total-devedor-conta-cartao.redux';
import SolicitacoesClienteContaCartaoTab from './solicitacoes-cliente-conta-cartao-tab';
import SolicitarCartaoAdicionalDialog from './solicitar-cartao-adicional-dialog';
import TransacoesContaCartaoTab from './transacoes-conta-cartao-tab';

const DetalhesContaCartaoPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;
  const params = useParams();
  const contaCartaoId = params.contaCartaoId as string;

  const { tab, query } = useQuerystring();
  const { showDialog, closeDialog } = useDialog();
  const [, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState<string | undefined>(tab as string);
  const [transacoesSelecionadasAba, setTransacoesSelecionadasAba] = useState<any[]>([]);

  const filters = useMemo(() => ({ contaCartaoId }), [contaCartaoId]);
  const contaCartao = useSelector((state) => selectContaCartaoById(state, contaCartaoId));
  const loadingState = useSelector((state) => selectContasCartaoLoadingStateByFilters(state, filters));

  const cliente = useSelector((state) => selectClienteById(state, contaCartao?.cliente));
  const produto = useSelector((state) => selectProdutoById(state, contaCartao?.produto));
  const contaBancaria = useSelector((state) => selectContaBancariaById(state, contaCartao?.contaBancaria));
  const bloqueioPrioritario = useSelector((state) =>
    selectTiposBloqueioContaCartaoById(state, contaCartao?.bloqueioPrioritario)
  );
  const diasAtraso = useSelector((state) => selectDiasAtrasoContaCartaoById(state, contaCartaoId));
  const saldoTotalDevedor = useSelector((state) => selectSaldoTotalDevedorContaCartaoById(state, contaCartaoId));

  const reloadCartoes = useCallback(() => {
    dispatch(loadCartoesContaCartao({ params: { contaCartaoId }, query }, { forceCall: true })).catch(
      (error: Error) => error
    );
  }, [contaCartaoId, dispatch, query]);

  const loadEntidadesRelacionadas = useCallback(
    (contaCartao: any) => {
      dispatch(loadCliente({ clienteId: contaCartao.cliente })).catch((error: Error) => error);
      dispatch(loadProduto({ produtoId: contaCartao.produto })).catch((error: Error) => error);
      dispatch(loadDiasAtrasoContaCartao({ contaCartaoId: contaCartao._id })).catch((error: Error) => error);

      if (produto?.tipoCredito !== TipoCreditoEnum.MULTIBENEFICIO) {
        dispatch(loadSaldoTotalDevedorContaCartao({ contaCartaoId: contaCartao._id })).catch((error: Error) => error);
      }

      if (contaCartao.bloqueioPrioritario) {
        dispatch(loadTipoBloqueioContaCartao({ tipoBloqueioContaCartaoId: contaCartao.bloqueioPrioritario })).catch(
          (error: Error) => error
        );
      }

      if (contaCartao.contaBancaria) {
        dispatch(loadContaBancaria({ contaBancariaId: contaCartao.contaBancaria })).catch((error: Error) => error);
      }
    },
    [dispatch, produto]
  );

  const _loadContaCartao = useCallback(
    () =>
      dispatch(loadContaCartao({ contaCartaoId }))
        .then(({ payload: { data } }: ApiSingleElementResponse) => loadEntidadesRelacionadas(data))
        .catch((error: Error) => error),
    [contaCartaoId, dispatch, loadEntidadesRelacionadas]
  );

  const onTabClickHandler = (eventKey: any) => {
    setSearchParams({ _tab: eventKey });
    setActiveTab(eventKey);
  };

  useEffect(() => {
    _loadContaCartao();
  }, [_loadContaCartao]);

  useEffect(() => {
    if (!activeTab) {
      const ehProdutoMultibeneficio = produto?.tipoCredito === TipoCreditoEnum.MULTIBENEFICIO;
      const defaultActiveTab = ehProdutoMultibeneficio ? 'beneficios' : 'cartoes';

      setActiveTab(defaultActiveTab);
    }
  }, [activeTab, produto]);

  if (!contaCartao) {
    return (
      <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_CONTA_CARTAO}>
        <div className="d-flex justify-content-center align-items-center h-100">
          <Loading notFoundMessage="Conta cartão não econtrada!" loadingState={loadingState} />
        </div>
      </RbacPage>
    );
  }

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_CONTA_CARTAO}>
      <div className="mb-5">
        <DetailCard>
          <div className="row mb-5">
            <div className="col-md-8 col-sm-12 mb-3">
              <DetailTitle>
                <div className="d-flex">
                  <span>Conta {contaCartao.numero}</span>
                  <span className="mx-3">-</span>
                  <span>
                    <Link to={`/clientes/${contaCartao.cliente}`}>{cliente?.nome}</Link>
                  </span>
                </div>
              </DetailTitle>
            </div>

            <div className="col-md-4 col-sm-12 text-md-end text-sm-start">
              <h4 className="mb-0">
                <BSBadge
                  pill
                  bg={statusContaCartaoColorMap[contaCartao.status as keyof typeof statusContaCartaoColorMap]}
                >
                  {statusContaCartaoLabelMap[contaCartao.status as keyof typeof statusContaCartaoLabelMap]}
                </BSBadge>
              </h4>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-lg-4 col-md-6 mb-4">
              <DetailElement descricao="Matricula" valor={contaCartao.matricula} />
              <DetailElement
                descricao="Data de criação da conta"
                valor={contaCartao.dataCriacao}
                format={FormatValueEnum.DATA}
              />
              <DetailElement
                descricao="Data de proposta de abertura da conta"
                valor={contaCartao.dataProposta}
                format={FormatValueEnum.DATA}
              />
              <DetailElement descricao="Número no emissor" valor={contaCartao.numeroNoEmissor} />
              <DetailElement
                descricao="Última atualização pelo emissor"
                valor={contaCartao.ehAtualizacaoEmissor}
                format={FormatValueEnum.BOOL}
              />
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <DetailElement descricao="Código do Produto" valor={produto?.codigo} />
              <DetailElement descricao="Produto" valor={produto?.nome} link={`/produtos/${contaCartao.produto}`} />
              <DetailElement descricao="Plano de crédito" valor={contaCartao.planoCredito} />
              <DetailElement
                descricao="Origem comercial"
                valor={contaCartao.origemComercial}
                format={FormatValueEnum.ENUM}
                map={origemComercialLabelMap}
              />
              <DetailElement
                descricao="Compara valor com o mês anterior"
                valor={contaCartao.comparaValorMesAnterior}
                format={FormatValueEnum.BOOL}
              />
            </div>

            {produto?.tipoCredito !== TipoCreditoEnum.PRE_PAGO && (
              <div className="col-lg-4 col-md-6 mb-4">
                <DetailElement descricao="Dia de vencimento" valor={contaCartao.diaVencimento} />
                <DetailElement
                  descricao="Data de alteração do dia de vencimento"
                  valor={contaCartao.dataHoraAlteracaoDiaVencimento}
                  format={FormatValueEnum.DATE_TIME}
                />
                <DetailElement
                  descricao="Data mínima para alteração de vencimento"
                  valor={contaCartao.dataMinimaProximaAlteracaoDiaVencimento}
                  format={FormatValueEnum.DATA}
                />
                <DetailElement
                  descricao="Ação de envio da fatura"
                  valor={contaCartao.acaoEnvioFatura}
                  format={FormatValueEnum.ENUM}
                  map={acaoEnvioFaturaLabelMap}
                />
                <DetailElement
                  descricao="Forma de envio da fatura"
                  valor={contaCartao.formaEnvioFatura}
                  format={FormatValueEnum.ENUM}
                  map={formaEnvioFaturaLabelMap}
                />
              </div>
            )}

            <div className="col-lg-4 col-md-6 mb-4">
              <div className="mb-3">
                <DetailSubTitle>Limites e valores</DetailSubTitle>
              </div>

              {produto?.tipoCredito !== TipoCreditoEnum.PRE_PAGO && (
                <DetailElement
                  descricao="Saldo total devedor"
                  valor={saldoTotalDevedor?.saldoTotalDevedor}
                  format={FormatValueEnum.BRL}
                />
              )}
              <DetailElement
                descricao="Limite de crédito"
                valor={contaCartao?.limiteCredito}
                format={FormatValueEnum.BRL}
              />
              <DetailElement
                descricao="Limite de crédito disponível"
                valor={contaCartao?.limiteCreditoDisponivel}
                format={FormatValueEnum.BRL}
              />
              {produto?.tipoCredito === TipoCreditoEnum.CONSIGNADO && (
                <>
                  <DetailElement
                    descricao="Valor margem consignada"
                    valor={contaCartao?.valorMargemConsignada}
                    format={FormatValueEnum.BRL}
                  />
                </>
              )}
              <DetailElement
                descricao="Crédito para saque"
                valor={contaCartao?.limiteCreditoSaque}
                format={FormatValueEnum.BRL}
              />
              <DetailElement
                descricao="Crédito disponível para saque"
                valor={contaCartao?.limiteCreditoDisponivelSaque}
                format={FormatValueEnum.BRL}
              />
              <DetailElement
                descricao="Crédito futuro"
                dica="Crédito proveniente do valor excedente de pagamento, mas não faz parte do limite disponível de crédito"
                valor={contaCartao?.creditoFuturo}
                format={FormatValueEnum.BRL}
              />
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <div className="mb-3">
                <DetailSubTitle>Cobrança</DetailSubTitle>
              </div>
              <DetailElement
                descricao="Data início atraso"
                valor={contaCartao.dataInicioAtraso}
                format={FormatValueEnum.DATA}
              />
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <div className="mb-3">
                <DetailSubTitle>Doações</DetailSubTitle>
              </div>
              <DetailElement
                descricao="Permite doação por arredondamento da fatura"
                valor={contaCartao.permiteDoacaoArredondamentoFatura}
                format={FormatValueEnum.BOOL}
              />
              <DetailElement
                descricao="Permite doação recorrente mensal"
                valor={contaCartao.permiteDoacaoRecorrenteMensal}
                format={FormatValueEnum.BOOL}
              />
              <DetailElement
                descricao="Tipo arredondamento da fatura"
                valor={contaCartao.tipoArredondamentoDoacaoFatura}
              />
              <DetailElement
                descricao="Valor doação recorrente mensal"
                valor={contaCartao.valorDoacaoRecorrenteMensal}
                format={FormatValueEnum.BRL}
              />
              <DetailElement
                descricao="Valor total doado"
                valor={contaCartao.valorTotalDoado}
                format={FormatValueEnum.BRL}
              />
            </div>

            {contaCartao.contaBancaria && (
              <div className="col-lg-4 col-md-6 mb-4">
                <div className="mb-3">
                  <DetailSubTitle>Conta Bancária:</DetailSubTitle>
                </div>

                <>
                  <DetailElement descricao="Número da conta bancária" valor={contaBancaria?.numeroConta} />
                  <DetailElement descricao="Código do banco" valor={contaBancaria?.codigoBanco} />
                  <DetailElement descricao="Dígito da conta" valor={contaBancaria?.digitoConta} />
                  <DetailElement descricao="Número da agência" valor={contaBancaria?.numeroAgencia} />
                  <DetailElement descricao="Dígito da agência" valor={contaBancaria?.digitoAgencia} />
                  <DetailElement
                    descricao="Tipo da conta"
                    valor={contaBancaria?.tipoConta}
                    format={FormatValueEnum.ENUM}
                    map={tipoContaBancariaLabelMap}
                  />
                  <DetailElement
                    descricao="Função ativa"
                    valor={contaBancaria?.funcaoAtiva}
                    format={FormatValueEnum.ENUM}
                    map={funcaoAtivaContaCartaoLabelMap}
                  />
                </>
              </div>
            )}
          </div>

          {bloqueioPrioritario && (
            <div className="row">
              <div className="col-lg-4 col-md-6 mb-4">
                <BSAlert variant="danger">
                  <DetailElement
                    className="mb-0"
                    descricao="Bloqueio prioritário"
                    valor={bloqueioPrioritario?.descricao}
                    link={`/tipos-bloqueio-conta-cartao/${bloqueioPrioritario._id}`}
                  />
                </BSAlert>
                {diasAtraso && produto && diasAtraso.diasAtraso > produto?.diasToleranciaParaBloqueioAtraso && (
                  <BSAlert variant="warning">
                    <DetailElement className="mb-0" descricao="Dias de atraso" valor={diasAtraso.diasAtraso} />
                  </BSAlert>
                )}
              </div>
            </div>
          )}

          <div className="row">
            <div className="col-12 d-flex justify-content-end flex-wrap">
              <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.CRIACAO_SOLICITACAO_CLIENTE}>
                <div className="m-1">
                  <BSButton
                    onClick={() =>
                      showDialog({
                        component: (
                          <CadastrarSolicitacaoClienteDialog
                            closeDialog={closeDialog}
                            contaCartaoId={contaCartao._id}
                            transacoes={transacoesSelecionadasAba}
                          />
                        ),
                        size: SizesEnum.EXTRA_LARGE,
                      })
                    }
                  >
                    Abrir solicitação
                  </BSButton>
                </div>
              </RbacElement>

              <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.ALTERACAO_DIA_VENCIMENTO_CONTA_CARTAO}>
                <div className="m-1">
                  <BSButton
                    onClick={() =>
                      showDialog({
                        component: (
                          <AtualizarDiaVencimentoDialog closeDialog={closeDialog} contaCartaoId={contaCartao._id} />
                        ),
                      })
                    }
                  >
                    Alterar vencimento
                  </BSButton>
                </div>
              </RbacElement>

              <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.ALTERACAO_LIMITE_CREDITO_CONTA_CARTAO}>
                <div className="m-1">
                  <BSButton
                    onClick={() =>
                      showDialog({
                        component: (
                          <AtualizarLimiteCreditoUsuarioDialog
                            closeDialog={closeDialog}
                            contaCartaoId={contaCartao._id}
                          />
                        ),
                      })
                    }
                  >
                    Limite de crédito
                  </BSButton>
                </div>
              </RbacElement>

              <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.SOLICITACAO_CARTAO_ADICIONAL_CONTA_CARTAO}>
                <div className="m-1">
                  <BSButton
                    onClick={() =>
                      showDialog({
                        component: (
                          <SolicitarCartaoAdicionalDialog
                            closeDialog={closeDialog}
                            contaCartaoId={contaCartao._id}
                            reload={reloadCartoes}
                          />
                        ),
                      })
                    }
                  >
                    Cartão adicional
                  </BSButton>
                </div>
              </RbacElement>
            </div>
          </div>
        </DetailCard>
      </div>

      <DetailCard>
        <BSTabs activeKey={activeTab} fill unmountOnExit onSelect={onTabClickHandler}>
          {produto?.tipoCredito === TipoCreditoEnum.MULTIBENEFICIO && (
            <BSTab eventKey="beneficios" title="Benefícios">
              <BeneficiosContaCartaoTab contaCartaoId={contaCartao._id} />
            </BSTab>
          )}
          <BSTab eventKey="cartoes" title="Cartões">
            <CartoesContaCartaoTab contaCartaoId={contaCartao._id} />
          </BSTab>

          <BSTab eventKey="bloqueios" title="Bloqueios">
            <BloqueiosContaCartaoTab contaCartaoId={contaCartao._id} />
          </BSTab>

          {![TipoCreditoEnum.PRE_PAGO, TipoCreditoEnum.MULTIBENEFICIO].includes(produto?.tipoCredito) && (
            <BSTab eventKey="faturas" title="Faturas">
              <FaturasContaCartaoTab contaCartaoId={contaCartao._id} />
            </BSTab>
          )}

          <BSTab eventKey="transacoes" title="Transações">
            <TransacoesContaCartaoTab
              contaCartaoId={contaCartao._id}
              setTransacoesSelecionadasParaSolicitacao={setTransacoesSelecionadasAba}
            />
          </BSTab>

          <BSTab eventKey="pedidosAutorizacao" title="Pedidos de autorização">
            <RbacElement
              acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_PEDIDO_AUTORIZACAO}
              componentToRender={<AccessDeniedTab />}
            >
              <PedidosAutorizacaoContaCartaoTab contaCartaoId={contaCartao._id} />
            </RbacElement>
          </BSTab>

          <BSTab eventKey="historico" title="Histórico">
            <RbacElement
              acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_HISTORICO_CONTA_CARTAO}
              componentToRender={<AccessDeniedTab />}
            >
              <HistoricoContaCartaoTab contaCartaoId={contaCartao._id} />
            </RbacElement>
          </BSTab>

          <BSTab eventKey="solicitacoes" title="Solicitações">
            <RbacElement
              acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_SOLICITACAO_CLIENTE}
              componentToRender={<AccessDeniedTab />}
            >
              <SolicitacoesClienteContaCartaoTab contaCartaoId={contaCartao._id} />
            </RbacElement>
          </BSTab>

          <BSTab eventKey="doacoes" title="Doações">
            <BSTab.Container defaultActiveKey="doacoesPendentes" unmountOnExit>
              <div className="row">
                <div className="col-lg-2">
                  <BSNav variant="pills" className="flex-column">
                    <BSNav.Item className="mb-1">
                      <BSNav.Link eventKey="doacoesCanceladas">Canceladas</BSNav.Link>
                    </BSNav.Item>
                    <BSNav.Item className="mb-1">
                      <BSNav.Link eventKey="doacoesPendentes">Pendentes</BSNav.Link>
                    </BSNav.Item>
                  </BSNav>
                </div>
                <div className="col-lg-10">
                  <BSTab.Content>
                    <BSTab.Pane eventKey="doacoesCanceladas">
                      <DoacoesCartaoCanceladasTab contaCartaoId={contaCartao._id} />
                    </BSTab.Pane>
                    <BSTab.Pane eventKey="doacoesPendentes">
                      <DoacoesCartaoPendentesTab contaCartaoId={contaCartao._id} />
                    </BSTab.Pane>
                  </BSTab.Content>
                </div>
              </div>
            </BSTab.Container>
          </BSTab>

          <BSTab eventKey="protocolosProcessamentoConta" title="Processamentos">
            <RbacElement
              acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_PROTOCOLO_PROCESSAMENTO_CONTA}
              componentToRender={<AccessDeniedTab />}
            >
              <ProtocolosProcessamentoContaTab contaCartaoId={contaCartao._id} />
            </RbacElement>
          </BSTab>
        </BSTabs>
      </DetailCard>
    </RbacPage>
  );
};

export default DetalhesContaCartaoPage;

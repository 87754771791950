import { actionFactory, selectorFactory, reducerFactory } from '../../../store';

const context = 'relatoriosOperacaoAutorizacoes';

const { loadMany, loadOne } = actionFactory(context, {
  buildUrlLoadMany: () => `/api/relatorios-operacao/autorizacoes`,
  buildUrlLoadOne: ({ relatorioOperacaoId }) => `/api/relatorios-operacao/autorizacoes/${relatorioOperacaoId}`,
});

const { selectOneById, selectManyByFilters, selectTotalByFilters, selectLoadingStateByFilters } = selectorFactory({
  context,
});

export const loadRelatoriosOperacaoAutorizacoes = loadMany;
export const loadRelatorioOperacaoAutorizacoes = loadOne;

export const selectRelatoriosOperacaoAutorizacoesByFilters = selectManyByFilters;
export const selectRelatorioOperacaoAutorizacoesById = selectOneById;
export const selectTotalRelatoriosOperacaoAutorizacoesByFilters = selectTotalByFilters;
export const selectRelatoriosOperacaoAutorizacoesLoadingStateByFilters = selectLoadingStateByFilters;

export const relatoriosOperacaoAutorizacoes = reducerFactory({ context });

import React, { Component } from 'react';
import BSButton from 'react-bootstrap/Button';
import { BsEmojiDizzy } from 'react-icons/bs';
import Page from '../../components/page';
import variables from '../../_export.scss';

export class ErrorBoundary extends Component<any, any> {
  constructor(props: { children: React.ReactNode }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    return this.state.hasError ? (
      <Page>
        <div className="d-flex flex-column justify-content-center align-items-center h-100">
          <h1 className="mb-4">Oops!</h1>

          <div className="mb-4">
            <BsEmojiDizzy size={100} color={variables.danger} />
          </div>

          <h2 className="mb-4">Erro de renderização</h2>

          <h4 className="mb-4">Algo não saiu como esperado</h4>

          <BSButton href="/" onClick={() => {}}>
            Ir para página inicial
          </BSButton>
        </div>
      </Page>
    ) : (
      this.props.children
    );
  }
}

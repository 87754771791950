import { useEffect } from 'react';
import BSButton from 'react-bootstrap/Button';
import BSModal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { Form } from '../../components/form/form';
import { FormControl, MasksFormControlEnum } from '../../components/form/form-control';
import { FormSelect } from '../../components/form/form-select';
import { periodicidadeLabelMap, tipoTarifaLabelMap } from '../../helpers';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import { loadProdutos, selectObjectTodosProdutos } from '../produtos/produtos.redux';
import { loadTiposTransacao, selectObjectTodosTiposTransacao } from '../tipos-transacao/tipos-transacao.redux';

type AtualizarTarifaFormFields = {
  produto: string;
  tipoTransacao: string;
  tipoTarifa: string;
  codigo: string;
  descricao: string;
  valor: number;
  quantidadeParcelas: number;
  recorrencia: string;
};

type AtualizarTarifaDialogProps = {
  tarifa: any;
  closeDialog: () => void;
  onUpdateHandler: (tarifaId: string, data: any) => void;
};

const AtualizarTarifaDialog: React.FC<AtualizarTarifaDialogProps> = ({ closeDialog, tarifa, onUpdateHandler }) => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const form = useForm<AtualizarTarifaFormFields>({ defaultValues: tarifa });
  const { control } = form;

  const { maxItemsQuery } = useQuerystring();

  const produtos = useSelector((state) => selectObjectTodosProdutos(state));
  const tiposTransacao = useSelector((state) => selectObjectTodosTiposTransacao(state));

  const onSubmitHandler = (data: AtualizarTarifaFormFields) => {
    onUpdateHandler(tarifa._id, data);
  };

  useEffect(() => {
    dispatch(loadProdutos()).catch((error: Error) => error);
    dispatch(loadTiposTransacao({ query: maxItemsQuery })).catch((error: Error) => error);
  }, [dispatch, maxItemsQuery]);

  return (
    <>
      <BSModal.Header closeButton>
        <BSModal.Title>Atualizar tarifa - {tarifa.descricao}</BSModal.Title>
      </BSModal.Header>
      <BSModal.Body>
        <Form id="form" form={form} onSubmit={onSubmitHandler}>
          <div className="row mb-3">
            <FormSelect
              control={control}
              className="col-xl-6 col-md-6 col-sm-12"
              label="Produto"
              name="produto"
              placeholder="Selecione o produto"
              options={Object.values(produtos ?? {})?.map((produto: any) => ({
                key: produto._id,
                value: produto._id,
                label: `${produto.codigo} - ${produto.nome}`,
              }))}
              rules={{ required: true }}
            />
            <FormSelect
              control={control}
              className="col-xl-6 col-md-6 col-sm-12"
              label="Tipo de transação"
              name="tipoTransacao"
              placeholder="Selecione o tipo de transação"
              options={Object.values(tiposTransacao ?? {})?.map((tipoTransacao: any) => ({
                key: tipoTransacao._id,
                value: tipoTransacao._id,
                label: `${tipoTransacao.codigo} - ${tipoTransacao.descricao}`,
              }))}
              rules={{ required: true }}
            />
          </div>

          <div className="row mb-3">
            <FormSelect
              control={control}
              className="col-xl-6 col-md-6 col-sm-12"
              label="Tipo de tarifa"
              name="tipoTarifa"
              placeholder="Selecione o tipo de tarifa"
              options={Object.entries(tipoTarifaLabelMap).map((elem) => ({
                label: elem[1],
                value: elem[0],
              }))}
              rules={{ required: true }}
            />
            <FormControl
              control={control}
              className="col-xl-6 col-md-6 col-sm-12"
              label="Código"
              name="codigo"
              type="text"
              rules={{ required: true }}
            />
          </div>

          <div className="row mb-3">
            <FormControl
              control={control}
              className="col-xl-6 col-md-6 col-sm-12"
              label="Descrição"
              name="descricao"
              type="text"
            />
            <FormControl
              control={control}
              className="col-xl-6 col-md-6 col-sm-12"
              label="Valor"
              name="valor"
              mask={MasksFormControlEnum.BRL}
              rules={{ required: true, min: 0 }}
            />
          </div>

          <div className="row mb-3">
            <FormControl
              control={control}
              className="col-xl-6 col-md-6 col-sm-12"
              label="Quantidade de parcelas"
              name="quantidadeParcelas"
              type="number"
            />
            <FormSelect
              control={control}
              className="col-xl-6 col-md-6 col-sm-12"
              label="Recorrência"
              name="recorrencia"
              placeholder="Selecione o tipo a recorrência"
              options={Object.entries(periodicidadeLabelMap).map((elem) => ({
                label: elem[1],
                value: elem[0],
              }))}
              rules={{ required: true }}
            />
          </div>
        </Form>
      </BSModal.Body>
      <BSModal.Footer>
        <BSButton variant="light" onClick={closeDialog}>
          Fechar
        </BSButton>
        <BSButton variant="primary" type="submit" form="form">
          Atualizar
        </BSButton>
      </BSModal.Footer>
    </>
  );
};

export default AtualizarTarifaDialog;

import { useCallback, useEffect, useMemo } from 'react';

import ExportCSVButton from '../../components/smart-table/export-csv-button';
import { SmartTable } from '../../components/smart-table/smart-table';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import { FormatValueEnum, formatData, tipoEnderecoLabelMap } from '../../helpers';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import {
  loadEnderecos,
  selectEnderecosLoadingStateByFilters,
  selectEnderecosByFilters,
  selectTotalEnderecosByFilters,
} from '../enderecos/enderecos.redux';

const smartColumns = (): SmartTableColumnProps[] => [
  {
    label: 'Tipo',
    attribute: 'tipo',
    format: FormatValueEnum.ENUM,
    map: tipoEnderecoLabelMap,
    className: 'text-center',
  },
  { label: 'CEP', attribute: 'cep', className: 'text-center' },
  { label: 'Logradouro', attribute: 'logradouro', className: 'text-center' },
  { label: 'Número', attribute: 'numero', className: 'text-center' },
  { label: 'Complemento', attribute: 'complemento', className: 'text-center' },
  { label: 'Bairro', attribute: 'bairro', className: 'text-center' },
  { label: 'Cidade', attribute: 'cidade', className: 'text-center' },
  { label: 'UF', attribute: 'uf', className: 'text-center' },
];

type EnderecosClienteTabProps = {
  clienteId: string;
};
const csvHeaders = [
  { label: 'CEP', key: 'cep' },
  { label: 'Logradouro', key: 'logradouro' },
  { label: 'Número', key: 'numero' },
  { label: 'Complemento', key: 'complemento' },
  { label: 'Bairro', key: 'bairro' },
  { label: 'Cidade', key: 'cidade' },
  { label: 'UF', key: 'uf' },
  { label: 'Data e hora da criação', key: 'dataHoraCriacao' },
];
const EnderecosClienteTab: React.FC<EnderecosClienteTabProps> = ({ clienteId }) => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const filter = useMemo(() => ({ clienteId }), [clienteId]);
  const enderecos = useSelector((state) => selectEnderecosByFilters(state, filter));
  const total = useSelector((state) => selectTotalEnderecosByFilters(state, filter));
  const loadingState = useSelector((state) => selectEnderecosLoadingStateByFilters(state, filter));

  const loadItems = useCallback(() => {
    dispatch(loadEnderecos({ params: { clienteId } })).catch((error: Error) => error);
  }, [clienteId, dispatch]);

  useEffect(() => {
    loadItems();
  }, [loadItems]);

  const enderecosExcel = enderecos?.map(({ _id, ...dadosEndereco }: any) => {
    let novosDados = dadosEndereco;
    novosDados['dataHoraCriacao'] = formatData(dadosEndereco.dataHoraCriacao);
    return novosDados;
  });
  return (
    <div>
      <div className="d-flex align-items-center mb-2">
        <ExportCSVButton data={enderecosExcel} filename="enderecos" headers={csvHeaders} />
      </div>
      <SmartTable
        emptyMessage="Nenhum endereço encontrado"
        errorMessage="Erro ao listar endereços"
        loadItems={loadItems}
        usePagination={true}
        size={total}
        columns={smartColumns()}
        items={enderecos}
        loadingState={loadingState}
      />
    </div>
  );
};

export default EnderecosClienteTab;

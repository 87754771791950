import { useCallback, useEffect, useMemo } from 'react';
import { SmartTable } from '../../components/smart-table/smart-table';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import { FormatValueEnum, formatTelefone, tipoTelefoneLabelMap } from '../../helpers';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import { loadTelefonesCliente, selectTelefonesByFilters } from '../telefones/telefones.redux';

const smartColumns = (): SmartTableColumnProps[] => [
  {
    label: 'Tipo',
    attribute: 'tipo',
    format: FormatValueEnum.ENUM,
    map: tipoTelefoneLabelMap,
    className: 'text-center',
  },
  {
    label: 'Telefone',
    className: 'text-center',
    format: (_, doc: any) => formatTelefone(doc),
  },
];

type TelefonesClienteTabProps = {
  clienteId: string;
};

const TelefonesClienteTab: React.FC<TelefonesClienteTabProps> = ({ clienteId }) => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const filters = useMemo(() => ({ clienteId }), [clienteId]);
  const telefones = useSelector((state) => selectTelefonesByFilters(state, filters));

  const loadItems = useCallback(() => {
    dispatch(loadTelefonesCliente({ params: { clienteId } })).catch((error: Error) => error);
  }, [clienteId, dispatch]);

  useEffect(() => {
    loadItems();
  }, [loadItems]);

  return (
    <div>
      <SmartTable
        emptyMessage="Nenhum endereço encontrado"
        errorMessage="Erro ao listar endereços"
        loadItems={loadItems}
        usePagination={true}
        columns={smartColumns()}
        items={telefones}
      />
    </div>
  );
};

export default TelefonesClienteTab;

import BSButton from 'react-bootstrap/Button';
import BSModal from 'react-bootstrap/Modal';
import { ClassTypesEnum } from '../../helpers';

type InfoDialogProps = {
  onHide: () => void;
  message: string;
  title?: string;
  cancelLabel?: string;
  confirmationLabel?: string;
  type?: ClassTypesEnum;
};

const InfoDialog: React.FC<InfoDialogProps> = ({ title, message, onHide, cancelLabel, confirmationLabel, type }) => {
  return (
    <>
      <BSModal.Header closeButton>
        <BSModal.Title>{title ?? 'Informação'}</BSModal.Title>
      </BSModal.Header>

      <BSModal.Body>
        <p>{message}</p>
      </BSModal.Body>

      <BSModal.Footer>
        <BSButton variant={ClassTypesEnum.LIGHT} onClick={onHide}>
          {cancelLabel ?? 'Fechar'}
        </BSButton>

        <BSButton data-testid="info-dialog" variant={type ?? ClassTypesEnum.PRIMARY} onClick={onHide}>
          {confirmationLabel ?? 'Ok'}
        </BSButton>
      </BSModal.Footer>
    </>
  );
};

export default InfoDialog;

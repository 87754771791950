import { reducerFactory, actionFactory } from '../../store';

const context = 'historicosNoDiaCartao';

const { loadOne: loadOneCartao } = actionFactory(context, {
  buildUrlLoadOne: ({ historicoCartaoId }) => `/api/historicos-cartao/${historicoCartaoId}/dia`,
});

export const loadHistoricoNoDiaCartao = loadOneCartao;

export const historicosNoDiaCartao = reducerFactory({ context });

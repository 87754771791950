import BSCard from 'react-bootstrap/Card';
import { IconType } from 'react-icons';
import { useNavigate } from 'react-router-dom';
import variables from '../../_export.scss';

export type AtalhoCardProps = {
  label: string;
  navigateTo: string;
  Icon: IconType;
};

const AtalhoCard: React.FC<AtalhoCardProps> = ({ Icon, label, navigateTo }) => {
  const navigate = useNavigate();

  const onClickHandler = () => {
    navigate(navigateTo);
  };

  return (
    <BSCard className="grow h-100 px-3 py-4" role="button" onClick={onClickHandler}>
      <div className="d-flex align-items-center h-100">
        <div className="p-1 me-4">
          <Icon size={40} color={variables.primary} />
        </div>

        <div className="d-flex align-items-center flex-grow-1 h-100 p-1">{label}</div>
      </div>
    </BSCard>
  );
};

export default AtalhoCard;

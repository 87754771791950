import React, { useEffect } from 'react';
import BSButton from 'react-bootstrap/Button';
import BSModal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { TipoBloqueioCartaoEnum, TipoCartaoEnum } from '@tamborineapps/lib-enums';
import { Form } from '../../components/form/form';
import { FormSelect } from '../../components/form/form-select';
import { formatCartao } from '../../helpers';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useAppDispatch } from '../../store/hooks-redux';
import { selectSessionUsuario } from '../session-usuario/session-usuario.redux';
import {
  loadTiposBloqueiosCartao,
  selectTiposBloqueioCartaoByFilters,
} from '../tipos-bloqueio-cartao/tipos-bloqueio-cartao.redux';

type IncluirBloqueioCartaoFormFields = {
  tipoBloqueio: string;
};

type IncluirBloqueioCartaoDialogProps = {
  cartao: any;
  closeDialog: () => void;
  onUpsertHandler: (cartaoId: string, data: any) => void;
};

const IncluirBloqueioCartaoDialog: React.FC<IncluirBloqueioCartaoDialogProps> = ({
  cartao,
  closeDialog,
  onUpsertHandler,
}) => {
  const dispatch = useAppDispatch();
  const { maxItemsQuery } = useQuerystring();

  const form = useForm<IncluirBloqueioCartaoFormFields>();
  const { control } = form;

  const [sessionUsuario] = useSelector((state) => selectSessionUsuario(state, {})) ?? [];
  const tiposBloqueiosCartao = useSelector((state) => selectTiposBloqueioCartaoByFilters(state, maxItemsQuery));
  useEffect(() => {
    dispatch(loadTiposBloqueiosCartao({ query: maxItemsQuery })).catch((error: Error) => error);
  }, [dispatch, maxItemsQuery]);
  const temPermissaoInclusaoBloqueioDefinitivoCartao = !!Boolean(
    sessionUsuario?.papelUsuario?.permissoes?.INCLUSAO_BLOQUEIO_PERMANENTE_CARTAO
  );

  return (
    <>
      <BSModal.Header closeButton>
        <BSModal.Title>Incluir bloqueio cartão - {formatCartao(cartao.numeroTruncado)}</BSModal.Title>
      </BSModal.Header>

      <BSModal.Body>
        <Form id="form" form={form} onSubmit={(data) => onUpsertHandler(cartao._id, data)}>
          <FormSelect
            control={control}
            className="mb-3"
            name="tipoBloqueio"
            label="Tipo de bloqueio"
            placeholder="Selecione o tipo de bloqueio"
            options={
              cartao.tipoCartao === TipoCartaoEnum.VIRTUAL
                ? tiposBloqueiosCartao
                    ?.filter((tipoBloqueioCartao: any) =>
                      [
                        TipoBloqueioCartaoEnum.BLOQUEIO_USUARIO,
                        TipoBloqueioCartaoEnum.CANCELAMENTO_ECOMMERCE,
                        TipoBloqueioCartaoEnum.CANCELAMENTO_CLIENTE,
                        TipoBloqueioCartaoEnum.BLOQUEIO_USUARIO,
                      ].includes(tipoBloqueioCartao.operacao)
                    )
                    .map((tipoBloqueioCartao: any) => ({
                      key: tipoBloqueioCartao._id,
                      value: tipoBloqueioCartao._id,
                      label: `${tipoBloqueioCartao.operacao} - ${tipoBloqueioCartao.descricao}`,
                    }))
                : tiposBloqueiosCartao
                    ?.filter((tipoBloqueioCartao: any) =>
                      temPermissaoInclusaoBloqueioDefinitivoCartao ? true : !tipoBloqueioCartao.bloqueioDefinitivo
                    )
                    .map((tipoBloqueioCartao: any) => ({
                      key: tipoBloqueioCartao._id,
                      value: tipoBloqueioCartao._id,
                      label: `${tipoBloqueioCartao.codigo} - ${tipoBloqueioCartao.descricao}`,
                    }))
            }
            rules={{ required: true }}
          />
        </Form>
      </BSModal.Body>

      <BSModal.Footer>
        <BSButton variant="light" onClick={closeDialog}>
          Fechar
        </BSButton>
        <BSButton variant="primary" type="submit" form="form">
          Incluir bloqueio
        </BSButton>
      </BSModal.Footer>
    </>
  );
};

export default IncluirBloqueioCartaoDialog;

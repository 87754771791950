import React, { createContext, useCallback, useState } from 'react';
import BSModal, { ModalProps as BSModalProps } from 'react-bootstrap/Modal';

export interface DialogProps extends BSModalProps {
  component: JSX.Element;
}

export type DialogContextProps = {
  showDialog: (dialogProps: DialogProps) => void;
  closeDialog: () => void;
};

export const DialogContext = createContext<DialogContextProps | null>(null);

export const DialogProvider = ({ children }: { children: React.ReactNode }) => {
  const [dialog, showDialog] = useState<DialogProps | null>(null);

  const closeDialog = useCallback(() => {
    showDialog(null);
  }, [showDialog]);

  return (
    <DialogContext.Provider value={{ closeDialog, showDialog }}>
      {children}
      <BSModal
        {...dialog}
        show={Boolean(dialog)}
        onHide={closeDialog}
        size={dialog?.size}
        centered={dialog?.centered ?? true}
        scrollable={dialog?.scrollable ?? true}
      >
        {dialog?.component}
      </BSModal>
    </DialogContext.Provider>
  );
};

import { AcaoPermissaoPapelUsuarioEnum, TipoRelatorioOperacaoEnum } from '@tamborineapps/lib-enums';
import { useCallback, useState } from 'react';
import BSButton from 'react-bootstrap/Button';
import BSSpinner from 'react-bootstrap/Spinner';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { DetailCard } from '../../components/details/datail-card';
import DetailTitle from '../../components/details/detail-title';
import { Form } from '../../components/form/form';
import { FormControl } from '../../components/form/form-control';
import { FormSelect } from '../../components/form/form-select';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import { ApiSingleElementResponse, ClassTypesEnum, tipoRelatorioOperacaoLabelMap } from '../../helpers';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch } from '../../store/hooks-redux';
import { gerarRelatorioOperacao } from './gerar-relatorio-operacao.redux';

type GerarRelatorioOperacaoFormFields = {
  tipoRelatorioOperacao: string;
  dataInicio: string;
  dataFim: string;
};

const GerarRelatorioOperacaoPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { showToast } = useToasts();

  const form = useForm<GerarRelatorioOperacaoFormFields>();
  const { control, reset, watch } = form;
  const tipoRelatorioOperacao = watch('tipoRelatorioOperacao');

  const [geracaoRelatorioOperacaoEmProcessamento, setGeracaoRelatorioOperacaoEmProcessamento] =
    useState<boolean>(false);

  const onSubmitHandler = useCallback(
    (data: GerarRelatorioOperacaoFormFields) => {
      setGeracaoRelatorioOperacaoEmProcessamento(true);

      const { tipoRelatorioOperacao, ...parametros } = data;

      dispatch(gerarRelatorioOperacao({ data: { parametros, tipoRelatorioOperacao } }))
        .then(({ payload: { data } }: ApiSingleElementResponse) => {
          showToast({ message: 'Processamento do relatório executado com sucesso', type: ClassTypesEnum.SUCCESS });

          return navigate(`/protocolos-processamento-lote/${data._id}`);
        })
        .catch((error: Error) => {
          setGeracaoRelatorioOperacaoEmProcessamento(false);
          showToast({ message: error.message, type: ClassTypesEnum.DANGER });
        });
    },
    [dispatch, navigate, showToast]
  );

  const onCancelHandler = () => {
    reset();
  };

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.GERACAO_RELATORIO_OPERACAO}>
      <DetailCard>
        <div className="mb-5">
          <DetailTitle renderBackButton={false}>Gerar relatório de operação</DetailTitle>
        </div>

        <Form form={form} onSubmit={onSubmitHandler}>
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-4">
              <FormSelect
                control={control}
                name="tipoRelatorioOperacao"
                label="Tipo de relatório"
                placeholder="Selecione o tipo de relatório"
                options={Object.entries(tipoRelatorioOperacaoLabelMap)?.map(([key, value]) => ({
                  key,
                  value: key,
                  label: value,
                }))}
                rules={{ required: true }}
              />
            </div>

            {tipoRelatorioOperacao && renderInputElement(tipoRelatorioOperacao as TipoRelatorioOperacaoEnum)}

            <div className="col-12 d-flex">
              <BSButton
                className="mx-1"
                variant="light"
                onClick={onCancelHandler}
                disabled={geracaoRelatorioOperacaoEmProcessamento}
              >
                Cancelar
              </BSButton>
              <BSButton
                className="mx-1 px-4"
                variant="primary"
                type="submit"
                disabled={geracaoRelatorioOperacaoEmProcessamento}
              >
                Gerar relatório de operação
              </BSButton>
            </div>
          </div>
        </Form>

        {geracaoRelatorioOperacaoEmProcessamento && (
          <div className="d-flex justify-content-center align-items-center mt-5">
            <div className="me-3">
              <BSSpinner variant="primary" />
            </div>
            <strong>Geração do relatório em processamento...</strong>
          </div>
        )}
      </DetailCard>
    </RbacPage>
  );

  function renderInputElement(tipoRelatorioOperacao: TipoRelatorioOperacaoEnum) {
    switch (tipoRelatorioOperacao) {
      case TipoRelatorioOperacaoEnum.AUTORIZACOES:
        return (
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-4">
              <FormControl
                control={control}
                name="dataInicio"
                label="Data incial"
                rules={{ required: true }}
                type="date"
              />
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <FormControl control={control} name="dataFim" label="Data final" rules={{ required: true }} type="date" />
            </div>
          </div>
        );

      default:
        return <></>;
    }
  }
};

export default GerarRelatorioOperacaoPage;

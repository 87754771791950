import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import { useCallback, useEffect, useMemo, useState } from 'react';
import BSBadge from 'react-bootstrap/Badge';
import BSButton from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';

import DetailElement from '../../components/details/detail-element';
import DetailSubTitle from '../../components/details/detail-subtitle';
import ConfirmationDialog from '../../components/dialog/confirmation-dialog';
import { Loading } from '../../components/loading';
import RbacElement from '../../components/role-based-access-control/role-based-access-control-element';
import { SmartTable } from '../../components/smart-table/smart-table';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import {
  ApiSingleElementResponse,
  ClassTypesEnum,
  FormatValueEnum,
  codigoConfirmacaoClienteLabelMap,
  statusProcessoAlteracaoDadosUsuarioClienteColorMap,
  statusProcessoAlteracaoDadosUsuarioClienteLabelMap,
} from '../../helpers';
import { useDialog } from '../../hooks/dialog/use-dialog';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import {
  loadProcessosAlteracaoDadosUsuarioCliente,
  selectProcessosAlteracaoDadosUsuarioClienteByFilters,
  selectProcessosAlteracaoDadosUsuarioClienteLoadingStateByFilters,
  selectTotalProcessosAlteracaoDadosUsuarioClienteByFilters,
} from '../processo-alteracao-dados-usuario-cliente/processo-alteracao-dados-usuario-cliente.redux';
import {
  loadUsuarioClientePorCliente,
  selectClientesUsuarioLoadingStateByFilters,
  removerBloqueio,
} from '../usuarios-clientes/usuario-cliente.redux';

const smartColumns = (): SmartTableColumnProps[] => [
  {
    label: 'Data hora de início',
    attribute: 'dataHoraInicio',
    className: 'text-center',
    format: FormatValueEnum.DATE_TIME,
    sortable: true,
  },
  {
    label: 'Data hora de finalização',
    attribute: 'dataHoraFinalizacao',
    className: 'text-center',
    format: FormatValueEnum.DATE_TIME,
    sortable: true,
  },
  {
    label: 'Tipo de alteração',
    attribute: 'tipoAlteracao',
    format: FormatValueEnum.ENUM,
    map: codigoConfirmacaoClienteLabelMap,
    className: 'text-center',
  },
  {
    label: 'Status',
    attribute: 'status',
    className: 'text-center',
    format(status: string) {
      return (
        <h5 className="mb-0">
          <BSBadge
            pill
            bg={
              statusProcessoAlteracaoDadosUsuarioClienteColorMap[
                status as keyof typeof statusProcessoAlteracaoDadosUsuarioClienteColorMap
              ]
            }
          >
            {
              statusProcessoAlteracaoDadosUsuarioClienteLabelMap[
                status as keyof typeof statusProcessoAlteracaoDadosUsuarioClienteLabelMap
              ]
            }
          </BSBadge>
        </h5>
      );
    },
  },
];

type AplicativoTabProps = {
  clienteId: string;
};

const AplicativoTab: React.FC<AplicativoTabProps> = ({ clienteId }) => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;
  const { showDialog, closeDialog } = useDialog();
  const navigate = useNavigate();
  const { query } = useQuerystring();
  const { showToast } = useToasts();

  // TODO: avaliar uso do useState
  const [usuarioCliente, setUsuarioCliente] = useState<any>();
  const filters = useMemo(() => ({ ...query, usuarioClienteId: usuarioCliente?._id }), [query, usuarioCliente]);

  const loadingStateUsuarioCliente = useSelector((state) =>
    selectClientesUsuarioLoadingStateByFilters(state, { clienteId })
  );

  const totalProcessosAlteracaoDadosUsuarioCliente = useSelector((state) =>
    selectTotalProcessosAlteracaoDadosUsuarioClienteByFilters(state, filters)
  );
  const alteracoesDadosAcessoUsuarioCliente = useSelector((state) =>
    selectProcessosAlteracaoDadosUsuarioClienteByFilters(state, filters)
  );
  const loadingStateProcessosAlteracaoDadosUsuarioCliente = useSelector((state) =>
    selectProcessosAlteracaoDadosUsuarioClienteLoadingStateByFilters(state, filters)
  );

  const loadUsuarioCliente = useCallback(
    () =>
      dispatch(loadUsuarioClientePorCliente({ clienteId }))
        .then(({ payload: { data } }: ApiSingleElementResponse) => setUsuarioCliente(data))
        .catch((error: Error) => {
          showToast({ message: error.message, type: ClassTypesEnum.DANGER });
        }),
    [clienteId, dispatch, showToast]
  );

  const loadAlteracaoDadosAcesso = useCallback(() => {
    dispatch(
      loadProcessosAlteracaoDadosUsuarioCliente({ params: { usuarioClienteId: usuarioCliente?._id }, query })
    ).catch((error: Error) => error);
  }, [dispatch, usuarioCliente, query]);

  const onRemoverBloqueioConfirmationHandler = () => {
    dispatch(removerBloqueio({ params: { usuarioClienteId: usuarioCliente?._id }, data: {} }))
      .then(() => {
        showToast({
          message: 'Desbloqueio realizado com sucesso',
          type: ClassTypesEnum.SUCCESS,
        });

        return loadUsuarioCliente();
      })
      .catch((error: Error) => error);
  };

  useEffect(() => {
    loadUsuarioCliente();
  }, [loadUsuarioCliente]);

  if (!usuarioCliente) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Loading notFoundMessage="Usuário cliente não encontrado" loadingState={loadingStateUsuarioCliente} />
      </div>
    );
  }

  return (
    <>
      <div className="mb-4">
        <DetailSubTitle>Segurança</DetailSubTitle>
      </div>

      <div className="row">
        <div className="col-lg-4 col-md-6 mb-4">
          <DetailElement
            descricao="Possui bloqueio por senha incorreta"
            valor={usuarioCliente.possuiBloqueioSenhaIncorreta}
            format={FormatValueEnum.BOOL}
          />
          <DetailElement
            descricao="Data e hora da expiração do bloqueio"
            valor={usuarioCliente.dataHoraExpiracaoBloqueioSenhaIncorreta}
            format={FormatValueEnum.DATE_TIME}
          />
        </div>

        <div className="col-lg-4 col-md-6 mb-4">
          <DetailElement
            descricao="Quantidade de tentativas de senhas inválidas"
            valor={usuarioCliente.quantidadeTentativasSenhaInvalida}
          />
          <DetailElement
            descricao="Autenticação biométrica habilitada"
            valor={usuarioCliente.permissaoBiometria}
            format={FormatValueEnum.BOOL}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12 d-flex justify-content-end flex-wrap">
          <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.REMOCAO_BLOQUEIO_POR_SENHA_USUARIO_CLIENTE}>
            <div className="m-1">
              <BSButton
                disabled={!usuarioCliente.possuiBloqueioSenhaIncorreta}
                onClick={() =>
                  showDialog({
                    component: (
                      <ConfirmationDialog
                        onConfirmation={onRemoverBloqueioConfirmationHandler}
                        onHide={closeDialog}
                        title="Remover bloqueio por senha"
                        message="Tem certeza que deseja remover o bloqueio por senha do usuário?"
                        confirmationLabel="Remover bloqueio"
                      />
                    ),
                  })
                }
              >
                Remover bloqueio por senha
              </BSButton>
            </div>
          </RbacElement>
        </div>
      </div>

      <div className="my-4">
        <hr />
      </div>

      <div className="mb-4">
        <DetailSubTitle>Alteração de dados de acesso ao aplicativo móvel</DetailSubTitle>
      </div>

      <SmartTable
        emptyMessage="Nenhuma alteração de dados de acesso encontrada"
        errorMessage="Erro ao listar alterações de dados de acesso"
        loadItems={loadAlteracaoDadosAcesso}
        usePagination={true}
        size={totalProcessosAlteracaoDadosUsuarioCliente}
        columns={smartColumns()}
        items={alteracoesDadosAcessoUsuarioCliente}
        loadingState={loadingStateProcessosAlteracaoDadosUsuarioCliente}
        onRowClick={(doc) => navigate(`processo-alteracao-dados-usuario-cliente/${doc._id}`)}
      />
    </>
  );
};

export default AplicativoTab;

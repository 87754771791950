import { BsCaretDownFill, BsCaretUpFill } from 'react-icons/bs';
import { useSearchParams } from 'react-router-dom';
import { FormatValueEnum } from '../../helpers';
import { useQuerystring } from '../../hooks/router/use-querystring';

type FormatFnType = (
  attribuleValue?: any,
  doc?: any
) => React.ReactNode | JSX.Element | string | number | undefined | null;

export type SmartTableColumnProps = {
  label: string;
  className?: string;
  attribute?: string;
  format?: FormatValueEnum | FormatFnType;
  map?: any;
  sortable?: boolean;
  selectable?: boolean;
};

type SmartTableHeaderProps = {
  columns: SmartTableColumnProps[];
};

export const SmartTableHeader: React.FC<SmartTableHeaderProps> = ({
  columns,
}: {
  columns: SmartTableColumnProps[];
}) => {
  const {
    query: { sort: sortAttribute },
  } = useQuerystring();
  const [searchParams, setSearchParams] = useSearchParams();

  const isAscSort = !(sortAttribute as string)?.startsWith('-');

  return (
    <thead>
      <tr>
        {columns.map((column: SmartTableColumnProps, columnIndex: number) => {
          const onSortClickHandler = () => {
            if (isAscSort) {
              searchParams.set('sort', `-${column.attribute}`);
            } else {
              searchParams.set('sort', column.attribute ?? '');
            }

            return setSearchParams(searchParams);
          };

          return (
            <th key={columnIndex} className={`${column.className || 'text-center'} text-nowrap`}>
              {column.sortable ? (
                <div role="button" onClick={onSortClickHandler}>
                  <small className="me-2">{column.label}</small>
                  <SortIcon
                    isAscSort={isAscSort}
                    attribute={column.attribute ?? ''}
                    sortAttribute={(sortAttribute as string) ?? ''}
                  />
                </div>
              ) : (
                <small data-testid="smart-table-header">{column.label}</small>
              )}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

type SortIconProps = {
  attribute: string;
  isAscSort: boolean;
  sortAttribute: string;
};

const SortIcon: React.FC<SortIconProps> = ({ attribute, isAscSort, sortAttribute }) => {
  const isAttribute = sortAttribute.replace('-', '').includes(attribute);

  return !isAttribute ? <BsCaretUpFill /> : isAscSort ? <BsCaretUpFill /> : <BsCaretDownFill />;
};

import { useSearchParams } from 'react-router-dom';
import { useQuerystring } from '../../../hooks/router/use-querystring';
import { getPaginationProps } from '../smart-table.utils';
import Pages from './pages';
import Sizes from './sizes';

type PaginationProps = {
  size: number;
};

export const Pagination: React.FC<PaginationProps> = ({ size = 0 }) => {
  const { query: routerQuery, maxItemsQuery } = useQuerystring();
  const [searchParams, setSearchParams] = useSearchParams();
  const query = { ...maxItemsQuery, ...routerQuery };

  const { numPages, page, pageSize } = getPaginationProps({ page: query.page, pageSize: query.pageSize, size });

  const tableSizes = [];

  if (size > 10 && size <= 20) {
    tableSizes.push(10, 20);
  }
  if (size > 20 && size <= 50) {
    tableSizes.push(10, 20, 50);
  }
  if (size > 50) {
    tableSizes.push(10, 20, 50, 100);
  }

  const onChangePage = (page: number) => {
    const currentPageSize = searchParams.get('pageSize');
    searchParams.set('pageSize', currentPageSize ?? maxItemsQuery.pageSize);
    searchParams.set('page', page.toString());

    return setSearchParams(searchParams);
  };

  const onChangeSize = (pageSize: number) => {
    const currentPage = searchParams.get('page');
    searchParams.set('page', currentPage ?? maxItemsQuery.page);
    searchParams.set('pageSize', pageSize.toString());

    return setSearchParams(searchParams);
  };

  return (
    <div data-testid="pagination" className="d-flex align-items-center justify-content-between mt-4">
      <Sizes onChangeSize={onChangeSize} options={tableSizes} pageSize={pageSize} />
      <Pages page={page} numPages={numPages} minimumSize={tableSizes[0]} size={size} onChangePage={onChangePage} />
    </div>
  );
};

import BSButton from 'react-bootstrap/Button';
import BSModal from 'react-bootstrap/Modal';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Form } from '../../components/form/form';
import { FormCheck } from '../../components/form/form-check';
import { FormControl } from '../../components/form/form-control';
import { ClassTypesEnum } from '../../helpers';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch } from '../../store/hooks-redux';
import { createGrupoCredenciaisCliente } from './grupo-credenciais-cliente.redux';

type CadastrarGrupoCredencialClienteFormFields = {
  nome: string;
  ativo: boolean;
};

type CadastrarGrupoCredencialClienteDialogProps = {
  closeDialog: () => void;
  reload: () => void;
};

const CadastrarGrupoCredencialClienteDialog: React.FC<CadastrarGrupoCredencialClienteDialogProps> = ({
  closeDialog,
  reload,
}) => {
  const dispatch = useAppDispatch();
  const { showToast } = useToasts();
  const form = useForm<CadastrarGrupoCredencialClienteFormFields>();
  const { control } = form;
  const onSubmitHandler = useCallback(
    (data: CadastrarGrupoCredencialClienteFormFields) => {
      dispatch(createGrupoCredenciaisCliente({ data }))
        .then(() => {
          closeDialog();
          showToast({
            message: 'Grupo de credenciais do cliente cadastrado com sucesso',
            type: ClassTypesEnum.SUCCESS,
          });
          reload();
        })
        .catch((error: Error) => {
          showToast({ message: error.message, type: ClassTypesEnum.DANGER });
        });
    },
    [closeDialog, dispatch, reload, showToast]
  );

  return (
    <>
      <BSModal.Header closeButton>
        <BSModal.Title>Novo grupo de credenciais</BSModal.Title>
      </BSModal.Header>
      <BSModal.Body>
        <Form id="form" form={form} onSubmit={onSubmitHandler}>
          <FormControl control={control} className="mb-3" label="Nome" name="nome" rules={{ required: true }} />
          <FormCheck control={control} className="mb-3" name="ativo" label="Ativo" type="switch" />
        </Form>
      </BSModal.Body>
      <BSModal.Footer>
        <BSButton variant="light" onClick={closeDialog}>
          Fechar
        </BSButton>
        <BSButton variant="primary" type="submit" form="form">
          Cadastrar grupo
        </BSButton>
      </BSModal.Footer>
    </>
  );
};

export default CadastrarGrupoCredencialClienteDialog;

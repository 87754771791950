import { actionFactory, reducerFactory, selectorFactory } from '../../store';

const context = 'cartoes';

const {
  loadMany,
  loadOne,
  updateOne: updateOneNovaVia,
} = actionFactory(context, {
  buildUrlLoadOne: ({ cartaoId }) => `/api/cartoes/${cartaoId}`,
  buildUrlLoadMany: () => `/api/cartoes`,
  buildUrlUpdateOne: ({ cartaoId }) => `/api/cartoes/${cartaoId}/nova-via`,
});

const { loadMany: loadManyContaCartao, updateOne: updateOnePermissoes } = actionFactory(context, {
  buildUrlUpdateOne: ({ cartaoId }) => `/api/cartoes/${cartaoId}/permissoes`,
  buildUrlLoadMany: ({ contaCartaoId }) => `/api/contas-cartao/${contaCartaoId}/cartoes`,
});

const { updateOne: updateOneBloqueio } = actionFactory(context, {
  buildUrlUpdateOne: ({ cartaoId }) => `/api/cartoes/${cartaoId}/bloqueio`,
});

const { updateOne: updateOneDesbloqueio } = actionFactory(context, {
  buildUrlUpdateOne: ({ cartaoId }) => `/api/cartoes/${cartaoId}/desbloqueio`,
});

const { updateOne: updateOneCartaSenha } = actionFactory(context, {
  buildUrlUpdateOne: ({ cartaoId }) => `/api/cartoes/${cartaoId}/carta-senha`,
});

const { selectOneById, selectManyByFilters, selectObjectAllIds, selectTotalByFilters, selectLoadingStateByFilters } =
  selectorFactory({ context });

const { createOne: createOneCartaoAdicional } = actionFactory(context, {
  buildUrlCreateOne: ({ contaCartaoId }) => `/api/contas-cartao/${contaCartaoId}/cartao-adicional`,
});
export const solicitarCartaoAdicionalContaCartao = createOneCartaoAdicional;

export const loadCartao = loadOne;
export const loadCartoes = loadMany;
export const loadCartoesContaCartao = loadManyContaCartao;

export const updateBloqueioCartao = updateOneBloqueio;
export const updateDesbloqueioCartao = updateOneDesbloqueio;
export const updateNovaCartaSenha = updateOneCartaSenha;
export const updatePermissoesCartao = updateOnePermissoes;
export const updateSolicitarNovaVia = updateOneNovaVia;

export const selectCartaoById = selectOneById;
export const selectObjectTodosCartoes = selectObjectAllIds;
export const selectCartoesByFilters = selectManyByFilters;
export const selectTotalCartoesByFilters = selectTotalByFilters;
export const selectCartaoLoadingStateByFilters = selectLoadingStateByFilters;

export const cartoes = reducerFactory({ context });

import DetailElement from '../../components/details/detail-element';
import { FormatValueEnum } from '../../helpers';
import { useAppSelector } from '../../store/hooks-redux';
import { selectEmissor } from './emissor.redux';

const AliquotasEmissorTab: React.FC = () => {
  const useSelector = useAppSelector;
  const [emissor] = useSelector((state) => selectEmissor(state, {})) ?? [];

  return (
    <div className="row">
      <div className="col-lg-4 col-md-6 mb-4">
        <DetailElement
          descricao="IOF adicional"
          valor={emissor.aliquotaIofAdicional}
          format={FormatValueEnum.PERCENT}
        />
        <DetailElement descricao="IOF diário" valor={emissor.aliquotaIofDiario} format={FormatValueEnum.PERCENT} />
        <DetailElement
          descricao="IOF diário acumulado"
          valor={emissor.aliquotaMaximaIofDiarioAcumulado}
          format={FormatValueEnum.PERCENT}
        />
        <DetailElement
          descricao="IOF internacional"
          valor={emissor.aliquotaIofInternacional}
          format={FormatValueEnum.PERCENT}
        />
        <DetailElement
          descricao="IOF saque crédito"
          valor={emissor.aliquotaIofSaqueCredito}
          format={FormatValueEnum.PERCENT}
        />
      </div>
    </div>
  );
};

export default AliquotasEmissorTab;

import { useCallback, useEffect, useMemo } from 'react';
import BSTab from 'react-bootstrap/Tab';
import BSTabs from 'react-bootstrap/Tabs';
import { useParams } from 'react-router-dom';
import { DetailCard } from '../../components/details/datail-card';
import DetailElement from '../../components/details/detail-element';
import DetailSubTitle from '../../components/details/detail-subtitle';
import DetailTitle from '../../components/details/detail-title';
import { Loading } from '../../components/loading';
import Page from '../../components/page';
import {
  ApiSingleElementResponse,
  FormatValueEnum,
  modeloCalculoPagamentoMinimoLabelMap,
  subOrigemLabelMap,
  subTaxaLabelMap,
} from '../../helpers';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import {
  loadParametroGeracaoBoleto,
  selectParametroGeracaoBoletoById,
} from '../parametros-geracao-boleto/parametros-geracao-boleto.redux';
import {
  loadParametroGeracaoCartao,
  selectParametroGeracaoCartaoPorID,
} from '../parametros-geracao-cartao/parametros-geracao-cartao.redux';
import CalendariosCorteProdutoTab from './calendarios-corte-produto-tab';
import CartoesProdutoTab from './cartoes-produto-tab';
import FaixasEtariasTab from './faixas-etarias-produto-tab';
import OpcoesParcelamentoFaturaProdutoTab from './opcoes-parcelamento-fatura-produto-tab';
import OperacoesPermitidasProdutoTab from './operacoes-permitidas-produto-tab';
import ParametrosGeracaoCartaoProdutoTab from './parametros-geracao-cartao-produto-tab';
import PermissoesProdutoTab from './permissoes-produto-tab';
import { loadProduto, selectProdutoById, selectProdutosLoadingStateByFilters } from './produtos.redux';
import VencimentosDisponiveisProdutoTab from './vencimentos-disponiveis-produto-tab';

const DetalhesProdutoPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const params = useParams();
  const produtoId = params.produtoId as string;

  const _loadParametroGeracaoBoleto = useCallback(
    (produto: any) => {
      dispatch(loadParametroGeracaoBoleto({ parametroGeracaoBoletoId: produto.parametrosGeracaoBoleto })).catch(
        (error: Error) => error
      );
    },
    [dispatch]
  );

  const _loadParametroGeracaoCartao = useCallback(
    (produto: any) => {
      dispatch(loadParametroGeracaoCartao({ parametroGeracaoCartaoId: produto.parametrosGeracaoCartao })).catch(
        (error: Error) => error
      );
    },
    [dispatch]
  );

  const filters = useMemo(() => ({ produtosId: produtoId }), [produtoId]);
  const produto = useSelector((state) => selectProdutoById(state, produtoId));
  const loadingState = useSelector((state) => selectProdutosLoadingStateByFilters(state, filters));
  const parametrosGeracaoBoleto = useSelector((state) =>
    selectParametroGeracaoBoletoById(state, produto?.parametrosGeracaoBoleto)
  );
  const parametrosGeracaoCartao = useSelector((state) =>
    selectParametroGeracaoCartaoPorID(state, produto?.parametrosGeracaoCartao)
  );

  useEffect(() => {
    dispatch(loadProduto({ produtoId }))
      .then(({ payload: { data } }: ApiSingleElementResponse) => {
        _loadParametroGeracaoBoleto(data);
        _loadParametroGeracaoCartao(data);
      })
      .catch((error: Error) => error);
  }, [_loadParametroGeracaoBoleto, _loadParametroGeracaoCartao, dispatch, produtoId]);

  if (!produto) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Loading notFoundMessage="Produto não encontrado" loadingState={loadingState} />
      </div>
    );
  }

  return (
    <Page>
      <div className="mb-5">
        <DetailCard>
          <div className="mb-5">
            <DetailTitle>
              Produto {produto.nome} ({produto.codigo})
            </DetailTitle>
          </div>

          <div className="row mb-4">
            <div className="col-lg-4 col-md-6 mb-4">
              <DetailElement descricao="Tipo de crédito" valor={produto.tipoCredito} />
              <DetailElement descricao="Código no emissor" valor={produto.codigoNoEmissor} />
              <DetailElement descricao="Identidade visual" valor={produto.identidadeVisual} />
              <DetailElement
                descricao="Parâmetro de geração de cartão"
                valor={parametrosGeracaoCartao?.codigo}
                link={`/parametros-geracao-boleto/${produto.parametrosGeracaoBoleto}`}
              />
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <DetailElement descricao="Origem de Recursos" valor={produto?.origemRecursos} />
              <DetailElement
                descricao="Sub modalidade da origem dos recursos"
                valor={subOrigemLabelMap[produto?.subOrigem as keyof typeof subOrigemLabelMap]}
                format={FormatValueEnum.ENUM}
                map={subOrigemLabelMap}
              />
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <DetailElement
                descricao="Exclusivo para clientes pré-aprovados "
                valor={produto.exclusivoClientesPreAprovados}
                format={FormatValueEnum.BOOL}
              />
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <div className="mb-3">
                <DetailSubTitle>Limites e valores</DetailSubTitle>
              </div>
              <DetailElement
                descricao="Limite de crédito máximo por conta"
                valor={produto.limiteCreditoMaximoPorConta}
                format={FormatValueEnum.BRL}
              />
              <DetailElement
                descricao="Limite de crédito minimo por conta"
                valor={produto.limiteCreditoMinimoPorConta}
                format={FormatValueEnum.BRL}
              />
              <DetailElement
                descricao="Limite de crédito padrão"
                valor={produto.limiteCreditoPadrao}
                format={FormatValueEnum.BRL}
              />
              <DetailElement
                descricao="Valor mínimo para envio da fatura"
                valor={produto.valorMinimoEnvioFatura}
                format={FormatValueEnum.BRL}
              />
              <DetailElement
                descricao="Valor mínimo para cobrança"
                valor={produto.saldoMinimoParaCobranca}
                format={FormatValueEnum.BRL}
              />
              <DetailElement
                descricao="Valor de tolerância para o saldo devido"
                valor={produto.valorToleranciaSaldoDevido}
                format={FormatValueEnum.BRL}
              />
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <div className="mb-3">
                <DetailSubTitle>Fatura</DetailSubTitle>
              </div>
              <DetailElement
                descricao="Data de prévia"
                valor={produto.diaPrevia}
                dica={<>Dia do mês em que o corte da prévia é feito</>}
              />
              <DetailElement
                descricao="Dia de referência para data de corte"
                valor={produto.diaCorte}
                dica={<>Dia do mês em que o corte da fatura é feito</>}
              />
              <DetailElement
                descricao="Dia de vencimento"
                valor={produto.diaVencimento}
                dica={<>Dia do mês em que vencem as faturas dos clientes do produto</>}
              />
              <DetailElement
                descricao="Parâmetro de geração de boleto"
                valor={parametrosGeracaoBoleto?.nomeBancoCedente}
                link={`/parametros-geracao-boleto/${produto.parametrosGeracaoBoleto}`}
              />
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <div className="mb-3">
                <DetailSubTitle>Prévia</DetailSubTitle>
              </div>
              <DetailElement
                descricao="Modelo de cálculo"
                valor={produto.modeloCalculoPagamentoMinimo}
                format={FormatValueEnum.ENUM}
                map={modeloCalculoPagamentoMinimoLabelMap}
                dica={
                  <>
                    Modelo de cálculo utilizado para calcular os valores de pagamento mínimo e desconto em folha.
                    Valores válidos: Valor Margem Consignada ou Percentual.
                  </>
                }
              />
              <DetailElement
                descricao="Mínimo de desconto em folha"
                valor={produto.valorMinimoDescontoEmFolha}
                format={FormatValueEnum.BRL}
              />
              <DetailElement
                descricao="Valor mínimo para pagamento mínimo"
                valor={produto.valorMinimoPagamentoMinimo}
                format={FormatValueEnum.BRL}
              />
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <div className="mb-3">
                <DetailSubTitle>Juros</DetailSubTitle>
              </div>
              <DetailElement descricao="Rotativo" valor={produto.taxaJurosRotativo} format={FormatValueEnum.PERCENT} />
              <DetailElement descricao="Saque" valor={produto.taxaJurosSaque} format={FormatValueEnum.PERCENT} />
              <DetailElement
                descricao="Compra parcelada emissor"
                valor={produto.taxaJurosCompraParceladaEmissor}
                format={FormatValueEnum.PERCENT}
              />
              <DetailElement
                descricao="Taxa efetiva Anual"
                valor={produto.taxaEfetivaAnual}
                format={FormatValueEnum.PERCENT}
              />
              <DetailElement
                descricao="Taxa Referencial"
                valor={produto.taxaReferencial}
                format={FormatValueEnum.ENUM}
                map={subTaxaLabelMap}
                dica={
                  <>
                    Tipo de taxa de referência utilizada pelo produto: Prefixado, Pós-fixado, Flutuantes, Índices de
                    preços, Crédito rural, Outros indexadores
                  </>
                }
              />
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <div className="mb-3">
                <DetailSubTitle>Percentuais</DetailSubTitle>
              </div>
              <DetailElement
                descricao="Excesso de limite permitido"
                format={FormatValueEnum.PERCENT}
                valor={produto.percentualUtilizacaoAcimaLimite}
              />
              <DetailElement
                descricao="Pagamento mínimo bloqueio definitivo"
                format={FormatValueEnum.PERCENT}
                valor={produto.percentualPagamentoMinimoBloqueioDefinitivo}
              />
              <DetailElement
                descricao="Limite de crédito em saque"
                format={FormatValueEnum.PERCENT}
                valor={produto.percentualLimiteCreditoSaque}
              />
              <DetailElement
                descricao="Tolerância para o saldo devido"
                format={FormatValueEnum.PERCENT}
                valor={produto.percentualToleranciaSaldoDevido}
              />
              <DetailElement
                descricao="Percentual de pagamento minímo"
                valor={produto.percentualPagamentoMinimo}
                format={FormatValueEnum.PERCENT}
              />
              <DetailElement
                descricao="Percentual indexador"
                valor={produto.percentualIndexador}
                format={FormatValueEnum.PERCENT}
                dica={<>Percentual praticado pela emissor em relação ao indexador da operação de crédito.</>}
              />
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <div className="mb-3">
                <DetailSubTitle>Bloqueio por atraso</DetailSubTitle>
              </div>
              <DetailElement
                descricao="Dias necessários para bloqueio Pré-Creli"
                valor={produto.diasParaBloqueioPreCreli}
              />
              <DetailElement descricao="Dias necessários para bloqueio Creli" valor={produto.diasParaBloqueioCreli} />
              <DetailElement
                descricao="Dias necessários para bloqueio Prejuízo"
                valor={produto.diasParaBloqueioPrejuizo}
              />
              <DetailElement
                descricao="Dias de tolerância para o atraso da conta"
                valor={produto.diasToleranciaParaBloqueioAtraso}
              />
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <div className="mb-3">
                <DetailSubTitle>Doação</DetailSubTitle>
              </div>
              <DetailElement
                descricao="Doação recorrente mensal"
                valor={produto.permiteDoacaoRecorrenteMensal}
                format={FormatValueEnum.BOOL}
              />
              <DetailElement
                descricao="Arredondamento da compra para doação"
                valor={produto.permiteDoacaoArredondamentoCompra}
                format={FormatValueEnum.BOOL}
              />
              <DetailElement
                descricao="Arredondamento do total da fatura para doação"
                valor={produto.permiteDoacaoArredondamentoFatura}
                format={FormatValueEnum.BOOL}
              />
              <DetailElement
                descricao="Permite doações espontâneas"
                valor={produto.permiteDoacaoEspontanea}
                format={FormatValueEnum.BOOL}
              />
              <DetailElement
                descricao="Valor total doações"
                valor={produto.valorTotalDoacoes}
                format={FormatValueEnum.BRL}
              />
            </div>
          </div>
        </DetailCard>
      </div>

      <DetailCard>
        <BSTabs defaultActiveKey="cartoes" fill unmountOnExit>
          <BSTab eventKey="cartoes" title="Cartões">
            <CartoesProdutoTab produtoId={produto._id} />
          </BSTab>
          <BSTab eventKey="parametrosGeracaoCartao" title="Parâmetros de geração de cartão">
            <ParametrosGeracaoCartaoProdutoTab produtoId={produto._id} />
          </BSTab>
          <BSTab eventKey="calendariosCorte" title="Calendários de corte">
            <CalendariosCorteProdutoTab produtoId={produto._id} />
          </BSTab>
          <BSTab eventKey="faixasEtarias" title="Faixas etárias">
            <FaixasEtariasTab produtoId={produto._id} />
          </BSTab>
          <BSTab eventKey="opcoesParcelamentoFatura" title="Parcelamento da fatura">
            <OpcoesParcelamentoFaturaProdutoTab produtoId={produto._id} />
          </BSTab>
          <BSTab eventKey="permissoes" title="Permissões">
            <PermissoesProdutoTab produtoId={produto._id} />
          </BSTab>
          <BSTab eventKey="vencimentosDisponiveis" title="Vencimentos disponíveis">
            <VencimentosDisponiveisProdutoTab produtoId={produto._id} />
          </BSTab>
          <BSTab eventKey="operacoesPermitidas" title="Operações permitidas">
            <OperacoesPermitidasProdutoTab produtoId={produto._id} />
          </BSTab>
        </BSTabs>
      </DetailCard>
    </Page>
  );
};

export default DetalhesProdutoPage;

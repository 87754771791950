import React, { useCallback, useEffect, useState } from 'react';
import BSAlert from 'react-bootstrap/Alert';
import BSButton from 'react-bootstrap/Button';
import BSCard from 'react-bootstrap/Card';
import BSModal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Form } from '../../components/form/form';
import { FormControl } from '../../components/form/form-control';
import { FormSelect } from '../../components/form/form-select';
import { SmartTable } from '../../components/smart-table/smart-table';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import {
  ApiSingleElementResponse,
  ClassTypesEnum,
  FormatValueEnum,
  formatBool,
  formatCartao,
  naturezaTransacaoLabelMap,
} from '../../helpers';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import { loadCartoesContaCartao, selectCartoesByFilters } from '../cartoes/cartoes.redux';
import { selectSessionUsuario } from '../session-usuario/session-usuario.redux';
import { gerarProtocoloSolicitacao } from '../solicitacoes-cliente/protocolo-solicitacao-cliente.redux';
import { saveSolicitacaoCliente } from '../solicitacoes-cliente/solicitacao-cliente.redux';
import {
  loadTiposSolicitacoesClientes,
  selectTipoSolicitacaoClienteById,
  selectTiposSolicitacaoClienteByFilters,
} from '../tipos-solicitacao-cliente/tipo-solicitacao-cliente.redux';
import { selectObjectTodosTiposTransacao } from '../tipos-transacao/tipos-transacao.redux';
import { loadContaCartao, selectContaCartaoById } from './contas-cartao.redux';

type CadastrarSolicitacaoClienteFieldProps = {
  cartao: string;
  descricao: string;
  tipoSolicitacao: string;
  transacoes?: string[];
};

type CadastrarSolicitacaoClienteDialogProps = {
  contaCartaoId: string;
  closeDialog: () => void;
  transacoes: any;
};

const smartColumns = ({ tiposTransacao }: { tiposTransacao: any }): SmartTableColumnProps[] => [
  {
    label: 'Data referência',
    attribute: 'dataReferencia',
    className: 'text-center',
    format: FormatValueEnum.DATE_TIME,
  },
  {
    label: 'Descrição',
    attribute: 'descricao',
    className: 'text-center',
  },
  {
    label: 'Estabelecimento',
    attribute: 'nomeEstabelecimento',
    className: 'text-center',
  },
  {
    label: 'Natureza',
    attribute: 'natureza',
    className: 'text-center',
    format: FormatValueEnum.ENUM,
    map: naturezaTransacaoLabelMap,
  },
  {
    label: 'Tipo de transação',
    attribute: 'tipoTransacao',
    className: 'text-center',
    format(tipoTransacao: keyof typeof tiposTransacao) {
      if (!tipoTransacao) {
        return '';
      }

      return tiposTransacao[tipoTransacao]?.descricao;
    },
  },
  {
    label: 'Pendente',
    attribute: 'pendente',
    className: 'text-center',
    format: FormatValueEnum.BOOL,
  },
  {
    label: 'Cancelada',
    attribute: 'cancelada',
    className: 'text-center',
    format: FormatValueEnum.BOOL,
  },
  {
    label: 'Valor (R$)',
    attribute: 'valor',
    className: 'text-end',
    format: FormatValueEnum.BRL,
  },
];

export const CadastrarSolicitacaoClienteDialog: React.FC<CadastrarSolicitacaoClienteDialogProps> = ({
  closeDialog,
  contaCartaoId,
  transacoes,
}) => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;
  const { maxItemsQuery } = useQuerystring();

  const navigate = useNavigate();
  const { showToast } = useToasts();

  const form = useForm<CadastrarSolicitacaoClienteFieldProps>({});
  const { control, watch } = form;

  const tipoSolicitacaoClienteId = watch('tipoSolicitacao');

  // TODO: reavaliar uso do useState hook
  const [numeroDoProtocolo, setNumeroDoProtocolo] = useState<string>();

  const cartoes = useSelector((state) => selectCartoesByFilters(state, { contaCartaoId }));
  const contaCartao = useSelector((state) => selectContaCartaoById(state, contaCartaoId));
  const [sessionUsuario] = useSelector((state) => selectSessionUsuario(state, {})) ?? [];
  const tiposSolicitacaoCliente = useSelector((state) => selectTiposSolicitacaoClienteByFilters(state, maxItemsQuery));
  const tipoSolicitacaoCliente = useSelector((state) =>
    selectTipoSolicitacaoClienteById(state, tipoSolicitacaoClienteId)
  );
  const tiposTransacao = useSelector((state) => selectObjectTodosTiposTransacao(state));

  const onSubmitHandler = (data: CadastrarSolicitacaoClienteFieldProps) => {
    const transacoesIds = transacoes.map(({ _id }: any) => _id);

    return dispatch(
      saveSolicitacaoCliente({
        data: {
          ...data,
          transacoes: transacoesIds,
          numeroDoProtocolo,
          contaCartao: contaCartaoId,
          usuarioSolicitacao: sessionUsuario.usuario,
        },
      })
    )
      .then(({ payload: { data } }: ApiSingleElementResponse) => {
        showToast({
          message: 'Solicitação criada com sucesso',
          type: ClassTypesEnum.SUCCESS,
        });

        closeDialog();

        return navigate(`contas-cartao/${contaCartaoId}/solicitacoes-cliente/${data._id}`);
      })
      .catch((error: Error) => {
        showToast({
          message: error.message,
          type: ClassTypesEnum.DANGER,
        });
      });
  };

  const loadNumeroProtocolo = useCallback(() => {
    dispatch(gerarProtocoloSolicitacao({ data: { deveGerarProtocolo: true } }))
      .then(({ payload: { data } }: ApiSingleElementResponse) =>
        setNumeroDoProtocolo(data.protocoloSolicitacao as string)
      )
      .catch((error: Error) => error);
  }, [dispatch]);

  const loadInformacoesComplementares = useCallback(() => {
    dispatch(loadCartoesContaCartao({ params: { contaCartaoId } })).catch((error: Error) => error);
    dispatch(loadContaCartao({ contaCartaoId })).catch((error: Error) => error);
    dispatch(loadTiposSolicitacoesClientes({ query: maxItemsQuery })).catch((error: Error) => error);
  }, [contaCartaoId, dispatch, maxItemsQuery]);

  const mostrarTabelaTransacoes = Boolean(transacoes.length);

  useEffect(() => {
    loadInformacoesComplementares();
    loadNumeroProtocolo();
  }, [loadInformacoesComplementares, loadNumeroProtocolo]);

  return (
    <>
      <BSModal.Header closeButton>
        <BSModal.Title>Cadastrar solicitação de cliente</BSModal.Title>
      </BSModal.Header>

      <BSModal.Body>
        <Form id="form" form={form} onSubmit={onSubmitHandler}>
          <div className="mb-4 d-flex">
            <h5>Conta {contaCartao?.numero}</h5>
            <h5 className="mx-2"> - </h5>
            <h5>Protocolo {numeroDoProtocolo}</h5>
          </div>

          {mostrarTabelaTransacoes && (
            <div className="mb-3">
              <div className="mb-2">
                <span>Transações selecionadas</span>
              </div>
              <BSCard>
                <BSCard.Body>
                  <small>
                    <SmartTable
                      columns={smartColumns({ tiposTransacao })}
                      emptyMessage="Nenhuma transação selecionada"
                      errorMessage="Erro ao listar transações"
                      items={transacoes}
                    />
                  </small>
                </BSCard.Body>
              </BSCard>
            </div>
          )}

          <FormSelect
            control={control}
            className="mb-3"
            name="tipoSolicitacao"
            label="Tipo de solicitação"
            placeholder="Selecione um tipo de solicitação"
            options={tiposSolicitacaoCliente?.map((tiposSolicitacao: any) => ({
              key: tiposSolicitacao._id,
              value: tiposSolicitacao._id,
              label: `(${tiposSolicitacao.codigo}) - ${tiposSolicitacao.descricao}`,
            }))}
            rules={{ required: true }}
          />

          {tipoSolicitacaoCliente && (
            <BSAlert>
              <h6 className="mb-3">
                ({tipoSolicitacaoCliente.codigo}) - {tipoSolicitacaoCliente.descricao}
              </h6>

              <ul>
                <li>Vinculado à cartão: {formatBool(tipoSolicitacaoCliente.vinculadoACartao)}</li>
                <li>Permite ajuste financeiro: {formatBool(tipoSolicitacaoCliente.permiteAjusteFinanceiro)}</li>
                <li>Resolução imediata: {formatBool(tipoSolicitacaoCliente.resolucaoImediata)}</li>
                <li>
                  Prazo para resolução em dias úteis: {tipoSolicitacaoCliente.prazoParaResolucaoEmDiasUteis ?? '-'}
                </li>
              </ul>
            </BSAlert>
          )}

          {tipoSolicitacaoCliente?.vinculadoACartao && cartoes?.length !== 0 && (
            <FormSelect
              control={control}
              className="mb-3"
              name="cartao"
              label="Cartão"
              placeholder="Selecione um cartão"
              options={cartoes?.map((cartao: any) => ({
                key: cartao._id,
                value: cartao._id,
                label: formatCartao(cartao.numeroTruncado),
              }))}
              rules={{ required: tipoSolicitacaoCliente?.vinculadoACartao }}
            />
          )}

          <FormControl
            control={control}
            className="mb-3"
            name="descricao"
            label="Descrição"
            placeholder="Descrição detalhada da solicitação do cliente"
            rules={{ required: true }}
            as="textarea"
            rows={7}
          />
        </Form>
      </BSModal.Body>

      <BSModal.Footer>
        <BSButton variant="light" onClick={closeDialog}>
          Fechar
        </BSButton>
        <BSButton variant="primary" type="submit" form="form">
          Abrir solicitação
        </BSButton>
      </BSModal.Footer>
    </>
  );
};

export default CadastrarSolicitacaoClienteDialog;

import { Route, Routes } from 'react-router-dom';
import DetalhesTipoTransacaoPage from './detalhes-tipo-transacao-page';
import TiposTransacaoPage from './tipos-transacao-page';

const TiposTransacaoRoutes: React.FC = () => {
  return (
    <Routes>
      <Route index element={<TiposTransacaoPage />} />
      <Route path=":tipoTransacaoId" element={<DetalhesTipoTransacaoPage />} />
    </Routes>
  );
};

export default TiposTransacaoRoutes;

import { useCallback, useEffect, useMemo, useState } from 'react';
import BSCard from 'react-bootstrap/Card';
import { useParams } from 'react-router-dom';
import { DetailCard } from '../../components/details/datail-card';
import DetailElement from '../../components/details/detail-element';
import DetailSubTitle from '../../components/details/detail-subtitle';
import DetailTitle from '../../components/details/detail-title';
import { Loading } from '../../components/loading';
import Page from '../../components/page';

import {
  ApiSingleElementResponse,
  FormatValueEnum,
  acaoEmbossingLabelMap,
  cartoesRename,
  formaEnvioCartaoLabelMap,
  formatCartao,
  localEntregaCartaoLabelMap,
  sexoLabelMap,
  statusEmbossingLabelMap,
  tipoArredondamentoDoacaoLabelMap,
  tipoCartaoLabelMap,
  tipoTitularidadeCartaoLabelMap,
} from '../../helpers';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import { loadCartao, selectCartaoById } from '../cartoes/cartoes.redux';
import {
  loadTiposBloqueiosCartao,
  selectObjectTodosTiposBloqueioCartao,
} from '../tipos-bloqueio-cartao/tipos-bloqueio-cartao.redux';
import {
  loadHistorico,
  selectHistoricoCartaoById,
  selectHistoricoCartaoLoadingStateByFilters,
} from './historicos-cartao.redux';
import { loadHistoricoNoDiaCartao } from './recuperar-historico-dia.redux';

const DetalhesHistoricoCartaoPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;
  const { maxItemsQuery } = useQuerystring();

  const params = useParams();
  const historicoCartaoId = params.historicoCartaoId as string;

  const [dadosAnteriores, setDadosAnteriores] = useState<any>();

  const filters = useMemo(() => ({ historicoCartaoId }), [historicoCartaoId]);
  const historicoCartao = useSelector((state) => selectHistoricoCartaoById(state, historicoCartaoId));
  const loadingState = useSelector((state) => selectHistoricoCartaoLoadingStateByFilters(state, filters));
  const cartao = useSelector((state) => selectCartaoById(state, historicoCartao?.cartaoId));
  const tiposBloqueiosCartao = useSelector((state) => selectObjectTodosTiposBloqueioCartao(state));

  const loadEntidadesComplementares = useCallback(
    (historico: any) => {
      dispatch(loadCartao({ cartaoId: historico.cartaoId })).catch((error: Error) => error);
      dispatch(loadHistoricoNoDiaCartao({ historicoCartaoId }))
        .then(({ payload: { data } }: ApiSingleElementResponse) => setDadosAnteriores(data))
        .catch((error: Error) => error);
    },
    [dispatch, historicoCartaoId]
  );

  const _loadHistorico = useCallback(
    () =>
      dispatch(loadHistorico({ historicoCartaoId }))
        .then(({ payload: { data } }: ApiSingleElementResponse) => loadEntidadesComplementares(data))
        .catch((error: Error) => error),
    [dispatch, historicoCartaoId, loadEntidadesComplementares]
  );

  const _loadTiposBloqueiosCartao = useCallback(
    () => dispatch(loadTiposBloqueiosCartao({ query: maxItemsQuery })).catch((error: Error) => error),
    [dispatch, maxItemsQuery]
  );

  useEffect(() => {
    _loadHistorico();
    _loadTiposBloqueiosCartao();
  }, [_loadHistorico, _loadTiposBloqueiosCartao]);

  function filterSameKeys(entidade: any, alteracao: any) {
    if (!entidade || !alteracao) {
      return {};
    }

    const keysAlteracao = Object.keys(alteracao);

    return Object.entries(entidade).reduce((sameKeys, entry) => {
      const [chave, valor] = entry;

      if (keysAlteracao.includes(chave)) {
        return { ...sameKeys, [chave]: valor };
      }

      return sameKeys;
    }, {});
  }

  const renderDetailElement = (atributo: string, valor: any): JSX.Element | undefined => {
    const nomeAtributo = cartoesRename[atributo as keyof typeof cartoesRename];

    if (!nomeAtributo) {
      return;
    }

    function isBoolean() {
      return <DetailElement key={atributo} descricao={nomeAtributo} valor={valor} format={FormatValueEnum.BOOL} />;
    }

    function isBloqueio() {
      if (!valor) {
        return <strong key={atributo}>Bloqueio retirado</strong>;
      }

      const bloqueio = tiposBloqueiosCartao?.[valor];

      return (
        <DetailElement
          key={atributo}
          descricao={nomeAtributo}
          valor={`(Código: ${bloqueio?.codigo}) - ${bloqueio?.descricao}`}
        />
      );
    }

    function isData() {
      return <DetailElement key={atributo} descricao={nomeAtributo} valor={valor} format={FormatValueEnum.DATA} />;
    }

    function isDataTime() {
      return <DetailElement key={atributo} descricao={nomeAtributo} valor={valor} format={FormatValueEnum.DATE_TIME} />;
    }

    function isElement() {
      return <DetailElement key={atributo} descricao={nomeAtributo} valor={valor} />;
    }

    function isEnum() {
      return (
        <DetailElement
          key={atributo}
          descricao={nomeAtributo}
          valor={valor}
          format={FormatValueEnum.ENUM}
          map={enums}
        />
      );
    }

    function isContadorTransacoesChip() {
      return (
        <>
          <DetailElement key="credito" descricao={`${nomeAtributo} crédito`} valor={valor.credito} />
          <DetailElement key="debito" descricao={`${nomeAtributo} débito`} valor={valor.debito} />
        </>
      );
    }

    const enums = {
      acaoEmbossing: acaoEmbossingLabelMap,
      formaEnvio: formaEnvioCartaoLabelMap,
      localEntrega: localEntregaCartaoLabelMap,
      portadorSexo: sexoLabelMap,
      statusEmbossing: statusEmbossingLabelMap,
      tipoArredondamentoDoacaoCompra: tipoArredondamentoDoacaoLabelMap,
      titularidade: tipoTitularidadeCartaoLabelMap,
      tipoCartao: tipoCartaoLabelMap,
    }[atributo];

    const cases = {
      acaoEmbossing: isEnum,
      formaEnvio: isEnum,
      localEntrega: isEnum,
      portadorSexo: isEnum,
      tipoArredondamentoDoacaoCompra: isEnum,
      titularidade: isEnum,
      tipoCartao: isEnum,
      ehAtualizacaoEmissor: isBoolean,
      ehSegundaVia: isBoolean,
      foiComunicadoBandeira: isBoolean,
      statusEmbossing: isEnum,
      foiEnviadoCartaSenha: isBoolean,
      permiteCompraContactless: isBoolean,
      permiteCompraInternacional: isBoolean,
      permiteCompraOnlineInternacional: isBoolean,
      permiteCompraOnlineNacional: isBoolean,
      permiteDoacaoArredondamentoCompra: isBoolean,
      permiteUsarOverlimit: isBoolean,
      dataBloqueio: isData,
      dataVencimento: isData,
      portadorDataNascimento: isData,
      dataCriacao: isDataTime,
      dataEmbossing: isDataTime,
      tipoBloqueio: isBloqueio,
      contadorTransacoesChip: isContadorTransacoesChip,
    };

    return Reflect.has(cases, atributo) ? cases[atributo as keyof typeof cases]() : isElement();
  };

  if (!historicoCartao) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Loading notFoundMessage="Registro de histórico não econtrado" loadingState={loadingState} />
      </div>
    );
  }

  return (
    <Page>
      <div className="mb-5">
        <DetailCard>
          <div className="mb-5">
            <DetailTitle>
              Registro de histórico de alteração
              {Boolean(cartao) && <> - Cartão {formatCartao(cartao.numeroTruncado)}</>}
            </DetailTitle>
          </div>

          <div className="row mb-4">
            <div className="col-lg-4 col-md-6 mb-4">
              <DetailElement
                descricao="Data da alteração"
                valor={historicoCartao.dataAlteracao}
                format={FormatValueEnum.DATE_TIME}
              />
              <DetailElement descricao="Origem da alteração" valor={historicoCartao.origemAlteracao} />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 col-lg-6 mb-4 d-flex flex-column">
              <div className="mb-4">
                <DetailSubTitle>Valores alterados</DetailSubTitle>
              </div>

              <BSCard className="flex-grow-1">
                <BSCard.Body className="p-5">
                  {historicoCartao.alteracao && (
                    <>
                      {Object.entries(historicoCartao.alteracao).map(([chave, valor]) => {
                        return renderDetailElement(chave, valor);
                      })}
                    </>
                  )}
                </BSCard.Body>
              </BSCard>
            </div>

            <div className="col-md-12 col-lg-6 mb-4 d-flex flex-column">
              <div className="mb-4">
                <DetailSubTitle>Valores anteriores</DetailSubTitle>
              </div>

              <BSCard className="flex-grow-1">
                <BSCard.Body className="p-5">
                  {dadosAnteriores ? (
                    <>
                      {Object.entries(filterSameKeys(dadosAnteriores, historicoCartao.alteracao)).map(
                        ([chave, valor]) => {
                          return renderDetailElement(chave, valor);
                        }
                      )}
                    </>
                  ) : (
                    <p>Sem alterações anteriores</p>
                  )}
                </BSCard.Body>
              </BSCard>
            </div>
          </div>
        </DetailCard>
      </div>
    </Page>
  );
};

export default DetalhesHistoricoCartaoPage;

import { SmartTable } from '../../components/smart-table/smart-table';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import { useAppSelector } from '../../store/hooks-redux';
import { selectProdutoById } from './produtos.redux';

const smartColumns = (): SmartTableColumnProps[] => [
  {
    label: 'Dia de vencimento',
    className: 'text-center',
    attribute: 'dia',
  },
];

type VencimentosDisponiveisProdutoTabProps = {
  produtoId: string;
};

const VencimentosDisponiveisProdutoTab: React.FC<VencimentosDisponiveisProdutoTabProps> = ({ produtoId }) => {
  const useSelector = useAppSelector;
  const { vencimentosDisponiveis } = useSelector((state) => selectProdutoById(state, produtoId));

  return (
    <SmartTable
      emptyMessage="Nenhum vencimento disponível encontrado"
      errorMessage="Erro ao listar vencimentos disponíveis"
      usePagination={true}
      columns={smartColumns()}
      items={sortVencimentosDisponiveis(vencimentosDisponiveis)}
      size={vencimentosDisponiveis.length}
    />
  );

  function sortVencimentosDisponiveis(vencimentosDisponiveis: []) {
    if (!vencimentosDisponiveis) {
      return [];
    }

    const vcto = vencimentosDisponiveis.map((dia) => ({ dia }));

    return vcto.sort((a, b) => a.dia - b.dia);
  }
};

export default VencimentosDisponiveisProdutoTab;

import { actionFactory, reducerFactory, selectorFactory } from '../../store';

const context = 'diasAtrasoContaCartao';

const { loadOne } = actionFactory(context, {
  buildUrlLoadOne: ({ contaCartaoId }) => `/api/contas-cartao/${contaCartaoId}/dias-atraso`,
});

const { selectOneById } = selectorFactory({ context });

export const loadDiasAtrasoContaCartao = loadOne;
export const selectDiasAtrasoContaCartaoById = selectOneById;

export const diasAtrasoContaCartao = reducerFactory({ context });

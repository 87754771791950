import { Route, Routes } from 'react-router-dom';
import TarifasPage from './tarifas-page';
import DetalhesTarifaPage from './detalhes-tarifa-page';

const TarifasRoutes: React.FC = () => {
  return (
    <Routes>
      <Route index element={<TarifasPage />} />
      <Route path=":tarifaId" element={<DetalhesTarifaPage />} />
    </Routes>
  );
};

export default TarifasRoutes;

import { actionFactory, reducerFactory, selectorFactory } from '../../store';

const context = 'clientes';

const { loadMany, loadOne } = actionFactory(context, {
  buildUrlLoadOne: ({ clienteId }) => `/api/clientes/${clienteId}`,
  buildUrlLoadMany: () => '/api/clientes',
});

const {
  selectOneById,
  selectAll,
  selectManyByFilters,
  selectTotalByFilters,
  selectLoadingStateByFilters,
  selectObjectAllIds,
} = selectorFactory({ context });

export const loadClientes = loadMany;
export const loadCliente = loadOne;

export const selectClienteById = selectOneById;
export const selectTodosClientes = selectAll;
export const selectObjectTodosClientes = selectObjectAllIds;
export const selectClientesByFilters = selectManyByFilters;
export const selectTotalClientesByFilters = selectTotalByFilters;
export const selectClientesLoadingStateByFilters = selectLoadingStateByFilters;

export const clientes = reducerFactory({ context });

import { Route, Routes } from 'react-router-dom';
import FeriadosPage from './feriados-page';

const FeriadosRoutes: React.FC = () => {
  return (
    <Routes>
      <Route index element={<FeriadosPage />} />
    </Routes>
  );
};

export default FeriadosRoutes;

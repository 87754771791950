import { useCallback, useEffect, useMemo } from 'react';
import BSAccordion from 'react-bootstrap/Accordion';
import BSBadge from 'react-bootstrap/Badge';
import BSButton from 'react-bootstrap/Button';
import BSListGroup from 'react-bootstrap/ListGroup';
import { BsPlusCircle, BsTrash } from 'react-icons/bs';
import { useParams } from 'react-router-dom';

import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import IconButton from '../../components/button/icon-button';
import { DetailCard } from '../../components/details/datail-card';
import DetailElement from '../../components/details/detail-element';
import DetailTitle from '../../components/details/detail-title';
import ConfirmationDialog from '../../components/dialog/confirmation-dialog';
import { Loading } from '../../components/loading';
import Page from '../../components/page';
import RbacElement from '../../components/role-based-access-control/role-based-access-control-element';
import { ApiSingleElementResponse, ClassTypesEnum, FormatValueEnum, PayloadDataResponseObject } from '../../helpers';
import { useDialog } from '../../hooks/dialog/use-dialog';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import { loadProduto, selectProdutoById } from '../produtos/produtos.redux';
import CadastrarPerguntasRespostasPerguntasFrequentesDialog from './cadastrar-perguntas-respostas-perguntas-frequentes-dialog';
import CadastrarTopicosPerguntasFrequentesDialog from './cadastrar-topicos-perguntas-frequentes-dialog';
import {
  loadPerguntaFrequente,
  removerPerguntaPerguntasFrequentes,
  removerTopicoPerguntasFrequentes,
  selectPerguntasFrequentesById,
  selectPerguntasFrequentesLoadingStateByFilters,
} from './perguntas-frequentes.redux';

const DetalhesPerguntasFrequentesPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const params = useParams();
  const perguntaFrequenteId = params.perguntaFrequenteId as string;

  const { showDialog, closeDialog } = useDialog();
  const { showToast } = useToasts();

  const filters = useMemo(() => ({ perguntaFrequenteId }), [perguntaFrequenteId]);
  const perguntaFrequente = useSelector((state) => selectPerguntasFrequentesById(state, perguntaFrequenteId));
  const produto = useSelector((state) => selectProdutoById(state, perguntaFrequente?.produto?._id));
  const loadingState = useSelector((state) => selectPerguntasFrequentesLoadingStateByFilters(state, filters));

  const _loadProduto = useCallback(
    (produtoId: string) => dispatch(loadProduto({ produtoId })).catch((error: Error) => error),
    [dispatch]
  );

  const _loadDetalhesPerguntasFrequentes = useCallback(
    () =>
      dispatch(loadPerguntaFrequente({ perguntaFrequenteId }))
        .then(({ payload: { data } }: ApiSingleElementResponse) => {
          const { produto } = data;

          return _loadProduto((produto as PayloadDataResponseObject)._id as string);
        })
        .catch((error: Error) => error),
    [perguntaFrequenteId, dispatch, _loadProduto]
  );

  const onRemoveTopicoHandler = (topicoId: any) =>
    dispatch(removerTopicoPerguntasFrequentes({ params: { perguntaFrequenteId, topicoId }, data: {} }))
      .then(() => {
        closeDialog();

        showToast({
          message: 'Tópico removido com sucesso',
          type: ClassTypesEnum.SUCCESS,
        });

        return _loadDetalhesPerguntasFrequentes();
      })
      .catch((error: Error) => {
        showToast({
          message: error.message,
          type: ClassTypesEnum.DANGER,
        });
      });

  const onRemovePerguntaHandler = (topicoId: any, perguntaId: any) =>
    dispatch(removerPerguntaPerguntasFrequentes({ params: { perguntaFrequenteId, topicoId, perguntaId }, data: {} }))
      .then(() => {
        closeDialog();

        showToast({
          message: 'Pergunta e resposta removidos com sucesso',
          type: ClassTypesEnum.SUCCESS,
        });

        return _loadDetalhesPerguntasFrequentes();
      })
      .catch((error: Error) => {
        showToast({
          message: error.message,
          type: ClassTypesEnum.DANGER,
        });
      });

  useEffect(() => {
    _loadDetalhesPerguntasFrequentes();
  }, [_loadDetalhesPerguntasFrequentes]);

  if (!perguntaFrequente) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Loading notFoundMessage="Pergunta frequente não encontrada" loadingState={loadingState} />
      </div>
    );
  }

  return (
    <Page>
      <div className="mb-5">
        <DetailCard>
          <div className="mb-5">
            <DetailTitle>
              Pergunta frequente: {produto?.nome} - {produto?.codigo}
            </DetailTitle>
          </div>
          <div className="row">
            <div className="col-12">
              <DetailElement
                descricao="Data da Criação "
                valor={perguntaFrequente?.dataHora?.criacao}
                format={FormatValueEnum.DATE_TIME}
              />
              <DetailElement
                descricao="Data da Alteração "
                valor={perguntaFrequente?.dataHora?.atualizacao}
                format={FormatValueEnum.DATE_TIME}
              />
            </div>

            <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.CRIACAO_PERGUNTA_FREQUENTE}>
              <div className="col-12 d-flex justify-content-end flex-wrap">
                <div className="m-1">
                  <BSButton
                    onClick={() =>
                      showDialog({
                        component: (
                          <CadastrarTopicosPerguntasFrequentesDialog
                            closeDialog={closeDialog}
                            perguntaFrequenteId={perguntaFrequente._id}
                            reload={_loadDetalhesPerguntasFrequentes}
                          />
                        ),
                      })
                    }
                  >
                    Novo tópico
                  </BSButton>
                </div>
              </div>
            </RbacElement>
          </div>
        </DetailCard>
      </div>
      <div className="row">
        <div className="col-12">
          <BSListGroup as="ol">
            {perguntaFrequente &&
              perguntaFrequente.topicos.map((topico: any) => {
                const { _id: topicoId, assunto, tags, perguntas } = topico;
                return (
                  <BSListGroup.Item as="li" key={topicoId}>
                    <div className="m-1 d-flex justify-content-end flex-wrap">
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">{assunto}</div>

                        <div className="d-flex mb-2 flex-wrap">
                          {tags &&
                            tags.map((tag: any, i: any) => (
                              <div key={tag + i} className="m-1">
                                <BSBadge pill bg="secondary">
                                  {tag}
                                </BSBadge>
                              </div>
                            ))}
                        </div>
                      </div>

                      <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.CRIACAO_PERGUNTA_FREQUENTE}>
                        <IconButton
                          Icon={BsPlusCircle}
                          type={ClassTypesEnum.PRIMARY}
                          onClick={() =>
                            showDialog({
                              component: (
                                <CadastrarPerguntasRespostasPerguntasFrequentesDialog
                                  closeDialog={closeDialog}
                                  perguntaFrequenteId={perguntaFrequenteId}
                                  topicoId={topicoId}
                                  reload={_loadDetalhesPerguntasFrequentes}
                                />
                              ),
                            })
                          }
                        />{' '}
                      </RbacElement>

                      <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.REMOCAO_PERGUNTA_FREQUENTE}>
                        <IconButton
                          Icon={BsTrash}
                          type={ClassTypesEnum.DANGER}
                          onClick={() => {
                            showDialog({
                              component: (
                                <ConfirmationDialog
                                  onConfirmation={() => onRemoveTopicoHandler(topicoId)}
                                  message={`Confirmar a remoção do assunto: ${assunto}?`}
                                  confirmationLabel="Remover"
                                  cancelLabel="Cancelar"
                                  title="Remover assunto"
                                  onHide={closeDialog}
                                />
                              ),
                            });
                          }}
                        />
                      </RbacElement>
                    </div>

                    <BSAccordion>
                      {perguntas &&
                        perguntas.map((pergunta: any, index: string) => (
                          <BSAccordion.Item eventKey={pergunta?._id} key={pergunta?._id}>
                            <BSAccordion.Header>{`${index + 1} ${pergunta?.titulo}`}</BSAccordion.Header>
                            <BSAccordion.Body>
                              <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.REMOCAO_PERGUNTA_FREQUENTE}>
                                <div className="m-1 d-flex justify-content-end flex-wrap">
                                  <IconButton
                                    Icon={BsTrash}
                                    type={ClassTypesEnum.DANGER}
                                    onClick={() => {
                                      showDialog({
                                        component: (
                                          <ConfirmationDialog
                                            onConfirmation={() => onRemovePerguntaHandler(topicoId, pergunta?._id)}
                                            message={`Confirma a remoção da pergunta: ${pergunta?.titulo}?`}
                                            confirmationLabel="Remover"
                                            cancelLabel="Cancelar"
                                            title="Remover pergunta"
                                            onHide={closeDialog}
                                          />
                                        ),
                                      });
                                    }}
                                  />
                                </div>
                              </RbacElement>

                              {pergunta?.texto}
                            </BSAccordion.Body>
                          </BSAccordion.Item>
                        ))}
                    </BSAccordion>
                  </BSListGroup.Item>
                );
              })}
          </BSListGroup>
        </div>
      </div>
    </Page>
  );
};

export default DetalhesPerguntasFrequentesPage;

import { useCallback, useMemo } from 'react';

import DetailElement from '../../components/details/detail-element';
import { SmartTable } from '../../components/smart-table/smart-table';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import { formatData, FormatValueEnum, tipoProcessamentoCalendarioCorteLabelMap } from '../../helpers';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import {
  loadCalendariosDeCorte,
  selectCalendarioCorteLoadingStateByFilters,
  selectCalendariosCorteByFilters,
  selectTotalCalendariosCorteByFilters,
} from '../calendario-corte/calendarios-corte.redux';
import { selectProdutoById } from './produtos.redux';

const smartColumns = (): SmartTableColumnProps[] => [
  {
    label: 'Data de prévia',
    attribute: 'dataPrevia',
    sortable: true,
    className: 'text-center',
    format: (dataPrevia: string) => formatData(dataPrevia),
  },
  {
    label: 'Data de corte',
    attribute: 'dataCorte',
    sortable: true,
    className: 'text-center',
    format: (dataCorte: string) => formatData(dataCorte),
  },
  {
    label: 'Data de vencimento',
    attribute: 'dataVencimento',
    sortable: true,
    className: 'text-center',
    format: (dataVencimento: string) => formatData(dataVencimento),
  },
  { label: 'Mês', attribute: 'mes', sortable: true, className: 'text-center' },
  { label: 'Ano', attribute: 'ano', sortable: true, className: 'text-center' },
];

type CalendariosCorteProdutoTabProps = {
  produtoId: string;
};

const CalendariosCorteProdutoTab: React.FC<CalendariosCorteProdutoTabProps> = ({ produtoId }) => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const { query } = useQuerystring();

  const filters = useMemo(() => ({ ...query, produto: produtoId }), [produtoId, query]);
  const produto = useSelector((state) => selectProdutoById(state, produtoId));
  const total = useSelector((state) => selectTotalCalendariosCorteByFilters(state, filters));
  const calendariosCorte = useSelector((state) => selectCalendariosCorteByFilters(state, filters));
  const loadingState = useSelector((state) => selectCalendarioCorteLoadingStateByFilters(state, filters));

  const loadItems = useCallback(
    () => dispatch(loadCalendariosDeCorte({ query: filters })).catch((error: Error) => error),
    [dispatch, filters]
  );

  return (
    <>
      <div className="row">
        <div className="col-lg-4 col-md-6 mb-4">
          <DetailElement
            descricao="Geração calendário de corte"
            valor={produto.tipoProcessamento}
            format={FormatValueEnum.ENUM}
            map={tipoProcessamentoCalendarioCorteLabelMap}
          />
          <DetailElement
            descricao="Geração automática de calendários de corte"
            valor={produto.geracaoAutomaticaCalendariosCorte}
            format={FormatValueEnum.BOOL}
          />
        </div>
        <div className="col-lg-4 col-md-6 mb-4">
          <DetailElement
            descricao="Meses de permanência dia vencimento"
            valor={produto.mesesPermanenciaDiaVencimento}
            dica={
              <>
                <p>
                  Informa quantos meses o cliente deve permanecer com a data de vencimento escolhida até poder efetuar
                  uma nova mudança.
                </p>

                <p>
                  Após a abertura da conta, o cliente pode alterar à qualquer momento, não sendo necessário permanência
                  com a data escolhida durante a abertura.
                </p>
              </>
            }
          />
          <DetailElement
            descricao="Distância corte vencimento em dias"
            valor={produto.distanciaCorteVencimentoEmDias}
          />
        </div>
      </div>

      <div className="mb-5">
        <hr />
      </div>

      <SmartTable
        emptyMessage="Nenhum calendário de corte encontrado"
        errorMessage="Erro ao listar calendários de corte"
        loadItems={loadItems}
        usePagination={true}
        size={total}
        columns={smartColumns()}
        items={calendariosCorte}
        loadingState={loadingState}
      />
    </>
  );
};

export default CalendariosCorteProdutoTab;

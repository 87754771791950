import { reducerFactory, actionFactory } from '../../store';

const context = 'historicosNoDiaContaCartao';

const { loadOne } = actionFactory(context, {
  buildUrlLoadOne: ({ historicoContaCartaoId }) => `/api/historicos-conta-cartao/${historicoContaCartaoId}/dia`,
});

export const loadHistoricoNoDiaContaCartao = loadOne;

export const historicosNoDiaContaCartao = reducerFactory({ context });

import BSButton from 'react-bootstrap/Button';
import BSModal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { Form } from '../../components/form/form';
import { FormControl } from '../../components/form/form-control';

type AtualizarSenhaUsuarioFormFields = {
  password: string;
};

type AtualizarSenhaUsuarioDialogProps = {
  usuario: any;
  closeDialog: () => void;
  onSubmitHandler: (data: any) => void;
};

const AtualizarSenhaUsuarioDialog: React.FC<AtualizarSenhaUsuarioDialogProps> = ({
  usuario,
  closeDialog,
  onSubmitHandler,
}) => {
  const form = useForm<AtualizarSenhaUsuarioFormFields>();
  const { control } = form;

  return (
    <>
      <BSModal.Header closeButton>
        <BSModal.Title>Atualizar senha do usuário</BSModal.Title>
      </BSModal.Header>

      <BSModal.Body>
        <Form id="form" form={form} onSubmit={onSubmitHandler}>
          <div className="mb-3">
            Usuário: <strong>{usuario.username}</strong>
          </div>

          <FormControl
            control={control}
            name="password"
            label="Nova senha"
            type="password"
            rules={{ required: true }}
          />
        </Form>
      </BSModal.Body>

      <BSModal.Footer>
        <BSButton variant="light" onClick={closeDialog}>
          Fechar
        </BSButton>
        <BSButton variant="primary" type="submit" form="form">
          Atualizar senha
        </BSButton>
      </BSModal.Footer>
    </>
  );
};

export default AtualizarSenhaUsuarioDialog;

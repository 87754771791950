import AccessDeniedPage from '../../pages/access-denied/access-denied-page';
import { selectSessionUsuarioLoadingStateByFilter } from '../../pages/session-usuario/session-usuario.redux';
import { useAppSelector } from '../../store/hooks-redux';
import { Loading } from '../loading';
import Page from '../page';
import RbacElement, { RbacElementProps } from './role-based-access-control-element';

const RbacPage: React.FC<RbacElementProps> = ({ acoesPermissao, children }) => {
  const useSelector = useAppSelector;
  const sessionLoadingState = useSelector((state) => selectSessionUsuarioLoadingStateByFilter(state, {}));

  if (sessionLoadingState === 'loading') {
    return (
      <div
        data-testid="role-based-access-control-page"
        className="d-flex align-items-center justify-content-center h-100"
      >
        <Loading loadingState={sessionLoadingState} />
      </div>
    );
  }

  return (
    <RbacElement acoesPermissao={acoesPermissao} componentToRender={<AccessDeniedPage />}>
      <Page>{children}</Page>
    </RbacElement>
  );
};

export default RbacPage;

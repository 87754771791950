import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import { useCallback, useMemo } from 'react';
import BSCard from 'react-bootstrap/Card';
import { Link, useNavigate } from 'react-router-dom';
import RbacElement from '../../components/role-based-access-control/role-based-access-control-element';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import { SmartTable } from '../../components/smart-table/smart-table';
import SmartTableButton from '../../components/smart-table/smart-table-button';
import SmartTableFilters from '../../components/smart-table/smart-table-filters/smart-table-filters';
import {
  SmartTableFilterControlTypesEnum,
  SmartTableFilterProps,
} from '../../components/smart-table/smart-table-filters/table-filter.types';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import Title from '../../components/title';
import { FormatValueEnum, tipoContratoTermosUsoLabelMap } from '../../helpers';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import { loadProdutos, selectObjectTodosProdutos } from '../produtos/produtos.redux';
import {
  loadContratosTermosUso,
  selectContratosTermosUsoByFilters,
  selectContratosTermosUsoLoadingStateByFilters,
  selectTotalContratosTermosUsoByFilters,
} from './contratos-termos-uso.redux';

const smartColumns = ({ produtosMap }: { produtosMap: any }): SmartTableColumnProps[] => [
  { label: 'Tipo', attribute: 'tipo', sortable: true, className: 'align-text-center' },
  {
    label: 'Produto',
    attribute: 'produtoId',
    format(produtoId) {
      if (!produtoId) {
        return '-';
      }

      return (
        <Link onClick={(e) => e.stopPropagation()} to={`/produtos/${produtoId}`}>
          {produtosMap[produtoId]}
        </Link>
      );
    },
    className: 'text-center',
    sortable: true,
  },
  {
    label: 'Data início da vigência',
    attribute: 'dataInicioVigencia',
    sortable: true,
    className: 'text-center',
    format: FormatValueEnum.DATA,
  },
  {
    label: 'Data fim da vigência',
    attribute: 'dataFimVigencia',
    sortable: true,
    className: 'text-center',
    format: FormatValueEnum.DATA,
  },
  {
    label: 'Mais recente',
    attribute: 'maisRecente',
    sortable: true,
    className: 'text-center',
    format: FormatValueEnum.BOOL,
  },
];

const smartFilters = ({ produtosMap }: { produtosMap: any }): SmartTableFilterProps[] => [
  {
    label: 'Tipo',
    attribute: 'tipo',
    controlType: SmartTableFilterControlTypesEnum.SELECT,
    map: tipoContratoTermosUsoLabelMap,
  },
  {
    label: 'Produto',
    attribute: 'produtoId',
    controlType: SmartTableFilterControlTypesEnum.SELECT,
    map: produtosMap,
  },
  { label: 'Mais recente', attribute: 'maisRecente', controlType: SmartTableFilterControlTypesEnum.BOOL_RADIO },
];

const ContratosTermosUsoPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const { query, maxItemsQuery } = useQuerystring();
  const navigate = useNavigate();

  if (!query.sort) {
    query.sort = '-maisRecente';
  }

  const filters = useMemo(() => ({ ...query }), [query]);
  const total = useSelector((state) => selectTotalContratosTermosUsoByFilters(state, filters));
  const contratos = useSelector((state) => selectContratosTermosUsoByFilters(state, filters));
  const loadingState = useSelector((state) => selectContratosTermosUsoLoadingStateByFilters(state, filters));
  const produtos = useSelector((state) => selectObjectTodosProdutos(state));

  const produtosMap = Object.entries(produtos || {}).reduce(
    (acc, [produtoId, produto]) => ({
      ...acc,
      [produtoId]: `${produto.codigo} - ${produto.nome}`,
    }),
    {}
  );

  const loadItems = useCallback(() => {
    dispatch(loadProdutos({ query: { ...maxItemsQuery } })).catch((error: Error) => error);
    dispatch(loadContratosTermosUso({ query })).catch((error: Error) => error);
  }, [dispatch, maxItemsQuery, query]);

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_CONTRATO_TERMOS_USO}>
      <div className="d-flex flex-column h-100">
        <Title>Contrato de termos de uso</Title>

        <div className="row">
          <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-12 mb-4">
            <SmartTableFilters filters={smartFilters({ produtosMap })} />
          </div>

          <div className="col-xxl-10 col-xl-9 col-lg-8 col-md-12 mb-4">
            <BSCard>
              <BSCard.Body>
                <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.CRIACAO_CONTRATO_TERMOS_USO}>
                  <div className="d-flex align-items-center mb-2">
                    <SmartTableButton onClick={() => navigate('cadastrar')}>Novo termo de uso</SmartTableButton>
                  </div>
                </RbacElement>
                <SmartTable
                  emptyMessage="Nenhum contrato de termos de uso encontrado"
                  errorMessage="Erro ao listar contratos de termos de uso"
                  usePagination={true}
                  loadItems={loadItems}
                  columns={smartColumns({ produtosMap })}
                  items={contratos}
                  loadingState={loadingState}
                  onRowClick={(doc) => navigate(`/contratos-termos-uso/${doc._id}/`)}
                  size={total}
                />
              </BSCard.Body>
            </BSCard>
          </div>
        </div>
      </div>
    </RbacPage>
  );
};

export default ContratosTermosUsoPage;

import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { DetailCard } from '../../components/details/datail-card';
import DetailElement from '../../components/details/detail-element';
import DetailTitle from '../../components/details/detail-title';
import { Loading } from '../../components/loading';
import Page from '../../components/page';
import { ApiSingleElementResponse, FormatValueEnum, periodicidadeLabelMap, tipoTarifaLabelMap } from '../../helpers';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import { loadProduto, selectProdutoById } from '../produtos/produtos.redux';
import { loadTipoTransacao, selectTiposTransacaoById } from '../tipos-transacao/tipos-transacao.redux';
import { loadTarifa, selectTarifaById, selectTarifasLoadingStateByFilters } from './tarifas.redux';

const DetalhesTarifaPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const params = useParams();
  const tarifaId = params?.tarifaId as string;

  const filters = useMemo(() => ({ tarifaId }), [tarifaId]);
  const tarifa = useSelector((state) => selectTarifaById(state, tarifaId));
  const loadingState = useSelector((state) => selectTarifasLoadingStateByFilters(state, filters));
  const produto = useSelector((state) => selectProdutoById(state, tarifa?.produto));
  const tipoTransacao = useSelector((state) => selectTiposTransacaoById(state, tarifa?.tipoTransacao));

  const loadEntidadesRelacionadas = useCallback(
    (tarifa: any) => {
      dispatch(loadTipoTransacao({ id: tarifa.tipoTransacao })).catch((error: Error) => error);
      dispatch(loadProduto({ produtoId: tarifa.produto })).catch((error: Error) => error);
    },
    [dispatch]
  );

  const _loadTarifa = useCallback(
    () =>
      dispatch(loadTarifa({ tarifaId }))
        .then(({ payload: { data } }: ApiSingleElementResponse) => loadEntidadesRelacionadas(data))
        .catch((error: Error) => error),
    [tarifaId, dispatch, loadEntidadesRelacionadas]
  );

  useEffect(() => {
    _loadTarifa();
  }, [_loadTarifa]);

  if (!tarifa) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Loading notFoundMessage="Tarifa não encontrada!" loadingState={loadingState} />
      </div>
    );
  }

  return (
    <Page>
      <div className="mb-5">
        <DetailCard>
          <div className="mb-5">
            <DetailTitle>
              Tarifa: {tarifa.descricao} - (Código: {tarifa.codigo})
            </DetailTitle>
          </div>

          <div className="row mb-4">
            <div className="col-lg-4 col-md-6 mb-4">
              <DetailElement
                descricao="Tipo tarifa"
                valor={tarifa.tipoTarifa}
                format={FormatValueEnum.ENUM}
                map={tipoTarifaLabelMap}
              />
              <DetailElement
                descricao="Recorrência"
                valor={tarifa.recorrencia}
                format={FormatValueEnum.ENUM}
                map={periodicidadeLabelMap}
              />
              <DetailElement descricao="Produto" valor={produto?.nome} link={`/produtos/${tarifa.produto}`} />
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <DetailElement descricao="Valor" valor={tarifa.valor} format={FormatValueEnum.BRL} />
              <DetailElement descricao="Quantidade de parcelas" valor={tarifa.quantidadeParcelas} />
              <DetailElement
                descricao="Tipo transação"
                valor={tipoTransacao?.descricao}
                link={`/tipos-transacao/${tarifa.tipoTransacao}`}
              />
            </div>
          </div>
        </DetailCard>
      </div>
    </Page>
  );
};

export default DetalhesTarifaPage;

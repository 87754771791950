import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import { useCallback, useState } from 'react';
import BSButton from 'react-bootstrap/Button';
import BSSpinner from 'react-bootstrap/Spinner';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { DetailCard } from '../../components/details/datail-card';
import DetailTitle from '../../components/details/detail-title';
import { Form } from '../../components/form/form';
import { FormFile } from '../../components/form/form-file';
import { FormSelect } from '../../components/form/form-select';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import { ApiSingleElementResponse, ClassTypesEnum, importacaoArquivoMap } from '../../helpers';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch } from '../../store/hooks-redux';
import { uploadArquivo } from './importar-arquivo.redux';

type ImportarArquivoFormFields = {
  tipoArquivo: string;
  files: FileList | string;
};

const ImportarArquivoPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { showToast } = useToasts();
  const navigate = useNavigate();

  const [arquivoEmProcessamento, setArquivoEmProcessamento] = useState<boolean>(false);

  const form = useForm<ImportarArquivoFormFields>();
  const { control, reset, watch } = form;

  const tipoArquivo = watch('tipoArquivo');
  const mostrarFileInput = Boolean(tipoArquivo);

  const onSubmitHandler = useCallback(
    (data: ImportarArquivoFormFields) => {
      const { files } = data;
      const [file] = files || [];
      const arquivo = new FormData();

      arquivo.append('tipoArquivo', data.tipoArquivo);
      arquivo.append('arquivo', file);

      setArquivoEmProcessamento(true);

      dispatch(uploadArquivo({ data: arquivo }))
        .then(({ payload: { data } }: ApiSingleElementResponse) => {
          showToast({ message: 'Processamento do arquivo finalizado', type: ClassTypesEnum.SUCCESS });
          setArquivoEmProcessamento(false);

          return navigate(`/protocolos-processamento-lote/${data._id}`);
        })
        .catch((error: Error) => {
          setArquivoEmProcessamento(false);
          showToast({ message: error.message, type: ClassTypesEnum.DANGER });
        });
    },
    [dispatch, navigate, showToast]
  );

  const onCancelHandler = () => {
    reset();
  };

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.IMPORTACAO_ARQUIVO}>
      <DetailCard>
        <div className="mb-5">
          <DetailTitle renderBackButton={false}>Importar arquivo</DetailTitle>
        </div>

        <Form form={form} onSubmit={onSubmitHandler}>
          <div className="row pb-3">
            <FormSelect
              control={control}
              className="col-xl-4 mb-3"
              name="tipoArquivo"
              label="Tipo de arquivo"
              placeholder="Selecione o tipo de arquivo"
              options={Object.entries(importacaoArquivoMap)?.map(([key, value]) => ({
                key,
                value: key,
                label: value.nome,
              }))}
              rules={{ required: true }}
            />

            {mostrarFileInput && (
              <div className="col-xl-8">
                <FormFile control={control} className="mb-3" name="files" label="Arquivo" rules={{ required: true }} />
              </div>
            )}
          </div>

          <div className="d-flex">
            <BSButton className="mx-1" variant="light" onClick={onCancelHandler} disabled={arquivoEmProcessamento}>
              Cancelar
            </BSButton>
            <BSButton className="mx-1 px-4" variant="primary" type="submit" disabled={arquivoEmProcessamento}>
              Importar arquivo
            </BSButton>
          </div>
        </Form>

        {arquivoEmProcessamento && (
          <div className="d-flex justify-content-center align-items-center mt-5">
            <div className="me-3">
              <BSSpinner variant="primary" />
            </div>
            <strong>Arquivo em processamento...</strong>
          </div>
        )}
      </DetailCard>
    </RbacPage>
  );
};

export default ImportarArquivoPage;

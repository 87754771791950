import { Middleware, configureStore } from '@reduxjs/toolkit';
import { authorizationMiddleware } from './authorization-middleware';
import { callApiMiddleware } from './call-api-middleware';

import { createLogger } from 'redux-logger';
import { rootReducer } from './root-reducer';

const logger = createLogger({
  collapsed: true,
}) as Middleware<{}, ReturnType<typeof rootReducer>>;

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    const defaultMiddleware = getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
      thunk: true,
    });

    return defaultMiddleware.concat(callApiMiddleware, authorizationMiddleware, logger);
  },
});

import { reducerFactory, actionFactory, selectorFactory } from '../../store';

const context = 'protocolosProcessamentoLote';

const { loadMany: loadManyArquivos, loadOne } = actionFactory(context, {
  buildUrlLoadOne: ({ protocoloProcessamentoLoteId }) =>
    `/api/protocolos-processamento-lote/${protocoloProcessamentoLoteId}`,
  buildUrlLoadMany: () => '/api/protocolos-processamento-lote/arquivos',
});

const { loadMany: loadManyRotinasFrequentes } = actionFactory(context, {
  buildUrlLoadMany: () => '/api/protocolos-processamento-lote/rotinas-frequentes',
});

const { loadMany: loadManyRotinas } = actionFactory(context, {
  buildUrlLoadMany: () => '/api/protocolos-processamento-lote/rotinas',
});

const { loadMany: loadManyRelatoriosOperacao } = actionFactory(context, {
  buildUrlLoadMany: () => '/api/protocolos-processamento-lote/relatorios-operacao',
});

const { selectManyByFilters, selectLoadingStateByFilters, selectTotalByFilters, selectOneById } = selectorFactory({
  context,
});

export const loadProtocolosProcessamentoLoteArquivos = loadManyArquivos;
export const loadProtocolosProcessamentoLoteRotinas = loadManyRotinas;
export const loadProtocolosProcessamentoLoteRotinasFrequentes = loadManyRotinasFrequentes;
export const loadProtocolosProcessamentoLoteRelatoriosOperacao = loadManyRelatoriosOperacao;
export const loadProtocoloProcessamentoLote = loadOne;

export const selectProtocolosProcessamentoLote = selectManyByFilters;
export const selectTotalProtocolosProcessamentoLoteByFilters = selectTotalByFilters;
export const selectProtocoloProcessamentoLoteById = selectOneById;
export const selectLoadingStateByFiltersProtocoloProcessamentoLote = selectLoadingStateByFilters;

export const protocolosProcessamentoLote = reducerFactory({ context });

import DetailElement from '../../components/details/detail-element';
import { FormatValueEnum } from '../../helpers';
import { useAppSelector } from '../../store/hooks-redux';
import { selectProdutoById } from './produtos.redux';

type PermissoesProdutoTabProps = {
  produtoId: string;
};

const PermissoesProdutoTab: React.FC<PermissoesProdutoTabProps> = ({ produtoId }) => {
  const useSelector = useAppSelector;
  const produto = useSelector((state) => selectProdutoById(state, produtoId));

  return (
    <>
      <div className="row">
        <div className="col-lg-4 col-md-6 mb-4">
          <DetailElement
            descricao="Saque autorizado"
            valor={produto.permiteSaqueAutorizado}
            format={FormatValueEnum.BOOL}
          />
          <DetailElement
            descricao="Saque complementar"
            valor={produto.permiteSaqueComplementar}
            format={FormatValueEnum.BOOL}
          />
          <DetailElement
            descricao="Transação de saque na bandeira"
            valor={produto.permiteSaqueBandeira}
            format={FormatValueEnum.BOOL}
          />
        </div>

        <div className="col-lg-4 col-md-6 mb-4">
          <DetailElement
            descricao="Cobrar tarifa saque crédito"
            valor={produto.cobrarTarifaSaqueCredito}
            format={FormatValueEnum.BOOL}
          />
          <DetailElement
            descricao="Cobrar tarifa nova via cartão"
            valor={produto.cobrarTarifaNovaViaCartao}
            format={FormatValueEnum.BOOL}
          />
          <DetailElement
            descricao="Cobrar tarifa overlimit"
            valor={produto.cobrarTarifaOverlimit}
            format={FormatValueEnum.BOOL}
          />
        </div>

        <div className="col-lg-4 col-md-6 mb-4">
          <DetailElement
            descricao="Pagamento complementar da fatura"
            valor={produto.utilizaPagamentoComplementar}
            format={FormatValueEnum.BOOL}
            dica={<>Indica se o produto permite pagamento complementar da fatura.</>}
          />
          <DetailElement descricao="Permite overlimit" valor={produto.permiteOverlimit} format={FormatValueEnum.BOOL} />
        </div>
      </div>
    </>
  );
};

export default PermissoesProdutoTab;

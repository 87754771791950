import BSButton from 'react-bootstrap/Button';
import BSModal from 'react-bootstrap/Modal';

import { useForm } from 'react-hook-form';
import { Form } from '../../components/form/form';
import { FormControl, MasksFormControlEnum } from '../../components/form/form-control';

type AtualizarPrazoConfirmacaoMCCFormFields = {
  mcc: string;
  prazoEmDiasParaConfirmacao: string;
  nomeCategoriaNegocio: string;
};

type AtualizarPrazoConfirmacaoMCCDialogProps = {
  prazoConfirmacaoMCC: any;
  closeDialog: () => void;
  onUpdateHandler: (PrazoConfirmacaoMCCId: string, data: any) => void;
};

const AtualizarPrazoConfirmacaoMCCDialog: React.FC<AtualizarPrazoConfirmacaoMCCDialogProps> = ({
  closeDialog,
  prazoConfirmacaoMCC,
  onUpdateHandler,
}) => {
  const form = useForm<AtualizarPrazoConfirmacaoMCCFormFields>({ defaultValues: prazoConfirmacaoMCC });
  const { control } = form;

  const onSubmitHandler = (data: AtualizarPrazoConfirmacaoMCCFormFields) => {
    if (Object.keys(data).length === 0) {
      return;
    }

    onUpdateHandler(prazoConfirmacaoMCC._id, data);
  };

  return (
    <>
      <BSModal.Header closeButton>
        <BSModal.Title>Atualizar MCC - {prazoConfirmacaoMCC.nomeCategoriaNegocio}</BSModal.Title>
      </BSModal.Header>

      <BSModal.Body>
        <Form id="form" form={form} onSubmit={onSubmitHandler}>
          <FormControl
            control={control}
            className="mb-3"
            name="mcc"
            label="MCC"
            mask={MasksFormControlEnum.MCC}
            rules={{ required: true }}
          />

          <FormControl
            control={control}
            className="mb-3"
            name="prazoEmDiasParaConfirmacao"
            label="Prazo em dias para confirmação"
            rules={{ required: true }}
          />

          <FormControl
            control={control}
            className="mb-3"
            name="nomeCategoriaNegocio"
            label="Nome da categoria do negócio"
            rules={{ required: true }}
          />
        </Form>
      </BSModal.Body>

      <BSModal.Footer>
        <BSButton variant="light" onClick={closeDialog}>
          Fechar
        </BSButton>
        <BSButton variant="primary" type="submit" form="form">
          Atualizar
        </BSButton>
      </BSModal.Footer>
    </>
  );
};

export default AtualizarPrazoConfirmacaoMCCDialog;

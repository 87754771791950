import { reducerFactory, actionFactory, selectorFactory } from '../../store';

const context = 'protocolosProcessamentoConta';

const { loadMany, loadOne } = actionFactory(context, {
  buildUrlLoadOne: ({ protocoloProcessamentoContaId }) =>
    `/api/protocolos-processamento-conta/${protocoloProcessamentoContaId}`,
  buildUrlLoadMany: () => '/api/protocolos-processamento-conta',
});

const { loadMany: loadManyContaCartao } = actionFactory(context, {
  buildUrlLoadMany: ({ contaCartaoId }) => `/api/contas-cartao/${contaCartaoId}/protocolos-processamento-conta`,
});

const { selectManyByFilters, selectLoadingStateByFilters, selectTotalByFilters, selectOneById } = selectorFactory({
  context,
});

export const loadProtocolosProcessamentoConta = loadMany;
export const loadProtocoloProcessamentoConta = loadOne;
export const loadProtocolosProcessamentoContaPorContaCartao = loadManyContaCartao;

export const selectProtocolosProcessamentoContaByFilters = selectManyByFilters;
export const selectTotalProtocolosProcessamentoContaByFilters = selectTotalByFilters;
export const selectProtocoloProcessamentoContaById = selectOneById;
export const selectLoadingStateByFiltersProtocoloProcessamentoConta = selectLoadingStateByFilters;

export const protocolosProcessamentoConta = reducerFactory({ context });

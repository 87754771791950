import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import { DetailCard } from '../../components/details/datail-card';
import DetailTitle from '../../components/details/detail-title';
import { Loading } from '../../components/loading';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import {
  loadContratoTermosUso,
  selectContratoTermosUsoById,
  selectContratosTermosUsoLoadingStateByFilters,
} from './contratos-termos-uso.redux';
import DownloadFileButton from '../../components/button/download-file-button';
import RbacElement from '../../components/role-based-access-control/role-based-access-control-element';
import DetailElement from '../../components/details/detail-element';
import { FormatValueEnum, tipoContratoTermosUsoLabelMap } from '../../helpers';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { loadProdutos, selectObjectTodosProdutos } from '../produtos/produtos.redux';

const DetalhesContratoTermosUsoPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const { maxItemsQuery } = useQuerystring();

  const params = useParams();
  const contratoTermosUsoId = params.contratoTermosUsoId as string;

  const filters = useMemo(() => ({ contratoTermosUsoId }), [contratoTermosUsoId]);

  const contrato = useSelector((state) => selectContratoTermosUsoById(state, contratoTermosUsoId));
  const loadingState = useSelector((state) => selectContratosTermosUsoLoadingStateByFilters(state, filters));
  const produtos = useSelector((state) => selectObjectTodosProdutos(state));

  useEffect(() => {
    dispatch(loadProdutos({ query: { ...maxItemsQuery } })).catch((error: Error) => error);
    dispatch(loadContratoTermosUso({ contratoTermosUsoId })).catch((error: Error) => error);
  }, [contratoTermosUsoId, dispatch, maxItemsQuery]);

  if (!contrato) {
    return (
      <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_CONTRATO_TERMOS_USO}>
        <div className="d-flex justify-content-center align-items-center h-100">
          <Loading notFoundMessage="Contrato de termos de uso não encontrado!" loadingState={loadingState} />
        </div>
      </RbacPage>
    );
  }

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_CONTRATO_TERMOS_USO}>
      <div className="mb-5">
        <DetailCard>
          <div className="row mb-5">
            <div className="col-md-8 col-sm-12 mb-3">
              <DetailTitle>
                <div className="d-flex">
                  <span>
                    Contrato:{' '}
                    {tipoContratoTermosUsoLabelMap[contrato.tipo as keyof typeof tipoContratoTermosUsoLabelMap]}
                  </span>
                </div>
              </DetailTitle>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-4 col-md-6 mb-4">
              <DetailElement descricao="Tipo" valor={contrato.tipo} />
              <DetailElement
                descricao="Produto"
                valor={produtos?.[contrato.produtoId]?.nome}
                link={`/produtos/${contrato.produtoId}`}
              />
              <DetailElement descricao="Mais recente" valor={contrato.maisRecente} format={FormatValueEnum.BOOL} />
              <DetailElement
                descricao="Data de início da vigência"
                valor={contrato.dataInicioVigencia}
                format={FormatValueEnum.DATA}
              />
              <DetailElement
                descricao="Data de fim da vigência"
                valor={contrato.dataFimVigencia}
                format={FormatValueEnum.DATA}
              />
            </div>
          </div>

          <div className="row ">
            <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.DOWNLOAD_ARQUIVO}>
              <div className="row">
                <div className="col-12 d-flex justify-content-end flex-wrap">
                  <DownloadFileButton
                    url={`/api/contratos-termos-uso/${contratoTermosUsoId}/download-arquivo`}
                    fileName={`${contratoTermosUsoId}.pdf`}
                  >
                    Download do contrato
                  </DownloadFileButton>
                </div>
              </div>
            </RbacElement>
          </div>
        </DetailCard>
      </div>
    </RbacPage>
  );
};

export default DetalhesContratoTermosUsoPage;

import { reducerFactory, actionFactory, selectorFactory } from '../../store';

const context = 'taxasMediasSelic';

const { loadMany } = actionFactory(context, {
  buildUrlLoadMany: () => '/api/taxas-medias-selic',
});

const { selectOneById, selectAll, selectManyByFilters, selectTotalByFilters, selectLoadingStateByFilters } =
  selectorFactory({ context });

export const loadTaxasMediaSelic = loadMany;

export const selectTaxaMediaSelicById = selectOneById;
export const selectTodosTaxasMediasSelic = selectAll;
export const selectTaxasMediasSelicByFilters = selectManyByFilters;
export const selectTotalTaxasMediasSelicByFilters = selectTotalByFilters;
export const selectTaxasMediasSelicLoadingStateByFilters = selectLoadingStateByFilters;

export const taxasMediasSelic = reducerFactory({ context });

import { reducerFactory, actionFactory, selectorFactory } from '../../store';

const context = 'tiposTransacao';

const { createOne, loadMany, loadOne, updateOne } = actionFactory(context, {
  buildUrlCreateOne: () => '/api/tipos-transacao',
  buildUrlLoadMany: () => '/api/tipos-transacao',
  buildUrlLoadOne: ({ tipoTransacaoId }) => `/api/tipos-transacao/${tipoTransacaoId}`,
  buildUrlUpdateOne: ({ tipoTransacaoId }) => `/api/tipos-transacao/${tipoTransacaoId}`,
});

const {
  selectOneById,
  selectAll,
  selectManyByFilters,
  selectTotalByFilters,
  selectLoadingStateByFilters,
  selectObjectAllIds,
} = selectorFactory({
  context,
});

export const createTipoTransacao = createOne;
export const updateTipoTransacao = updateOne;

export const loadTiposTransacao = loadMany;
export const loadTipoTransacao = loadOne;

export const selectTiposTransacaoById = selectOneById;
export const selectTodosTiposTransacao = selectAll;
export const selectObjectTodosTiposTransacao = selectObjectAllIds;
export const selectTiposTransacaoByFilters = selectManyByFilters;
export const selectTotalTiposTransacaoByFilters = selectTotalByFilters;
export const selectTiposTransacaoLoadingStateByFilters = selectLoadingStateByFilters;

export const tiposTransacao = reducerFactory({ context });

import { combineReducers, createAction, createReducer } from '@reduxjs/toolkit';
import {
  ApiMultiElementResponse,
  ApiResponse,
  BodyRequest,
  LoadingStateType,
  PathParameters,
  PayloadDataResponseObject,
  QueryParameters,
  camelToSnakeUpperCase,
  serializeFilters,
} from '../helpers';

interface ReducerFactoryProps {
  context: string;
}

export const reducerFactory = ({ context: unformatedContext }: ReducerFactoryProps) => {
  const context = camelToSnakeUpperCase(unformatedContext);

  const actionTypes = {
    createFailureActionType: `CREATE_${context}_FAILURE`,
    createRequestActionType: `CREATE_${context}_REQUEST`,
    createSuccessActionType: `CREATE_${context}_SUCCESS`,

    deleteFailureActionType: `DELETE_${context}_FAILURE`,
    deleteRequestActionType: `DELETE_${context}_REQUEST`,
    deleteSuccessActionType: `DELETE_${context}_SUCCESS`,

    loadFailureActionType: `LOAD_${context}_FAILURE`,
    loadRequestActionType: `LOAD_${context}_REQUEST`,
    loadSuccessActionType: `LOAD_${context}_SUCCESS`,

    updateFailureActionType: `UPDATE_${context}_FAILURE`,
    updateRequestActionType: `UPDATE_${context}_REQUEST`,
    updateSuccessActionType: `UPDATE_${context}_SUCCESS`,
  };

  const loadRequestAction = createAction<{
    filters: PathParameters | QueryParameters;
  }>(actionTypes.loadRequestActionType);

  const loadFailureAction = createAction<{
    filters: PathParameters | QueryParameters;
  }>(actionTypes.loadFailureActionType);

  const loadSuccessAction = createAction<{
    filters: PathParameters | QueryParameters;
    response: ApiResponse;
  }>(actionTypes.loadSuccessActionType);

  const createRequestAction = createAction<{
    requestId: string;
  }>(actionTypes.createRequestActionType);

  const createFailureAction = createAction<{
    requestId: string;
  }>(actionTypes.createFailureActionType);

  const createSuccessAction = createAction<{
    params: PathParameters;
    data: BodyRequest;
    response: ApiResponse;
    requestId: string;
  }>(actionTypes.createSuccessActionType);

  const updateRequestAction = createAction<{
    requestId: string;
  }>(actionTypes.updateRequestActionType);

  const updateFailureAction = createAction<{
    requestId: string;
  }>(actionTypes.updateFailureActionType);

  const updateSuccessAction = createAction<{
    requestId: string;
    params: PathParameters;
    data: BodyRequest;
    response: ApiResponse;
  }>(actionTypes.updateSuccessActionType);

  const deleteRequestAction = createAction<{
    requestId: string;
  }>(actionTypes.deleteRequestActionType);

  const deleteFailureAction = createAction<{
    requestId: string;
  }>(actionTypes.deleteFailureActionType);

  const deleteSuccessAction = createAction<{
    requestId: string;
    params: PathParameters;
    data: BodyRequest;
    response: ApiResponse;
  }>(actionTypes.deleteSuccessActionType);

  const ids = {
    allIds: createReducer([] as string[], (builder) =>
      builder
        .addCase(loadSuccessAction, (state, action) =>
          assignAllIds({ state, data: action.payload.response.payload.data })
        )
        .addCase(createSuccessAction, (state, action) =>
          assignAllIds({ state, data: action.payload.response.payload.data })
        )
        .addCase(updateSuccessAction, (state, action) =>
          assignAllIds({ state, data: action.payload.response.payload.data })
        )
        .addCase(deleteSuccessAction, (state, action) =>
          deleteIds({ state, data: action.payload.response.payload.data })
        )
    ),
    byIds: createReducer({} as Record<string, any>, (builder) =>
      builder
        .addCase(loadSuccessAction, (state, action) =>
          assignById({ state, data: action.payload.response.payload.data })
        )
        .addCase(createSuccessAction, (state, action) =>
          assignById({ state, data: action.payload.response.payload.data })
        )
        .addCase(updateSuccessAction, (state, action) =>
          assignById({ state, data: action.payload.response.payload.data })
        )
        .addCase(deleteSuccessAction, (state, action) =>
          deleteManyById({ state, data: action.payload.response.payload.data })
        )
    ),
  };

  const byFilters = {
    byFilters: createReducer({} as Record<string, string[]>, (builder) =>
      builder
        .addCase(loadSuccessAction, (state, action) =>
          assignByFilters({
            state,
            data: action.payload.response.payload.data,
            filters: action.payload.filters,
          })
        )
        .addCase(updateSuccessAction, () => deleteAllFilters())
        .addCase(createSuccessAction, () => deleteAllFilters())
        .addCase(deleteSuccessAction, () => deleteAllFilters())
    ),
  };

  const totalByFilters = {
    totalByFilters: createReducer({} as Record<string, number>, (builder) =>
      builder
        .addCase(loadSuccessAction, (state, action) =>
          assignTotalSizeByFilter({
            state,
            total: (action.payload.response as ApiMultiElementResponse).payload.total,
            filters: action.payload.filters,
          })
        )
        .addCase(createSuccessAction, () => deleteAllFilters())
        .addCase(updateSuccessAction, () => deleteAllFilters())
        .addCase(deleteSuccessAction, () => deleteAllFilters())
    ),
  };

  const loadingStateByFilters = {
    loadingStateByFilters: createReducer({} as Record<string, LoadingStateType>, (builder) =>
      builder
        .addCase(loadRequestAction, (state, action) =>
          assignLoadingStateByFilters({
            state,
            filters: action.payload.filters,
            loadingState: 'loading',
          })
        )
        .addCase(loadFailureAction, (state, action) =>
          assignLoadingStateByFilters({
            state,
            filters: action.payload.filters,
            loadingState: 'failure',
          })
        )
        .addCase(loadSuccessAction, (state, action) =>
          assignLoadingStateByFilters({
            state,
            filters: action.payload.filters,
            loadingState: 'loaded',
          })
        )
    ),
  };

  const loadingStateByCreate = {
    loadingStateByCreate: createReducer({} as Record<string, LoadingStateType>, (builder) =>
      builder
        .addCase(createRequestAction, (state, action) =>
          assignLoadingStateByRequest({
            state,
            requestId: action.payload.requestId,
            loadingState: 'loading',
          })
        )
        .addCase(createFailureAction, (state, action) =>
          assignLoadingStateByRequest({
            state,
            requestId: action.payload.requestId,
            loadingState: 'failure',
          })
        )
        .addCase(createSuccessAction, (state, action) =>
          assignLoadingStateByRequest({
            state,
            requestId: action.payload.requestId,
            loadingState: 'loaded',
          })
        )
    ),
  };

  const loadingStateByUpdate = {
    loadingStateByUpdate: createReducer({} as Record<string, LoadingStateType>, (builder) =>
      builder
        .addCase(updateRequestAction, (state, action) =>
          assignLoadingStateByRequest({
            state,
            requestId: action.payload.requestId,
            loadingState: 'loading',
          })
        )
        .addCase(updateFailureAction, (state, action) =>
          assignLoadingStateByRequest({
            state,
            requestId: action.payload.requestId,
            loadingState: 'failure',
          })
        )
        .addCase(updateSuccessAction, (state, action) =>
          assignLoadingStateByRequest({
            state,
            requestId: action.payload.requestId,
            loadingState: 'loaded',
          })
        )
    ),
  };

  const loadingStateByDelete = {
    loadingStateByDelete: createReducer({} as Record<string, LoadingStateType>, (builder) =>
      builder
        .addCase(deleteRequestAction, (state, action) =>
          assignLoadingStateByRequest({
            state,
            requestId: action.payload.requestId,
            loadingState: 'loading',
          })
        )
        .addCase(deleteFailureAction, (state, action) =>
          assignLoadingStateByRequest({
            state,
            requestId: action.payload.requestId,
            loadingState: 'failure',
          })
        )
        .addCase(deleteSuccessAction, (state, action) =>
          assignLoadingStateByRequest({
            state,
            requestId: action.payload.requestId,
            loadingState: 'loaded',
          })
        )
    ),
  };

  return combineReducers({
    ...ids,
    ...byFilters,
    ...totalByFilters,
    ...loadingStateByFilters,
    ...loadingStateByCreate,
    ...loadingStateByUpdate,
    ...loadingStateByDelete,
  });
};

const assignAllIds = ({
  state,
  data,
}: {
  state: string[];
  data: PayloadDataResponseObject | PayloadDataResponseObject[];
}): string[] => {
  if (!data) {
    return [...state];
  }

  if (Array.isArray(data)) {
    const elementsIds = data.map((elem) => elem._id as string);

    return [...new Set([...state, ...elementsIds])];
  }

  const id = data._id as string;

  return [...new Set([...state, id])];
};

const assignById = ({
  state,
  data,
}: {
  state: Record<string, any>;
  data: PayloadDataResponseObject | PayloadDataResponseObject[];
}) => {
  if (!data) {
    return { ...state };
  }

  if (Array.isArray(data)) {
    return data.reduce(
      (newState, elem) => {
        const id = elem._id as string;

        return { ...newState, [id]: elem };
      },
      { ...state }
    );
  }

  const id = data._id as string;

  return { ...state, [id]: data };
};

const assignByFilters = ({
  state,
  filters,
  data,
}: {
  state: Record<string, any>;
  filters: QueryParameters | PathParameters;
  data: PayloadDataResponseObject | PayloadDataResponseObject[];
}) => {
  if (!data) {
    return { ...state };
  }

  const serializedFilters = serializeFilters(filters);

  const elementsIds = Array.isArray(data) ? data.map((elem) => elem._id as string) : [data._id as string];

  return {
    ...state,
    [serializedFilters]: [...new Set([...(state[serializedFilters] ?? []), ...elementsIds])],
  };
};

const deleteAllFilters = () => ({});

const assignTotalSizeByFilter = ({
  state,
  total,
  filters,
}: {
  state: Record<string, any>;
  total?: number;
  filters: QueryParameters | PathParameters;
}) => {
  if (!total) {
    return { ...state };
  }

  return { ...state, [serializeFilters(filters)]: total };
};

const deleteIds = ({
  state,
  data,
}: {
  state: string[];
  data: PayloadDataResponseObject | PayloadDataResponseObject[];
}) => {
  if (!data) {
    return [...state];
  }

  if (Array.isArray(data)) {
    const elementsIds = data.map((elem) => elem._id as string);

    return state.filter((item) => !elementsIds.includes(item));
  }

  const id = data._id as string;

  return state.filter((item) => item !== id);
};

const deleteManyById = ({
  state,
  data,
}: {
  state: Record<string, any>;
  data: PayloadDataResponseObject | PayloadDataResponseObject[];
}) => {
  if (!data) {
    return { ...state };
  }

  if (Array.isArray(data)) {
    const ids = data.map((elem) => elem._id as string);

    return Object.entries(state).reduce((newState, [key, value]) => {
      if (ids.includes(key)) {
        return { ...newState };
      }

      return { ...newState, [key]: value };
    }, {});
  }

  const id = data._id as string;

  return Object.entries(state).reduce(
    (newState, [key, value]) => (id === key ? { ...newState } : { ...newState, [key]: value }),
    {}
  );
};

const assignLoadingStateByFilters = ({
  state,
  filters,
  loadingState,
}: {
  state: Record<string, any>;
  filters: QueryParameters | PathParameters;
  loadingState: LoadingStateType;
}) => {
  const serializedFilters = serializeFilters(filters);

  return { ...state, [serializedFilters]: loadingState };
};

const assignLoadingStateByRequest = ({
  state,
  requestId,
  loadingState,
}: {
  state: Record<string, any>;
  requestId: string;
  loadingState: LoadingStateType;
}) => {
  return { ...state, [requestId]: loadingState };
};

import { useCallback, useMemo } from 'react';
import BSCard from 'react-bootstrap/Card';
import { BsPencilSquare } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import IconButton from '../../components/button/icon-button';
import RbacElement from '../../components/role-based-access-control/role-based-access-control-element';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import { SmartTable } from '../../components/smart-table/smart-table';
import SmartTableButton from '../../components/smart-table/smart-table-button';
import SmartTableFilters from '../../components/smart-table/smart-table-filters/smart-table-filters';
import {
  SmartTableFilterControlTypesEnum,
  SmartTableFilterProps,
} from '../../components/smart-table/smart-table-filters/table-filter.types';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import Title from '../../components/title';
import { ClassTypesEnum, FormatValueEnum } from '../../helpers';
import { DialogProps } from '../../hooks/dialog/dialog-context';
import { useDialog } from '../../hooks/dialog/use-dialog';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch } from '../../store/hooks-redux';
import {
  loadPerguntasSeguranca,
  selectPerguntasSegurancaByFilters,
  selectPerguntasSegurancaLoadingStateByFilters,
  selectTotalPerguntasSegurancaByFilters,
  updatePerguntaSeguranca,
} from './perguntas-seguranca.redux';

import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import AtualizarPerguntaSegurancaDialog from './atualizar-perguntas-seguranca-dialog';
import CadastrarPerguntaSegurancaDialog from './cadastrar-perguntas-seguranca-dialog';

const smartFilters = (): SmartTableFilterProps[] => [
  {
    label: 'Título',
    attribute: 'titulo',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
  },
];

const smartColumns = ({
  showDialog,
  closeDialog,
  onUpdatePerguntaSegurancaHandler,
}: {
  showDialog: (dialogProps: DialogProps) => void;
  closeDialog: () => void;
  onUpdatePerguntaSegurancaHandler: (perguntaSegurancaId: string, data: any) => void;
}): SmartTableColumnProps[] => [
  {
    label: 'Título',
    attribute: 'titulo',
    sortable: true,
    className: 'text-center',
  },
  {
    label: 'Ativa para novos usuários',
    attribute: 'ativaParaNovoUsuarios',
    format: FormatValueEnum.BOOL,
    sortable: true,
    className: 'text-center',
  },
  {
    label: 'Ações',
    format: (_, perguntaSeguranca) => {
      return (
        <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.ALTERACAO_PERGUNTA_SEGURANCA}>
          <div className="d-flex justify-content-center">
            <div className="mx-1">
              <IconButton
                Icon={BsPencilSquare}
                type={ClassTypesEnum.PRIMARY}
                onClick={() => {
                  showDialog({
                    component: (
                      <AtualizarPerguntaSegurancaDialog
                        closeDialog={closeDialog}
                        perguntaSeguranca={perguntaSeguranca}
                        onUpdateHandler={onUpdatePerguntaSegurancaHandler}
                      />
                    ),
                  });
                }}
              />
            </div>
          </div>
        </RbacElement>
      );
    },
  },
];

const PerguntasSegurancaPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { query } = useQuerystring();
  const { closeDialog, showDialog } = useDialog();
  const { showToast } = useToasts();

  const onNovaPerguntaSegurancaHandler = () => {
    showDialog({
      component: <CadastrarPerguntaSegurancaDialog closeDialog={closeDialog} reload={loadItems} />,
    });
  };

  const filters = useMemo(() => ({ ...query }), [query]);
  const total = useSelector((state) => selectTotalPerguntasSegurancaByFilters(state, filters));
  const perguntasSeguranca = useSelector((state) => selectPerguntasSegurancaByFilters(state, filters));
  const loadingState = useSelector((state) => selectPerguntasSegurancaLoadingStateByFilters(state, filters));

  const onUpdatePerguntaSegurancaHandler = (perguntaSegurancaId: string, data: any) => {
    if (!data) {
      return;
    }

    dispatch(updatePerguntaSeguranca({ params: { perguntaSegurancaId }, data }))
      .then(() => {
        closeDialog();

        showToast({
          message: 'Pergunta de segurança atualizada com sucesso',
          type: ClassTypesEnum.SUCCESS,
        });

        return loadItems();
      })
      .catch((error: Error) => {
        showToast({
          message: error.message,
          type: ClassTypesEnum.DANGER,
        });
      });
  };

  const loadItems = useCallback(
    () => dispatch(loadPerguntasSeguranca({ query: filters })).catch((error: Error) => error),
    [dispatch, filters]
  );

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_PERGUNTA_SEGURANCA}>
      <div className="d-flex flex-column h-100">
        <Title>Perguntas de segurança</Title>

        <div className="row">
          <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-12 mb-4">
            <SmartTableFilters filters={smartFilters()} />
          </div>

          <div className="col-xxl-10 col-xl-9 col-lg-8 col-md-12 mb-4">
            <BSCard>
              <BSCard.Body>
                <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.CRIACAO_PERGUNTA_SEGURANCA}>
                  <div className="d-flex align-items-center mb-2">
                    <SmartTableButton onClick={onNovaPerguntaSegurancaHandler}>
                      Nova pergunta de segurança
                    </SmartTableButton>
                  </div>
                </RbacElement>
                <SmartTable
                  emptyMessage="Nenhuma pergunta de segurança encontrada"
                  errorMessage="Erro na listagem de perguntas de segurança"
                  usePagination={true}
                  loadItems={loadItems}
                  columns={smartColumns({ closeDialog, showDialog, onUpdatePerguntaSegurancaHandler })}
                  items={perguntasSeguranca}
                  loadingState={loadingState}
                  size={total}
                />
              </BSCard.Body>
            </BSCard>
          </div>
        </div>
      </div>
    </RbacPage>
  );
};

export default PerguntasSegurancaPage;

import React from 'react';
import Table from 'react-bootstrap/esm/Table';

interface TableData {
  [key: string]: string;
}

interface TableProps {
  data: Array<TableData>;
  className?: string;
  cabecalhos: Array<string>;
}

const TableQMR: React.FC<TableProps> = ({ data, className, cabecalhos }) => {
  const columns = Array.from(new Set(data.flatMap((row) => Object.keys(row))));
  return (
    <Table className="w-80 inline-block" size="sm">
      <thead>
        <tr>
          {cabecalhos.map((column: any, columnIndex: number) => {
            return (
              <th key={columnIndex} className={`${!className && 'text-front'} text-nowrap`}>
                <small>{column}</small>
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {data.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {columns.map((label: any, columnIndex) => (
              <td key={columnIndex}>{row[label]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default TableQMR;

import { reducerFactory, actionFactory, selectorFactory } from '../../store';

const context = 'perguntasFrequentes';

const {
  createOne,
  loadMany,
  loadOne,
  updateOne: updateOneTopico,
} = actionFactory(context, {
  buildUrlCreateOne: () => '/api/perguntas-frequentes',
  buildUrlLoadMany: () => '/api/perguntas-frequentes',
  buildUrlLoadOne: ({ perguntaFrequenteId }) => `/api/perguntas-frequentes/${perguntaFrequenteId}`,
  buildUrlUpdateOne: ({ perguntaFrequenteId, topicoId }) =>
    `/api/perguntas-frequentes/${perguntaFrequenteId}/topicos/${topicoId}`,
});

const { updateOne: createOneTopico } = actionFactory(context, {
  buildUrlUpdateOne: ({ perguntaFrequenteId }) => `/api/perguntas-frequentes/${perguntaFrequenteId}/topicos`,
});

const { updateOne: createOnePerguntaResposta } = actionFactory(context, {
  buildUrlUpdateOne: ({ perguntaFrequenteId, topicoId }) =>
    `/api/perguntas-frequentes/${perguntaFrequenteId}/topicos/${topicoId}/perguntas`,
});

const { updateOne: updateOnePergunta } = actionFactory(context, {
  buildUrlUpdateOne: ({ perguntaFrequenteId, topicoId, perguntaId }) =>
    `/api/perguntas-frequentes/${perguntaFrequenteId}/topicos/${topicoId}/perguntas/${perguntaId}`,
});

const { selectOneById, selectManyByFilters, selectLoadingStateByFilters, selectTotalByFilters } = selectorFactory({
  context,
});

export const createPerguntasFrequentes = createOne;
export const createTopicoPerguntasFrequentes = createOneTopico;
export const createPerguntaResposta = createOnePerguntaResposta;

export const loadPerguntaFrequente = loadOne;
export const loadPerguntasFrequentes = loadMany;

export const selectPerguntasFrequentesById = selectOneById;
export const selectPerguntasFrequentesByFilters = selectManyByFilters;
export const selectTotalPerguntasFrequentesByFilters = selectTotalByFilters;
export const selectPerguntasFrequentesLoadingStateByFilters = selectLoadingStateByFilters;

export const removerPerguntaPerguntasFrequentes = updateOnePergunta;
export const removerTopicoPerguntasFrequentes = updateOneTopico;

export const perguntasFrequentes = reducerFactory({ context });

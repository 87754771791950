import React from 'react';

import BSButton from 'react-bootstrap/Button';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Form } from '../../components/form/form';
import { FormControl, MasksFormControlEnum } from '../../components/form/form-control';
import { ClassTypesEnum } from '../../helpers';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch } from '../../store/hooks-redux';
import { updateProduto } from './produtos.redux';

type AtualizarJurosProdutoFormFields = {
  taxaJurosRotativo: number;
  taxaJurosSaque: number;
  taxaJurosCompraParceladaEmissor: number;
  taxaEfetivaAnual: number;
};

type AtualizarJurosProdutoTabProps = {
  produto: any;
};

const AtualizarJurosProdutoTab: React.FC<AtualizarJurosProdutoTabProps> = ({ produto }) => {
  const dispatch = useAppDispatch();
  const { showToast } = useToasts();
  const navigate = useNavigate();

  const form = useForm<AtualizarJurosProdutoFormFields>({
    defaultValues: {
      taxaJurosRotativo: Number(produto.taxaJurosRotativo),
      taxaJurosSaque: Number(produto.taxaJurosSaque),
      taxaJurosCompraParceladaEmissor: Number(produto.taxaJurosCompraParceladaEmissor),
      taxaEfetivaAnual: Number(produto.taxaEfetivaAnual),
    },
  });
  const { control } = form;

  const onSubmitHandler = (data: AtualizarJurosProdutoFormFields) => {
    if (Object.keys(data).length === 0) {
      return;
    }

    dispatch(updateProduto({ params: { produtoId: produto._id }, data }))
      .then(() => {
        showToast({
          message: 'Produto atualizado com sucesso',
          type: ClassTypesEnum.SUCCESS,
        });

        return navigate('/produtos/');
      })
      .catch((error: Error) => {
        showToast({
          message: error.message,
          type: ClassTypesEnum.DANGER,
        });
      });
  };

  return (
    <>
      <Form form={form} id="form" onSubmit={onSubmitHandler}>
        <div className="row">
          <FormControl
            control={control}
            className="col-xl-4 col-md-6 col-sm-12 mb-3"
            label="Rotativo"
            name="taxaJurosRotativo"
            mask={MasksFormControlEnum.PERCENT}
          />

          <FormControl
            control={control}
            className="col-xl-4 col-md-6 col-sm-12 mb-3"
            label="Saque"
            name="taxaJurosSaque"
            mask={MasksFormControlEnum.PERCENT}
          />

          <FormControl
            control={control}
            className="col-xl-4 col-md-6 col-sm-12 mb-3"
            label="Compra parceclada emissor"
            name="taxaJurosCompraParceladaEmissor"
            mask={MasksFormControlEnum.PERCENT}
          />

          <FormControl
            control={control}
            className="col-xl-4 col-md-6 col-sm-12 mb-3"
            label="Taxa efetiva anual"
            name="taxaEfetivaAnual"
            mask={MasksFormControlEnum.PERCENT}
          />

          <div className="d-flex justify-content-end">
            <BSButton variant="primary" type="submit" form="form">
              Atualizar
            </BSButton>
          </div>
        </div>
      </Form>
    </>
  );
};
export default AtualizarJurosProdutoTab;

import BSFloatingLabel from 'react-bootstrap/FloatingLabel';
import BSButton from 'react-bootstrap/Button';
import BSInputGroup from 'react-bootstrap/InputGroup';
import BSForm from 'react-bootstrap/Form';
import { useSearchParams } from 'react-router-dom';
import { SmartTableFilterComponentProps, SmartTableFilterControlTypesEnum } from './table-filter.types';
import { BsX } from 'react-icons/bs';
import React, { useEffect, useState } from 'react';

enum RangeAttibuteTypeEnum {
  MAX = 'max',
  MIN = 'min',
}

const { MIN, MAX } = RangeAttibuteTypeEnum;
const { DATE_RANGE, DATE_TIME_RANGE } = SmartTableFilterControlTypesEnum;

const searchParamPropMapper = {
  [DATE_RANGE]: { minProp: 'dateGte', maxProp: 'dateLte' },
  [DATE_TIME_RANGE]: { minProp: 'dateTimeGte', maxProp: 'dateTimeLte' },
};

const RangeDatepickerFilter: React.FC<SmartTableFilterComponentProps> = ({
  filter,
  removeAttributeFromSearchParams,
  upsertAttributeInSearchParams,
}) => {
  const [searchParams] = useSearchParams();
  const [minFormControlValue, setMinFormControlValue] = useState('');
  const [maxFormControlValue, setMaxFormControlValue] = useState('');
  const { attribute, label, className, controlType } = filter;
  const { minProp, maxProp } = searchParamPropMapper[controlType as keyof typeof searchParamPropMapper];

  const minAttribute = `${attribute}[${minProp}]`;
  const maxAttribute = `${attribute}[${maxProp}]`;

  const onChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    rangeAttribute: RangeAttibuteTypeEnum
  ): void => {
    const { value } = event.target as HTMLInputElement;

    event.preventDefault();

    if (rangeAttribute === MIN) {
      setMinFormControlValue(value);

      return upsertAttributeInSearchParams(minAttribute, value);
    }

    setMaxFormControlValue(value);

    return upsertAttributeInSearchParams(maxAttribute, value);
  };

  const clearFilterHandler = (rangeAttribute: RangeAttibuteTypeEnum): void => {
    if (rangeAttribute === MIN) {
      setMinFormControlValue('');

      return removeAttributeFromSearchParams(minAttribute);
    }

    setMaxFormControlValue('');

    return removeAttributeFromSearchParams(maxAttribute);
  };

  useEffect((): void => {
    const minValue = searchParams.get(minAttribute);
    setMinFormControlValue(minValue ?? '');

    const maxValue = searchParams.get(maxAttribute);
    setMaxFormControlValue(maxValue ?? '');
  }, [maxAttribute, minAttribute, searchParams]);

  return (
    <BSForm noValidate className={className ?? 'mb-3'}>
      <BSForm.Group className="mb-3">
        <BSForm.Label>{label}</BSForm.Label>
        <BSInputGroup className="d-flex flex-nowrap mb-2">
          <BSFloatingLabel label="De" className="w-100">
            <BSForm.Control
              type="date"
              placeholder="De"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeHandler(e, MIN)}
              value={minFormControlValue}
            />
          </BSFloatingLabel>
          {searchParams.get(minAttribute) && (
            <BSButton variant="outline-primary" onClick={() => clearFilterHandler(MIN)}>
              <BsX size={20} />
            </BSButton>
          )}
        </BSInputGroup>

        <BSInputGroup className="d-flex flex-nowrap">
          <BSFloatingLabel label="Até" className="w-100">
            <BSForm.Control
              type="date"
              placeholder="Até"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeHandler(e, MAX)}
              value={maxFormControlValue}
            />
          </BSFloatingLabel>
          {searchParams.get(maxAttribute) && (
            <BSButton variant="outline-primary" onClick={() => clearFilterHandler(MAX)}>
              <BsX size={20} />
            </BSButton>
          )}
        </BSInputGroup>
      </BSForm.Group>
    </BSForm>
  );
};

export default RangeDatepickerFilter;

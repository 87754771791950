import { reducerFactory, actionFactory, selectorFactory } from '../../store';

const context = 'feriados';

const { loadMany, createOne } = actionFactory(context, {
  buildUrlLoadMany: () => '/api/feriados',
  buildUrlCreateOne: () => '/api/feriados',
});

const { selectOneById, selectAll, selectManyByFilters, selectTotalByFilters, selectLoadingStateByFilters } =
  selectorFactory({ context });

export const saveFeriado = createOne;
export const loadFeriados = loadMany;

export const selectFeriadoById = selectOneById;
export const selectTodosFeriados = selectAll;
export const selectFeriadoByFilters = selectManyByFilters;
export const selectTotalFeriadosByFilters = selectTotalByFilters;
export const selectFeriadosLoadingStateByFilters = selectLoadingStateByFilters;

export const feriados = reducerFactory({ context });

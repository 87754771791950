import React from 'react';

import BSButton from 'react-bootstrap/Button';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Form } from '../../components/form/form';
import { FormCheck } from '../../components/form/form-check';
import { ClassTypesEnum } from '../../helpers';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch } from '../../store/hooks-redux';
import { updateProduto } from './produtos.redux';

type AtualizarDoacaoProdutoFormFields = {
  permiteDoacaoArredondamentoCompra: boolean;
  permiteDoacaoArredondamentoFatura: boolean;
  permiteDoacaoEspontanea: boolean;
  permiteDoacaoRecorrenteMensal: boolean;
};

type AtualizarDoacaoProdutoTabProps = {
  produto: any;
};

const AtualizarDoacaoProdutoTab: React.FC<AtualizarDoacaoProdutoTabProps> = ({ produto }) => {
  const dispatch = useAppDispatch();
  const { showToast } = useToasts();
  const navigate = useNavigate();

  const form = useForm<AtualizarDoacaoProdutoFormFields>({
    defaultValues: {
      permiteDoacaoArredondamentoCompra: Boolean(produto.permiteDoacaoArredondamentoCompra),
      permiteDoacaoArredondamentoFatura: Boolean(produto.permiteDoacaoArredondamentoFatura),
      permiteDoacaoEspontanea: Boolean(produto.permiteDoacaoEspontanea),
      permiteDoacaoRecorrenteMensal: Boolean(produto.permiteDoacaoRecorrenteMensal),
    },
  });
  const { control } = form;

  const onSubmitHandler = (data: AtualizarDoacaoProdutoFormFields) => {
    if (Object.keys(data).length === 0) {
      return;
    }

    dispatch(updateProduto({ params: { produtoId: produto._id }, data }))
      .then(() => {
        showToast({
          message: 'Produto atualizado com sucesso',
          type: ClassTypesEnum.SUCCESS,
        });

        return navigate('/produtos/');
      })
      .catch((error: Error) => {
        showToast({
          message: error.message,
          type: ClassTypesEnum.DANGER,
        });
      });
  };

  return (
    <>
      <Form form={form} id="form" onSubmit={onSubmitHandler}>
        <div className="row">
          <FormCheck
            control={control}
            className="col-12 mb-3"
            name="permiteDoacaoRecorrenteMensal"
            label="Permite doação recorrente mensal"
            type="switch"
          />

          <FormCheck
            control={control}
            className="col-12 mb-3"
            name="permiteDoacaoArredondamentoCompra"
            label="Permite doação de arredondamento de compra"
            type="switch"
          />

          <FormCheck
            control={control}
            className="col-12 mb-3"
            name="permiteDoacaoArredondamentoFatura"
            label="Permite doação de arredondamento de fatura"
            type="switch"
          />

          <FormCheck
            control={control}
            className="col-12 mb-3"
            name="permiteDoacaoEspontanea"
            label="Permite doação espontânea"
            type="switch"
          />

          <div className="d-flex justify-content-end">
            <BSButton variant="primary" type="submit" form="form">
              Atualizar
            </BSButton>
          </div>
        </div>
      </Form>
    </>
  );
};
export default AtualizarDoacaoProdutoTab;

import BSForm, { FormProps as BSFormProps } from 'react-bootstrap/Form';
import { FieldValues, SubmitHandler, UseFormReturn } from 'react-hook-form';

export interface FormProps<TFormValues extends FieldValues> extends Omit<BSFormProps, 'onSubmit'> {
  form: UseFormReturn<TFormValues>;
  onSubmit?: SubmitHandler<TFormValues>;
  submitPristine?: boolean;
}

export const Form = <TFormValues extends FieldValues>({
  form,
  submitPristine = false,
  children,
  ...props
}: FormProps<TFormValues>): JSX.Element => {
  const {
    handleSubmit,
    formState: { dirtyFields },
  } = form;

  const onSuccessHandler = (data: TFormValues) => {
    if (submitPristine) {
      return props.onSubmit?.(data);
    }

    const dirtyValues = getOnlyDirtyFields(dirtyFields, data);

    return props.onSubmit?.(dirtyValues as TFormValues);
  };

  const onSubmitHandler = handleSubmit(onSuccessHandler);

  return (
    <BSForm {...props} onSubmit={onSubmitHandler}>
      {children}
    </BSForm>
  );
};

type UnknownObject = Record<string, unknown>;
type UnknownArrayOrObject = unknown[] | UnknownObject;

const getOnlyDirtyFields = (
  dirtyFields: UnknownArrayOrObject | boolean | unknown,
  allValues: UnknownArrayOrObject | unknown
): UnknownArrayOrObject | unknown => {
  if (dirtyFields === true || Array.isArray(dirtyFields)) {
    return allValues;
  }

  const dirtyFieldsObject = dirtyFields as UnknownObject;
  const allValuesObject = allValues as UnknownObject;

  return Object.fromEntries(
    Object.keys(dirtyFieldsObject).map((key) => [key, getOnlyDirtyFields(dirtyFieldsObject[key], allValuesObject[key])])
  );
};

import { BsFiles } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { SizesEnum } from '../../helpers';
import { FormatValueEnum, formatValue } from '../../helpers';
import { useClipBoard } from '../../hooks/clipboard/use-clipboard';
import IconButton from '../button/icon-button';
import HelpTooltip from '../help-tooltip';

type DetailElementProps = {
  className?: string;
  valor?: string | number;
  valorClipboard?: string;
  descricao: string;
  format?: FormatValueEnum;
  link?: string;
  map?: any;
  copiavel?: boolean;
  dica?: string | JSX.Element;
};

const DetailElement: React.FC<DetailElementProps> = ({
  className,
  descricao,
  valor,
  valorClipboard,
  format,
  link,
  map,
  copiavel,
  dica,
}) => {
  const possuiValor = valor !== null && valor !== undefined;
  const { isFeedbackVisible, copyToClipboard, clearClipboardFeedback } = useClipBoard(valorClipboard ?? valor);

  return (
    <div data-testid="detail-element" className={`d-flex align-items-center ${className || 'mb-2'}`}>
      <span className="d-flex align-items-center me-2">
        <strong>{descricao}</strong>
        {dica && <HelpTooltip dica={dica} />}
        <strong>:</strong>
      </span>

      <span>
        {possuiValor ? (
          link ? (
            <Link to={link}>{formatValue({ value: valor, format, map })}</Link>
          ) : (
            formatValue({ value: valor, format, map })
          )
        ) : (
          '-'
        )}
      </span>

      {copiavel && (
        <IconButton
          className="d-flex align-items-center"
          size={SizesEnum.SMALL}
          Icon={BsFiles}
          onClick={copyToClipboard}
          onMouseLeave={clearClipboardFeedback}
        />
      )}

      {isFeedbackVisible && (
        <strong>
          <em className="ml-2">Copiado!</em>
        </strong>
      )}
    </div>
  );
};

export default DetailElement;

import { reducerFactory, actionFactory, selectorFactory } from '../../store';

const context = 'itensDoacao';

const { createOne, deleteOne, loadMany, updateOne } = actionFactory(context, {
  buildUrlCreateOne: () => '/api/itens-doacao',
  buildUrlDeleteOne: ({ itemDoacaoId }) => `/api/itens-doacao/${itemDoacaoId}`,
  buildUrlLoadMany: () => '/api/itens-doacao',
  buildUrlUpdateOne: ({ itemDoacaoId }) => `/api/itens-doacao/${itemDoacaoId}`,
});

const { selectManyByFilters, selectOneById, selectTotalByFilters, selectLoadingStateByFilters } = selectorFactory({
  context,
});

export const saveItemDoacao = createOne;
export const deleteItemDoacao = deleteOne;
export const loadItensDoacao = loadMany;
export const updateItemDoacao = updateOne;

export const selectItemDoacaoById = selectOneById;
export const selectItensDoacaoByFilters = selectManyByFilters;
export const selectTotalItensDoacaoByFilters = selectTotalByFilters;
export const selectItensDoacaoLoadingStateByFilters = selectLoadingStateByFilters;

export const itensDoacao = reducerFactory({ context });

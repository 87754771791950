import { useCallback } from 'react';
import BSButton from 'react-bootstrap/Button';
import BSModal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';

import { Form } from '../../components/form/form';
import { FormControl, MasksFormControlEnum } from '../../components/form/form-control';
import { ClassTypesEnum } from '../../helpers';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch } from '../../store/hooks-redux';
import { savePrazoConfirmacaoAutorizacaoMCC } from './prazos-confirmacao-autorizacao-mcc.redux';

type CadastrarPrazoConfirmacaoMCCFormFields = {
  mcc: string;
  prazoEmDiasParaConfirmacao: string;
  nomeCategoriaNegocio: string;
};

type CadastrarPrazoConfirmacaoMCCProps = {
  closeDialog: () => void;
  reload: () => void;
};

const CadastrarPrazoConfirmacaoMCCDialog: React.FC<CadastrarPrazoConfirmacaoMCCProps> = ({ closeDialog, reload }) => {
  const dispatch = useAppDispatch();
  const { showToast } = useToasts();

  const form = useForm<CadastrarPrazoConfirmacaoMCCFormFields>();
  const { control } = form;

  const onSubmitHandler = useCallback(
    (data: CadastrarPrazoConfirmacaoMCCFormFields) => {
      dispatch(savePrazoConfirmacaoAutorizacaoMCC({ data }))
        .then(() => {
          closeDialog();
          showToast({
            message: 'Prazo confirmação autorização MCC cadastrada com sucesso',
            type: ClassTypesEnum.SUCCESS,
          });
          reload();
        })
        .catch((error: Error) => {
          showToast({ message: error.message, type: ClassTypesEnum.DANGER });
        });
    },
    [closeDialog, dispatch, reload, showToast]
  );

  return (
    <>
      <BSModal.Header closeButton>
        <BSModal.Title>Cadastrar novo prazo</BSModal.Title>
      </BSModal.Header>

      <BSModal.Body>
        <Form id="form" form={form} onSubmit={onSubmitHandler}>
          <FormControl
            control={control}
            className="mb-3"
            name="mcc"
            label="MCC"
            mask={MasksFormControlEnum.MCC}
            rules={{ required: true }}
          />

          <FormControl
            control={control}
            className="mb-3"
            name="prazoEmDiasParaConfirmacao"
            label="Prazo em dias para confirmação"
            rules={{ required: true }}
          />

          <FormControl
            control={control}
            className="mb-3"
            name="nomeCategoriaNegocio"
            label="Nome da categoria do negócio"
            rules={{ required: true }}
          />
        </Form>
      </BSModal.Body>

      <BSModal.Footer>
        <BSButton variant="light" onClick={closeDialog}>
          Fechar
        </BSButton>
        <BSButton variant="primary" type="submit" form="form">
          Cadastrar
        </BSButton>
      </BSModal.Footer>
    </>
  );
};

export default CadastrarPrazoConfirmacaoMCCDialog;

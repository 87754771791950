import { Route, Routes } from 'react-router-dom';

import DetalhesParametroGeracaoCartaoPage from './detalhes-parametro-geracao-cartao-page';
import ParametroGeracaoCartaoPage from './parametro-geracao-cartao-page';
const ParametrosGeracaoCartaoRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path=":parametroGeracaoCartaoId" element={<DetalhesParametroGeracaoCartaoPage />} />
      <Route index element={<ParametroGeracaoCartaoPage />} />
    </Routes>
  );
};

export default ParametrosGeracaoCartaoRoutes;

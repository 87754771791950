import { AcaoPermissaoPapelUsuarioEnum, TipoCreditoEnum } from '@tamborineapps/lib-enums';
import { useCallback, useEffect, useMemo } from 'react';
import BSButton from 'react-bootstrap/Button';
import BSTab from 'react-bootstrap/Tab';
import BSTabs from 'react-bootstrap/Tabs';
import { useParams } from 'react-router-dom';

import { DetailCard } from '../../components/details/datail-card';
import DetailElement from '../../components/details/detail-element';
import DetailSubTitle from '../../components/details/detail-subtitle';
import DetailTitle from '../../components/details/detail-title';
import { Loading } from '../../components/loading';
import Page from '../../components/page';
import RbacElement from '../../components/role-based-access-control/role-based-access-control-element';
import {
  ApiSingleElementResponse,
  FormatValueEnum,
  SizesEnum,
  formatCartao,
  formatDateTime,
  moedaLabelMap,
  naturezaTransacaoLabelMap,
} from '../../helpers';
import { useDialog } from '../../hooks/dialog/use-dialog';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import { loadCartao, selectCartaoById } from '../cartoes/cartoes.redux';
import { selectCategoriaBeneficioById } from '../categorias-beneficio/categorias.beneficio.redux';
import { loadContaCartao, selectContaCartaoById } from '../contas-cartao/contas-cartao.redux';
import { loadFatura, selectFaturaById } from '../faturas/faturas.redux';
import {
  loadLancamentosContabeisPorTransacao,
  selectLancamentosContabeisByFilters,
} from '../lancamentos-contabeis/lancamento-contabil.redux';
import { loadPedidoAutorizacao, selectPedidoAutorizacaoById } from '../pedidos-autorizacao/pedidos-autorizacao.redux';
import { loadProduto, selectProdutoById } from '../produtos/produtos.redux';
import { loadTipoTransacao, selectTiposTransacaoById } from '../tipos-transacao/tipos-transacao.redux';
import CancelarTransacaoDialog from './cancelar-transacao-dialog';
import DoacoesCartaoTransacaoTab from './doacoes-cartao-transacao-tab';
import TransacoesOriginadasTab from './transacoes-originadas-tab';
import { loadTransacao, selectTransacaoById, selectTransacoesLoadingStateByFilters } from './transacoes.redux';

const DetalhesTransacaoPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const params = useParams();
  const transacaoId = params.transacaoId as string;

  const { showDialog, closeDialog } = useDialog();

  const filters = useMemo(() => ({ transacaoId }), [transacaoId]);
  const transacao = useSelector((state) => selectTransacaoById(state, transacaoId));
  const loadingState = useSelector((state) => selectTransacoesLoadingStateByFilters(state, filters));

  const transacaoOrigem = useSelector((state) => selectTransacaoById(state, transacao?.transacaoOrigem));
  const contaCartao = useSelector((state) => selectContaCartaoById(state, transacao?.contaCartao));
  const cartao = useSelector((state) => selectCartaoById(state, transacao?.cartao));
  const fatura = useSelector((state) => selectFaturaById(state, transacao?.fatura));
  const pedidoAutorizacao = useSelector((state) => selectPedidoAutorizacaoById(state, transacao?.pedidoAutorizacao));
  const tipoTransacao = useSelector((state) => selectTiposTransacaoById(state, transacao?.tipoTransacao));
  const categoriaBeneficio = useSelector((state) => selectCategoriaBeneficioById(state, transacao?.categoriaBeneficio));
  const produto = useSelector((state) => selectProdutoById(state, contaCartao?.produto));

  const filterslancamentosContabeis = useMemo(() => ({ transacaoId }), [transacaoId]);
  const lancamentosContabeis = useSelector((state) =>
    selectLancamentosContabeisByFilters(state, filterslancamentosContabeis)
  );

  const _loadEntidadesRelacionadas = useCallback(
    (transacao: any) => {
      dispatch(loadContaCartao({ contaCartaoId: transacao.contaCartao })).catch((error: Error) => error);
      dispatch(loadTipoTransacao({ tipoTransacaoId: transacao.tipoTransacao })).catch((error: Error) => error);

      if (contaCartao?.produto) {
        dispatch(loadProduto({ produtoId: contaCartao?.produto })).catch((error: Error) => error);
      }

      if (transacao.transacaoOrigem) {
        dispatch(loadTransacao({ transacaoId: transacao.transacaoOrigem })).catch((error: Error) => error);
      }

      if (transacao.cartao) {
        dispatch(loadCartao({ cartaoId: transacao.cartao })).catch((error: Error) => error);
      }

      if (transacao.fatura) {
        dispatch(loadFatura({ faturaId: transacao.fatura })).catch((error: Error) => error);
      }

      if (transacao.pedidoAutorizacao) {
        dispatch(loadPedidoAutorizacao({ pedidoAutorizacaoId: transacao.pedidoAutorizacao })).catch(
          (error: Error) => error
        );
      }

      dispatch(loadLancamentosContabeisPorTransacao({ params: { transacaoId: transacao._id } })).catch(
        (error: Error) => error
      );
    },
    [contaCartao?.produto, dispatch]
  );

  const _loadTransacao = useCallback(
    () =>
      dispatch(loadTransacao({ transacaoId }))
        .then(({ payload: { data } }: ApiSingleElementResponse) => _loadEntidadesRelacionadas(data))
        .catch((error: Error) => error),
    [_loadEntidadesRelacionadas, dispatch, transacaoId]
  );

  const informacoesPagamento = lancamentosContabeis
    ?.filter(({ natureza }: { natureza: string }) => natureza === 'C')
    .reduce(
      (obj: any, lancamentoContabil: any) => ({
        dataUltimoLancamento:
          lancamentoContabil.dataLancamento < obj.dataLancamento
            ? obj.dataLancamento
            : lancamentoContabil.dataLancamento,
        valorTotalPago: lancamentoContabil.valor + obj.valorTotalPago,
      }),
      { valorTotalPago: 0, dataUltimoLancamento: '' }
    );

  const exibirCancelarTransacao = !(
    !transacao?.pendente ||
    transacao?.origemTransacao === 'clearing' ||
    transacao?.parcelaAtual >= 2 ||
    transacao?.cancelada
  );

  useEffect(() => {
    _loadTransacao();
  }, [_loadTransacao, transacaoId]);

  if (!transacao) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Loading notFoundMessage="Transação não encontrada" loadingState={loadingState} />
      </div>
    );
  }

  return (
    <Page>
      <div className="mb-5">
        <DetailCard>
          <div className="mb-5">
            <DetailTitle>
              Transação: {transacao.codigo && `(${transacao.codigo})`} {transacao.descricao} -{' '}
              {formatDateTime(transacao.dataReferencia)}
            </DetailTitle>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-6 mb-4">
              <DetailElement
                descricao="Tipo da transação"
                valor={
                  tipoTransacao
                    ? tipoTransacao.codigo
                      ? `(${tipoTransacao.codigo}) ${tipoTransacao.descricao}`
                      : tipoTransacao.descricao
                    : '-'
                }
                link={`/tipos-transacao/${transacao.tipoTransacao}`}
              />
              <DetailElement descricao="Valor juros" valor={transacao.valorJuros} format={FormatValueEnum.BRL} />
              <DetailElement
                descricao="Moeda origem"
                valor={transacao.moedaOrigem}
                format={FormatValueEnum.ENUM}
                map={moedaLabelMap}
              />
              <DetailElement descricao="MCC" valor={transacao.mcc} />
              {transacaoOrigem && (
                <>
                  <DetailElement
                    descricao="Transação de origem"
                    valor="Detalhes"
                    link={`/contas-cartao/${transacao.contaCartao}/transacoes/${transacao.transacaoOrigem}/`}
                  />
                  <DetailElement
                    descricao="Data da transação de origem"
                    valor={transacaoOrigem.dataHoraTransacao}
                    format={FormatValueEnum.DATE_TIME}
                  />
                </>
              )}
              <DetailElement descricao="Origem transação" valor={transacao.origemTransacao} />
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <DetailElement descricao="Valor" valor={transacao.valor} format={FormatValueEnum.BRL} />
              <DetailElement descricao="Valor total IOF" valor={transacao.valorTotalIof} format={FormatValueEnum.BRL} />
              <DetailElement
                descricao="Valor moeda origem"
                valor={transacao.valorMoedaOrigem}
                format={FormatValueEnum.USD}
              />
              <DetailElement descricao="Quantidade de parcelas" valor={transacao.quantidadeParcelas} />
              <DetailElement
                descricao="Natureza"
                valor={transacao.natureza}
                format={FormatValueEnum.ENUM}
                map={naturezaTransacaoLabelMap}
              />
              {produto?.tipoCredito === TipoCreditoEnum.MULTIBENEFICIO && (
                <DetailElement
                  descricao="Categoria benefício"
                  valor={
                    categoriaBeneficio
                      ? categoriaBeneficio.nome
                        ? `${categoriaBeneficio.nome}`
                        : categoriaBeneficio.nome
                      : '-'
                  }
                />
              )}
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <DetailElement
                descricao="Valor total (compra parcelada)"
                valor={transacao.valorTotalParcelado}
                format={FormatValueEnum.BRL}
              />
              <DetailElement descricao="Nome estabelecimento" valor={transacao.nomeEstabelecimento} />
              <DetailElement descricao="Localização estabelecimento" valor={transacao.localizacaoEstabelecimento} />
              <DetailElement descricao="Parcela atual" valor={transacao.parcelaAtual} />
              {pedidoAutorizacao && (
                <DetailElement
                  descricao="Pedido de autorização"
                  valor={pedidoAutorizacao.status}
                  link={`/pedidos-autorizacao/${transacao.pedidoAutorizacao}`}
                />
              )}
              <DetailElement
                descricao="Classificação contábil"
                valor={transacao.classificacaoContabil}
                format={FormatValueEnum.BOOL}
              />
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <div className="mb-3">
                <DetailSubTitle>Autorização</DetailSubTitle>
              </div>

              <DetailElement descricao="Código da autorização" valor={transacao.identificadorAutorizacao} />
              <DetailElement
                descricao="Data da autorização"
                valor={transacao.dataHoraTransacao}
                format={FormatValueEnum.DATE_TIME}
              />
              {transacao.dataConfirmacao ? (
                <DetailElement
                  descricao="Data da confirmação"
                  valor={transacao.dataConfirmacao}
                  format={FormatValueEnum.DATE_TIME}
                />
              ) : (
                <DetailElement descricao="Data da confirmação" valor="Aguardando confirmação" />
              )}
              <DetailElement descricao="Pendente" valor={transacao.pendente} format={FormatValueEnum.BOOL} />
              <DetailElement descricao="Cancelada" valor={transacao.cancelada} format={FormatValueEnum.BOOL} />
              <DetailElement descricao="Código país" valor={transacao.codigoPais} />
              <DetailElement descricao="Risco de Fraude da transação" valor={transacao.riscoFraudeTransacao} />
              <DetailElement descricao="Razão da Fraude " valor={transacao.razaoRiscoFraude} />
              <DetailElement descricao="Método de Entrada do PAN" valor={transacao.metodoEntradaPan} />
              <DetailElement descricao="Método de verificação do CVC" valor={transacao.metodoVerificacaoCVC} />
              <DetailElement descricao="Método de verificação do CVM" valor={transacao.metodoVerificacaoCVM} />
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <div className="mb-3">
                <DetailSubTitle>Dados da conta</DetailSubTitle>
              </div>

              <DetailElement
                descricao="Número da conta"
                valor={contaCartao?.numero}
                link={`/contas-cartao/${transacao.contaCartao}/`}
              />
              <DetailElement
                descricao="Atualização limite disponível pendente"
                valor={transacao.atualizacaoLimiteDisponivelPendente}
                format={FormatValueEnum.BOOL}
              />
              {cartao && (
                <DetailElement
                  descricao="Cartão"
                  valor={formatCartao(cartao.numeroTruncado)}
                  link={`/contas-cartao/${transacao.contaCartao}/cartoes/${transacao.cartao}`}
                />
              )}
              {fatura && (
                <DetailElement
                  descricao="Data de início da fatura"
                  valor={fatura.dataInicioPeriodo}
                  format={FormatValueEnum.DATA}
                />
              )}
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <div className="mb-3">
                <DetailSubTitle>Pagamento</DetailSubTitle>
              </div>

              <DetailElement
                descricao="Data do último pagamento"
                valor={informacoesPagamento?.dataUltimoLancamento}
                format={FormatValueEnum.DATA}
              />

              <DetailElement
                descricao="Valor total pago"
                valor={informacoesPagamento?.valorTotalPago || 0}
                format={FormatValueEnum.BRL}
              />
              <DetailElement
                descricao="Valor não quitado"
                format={FormatValueEnum.BRL}
                valor={
                  informacoesPagamento?.valorTotalPago ? transacao.valor - informacoesPagamento?.valorTotalPago : 0
                }
              />
            </div>
          </div>

          {exibirCancelarTransacao && (
            <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.CANCELAMENTO_TRANSACAO}>
              <div className=" d-flex justify-content-end">
                <div className="m-1">
                  <BSButton
                    onClick={() =>
                      showDialog({
                        component: (
                          <CancelarTransacaoDialog
                            closeDialog={closeDialog}
                            reload={_loadTransacao}
                            transacaoId={transacaoId}
                          />
                        ),
                        size: SizesEnum.EXTRA_LARGE,
                      })
                    }
                  >
                    Cancelar transação
                  </BSButton>
                </div>
              </div>
            </RbacElement>
          )}
        </DetailCard>
      </div>

      <DetailCard>
        <BSTabs defaultActiveKey="transacoesRelacionadas" fill unmountOnExit>
          <BSTab eventKey="transacoesRelacionadas" title="Transações relacionadas">
            <TransacoesOriginadasTab transacaoId={transacao._id} />
          </BSTab>
          <BSTab eventKey="doacoesCartao" title="Doações">
            <DoacoesCartaoTransacaoTab transacaoId={transacao._id} />
          </BSTab>
        </BSTabs>
      </DetailCard>
    </Page>
  );
};

export default DetalhesTransacaoPage;

import BSCard from 'react-bootstrap/Card';
import { IconType } from 'react-icons';
import { ClassTypesEnum } from '../../helpers';
import variables from '../../_export.scss';
import classes from './info-icon-card.module.scss';

type InfoIconCardProps = {
  Icon: IconType;
  title: string | number;
  subtitle?: string;
  variant?: ClassTypesEnum;
  className?: string;
};

export const InfoIconCard: React.FC<InfoIconCardProps> = ({ Icon, subtitle, title, variant, className }) => {
  const getIconColor = (variant?: ClassTypesEnum) => {
    switch (variant) {
      case ClassTypesEnum.PRIMARY:
        return variables.primary;

      case ClassTypesEnum.SECONDARY:
        return variables.secondary;

      case ClassTypesEnum.SUCCESS:
        return variables.success;

      case ClassTypesEnum.DANGER:
        return variables.danger;

      case ClassTypesEnum.WARNING:
        return variables.warning;

      default:
        return variables.info;
    }
  };

  return (
    <BSCard className={`${classes.card} ${className}`}>
      <BSCard.Body className="p-4">
        <div className="d-flex">
          <div className="d-flex align-items-center pe-3">
            <Icon size={40} color={getIconColor(variant)} />
          </div>

          <div className="d-flex flex-column align-items-center flex-grow-1">
            <h3>{title}</h3>

            {subtitle && <p className="mb-0 text-secondary text-center">{subtitle}</p>}
          </div>
        </div>
      </BSCard.Body>
    </BSCard>
  );
};

import { useCallback, useMemo } from 'react';
import BSCard from 'react-bootstrap/Card';
import { BsPencilSquare } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import IconButton from '../../components/button/icon-button';
import { SmartTable } from '../../components/smart-table/smart-table';
import SmartTableButton from '../../components/smart-table/smart-table-button';
import SmartTableFilters from '../../components/smart-table/smart-table-filters/smart-table-filters';
import {
  SmartTableFilterControlTypesEnum,
  SmartTableFilterProps,
} from '../../components/smart-table/smart-table-filters/table-filter.types';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import Title from '../../components/title';
import { ClassTypesEnum, FormatValueEnum, SizesEnum, utilizacaoLabelMap } from '../../helpers';
import { DialogProps } from '../../hooks/dialog/dialog-context';
import { useDialog } from '../../hooks/dialog/use-dialog';
import { useToasts } from '../../hooks/toast/use-toasts';

import { useQuerystring } from '../../hooks/router/use-querystring';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import CadastrarTipoBloqueioContaCartaoDialog from '../tipos-bloqueio-conta-cartao/cadastrar-tipo-bloqueio-conta-cartao-dialog';
import {
  loadTiposBloqueiosContaCartao,
  selectTiposBloqueioContaCartaoByFilters,
  selectTiposBloqueioContaCartaoLoadingStateByFilters,
  selectTotalTiposBloqueioContaCartaoByFilters,
  updateTipoBloqueioContaCartao,
} from './tipos-bloqueio-conta-cartao.redux';

import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import RbacElement from '../../components/role-based-access-control/role-based-access-control-element';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import AtualizarTipoBloqueioContaCartaoDialog from './atualizar-tipo-bloqueio-conta-cartao';

const smartFilters: SmartTableFilterProps[] = [
  {
    label: 'Código',
    attribute: 'codigo',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
  },
  {
    label: 'Tipo',
    attribute: 'tipo',
    controlType: SmartTableFilterControlTypesEnum.SELECT,
    map: utilizacaoLabelMap,
  },
  {
    label: 'Prioridade',
    attribute: 'prioridade',
    controlType: SmartTableFilterControlTypesEnum.NUMBER_INPUT,
  },
  {
    label: 'Bloqueio definitivo',
    attribute: 'bloqueioDefinitivo',
    controlType: SmartTableFilterControlTypesEnum.BOOL_RADIO,
  },
];

const smartColumns = ({
  showDialog,
  closeDialog,
  onUpdateTipoBloqueioContaCartaoHandler,
}: {
  showDialog: (dialogProps: DialogProps) => void;
  closeDialog: () => void;
  onUpdateTipoBloqueioContaCartaoHandler: (tipoBloqueioContaCartaoId: string, data: any) => void;
}): SmartTableColumnProps[] => [
  {
    label: 'Código',
    attribute: 'codigo',
    sortable: true,
    className: 'text-center',
  },
  {
    label: 'Descrição',
    attribute: 'descricao',
    className: 'text-center',
  },
  {
    label: 'Tipo',
    attribute: 'tipo',
    sortable: true,
    format: FormatValueEnum.ENUM,
    map: utilizacaoLabelMap,
    className: 'text-center',
  },
  {
    label: 'Prioridade',
    attribute: 'prioridade',
    sortable: true,
    className: 'text-right',
  },
  {
    label: 'Bloqueio definitivo',
    attribute: 'bloqueioDefinitivo',
    sortable: true,
    className: 'text-center',
    format: FormatValueEnum.BOOL,
  },
  {
    label: 'Ações',
    format: (_, tipoBloqueioContaCartao) => {
      return (
        <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.ALTERACAO_TIPO_BLOQUEIO_CONTA_CARTAO}>
          <div className="d-flex justify-content-center">
            <div className="mx-1">
              <IconButton
                Icon={BsPencilSquare}
                type={ClassTypesEnum.PRIMARY}
                onClick={() => {
                  showDialog({
                    component: (
                      <AtualizarTipoBloqueioContaCartaoDialog
                        closeDialog={closeDialog}
                        tipoBloqueioContaCartao={tipoBloqueioContaCartao}
                        onUpdateHandler={onUpdateTipoBloqueioContaCartaoHandler}
                      />
                    ),
                    size: SizesEnum.LARGE,
                  });
                }}
              />
            </div>
          </div>
        </RbacElement>
      );
    },
  },
];

const TiposBloqueioContaCartaoPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const { query } = useQuerystring();
  const navigate = useNavigate();

  const { closeDialog, showDialog } = useDialog();
  const { showToast } = useToasts();

  const filters = useMemo(() => ({ ...query }), [query]);
  const total = useSelector((state) => selectTotalTiposBloqueioContaCartaoByFilters(state, filters));
  const tiposBloqueioContaCartao = useSelector((state) => selectTiposBloqueioContaCartaoByFilters(state, filters));
  const loadingState = useSelector((state) => selectTiposBloqueioContaCartaoLoadingStateByFilters(state, filters));

  const onUpdateTipoBloqueioContaCartaoHandler = (tipoBloqueioContaCartaoId: string, data: any) => {
    if (!data) {
      return;
    }

    dispatch(updateTipoBloqueioContaCartao({ params: { tipoBloqueioContaCartaoId }, data }))
      .then(() => {
        closeDialog();

        showToast({
          message: 'Tipo de bloqueio de conta cartão atualizado com sucesso',
          type: ClassTypesEnum.SUCCESS,
        });

        return loadItems();
      })
      .catch((error: Error) => {
        showToast({
          message: error.message,
          type: ClassTypesEnum.DANGER,
        });
      });
  };

  const onNovoTipoBloqueioContaCartapHandler = () => {
    showDialog({
      component: <CadastrarTipoBloqueioContaCartaoDialog closeDialog={closeDialog} reload={loadItems} />,
      size: SizesEnum.LARGE,
    });
  };

  const loadItems = useCallback(
    () => dispatch(loadTiposBloqueiosContaCartao({ query: filters })).catch((error: Error) => error),
    [dispatch, filters]
  );

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_TIPO_BLOQUEIO_CONTA_CARTAO}>
      <div className="d-flex flex-column h-100">
        <Title>Tipos de bloqueio de conta cartão</Title>

        <div className="row">
          <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-12 mb-4">
            <SmartTableFilters filters={smartFilters} />
          </div>

          <div className="col-xxl-10 col-xl-9 col-lg-8 col-md-12 mb-4">
            <BSCard>
              <BSCard.Body>
                <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.CRIACAO_TIPO_BLOQUEIO_CONTA_CARTAO}>
                  <div className="d-flex align-items-center mb-2">
                    <SmartTableButton onClick={onNovoTipoBloqueioContaCartapHandler}>
                      Novo tipo de bloqueio de conta
                    </SmartTableButton>
                  </div>
                </RbacElement>
                <SmartTable
                  emptyMessage="Nenhum tipo de bloqueio de conta cartão encontrado"
                  errorMessage="Erro na listagem de tipos de bloqueio de conta cartão"
                  usePagination={true}
                  loadItems={loadItems}
                  columns={smartColumns({
                    closeDialog,
                    showDialog,
                    onUpdateTipoBloqueioContaCartaoHandler,
                  })}
                  items={tiposBloqueioContaCartao}
                  loadingState={loadingState}
                  size={total}
                  onRowClick={(doc) => navigate(doc._id)}
                />
              </BSCard.Body>
            </BSCard>
          </div>
        </div>
      </div>
    </RbacPage>
  );
};

export default TiposBloqueioContaCartaoPage;

export enum ClassTypesEnum {
  PRIMARY = 'primary',
  INFO = 'info',
  SECONDARY = 'secondary',
  DANGER = 'danger',
  DARK = 'dark',
  SUCCESS = 'success',
  WARNING = 'warning',
  LIGHT = 'light',
  MUTED = 'muted',
}

export enum SizesEnum {
  EXTRA_SMALL = 'xs',
  SMALL = 'sm',
  MEDIUM = 'md',
  LARGE = 'lg',
  EXTRA_LARGE = 'xl',
}

export enum HttpStatusCode {
  OK = 200,
  CREATED = 201,
  ACCEPTED = 202,
  NO_CONTENT = 204,
  NOT_FOUND = 404,
  UNAUTHORIZED = 401,
  INTERNAL_SERVER_ERROR = 500,
  BAD_REQUEST = 400,
  FORBIDDEN = 403,
}

export enum AfirmacaoEnum {
  S = 'Sim',
  N = 'Não',
}

import { useEffect } from 'react';
import BSButton from 'react-bootstrap/Button';
import { BsEmojiNeutral } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import variables from '../_export.scss';
import Page from '../components/page';
import { useAppDispatch } from '../store/hooks-redux';
import { loadSessionUsuario } from './session-usuario/session-usuario.redux';

const NotFoundPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(loadSessionUsuario({})).catch((error: Error) => error);
  }, [dispatch]);

  return (
    <Page>
      <div className="d-flex flex-column justify-content-center align-items-center h-100">
        <h1 className="mb-4">Oops!</h1>

        <div className="mb-4">
          <BsEmojiNeutral size={100} color={variables.warning} />
        </div>

        <h2 className="mb-4">404 - Not Found</h2>

        <h4 className="mb-4">Página não encontrada.</h4>

        <BSButton onClick={() => navigate('/')}>Ir para página inicial</BSButton>
      </div>
    </Page>
  );
};

export default NotFoundPage;

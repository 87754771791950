import BSButton from 'react-bootstrap/Button';
import BSModal from 'react-bootstrap/Modal';

import { useForm } from 'react-hook-form';
import { Form } from '../../components/form/form';
import { FormControl } from '../../components/form/form-control';
import { ClassTypesEnum } from '../../helpers';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch } from '../../store/hooks-redux';
import { updateLog } from './simulador-juros-rotativo.redux';

type InformarErroCalculoFormFields = {
  feedback: string;
};

type InformarErroCalculoDialogProps = {
  logSimuladorId: string;
  closeDialog: () => void;
};

const InformarErroCalculoDialog: React.FC<InformarErroCalculoDialogProps> = ({ logSimuladorId, closeDialog }) => {
  const dispatch = useAppDispatch();
  const { showToast } = useToasts();

  const form = useForm<InformarErroCalculoFormFields>();
  const { control } = form;

  const onSubmitHandler = (data: any) => {
    if (!data) {
      return;
    }

    dispatch(updateLog({ params: { logSimuladorId }, data }))
      .then(() => {
        closeDialog();

        showToast({
          message: 'Erro informado com sucesso.',
          type: ClassTypesEnum.SUCCESS,
        });

        return;
      })
      .catch((error: Error) => {
        showToast({
          message: error.message,
          type: ClassTypesEnum.DANGER,
        });
      });
  };

  return (
    <>
      <BSModal.Header closeButton>
        <BSModal.Title>Informar erro de cálculo</BSModal.Title>
      </BSModal.Header>

      <BSModal.Body>
        <Form id="form" form={form} onSubmit={onSubmitHandler}>
          <FormControl
            control={control}
            className="mb-3"
            label="Descrição"
            as="textarea"
            rows={7}
            name="feedback"
            rules={{ required: true }}
          />
        </Form>
      </BSModal.Body>

      <BSModal.Footer>
        <BSButton variant="light" onClick={closeDialog}>
          Fechar
        </BSButton>
        <BSButton variant="primary" type="submit" form="form">
          Enviar
        </BSButton>
      </BSModal.Footer>
    </>
  );
};

export default InformarErroCalculoDialog;

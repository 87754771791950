import { IconType } from 'react-icons';
import { ClassTypesEnum, SizesEnum } from '../../helpers';
import { forwardRef } from 'react';

type IconButtonProps = {
  className?: string;
  onClick?: () => void;
  onMouseLeave?: () => void;
  Icon: IconType;
  type?: ClassTypesEnum;
  size?: SizesEnum;
  disabled?: boolean;
  preventClickEventDefaults?: boolean;
};

const IconButton = forwardRef<HTMLDivElement, IconButtonProps>(
  (
    {
      className,
      onClick,
      onMouseLeave: onMouseLeaveHandler,
      Icon,
      type,
      size,
      disabled,
      preventClickEventDefaults = true,
    },
    ref
  ) => {
    const getIconSize = (size?: SizesEnum) => {
      switch (size) {
        case SizesEnum.SMALL:
          return 17;
        case SizesEnum.MEDIUM:
          return 20;
        case SizesEnum.LARGE:
          return 30;
        default:
          return 20;
      }
    };

    const onClickHandler = (event: React.MouseEvent<HTMLDivElement>) => {
      if (preventClickEventDefaults) {
        event.stopPropagation();
        event.preventDefault();
      }

      !disabled && onClick?.();
    };

    return (
      <div
        ref={ref}
        className={`px-1 ${className} ${disabled ? `text-${ClassTypesEnum.MUTED}` : type ? `text-${type}` : ''}`}
        onMouseLeave={onMouseLeaveHandler}
        onClick={onClickHandler}
      >
        <Icon data-testid="icon-button" size={getIconSize(size)} color={type} role={onClick && 'button'} />
      </div>
    );
  }
);

export default IconButton;

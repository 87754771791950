import { useCallback, useEffect } from 'react';
import BSButton from 'react-bootstrap/Button';
import BSModal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';

import { Form } from '../../components/form/form';
import { FormSelect } from '../../components/form/form-select';
import { ClassTypesEnum } from '../../helpers';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import { loadProdutos, selectProdutosByFilters } from '../produtos/produtos.redux';
import { createPerguntasFrequentes } from './perguntas-frequentes.redux';

type CadastrarPerguntaFrequenteFormFields = {
  produtoId: string;
};

type CadastrarPerguntaFrequenteDialogProps = {
  closeDialog: () => void;
  reload: () => void;
};

const CadastrarPerguntaFrequenteDialog: React.FC<CadastrarPerguntaFrequenteDialogProps> = ({ closeDialog, reload }) => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const { maxItemsQuery } = useQuerystring();

  const { showToast } = useToasts();

  const form = useForm<CadastrarPerguntaFrequenteFormFields>();
  const { control } = form;

  const produtos = useSelector((state) => selectProdutosByFilters(state, maxItemsQuery));

  const onSubmitHandler = useCallback(
    (data: CadastrarPerguntaFrequenteFormFields) => {
      dispatch(createPerguntasFrequentes({ data }))
        .then(() => {
          closeDialog();
          showToast({
            message: 'Pergunta frequente cadastrada para o produto com sucesso',
            type: ClassTypesEnum.SUCCESS,
          });
          reload();
        })
        .catch((error: Error) => {
          showToast({ message: error.message, type: ClassTypesEnum.DANGER });
        });
    },
    [closeDialog, dispatch, reload, showToast]
  );

  useEffect(() => {
    dispatch(loadProdutos({ query: maxItemsQuery })).catch((error: Error) => error);
  }, [dispatch, maxItemsQuery]);

  return (
    <>
      <BSModal.Header closeButton>
        <BSModal.Title>Nova pergunta frequente</BSModal.Title>
      </BSModal.Header>

      <BSModal.Body>
        <Form id="form" form={form} onSubmit={onSubmitHandler}>
          <FormSelect
            control={control}
            className="mb-3"
            name="produtoId"
            label="Produto"
            placeholder="Selecione o produto"
            options={produtos?.map((elem: any) => ({
              key: elem._id,
              value: elem._id,
              label: `${elem.codigo} - ${elem.nome}`,
            }))}
            rules={{ required: true }}
          />
        </Form>
      </BSModal.Body>

      <BSModal.Footer>
        <BSButton variant="light" onClick={closeDialog}>
          Fechar
        </BSButton>
        <BSButton variant="primary" type="submit" form="form">
          Cadastrar
        </BSButton>
      </BSModal.Footer>
    </>
  );
};

export default CadastrarPerguntaFrequenteDialog;

import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { DetailCard } from '../../components/details/datail-card';
import DetailSubTitle from '../../components/details/detail-subtitle';
import DetailTitle from '../../components/details/detail-title';
import { Loading } from '../../components/loading';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import {
  loadPapelUsuario,
  selectPapeisUsuariosLoadingStateByFilters,
  selectPapelUsuarioById,
} from './papeis-usuarios.redux';
import PermissoesPapelUsuarioTabs from './permissoes-papel-usuario-tabs';

const DetalhesPapelUsuarioPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const params = useParams();
  const papelUsuarioId = params.papelUsuarioId as string;

  const filters = useMemo(() => ({ papelUsuarioId }), [papelUsuarioId]);
  const papelUsuario = useSelector((state) => selectPapelUsuarioById(state, papelUsuarioId));
  const loadingState = useSelector((state) => selectPapeisUsuariosLoadingStateByFilters(state, filters));

  const _loadPapelUsuario = useCallback(
    () => dispatch(loadPapelUsuario({ papelUsuarioId })).catch((error: Error) => error),
    [papelUsuarioId, dispatch]
  );

  useEffect(() => {
    _loadPapelUsuario();
  }, [_loadPapelUsuario]);

  if (!papelUsuario) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Loading notFoundMessage="Papel de usuário não encontrado" loadingState={loadingState} />
      </div>
    );
  }

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_PAPEL_USUARIO}>
      <div className="mb-5">
        <DetailCard>
          <div className="mb-5">
            <DetailTitle>Papel de usuário - {papelUsuario.descricao}</DetailTitle>
          </div>

          <div className="mb-3">
            <DetailSubTitle>Permissões</DetailSubTitle>
          </div>

          <PermissoesPapelUsuarioTabs permissoes={papelUsuario.permissoes} readOnly={true} />
        </DetailCard>
      </div>
    </RbacPage>
  );
};

export default DetalhesPapelUsuarioPage;

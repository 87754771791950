import { useEffect, useState } from 'react';
import BSButton from 'react-bootstrap/Button';
import BSForm from 'react-bootstrap/Form';
import BSInputGroup from 'react-bootstrap/InputGroup';
import { BsX } from 'react-icons/bs';
import { useSearchParams } from 'react-router-dom';
import { SmartTableFilterComponentProps } from './table-filter.types';

const SearchDatepickerFilter: React.FC<SmartTableFilterComponentProps> = ({
  filter,
  upsertAttributeInSearchParams,
  removeAttributeFromSearchParams,
}) => {
  const [searchParams] = useSearchParams();
  const [formControlValue, setFormControlValue] = useState<string>('');
  const { attribute, label, className } = filter;

  const searchAttribute = `${attribute}[searchDate]`;

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const { value } = event.target as HTMLInputElement;

    event.preventDefault();

    // TOOD: Validation: fullDateRFC3339Regex

    setFormControlValue(value);

    return value
      ? upsertAttributeInSearchParams(searchAttribute, value)
      : removeAttributeFromSearchParams(searchAttribute);
  };

  const clearFilterHandler = (): void => {
    setFormControlValue('');

    return removeAttributeFromSearchParams(searchAttribute);
  };

  useEffect(() => {
    const value = searchParams.get(searchAttribute);
    setFormControlValue(value ?? '');
  }, [searchAttribute, searchParams]);

  return (
    <BSForm.Group data-testid="search-datepicker-filter" className={className ?? 'mb-3'}>
      <BSForm.Label>{label}</BSForm.Label>
      <BSInputGroup data-testid="search-input">
        <BSForm.Control type="date" value={formControlValue} onChange={onChangeHandler} />
        {searchParams.get(searchAttribute) && (
          <BSButton variant="outline-primary" onClick={clearFilterHandler}>
            <BsX size={20} />
          </BSButton>
        )}
      </BSInputGroup>
    </BSForm.Group>
  );
};

export default SearchDatepickerFilter;

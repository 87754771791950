import { reducerFactory, actionFactory, selectorFactory } from '../../store';

const context = 'enderecos';

const { loadMany } = actionFactory(context, {
  buildUrlLoadMany: ({ clienteId }) => `/api/clientes/${clienteId}/enderecos`,
});

const { selectManyByFilters, selectTotalByFilters, selectLoadingStateByFilters } = selectorFactory({ context });

export const loadEnderecos = loadMany;

export const selectEnderecosByFilters = selectManyByFilters;
export const selectTotalEnderecosByFilters = selectTotalByFilters;
export const selectEnderecosLoadingStateByFilters = selectLoadingStateByFilters;

export const enderecos = reducerFactory({ context });

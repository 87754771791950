import { Route, Routes } from 'react-router-dom';
import PrazosConfirmacaoAutorizacaoMCCPage from './prazos-confirmacao-autorizacao-mcc-page';

const PrazosConfirmacaoAutorizacaoMCCRoutes: React.FC = () => {
  return (
    <Routes>
      <Route index element={<PrazosConfirmacaoAutorizacaoMCCPage />} />
    </Routes>
  );
};

export default PrazosConfirmacaoAutorizacaoMCCRoutes;

import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import { useSearchParams } from 'react-router-dom';
import { BsX } from 'react-icons/bs';
import React, { useEffect, useState } from 'react';
import { SmartTableFilterComponentProps } from './table-filter.types';

const SearchSelectFilter: React.FC<SmartTableFilterComponentProps> = ({
  filter,
  removeAttributeFromSearchParams,
  upsertAttributeInSearchParams,
}) => {
  const [searchParams] = useSearchParams();
  const [formControlValue, setFormControlValue] = useState<string>('');
  const { attribute, label, className, map } = filter;

  const searchAttribute = `${attribute}[select]`;

  const onChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const { value } = event.target as HTMLSelectElement;

    setFormControlValue(value);

    return value
      ? upsertAttributeInSearchParams(searchAttribute, value)
      : removeAttributeFromSearchParams(searchAttribute);
  };

  const clearFilterHandler = () => {
    setFormControlValue('');

    return removeAttributeFromSearchParams(searchAttribute);
  };

  useEffect(() => {
    const value = searchParams.get(searchAttribute);
    setFormControlValue(value ?? '');
  }, [searchAttribute, searchParams]);

  return (
    <Form.Group data-testid="search-select-filter" className={className ?? 'mb-3'}>
      <Form.Label>{label}</Form.Label>
      <InputGroup>
        <Form.Select onChange={onChangeHandler} aria-label={`${searchAttribute}-select`} value={formControlValue}>
          <option />
          {getSelectValues(map ?? {}).map(([key, value], index) => {
            const id = key;
            const description = value;

            return <option key={index} value={id}>{`${description}`}</option>;
          })}
        </Form.Select>
        {searchParams.get(searchAttribute) && (
          <Button variant="outline-primary" onClick={clearFilterHandler}>
            <BsX size={20} />
          </Button>
        )}
      </InputGroup>
    </Form.Group>
  );
};

function getSelectValues(values: any) {
  if (!Array.isArray(values)) {
    return Object.entries(values);
  }

  return values.map((v) => [v, v]);
}

export default SearchSelectFilter;

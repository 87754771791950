import { actionFactory, reducerFactory, selectorFactory } from '../../store';

const context = 'parametrosGeracaoCartao';

const { createOne, loadMany, loadOne } = actionFactory(context, {
  buildUrlCreateOne: () => '/api/parametros-geracao-cartao',
  buildUrlLoadOne: ({ parametroGeracaoCartaoId }) => `/api/parametros-geracao-cartao/${parametroGeracaoCartaoId}`,
  buildUrlLoadMany: () => '/api/parametros-geracao-cartao',
  buildUrlUpdateOne: ({ parametroGeracaoCartaoId }) => `/api/parametros-geracao-cartao/${parametroGeracaoCartaoId}`,
});

const {
  selectOneById,
  selectManyByFilters,
  selectTotalByFilters,
  selectAll,
  selectLoadingStateByFilters,
  selectObjectAllIds,
} = selectorFactory({ context });

export const createParametroGeracaocartao = createOne;
export const loadParametrosGeracaoCartao = loadMany;
export const loadParametroGeracaoCartao = loadOne;

export const selectParametroGeracaoCartaoPorID = selectOneById;
export const selectTodosParametrosGeracaoCartao = selectAll;
export const selectParametrosGeracaoCartaoPorFiltro = selectManyByFilters;
export const selectTotalParametrosGeracaoCartaoPorFiltro = selectTotalByFilters;
export const selectParametrosGeracaoCartaoLoadingStateByFilters = selectLoadingStateByFilters;
export const selectObjectTodosParametrosGeracaoCartao = selectObjectAllIds;

export const parametrosGeracaoCartao = reducerFactory({ context });

import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { DetailCard } from '../../components/details/datail-card';
import DetailElement from '../../components/details/detail-element';
import DetailTitle from '../../components/details/detail-title';
import { Loading } from '../../components/loading';
import Page from '../../components/page';
import { FormatValueEnum, bandeiraLabelMap, designCartaoLabelMap } from '../../helpers';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import {
  loadParametroGeracaoCartao,
  selectParametroGeracaoCartaoPorID,
  selectParametrosGeracaoCartaoLoadingStateByFilters,
} from './parametros-geracao-cartao.redux';

const DetalhesParametroGeracaoCartaoPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const useSelector = useAppSelector;
  const parametroGeracaoCartaoId = params.parametroGeracaoCartaoId as string;
  const filters = useMemo(() => ({ parametroGeracaoCartaoId }), [parametroGeracaoCartaoId]);
  const parametroGeracaoCartao = useSelector((state) =>
    selectParametroGeracaoCartaoPorID(state, parametroGeracaoCartaoId)
  );
  const loadingState = useSelector((state) => selectParametrosGeracaoCartaoLoadingStateByFilters(state, filters));

  const _loadParametroGeracaoCartao = useCallback(
    () => dispatch(loadParametroGeracaoCartao({ parametroGeracaoCartaoId })).catch((error: Error) => error),
    [dispatch, parametroGeracaoCartaoId]
  );

  useEffect(() => {
    _loadParametroGeracaoCartao();
  }, [_loadParametroGeracaoCartao]);

  if (!parametroGeracaoCartao) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Loading notFoundMessage="Parâmetro Geração Cartão não encontrado" loadingState={loadingState} />
      </div>
    );
  }
  return (
    <Page>
      <div className="mb-5">
        <DetailCard>
          <div className="mb-5">
            <DetailTitle>Parâmetro de geração de cartão ({parametroGeracaoCartao.codigo})</DetailTitle>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-6 mb-4">
              <DetailElement
                descricao="Bandeira"
                valor={parametroGeracaoCartao.bandeira}
                format={FormatValueEnum.ENUM}
                map={bandeiraLabelMap}
              />
              <DetailElement descricao="Bin" valor={parametroGeracaoCartao.bin} />
              <DetailElement
                descricao="Design do cartão"
                valor={parametroGeracaoCartao.designCartao}
                format={FormatValueEnum.ENUM}
                map={designCartaoLabelMap}
              />
              <DetailElement descricao="Modelo do plástico" valor={parametroGeracaoCartao.modeloPlastico} />
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <DetailElement
                descricao="Prazo de validade do cartão em anos"
                valor={parametroGeracaoCartao.prazoValidadeCartaoEmAnos}
              />
              <DetailElement
                descricao="Tamanho do número do cartão"
                valor={parametroGeracaoCartao.tamanhoNumeroCartao}
              />
              <DetailElement
                descricao="Validade Certificado e Segurança"
                valor={parametroGeracaoCartao.validadeCertificadoSeguranca}
                format={FormatValueEnum.DATA}
              />
              <DetailElement descricao="Produto bandeira" valor={parametroGeracaoCartao.produtoBandeira} />
            </div>
          </div>
        </DetailCard>
      </div>
    </Page>
  );
};
export default DetalhesParametroGeracaoCartaoPage;

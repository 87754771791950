import BSButton from 'react-bootstrap/Button';
import BSModal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';

import { Form } from '../../components/form/form';
import { FormSelect } from '../../components/form/form-select';
import { ClassTypesEnum } from '../../helpers';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import { selectProdutoById } from '../produtos/produtos.redux';
import { atualizarDiaVencimento, selectContaCartaoById } from './contas-cartao.redux';

type AtualizarDiaVencimentoFields = {
  diaVencimento: string;
};

type AtualizarDiaVencimentoDialogProps = {
  contaCartaoId: any;
  closeDialog: () => void;
};

const AtualizarDiaVencimentoDialog: React.FC<AtualizarDiaVencimentoDialogProps> = ({ contaCartaoId, closeDialog }) => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const { showToast } = useToasts();

  const contaCartao = useSelector((state) => selectContaCartaoById(state, contaCartaoId));
  const produto = useSelector((state) => selectProdutoById(state, contaCartao.produto));

  const form = useForm<AtualizarDiaVencimentoFields>({ defaultValues: { diaVencimento: contaCartao.diaVencimento } });
  const { control } = form;

  const onSubmitAtualizarDiaVencimento = (data: AtualizarDiaVencimentoFields) => {
    return dispatch(atualizarDiaVencimento({ data, params: { contaCartaoId } }))
      .then(() => {
        showToast({
          message: 'Data de vencimento atualizada com sucesso!',
          type: ClassTypesEnum.SUCCESS,
        });

        return closeDialog();
      })
      .catch((error: Error) => {
        showToast({
          message: error.message,
          type: ClassTypesEnum.DANGER,
        });
      });
  };

  function sortVencimentosDisponiveis(vencimentosDisponiveis: any): any[] {
    if (!vencimentosDisponiveis) {
      return [];
    }

    return [...vencimentosDisponiveis].sort();
  }

  return (
    <>
      <BSModal.Header closeButton>
        <BSModal.Title>Atualizar data de vencimento</BSModal.Title>
      </BSModal.Header>

      <BSModal.Body>
        <Form id="form" form={form} onSubmit={onSubmitAtualizarDiaVencimento} submitPristine>
          <FormSelect
            control={control}
            className="mb-3"
            name="diaVencimento"
            label="Vencimentos disponíveis"
            placeholder="Escolher..."
            options={sortVencimentosDisponiveis(produto?.vencimentosDisponiveis)?.map((dia: string) => ({
              key: dia,
              value: dia,
              label: dia,
            }))}
            rules={{
              required: true,
            }}
          />
        </Form>
      </BSModal.Body>

      <BSModal.Footer>
        <BSButton variant="light" onClick={closeDialog}>
          Fechar
        </BSButton>
        <BSButton variant="primary" type="submit" form="form">
          Atualizar
        </BSButton>
      </BSModal.Footer>
    </>
  );
};

export default AtualizarDiaVencimentoDialog;

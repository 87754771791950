import { useCallback, useMemo, useState } from 'react';
import BSBadge from 'react-bootstrap/Badge';
import BSButton from 'react-bootstrap/Button';
import BSForm from 'react-bootstrap/Form';
import { Link, useNavigate } from 'react-router-dom';

import { AcaoPermissaoPapelUsuarioEnum, TipoCreditoEnum } from '@tamborineapps/lib-enums';
import RbacElement from '../../components/role-based-access-control/role-based-access-control-element';
import { SmartTable } from '../../components/smart-table/smart-table';
import SmartTableFilters from '../../components/smart-table/smart-table-filters/smart-table-filters';
import {
  SmartTableFilterControlTypesEnum,
  SmartTableFilterProps,
} from '../../components/smart-table/smart-table-filters/table-filter.types';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import {
  ApiMultiElementResponse,
  FormatValueEnum,
  SizesEnum,
  formatCartao,
  mapearERemoverElementosNulosERepetidos,
  naturezaTransacaoColorMap,
  naturezaTransacaoLabelMap,
} from '../../helpers';
import { useDialog } from '../../hooks/dialog/use-dialog';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import { loadCartoes, selectObjectTodosCartoes } from '../cartoes/cartoes.redux';
import {
  loadCategoriasBeneficios,
  selectCategoriaBeneficiosByFilters,
} from '../categorias-beneficio/categorias.beneficio.redux';
import { loadProduto, selectProdutoById } from '../produtos/produtos.redux';
import { loadTiposTransacao, selectObjectTodosTiposTransacao } from '../tipos-transacao/tipos-transacao.redux';
import {
  loadTransacoesContaCartao,
  selectTotalTransacoesByFilters,
  selectTransacoesByFilters,
  selectTransacoesLoadingStateByFilters,
} from '../transacoes/transacoes.redux';
import CadastrarSolicitacaoClienteDialog from './cadastrar-solicitacao-cliente-dialog';
import { selectContaCartaoById } from './contas-cartao.redux';

const smartColumns = (
  { cartoes }: { cartoes: any },
  { tiposTransacao }: { tiposTransacao: any },
  { categoriasBeneficiosMap }: { categoriasBeneficiosMap: any },
  produto: any,
  transacoesSelecionadas: any,
  setTransacoesSelecionadas: any
): SmartTableColumnProps[] => [
  {
    label: '',
    selectable: true,
    className: 'text-center align-middle',
    format: (_, doc) => {
      const checked = transacoesSelecionadas.map(({ _id }: any) => _id).includes(doc._id);

      return (
        <BSForm.Check
          className="form-checkbox"
          role="button"
          type="checkbox"
          onClick={(e: React.MouseEvent) => e.stopPropagation()}
          onChange={(event) =>
            event.target.checked
              ? setTransacoesSelecionadas((prev: any) => [...prev, doc])
              : setTransacoesSelecionadas((prev: any) => prev.filter(({ _id }: any) => _id !== doc._id))
          }
          checked={checked}
        />
      );
    },
  },
  {
    label: 'Data referência',
    attribute: 'dataReferencia',
    sortable: true,
    className: 'text-center',
    format: FormatValueEnum.DATE_TIME,
  },
  {
    label: 'Descrição',
    attribute: 'descricao',
    className: 'text-center',
  },
  {
    label: 'Estabelecimento',
    attribute: 'nomeEstabelecimento',
    className: 'text-center',
  },
  {
    label: 'Cartão',
    className: 'text-center',
    format: (_, doc) => {
      const cartao = cartoes[doc?.cartao];

      if (!cartao) {
        return '-';
      }

      return (
        <Link
          onClick={(e) => e.stopPropagation()}
          to={`/contas-cartao/${doc.contaCartao.toString()}/cartoes/${doc.cartao.toString()}`}
        >
          {formatCartao(cartao?.numeroTruncado)}
        </Link>
      );
    },
  },
  {
    label: 'Tipo de transação',
    attribute: 'tipoTransacao',
    className: 'text-center',
    format(tipoTransacao: keyof typeof tiposTransacao) {
      if (!tipoTransacao) {
        return '-';
      }

      return (
        <Link onClick={(e) => e.stopPropagation()} to={`/tipos-transacao/${tipoTransacao.toString()}`}>
          {tiposTransacao[tipoTransacao]?.descricao}
        </Link>
      );
    },
  },
  ...(produto?.tipoCredito === TipoCreditoEnum.MULTIBENEFICIO
    ? [
        {
          label: 'Benefício',
          attribute: 'categoriaBeneficio',
          className: 'text-center',

          format(categoriaBeneficio: keyof typeof categoriasBeneficiosMap) {
            if (!categoriaBeneficio) {
              return '-';
            }
            return <>{categoriasBeneficiosMap[categoriaBeneficio]}</>;
          },
        },
      ]
    : []),

  {
    label: 'Pendente',
    attribute: 'pendente',
    sortable: true,
    className: 'text-center',
    format: FormatValueEnum.BOOL,
  },
  {
    label: 'Cancelada',
    attribute: 'cancelada',
    sortable: true,
    className: 'text-center',
    format: FormatValueEnum.BOOL,
  },

  {
    label: 'Natureza',
    attribute: 'natureza',
    sortable: true,
    className: 'text-center',
    format(natureza: keyof typeof naturezaTransacaoLabelMap) {
      return (
        <h5 className="m-0">
          <BSBadge pill bg={naturezaTransacaoColorMap[natureza as keyof typeof naturezaTransacaoColorMap]}>
            {naturezaTransacaoLabelMap[natureza]}
          </BSBadge>
        </h5>
      );
    },
  },
  {
    label: 'Valor (R$)',
    attribute: 'valor',
    sortable: true,
    className: 'text-end',
    format: FormatValueEnum.BRL,
  },
];

const smartFilters = (
  produto: any,
  { categoriasBeneficiosMap }: { categoriasBeneficiosMap: any[] }
): SmartTableFilterProps[] => [
  {
    attribute: 'cartao',
    label: 'Final do cartão',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
    minLength: 3,
  },
  {
    attribute: 'status',
    label: 'Status',
    controlType: SmartTableFilterControlTypesEnum.SELECT,
    map: ['Cancelada', 'Confirmada', 'Futura', 'Pendente'],
  },
  {
    attribute: 'natureza',
    label: 'Natureza',
    controlType: SmartTableFilterControlTypesEnum.SELECT,
    map: naturezaTransacaoLabelMap,
  },
  {
    attribute: 'dataReferencia',
    label: 'Data de referência',
    controlType: SmartTableFilterControlTypesEnum.DATE_INPUT,
  },
  ...(produto?.tipoCredito === TipoCreditoEnum.MULTIBENEFICIO
    ? [
        {
          attribute: 'categoriaBeneficio',
          label: 'Categoria benefício',
          controlType: SmartTableFilterControlTypesEnum.SELECT,
          map: categoriasBeneficiosMap,
        },
      ]
    : []),
];
type TransacoesContaCartaoTabProps = {
  contaCartaoId: string;
  setTransacoesSelecionadasParaSolicitacao: (listaTransacoes: any[]) => void;
};

const TransacoesContaCartaoTab: React.FC<TransacoesContaCartaoTabProps> = ({
  contaCartaoId,
  setTransacoesSelecionadasParaSolicitacao,
}) => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const { showDialog, closeDialog } = useDialog();
  const navigate = useNavigate();
  const { query, maxItemsQuery } = useQuerystring();
  const [transacoesSelecionadas, setTransacoesSelecionadas] = useState<any[]>([]);

  setTransacoesSelecionadasParaSolicitacao(transacoesSelecionadas);

  if (!query.sort) {
    query.sort = '-dataReferencia';
  }

  const filters = useMemo(() => ({ ...query, contaCartaoId }), [contaCartaoId, query]);
  const total = useSelector((state) => selectTotalTransacoesByFilters(state, filters));
  const transacoes = useSelector((state) => selectTransacoesByFilters(state, filters));
  const loadingState = useSelector((state) => selectTransacoesLoadingStateByFilters(state, filters));
  const contaCartao = useSelector((state) => selectContaCartaoById(state, contaCartaoId));
  const tiposTransacao = useSelector((state) => selectObjectTodosTiposTransacao(state));
  const produto = useSelector((state) => selectProdutoById(state, contaCartao?.produto));
  const cartoes = useSelector((state) => selectObjectTodosCartoes(state));
  const categoriasBeneficios = useSelector((state) => selectCategoriaBeneficiosByFilters(state, maxItemsQuery));

  const categoriasBeneficiosMap = (categoriasBeneficios || []).reduce(
    (obj: any, categoriaBeneficio: any) => ({
      ...obj,
      [categoriaBeneficio._id]: categoriaBeneficio.nome,
    }),
    {}
  );

  const loadInformacoesComplementares = useCallback(
    (transacoes: any[]) => {
      const tiposTransacao = mapearERemoverElementosNulosERepetidos(transacoes, 'tipoTransacao');
      const cartoes = mapearERemoverElementosNulosERepetidos(transacoes, 'cartao');

      dispatch(loadCategoriasBeneficios({ query: maxItemsQuery })).catch((error: Error) => error);
      dispatch(loadProduto({ produtoId: contaCartao.produto })).catch((error: Error) => error);
      dispatch(loadCartoes({ query: { _id: { in: cartoes }, ...maxItemsQuery } })).catch((error: Error) => error);
      dispatch(loadTiposTransacao({ query: { _id: { in: tiposTransacao }, ...maxItemsQuery } })).catch(
        (error: Error) => error
      );
    },
    [contaCartao.produto, dispatch, maxItemsQuery]
  );

  const loadItems = useCallback(() => {
    dispatch(loadTransacoesContaCartao({ params: { contaCartaoId }, query })).then(
      ({ payload: { data } }: ApiMultiElementResponse) => loadInformacoesComplementares(data)
    );
  }, [contaCartaoId, dispatch, loadInformacoesComplementares, query]);

  const emptyArrayTransacoes = () => (transacoesSelecionadas.length = 0);
  const mostrarBotaoAbrirSolicitacao = Boolean(transacoesSelecionadas.length);

  return (
    <>
      <div className="row">
        <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-12 mb-4">
          <SmartTableFilters filters={smartFilters(produto, { categoriasBeneficiosMap })} />
        </div>

        <div className="col-xxl-10 col-xl-9 col-lg-8 col-md-12 mb-4">
          {mostrarBotaoAbrirSolicitacao && (
            <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.CRIACAO_SOLICITACAO_CLIENTE}>
              <div className="d-flex align-items-center justify-content-between">
                <span className="m-2 text-muted"> Transações selecionadas: {transacoesSelecionadas.length}</span>
                <div className="m-1 d-flex justify-content-end">
                  <BSButton
                    onClick={() =>
                      showDialog({
                        component: (
                          <CadastrarSolicitacaoClienteDialog
                            closeDialog={() => {
                              emptyArrayTransacoes();
                              closeDialog();
                            }}
                            contaCartaoId={contaCartaoId}
                            transacoes={transacoesSelecionadas}
                          />
                        ),
                        size: SizesEnum.EXTRA_LARGE,
                      })
                    }
                  >
                    Abrir solicitação
                  </BSButton>
                </div>
              </div>
            </RbacElement>
          )}

          <SmartTable
            emptyMessage="Nenhuma transação encontrada"
            errorMessage="Erro ao listar transações"
            loadItems={loadItems}
            usePagination={true}
            size={total}
            columns={smartColumns(
              { cartoes },
              { tiposTransacao },
              { categoriasBeneficiosMap },
              produto,
              transacoesSelecionadas,
              setTransacoesSelecionadas
            )}
            items={transacoes}
            loadingState={loadingState}
            onRowClick={(doc) => navigate(`transacoes/${doc._id}`)}
          />
        </div>
      </div>
    </>
  );
};

export default TransacoesContaCartaoTab;

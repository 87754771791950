import ApresentacaoSection from './apresentacao-section';
import AtalhosSection from './atalhos-section';

const HomePage: React.FC = () => {
  return (
    <>
      <ApresentacaoSection />

      <AtalhosSection />
    </>
  );
};

export default HomePage;

import { useState } from 'react';

export const useClipBoard = (copyableValue?: string | number) => {
  const [isFeedbackVisible, showFeedback] = useState<boolean>(false);

  return {
    isFeedbackVisible,
    copyToClipboard: () => {
      if (copyableValue) {
        navigator.clipboard.writeText(copyableValue.toString());
        showFeedback(true);
      }
    },
    clearClipboardFeedback: () => {
      showFeedback(false);
    },
  };
};

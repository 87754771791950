import { Route, Routes } from 'react-router-dom';
import TaxasCambioPage from './taxas-cambio-page';

const TaxasCambioRoutes: React.FC = () => {
  return (
    <Routes>
      <Route index element={<TaxasCambioPage />} />
    </Routes>
  );
};

export default TaxasCambioRoutes;

import { Route, Routes } from 'react-router-dom';

import EmbossingCartoesPage from './embossing-cartoes-page';

const EmbossingCartaoRoutes: React.FC = () => {
  return (
    <Routes>
      <Route index element={<EmbossingCartoesPage />} />
    </Routes>
  );
};

export default EmbossingCartaoRoutes;

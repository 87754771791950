import BSButton from 'react-bootstrap/Button';
import BSModal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { Form } from '../../components/form/form';
import { FormCheck } from '../../components/form/form-check';
import { FormControl } from '../../components/form/form-control';

type AtualizarPerguntaSegurancaFormFields = {
  ativaParaNovoUsuarios: boolean;
  titulo: string;
};

type AtualizarPerguntaSegurancaProps = {
  perguntaSeguranca: any;
  closeDialog: () => void;
  onUpdateHandler: (perguntaSegurancaId: string, data: any) => void;
};

const AtualizarPerguntaSegurancaDialog: React.FC<AtualizarPerguntaSegurancaProps> = ({
  closeDialog,
  perguntaSeguranca,
  onUpdateHandler,
}) => {
  const form = useForm<AtualizarPerguntaSegurancaFormFields>({ defaultValues: perguntaSeguranca });
  const { control } = form;

  const onSubmitHandler = (data: AtualizarPerguntaSegurancaFormFields) => {
    onUpdateHandler(perguntaSeguranca._id, data);
  };

  return (
    <>
      <BSModal.Header closeButton>
        <BSModal.Title>Atualizar pergunta segurança - {perguntaSeguranca.titulo}</BSModal.Title>
      </BSModal.Header>
      <BSModal.Body>
        <Form id="form" form={form} onSubmit={onSubmitHandler}>
          <div className="row">
            <FormControl control={control} className="mb-3" label="Título" name="titulo" rules={{ required: true }} />
            <FormCheck
              control={control}
              className="mb-3"
              name="ativaParaNovoUsuarios"
              label="Ativa para novos usuários"
              type="switch"
            />
          </div>
        </Form>
      </BSModal.Body>
      <BSModal.Footer>
        <BSButton variant="light" onClick={closeDialog}>
          Fechar
        </BSButton>
        <BSButton variant="primary" type="submit" form="form">
          Atualizar
        </BSButton>
      </BSModal.Footer>
    </>
  );
};

export default AtualizarPerguntaSegurancaDialog;

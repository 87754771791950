import { reducerFactory, actionFactory, selectorFactory } from '../../store';

const context = 'solicitacaoAberturaConta';

const { loadMany, loadOne } = actionFactory(context, {
  buildUrlLoadMany: () => '/api/solicitacoes-abertura-conta',
  buildUrlLoadOne: ({ solicitacaoAberturaContaId }) => `/api/solicitacoes-abertura-conta/${solicitacaoAberturaContaId}`,
});

const { selectOneById, selectAll, selectManyByFilters, selectTotalByFilters, selectLoadingStateByFilters } =
  selectorFactory({
    context,
  });

export const loadSolicitacoesAberturaConta = loadMany;
export const loadSolicitacaoAberturaConta = loadOne;

export const selectSolicitacaoAberturaContaById = selectOneById;
export const selectTodasSolicitacoesAberturaConta = selectAll;
export const selectSolicitacoesAberturaContaByFilters = selectManyByFilters;
export const selectTotalSolicitacoesAberturaContaByFilters = selectTotalByFilters;
export const selectSolicitacaoAberturaContaLoadingStateByFilters = selectLoadingStateByFilters;

export const solicitacaoAberturaConta = reducerFactory({ context });

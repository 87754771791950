import { TipoProcessamentoCalendarioCorteEnum } from '@tamborineapps/lib-enums';
import BSButton from 'react-bootstrap/Button';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Form } from '../../components/form/form';
import { FormCheck } from '../../components/form/form-check';
import { FormControl } from '../../components/form/form-control';
import { FormSelect } from '../../components/form/form-select';
import { ClassTypesEnum, tipoProcessamentoCalendarioCorteLabelMap } from '../../helpers';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch } from '../../store/hooks-redux';
import { updateProduto } from './produtos.redux';

type AtualizarCalendarioCorteProdutoFormFields = {
  geracaoAutomaticaCalendariosCorte: boolean;
  tipoProcessamento: string;
  distanciaCorteVencimentoEmDias: number;
  mesesPermanenciaDiaVencimento: number;
};

type AtualizarCalendarioCorteProdutoTabProps = {
  produto: any;
};

const AtualizarCalendarioCorteProdutoTab: React.FC<AtualizarCalendarioCorteProdutoTabProps> = ({ produto }) => {
  const dispatch = useAppDispatch();
  const { showToast } = useToasts();
  const navigate = useNavigate();

  const form = useForm<AtualizarCalendarioCorteProdutoFormFields>({
    defaultValues: {
      distanciaCorteVencimentoEmDias: produto.distanciaCorteVencimentoEmDias,
      geracaoAutomaticaCalendariosCorte: Boolean(produto.geracaoAutomaticaCalendariosCorte),
      mesesPermanenciaDiaVencimento: produto.mesesPermanenciaDiaVencimento,
      tipoProcessamento: produto.tipoProcessamento,
    },
  });
  const { control } = form;

  const onSubmitHandler = (data: AtualizarCalendarioCorteProdutoFormFields) => {
    if (Object.keys(data).length === 0) {
      return;
    }

    dispatch(updateProduto({ params: { produtoId: produto._id }, data }))
      .then(() => {
        showToast({
          message: 'Produto atualizado com sucesso',
          type: ClassTypesEnum.SUCCESS,
        });

        return navigate('/produtos/');
      })
      .catch((error: Error) => {
        showToast({
          message: error.message,
          type: ClassTypesEnum.DANGER,
        });
      });
  };

  return (
    <>
      <Form form={form} id="form" onSubmit={onSubmitHandler}>
        <div className="row">
          <FormCheck
            control={control}
            className="col-12 mb-3"
            name="geracaoAutomaticaCalendariosCorte"
            label="Geração automática de calendário de corte"
            type="switch"
          />

          <FormSelect
            control={control}
            className="col-xl-4 col-md-6 col-sm-12 mb-3"
            name="tipoProcessamento"
            label="Tipo de processamento do calendário"
            options={Object.entries(TipoProcessamentoCalendarioCorteEnum)?.map(([key, value]) => ({
              key,
              value,
              label:
                tipoProcessamentoCalendarioCorteLabelMap[
                  value as keyof typeof tipoProcessamentoCalendarioCorteLabelMap
                ],
            }))}
            rules={{
              required: true,
            }}
          />

          <FormControl
            control={control}
            className="col-xl-4 col-md-6 col-sm-12 mb-3"
            label="Distância entre corte e vencimento em dias"
            name="distanciaCorteVencimentoEmDias"
            type="number"
          />

          <FormControl
            control={control}
            className="col-xl-4 col-md-6 col-sm-12 mb-3"
            label="Meses de permanência do dia de vencimento"
            name="mesesPermanenciaDiaVencimento"
            type="number"
          />

          <div className="d-flex justify-content-end">
            <BSButton variant="primary" type="submit" form="form">
              Atualizar
            </BSButton>
          </div>
        </div>
      </Form>
    </>
  );
};
export default AtualizarCalendarioCorteProdutoTab;

import { dateToStr } from '@tamborineapps/utils/src/date';
import { useCallback, useEffect, useMemo } from 'react';
import BSButton from 'react-bootstrap/Button';
import BSTab from 'react-bootstrap/Tab';
import BSTabs from 'react-bootstrap/Tabs';
import { Link, useNavigate, useParams } from 'react-router-dom';
import DownloadFileButton from '../../components/button/download-file-button';
import { DetailCard } from '../../components/details/datail-card';
import DetailElement from '../../components/details/detail-element';
import DetailSubTitle from '../../components/details/detail-subtitle';
import DetailTitle from '../../components/details/detail-title';
import { Loading } from '../../components/loading';
import Page from '../../components/page';
import { ApiSingleElementResponse, FormatValueEnum, formatNomeMesAno } from '../../helpers';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import { loadContaCartao, selectContaCartaoById } from '../contas-cartao/contas-cartao.redux';
import {
  loadSolicitacoesParcelamentoPorFatura,
  selectSolicitacoesParcelamentoByFilters,
} from '../solicitacoes-parcelamento/solicitacoes-parcelamento.redux';
import AntecipacaoParcelasTransacoesFaturaTab from './antecipar-parcelas-transacoes-fatura-tab';
import BoletosFaturaTab from './boletos-fatura-tab';
import DoacoesCartaoFaturaTab from './doacoes-cartao-fatura-tab';
import { loadFatura, selectFaturaById, selectFaturasLoadingStateByFilters } from './faturas.redux';
import { loadSaldosFatura, selectSaldosFaturaById } from './saldos-fatura.redux';
import SolicitacoesParcelamentoTab from './solicitacoes-parcelamento-tab';
import TransacoesFaturaTab from './transacoes-fatura-tab';

const DetalhesFaturaPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const params = useParams();
  const faturaId = params.faturaId as string;

  const navigate = useNavigate();

  const filters = useMemo(() => ({ faturaId }), [faturaId]);
  const fatura = useSelector((state) => selectFaturaById(state, faturaId));
  const loadingState = useSelector((state) => selectFaturasLoadingStateByFilters(state, filters));
  const contaCartao = useSelector((state) => selectContaCartaoById(state, fatura?.contaCartao));
  const saldosFatura = useSelector((state) => selectSaldosFaturaById(state, fatura?._id));
  const solicitacoesParcelamentos = useSelector((state) => selectSolicitacoesParcelamentoByFilters(state, filters));

  const permiteSimulacaoParcelamento = (fatura: any, solicitacoesParcelamentos: any[]): boolean => {
    if (!fatura.estaFechada || !fatura.valorTotal) {
      return false;
    }

    const hojeStr = dateToStr(new Date());
    const estaEntreCorteEVencimento = hojeStr >= fatura.dataCorte && hojeStr <= fatura.dataVencimento;

    if (!estaEntreCorteEVencimento) {
      return false;
    }

    const possuiSolicitacaoParcelamentoAtiva = solicitacoesParcelamentos.some(({ status }: { status: string }) =>
      ['P', 'F'].includes(status)
    );

    if (possuiSolicitacaoParcelamentoAtiva) {
      return false;
    }

    return true;
  };

  const _loadEntidadesRelacionadas = useCallback(
    (fatura: any) => {
      dispatch(loadContaCartao({ contaCartaoId: fatura.contaCartao })).catch((error: Error) => error);
      dispatch(loadSaldosFatura({ faturaId: fatura._id })).catch((error: Error) => error);
      dispatch(loadSolicitacoesParcelamentoPorFatura({ params: { faturaId: fatura._id } })).catch(
        (error: Error) => error
      );
    },
    [dispatch]
  );

  const _loadFatura = useCallback(
    () =>
      dispatch(loadFatura({ faturaId }))
        .then(({ payload: { data } }: ApiSingleElementResponse) => _loadEntidadesRelacionadas(data))
        .catch((error: Error) => error),
    [_loadEntidadesRelacionadas, dispatch, faturaId]
  );

  useEffect(() => {
    _loadFatura();
  }, [_loadFatura]);

  if (!fatura) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Loading notFoundMessage="Fatura não encontrada" loadingState={loadingState} />
      </div>
    );
  }

  return (
    <Page>
      <div className="mb-5">
        <DetailCard>
          <div className="mb-5">
            <DetailTitle>
              Fatura de {formatNomeMesAno(fatura.dataInicioPeriodo)} -{' '}
              <Link to={`/contas-cartao/${fatura.contaCartao}`}>Conta {contaCartao?.numero}</Link>
            </DetailTitle>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-6 mb-4">
              <DetailElement descricao="Está fechada" valor={fatura.estaFechada} format={FormatValueEnum.BOOL} />

              <DetailElement
                descricao="Pode ser bloqueada por atraso de pagamento da fatura"
                valor={fatura.impedirBloqueioAtrasoNoPeriodo}
                format={FormatValueEnum.BOOL}
              />
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <DetailElement descricao="Deve ser enviada" valor={fatura.deveSerEnviada} format={FormatValueEnum.BOOL} />
              <DetailElement descricao="Ciclos rotativo" valor={fatura.ciclosRotativo} />
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <DetailElement
                descricao="Melhor data para compras"
                valor={fatura.dataMelhorCompra}
                format={FormatValueEnum.DATA}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="mb-3">
                <DetailSubTitle>Período</DetailSubTitle>
              </div>

              <DetailElement descricao="Início" valor={fatura.dataInicioPeriodo} format={FormatValueEnum.DATA} />
              <DetailElement descricao="Prévia" valor={fatura.dataPrevia} format={FormatValueEnum.DATA} />
              <DetailElement descricao="Corte" valor={fatura.dataCorte} format={FormatValueEnum.DATA} />
              <DetailElement descricao="Vencimento" valor={fatura.dataVencimento} format={FormatValueEnum.DATA} />
              <DetailElement
                descricao="Data de vencimento da fatura anterior"
                valor={fatura.dataVencimentoPeriodoAnterior}
                format={FormatValueEnum.DATA}
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="mb-3">
                <DetailSubTitle>Porcetagem CET Rotativo</DetailSubTitle>
              </div>

              <DetailElement descricao="Mensal" valor={fatura.cetRotativoPeriodo} format={FormatValueEnum.PERCENT} />
              <DetailElement descricao="Anual" valor={fatura.cetRotativoAnual} format={FormatValueEnum.PERCENT} />
              <DetailElement
                descricao="Mensal com saque"
                valor={fatura.cetSaquePeriodo}
                format={FormatValueEnum.PERCENT}
              />
              <DetailElement
                descricao="Anual com saque"
                valor={fatura.cetSaqueAnual}
                format={FormatValueEnum.PERCENT}
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="mb-3">
                <DetailSubTitle>Prévia</DetailSubTitle>
              </div>

              <DetailElement
                descricao="Compara valor com o mês anterior"
                valor={fatura.comparaValorMesAnterior}
                format={FormatValueEnum.BOOL}
              />
              <DetailElement
                descricao="Valor do pagamento mínimo da fatura"
                valor={fatura.valorPagamentoMinimo}
                format={FormatValueEnum.BRL}
              />
              <DetailElement
                descricao="Valor para desconto em folha"
                valor={fatura.valorDescontoEmFolha}
                format={FormatValueEnum.BRL}
              />
              <DetailElement
                descricao="Valor para pagamento complementar"
                valor={fatura.valorPagamentoComplementar}
                format={FormatValueEnum.BRL}
              />
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <div className="mb-3">
                <DetailSubTitle>Subtotais</DetailSubTitle>
              </div>

              <DetailElement
                descricao="Subtotal de créditos"
                valor={fatura.valorSubtotalCreditos}
                format={FormatValueEnum.BRL}
              />
              <DetailElement
                descricao="Subtotal de débitos nacionais"
                valor={fatura.valorSubtotalDebitos}
                format={FormatValueEnum.BRL}
              />
              <DetailElement
                descricao="Subtotal de débitos internacionais"
                valor={fatura.valorSubtotalDebitosInternacional}
                format={FormatValueEnum.BRL}
              />
              <DetailElement
                descricao="Subtotal de débitos internacionais dólar"
                valor={fatura.valorDebitosInternacionalDolar}
                format={FormatValueEnum.USD}
              />
              <DetailElement
                descricao="Subtotal de encargos"
                valor={fatura.valorSubtotalEncargos}
                format={FormatValueEnum.BRL}
              />
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <div className="mb-3">
                <DetailSubTitle>Valores presentes na fatura</DetailSubTitle>
              </div>

              <DetailElement descricao="Valor total" valor={fatura.valorTotal} format={FormatValueEnum.BRL} />
              <DetailElement
                descricao="Valor de compras do cliente"
                valor={fatura.valorCompras}
                format={FormatValueEnum.BRL}
              />
              <DetailElement
                descricao="Valor total da fatura anterior"
                valor={fatura.valorTotalAnterior}
                format={FormatValueEnum.BRL}
              />
              <DetailElement
                descricao="Valor total das parcelas não faturadas no emissor"
                valor={fatura.valorEstoqueParceladoEmissor}
                format={FormatValueEnum.BRL}
              />
              <DetailElement
                descricao="Valor total das parcelas não faturadas no lojista"
                valor={fatura.valorEstoqueParceladoLojista}
                format={FormatValueEnum.BRL}
              />
              <DetailElement
                descricao="Valor total das parcelas não faturadas"
                valor={fatura.valorEstoqueParcelado}
                format={FormatValueEnum.BRL}
              />
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <div className="mb-3">
                <DetailSubTitle>Outros valores referentes à fatura:</DetailSubTitle>
              </div>

              <DetailElement descricao="Valor em disputa" valor={fatura.valorEmDisputa} format={FormatValueEnum.BRL} />
              <DetailElement
                descricao="Valor do saldo residual"
                valor={saldosFatura?.saldoResidual}
                format={FormatValueEnum.BRL}
              />
              <DetailElement
                descricao="Saldo Moeda Corrente"
                valor={saldosFatura?.saldoMoedaCorrente}
                format={FormatValueEnum.BRL}
              />
              {fatura.comparaValorMesAnterior && (
                <DetailElement
                  descricao="Valor utilizado no cálculo do pagamento mínimo para comparação com o mês anterior"
                  valor={fatura.valorParaComparacao}
                  format={FormatValueEnum.BRL}
                />
              )}
              <DetailElement
                descricao="Valor das parcelas não financiáveis"
                valor={fatura.valorParcelasNaoFinanciaveis}
                format={FormatValueEnum.BRL}
              />
              <DetailElement
                descricao="Valor projeção juros e IOF rotativo para próximo período"
                valor={fatura.valorProjecaoRotativoProximoPeriodo}
                format={FormatValueEnum.BRL}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12 d-flex justify-content-end flex-wrap">
              <div className="m-1">
                <BSButton
                  onClick={() => navigate('simulacao-parcelamento-fatura')}
                  disabled={!permiteSimulacaoParcelamento(fatura, solicitacoesParcelamentos as any[])}
                >
                  Simular parcelamento
                </BSButton>
              </div>

              {fatura?.armazenamento?.referencia && (
                <div className="m-1">
                  <DownloadFileButton
                    contentType="application/pdf"
                    fileName={`${faturaId}.pdf`}
                    url={`/api/faturas/${faturaId}/pdf`}
                  >
                    Download PDF
                  </DownloadFileButton>
                </div>
              )}
            </div>
          </div>
        </DetailCard>
      </div>

      <DetailCard>
        <BSTabs defaultActiveKey="transacoes" fill unmountOnExit>
          <BSTab eventKey="transacoes" title="Transações">
            <TransacoesFaturaTab faturaId={fatura._id} />
          </BSTab>

          <BSTab eventKey="boletos" title="Boletos">
            <BoletosFaturaTab faturaId={fatura._id} />
          </BSTab>

          <BSTab eventKey="solicitacoesParcelamento" title="Solicitações de parcelamento">
            <SolicitacoesParcelamentoTab faturaId={fatura._id} />
          </BSTab>

          <BSTab eventKey="anteciparParcelas" title="Antecipar parcelas">
            <AntecipacaoParcelasTransacoesFaturaTab faturaId={fatura._id} />
          </BSTab>

          <BSTab eventKey="doacoesCartao" title="Doações">
            <DoacoesCartaoFaturaTab faturaId={fatura._id} />
          </BSTab>
        </BSTabs>
      </DetailCard>
    </Page>
  );
};

export default DetalhesFaturaPage;

import LoginBranding from './auth-branding';
import LoginForm from './auth-form';

const LoginPage: React.FC = () => {
  return (
    <div className="row h-100 g-0">
      <div className="col-md-6">
        <LoginBranding />
      </div>

      <div className="col-md-6">
        <LoginForm />
      </div>
    </div>
  );
};

export default LoginPage;

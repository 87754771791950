import { useCallback, useMemo } from 'react';
import BSCard from 'react-bootstrap/Card';
import { useNavigate } from 'react-router-dom';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import { SmartTable } from '../../components/smart-table/smart-table';
import SmartTableFilters from '../../components/smart-table/smart-table-filters/smart-table-filters';
import {
  SmartTableFilterControlTypesEnum,
  SmartTableFilterProps,
} from '../../components/smart-table/smart-table-filters/table-filter.types';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import Title from '../../components/title';
import { ApiMultiElementResponse, FormatValueEnum, mapearERemoverElementosNulosERepetidos } from '../../helpers';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';

import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import { loadUsuarios, selectObjectTodosUsuarios } from '../usuarios/usuarios.redux';
import {
  loadHistoricoUsuarios,
  selectHistoricoUsuarioByFilters,
  selectHistoricoUsuarioLoadingStateByFilters,
  selectTotalHistoricoUsuarioByFilters,
} from './historico-atividade-usuario.redux';

const smartColumns = ({ usuarios }: { usuarios: any }): SmartTableColumnProps[] => [
  {
    label: 'Data registro requisição',
    attribute: 'dataHoraRequisicao',
    className: 'text-center',
    format: FormatValueEnum.DATE_TIME,
    sortable: true,
  },
  {
    label: 'Usuário',
    attribute: 'usuario',
    className: 'text-center',
    format(usuario) {
      if (!usuario) {
        return '-';
      }

      return usuarios[usuario] ? usuarios[usuario].username : '';
    },
  },
  {
    label: 'Email',
    attribute: 'usuario',
    className: 'text-center',
    format(usuario) {
      if (!usuario) {
        return '-';
      }

      return usuarios[usuario] ? usuarios[usuario].email : '';
    },
  },
  { label: 'URL', attribute: 'url', sortable: true },
  {
    label: 'Método HTTP',
    attribute: 'metodo',
    sortable: true,
    className: 'text-center',
  },
  {
    label: 'Origem alteração',
    attribute: 'origemAlteracao',
    sortable: true,
    className: 'text-center',
  },
];

const smartFilters = (): SmartTableFilterProps[] => [
  { label: 'Usuário', attribute: 'nomeUsuario', controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT },
  { label: 'Email', attribute: 'emailUsuario', controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT },
  {
    label: 'Data registro requisição',
    attribute: 'dataHoraRequisicao',
    controlType: SmartTableFilterControlTypesEnum.DATE_INPUT,
  },
];

const HistoricoUsuariosPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const navigate = useNavigate();
  const { query, maxItemsQuery } = useQuerystring();

  if (!query.sort) {
    query.sort = '-dataHoraRequisicao';
  }

  const filters = useMemo(() => ({ ...query }), [query]);
  const total = useSelector((state) => selectTotalHistoricoUsuarioByFilters(state, filters));
  const historicos = useSelector((state) => selectHistoricoUsuarioByFilters(state, filters));
  const loadingState = useSelector((state) => selectHistoricoUsuarioLoadingStateByFilters(state, filters));
  const usuarios = useSelector((state) => selectObjectTodosUsuarios(state));

  const loadEntidadesComplementares = useCallback(
    (historicos: any[]) => {
      const usuarios = mapearERemoverElementosNulosERepetidos(historicos, 'usuario');

      dispatch(loadUsuarios({ query: { _id: { in: usuarios }, ...maxItemsQuery } })).catch((error: Error) => error);
    },
    [dispatch, maxItemsQuery]
  );

  const loadItems = useCallback(
    () =>
      dispatch(loadHistoricoUsuarios({ query }))
        .then(({ payload: { data } }: ApiMultiElementResponse) => loadEntidadesComplementares(data))
        .catch((error: Error) => error),
    [dispatch, loadEntidadesComplementares, query]
  );

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_HISTORICO_ATIVIDADE_USUARIO}>
      <div className="d-flex flex-column h-100">
        <Title>Histórico de atividade dos usuários</Title>
        <div className="row">
          <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-12 mb-4">
            <SmartTableFilters filters={smartFilters()} />
          </div>
          <div className="col-xxl-10 col-xl-9 col-lg-8 col-md-12 mb-4">
            <BSCard>
              <BSCard.Body>
                <SmartTable
                  columns={smartColumns({ usuarios })}
                  emptyMessage="Nenhum registro de histórico encontrado"
                  errorMessage="Erro ao listar histórico"
                  loadingState={loadingState}
                  loadItems={loadItems}
                  items={historicos}
                  onRowClick={(doc) => navigate(doc._id)}
                  size={total}
                  usePagination={true}
                />
              </BSCard.Body>
            </BSCard>
          </div>
        </div>
      </div>
    </RbacPage>
  );
};

export default HistoricoUsuariosPage;

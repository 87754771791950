import { reducerFactory, actionFactory, selectorFactory } from '../../store';

const context = 'historicosUsuario';

const { loadMany, loadOne } = actionFactory(context, {
  buildUrlLoadMany: () => '/api/historicos-atividade-usuario',
  buildUrlLoadOne: ({ historicoUsuarioId }) => `/api/historicos-atividade-usuario/${historicoUsuarioId}`,
});

const { selectManyByFilters, selectOneById, selectTotalByFilters, selectLoadingStateByFilters } = selectorFactory({
  context,
});

export const loadHistoricoUsuarios = loadMany;
export const loadHistorico = loadOne;

export const selectHistoricoUsuarioById = selectOneById;
export const selectHistoricoUsuarioByFilters = selectManyByFilters;
export const selectTotalHistoricoUsuarioByFilters = selectTotalByFilters;
export const selectHistoricoUsuarioLoadingStateByFilters = selectLoadingStateByFilters;

export const historicosUsuario = reducerFactory({ context });

import { reducerFactory, actionFactory, selectorFactory } from '../../store';

const context = 'faixasMcc';

const { createOne, loadMany, updateOne } = actionFactory(context, {
  buildUrlCreateOne: () => '/api/faixas-mcc',
  buildUrlLoadMany: () => '/api/faixas-mcc',
  buildUrlUpdateOne: ({ faixaMccId }) => `/api/faixas-mcc/${faixaMccId}`,
});

const { selectManyByFilters, selectTotalByFilters, selectLoadingStateByFilters } = selectorFactory({
  context,
});

export const createFaixaMcc = createOne;
export const loadFaixasMcc = loadMany;
export const updateFaixaMcc = updateOne;

export const selectFaixasMccByFilters = selectManyByFilters;
export const selectTotalFaixasMccByFilters = selectTotalByFilters;
export const selectFaixasMccLoadingStateByFilters = selectLoadingStateByFilters;

export const faixasMcc = reducerFactory({ context });

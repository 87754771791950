import { converteInteiroParaMoeda } from '@tamborineapps/utils/src/string';
import { useCallback, useEffect } from 'react';
import BSButton from 'react-bootstrap/Button';
import BSModal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import DetailElement from '../../components/details/detail-element';
import { Form } from '../../components/form/form';
import { FormControl, MasksFormControlEnum } from '../../components/form/form-control';
import { ApiSingleElementResponse, ClassTypesEnum, FormatValueEnum } from '../../helpers';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import { loadProduto, selectProdutoById } from '../produtos/produtos.redux';
import { atualizarLimiteCredito, loadContaCartao, selectContaCartaoById } from './contas-cartao.redux';

type AtualizarLimiteCreditoFormFields = {
  limiteCredito: string;
};

type AtualizarLimiteCreditoUsuarioDialogProps = {
  contaCartaoId: any;
  closeDialog: () => void;
};

const AtualizarLimiteCreditoUsuarioDialog: React.FC<AtualizarLimiteCreditoUsuarioDialogProps> = ({
  contaCartaoId,
  closeDialog,
}) => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const { showToast } = useToasts();

  const contaCartao = useSelector((state) => selectContaCartaoById(state, contaCartaoId));
  const produto = useSelector((state) => selectProdutoById(state, contaCartao?.produto));

  const form = useForm<AtualizarLimiteCreditoFormFields>();
  const { control } = form;

  const loadEntidadesRelacionadas = useCallback(
    (contaCartao: any) => {
      dispatch(loadProduto({ produtoId: contaCartao.produto })).catch((error: Error) => error);
    },
    [dispatch]
  );

  const _loadContaCartao = useCallback(
    () =>
      dispatch(loadContaCartao({ contaCartaoId }))
        .then(({ payload: { data } }: ApiSingleElementResponse) => loadEntidadesRelacionadas(data))
        .catch((error: Error) => error),
    [contaCartaoId, dispatch, loadEntidadesRelacionadas]
  );

  const onSubmitAtualizarLimiteCredito = (data: AtualizarLimiteCreditoFormFields) => {
    return dispatch(atualizarLimiteCredito({ params: { contaCartaoId }, data }))
      .then(() => {
        showToast({
          message: 'Limite atualizado com sucesso!',
          type: ClassTypesEnum.SUCCESS,
        });

        return closeDialog();
      })
      .catch((error: Error) => {
        showToast({
          message: error.message,
          type: ClassTypesEnum.DANGER,
        });
      });
  };

  useEffect(() => {
    _loadContaCartao();
  }, [_loadContaCartao]);

  return (
    <>
      <BSModal.Header closeButton>
        <BSModal.Title>Atualizar limite de crédito</BSModal.Title>
      </BSModal.Header>

      <BSModal.Body>
        <Form id="form" form={form} onSubmit={onSubmitAtualizarLimiteCredito}>
          <DetailElement descricao="Produto" valor={`${produto?.nome} - (Código: ${produto?.codigo})`} />
          {produto && produto?.limiteCreditoMinimoPorConta && (
            <DetailElement
              descricao="Limite de crédito mínimo"
              valor={produto?.limiteCreditoMinimoPorConta}
              format={FormatValueEnum.BRL}
            />
          )}

          {produto && produto?.limiteCreditoMaximoPorConta && (
            <DetailElement
              descricao="Limite de crédito máximo"
              valor={produto?.limiteCreditoMaximoPorConta}
              format={FormatValueEnum.BRL}
            />
          )}

          <DetailElement
            descricao="Limite de crédito atual"
            valor={contaCartao.limiteCredito}
            format={FormatValueEnum.BRL}
          />

          <FormControl
            control={control}
            className="mb-3"
            label="Novo limite de crédito (R$)"
            name="limiteCredito"
            rules={{
              required: true,
              max: {
                value: produto?.limiteCreditoMaximoPorConta,
                message: `Limite de crédito não pode ser superior à R$ ${converteInteiroParaMoeda(
                  produto?.limiteCreditoMaximoPorConta
                )}`,
              },
            }}
            mask={MasksFormControlEnum.BRL}
          />
        </Form>
      </BSModal.Body>

      <BSModal.Footer>
        <BSButton variant="light" onClick={closeDialog}>
          Fechar
        </BSButton>
        <BSButton variant="primary" type="submit" form="form">
          Atualizar Limite de crédito
        </BSButton>
      </BSModal.Footer>
    </>
  );
};

export default AtualizarLimiteCreditoUsuarioDialog;

import { Route, Routes } from 'react-router-dom';
import PerguntasFrequentesPage from './perguntas-frequentes-page';
import DetalhesPerguntasFrequentesPage from './detalhes-perguntas-frequentes-page';
const PerguntasFrequentesRoutes: React.FC = () => {
  return (
    <Routes>
      <Route index element={<PerguntasFrequentesPage />} />
      <Route path=":perguntaFrequenteId" element={<DetalhesPerguntasFrequentesPage />} />
    </Routes>
  );
};

export default PerguntasFrequentesRoutes;

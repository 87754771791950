import { useCallback, useEffect } from 'react';
import BSButton from 'react-bootstrap/Button';
import BSModal from 'react-bootstrap/Modal';

import { useForm } from 'react-hook-form';
import { Form } from '../../components/form/form';
import { FormControl, MasksFormControlEnum } from '../../components/form/form-control';
import { FormSelect } from '../../components/form/form-select';
import { ClassTypesEnum } from '../../helpers';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import { loadProdutos, selectObjectTodosProdutos } from '../produtos/produtos.redux';
import { saveClientePreAprovadoProduto } from './cliente-pre-aprovado.redux';

type CadastrarClientePreAprovadoProdutoFormFields = {
  cpfCnpj: string;
  produtoId: string;
  limiteCredito: string;
};

type CadastrarClientePreAprovadoProdutoProps = {
  closeDialog: () => void;
  reload: () => void;
};

const CadastrarClientePreAprovadoProdutoDialog: React.FC<CadastrarClientePreAprovadoProdutoProps> = ({
  closeDialog,
  reload,
}) => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;
  const { showToast } = useToasts();

  const form = useForm<CadastrarClientePreAprovadoProdutoFormFields>();
  const { control } = form;

  const produtos = useSelector((state) => selectObjectTodosProdutos(state));

  const onSubmitHandler = useCallback(
    (data: CadastrarClientePreAprovadoProdutoFormFields) => {
      dispatch(saveClientePreAprovadoProduto({ data }))
        .then(() => {
          closeDialog();
          showToast({ message: 'Cliente pré-aprovado cadastrado com sucesso', type: ClassTypesEnum.SUCCESS });
          reload();
        })
        .catch((error: Error) => {
          showToast({ message: error.message, type: ClassTypesEnum.DANGER });
        });
    },
    [closeDialog, dispatch, reload, showToast]
  );

  useEffect(() => {
    dispatch(loadProdutos()).catch((error: Error) => error);
  }, [dispatch]);

  return (
    <>
      <BSModal.Header closeButton>
        <BSModal.Title>Novo cliente pré-aprovado</BSModal.Title>
      </BSModal.Header>

      <BSModal.Body>
        <Form id="form" form={form} onSubmit={onSubmitHandler}>
          <FormControl
            control={control}
            className="mb-3"
            label="CPF/CNPJ"
            name="cpfCnpj"
            rules={{ required: true, minLength: 11, maxLength: 14 }}
            mask={MasksFormControlEnum.CPF_CNPJ}
          />

          <FormSelect
            control={control}
            className="mb-3"
            label="Produto"
            name="produtoId"
            placeholder="Selecione o produto"
            options={Object.values(produtos ?? [])?.map((produto: any) => ({
              key: produto._id,
              value: produto._id,
              label: `${produto.codigo} - ${produto.nome}`,
            }))}
            rules={{ required: true }}
          />

          <FormControl
            control={control}
            className="mb-3"
            label="Limite de crédito"
            name="limiteCredito"
            placeholder="R$ 0,00"
            mask={MasksFormControlEnum.BRL}
          />
        </Form>
      </BSModal.Body>

      <BSModal.Footer>
        <BSButton variant="light" onClick={closeDialog}>
          Fechar
        </BSButton>
        <BSButton variant="primary" type="submit" form="form">
          Cadastrar cliente pré-aprovado
        </BSButton>
      </BSModal.Footer>
    </>
  );
};

export default CadastrarClientePreAprovadoProdutoDialog;

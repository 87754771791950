import DetailElement from '../../components/details/detail-element';
import { FormatValueEnum } from '../../helpers';
import { useAppSelector } from '../../store/hooks-redux';
import { selectEmissor } from './emissor.redux';

const DecredEmissorTab: React.FC = () => {
  const useSelector = useAppSelector;
  const [emissor] = useSelector((state) => selectEmissor(state, {})) ?? [];

  return (
    <div className="row">
      <div className="col-lg-4 col-md-6 mb-4">
        <DetailElement descricao="Nome" valor={emissor.decred.nomeResponsavelPreenchimento} />
        <DetailElement
          descricao="CPF"
          valor={emissor.decred?.cpfResponsavelPreenchimento}
          format={FormatValueEnum.CPF_CNPJ}
        />
        <DetailElement descricao="DDD" valor={emissor.decred.dddResponsavelPreenchimento} />
        <DetailElement descricao="Telefone" valor={emissor.decred.telefoneResponsavelPreenchimento} />
        <DetailElement descricao="Ramal" valor={emissor.decred.ramalResponsavelPreenchimento} />
      </div>
    </div>
  );
};

export default DecredEmissorTab;

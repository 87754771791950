import { useCallback, useMemo } from 'react';
import BSCard from 'react-bootstrap/Card';

import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import RbacElement from '../../components/role-based-access-control/role-based-access-control-element';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import { SmartTable } from '../../components/smart-table/smart-table';
import SmartTableButton from '../../components/smart-table/smart-table-button';
import SmartTableFilters from '../../components/smart-table/smart-table-filters/smart-table-filters';
import {
  SmartTableFilterControlTypesEnum,
  SmartTableFilterProps,
} from '../../components/smart-table/smart-table-filters/table-filter.types';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import Title from '../../components/title';
import { FormatValueEnum } from '../../helpers';
import { useDialog } from '../../hooks/dialog/use-dialog';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import CadastrarTaxasCambioDialog from './cadastrar-taxa-cambio-dialog';
import {
  loadTaxasCambio,
  selectTaxasCambiosByFilters,
  selectTaxasCambiosLoadingStateByFilters,
  selectTotalTaxasCambiosByFilters,
} from './taxas-cambio.redux';

const smartFilters = (): SmartTableFilterProps[] => [
  {
    label: 'Data',
    attribute: 'data',
    controlType: SmartTableFilterControlTypesEnum.DATE_INPUT,
  },
  {
    label: 'Moeda',
    attribute: 'moeda',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
  },
];

const smartColumns = (): SmartTableColumnProps[] => [
  {
    label: 'Data',
    attribute: 'data',
    format: FormatValueEnum.DATA,
    sortable: true,
    className: 'text-center',
  },
  {
    label: 'Moeda',
    attribute: 'moeda',
    sortable: true,
    className: 'text-center',
  },
  {
    label: 'Cotação',
    attribute: 'cotacao',
    sortable: true,
    className: 'text-right',
    format: (cotacao: number) => `R$ ${Number(cotacao).toFixed(4).replace('.', ',')}`,
  },
];

const TaxasCambioPage = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const { query } = useQuerystring();
  const { closeDialog, showDialog } = useDialog();

  if (!query.sort) {
    query.sort = '-data';
  }

  const filters = useMemo(() => ({ ...query }), [query]);
  const total = useSelector((state) => selectTotalTaxasCambiosByFilters(state, filters));
  const taxasCambio = useSelector((state) => selectTaxasCambiosByFilters(state, filters));
  const loadingState = useSelector((state) => selectTaxasCambiosLoadingStateByFilters(state, filters));

  const loadItems = useCallback(
    () => dispatch(loadTaxasCambio({ query: filters })).catch((error: Error) => error),
    [dispatch, filters]
  );

  const onNovaTaxaCambioHandler = () => {
    showDialog({
      component: <CadastrarTaxasCambioDialog closeDialog={closeDialog} reload={loadItems} />,
    });
  };

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_TAXA_CAMBIO}>
      <div className="d-flex flex-column h-100">
        <Title>Taxas de câmbio</Title>

        <div className="row">
          <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-12 mb-4">
            <SmartTableFilters filters={smartFilters()} />
          </div>

          <div className="col-xxl-10 col-xl-9 col-lg-8 col-md-12 mb-4">
            <BSCard>
              <BSCard.Body>
                <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.CRIACAO_TAXA_CAMBIO}>
                  <div className="d-flex align-items-center mb-2">
                    <SmartTableButton onClick={onNovaTaxaCambioHandler}>Nova taxa de câmbio</SmartTableButton>
                  </div>
                </RbacElement>
                <SmartTable
                  emptyMessage="Nenhuma taxa de câmbio encontrada"
                  errorMessage="Erro na listagem de taxas de câmbio"
                  usePagination={true}
                  loadItems={loadItems}
                  columns={smartColumns()}
                  items={taxasCambio}
                  loadingState={loadingState}
                  size={total}
                />
              </BSCard.Body>
            </BSCard>
          </div>
        </div>
      </div>
    </RbacPage>
  );
};

export default TaxasCambioPage;

import { reducerFactory, actionFactory, selectorFactory } from '../../store';

const context = 'solicitacoesParcelamento';

const { loadOne, updateOne } = actionFactory(context, {
  buildUrlLoadOne: ({ solicitacaoParcelamentoId }) => `/api/solicitacoes-parcelamento/${solicitacaoParcelamentoId}`,
  buildUrlUpdateOne: ({ solicitacaoParcelamentoId }) =>
    `/api/solicitacoes-parcelamento/${solicitacaoParcelamentoId}/cancelamento`,
});

const { createOne, loadMany: loadManyFatura } = actionFactory(context, {
  buildUrlCreateOne: ({ faturaId }) => `/api/faturas/${faturaId}/solicitacoes-parcelamento`,
  buildUrlLoadMany: ({ faturaId }) => `/api/faturas/${faturaId}/solicitacoes-parcelamento`,
});

const { selectOneById, selectManyByFilters, selectLoadingStateByFilters, selectTotalByFilters } = selectorFactory({
  context,
});

export const loadSolicitacaoParcelamento = loadOne;
export const loadSolicitacoesParcelamentoPorFatura = loadManyFatura;
export const saveSolicitacaoParcelamentoFatura = createOne;
export const updateSolicitacaoParcelamento = updateOne;

export const selectSolicitacaoParcelamentoById = selectOneById;
export const selectSolicitacoesParcelamentoByFilters = selectManyByFilters;
export const selectSolicitacoesParcelamentoLoadingStateByFilters = selectLoadingStateByFilters;
export const selectTotalSolicitacoesParcelamentoByFilters = selectTotalByFilters;

export const solicitacoesParcelamento = reducerFactory({ context });

export const limitadorContadorQuery = 10000;

export const getPaginationProps = ({
  page,
  pageSize,
  size,
}: {
  page: string | number;
  pageSize: string | number;
  size: string | number;
}) => {
  const _page = Number(page);
  const _pageSize = Number(pageSize);
  const numPages = Math.ceil(Number(size) / _pageSize);

  return {
    id: 'smart-table-utils',
    numPages,
    page: _page,
    pageSize: _pageSize,
  };
};

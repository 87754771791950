import BSContainer from 'react-bootstrap/Container';
import SearchBoxCard from './search-box-card';
import classes from './apresentacao-section.module.scss';
import RbacElement from '../../components/role-based-access-control/role-based-access-control-element';
import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';

const ApresentacaoSection: React.FC = () => {
  return (
    <BSContainer
      fluid
      className={`${classes.container} d-flex justify-content-center align-items-center flex-column p-md-5 p-3`}
    >
      <div className="text-center mb-1">
        <h3 className={`${classes.text} mb-0`}>
          Bem-vindo ao <strong>TOS</strong>
        </h3>
      </div>

      <div className="text-center mb-5">
        <h5 className={`${classes.text} mb-0`}>Tamborine Operation System</h5>
      </div>

      <div className="text-center">
        <h2 className={`${classes.text} mb-0`}>Opere tudo num único lugar.</h2>
      </div>

      <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_CONTA_CARTAO}>
        <div className="text-center mb-3 mt-5">
          <h5 className={`${classes.text} mb-0`}>Pesquise por um cliente</h5>
        </div>

        <div className="row justify-content-md-center w-100">
          <div className="col-xl-5 col-lg-8 col-md-12 col-sm-12">
            <SearchBoxCard />
          </div>
        </div>
      </RbacElement>
    </BSContainer>
  );
};

export default ApresentacaoSection;

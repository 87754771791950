import React, { useCallback, useState } from 'react';

import BSButton from 'react-bootstrap/Button';
import { useForm } from 'react-hook-form';
import { BsTrash } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import IconButton from '../../components/button/icon-button';
import { Form } from '../../components/form/form';
import { FormCheck } from '../../components/form/form-check';
import { FormControl, MasksFormControlEnum } from '../../components/form/form-control';
import { SmartTable } from '../../components/smart-table/smart-table';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import { ClassTypesEnum, FormatValueEnum } from '../../helpers';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch } from '../../store/hooks-redux';
import { updateProduto } from './produtos.redux';

type AtualizarParcelamentoFaturaProdutoFormFields = {
  permiteParcelamentoAutomaticoFatura: number;
  ciclosRotativoParaParcelamentoAutomaticoFatura: number;
  maximoParcelamentosSimultaneos: number;
  numeroMaximoFaturasAtrasoOverlimit: number;
  prazoMaximoParcelamentoFatura: number;
  iofParcelamentoFaturaFinanciavel: boolean;
  valorMinimoParcelaParcelamentoFatura: number;
};

type AtualizarParcelamentoFaturaProdutoTabProps = {
  produto: any;
};

type OpcaoParcelamentoProps = {
  parcelas: string;
  taxaMensalJuros: string;
};

type OpcaoParcelamentoFormFields = {
  parcelas: string;
  taxaMensalJuros: string;
};

const opcoesParcelamentoSmartColumns = ({
  onRemoveHandler,
}: {
  onRemoveHandler: (parcela: string) => void;
}): SmartTableColumnProps[] => [
  {
    label: 'Parcelas',
    attribute: 'parcelas',
    className: 'text-center',
  },
  {
    label: 'Taxa mensal de juros',
    attribute: 'taxaMensalJuros',
    format: FormatValueEnum.PERCENT,
    className: 'text-center',
  },
  {
    label: '',
    format: (_, doc) => (
      <IconButton Icon={BsTrash} type={ClassTypesEnum.DANGER} onClick={() => onRemoveHandler(doc.parcelas)} />
    ),
  },
];

const AtualizarParcelamentoFaturaProdutoTab: React.FC<AtualizarParcelamentoFaturaProdutoTabProps> = ({ produto }) => {
  const dispatch = useAppDispatch();
  const { showToast } = useToasts();
  const navigate = useNavigate();

  const form = useForm<AtualizarParcelamentoFaturaProdutoFormFields>({
    defaultValues: {
      permiteParcelamentoAutomaticoFatura: Number(produto.permiteParcelamentoAutomaticoFatura),
      ciclosRotativoParaParcelamentoAutomaticoFatura: Number(produto.ciclosRotativoParaParcelamentoAutomaticoFatura),
      maximoParcelamentosSimultaneos: Number(produto.maximoParcelamentosSimultaneos),
      numeroMaximoFaturasAtrasoOverlimit: Number(produto.numeroMaximoFaturasAtrasoOverlimit),
      prazoMaximoParcelamentoFatura: Number(produto.prazoMaximoParcelamentoFatura),
      iofParcelamentoFaturaFinanciavel: Boolean(produto.iofParcelamentoFaturaFinanciavel),
      valorMinimoParcelaParcelamentoFatura: Number(produto.valorMinimoParcelaParcelamentoFatura),
    },
  });
  const { control } = form;

  const [opcoesParcelamento, setOpcoesParcelamento] = useState<OpcaoParcelamentoProps[]>(
    produto?.opcoesParcelamento ?? []
  );

  const opcaoParcelamentoForm = useForm<OpcaoParcelamentoFormFields>();
  const { control: opcaoParcelamentoControl, reset: resetOpcaoParcelamento } = opcaoParcelamentoForm;

  const incluirOpcaoParcelamento = useCallback(
    (data: OpcaoParcelamentoFormFields) => {
      resetOpcaoParcelamento();
      setOpcoesParcelamento((prev) => [...prev, { ...data }].sort((a, b) => Number(a.parcelas) - Number(b.parcelas)));
    },
    [resetOpcaoParcelamento]
  );

  const removerOpcaoParcelamento = useCallback((parcelas: string) => {
    setOpcoesParcelamento((prev) => prev.filter(({ parcelas: _parcelas }) => parcelas !== _parcelas));
  }, []);

  const onSubmitHandler = (data: AtualizarParcelamentoFaturaProdutoFormFields) => {
    dispatch(
      updateProduto({
        params: { produtoId: produto._id },
        data: { ...data, opcoesParcelamentoFatura: opcoesParcelamento },
      })
    )
      .then(() => {
        showToast({
          message: 'Produto atualizado com sucesso',
          type: ClassTypesEnum.SUCCESS,
        });

        return navigate('/produtos/');
      })
      .catch((error: Error) => {
        showToast({
          message: error.message,
          type: ClassTypesEnum.DANGER,
        });
      });
  };

  return (
    <>
      <Form form={form} id="form" onSubmit={onSubmitHandler}>
        <div className="row">
          <FormCheck
            control={control}
            className="col-md-12 mb-3"
            name="permiteParcelamentoAutomaticoFatura"
            label="Permite parcelamento automático"
            type="switch"
          />

          <FormCheck
            control={control}
            className="col-md-12 mb-3"
            name="iofParcelamentoFaturaFinanciavel"
            label="IOF do parcelamento de faturas financiável"
            type="switch"
          />

          <FormControl
            control={control}
            className="col-xl-4 col-md-6 col-sm-12 mb-3"
            label="Número de ciclos de rotativo para parcelamento automático de fatura"
            name="ciclosRotativoParaParcelamentoAutomaticoFatura"
            type="number"
          />

          <FormControl
            control={control}
            className="col-xl-4 col-md-6 col-sm-12 mb-3"
            label="Número de faturas sem atraso para permissão de overlimit"
            name="numeroMaximoFaturasAtrasoOverlimit"
            type="number"
          />

          <FormControl
            control={control}
            className="col-xl-4 col-md-6 col-sm-12 mb-3"
            label="Máximo de parcelamentos simultâneos"
            name="maximoParcelamentosSimultaneos"
            type="number"
          />

          <FormControl
            control={control}
            className="col-xl-4 col-md-6 col-sm-12 mb-3"
            label="Prazo máximo de aceite do parcelamento"
            name="prazoMaximoParcelamentoFatura"
            type="number"
          />

          <FormControl
            control={control}
            className="col-xl-4 col-md-6 col-sm-12 mb-3"
            label="Valor mínimo da parcela do parcelamento de fatura"
            name="valorMinimoParcelaParcelamentoFatura"
            mask={MasksFormControlEnum.BRL}
          />
        </div>
      </Form>

      <div className="mb-3">
        <span>
          <strong>Opções de parcelamento</strong>
        </span>
      </div>

      <div className="mb-4">
        <SmartTable
          columns={opcoesParcelamentoSmartColumns({ onRemoveHandler: removerOpcaoParcelamento })}
          emptyMessage={<span>Nenhuma opção de parcelamento</span>}
          items={opcoesParcelamento}
          usePagination={false}
          size={opcoesParcelamento.length}
        />
      </div>

      <Form form={opcaoParcelamentoForm} onSubmit={incluirOpcaoParcelamento} id="opcaoParcelamentoForm">
        <div className="row">
          <FormControl
            className="col-xl-6 col-md-12 mb-3"
            control={opcaoParcelamentoControl}
            label="Quantidade de parcelas"
            name="parcelas"
            type="number"
            rules={{
              required: true,
              validate: (value) => {
                const parcelasJaExistentes = opcoesParcelamento.find(({ parcelas }) => value === parcelas);

                if (parcelasJaExistentes) {
                  return 'Parcela já existente';
                }
              },
            }}
          />

          <FormControl
            className="col-xl-6 col-md-12 mb-3"
            control={opcaoParcelamentoControl}
            label="Taxa mensal de juros"
            name="taxaMensalJuros"
            rules={{ required: true }}
          />
        </div>

        <BSButton form="opcaoParcelamentoForm" type="submit" variant={ClassTypesEnum.PRIMARY}>
          Adicionar opção de parcelamento
        </BSButton>
      </Form>

      <div className="my-4">
        <hr />
      </div>

      <div className="d-flex justify-content-end">
        <BSButton variant="primary" type="submit" form="form">
          Atualizar
        </BSButton>
      </div>
    </>
  );
};
export default AtualizarParcelamentoFaturaProdutoTab;

import { actionFactory, reducerFactory, selectorFactory } from '../../store';

const context = 'contasBancarias';

const { loadOne } = actionFactory(context, {
  buildUrlLoadOne: ({ contaBancariaId }) => `/api/contas-bancarias/${contaBancariaId}`,
});

const { selectOneById } = selectorFactory({ context });

export const loadContaBancaria = loadOne;
export const selectContaBancariaById = selectOneById;

export const contasBancarias = reducerFactory({ context });

import { useCallback, useEffect, useState } from 'react';
import BSButton from 'react-bootstrap/Button';
import BSModal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { Form } from '../../components/form/form';
import { FormAutocomplete } from '../../components/form/form-autocomplete';
import { FormControl, MasksFormControlEnum } from '../../components/form/form-control';
import { FormSelect } from '../../components/form/form-select';
import { ApiMultiElementResponse, ClassTypesEnum, mapearERemoverElementosNulosERepetidos } from '../../helpers';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import { loadClientes, selectObjectTodosClientes } from '../clientes/clientes.redux';
import { loadContasCartao } from '../contas-cartao/contas-cartao.redux';
import { loadProdutos, selectProdutosByFilters } from '../produtos/produtos.redux';
import { loadRoteirosContabeis, selectRoteirosContabeisByFilters } from '../roteiros-contabeis/roteiro-contabil.redux';
import { saveLancamentoContabil } from './lancamento-contabil.redux';

type LancamentoContabilFormFields = {
  dataLancamento: string;
  valor: string;
  descricao: string;
  contaCartao: string;
  roteiroContabil: string;
  produto: string;
};

type LancamentoContabilDialogProps = {
  closeDialog: () => void;
  reload: () => void;
};

const CadastrarLancamentoContabilDialog: React.FC<LancamentoContabilDialogProps> = ({ closeDialog, reload }) => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const { maxItemsQuery } = useQuerystring();
  const { showToast } = useToasts();

  const form = useForm<LancamentoContabilFormFields>();
  const { control } = form;

  const [contasCartao, setContasCartao] = useState<any[] | null>();
  const produtos = useSelector((state) => selectProdutosByFilters(state, maxItemsQuery));
  const roteirosContabeis = useSelector((state) => selectRoteirosContabeisByFilters(state, maxItemsQuery));
  const clientes = useSelector((state) => selectObjectTodosClientes(state));

  const onSubmitHandler = useCallback(
    (data: LancamentoContabilFormFields) => {
      dispatch(saveLancamentoContabil({ data }))
        .then(() => {
          closeDialog();
          showToast({ message: 'Lançamento incluído com sucesso', type: ClassTypesEnum.SUCCESS });
          reload();
        })
        .catch((error: Error) => {
          showToast({ message: error.message, type: ClassTypesEnum.DANGER });
        });
    },
    [closeDialog, dispatch, reload, showToast]
  );

  const onSearchHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (!value) {
      return;
    }

    setContasCartao(null);

    return dispatch(loadContasCartao({ query: { numero: { search: value } } }))
      .then(({ payload: { data } }: ApiMultiElementResponse) => {
        onSearchClientes(data);

        return setContasCartao(data);
      })
      .catch((error: Error) => error);
  };

  const onSearchClientes = (contasCartao: any[]) => {
    const clientes = mapearERemoverElementosNulosERepetidos(contasCartao, 'cliente');

    dispatch(loadClientes({ query: { _id: { in: clientes }, ...maxItemsQuery } })).catch((error: Error) => error);
  };

  useEffect(() => {
    dispatch(loadRoteirosContabeis({ query: maxItemsQuery })).catch((error: Error) => error);
    dispatch(loadProdutos({ query: maxItemsQuery })).catch((error: Error) => error);
  }, [dispatch, maxItemsQuery]);

  return (
    <>
      <BSModal.Header closeButton>
        <BSModal.Title>Lançamentos Contábeis</BSModal.Title>
      </BSModal.Header>

      <BSModal.Body>
        <Form id="form" form={form} onSubmit={onSubmitHandler}>
          <FormAutocomplete
            className="mb-3"
            control={control}
            name="contaCartao"
            label="Conta cartão"
            placeholder="Pesquise por um número de conta-cartão"
            options={(contasCartao ?? []).map((contaCartao) => ({
              key: contaCartao._id,
              label: `Conta ${contaCartao.numero} - ${clientes?.[contaCartao?.cliente]?.nome}`,
              value: contaCartao._id,
            }))}
            rules={{ required: true }}
            onChange={onSearchHandler}
          />

          <FormControl
            className="mb-3"
            control={control}
            name="descricao"
            label="Descrição"
            rules={{ required: true }}
          />

          <FormControl
            className="mb-3"
            control={control}
            name="dataLancamento"
            label="Data de lançamento"
            rules={{ required: true }}
            type="date"
          />

          <FormControl
            className="mb-3"
            control={control}
            name="valor"
            label="Valor"
            mask={MasksFormControlEnum.BRL}
            rules={{ required: true, min: 0 }}
          />

          <FormSelect
            control={control}
            className="mb-3"
            name="produto"
            label="Produto"
            placeholder="Selecione o produto"
            options={produtos?.map((elem: any) => ({
              key: elem._id,
              value: elem._id,
              label: `${elem.codigo} - ${elem.nome}`,
            }))}
            rules={{ required: true }}
          />

          <FormSelect
            control={control}
            name="roteiroContabil"
            label="Roteiro Contábil"
            placeholder="Selecione o roteiro contábil"
            options={roteirosContabeis?.map((roteiro: any) => ({
              key: roteiro._id,
              value: roteiro._id,
              label: `${roteiro.descricao}`,
            }))}
            rules={{ required: true }}
          />
        </Form>
      </BSModal.Body>

      <BSModal.Footer>
        <BSButton variant="light" onClick={closeDialog}>
          Fechar
        </BSButton>
        <BSButton variant="primary" type="submit" form="form">
          Cadastrar lançamento contábil
        </BSButton>
      </BSModal.Footer>
    </>
  );
};

export default CadastrarLancamentoContabilDialog;

import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import { useCallback, useEffect, useState } from 'react';
import BSButton from 'react-bootstrap/Button';
import BSSpinner from 'react-bootstrap/Spinner';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { DetailCard } from '../../components/details/datail-card';
import DetailTitle from '../../components/details/detail-title';
import { Form } from '../../components/form/form';
import { FormFile } from '../../components/form/form-file';
import { FormSelect } from '../../components/form/form-select';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import { ClassTypesEnum, tipoContratoTermosUsoLabelMap } from '../../helpers';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import { loadProdutos, selectObjectTodosProdutos } from '../produtos/produtos.redux';
import { loadContratosTermosUso, uploadArquivoPDF } from './contratos-termos-uso.redux';

type CadastrarTermosUsoFields = {
  tipo: string;
  produtoId: string;
  files: FileList | string;
};

const CadastrarTermosUsoPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;
  const navigate = useNavigate();
  const form = useForm<CadastrarTermosUsoFields>();
  const { maxItemsQuery } = useQuerystring();

  const { control, reset } = form;
  const { showToast } = useToasts();

  const [arquivoEmProcessamento, setArquivoEmProcessamento] = useState<boolean>(false);

  const produtos = useSelector((state) => selectObjectTodosProdutos(state));

  const onSubmitHandler = useCallback(
    (data: CadastrarTermosUsoFields) => {
      const { files } = data;
      const [file] = files || [];
      const arquivo = new FormData();

      arquivo.append('tipo', data.tipo);
      arquivo.append('produtoId', data.produtoId);
      arquivo.append('arquivo', file);

      setArquivoEmProcessamento(true);

      dispatch(uploadArquivoPDF({ data: arquivo }))
        .then(() => {
          const query = { sort: '-maisRecente' };

          dispatch(loadContratosTermosUso({ query }, { forceCall: true }))
            .then(() => {
              showToast({ message: 'Contrato cadastrado com sucesso', type: ClassTypesEnum.SUCCESS });
              setArquivoEmProcessamento(false);
              navigate(`/contratos-termos-uso`);
            })
            .catch((error: Error) => error);
        })
        .catch((error: Error) => {
          setArquivoEmProcessamento(false);
          showToast({ message: error.message, type: ClassTypesEnum.DANGER });
        });
    },
    [dispatch, navigate, showToast]
  );

  const onCancelHandler = () => {
    reset();
  };

  useEffect(() => {
    dispatch(loadProdutos({ query: { ...maxItemsQuery } })).catch((error: Error) => error);
  }, [dispatch, maxItemsQuery]);

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.CRIACAO_CONTRATO_TERMOS_USO}>
      <div className="mb-5">
        <DetailCard>
          <div className="mb-5">
            <DetailTitle>Novo contrato de termos de uso</DetailTitle>
          </div>

          <Form id="form" form={form} onSubmit={onSubmitHandler}>
            <FormSelect
              control={control}
              className="mb-3"
              label="Produto"
              name="produtoId"
              placeholder="Selecione o produto"
              options={Object.values(produtos ?? {})?.map((produto: any) => ({
                key: produto._id,
                value: produto._id,
                label: `${produto.codigo} - ${produto.nome}`,
              }))}
              rules={{ required: true }}
            />

            <FormSelect
              control={control}
              className="mb-3"
              label="Tipo"
              name="tipo"
              placeholder="Selecione o tipo"
              options={Object.entries(tipoContratoTermosUsoLabelMap).map(([key, value]) => ({
                key,
                label: value,
                value: key,
              }))}
              rules={{ required: true }}
            />

            <FormFile
              control={control}
              className="mb-3"
              name="files"
              label="Arquivo"
              rules={{ required: true }}
              accept={['.pdf']}
            />

            <div className="d-flex justify-content-end">
              <div className="m-2">
                <BSButton variant="light" onClick={onCancelHandler}>
                  Cancelar
                </BSButton>
                <BSButton className="mx-1 px-4" variant="primary" type="submit" disabled={arquivoEmProcessamento}>
                  Importar arquivo
                </BSButton>
              </div>
            </div>
          </Form>

          {arquivoEmProcessamento && (
            <div className="d-flex justify-content-center align-items-center mt-5">
              <div className="me-3">
                <BSSpinner variant="primary" />
              </div>
              <strong>Cadastro em processamento...</strong>
            </div>
          )}
        </DetailCard>
      </div>
    </RbacPage>
  );
};
export default CadastrarTermosUsoPage;

import { useCallback, useMemo } from 'react';
import BSBadge from 'react-bootstrap/Badge';
import { useNavigate } from 'react-router-dom';
import { SmartTable } from '../../components/smart-table/smart-table';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import { ClassTypesEnum, FormatValueEnum, cartoesRename } from '../../helpers';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import {
  loadHistoricoCartao,
  selectHistoricoCartaoLoadingStateByFilters,
  selectHistoricosCartaoByFilters,
  selectTotalHistoricosCartaoByFilters,
} from '../historicos-cartao/historicos-cartao.redux';

const smartColumns = (): SmartTableColumnProps[] => [
  {
    label: 'Data do evento',
    attribute: 'dataAlteracao',
    className: 'text-start',
    format: FormatValueEnum.DATE_TIME,
  },
  {
    label: 'Origem',
    attribute: 'origemAlteracao',
    className: 'text-start',
  },
  {
    label: 'Campos alterados',
    attribute: 'alteracao',
    className: 'align-middle',
    format: (alteracao: any) => {
      if (!alteracao) {
        return '-';
      }

      const listaAlteracao = Object.keys(alteracao)
        .map((nameKey: string) => cartoesRename[nameKey as keyof typeof cartoesRename])
        .filter((el) => el);

      if (listaAlteracao.length > 10) {
        return (
          <h6 className="m-1">
            <BSBadge pill bg={ClassTypesEnum.LIGHT} text={ClassTypesEnum.PRIMARY}>
              Diversos campos alterados
            </BSBadge>
          </h6>
        );
      }

      return (
        <div className="d-flex flex-wrap">
          {listaAlteracao.map((alteracao: string, index: number) => (
            <h6 className="m-1" key={index}>
              <BSBadge pill bg={ClassTypesEnum.LIGHT} text={ClassTypesEnum.MUTED}>
                {alteracao}
              </BSBadge>
            </h6>
          ))}
        </div>
      );
    },
  },
];

type HistoricoCartaoTabProps = {
  cartaoId: string;
  contaCartaoId: string;
};

const HistoricoCartaoTab: React.FC<HistoricoCartaoTabProps> = ({ cartaoId, contaCartaoId }) => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const navigate = useNavigate();
  const { query } = useQuerystring();

  if (!query.sort) {
    query.sort = '-dataAlteracao';
  }

  const filter = useMemo(() => ({ ...query, cartaoId }), [cartaoId, query]);
  const historicosCartao = useSelector((state) => selectHistoricosCartaoByFilters(state, filter));
  const loadingState = useSelector((state) => selectHistoricoCartaoLoadingStateByFilters(state, filter));
  const total = useSelector((state) => selectTotalHistoricosCartaoByFilters(state, filter));

  const loadItems = useCallback(
    () => dispatch(loadHistoricoCartao({ params: { cartaoId }, query })).catch((error: Error) => error),
    [cartaoId, dispatch, query]
  );

  return (
    <SmartTable
      emptyMessage="Nenhum registro encontrado"
      errorMessage="Erro ao listar histórico"
      loadItems={loadItems}
      usePagination={true}
      size={total}
      columns={smartColumns()}
      items={historicosCartao}
      loadingState={loadingState}
      onRowClick={(doc) => navigate(`/contas-cartao/${contaCartaoId}/cartoes/${cartaoId}/historicos/${doc._id}/`)}
    />
  );
};

export default HistoricoCartaoTab;

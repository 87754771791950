import { reducerFactory, actionFactory, selectorFactory } from '../../store';

const context = 'interacaoSolicitacaoCliente';

const { loadMany, createOne } = actionFactory(context, {
  buildUrlLoadMany: ({ solicitacaoClienteId }) => `/api/solicitacoes-clientes/${solicitacaoClienteId}/interacoes`,
  buildUrlCreateOne: ({ solicitacaoClienteId }) => `/api/solicitacoes-clientes/${solicitacaoClienteId}/interacoes`,
});

const { selectOneById, selectAll, selectManyByFilters, selectTotalByFilters, selectLoadingStateByFilters } =
  selectorFactory({ context });

export const loadInteracoesSolicitacaoCliente = loadMany;
export const saveInteracaoSolicitacaoCliente = createOne;

export const selectInteracaoSolicitacaoClienteById = selectOneById;
export const selectInteracoesSolicitacaoCliente = selectAll;
export const selectInteracoesSolicitacaoClienteByFilters = selectManyByFilters;
export const selectTotalInteracoesSolicitacaoClienteByFilters = selectTotalByFilters;
export const selectInteracaoSolicitacaoClienteLoadingStateByFilters = selectLoadingStateByFilters;

export const interacaoSolicitacaoCliente = reducerFactory({ context });

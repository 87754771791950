import { ParsedQs } from 'qs';
import { HttpStatusCode } from './enums';
import { serializeFilters } from './utils';

export interface PathParameters {
  [key: string]: string;
}

export type QueryParameters = ParsedQs;

export interface HeadersParameters {
  [key: string]: string;
}

export interface BodyRequest {
  [key: string]:
    | string
    | number
    | boolean
    | Date
    | BodyRequest
    | BodyRequest[]
    | string[]
    | number[]
    | boolean[]
    | Date[]
    | undefined;
}

export interface PayloadDataResponseObject {
  [key: string]:
    | string
    | number
    | boolean
    | Date
    | PayloadDataResponseObject
    | PayloadDataResponseObject[]
    | string[]
    | number[]
    | boolean[]
    | Date[];
}

type MethodType = 'GET' | 'PUT' | 'POST' | 'DELETE';

export class ApiError extends Error {
  status: number;

  constructor(status: number, message: string) {
    super(message);
    this.status = status;

    Object.setPrototypeOf(this, ApiError.prototype);
  }
}

export interface ApiSingleElementResponse {
  payload: {
    data: PayloadDataResponseObject;
  };
  status: number;
}

export interface ApiMultiElementResponse {
  payload: {
    data: PayloadDataResponseObject[];
    total: number;
  };
  status: number;
}

export type ApiResponse = ApiSingleElementResponse | ApiMultiElementResponse;

const { ACCEPTED, CREATED, OK, NO_CONTENT } = HttpStatusCode;

export const http = {
  get: (url: string, query?: QueryParameters) => {
    const _query = removeTabFromQuery(query);
    const querystring = serializeFilters(_query);
    const _url = querystring ? `${url}?${querystring}` : url;

    return makeRequest(_url, { method: 'GET' });
  },
  delete: (url: string) => makeRequest(url, { method: 'DELETE', body: {} }),
  post: async (url: string, data: BodyRequest): Promise<ApiResponse> =>
    makeRequest(url, {
      body: data,
      method: 'POST',
    }),
  put: async (url: string, data: BodyRequest): Promise<ApiResponse> =>
    makeRequest(url, {
      body: data,
      method: 'PUT',
    }),

  downloadFile: (url: string, contentType: string) =>
    fetch(url, { method: 'GET', headers: { 'Content-Type': contentType } }),
  postFile: (url: string, formData: FormData) =>
    makeRequest(url, {
      method: 'POST',
      body: formData,
    }),
};

function removeTabFromQuery(query: any) {
  const { _tab, ...queryWithousTab } = query ?? {};

  return queryWithousTab;
}

interface MakeRequestOptionsProps {
  body?: BodyRequest | FormData;
  headers?: HeadersParameters;
  method: MethodType;
}

const makeRequest = async (url: string, { body, method }: MakeRequestOptionsProps): Promise<ApiResponse> => {
  const isFileUpload = body instanceof FormData;
  const data = isFileUpload ? (body as FormData) : body && JSON.stringify(body as BodyRequest);

  const response = await fetch(url, {
    method,
    ...(!isFileUpload && { headers: { 'Content-Type': 'application/json' } }),
    ...(data && { body: data }),
  });
  const json = await response.json();

  const successRequest = [CREATED, ACCEPTED, NO_CONTENT, OK].includes(response.status);

  if (!successRequest) {
    throw new ApiError(response.status, json.message);
  }

  return { status: response.status, payload: { ...json } };
};

import BSButton from 'react-bootstrap/Button';
import BSInputGroup from 'react-bootstrap/InputGroup';
import BSForm from 'react-bootstrap/Form';

import { useSearchParams } from 'react-router-dom';
import { SmartTableFilterComponentProps, SmartTableFilterControlTypesEnum } from './table-filter.types';
import { BsX } from 'react-icons/bs';
import React, { useEffect, useState } from 'react';
import { getMinLengthErrorMessage, validateMinLength } from './smart-table-filters';
import HelpTooltip from '../../help-tooltip';

const { DATE_INPUT, DATE_TIME_INPUT, NUMBER_INPUT, TEXT_INPUT } = SmartTableFilterControlTypesEnum;

const controlTypeMapper = {
  [DATE_INPUT]: 'date',
  [DATE_TIME_INPUT]: 'date',
  [NUMBER_INPUT]: 'number',
  [TEXT_INPUT]: 'text',
};

const searchParamPropMapper = {
  [NUMBER_INPUT]: 'searchNumber',
  [TEXT_INPUT]: 'search',
};

const SearchInputFilter: React.FC<SmartTableFilterComponentProps> = ({
  filter,
  upsertAttributeInSearchParams,
  removeAttributeFromSearchParams,
}) => {
  const [searchParams] = useSearchParams();
  const [formControlValue, setFormControlValue] = useState<string>('');
  const [invalid, setInvalid] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const { attribute, label, className, controlType, minLength, maxLength, exactLength, dica } = filter;

  const prop: string = searchParamPropMapper[controlType as keyof typeof searchParamPropMapper];

  const searchAttribute = `${attribute}[${prop}]`;

  const onKeyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      const { value } = event.target as HTMLInputElement;

      if (!value) {
        return removeAttributeFromSearchParams(searchAttribute);
      }

      if (!invalid) {
        return upsertAttributeInSearchParams(searchAttribute, value);
      }
    }
  };

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    event.preventDefault();

    const { value } = event.target as HTMLInputElement;

    setFormControlValue(value);

    if (exactLength && value.length !== exactLength) {
      setInvalid(true);
      setError(`Digite exatamente ${exactLength} caracteres.`);

      return;
    }

    if (minLength && validateMinLength(minLength, value)) {
      setInvalid(true);
      setError(getMinLengthErrorMessage(minLength));

      return;
    }

    if (maxLength && value.length > (maxLength || Infinity)) {
      setInvalid(true);
      setError(`Digite no máximo ${maxLength} caracteres.`);

      return;
    }

    setInvalid(false);
    setError('');
  };

  const clearFilterHandler = () => {
    setInvalid(false);
    setError('');
    setFormControlValue('');

    return removeAttributeFromSearchParams(searchAttribute);
  };

  useEffect(() => {
    const value = searchParams.get(searchAttribute);
    setFormControlValue(value ?? '');
  }, [searchAttribute, searchParams]);

  return (
    <BSForm noValidate className={className ?? 'mb-3'}>
      <BSForm.Group>
        <BSForm.Label className="d-flex align-items-center">
          {label} {dica && <HelpTooltip dica={dica} />}
        </BSForm.Label>
        <BSInputGroup>
          <BSForm.Control
            type={controlTypeMapper[controlType as keyof typeof controlTypeMapper]}
            value={formControlValue}
            onChange={onChangeHandler}
            onKeyDown={onKeyDownHandler}
            isInvalid={invalid}
          />
          {searchParams.get(searchAttribute) && (
            <BSButton variant="outline-primary" onClick={clearFilterHandler}>
              <BsX size={20} />
            </BSButton>
          )}
          {(minLength && invalid) || (maxLength && invalid) || (exactLength && invalid) ? (
            <BSForm.Control.Feedback type="invalid">{error}</BSForm.Control.Feedback>
          ) : null}
        </BSInputGroup>
      </BSForm.Group>
    </BSForm>
  );
};

export default SearchInputFilter;

import { reducerFactory, actionFactory, selectorFactory } from '../../store';

const context = 'doacoesCartao';

const { loadMany: loadManyDoacoesPendentes, loadOne } = actionFactory(context, {
  buildUrlLoadOne: ({ doacaoCartaoId }) => `/api/doacoes-cartao/${doacaoCartaoId}`,
  buildUrlLoadMany: ({ contaCartaoId }) => `/api/contas-cartao/${contaCartaoId}/doacoes-pendentes`,
});
const { loadMany: loadManyFatura } = actionFactory(context, {
  buildUrlLoadMany: ({ faturaId }) => `/api/fatura/${faturaId}/doacoes-fatura`,
});
const { loadMany: loadManyDoacoesCanceladas } = actionFactory(context, {
  buildUrlLoadMany: ({ contaCartaoId }) => `/api/contas-cartao/${contaCartaoId}/doacoes-canceladas`,
});
const { loadMany: loadManyDoacoesCartaoTransacao } = actionFactory(context, {
  buildUrlLoadMany: ({ transacaoId }) => `/api/transacoes/${transacaoId}/doacoes-cartao`,
});
const { selectOneById, selectManyByFilters, selectTotalByFilters, selectLoadingStateByFilters } = selectorFactory({
  context,
});

export const loadDoacaoCartao = loadOne;
export const loadDoacaoCartaoFatura = loadManyFatura;
export const loadDoacoesPendentes = loadManyDoacoesPendentes;
export const loadDoacoesCanceladas = loadManyDoacoesCanceladas;
export const loadDoacoesCartaoTransacao = loadManyDoacoesCartaoTransacao;

export const selectDoacaoCartaoById = selectOneById;
export const selectDoacoesCartaoByFilters = selectManyByFilters;
export const selectTotalDoacoesCartaoByFilters = selectTotalByFilters;
export const selectDoacoesCartaoLoadingStateByFilters = selectLoadingStateByFilters;

export const doacoesCartao = reducerFactory({ context });

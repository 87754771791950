import { useCallback, useEffect, useMemo } from 'react';
import BSAlert from 'react-bootstrap/Alert';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SmartTable } from '../../components/smart-table/smart-table';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import { ClassTypesEnum, FormatValueEnum } from '../../helpers';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useAppDispatch } from '../../store/hooks-redux';
import {
  loadTransacoes,
  selectTotalTransacoesByFilters,
  selectTransacoesLoadingStateByFilters,
  selectTransacoesByFilters,
} from '../transacoes/transacoes.redux';
import { loadFatura, selectFaturaById } from './faturas.redux';

const smartColumns = (): SmartTableColumnProps[] => [
  {
    label: 'Data transação',
    attribute: 'dataHoraTransacao',
    sortable: true,
    className: 'text-center',
    format: FormatValueEnum.DATE_TIME,
  },
  {
    label: 'Descrição',
    attribute: 'descricao',
  },
  {
    label: 'Estabelecimento',
    attribute: 'nomeEstabelecimento',
  },
  {
    label: 'Total de parcelas',
    attribute: 'quantidadeParcelas',
    sortable: true,
    className: 'text-right',
  },
  {
    label: 'Valor parcelado (R$)',
    attribute: 'valorTotalParcelado',
    format: FormatValueEnum.BRL,
    sortable: true,
    className: 'text-right',
  },
];

type AnteciparParcelasTransacoesFaturaTabProps = {
  faturaId: string;
};

const AnteciparParcelasTransacoesFaturaTab: React.FC<AnteciparParcelasTransacoesFaturaTabProps> = ({ faturaId }) => {
  const dispatch = useAppDispatch();
  const { query } = useQuerystring();
  const navigate = useNavigate();

  const faturaAberta = useSelector((state) => selectFaturaById(state, faturaId));

  const filters = useMemo(
    () => ({
      ...query,
      contaCartao: faturaAberta.contaCartao,
      aAntecipar: 'true',
    }),
    [faturaAberta.contaCartao, query]
  );

  const transacoesParceladas = useSelector((state) => selectTransacoesByFilters(state, filters));
  const total = useSelector((state) => selectTotalTransacoesByFilters(state, filters));
  const loadingState = useSelector((state) => selectTransacoesLoadingStateByFilters(state, filters));

  const _loadFatura = useCallback(
    () => dispatch(loadFatura({ faturaId })).catch((error: Error) => error),
    [dispatch, faturaId]
  );

  const loadItems = useCallback(
    () => dispatch(loadTransacoes({ query: filters })).catch((error: Error) => error),
    [dispatch, filters]
  );

  useEffect(() => {
    _loadFatura();
  }, [_loadFatura]);

  if (faturaAberta.estaFechada) {
    return <BSAlert variant={ClassTypesEnum.PRIMARY}>Fatura fechada</BSAlert>;
  }

  return (
    <SmartTable
      emptyMessage="Nenhuma transação encontrada"
      errorMessage="Erro ao listar transações"
      loadItems={loadItems}
      usePagination={true}
      size={total}
      columns={smartColumns()}
      items={transacoesParceladas}
      loadingState={loadingState}
      onRowClick={(doc) => navigate(`transacoes/${doc._id}/antecipacao-parcelas`)}
    />
  );
};

export default AnteciparParcelasTransacoesFaturaTab;

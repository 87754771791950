import { useEffect } from 'react';
import BSButton from 'react-bootstrap/Button';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Form } from '../../components/form/form';
import { FormCheck } from '../../components/form/form-check';
import { FormControl } from '../../components/form/form-control';
import { FormSelect } from '../../components/form/form-select';
import { ClassTypesEnum } from '../../helpers';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import {
  loadParametrosGeracaoCartao,
  selectObjectTodosParametrosGeracaoCartao,
} from '../parametros-geracao-cartao/parametros-geracao-cartao.redux';
import { updateProduto } from './produtos.redux';

type AtualizarDadosGeraisProdutoFormFields = {
  codigoNoEmissor: string;
  origemRecursos: string;
  exclusivoClientesPreAprovados: boolean;
  subOrigem: string;
  parametrosGeracaoCartao: string;
};

type AtualizarDadosGeraisProdutoTabProps = {
  produto: any;
};

const AtualizarDadosGeraisProdutoTab: React.FC<AtualizarDadosGeraisProdutoTabProps> = ({ produto }) => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const { showToast } = useToasts();
  const navigate = useNavigate();
  const { maxItemsQuery } = useQuerystring();

  const parametrosGeracaoCartao = useSelector((state) => selectObjectTodosParametrosGeracaoCartao(state));

  const form = useForm<AtualizarDadosGeraisProdutoFormFields>({
    defaultValues: {
      codigoNoEmissor: produto.codigoNoEmissor,
      origemRecursos: produto.origemRecursos,
      exclusivoClientesPreAprovados: Boolean(produto.exclusivoClientesPreAprovados),
      subOrigem: produto.subOrigem,
    },
  });
  const { control } = form;

  const onSubmitHandler = (data: AtualizarDadosGeraisProdutoFormFields) => {
    if (Object.keys(data).length === 0) {
      return;
    }

    dispatch(updateProduto({ params: { produtoId: produto._id }, data }))
      .then(() => {
        showToast({
          message: 'Produto atualizado com sucesso',
          type: ClassTypesEnum.SUCCESS,
        });

        return navigate('/produtos/');
      })
      .catch((error: Error) => {
        showToast({
          message: error.message,
          type: ClassTypesEnum.DANGER,
        });
      });
  };

  useEffect(() => {
    dispatch(loadParametrosGeracaoCartao({ query: maxItemsQuery })).catch((error: Error) => error);
  }, [dispatch, maxItemsQuery]);

  return (
    <>
      <Form form={form} id="form" onSubmit={onSubmitHandler}>
        <div className="row">
          <FormControl
            control={control}
            className="col-xl-4 col-md-6 col-sm-12 mb-3"
            label="Código no emissor"
            name="codigoNoEmissor"
          />

          <FormControl
            control={control}
            className="col-xl-4 col-md-6 col-sm-12 mb-3"
            label="Origem recursos"
            name="origemRecursos"
            readOnly // TODO: implementar FormSelect
          />

          <FormControl
            control={control}
            className="col-xl-4 col-md-6 col-sm-12 mb-3"
            label="Sub-origem dos recursos"
            name="subOrigem"
            readOnly // TODO: implementar FormSelect
          />

          <FormSelect
            control={control}
            className="col-xl-4 col-md-6 col-sm-12 mb-3"
            label="Parâmetros de geração de cartão"
            name="parametrosGeracaoCartao"
            options={Object.entries(parametrosGeracaoCartao ?? {}).map(([key, parametro]) => ({
              key,
              label: parametro.codigo,
              value: key,
            }))}
          />

          <FormCheck
            control={control}
            className="col-12 mb-3"
            name="exclusivoClientesPreAprovados"
            label="Exclusivo para clientes pré-aprovados"
            type="switch"
          />

          <div className="d-flex justify-content-end">
            <BSButton variant="primary" type="submit" form="form">
              Atualizar
            </BSButton>
          </div>
        </div>
      </Form>
    </>
  );
};
export default AtualizarDadosGeraisProdutoTab;

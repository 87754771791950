import { useEffect, useState } from 'react';
import BSForm from 'react-bootstrap/Form';

import { FormControlProps as BSFormControlProps } from 'react-bootstrap/FormControl';
import { FieldValue, FieldValues, useController, UseControllerProps } from 'react-hook-form';
import FormControlValidationFeedback from './form-control-validation-feedback';
import FormLabel from './form-label';

interface FormFileProps<TFormValues extends FieldValues>
  extends Omit<BSFormControlProps, 'defaultValue' | 'type' | 'name' | 'accept'>,
    UseControllerProps<TFormValues> {
  label?: string;
  accept?: string[];
}

export const FormFile = <TFormValues extends FieldValues>({
  accept,
  control,
  name,
  label,
  rules,
  className,
  shouldUnregister,
  ...props
}: FormFileProps<TFormValues>): JSX.Element => {
  const defaultValue = '' as FieldValue<TFormValues>;

  const {
    field: { onChange, onBlur, name: fieldName, value: fieldValue },
    fieldState: { error },
  } = useController<TFormValues>({
    name,
    control,
    rules,
    defaultValue,
    shouldUnregister,
  });

  const [value, setValue] = useState<string>(defaultValue);

  useEffect(() => {
    if (!fieldValue) {
      setValue(defaultValue);
    }
  }, [defaultValue, fieldValue, name]);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    onChange(event.target.files);
  };

  return (
    <BSForm.Group data-testid="form-file" className={className}>
      {label && <FormLabel label={label} required={Boolean(rules?.required)} />}

      <BSForm.Control
        {...props}
        accept={accept?.join(',')}
        aria-invalid={Boolean(error)}
        isInvalid={Boolean(error)}
        onChange={onChangeHandler}
        onBlur={onBlur}
        value={value}
        name={fieldName}
        type="file"
        data-testid="form-input"
      />

      <FormControlValidationFeedback error={error} rules={rules} />
    </BSForm.Group>
  );
};

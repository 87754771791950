import React, { useCallback, useState } from 'react';

import BSButton from 'react-bootstrap/Button';
import { useForm } from 'react-hook-form';
import { BsTrash } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import IconButton from '../../components/button/icon-button';
import { Form } from '../../components/form/form';
import { FormControl } from '../../components/form/form-control';
import { SmartTable } from '../../components/smart-table/smart-table';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import { ClassTypesEnum, FormatValueEnum } from '../../helpers';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch } from '../../store/hooks-redux';
import { updateProduto } from './produtos.redux';

type AtualizarFaixasEtariasProdutoTabProps = {
  produto: any;
};

type FaixaEtariaProps = {
  idadeInicial: string;
  percentualPagamentoMinimo: string;
};

type AtualizarFaixaEtariaFormFields = {
  idadeInicial: string;
  percentualPagamentoMinimo: string;
};

const smartColumns = ({
  onRemoveHandler,
}: {
  onRemoveHandler: (idadeInicial: string) => void;
}): SmartTableColumnProps[] => [
  {
    label: 'Idade inicial',
    attribute: 'idadeInicial',
    className: 'text-center',
  },
  {
    label: 'Percentual de pagamento mínimo',
    attribute: 'percentualPagamentoMinimo',
    format: FormatValueEnum.PERCENT,
    className: 'text-center',
  },
  {
    label: '',
    format: (_, doc) => (
      <IconButton Icon={BsTrash} type={ClassTypesEnum.DANGER} onClick={() => onRemoveHandler(doc.idadeInicial)} />
    ),
  },
];

const AtualizarFaixasEtariasProdutoTab: React.FC<AtualizarFaixasEtariasProdutoTabProps> = ({ produto }) => {
  const dispatch = useAppDispatch();
  const { showToast } = useToasts();
  const navigate = useNavigate();

  const [faixasEtarias, setFaxasEtarias] = useState<FaixaEtariaProps[]>(produto?.faixasEtarias ?? []);

  const form = useForm<AtualizarFaixaEtariaFormFields>();
  const { control, reset } = form;

  const incluirFaixaEtaria = useCallback(
    (data: AtualizarFaixaEtariaFormFields) => {
      reset();
      setFaxasEtarias((prev) => [...prev, { ...data }].sort((a, b) => Number(a.idadeInicial) - Number(b.idadeInicial)));
    },
    [reset]
  );

  const removerFaixaEtaria = useCallback((idadeInicial: string) => {
    setFaxasEtarias((prev) => prev.filter(({ idadeInicial: _idadeInicial }) => idadeInicial !== _idadeInicial));
  }, []);

  const onSubmitHandler = () => {
    dispatch(updateProduto({ params: { produtoId: produto._id }, data: { faixasEtarias } }))
      .then(() => {
        showToast({
          message: 'Produto atualizado com sucesso',
          type: ClassTypesEnum.SUCCESS,
        });

        return navigate('/produtos/');
      })
      .catch((error: Error) => {
        showToast({
          message: error.message,
          type: ClassTypesEnum.DANGER,
        });
      });
  };

  return (
    <>
      <div className="mb-3">
        <span>
          <strong>Faixas etárias</strong>
        </span>
      </div>

      <div className="mb-4">
        <SmartTable
          columns={smartColumns({ onRemoveHandler: removerFaixaEtaria })}
          emptyMessage={<span>Nenhuma opção de parcelamento</span>}
          items={faixasEtarias}
          usePagination={false}
          size={faixasEtarias.length}
        />
      </div>

      <Form form={form} onSubmit={incluirFaixaEtaria} id="form">
        <div className="row">
          <FormControl
            className="col-xl-6 col-md-12 mb-3"
            control={control}
            label="Idade inicial"
            name="idadeInicial"
            type="number"
            rules={{
              required: true,
              validate: (value) => {
                const idadeInicialJaExistente = faixasEtarias.find(({ idadeInicial }) => value === idadeInicial);

                if (idadeInicialJaExistente) {
                  return 'Idade inicial já existente';
                }
              },
            }}
          />

          <FormControl
            className="col-xl-6 col-md-12 mb-3"
            control={control}
            label="Percentual de pagamento mínimo"
            name="percentualPagamentoMinimo"
            rules={{ required: true }}
          />
        </div>

        <BSButton form="form" type="submit" variant={ClassTypesEnum.PRIMARY}>
          Adicionar faixa etária
        </BSButton>
      </Form>

      <div className="my-4">
        <hr />
      </div>

      <div className="d-flex justify-content-end">
        <BSButton variant="primary" form="form" onClick={onSubmitHandler}>
          Atualizar
        </BSButton>
      </div>
    </>
  );
};
export default AtualizarFaixasEtariasProdutoTab;

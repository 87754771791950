import { Middleware } from '@reduxjs/toolkit';
import { ApiError } from '../helpers';
import { CallApiAction } from './action-factory';
import type { RootState } from './redux.types';

export const callApiMiddleware: Middleware<Record<string, never>, RootState> =
  ({ getState, dispatch }) =>
  (next) =>
  async (action) => {
    const callApiAction = action as CallApiAction;

    if (!callApiAction.callApi || !callApiAction.types) {
      return next(callApiAction);
    }

    validateActionParameters(callApiAction);

    const [requestType, successType, failureType] = callApiAction.types;

    const shouldSkipApiCallResponse = callApiAction?.shouldSkipApiCall?.(getState());

    if (shouldSkipApiCallResponse) {
      return shouldSkipApiCallResponse;
    }

    dispatch({ type: requestType, payload: callApiAction.payload });

    try {
      const response = await callApiAction.callApi();

      dispatch({
        type: successType,
        payload: { ...callApiAction.payload, response },
      });

      return response;
    } catch (error) {
      const apiError = error as ApiError;

      dispatch({
        type: failureType,
        payload: callApiAction.payload,
        error: apiError,
      });

      throw apiError;
    }
  };

const validateActionParameters = (action: CallApiAction) => {
  const { types, callApi } = action;

  if (!Array.isArray(types) || types?.length !== 3 || !types?.every((type) => typeof type === 'string')) {
    throw new Error('Expected an array of three string types.');
  }

  if (typeof callApi !== 'function') {
    throw new Error('Expected callApi to be a function.');
  }
};

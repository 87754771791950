import { useCallback } from 'react';
import BSButton from 'react-bootstrap/Button';
import BSModal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { Form } from '../../components/form/form';
import { FormControl } from '../../components/form/form-control';
import { ClassTypesEnum } from '../../helpers';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch } from '../../store/hooks-redux';
import { cancelarTransacao } from './transacoes.redux';

type CancelarTransacaoFormFields = {
  motivo: string;
};

type CancelarTransacaoProps = {
  closeDialog: () => void;
  reload: () => void;
  transacaoId: string;
};

const CancelarTransacaoDialog: React.FC<CancelarTransacaoProps> = ({ closeDialog, reload, transacaoId }) => {
  const dispatch = useAppDispatch();
  const { showToast } = useToasts();

  const form = useForm<CancelarTransacaoFormFields>();
  const { control } = form;

  const onSubmitHandler = useCallback(
    (data: CancelarTransacaoFormFields) => {
      dispatch(cancelarTransacao({ params: { transacaoId }, data }))
        .then(() => {
          closeDialog();
          showToast({ message: 'Cancelamento concluído', type: ClassTypesEnum.SUCCESS });
          reload();
        })
        .catch((error: Error) => {
          showToast({ message: error.message, type: ClassTypesEnum.DANGER });
        });
    },
    [closeDialog, dispatch, reload, showToast, transacaoId]
  );

  return (
    <>
      <BSModal.Header closeButton>
        <BSModal.Title>Cancelar transação</BSModal.Title>
      </BSModal.Header>

      <BSModal.Body>
        <Form id="form" form={form} onSubmit={onSubmitHandler}>
          <FormControl
            control={control}
            className="mb-3"
            label="Motivo"
            name="motivo"
            as="textarea"
            rows={7}
            rules={{ required: true }}
          />
        </Form>
      </BSModal.Body>

      <BSModal.Footer>
        <BSButton variant="light" onClick={closeDialog}>
          Fechar
        </BSButton>
        <BSButton variant="primary" type="submit" form="form">
          Confirmar
        </BSButton>
      </BSModal.Footer>
    </>
  );
};

export default CancelarTransacaoDialog;

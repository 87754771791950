import { Route, Routes } from 'react-router-dom';
import DetalhesTipoBloqueioCartaoPage from './detalhes-tipo-bloqueio-cartao-page';
import TiposBloqueioCartaoPage from './tipos-bloqueio-cartao-page';

const TiposBloqueioCartaoRoutes: React.FC = () => {
  return (
    <Routes>
      <Route index element={<TiposBloqueioCartaoPage />} />
      <Route path=":tipoBloqueioCartaoId" element={<DetalhesTipoBloqueioCartaoPage />} />
    </Routes>
  );
};

export default TiposBloqueioCartaoRoutes;

import { Route, Routes } from 'react-router-dom';

import DetalhesParametroGeracaoBoletoPage from './detalhes-parametro-geracao-boleto-page';
import ParametrosGeracaoBoletoPage from './parametro-geracao-boleto-page';

const ParametrosGeracaoBoletoRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path=":parametroGeracaoBoletoId" element={<DetalhesParametroGeracaoBoletoPage />} />
      <Route index element={<ParametrosGeracaoBoletoPage />} />
    </Routes>
  );
};

export default ParametrosGeracaoBoletoRoutes;

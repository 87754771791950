import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { DetailCard } from '../../components/details/datail-card';
import DetailElement from '../../components/details/detail-element';
import DetailTitle from '../../components/details/detail-title';
import { Loading } from '../../components/loading';
import Page from '../../components/page';
import {
  FormatValueEnum,
  codigoRespostaAutorizadorLabelMap,
  formatBool,
  tipoBloqueioCartaoLabelMap,
} from '../../helpers';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import {
  loadTipoBloqueioCartao,
  selectTiposBloqueioCartaoLoadingStateByFilters,
  selectTiposBloqueiosById,
} from './tipos-bloqueio-cartao.redux';

const DetalhesTipoBloqueioCartaoPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const params = useParams();
  const tipoBloqueioCartaoId = params.tipoBloqueioCartaoId as string;

  const filters = useMemo(() => ({ tipoBloqueioCartaoId }), [tipoBloqueioCartaoId]);
  const tipoBloqueioCartao = useSelector((state) => selectTiposBloqueiosById(state, tipoBloqueioCartaoId));
  const loadingState = useSelector((state) => selectTiposBloqueioCartaoLoadingStateByFilters(state, filters));

  useEffect(() => {
    dispatch(loadTipoBloqueioCartao({ tipoBloqueioCartaoId })).catch((error: Error) => error);
  }, [dispatch, tipoBloqueioCartaoId]);

  if (!tipoBloqueioCartao) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Loading notFoundMessage="Tipo de bloqueio de cartão não encontrado" loadingState={loadingState} />
      </div>
    );
  }

  return (
    <Page>
      <div className="mb-5">
        <DetailCard>
          <div className="mb-5">
            <DetailTitle>
              Tipo de bloqueio de cartão: {tipoBloqueioCartao.descricao} ({tipoBloqueioCartao.codigo})
            </DetailTitle>
          </div>

          <div className="row mb-4">
            <div className="col-lg-4 col-md-6 mb-4">
              <DetailElement
                descricao="Resposta autorizador"
                valor={tipoBloqueioCartao.respostaAutorizador}
                format={FormatValueEnum.ENUM}
                map={codigoRespostaAutorizadorLabelMap}
              />
              <DetailElement
                descricao="Permite consultar saldo"
                valor={tipoBloqueioCartao.permiteConsultarSaldo}
                format={FormatValueEnum.BOOL}
              />
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <DetailElement
                descricao="É bloqueio definitivo"
                valor={tipoBloqueioCartao.bloqueioDefinitivo}
                format={FormatValueEnum.BOOL}
              />

              <DetailElement
                descricao="Permite emissão segunda via"
                valor={tipoBloqueioCartao.permiteEmissaoSegundaVia}
                format={FormatValueEnum.BOOL}
              />
              <DetailElement
                descricao="Operação"
                valor={tipoBloqueioCartao.operacao}
                format={FormatValueEnum.ENUM}
                map={tipoBloqueioCartaoLabelMap}
              />
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <DetailElement
                descricao="Cartão considerado emitido para PESBACEN"
                valor={formatBool(tipoBloqueioCartao.indicaCartaoNaoEmitidoPescaben)}
              />
            </div>
          </div>
        </DetailCard>
      </div>
    </Page>
  );
};

export default DetalhesTipoBloqueioCartaoPage;

import { useEffect } from 'react';
import BSButton from 'react-bootstrap/Button';
import BSModal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { Form } from '../../components/form/form';
import { FormSelect } from '../../components/form/form-select';
import { ClassTypesEnum } from '../../helpers';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import { selectSessionUsuario } from '../session-usuario/session-usuario.redux';
import {
  loadTiposBloqueiosContaCartao,
  selectTiposBloqueioContaCartaoByFilters,
} from '../tipos-bloqueio-conta-cartao/tipos-bloqueio-conta-cartao.redux';
import { incluirBloqueioContaCartao } from './contas-cartao.redux';

type IncluirBloqueioContaCartaoFormFields = {
  tipoBloqueio: string;
};

type IncluirBloqueioContaCartaoDialogProps = {
  contaCartao: any;
  closeDialog: () => void;
};

const IncluirBloqueioContaCartaoDialog: React.FC<IncluirBloqueioContaCartaoDialogProps> = ({
  contaCartao,
  closeDialog,
}) => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;
  const { maxItemsQuery } = useQuerystring();

  const { showToast } = useToasts();
  const form = useForm<IncluirBloqueioContaCartaoFormFields>();
  const { control } = form;

  const [sessionUsuario] = useSelector((state) => selectSessionUsuario(state, {})) ?? [];
  const tiposBloqueiosContaCartao = useSelector((state) =>
    selectTiposBloqueioContaCartaoByFilters(state, maxItemsQuery)
  );

  const temPermissaoInclusaoBloqueioDefinitivo = !!Boolean(
    sessionUsuario.papelUsuario?.permissoes?.INCLUSAO_BLOQUEIO_PERMANENTE_CONTA_CARTAO
  );

  const onSubmitHandler = (data: any) => {
    const { tipoBloqueio } = data;

    if (!tipoBloqueio) {
      return;
    }

    dispatch(
      incluirBloqueioContaCartao({
        params: { contaCartaoId: contaCartao._id },
        data: {
          tipoBloqueioId: tipoBloqueio,
        },
      })
    )
      .then(() => {
        showToast({
          message: 'Bloqueio alterado com sucesso',
          type: ClassTypesEnum.SUCCESS,
        });

        closeDialog();
      })
      .catch((error: Error) => {
        showToast({
          message: error.message,
          type: ClassTypesEnum.DANGER,
        });
      });
  };

  useEffect(() => {
    dispatch(loadTiposBloqueiosContaCartao({ query: maxItemsQuery })).catch((error: Error) => error);
  }, [dispatch, maxItemsQuery]);

  return (
    <>
      <BSModal.Header closeButton>
        <BSModal.Title>Incluir bloqueio em Conta - #{contaCartao.numero}</BSModal.Title>
      </BSModal.Header>

      <BSModal.Body>
        <Form id="form" form={form} onSubmit={onSubmitHandler}>
          <FormSelect
            control={control}
            className="mb-3"
            name="tipoBloqueio"
            label="Tipo de bloqueio"
            placeholder="Selecione o tipo de bloqueio"
            options={tiposBloqueiosContaCartao
              ?.filter((tipoBloqueioContaCartao: any) =>
                tipoBloqueioContaCartao.tipo !== 'S' && temPermissaoInclusaoBloqueioDefinitivo
                  ? true
                  : !tipoBloqueioContaCartao.bloqueioDefinitivo
              )
              .map((tipoBloqueioContaCartao: any) => ({
                key: tipoBloqueioContaCartao._id,
                value: tipoBloqueioContaCartao._id,
                label: `${tipoBloqueioContaCartao.codigo} - ${tipoBloqueioContaCartao.descricao}`,
              }))}
            rules={{ required: true }}
          />
        </Form>
      </BSModal.Body>

      <BSModal.Footer>
        <BSButton variant="light" onClick={closeDialog}>
          Fechar
        </BSButton>
        <BSButton variant="primary" type="submit" form="form">
          Incluir bloqueio
        </BSButton>
      </BSModal.Footer>
    </>
  );
};

export default IncluirBloqueioContaCartaoDialog;

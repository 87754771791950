import { dateToStr, getHorario, getMonthName, strToDate } from '@tamborineapps/utils/src/date';

export enum FormatValueEnum {
  PERCENT = 'percent',
  BRL = 'brl',
  BOOL = 'bool',
  DATA = 'data',
  DATE_TIME = 'dateTime',
  CPF_CNPJ = 'cpfCnpj',
  ENUM = 'enum',
  CARTAO = 'cartao',
  TELEFONE = 'telefone',
  USD = 'usd',
  PERCENT_TO_NUMBER = 'percentToNumber',
  CURRENCY = 'currency',
}

export const formatNomeMesAno = (data: Date | string): string => {
  const date = strToDate(data) as Date;

  return `${getMonthName(date)}/${date.getFullYear()}`;
};

export const formatPercentToNumber = (elem: string): number => {
  return Number(elem.replace(',', '.').replace('%', '')) / 100;
};

export const formatTelefone = ({ codigoArea, numero }: { codigoArea: string; numero: string }): string => {
  if (!numero && !codigoArea) {
    return '';
  }

  const formatNumero = (numero: string): string => {
    if (!numero) {
      return '';
    }

    const size = numero.length;

    switch (size) {
      case 8:
        return numero.replace(/(\d{4})(\d{4})/, '$1 - $2');
      case 9:
        return numero.replace(/(\d{1})(\d{4})(\d{4})/, '$1 $2 - $3');
      default:
        return numero;
    }
  };

  const formatCodigoArea = (codigoArea: string): string => {
    if (!codigoArea) {
      return '';
    }

    return codigoArea.replace(/(\d{2})/, '($1)');
  };

  return `${formatCodigoArea(codigoArea)} ${formatNumero(numero)}`;
};

export const formatCpfCnpj = (value: string): string => {
  if (!value) {
    return '-';
  }

  const valueStr = value.toString();

  if (valueStr.length === 11) {
    return valueStr.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }

  return valueStr.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
};
export const formatCartao = (value: string): string => {
  return `${value.slice(0, 4)}.****.****.${value.slice(12, value.length)}`;
};

export const formatDateTime = (value?: string | Date): string => {
  if (!value) {
    return '-';
  }

  const data = new Date(value);

  const dataStr = dateToStr(data).split('-').reverse().join('/');
  const horaStr = getHorario(strToDate(data));

  return `${dataStr} às ${horaStr.slice(0, 2)}:${horaStr.slice(2, 4)}:${horaStr.slice(4, horaStr.length)}`;
};

export const formatPercentValues = (value: number, decimalPlaces: number = 0): string => {
  const [int, dec = ''] = value.toString().split('.');

  const intMultPor100 = Number(int + dec.slice(0, 2).padEnd(2, '0'));
  const decMultPor100 = dec.slice(2).padEnd(decimalPlaces, '0');
  const decMultPor100String = decMultPor100 && `,${decMultPor100}`;

  return `${intMultPor100}${decMultPor100String}%`;
};

export const formatData = (data?: string): string => {
  return data?.split('-')?.reverse()?.join('/') ?? '-';
};

export const formatBool = (bool: boolean | string): string => {
  let boolValue = bool;

  if (typeof boolValue === 'string') {
    boolValue = boolValue === 'true' ? true : false;
  }

  return boolValue ? 'Sim' : 'Não';
};

export const formatBRL = (value?: number | string): string => {
  const number = Number(value ?? 0);

  return Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(number / 100);
};

export const formatUSD = (value?: number | string): string => {
  const number = Number(value ?? 0);

  return Intl.NumberFormat('pt-br', { style: 'currency', currency: 'USD' }).format(number / 100);
};

export const formatCurrency = (value?: number | string): string => {
  const number = Number(value ?? 0);

  return Intl.NumberFormat('pt-br', { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol' }).format(
    number / 100
  );
};

export const formatEnum = (value: any, map: any): string => {
  return map[value];
};

export const formatValue = ({
  format,
  value,
  map = {},
  decimalPlaces,
}: {
  format?: FormatValueEnum;
  value?: any;
  map?: any;
  decimalPlaces?: number;
}): React.ReactNode => {
  if (value === undefined || value === null) {
    return '-';
  }

  switch (format) {
    case FormatValueEnum.PERCENT:
      return formatPercentValues(Number(value), decimalPlaces);
    case FormatValueEnum.BRL:
      return formatBRL(value as string | number);
    case FormatValueEnum.DATA:
      return formatData(value as string);
    case FormatValueEnum.DATE_TIME:
      return formatDateTime(value as string | Date);
    case FormatValueEnum.BOOL:
      return formatBool(value as boolean);
    case FormatValueEnum.CPF_CNPJ:
      return formatCpfCnpj(value as string);
    case FormatValueEnum.ENUM:
      return formatEnum(value, map);
    case FormatValueEnum.CARTAO:
      return formatCartao(value as string);
    case FormatValueEnum.TELEFONE: {
      const { codigoArea, numero } = value;

      return formatTelefone({ codigoArea, numero });
    }
    case FormatValueEnum.USD:
      return formatUSD(value as string | number);
    case FormatValueEnum.PERCENT_TO_NUMBER:
      return formatPercentToNumber(value as string);
    case FormatValueEnum.CURRENCY:
      return formatCurrency(value as string | number);
    default:
      return value;
  }
};

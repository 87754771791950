import { Route, Routes } from 'react-router-dom';
import RoteirosContabeisPage from './roteiros-contabeis-page';
import DetalhesRoteiroContabilPage from './detalhes-roteiro-contabil';

const TarifasRoutes: React.FC = () => {
  return (
    <Routes>
      <Route index element={<RoteirosContabeisPage />} />
      <Route path=":roteiroContabilId" element={<DetalhesRoteiroContabilPage />} />
    </Routes>
  );
};

export default TarifasRoutes;

import { Route, Routes } from 'react-router-dom';

import HistoricoAtividadeUsuariosPage from './historico-atividade-usuario-page';
import DetalhesHistoricoAtividadeUsuarioPage from './detalhes-historico-atividade-usuario-page';

const HistoricoAtividadeUsuariosRoutes: React.FC = () => {
  return (
    <Routes>
      <Route index element={<HistoricoAtividadeUsuariosPage />} />
      <Route path=":historicoUsuarioId" element={<DetalhesHistoricoAtividadeUsuarioPage />} />
    </Routes>
  );
};

export default HistoricoAtividadeUsuariosRoutes;

import React, { useCallback, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { SmartTable } from '../../components/smart-table/smart-table';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import { ApiMultiElementResponse, FormatValueEnum, mapearERemoverElementosNulosERepetidos } from '../../helpers';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import {
  loadContasCartaoCliente,
  selectContasCartaoByFilters,
  selectContasCartaoLoadingStateByFilters,
  selectTotalContasCartaoByFilters,
} from '../contas-cartao/contas-cartao.redux';
import { loadProdutos, selectObjectTodosProdutos } from '../produtos/produtos.redux';

const smartColumns = ({ produtos }: { produtos: any }): SmartTableColumnProps[] => [
  {
    label: 'Número da conta',
    attribute: 'numero',
    className: 'text-center',
    format: (numero: string) => <>{numero}</>,
    sortable: true,
  },
  {
    label: 'Produto',
    attribute: 'produto',
    format(produto) {
      if (!produto) {
        return '-';
      }

      return (
        <Link onClick={(e) => e.stopPropagation()} to={`/produtos/${produto}`}>
          {produtos[produto]?.nome}
        </Link>
      );
    },
  },
  {
    label: 'Limite de crédito',
    format: FormatValueEnum.BRL,
    attribute: 'limiteCredito',
    sortable: true,
    className: 'text-end',
  },
  {
    label: 'Limite disponível',
    format: FormatValueEnum.BRL,
    attribute: 'limiteCreditoDisponivel',
    sortable: true,
    className: 'text-end',
  },
  {
    label: 'Limite para saque',
    format: FormatValueEnum.BRL,
    attribute: 'limiteCreditoSaque',
    sortable: true,
    className: 'text-end',
  },
];

type ContasCartaoClienteTabProps = {
  clienteId: string;
};

const ContasCartaoClienteTab: React.FC<ContasCartaoClienteTabProps> = ({ clienteId }) => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;
  const navigate = useNavigate();
  const { query, maxItemsQuery } = useQuerystring();

  const filters = useMemo(() => ({ clienteId, ...query }), [clienteId, query]);
  const total = useSelector((state) => selectTotalContasCartaoByFilters(state, filters));
  const contasCartao = useSelector((state) => selectContasCartaoByFilters(state, filters));
  const loadingState = useSelector((state) => selectContasCartaoLoadingStateByFilters(state, filters));

  const produtos = useSelector((state) => selectObjectTodosProdutos(state));

  const loadProdutosECartoes = useCallback(
    (contasCartao: any[]) => {
      const produtos = mapearERemoverElementosNulosERepetidos(contasCartao, 'produto');
      dispatch(loadProdutos({ query: { _id: { in: produtos }, ...maxItemsQuery } })).catch((error: Error) => error);
    },
    [dispatch, maxItemsQuery]
  );

  const loadItems = useCallback(
    () =>
      dispatch(loadContasCartaoCliente({ params: { clienteId } }))
        .then(({ payload: { data } }: ApiMultiElementResponse) => loadProdutosECartoes(data))
        .catch((error: Error) => error),
    [clienteId, dispatch, loadProdutosECartoes]
  );

  return (
    <SmartTable
      emptyMessage="Nenhuma conta encontrada"
      errorMessage="Erro ao listar contas"
      loadItems={loadItems}
      usePagination={true}
      size={total}
      columns={smartColumns({ produtos })}
      items={contasCartao}
      loadingState={loadingState}
      onRowClick={(doc) => navigate(`/contas-cartao/${doc._id}/`)}
    />
  );
};

export default ContasCartaoClienteTab;

import { reducerFactory, actionFactory } from '../../store';

const context = 'importarArquivo';

const { uploadFile } = actionFactory(context, {
  buildUrlUploadFile: () => '/api/importar-arquivo',
});

export const uploadArquivo = uploadFile;

export const importarArquivo = reducerFactory({ context });

import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import { useEffect } from 'react';
import BSButton from 'react-bootstrap/Button';
import { BsShieldX } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import IconButton from '../../components/button/icon-button';
import ConfirmationDialog from '../../components/dialog/confirmation-dialog';
import RbacElement from '../../components/role-based-access-control/role-based-access-control-element';
import { SmartTable } from '../../components/smart-table/smart-table';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import { ClassTypesEnum, FormatValueEnum } from '../../helpers';
import { DialogProps } from '../../hooks/dialog/dialog-context';
import { useDialog } from '../../hooks/dialog/use-dialog';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import {
  loadTiposBloqueiosContaCartao,
  selectObjectTodosTiposBloqueioContaCartao,
} from '../tipos-bloqueio-conta-cartao/tipos-bloqueio-conta-cartao.redux';
import { removerBloqueioContaCartao, selectContaCartaoById } from './contas-cartao.redux';
import IncluirBloqueioContaCartaoDialog from './incluir-bloqueio-conta-cartao-dialog';
import { useQuerystring } from '../../hooks/router/use-querystring';

type BloqueiosContaCartaoTabProps = {
  contaCartaoId: string;
};

const smartColumns = ({
  tiposBloqueiosContaCartao,
  showDialog,
  closeDialog,
  onRemoveTipoBloqueioHandler,
}: {
  tiposBloqueiosContaCartao: any;
  showDialog: (dialogProps: DialogProps) => void;
  closeDialog: () => void;
  onRemoveTipoBloqueioHandler: (tipoBloqueioId: string) => void;
}): SmartTableColumnProps[] => [
  {
    label: 'Data de inclusão',
    attribute: 'dataInclusao',
    className: 'text-center align-middle',
    format: FormatValueEnum.DATA,
  },
  {
    label: 'Descrição',
    attribute: 'tipoBloqueio',
    className: 'text-center align-middle',
    format: (tipoBloqueio: keyof typeof tiposBloqueiosContaCartao) =>
      tiposBloqueiosContaCartao[tipoBloqueio]?.descricao,
  },
  {
    label: 'Rotina',
    attribute: 'rotina',
    className: 'text-center align-middle',
  },
  {
    label: 'Ações',
    format: (_, bloqueio) => {
      if (tiposBloqueiosContaCartao[bloqueio.tipoBloqueio]?.bloqueioDefinitivo) {
        return;
      }

      return (
        <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.REMOCAO_BLOQUEIO_CONTA_CARTAO}>
          <div className="d-flex justify-content-center">
            <div className="m1">
              <IconButton
                Icon={BsShieldX}
                type={ClassTypesEnum.DANGER}
                onClick={() => {
                  showDialog({
                    component: (
                      <ConfirmationDialog
                        onConfirmation={() => onRemoveTipoBloqueioHandler(bloqueio.tipoBloqueio)}
                        message="Tem certeza que deseja remover o bloqueio da conta?"
                        confirmationLabel="Remover bloqueio"
                        title="Remover bloqueio"
                        onHide={closeDialog}
                      />
                    ),
                  });
                }}
              />
            </div>
          </div>
        </RbacElement>
      );
    },
  },
];

const BloqueiosContaCartaoTab: React.FC<BloqueiosContaCartaoTabProps> = ({ contaCartaoId }) => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;
  const { maxItemsQuery } = useQuerystring();

  const navigate = useNavigate();
  const { showDialog, closeDialog } = useDialog();
  const { showToast } = useToasts();

  const tiposBloqueiosContaCartao = useSelector((state) => selectObjectTodosTiposBloqueioContaCartao(state));
  const contaCartao = useSelector((state) => selectContaCartaoById(state, contaCartaoId));

  const onRemoveTipoBloqueioHandler = (tipoBloqueioId: string) =>
    dispatch(removerBloqueioContaCartao({ params: { contaCartaoId }, data: { tipoBloqueioId } }))
      .then(() => {
        showToast({
          message: 'Bloqueio removido com sucesso',
          type: ClassTypesEnum.SUCCESS,
        });

        return closeDialog();
      })
      .catch((error: Error) => error);

  useEffect(() => {
    dispatch(loadTiposBloqueiosContaCartao({ query: maxItemsQuery })).catch((error: Error) => error);
  }, [dispatch, maxItemsQuery]);

  return (
    <>
      <div className="d-flex justify-content-end flex-wrap mb-2">
        <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.INCLUSAO_BLOQUEIO_CONTA_CARTAO}>
          <BSButton
            onClick={() =>
              showDialog({
                component: <IncluirBloqueioContaCartaoDialog closeDialog={closeDialog} contaCartao={contaCartao} />,
              })
            }
          >
            Incluir bloqueio
          </BSButton>
        </RbacElement>
      </div>

      <SmartTable
        emptyMessage="Nenhum bloqueio encontrado"
        errorMessage="Erro ao listar bloqueios"
        columns={smartColumns({
          closeDialog,
          onRemoveTipoBloqueioHandler,
          showDialog,
          tiposBloqueiosContaCartao,
        })}
        items={contaCartao.bloqueios ?? []}
        onRowClick={(doc) => navigate(`/contas-cartao/${contaCartaoId}/tipos-bloqueio-conta/${doc.tipoBloqueio}`)}
      />
    </>
  );
};

export default BloqueiosContaCartaoTab;

import { BackButton } from '../back-button';

type DetailTitleProps = {
  children: React.ReactNode;
  renderBackButton?: boolean;
};

const DetailTitle: React.FC<DetailTitleProps> = ({ children, renderBackButton = true }) => {
  return (
    <div data-testid="detail-title" className="d-flex align-items-center">
      {renderBackButton && <BackButton />}
      <h4 className="mb-0">{children}</h4>
    </div>
  );
};

export default DetailTitle;

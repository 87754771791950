import BSBadge from 'react-bootstrap/Badge';
import BSCard from 'react-bootstrap/Card';

import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import { SmartTable } from '../../components/smart-table/smart-table';
import SmartTableFilters from '../../components/smart-table/smart-table-filters/smart-table-filters';
import {
  SmartTableFilterControlTypesEnum,
  SmartTableFilterProps,
} from '../../components/smart-table/smart-table-filters/table-filter.types';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import Title from '../../components/title';
import {
  FormatValueEnum,
  contextoProtocoloProcessamentoLoteLabelMap,
  statusProcessamentoColorMap,
  statusProcessamentoLabelMap,
} from '../../helpers';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import {
  loadProtocolosProcessamentoLoteArquivos,
  selectLoadingStateByFiltersProtocoloProcessamentoLote,
  selectProtocolosProcessamentoLote,
  selectTotalProtocolosProcessamentoLoteByFilters,
} from './protocolo-processamento-lote.redux';

const smartFilters: SmartTableFilterProps[] = [
  {
    attribute: 'contexto',
    label: 'Contexto',
    controlType: SmartTableFilterControlTypesEnum.SELECT,
    map: contextoProtocoloProcessamentoLoteLabelMap,
  },
  {
    attribute: 'dataHoraInicio',
    label: 'Início',
    controlType: SmartTableFilterControlTypesEnum.DATE_INPUT,
  },
  {
    attribute: 'dataHoraTermino',
    label: 'Término',
    controlType: SmartTableFilterControlTypesEnum.DATE_INPUT,
  },
  {
    attribute: 'status',
    label: 'Status',
    controlType: SmartTableFilterControlTypesEnum.SELECT,
    map: statusProcessamentoLabelMap,
  },
];

const smartColumns = (): SmartTableColumnProps[] => [
  {
    label: 'Tipo',
    attribute: 'tipo',
    sortable: true,
    className: 'text-center',
    format: FormatValueEnum.ENUM,
    map: statusProcessamentoLabelMap,
  },
  {
    label: 'Contexto',
    attribute: 'contexto',
    sortable: true,
    className: 'text-center',
  },
  {
    label: 'Arquivo',
    className: 'text-center',
    format: (_, protocoloProcessamentoLote: any) => {
      if (protocoloProcessamentoLote.parametros?.arquivoImportado) {
        return protocoloProcessamentoLote.parametros.arquivoImportado;
      }

      if (protocoloProcessamentoLote.resultados.arquivoGerado) {
        return protocoloProcessamentoLote.resultados.arquivoGerado.split('/').pop();
      }

      return '-';
    },
  },
  {
    label: 'Data de início',
    attribute: 'dataHoraInicio',
    className: 'text-center',
    sortable: true,
    format: FormatValueEnum.DATE_TIME,
  },
  {
    label: 'Data de término',
    attribute: 'dataHoraTermino',
    className: 'text-center',
    sortable: true,
    format: FormatValueEnum.DATE_TIME,
  },
  {
    label: 'Status',
    attribute: 'status',
    sortable: true,
    className: 'text-center',
    format: (status: string) => {
      return (
        <h5 className="m-0">
          <BSBadge pill bg={statusProcessamentoColorMap[status as keyof typeof statusProcessamentoColorMap]}>
            {status}
          </BSBadge>
        </h5>
      );
    },
  },
];

const ProtocolosProcessamentoLoteArquivosPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const { query } = useQuerystring();
  const navigate = useNavigate();

  query.tipoProcessamento = 'arquivos';

  if (!query.sort) {
    query.sort = '-dataHoraInicio';
  }

  const filters = useMemo(() => ({ ...query }), [query]);
  const total = useSelector((state) => selectTotalProtocolosProcessamentoLoteByFilters(state, filters));
  const protocolosProcessamentoLote = useSelector((state) => selectProtocolosProcessamentoLote(state, filters));
  const loadingState = useSelector((state) => selectLoadingStateByFiltersProtocoloProcessamentoLote(state, filters));

  const loadItems = useCallback(() => {
    dispatch(loadProtocolosProcessamentoLoteArquivos({ query: filters })).catch((error: Error) => error);
  }, [dispatch, filters]);

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_PROTOCOLO_PROCESSAMENTO_LOTE}>
      <div className="d-flex flex-column h-100">
        <Title>Processamento de arquivos</Title>

        <div className="row">
          <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-12 mb-4">
            <SmartTableFilters filters={smartFilters} />
          </div>

          <div className="col-xxl-10 col-xl-9 col-lg-8 col-md-12 mb-4">
            <BSCard>
              <BSCard.Body>
                <SmartTable
                  emptyMessage="Nenhum protocolo de processamento de lote encontrado"
                  errorMessage="Erro na listagem de protocolos de processamento de lote"
                  usePagination={true}
                  loadItems={loadItems}
                  columns={smartColumns()}
                  items={protocolosProcessamentoLote}
                  loadingState={loadingState}
                  onRowClick={(doc) => navigate(`/protocolos-processamento-lote/${doc._id}`)}
                  size={total}
                />
              </BSCard.Body>
            </BSCard>
          </div>
        </div>
      </div>
    </RbacPage>
  );
};

export default ProtocolosProcessamentoLoteArquivosPage;

import React from 'react';
import { SmartTable } from '../../components/smart-table/smart-table';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import { FormatValueEnum } from '../../helpers';
import { useAppSelector } from '../../store/hooks-redux';
import { selectProdutoById } from './produtos.redux';

const smartColumns = (): SmartTableColumnProps[] => [
  {
    label: 'Idade inicial',
    className: 'text-center',
    attribute: 'idadeInicial',
  },
  {
    attribute: 'percentualPagamentoMinimo',
    label: 'Percentual de pagamento mínimo da fatura',
    className: 'text-center',
    format: FormatValueEnum.PERCENT,
  },
];

type FaixasEtariasProps = {
  idadeInicial: string;
  percentualPagamentoMinimo: number;
};

type FaixasEtariasTabProps = {
  produtoId: string;
};

const FaixasEtariasTab: React.FC<FaixasEtariasTabProps> = ({ produtoId }) => {
  const useSelector = useAppSelector;

  const { faixasEtarias } = useSelector((state) => selectProdutoById(state, produtoId));

  return (
    <SmartTable
      emptyMessage="Nenhuma faixa etária definida para o produto"
      errorMessage="Erro ao listar faixas etárias"
      usePagination={true}
      columns={smartColumns()}
      items={sortfaixasEtarias(faixasEtarias)}
    />
  );

  function sortfaixasEtarias(faixasEtarias: FaixasEtariasProps[]) {
    if (!faixasEtarias) {
      return [];
    }

    return [...faixasEtarias].sort((a: FaixasEtariasProps, b: FaixasEtariasProps) =>
      a.idadeInicial.localeCompare(b.idadeInicial)
    );
  }
};

export default FaixasEtariasTab;

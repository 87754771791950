import BSForm from 'react-bootstrap/Form';

import { FormSelectProps as BSFormSelectProps } from 'react-bootstrap/FormSelect';
import { FieldValue, FieldValues, useController, UseControllerProps } from 'react-hook-form';
import FormControlValidationFeedback from './form-control-validation-feedback';
import FormLabel from './form-label';

type FormSelectOption = {
  key?: string;
  value: string | number | readonly string[] | undefined;
  label: string;
};

interface FormSelectProps<TFormValues extends FieldValues>
  extends Omit<BSFormSelectProps, 'defaultValue' | 'name'>,
    UseControllerProps<TFormValues> {
  label?: string;
  options?: FormSelectOption[];
  dica?: string | JSX.Element;
  placeholder?: string;
}

export const FormSelect = <TFormValues extends FieldValues>({
  control,
  name,
  label,
  rules,
  className,
  shouldUnregister,
  options = [],
  dica,
  ...props
}: FormSelectProps<TFormValues>): JSX.Element => {
  const defaultValue = '' as FieldValue<TFormValues>;

  const {
    field: { onChange, onBlur, name: fieldName, value },
    fieldState: { error },
  } = useController<TFormValues>({
    name,
    control,
    rules,
    defaultValue,
    shouldUnregister,
  });

  const onChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    props.onChange?.(event);
    onChange(event.target.value);
  };

  const onBlurHandler = (event: React.FocusEvent<HTMLSelectElement>) => {
    props.onBlur?.(event);
    onBlur();
  };

  return (
    <BSForm.Group data-testid="form-select" className={className}>
      {label && <FormLabel label={label} required={Boolean(rules?.required)} dica={dica} />}

      <BSForm.Select
        {...props}
        aria-invalid={Boolean(error)}
        isInvalid={Boolean(error)}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
        value={value}
        name={fieldName}
      >
        {props.placeholder && <option value="">{props.placeholder}</option>}

        {options.map((option, index: number) => (
          <option value={option.value} key={option.key ?? index}>
            {option.label}
          </option>
        ))}
      </BSForm.Select>

      <FormControlValidationFeedback error={error} rules={rules} />
    </BSForm.Group>
  );
};

import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { DetailCard } from '../../components/details/datail-card';
import DetailElement from '../../components/details/detail-element';
import DetailTitle from '../../components/details/detail-title';
import { Loading } from '../../components/loading';
import Page from '../../components/page';
import { ApiSingleElementResponse, FormatValueEnum, formatValue } from '../../helpers';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import { loadCartao, selectCartaoById } from '../cartoes/cartoes.redux';
import { loadContaCartao, selectContaCartaoById } from '../contas-cartao/contas-cartao.redux';
import { loadFatura, selectFaturaById } from '../faturas/faturas.redux';
import { loadProduto, selectProdutoById } from '../produtos/produtos.redux';
import { loadTipoTransacao, selectTiposTransacaoById } from '../tipos-transacao/tipos-transacao.redux';
import { loadTransacao, selectTransacaoById } from '../transacoes/transacoes.redux';
import {
  loadDoacaoCartao,
  selectDoacaoCartaoById,
  selectDoacoesCartaoLoadingStateByFilters,
} from './doacoes-cartao.redux';

const DetalhesDoacaoCartaoPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const params = useParams();
  const doacaoCartaoId = params.doacaoCartaoId as string;

  const filters = useMemo(() => ({ doacaoCartaoId }), [doacaoCartaoId]);
  const doacaoCartao = useSelector((state) => selectDoacaoCartaoById(state, doacaoCartaoId));
  const loadingState = useSelector((state) => selectDoacoesCartaoLoadingStateByFilters(state, filters));
  const tipoTransacao = useSelector((state) => selectTiposTransacaoById(state, doacaoCartao?.tipoTransacao));
  const cartao = useSelector((state) => selectCartaoById(state, doacaoCartao?.cartao));
  const contaCartao = useSelector((state) => selectContaCartaoById(state, doacaoCartao?.contaCartao));
  const fatura = useSelector((state) => selectFaturaById(state, doacaoCartao?.fatura));
  const transacaoGerada = useSelector((state) => selectTransacaoById(state, doacaoCartao?.transacaoGerada));
  const transacaoOrigem = useSelector((state) => selectTransacaoById(state, doacaoCartao?.transacaoOrigem));
  const produto = useSelector((state) => selectProdutoById(state, doacaoCartao?.produto));

  const loadEntidadesRelacionadas = useCallback(
    (doacaoCartao: any) => {
      dispatch(loadContaCartao({ contaCartaoId: doacaoCartao.contaCartao })).catch((error: Error) => error);
      dispatch(loadTipoTransacao({ tipoTransacaoId: doacaoCartao.tipoTransacao })).catch((error: Error) => error);
      dispatch(loadTransacao({ transacaoId: doacaoCartao.transacaoOrigem })).catch((error: Error) => error);
      dispatch(loadTransacao({ transacaoId: doacaoCartao.transacaoGerada })).catch((error: Error) => error);
      dispatch(loadCartao({ cartaoId: doacaoCartao.cartao })).catch((error: Error) => error);
      dispatch(loadFatura({ faturaId: doacaoCartao.fatura })).catch((error: Error) => error);
      dispatch(loadProduto({ produtoId: doacaoCartao.produto })).catch((error: Error) => error);
    },
    [dispatch]
  );

  const _loadDoacaoCartao = useCallback(
    () =>
      dispatch(loadDoacaoCartao({ doacaoCartaoId }))
        .then(({ payload: { data } }: ApiSingleElementResponse) => loadEntidadesRelacionadas(data))
        .catch((error: Error) => error),
    [doacaoCartaoId, dispatch, loadEntidadesRelacionadas]
  );

  useEffect(() => {
    _loadDoacaoCartao();
  }, [_loadDoacaoCartao]);

  if (!doacaoCartao) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Loading notFoundMessage="Doação não encontrada!" loadingState={loadingState} />
      </div>
    );
  }

  return (
    <Page>
      <div className="mb-5">
        <DetailCard>
          <div className="mb-5">
            <DetailTitle>
              Doação: {doacaoCartao.descricao} - {''}
              {formatValue({
                value: doacaoCartao.valor,
                format: FormatValueEnum.BRL,
              })}
            </DetailTitle>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-6 mb-4">
              <DetailElement descricao="Data da doação" valor={doacaoCartao.data} format={FormatValueEnum.DATA} />
              <DetailElement
                descricao="Data e hora geração doação"
                valor={doacaoCartao.dataHora}
                format={FormatValueEnum.DATE_TIME}
              />
              <DetailElement descricao="Paga" valor={doacaoCartao.paga} format={FormatValueEnum.BOOL} />
              <DetailElement descricao="Pendente" valor={doacaoCartao.pendente} format={FormatValueEnum.BOOL} />
              <DetailElement descricao="Cancelada" valor={doacaoCartao.cancelada} format={FormatValueEnum.BOOL} />
            </div>
            <div className="col-lg-4 col-md-6">
              <DetailElement descricao="Tipo arredondamento" valor={doacaoCartao.tipoArredondamento} />
              {transacaoGerada && (
                <DetailElement
                  descricao="Data da transação gerada"
                  valor={transacaoGerada.dataHoraTransacao}
                  format={FormatValueEnum.DATE_TIME}
                  link={`/contas-cartao/${doacaoCartao.contaCartao}/transacoes/${doacaoCartao.transacaoGerada}/`}
                />
              )}
              {transacaoOrigem && (
                <DetailElement
                  descricao="Data da transação de origem"
                  valor={transacaoOrigem.dataHoraTransacao}
                  format={FormatValueEnum.DATE_TIME}
                  link={`/contas-cartao/${doacaoCartao.contaCartao}/transacoes/${doacaoCartao.transacaoOrigem}/`}
                />
              )}
              {tipoTransacao && (
                <DetailElement
                  descricao="Tipo da transação"
                  valor={tipoTransacao.descricao}
                  link={`/tipos-transacao/${doacaoCartao.tipoTransacao}/`}
                />
              )}
            </div>
            <div className="col-lg-4 col-md-6">
              {contaCartao && (
                <DetailElement
                  descricao="Conta cartão"
                  valor={contaCartao.numero}
                  link={`/contas-cartao/${doacaoCartao.contaCartao}/`}
                />
              )}
              {cartao && (
                <DetailElement
                  descricao="Cartão"
                  valor={cartao.numeroTruncado}
                  format={FormatValueEnum.CARTAO}
                  link={`/contas-cartao/${doacaoCartao.contaCartao}/cartoes/${doacaoCartao.cartao}/`}
                />
              )}
              {fatura && (
                <DetailElement
                  descricao="Data de início da fatura"
                  valor={fatura.dataInicioPeriodo}
                  format={FormatValueEnum.DATA}
                  link={`/contas-cartao/${doacaoCartao.contaCartao}/cartoes/${doacaoCartao.cartao}/faturas/${doacaoCartao.fatura}/`}
                />
              )}
              {produto && (
                <DetailElement descricao="Produto" valor={produto.nome} link={`/produtos/${doacaoCartao.produto}/`} />
              )}
            </div>
          </div>
        </DetailCard>
      </div>
    </Page>
  );
};

export default DetalhesDoacaoCartaoPage;

import React from 'react';
import BSButton from 'react-bootstrap/Button';
import BSListGroup from 'react-bootstrap/ListGroup';
import { BsTrash } from 'react-icons/bs';
import variables from '../../_export.scss';
import ConfirmationDialog from '../../components/dialog/confirmation-dialog';
import { ClassTypesEnum, tipoTransacaoLabelMap } from '../../helpers';
import { useDialog } from '../../hooks/dialog/use-dialog';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch } from '../../store/hooks-redux';
import IncluirOperacaoPermitidaProdutoDialog, {
  operacoesDisponiveis,
} from './incluir-operacao-permitida-produto-dialog';
import { removerOperacaoPermitidaProduto } from './produtos.redux';

type AtualizarOperacoesPermitidasProdutoTabProps = {
  produto: any;
};

const AtualizarOperacoesPermitidasProdutoTab: React.FC<AtualizarOperacoesPermitidasProdutoTabProps> = ({ produto }) => {
  const dispatch = useAppDispatch();
  const { showDialog, closeDialog } = useDialog();
  const { showToast } = useToasts();

  const onIncluirOperacaoClickHandler = () => {
    showDialog({
      component: <IncluirOperacaoPermitidaProdutoDialog produto={produto} closeDialog={closeDialog} />,
    });
  };

  const onRemoveOperacaoClickHandler = (operacao: string) => {
    showDialog({
      component: (
        <ConfirmationDialog
          onConfirmation={() => removerOperacao(operacao)}
          message={`Tem certeza que deseja remover a operação ${
            tipoTransacaoLabelMap[operacao as keyof typeof tipoTransacaoLabelMap]
          }?`}
          confirmationLabel="Remover"
          cancelLabel="Cancelar"
          title="Remover operação"
          onHide={closeDialog}
        />
      ),
    });
  };

  const removerOperacao = (operacao: string) => {
    dispatch(removerOperacaoPermitidaProduto({ params: { produtoId: produto._id }, data: { operacao } }))
      .then(() => {
        showToast({
          message: 'Operação removida com sucesso',
          type: ClassTypesEnum.SUCCESS,
        });
        closeDialog();
      })
      .catch((error: Error) => {
        showToast({
          message: error.message,
          type: ClassTypesEnum.DANGER,
        });
      });
  };

  return (
    <>
      <p className="mb-4">
        Operações referentes aos tipos de transação que serão aceitos em autorizações de contas-cartão vinculadas ao
        produto.
      </p>

      {Boolean(produto?.operacoesPermitidas?.length) && (
        <>
          <h6 className="mb-3">Operações permitidas:</h6>

          <div className="row">
            <div className="col-12">
              <BSListGroup className="mb-3">
                {produto.operacoesPermitidas.map((operacao: string) => (
                  <BSListGroup.Item key={operacao}>
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="me-3">
                        {tipoTransacaoLabelMap[operacao as keyof typeof tipoTransacaoLabelMap]}
                      </span>

                      <BsTrash
                        role="button"
                        color={variables.danger}
                        onClick={() => onRemoveOperacaoClickHandler(operacao)}
                      />
                    </div>
                  </BSListGroup.Item>
                ))}
              </BSListGroup>
            </div>
          </div>
        </>
      )}

      {produto?.operacoesPermitidas?.length !== operacoesDisponiveis.length && (
        <div className="mt-3">
          <BSButton onClick={onIncluirOperacaoClickHandler}>Incluir operação</BSButton>
        </div>
      )}
    </>
  );
};
export default AtualizarOperacoesPermitidasProdutoTab;

import { useCallback, useEffect, useState } from 'react';
import BSDropdown from 'react-bootstrap/Dropdown';
import BSForm from 'react-bootstrap/Form';

import { FormControlProps as BSFormControlProps } from 'react-bootstrap/FormControl';
import { FieldValue, FieldValues, useController, UseControllerProps } from 'react-hook-form';
import FormControlValidationFeedback from './form-control-validation-feedback';
import FormLabel from './form-label';

type FormAutocompleteOption = {
  key: string;
  value: string | number | undefined;
  label: string;
};

interface FormAutocompleteProps<TFormValues extends FieldValues>
  extends Omit<BSFormControlProps, 'defaultValue' | 'name'>,
    UseControllerProps<TFormValues> {
  label?: string;
  options: FormAutocompleteOption[];
}

export const FormAutocomplete = <TFormValues extends FieldValues>({
  control,
  name,
  label,
  rules,
  className,
  shouldUnregister,
  options,
  ...props
}: FormAutocompleteProps<TFormValues>): JSX.Element => {
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [displayValue, setDisplayValue] = useState<string>('');
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<FormAutocompleteOption | undefined>();

  const defaultValue = '' as FieldValue<TFormValues>;

  const {
    field: { onChange, onBlur, name: fieldName, value },
    fieldState: { error },
    formState: { defaultValues },
  } = useController<TFormValues>({
    name,
    control,
    rules,
    defaultValue: defaultValue,
    shouldUnregister,
  });

  const autocompleteOptionIdPrefix = `${name}-ac-option-`;

  if (defaultValues?.[name]) {
    const value = defaultValues?.[name];
    const option = options.find((option) => option.value === value);

    setDisplayValue(option?.label ?? '');
  }

  const clearAllValues = () => {
    setSearchValue('');
    setDisplayValue('');
    setSelectedOption(undefined);
    onChange('');
  };

  const onFocusDisplayInputHandler = () => {
    setIsSearching(true);
    clearAllValues();
  };

  const onChangeSearchInputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange?.(event);

    const eventValue = event.target.value;

    setSearchValue(eventValue);
    setDisplayValue('');
  };

  const onSelectHandler = (eventKey: any) => {
    const option = options.find((option) => option.key === eventKey);

    setSelectedOption(option);
    setDisplayValue(option?.label ?? '');
    onChange(option?.value ?? '');
    setIsSearching(false);
  };

  const onBlurSearchInputHandler = (event: React.FocusEvent<HTMLInputElement>) => {
    const focusLostByDropdownSelection = event?.relatedTarget?.id?.startsWith(autocompleteOptionIdPrefix);

    if (!focusLostByDropdownSelection) {
      setIsSearching(false);
    }

    props.onBlur?.(event);
    onBlur();
  };

  const onToggleHandler = (nextValue: boolean) => {
    setShowOptions(nextValue);
  };

  const showOptionsHandler = useCallback(() => {
    if (!isSearching) {
      return setShowOptions(false);
    }

    if (searchValue) {
      return setShowOptions(true);
    }

    setShowOptions(false);
  }, [isSearching, searchValue]);

  useEffect(() => {
    showOptionsHandler();
  }, [showOptionsHandler, isSearching, options.length, searchValue, selectedOption, value]);

  return (
    <BSForm.Group className={className}>
      {label && <FormLabel label={label} required={Boolean(rules?.required)} />}

      {isSearching ? (
        <BSForm.Control
          {...props}
          aria-invalid={Boolean(error)}
          onChange={onChangeSearchInputChangeHandler}
          onBlur={onBlurSearchInputHandler}
          isInvalid={Boolean(error)}
          value={searchValue}
          name={fieldName}
          autoComplete="off"
        />
      ) : (
        <BSForm.Control
          placeholder={props.placeholder}
          aria-invalid={Boolean(error)}
          onFocus={onFocusDisplayInputHandler}
          isInvalid={Boolean(error)}
          value={displayValue}
          readOnly
        />
      )}

      <BSDropdown show={showOptions} onToggle={onToggleHandler} onSelect={onSelectHandler}>
        <BSDropdown.Menu className="w-100">
          {options.map(({ key, label }, index: number) => (
            <BSDropdown.Item id={`${autocompleteOptionIdPrefix}${key}`} eventKey={key} key={key ?? index}>
              {label}
            </BSDropdown.Item>
          ))}

          {Boolean(!options.length) && (
            <BSDropdown.Item disabled>
              <div className="d-flex justify-content-center py-2">Sem opções</div>
            </BSDropdown.Item>
          )}
        </BSDropdown.Menu>
      </BSDropdown>

      <FormControlValidationFeedback error={error} rules={rules} />
    </BSForm.Group>
  );
};

import { Route, Routes } from 'react-router-dom';

import ContratosTermosUsoPage from './contratos-termos-uso-page';
import DetalhesContratoTermosUsoPage from './detalhes-contratos-termos-uso-page';
import CadastrarTermosUsoPage from './cadastrar-contratos-termo-uso-page';
const ContratosTermosUsoRoutes: React.FC = () => {
  return (
    <Routes>
      <Route index element={<ContratosTermosUsoPage />} />
      <Route path=":contratoTermosUsoId" element={<DetalhesContratoTermosUsoPage />} />
      <Route path="cadastrar" element={<CadastrarTermosUsoPage />} />
    </Routes>
  );
};

export default ContratosTermosUsoRoutes;

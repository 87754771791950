import BSButton from 'react-bootstrap/Button';
import BSModal from 'react-bootstrap/Modal';
import { awaitForAsyncTask } from '../../helpers';
import { ClassTypesEnum } from '../../helpers';

type ConfirmationDialogProps = {
  onHide: () => void;
  message?: string | JSX.Element;
  onConfirmation: (...args: any[]) => any;
  title?: string;
  cancelLabel?: string;
  confirmationLabel?: string;
  type?: ClassTypesEnum;
};

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  title,
  message,
  onConfirmation,
  onHide,
  cancelLabel,
  confirmationLabel,
  type,
}) => {
  const onClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();

    awaitForAsyncTask(onConfirmation, onHide)();
  };

  return (
    <>
      <BSModal.Header closeButton>
        <BSModal.Title>{title ?? 'Confirmação'}</BSModal.Title>
      </BSModal.Header>

      <BSModal.Body>
        <p>{message ?? 'Tem certeza que deseja executar esta ação?'}</p>
      </BSModal.Body>

      <BSModal.Footer>
        <BSButton variant={ClassTypesEnum.LIGHT} onClick={onHide}>
          {cancelLabel ?? 'Fechar'}
        </BSButton>

        <BSButton data-testid="confirmation-dialog" variant={type ?? ClassTypesEnum.PRIMARY} onClick={onClickHandler}>
          {confirmationLabel ?? 'Confirmar'}
        </BSButton>
      </BSModal.Footer>
    </>
  );
};

export default ConfirmationDialog;

import { Route, Routes } from 'react-router-dom';
import SolicitacoesClientesPage from './solicitacoes-clientes-page';
import DetalhesSolicitacaoClientePage from './detalhes-solicitacao-cliente-page';
import DetalhesTransacaoPage from '../transacoes/detalhes-transacao-page';

const SolicitacoesClientesRoutes: React.FC = () => {
  return (
    <Routes>
      <Route index element={<SolicitacoesClientesPage />} />
      <Route path=":solicitacaoClienteId" element={<DetalhesSolicitacaoClientePage />} />
      <Route path=":solicitacaoClienteId/transacoes/:transacaoId" element={<DetalhesTransacaoPage />} />
    </Routes>
  );
};

export default SolicitacoesClientesRoutes;

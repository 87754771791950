import React from 'react';

import BSButton from 'react-bootstrap/Button';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Form } from '../../components/form/form';
import { FormControl, MasksFormControlEnum } from '../../components/form/form-control';
import { ClassTypesEnum } from '../../helpers';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch } from '../../store/hooks-redux';
import { updateProduto } from './produtos.redux';

type AtualizarLimitesValoresProdutoFormFields = {
  limiteCreditoMaximoPorConta: number;
  limiteCreditoMinimoPorConta: number;
  limiteCreditoPadrao: number;
  valorMinimoEnvioFatura: number;
  saldoMinimoParaCobranca: number;
  valorToleranciaSaldoDevido: number;
};

type AtualizarLimitesValoresProdutoTabProps = {
  produto: any;
};

const AtualizarLimitesValoresProdutoTab: React.FC<AtualizarLimitesValoresProdutoTabProps> = ({ produto }) => {
  const dispatch = useAppDispatch();
  const { showToast } = useToasts();
  const navigate = useNavigate();

  const form = useForm<AtualizarLimitesValoresProdutoFormFields>({
    defaultValues: {
      limiteCreditoMaximoPorConta: Number(produto.limiteCreditoMaximoPorConta),
      limiteCreditoMinimoPorConta: Number(produto.limiteCreditoMinimoPorConta),
      limiteCreditoPadrao: Number(produto.limiteCreditoPadrao),
      valorMinimoEnvioFatura: Number(produto.valorMinimoEnvioFatura),
      saldoMinimoParaCobranca: Number(produto.saldoMinimoParaCobranca),
      valorToleranciaSaldoDevido: Number(produto.valorToleranciaSaldoDevido),
    },
  });
  const { control } = form;

  const onSubmitHandler = (data: AtualizarLimitesValoresProdutoFormFields) => {
    if (Object.keys(data).length === 0) {
      return;
    }

    dispatch(updateProduto({ params: { produtoId: produto._id }, data }))
      .then(() => {
        showToast({
          message: 'Produto atualizado com sucesso',
          type: ClassTypesEnum.SUCCESS,
        });

        return navigate('/produtos/');
      })
      .catch((error: Error) => {
        showToast({
          message: error.message,
          type: ClassTypesEnum.DANGER,
        });
      });
  };

  return (
    <>
      <Form form={form} id="form" onSubmit={onSubmitHandler}>
        <div className="row">
          <FormControl
            control={control}
            className="col-xl-4 col-md-6 col-sm-12 mb-3"
            label="Limite de crédito máximo por conta"
            name="limiteCreditoMaximoPorConta"
            mask={MasksFormControlEnum.BRL}
          />

          <FormControl
            control={control}
            className="col-xl-4 col-md-6 col-sm-12 mb-3"
            label="Limite de crédito mínimo por conta"
            name="limiteCreditoMinimoPorConta"
            mask={MasksFormControlEnum.BRL}
          />

          <FormControl
            control={control}
            className="col-xl-4 col-md-6 col-sm-12 mb-3"
            label="Limite de crédito padrão"
            name="limiteCreditoPadrao"
            mask={MasksFormControlEnum.BRL}
          />

          <FormControl
            control={control}
            className="col-xl-4 col-md-6 col-sm-12 mb-3"
            label="Valor mínimo para envio da fatura"
            name="valorMinimoEnvioFatura"
            type="number"
            mask={MasksFormControlEnum.BRL}
          />

          <FormControl
            control={control}
            className="col-xl-4 col-md-6 col-sm-12 mb-3"
            label="Valor mínimo para cobrança"
            name="saldoMinimoParaCobranca"
            mask={MasksFormControlEnum.BRL}
          />

          <FormControl
            control={control}
            className="col-xl-4 col-md-6 col-sm-12 mb-3"
            label="Valor de tolerância para saldo devido"
            name="valorToleranciaSaldoDevido"
            mask={MasksFormControlEnum.BRL}
          />

          <div className="d-flex justify-content-end">
            <BSButton variant="primary" type="submit" form="form">
              Atualizar
            </BSButton>
          </div>
        </div>
      </Form>
    </>
  );
};
export default AtualizarLimitesValoresProdutoTab;

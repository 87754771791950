import { useCallback, useMemo } from 'react';
import BSCard from 'react-bootstrap/Card';
import BSForm from 'react-bootstrap/Form';
import { useSelector } from 'react-redux';

import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import ConfirmationDialog from '../../components/dialog/confirmation-dialog';
import RbacElement from '../../components/role-based-access-control/role-based-access-control-element';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import { SmartTable } from '../../components/smart-table/smart-table';
import SmartTableButton from '../../components/smart-table/smart-table-button';
import SmartTableFilters from '../../components/smart-table/smart-table-filters/smart-table-filters';
import {
  SmartTableFilterControlTypesEnum,
  SmartTableFilterProps,
} from '../../components/smart-table/smart-table-filters/table-filter.types';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import Title from '../../components/title';
import { ClassTypesEnum, formatBool } from '../../helpers';
import { DialogProps } from '../../hooks/dialog/dialog-context';
import { useDialog } from '../../hooks/dialog/use-dialog';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch } from '../../store/hooks-redux';
import { selectSessionUsuario } from '../session-usuario/session-usuario.redux';
import CadastrarCredencialClienteDialog from './cadastrar-credencial-cliente.dialog';
import {
  loadCredenciaisCliente,
  selectCredenciaisClienteByFilters,
  selectCredenciaisClienteLoadingStateByFilters,
  selectTotalCredenciaisClienteByFilters,
  updateCredencialCliente,
} from './credenciais-cliente.redux';

const smartFilters: SmartTableFilterProps[] = [
  {
    label: 'Nome',
    attribute: 'nome',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
  },
  {
    label: 'Ativo',
    attribute: 'ativo',
    controlType: SmartTableFilterControlTypesEnum.BOOL_RADIO,
  },
];

const smartColumns = ({
  sessionUsuario,
  showDialog,
  closeDialog,
  onUpdateCredencialClienteHandler,
}: {
  sessionUsuario: any;
  showDialog: (dialogProps: DialogProps) => void;
  closeDialog: () => void;
  onUpdateCredencialClienteHandler: (credencialClienteId: string, data: any) => void;
}): SmartTableColumnProps[] => [
  {
    label: 'Nome',
    attribute: 'nome',
    className: 'text-center',
  },
  {
    label: 'Ativo',
    className: 'text-center',
    format: (_, clientCredential) => {
      if (!sessionUsuario.papelUsuario?.permissoes?.ALTERACAO_CLIENT_CREDENTIALS) {
        return <div className="text-center">{formatBool(clientCredential?.ativo)}</div>;
      }

      return (
        <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.ALTERACAO_CLIENT_CREDENTIALS}>
          <BSForm.Switch
            checked={clientCredential.ativo}
            name="ativo"
            onChange={() => {
              showDialog({
                component: (
                  <ConfirmationDialog
                    onConfirmation={() =>
                      onUpdateCredencialClienteHandler(clientCredential._id, { ativo: !clientCredential.ativo })
                    }
                    message={`Tem certeza que deseja alterar o status de ativação do usuário: ${clientCredential.nome}?`}
                    confirmationLabel="Alterar"
                    title="Alterar ativação do usuário"
                    onHide={closeDialog}
                  />
                ),
              });
            }}
          />
        </RbacElement>
      );
    },
  },
];

const CredenciaisClientePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { query } = useQuerystring();
  const { closeDialog, showDialog } = useDialog();
  const { showToast } = useToasts();

  const [sessionUsuario] = useSelector((state) => selectSessionUsuario(state, {})) ?? [];

  const filters = useMemo(() => ({ ...query }), [query]);
  const total = useSelector((state) => selectTotalCredenciaisClienteByFilters(state, filters));
  const clients = useSelector((state) => selectCredenciaisClienteByFilters(state, filters));
  const loadingState = useSelector((state) => selectCredenciaisClienteLoadingStateByFilters(state, filters));

  const loadItems = useCallback(
    () => dispatch(loadCredenciaisCliente({ query })).catch((error: Error) => error),
    [dispatch, query]
  );

  const onNovoCredencialClienteHandler = () => {
    showDialog({
      component: <CadastrarCredencialClienteDialog closeDialog={closeDialog} reload={loadItems} />,
    });
  };
  const onUpdateCredencialClienteHandler = (credencialClienteId: string, data: any) => {
    if (!data) {
      return;
    }

    dispatch(updateCredencialCliente({ params: { credencialClienteId }, data }))
      .then(() => {
        closeDialog();

        showToast({
          message: 'Ativação do client credential atualizada com sucesso',
          type: ClassTypesEnum.SUCCESS,
        });

        return loadItems();
      })
      .catch((error: Error) => {
        showToast({
          message: error.message,
          type: ClassTypesEnum.DANGER,
        });
      });
  };

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_CLIENT_CREDENTIALS}>
      <div className="d-flex flex-column h-100">
        <Title>Clients</Title>

        <div className="row">
          <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-12 mb-4">
            <SmartTableFilters filters={smartFilters} />
          </div>

          <div className="col-xxl-10 col-xl-9 col-lg-8 col-md-12 mb-4">
            <BSCard>
              <BSCard.Body>
                <div className="d-flex align-items-center mb-2">
                  <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.CRIACAO_CLIENT_CREDENTIALS}>
                    <SmartTableButton onClick={onNovoCredencialClienteHandler}>
                      Novo client credential{' '}
                    </SmartTableButton>
                  </RbacElement>
                </div>

                <SmartTable
                  emptyMessage="Nenhum client credential encontrado"
                  errorMessage="Erro na listagem de clients credentials"
                  usePagination={true}
                  loadItems={loadItems}
                  columns={smartColumns({
                    sessionUsuario,
                    closeDialog,
                    showDialog,
                    onUpdateCredencialClienteHandler,
                  })}
                  items={clients}
                  loadingState={loadingState}
                  size={total}
                />
              </BSCard.Body>
            </BSCard>
          </div>
        </div>
      </div>
    </RbacPage>
  );
};

export default CredenciaisClientePage;

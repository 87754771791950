import { useEffect } from 'react';
import BSBadge from 'react-bootstrap/Badge';

import { SmartTable } from '../../components/smart-table/smart-table';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import { ClassTypesEnum, FormatValueEnum } from '../../helpers';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import {
  loadCategoriasBeneficios,
  selectObjectTodasCategoriasBeneficiosById,
} from '../categorias-beneficio/categorias.beneficio.redux';
import { selectContaCartaoById } from './contas-cartao.redux';

const smartColumns = ({ categoriasBeneficios }: { categoriasBeneficios: any }): SmartTableColumnProps[] => [
  {
    label: 'Categoria',
    attribute: 'categoria',
    className: 'text-center',
    format: (categoria: keyof typeof categoriasBeneficios) => categoriasBeneficios[categoria]?.nome,
  },
  {
    label: 'Limite de crédito disponível',
    attribute: 'limiteCreditoDisponivel',
    className: 'text-center',
    format: FormatValueEnum.BRL,
  },
  {
    label: 'Limite de crédito intransferível',
    attribute: 'limiteCreditoIntransferivel',
    className: 'text-center',
    format: FormatValueEnum.BRL,
  },
  {
    label: 'Status',
    attribute: 'ativo',
    className: 'text-center',
    format: (ativo: boolean) => {
      return (
        <h5 className="m-0">
          <BSBadge pill bg={ativo ? ClassTypesEnum.SUCCESS : ClassTypesEnum.SECONDARY}>
            {ativo ? 'Ativo' : 'Inativo'}
          </BSBadge>
        </h5>
      );
    },
  },
];

type BeneficiosContaCartaoTabProps = {
  contaCartaoId: string;
};

const BeneficiosContaCartaoTab: React.FC<BeneficiosContaCartaoTabProps> = ({ contaCartaoId }) => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const { maxItemsQuery } = useQuerystring();

  const contaCartao = useSelector((state) => selectContaCartaoById(state, contaCartaoId));
  const categoriasBeneficios = useSelector((state) => selectObjectTodasCategoriasBeneficiosById(state));

  useEffect(() => {
    dispatch(loadCategoriasBeneficios({ query: maxItemsQuery })).catch((error: Error) => error);
  }, [dispatch, maxItemsQuery]);

  return (
    <SmartTable
      emptyMessage="Nenhum limite de benefício encontrado"
      errorMessage="Erro ao listar limites de benefícios"
      columns={smartColumns({ categoriasBeneficios })}
      items={contaCartao.beneficios}
      usePagination={false}
    />
  );
};

export default BeneficiosContaCartaoTab;

import { reducerFactory, actionFactory, selectorFactory } from '../../store';

const context = 'transacoes';

const {
  loadMany,
  loadOne,
  updateOne: updateOneCancelamento,
} = actionFactory(context, {
  buildUrlLoadMany: () => '/api/transacoes',
  buildUrlLoadOne: ({ transacaoId }) => `/api/transacoes/${transacaoId}`,
  buildUrlUpdateOne: ({ transacaoId }) => `/api/transacoes/${transacaoId}/cancelamento`,
});

const { loadMany: loadManyFatura } = actionFactory(context, {
  buildUrlLoadMany: ({ faturaId }) => `/api/faturas/${faturaId}/transacoes`,
});

const { loadMany: loadManyCanceladas } = actionFactory(context, {
  buildUrlLoadMany: ({ contaCartaoId }) => `/api/contas-cartao/${contaCartaoId}/transacoes-canceladas`,
});

const { loadMany: loadManyFuturas } = actionFactory(context, {
  buildUrlLoadMany: ({ contaCartaoId }) => `/api/contas-cartao/${contaCartaoId}/transacoes-futuras`,
});

const { loadMany: loadManyOriginadas } = actionFactory(context, {
  buildUrlLoadMany: ({ transacaoId }) => `/api/transacoes/${transacaoId}/transacoes-originadas`,
});

const { loadMany: loadManyPendentes } = actionFactory(context, {
  buildUrlLoadMany: ({ contaCartaoId }) => `/api/contas-cartao/${contaCartaoId}/transacoes-pendentes`,
});

const { loadMany: loadManyContaCartao } = actionFactory(context, {
  buildUrlLoadMany: ({ contaCartaoId }) => `/api/contas-cartao/${contaCartaoId}/transacoes`,
});

const { updateOne: updateOneAntecipacao } = actionFactory(context, {
  buildUrlUpdateOne: ({ transacaoId }) => `/api/transacoes/${transacaoId}/antecipacao-parcelas`,
});

const {
  selectOneById,
  selectAll,
  selectManyByFilters,
  selectTotalByFilters,
  selectLoadingStateByFilters,
  selectObjectAllIds,
} = selectorFactory({
  context,
});

export const anteciparTransacoes = updateOneAntecipacao;
export const cancelarTransacao = updateOneCancelamento;

export const loadTransacoesCanceladas = loadManyCanceladas;
export const loadTransacoesFatura = loadManyFatura;
export const loadTransacoesFuturas = loadManyFuturas;
export const loadTransacoesOriginadas = loadManyOriginadas;
export const loadTransacoesPendentes = loadManyPendentes;
export const loadTransacoesContaCartao = loadManyContaCartao;

export const loadTransacoes = loadMany;
export const loadTransacao = loadOne;

export const selectTransacaoById = selectOneById;
export const selectTodasTransacoes = selectAll;
export const selectObjectTodasTransacoes = selectObjectAllIds;
export const selectTransacoesByFilters = selectManyByFilters;
export const selectTotalTransacoesByFilters = selectTotalByFilters;
export const selectTransacoesLoadingStateByFilters = selectLoadingStateByFilters;

export const transacoes = reducerFactory({ context });

type Props = {
  children: React.ReactNode;
};

const DetailSubTitle: React.FC<Props> = ({ children }) => {
  return (
    <h5 data-testid="detail-subtitle" className="text-primary mb-0">
      {children}
    </h5>
  );
};

export default DetailSubTitle;

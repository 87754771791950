import { useEffect, useMemo } from 'react';
import BSBadge from 'react-bootstrap/Badge';
import BSButton from 'react-bootstrap/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { DetailCard } from '../../components/details/datail-card';
import DetailElement from '../../components/details/detail-element';
import DetailTitle from '../../components/details/detail-title';
import ConfirmationDialog from '../../components/dialog/confirmation-dialog';
import { Loading } from '../../components/loading';
import Page from '../../components/page';
import { ClassTypesEnum, FormatValueEnum, formatEnum, statusSolicitacaoParcelamentoLabelMap } from '../../helpers';
import { useDialog } from '../../hooks/dialog/use-dialog';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import {
  loadSolicitacaoParcelamento,
  selectSolicitacaoParcelamentoById,
  selectSolicitacoesParcelamentoLoadingStateByFilters,
  updateSolicitacaoParcelamento,
} from './solicitacoes-parcelamento.redux';

const DetalhesSolicitacaoParcelamentoPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const { showToast } = useToasts();
  const { showDialog, closeDialog } = useDialog();
  const navigate = useNavigate();

  const params = useParams();
  const solicitacaoParcelamentoId = params.solicitacaoParcelamentoId as string;
  const faturaId = params.faturaId as string;
  const contaCartaoId = params.contaCartaoId as string;

  const filters = useMemo(() => ({ solicitacaoParcelamentoId }), [solicitacaoParcelamentoId]);
  const solicitacaoParcelamento = useSelector((state) =>
    selectSolicitacaoParcelamentoById(state, solicitacaoParcelamentoId)
  );
  const loadingState = useSelector((state) => selectSolicitacoesParcelamentoLoadingStateByFilters(state, filters));

  const onCancelarSolicitacaoConfirmationHandler = () => {
    dispatch(updateSolicitacaoParcelamento({ params: { solicitacaoParcelamentoId }, data: {} }))
      .then(() => {
        showToast({
          message: 'Solicitacao de parcelamento cancelada com sucesso!',
          type: ClassTypesEnum.SUCCESS,
        });

        return navigate(`/contas-cartao/${contaCartaoId}/faturas/${faturaId}`);
      })
      .catch((error: Error) =>
        showToast({
          message: error.message,
          type: ClassTypesEnum.DANGER,
        })
      );
  };

  useEffect(() => {
    dispatch(loadSolicitacaoParcelamento({ solicitacaoParcelamentoId })).catch((error: Error) => error);
  }, [dispatch, solicitacaoParcelamentoId]);

  if (!solicitacaoParcelamento) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Loading notFoundMessage="Solicitação de parcelamento não encontrada" loadingState={loadingState} />
      </div>
    );
  }

  return (
    <Page>
      <DetailCard>
        <div className="row">
          <div className="col-md-8 col-sm-12 mb-3">
            <DetailTitle>Solicitação de parcelamento de fatura</DetailTitle>
          </div>

          <div className="col-md-4 col-sm-12 text-md-end text-sm-start mb-5">
            <h5 className="m-0">
              {/* TODO: enum color */}
              <BSBadge pill bg={ClassTypesEnum.PRIMARY}>
                {formatEnum(solicitacaoParcelamento.status, statusSolicitacaoParcelamentoLabelMap)}
              </BSBadge>
            </h5>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-lg-12 col-md-12 mb-4">
            <DetailElement
              descricao="Data da solicitação"
              valor={solicitacaoParcelamento.dataSolicitacao}
              format={FormatValueEnum.DATE_TIME}
            />
            <DetailElement descricao="Número de parcelas" valor={solicitacaoParcelamento.numeroParcelas} />
            <DetailElement
              descricao="Valor parcelado"
              valor={solicitacaoParcelamento.valorBaseParcelamento}
              format={FormatValueEnum.BRL}
            />
            <DetailElement
              descricao="Taxa mensal de juros"
              valor={solicitacaoParcelamento.taxaMensalJuros}
              format={FormatValueEnum.PERCENT}
            />
            <DetailElement
              descricao="Valor de entrada"
              valor={solicitacaoParcelamento.valorEntrada}
              format={FormatValueEnum.BRL}
            />
            <DetailElement
              descricao="Valor primeira parcela"
              valor={solicitacaoParcelamento.valorPrimeiraParcela}
              format={FormatValueEnum.BRL}
            />
            <DetailElement
              descricao="Valor demais parcelas"
              valor={solicitacaoParcelamento.valorSegundaParcela}
              format={FormatValueEnum.BRL}
            />
            <DetailElement
              descricao="Total parcelamento"
              valor={solicitacaoParcelamento.valorTotalParcelamento}
              format={FormatValueEnum.BRL}
            />
          </div>
        </div>

        {solicitacaoParcelamento.status === 'P' && (
          <div className="d-flex justify-content-end flex-wrap">
            <BSButton
              onClick={() => {
                showDialog({
                  component: (
                    <ConfirmationDialog
                      onConfirmation={onCancelarSolicitacaoConfirmationHandler}
                      onHide={closeDialog}
                      confirmationLabel="Cancelar solicitação de parcelamento de fatura"
                      message="Tem certeza que deseja cancelar a solicitação de parcelamento da fatura?"
                      title="Cancelar solicitação de parcelamento"
                    />
                  ),
                });
              }}
            >
              Cancelar solicitação
            </BSButton>
          </div>
        )}
      </DetailCard>
    </Page>
  );
};

export default DetalhesSolicitacaoParcelamentoPage;

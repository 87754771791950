import { actionFactory, reducerFactory, selectorFactory } from '../../store';

const context = 'clientesPreAprovadosProduto';

const { createOne, deleteOne, loadMany } = actionFactory(context, {
  buildUrlCreateOne: () => '/api/clientes-pre-aprovados-produto',
  buildUrlLoadMany: () => '/api/clientes-pre-aprovados-produto',
  buildUrlDeleteOne: ({ clientePreAprovadoProdutoId }) =>
    `/api/clientes-pre-aprovados-produto/${clientePreAprovadoProdutoId}`,
});

const { selectManyByFilters, selectTotalByFilters, selectLoadingStateByFilters } = selectorFactory({
  context,
});

export const deleteClientePreAprovadoProduto = deleteOne;
export const loadClientesPreAprovadosProduto = loadMany;
export const saveClientePreAprovadoProduto = createOne;

export const selectClientePreAprovadoProdutoByFilters = selectManyByFilters;
export const selectTotalClientePreAprovadoProdutoByFilters = selectTotalByFilters;
export const selectClientePreAprovadoProdutoLoadingStateByFilters = selectLoadingStateByFilters;

export const clientesPreAprovadosProduto = reducerFactory({ context });

import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import { useCallback, useMemo } from 'react';
import BSCard from 'react-bootstrap/Card';
import { useNavigate } from 'react-router-dom';
import Title from '../../components/details/detail-title';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import { SmartTable } from '../../components/smart-table/smart-table';
import SmartTableFilters from '../../components/smart-table/smart-table-filters/smart-table-filters';
import {
  SmartTableFilterControlTypesEnum,
  SmartTableFilterProps,
} from '../../components/smart-table/smart-table-filters/table-filter.types';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import { trimestreLabelMap } from '../../helpers';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import { loadQMRs, selectQMRsLoadingStateByFilters, selectQMRsporFiltro, selectTotalQMRsByFilters } from './qmr.redux';

const smartFilters = (): SmartTableFilterProps[] => [
  {
    attribute: 'numeroTrimestre',
    label: 'Número do Trimestre',
    controlType: SmartTableFilterControlTypesEnum.SELECT,
    map: trimestreLabelMap,
  },
  {
    attribute: 'ano',
    label: 'Ano',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
  },
];

const smartColumns = (): SmartTableColumnProps[] => [
  {
    label: 'Número do Trimestre',
    attribute: 'numeroTrimestre',
    className: 'text-center',
  },
  {
    label: 'Ano',
    attribute: 'ano',
    className: 'text-center',
  },
];
const QmrPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const { query } = useQuerystring();

  const filters = useMemo(() => ({ ...query }), [query]);
  const qmrs = useSelector((state) => selectQMRsporFiltro(state, filters));
  const total = useSelector((state) => selectTotalQMRsByFilters(state, filters));
  const loadingState = useSelector((state) => selectQMRsLoadingStateByFilters(state, filters));

  const loadItems = useCallback(() => {
    dispatch(loadQMRs({ query: filters })).catch((error: Error) => error);
  }, [dispatch, filters]);

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_QMR}>
      <div className="d-flex flex-column h-100">
        <Title>QMR</Title>
        <div className="row p-5">
          <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-12 mb-4">
            <SmartTableFilters filters={smartFilters()} />
          </div>
          <div className="col-xxl-10 col-xl-9 col-lg-8 col-md-12 mb-4">
            <BSCard>
              <BSCard.Body>
                <SmartTable
                  emptyMessage="Nenhum QMR encontrado"
                  errorMessage="Erro ao listar QMR's"
                  usePagination={true}
                  loadItems={loadItems}
                  columns={smartColumns()}
                  items={qmrs}
                  loadingState={loadingState}
                  onRowClick={(doc) => navigate(`/qmr/${doc._id}`)}
                  size={total}
                />
              </BSCard.Body>
            </BSCard>
          </div>
        </div>
      </div>
    </RbacPage>
  );
};

export default QmrPage;

import { TipoCartaoEnum } from '@tamborineapps/lib-enums';
import React from 'react';
import BSButton from 'react-bootstrap/Button';
import BSModal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { Form } from '../../components/form/form';
import { FormCheck } from '../../components/form/form-check';
import { formatCartao } from '../../helpers';

type AlterarPermissoesCartaoFormFields = {
  permiteCompraOnlineNacional: boolean;
  permiteCompraOnlineInternacional: boolean;
  permiteCompraInternacional: boolean;
  permiteCompraContactless: boolean;
  permiteUsarOverlimit: boolean;
};

type AlterarPermissoesCartaoDialogProps = {
  cartao: any;
  closeDialog: () => void;
  onSubmitHandler: (cartaoId: string, data: any) => void;
};

const AlterarPermissoesCartaoDialog: React.FC<AlterarPermissoesCartaoDialogProps> = ({
  cartao,
  closeDialog,
  onSubmitHandler,
}) => {
  const defaultValues: AlterarPermissoesCartaoFormFields = {
    permiteCompraOnlineNacional: cartao.permiteCompraOnlineNacional,
    permiteCompraOnlineInternacional: cartao.permiteCompraOnlineInternacional,
    permiteCompraInternacional: cartao.permiteCompraInternacional,
    permiteCompraContactless: cartao.permiteCompraContactless,
    permiteUsarOverlimit: cartao.permiteUsarOverlimit,
  };

  const form = useForm<AlterarPermissoesCartaoFormFields>({ defaultValues });
  const { control } = form;

  const ehCartaoVirtual = cartao?.tipoCartao === TipoCartaoEnum.VIRTUAL;

  return (
    <>
      <BSModal.Header closeButton>
        <BSModal.Title>Alterar permissões de cartão - {formatCartao(cartao.numeroTruncado)}</BSModal.Title>
      </BSModal.Header>

      <BSModal.Body>
        <Form
          id="form"
          form={form}
          onSubmit={(data: AlterarPermissoesCartaoFormFields) => onSubmitHandler(cartao._id, data)}
          submitPristine
        >
          <FormCheck
            control={control}
            className="mb-3"
            name="permiteCompraOnlineNacional"
            label="Permite compra online nacional"
            type="switch"
          />

          <FormCheck
            control={control}
            className="mb-3"
            name="permiteCompraOnlineInternacional"
            label="Permite compra online internacional"
            type="switch"
          />

          <FormCheck
            control={control}
            className="mb-3"
            name="permiteCompraInternacional"
            label="Permite compra internacional"
            type="switch"
            disabled={ehCartaoVirtual}
          />

          <FormCheck
            control={control}
            className="mb-3"
            name="permiteCompraContactless"
            label="Permite compra contactless"
            type="switch"
            disabled={ehCartaoVirtual}
          />

          <FormCheck
            control={control}
            className="mb-3"
            name="permiteUsarOverlimit"
            label="Permite utilizar overlimit"
            type="switch"
          />
        </Form>
      </BSModal.Body>

      <BSModal.Footer>
        <BSButton variant="light" onClick={closeDialog}>
          Fechar
        </BSButton>
        <BSButton variant="primary" type="submit" form="form">
          Alterar permissões
        </BSButton>
      </BSModal.Footer>
    </>
  );
};

export default AlterarPermissoesCartaoDialog;

import { reducerFactory, actionFactory, selectorFactory } from '../../store';

const context = 'produtos';

const { loadMany, loadOne, updateOne } = actionFactory(context, {
  buildUrlLoadMany: () => '/api/produtos',
  buildUrlLoadOne: ({ produtoId }) => `/api/produtos/${produtoId}`,
  buildUrlUpdateOne: ({ produtoId }) => `/api/produtos/${produtoId}`,
});

const { updateOne: updateOneIncluirOperacaoPermitida } = actionFactory(context, {
  buildUrlUpdateOne: ({ produtoId }) => `/api/produtos/${produtoId}/operacoes-permitidas`,
});

const { updateOne: updateOneRemoverOperacaoPermitida } = actionFactory(context, {
  buildUrlUpdateOne: ({ produtoId, operacao }) => `/api/produtos/${produtoId}/operacoes-permitidas/${operacao}`,
});

const {
  selectOneById,
  selectAll,
  selectManyByFilters,
  selectTotalByFilters,
  selectLoadingStateByFilters,
  selectObjectAllIds,
} = selectorFactory({
  context,
});

export const loadProduto = loadOne;
export const loadProdutos = loadMany;
export const updateProduto = updateOne;
export const incluirOperacaoPermitidaProduto = updateOneIncluirOperacaoPermitida;
export const removerOperacaoPermitidaProduto = updateOneRemoverOperacaoPermitida;

export const selectProdutoById = selectOneById;
export const selectTodosProdutos = selectAll;
export const selectObjectTodosProdutos = selectObjectAllIds;
export const selectProdutosByFilters = selectManyByFilters;
export const selectTotalProdutosByFilters = selectTotalByFilters;
export const selectProdutosLoadingStateByFilters = selectLoadingStateByFilters;

export const produtos = reducerFactory({ context });

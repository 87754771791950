import React, { useCallback, useMemo } from 'react';
import BSCard from 'react-bootstrap/Card';
import { BsPencilSquare } from 'react-icons/bs';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import IconButton from '../../components/button/icon-button';
import RbacElement from '../../components/role-based-access-control/role-based-access-control-element';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import { SmartTable } from '../../components/smart-table/smart-table';
import SmartTableButton from '../../components/smart-table/smart-table-button';
import SmartTableFilters from '../../components/smart-table/smart-table-filters/smart-table-filters';
import {
  SmartTableFilterControlTypesEnum,
  SmartTableFilterProps,
} from '../../components/smart-table/smart-table-filters/table-filter.types';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import Title from '../../components/title';
import { ClassTypesEnum } from '../../helpers';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import {
  loadPapeisUsuarios,
  selectPapeisUsuariosByFilters,
  selectPapeisUsuariosLoadingStateByFilters,
  selectTotalPapeisUsuariosByFilters,
} from './papeis-usuarios.redux';

const smartFilters: SmartTableFilterProps[] = [
  {
    label: 'Descrição',
    attribute: 'descricao',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
  },
];

const smartColumns = (navigate: NavigateFunction): SmartTableColumnProps[] => [
  {
    label: 'Descricao',
    attribute: 'descricao',
    sortable: true,
    className: 'text-center',
  },
  {
    label: 'Ações',
    format: (_, doc) => {
      return (
        <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.ALTERACAO_PAPEL_USUARIO}>
          <div className="d-flex justify-content-center">
            <div className="m1">
              <IconButton
                Icon={BsPencilSquare}
                type={ClassTypesEnum.PRIMARY}
                onClick={() => {
                  navigate(`${doc._id}/atualizar`);
                }}
              />
            </div>
          </div>
        </RbacElement>
      );
    },
  },
];

const PapeisUsuariosPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const { query } = useQuerystring();
  const navigate = useNavigate();

  if (!query.sort) {
    query.sort = 'descricao';
  }

  const filters = useMemo(() => ({ ...query }), [query]);
  const total = useSelector((state) => selectTotalPapeisUsuariosByFilters(state, filters));
  const papeisUsuarios = useSelector((state) => selectPapeisUsuariosByFilters(state, filters));
  const loadingState = useSelector((state) => selectPapeisUsuariosLoadingStateByFilters(state, filters));

  const loadItems = useCallback(() => {
    dispatch(loadPapeisUsuarios({ query: filters })).catch((error: Error) => error);
  }, [dispatch, filters]);

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_PAPEL_USUARIO}>
      <div className="d-flex flex-column h-100">
        <Title>Papéis de usuários</Title>

        <div className="row">
          <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-12 mb-4">
            <SmartTableFilters filters={smartFilters} />
          </div>

          <div className="col-xxl-10 col-xl-9 col-lg-8 col-md-12 mb-4">
            <BSCard>
              <BSCard.Body>
                <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.CRIACAO_PAPEL_USUARIO}>
                  <div className="d-flex align-items-center mb-2">
                    <SmartTableButton onClick={() => navigate('cadastrar')}>Novo papel de usuário</SmartTableButton>
                  </div>
                </RbacElement>
                <SmartTable
                  emptyMessage="Nenhum papel de usuário encontrado"
                  errorMessage="Erro na listagem de papéis de usuários"
                  usePagination={true}
                  loadItems={loadItems}
                  columns={smartColumns(navigate)}
                  items={papeisUsuarios}
                  loadingState={loadingState}
                  size={total}
                  onRowClick={(doc) => navigate(doc._id)}
                />
              </BSCard.Body>
            </BSCard>
          </div>
        </div>
      </div>
    </RbacPage>
  );
};

export default PapeisUsuariosPage;

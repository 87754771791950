import { useCallback, useEffect, useMemo, useState } from 'react';
import BSBadge from 'react-bootstrap/Badge';
import BSCard from 'react-bootstrap/Card';
import { Link, useParams } from 'react-router-dom';

import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import { DetailCard } from '../../components/details/datail-card';
import DetailElement from '../../components/details/detail-element';
import DetailSubTitle from '../../components/details/detail-subtitle';
import DetailTitle from '../../components/details/detail-title';
import { Loading } from '../../components/loading';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import {
  ApiSingleElementResponse,
  ClassTypesEnum,
  FormatValueEnum,
  clienteRename,
  sexoLabelMap,
  tipoEnderecoLabelMap,
  tipoPessoaLabelMap,
  tipoTelefoneLabelMap,
} from '../../helpers';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import { loadCliente, selectClienteById } from '../clientes/clientes.redux';
import { loadHistoricoNoDiaCliente } from './historico-cliente-dia.redux';
import {
  loadHistorico,
  selectHistoricoClienteById,
  selectHistoricoClienteLoadingStateByFilters,
} from './historico-cliente.redux';

const DetalhesHistoricoClientePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const params = useParams();
  const historicoClienteId = params.historicoClienteId as string;

  const [dadosAnteriores, setDadosAnteriores] = useState<any>();

  const filters = useMemo(() => ({ historicoClienteId }), [historicoClienteId]);
  const historicoCliente = useSelector((state) => selectHistoricoClienteById(state, historicoClienteId));
  const loadingState = useSelector((state) => selectHistoricoClienteLoadingStateByFilters(state, filters));
  const cliente = useSelector((state) => selectClienteById(state, historicoCliente?.clienteId));

  const loadEntidadesComplementares = useCallback(
    (historico: any) => {
      dispatch(loadCliente({ clienteId: historico.clienteId })).catch((error: Error) => error);
      dispatch(loadHistoricoNoDiaCliente({ historicoClienteId }))
        .then(({ payload: { data } }: ApiSingleElementResponse) => setDadosAnteriores(data))
        .catch((error: Error) => error);
    },
    [dispatch, historicoClienteId]
  );

  const _loadHistorico = useCallback(
    () =>
      dispatch(loadHistorico({ historicoClienteId }))
        .then(({ payload: { data } }: ApiSingleElementResponse) => loadEntidadesComplementares(data))
        .catch((error: Error) => error),
    [dispatch, historicoClienteId, loadEntidadesComplementares]
  );

  useEffect(() => {
    _loadHistorico();
  }, [_loadHistorico]);

  function filterSameKeys(entidade: any, alteracao: any) {
    if (!entidade || !alteracao) {
      return {};
    }

    const keysAlteracao = Object.keys(alteracao);

    return Object.entries(entidade).reduce((sameKeys, entry) => {
      const [chave, valor] = entry;

      if (keysAlteracao.includes(chave)) {
        return { ...sameKeys, [chave]: valor };
      }

      return sameKeys;
    }, {});
  }

  const renderDetailElement = (tipoEntidade: string, atributo: string, valor: any): JSX.Element | undefined => {
    const nomeAtributo = clienteRename[atributo as keyof typeof clienteRename];

    if (!nomeAtributo) {
      return undefined;
    }

    function isElement() {
      return <DetailElement key={atributo} descricao={nomeAtributo} valor={valor} />;
    }

    function isCpfCnpj() {
      return <DetailElement key={atributo} descricao={nomeAtributo} valor={valor} format={FormatValueEnum.CPF_CNPJ} />;
    }

    function isDataNascimento() {
      return <DetailElement key={atributo} descricao={nomeAtributo} valor={valor} format={FormatValueEnum.DATA} />;
    }

    function isEhDeficienteVisual() {
      return <DetailElement key={atributo} descricao={nomeAtributo} valor={valor} format={FormatValueEnum.BOOL} />;
    }

    function isSexo() {
      return (
        <DetailElement
          key={atributo}
          descricao={nomeAtributo}
          valor={valor}
          format={FormatValueEnum.ENUM}
          map={sexoLabelMap}
        />
      );
    }

    function isTipo() {
      return (
        <DetailElement
          key={atributo}
          descricao={nomeAtributo}
          valor={valor}
          format={FormatValueEnum.ENUM}
          map={enumTipos}
        />
      );
    }

    function isTermosVigentes() {
      if (valor.length === 0 || !valor) {
        return;
      }

      return valor?.map((termo: any) => (
        <>
          <DetailElement descricao="Contrato" valor="Mais" link={`/contratos-termos-uso/${termo?.contratoId}`} />
          <DetailElement
            descricao="Início da Pendência"
            valor={termo?.dataHora?.inicioPendencia}
            format={FormatValueEnum.DATE_TIME}
          />
          <DetailElement descricao="Aceite" valor={termo?.dataHora?.aceite} format={FormatValueEnum.DATE_TIME} />
          <DetailElement descricao="Pendente" valor={termo?.pendente} format={FormatValueEnum.BOOL} />
          <DetailElement descricao="Tipo" valor={termo?.tipo} />
        </>
      ));
    }

    const enumTipos = {
      cliente: tipoPessoaLabelMap,
      endereco: tipoEnderecoLabelMap,
      telefone: tipoTelefoneLabelMap,
    }[tipoEntidade];

    const cases = {
      cpfCnpj: isCpfCnpj,
      dataNascimento: isDataNascimento,
      ehDeficienteVisual: isEhDeficienteVisual,
      sexo: isSexo,
      tipo: isTipo,
      termosVigentes: isTermosVigentes,
    };

    return Reflect.has(cases, atributo) ? cases[atributo as keyof typeof cases]() : isElement();
  };

  if (!historicoCliente) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Loading notFoundMessage="Registro de histórico não econtrado" loadingState={loadingState} />
      </div>
    );
  }

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_HISTORICO_CLIENTE}>
      <div className="mb-5">
        <DetailCard>
          <div className="row mb-5">
            <div className="col-md-8 col-sm-12">
              <DetailTitle>
                Registro de histórico de alteração
                {Boolean(cliente) && (
                  <>
                    {' '}
                    - <Link to={`/clientes/${cliente._id}`}>{cliente.nome}</Link>
                  </>
                )}
              </DetailTitle>
            </div>

            {historicoCliente?.ehExclusao && (
              <div className="col-md-4 col-sm-12 text-md-end text-sm-start mt-3">
                <h4 className="mb-0">
                  <BSBadge pill bg={ClassTypesEnum.WARNING}>
                    Exclusão
                  </BSBadge>
                </h4>
              </div>
            )}
          </div>

          <div className="row mb-4">
            <div className="col-lg-4 col-md-6 mb-4">
              <DetailElement descricao="Entidade" valor={historicoCliente.tipoEntidade} />
              <DetailElement
                descricao="Data da alteração"
                valor={historicoCliente.dataAlteracao}
                format={FormatValueEnum.DATE_TIME}
              />
              <DetailElement descricao="Origem da alteração" valor={historicoCliente.origemAlteracao} />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 col-lg-6 mb-4 d-flex flex-column">
              <div className="mb-4">
                <DetailSubTitle>Valores alterados</DetailSubTitle>
              </div>

              <BSCard className="flex-grow-1">
                <BSCard.Body className="p-5">
                  {historicoCliente.alteracao && (
                    <>
                      {Object.entries(historicoCliente.alteracao).map(([chave, valor]) => {
                        return renderDetailElement(historicoCliente.tipoEntidade, chave, valor);
                      })}
                    </>
                  )}
                </BSCard.Body>
              </BSCard>
            </div>

            <div className="col-md-12 col-lg-6 mb-4 d-flex flex-column">
              <div className="mb-4">
                <DetailSubTitle>Valores anteriores</DetailSubTitle>
              </div>

              <BSCard className="flex-grow-1">
                <BSCard.Body className="p-5">
                  {dadosAnteriores ? (
                    <>
                      {Object.entries(filterSameKeys(dadosAnteriores, historicoCliente.alteracao)).map(
                        ([chave, valor]) => {
                          return renderDetailElement(historicoCliente.tipoEntidade, chave, valor);
                        }
                      )}
                    </>
                  ) : (
                    <p>Sem alterações anteriores</p>
                  )}
                </BSCard.Body>
              </BSCard>
            </div>
          </div>
        </DetailCard>
      </div>
    </RbacPage>
  );
};

export default DetalhesHistoricoClientePage;

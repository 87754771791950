import BSCard from 'react-bootstrap/Card';
import {
  SmartTableFilterControlTypesEnum,
  SmartTableFilterProps,
} from '../../components/smart-table/smart-table-filters/table-filter.types';
import Title from '../../components/title';

import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import { useCallback, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import ExportCSVButton from '../../components/smart-table/export-csv-button';
import { SmartTable } from '../../components/smart-table/smart-table';
import SmartTableFilters from '../../components/smart-table/smart-table-filters/smart-table-filters';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import {
  ApiMultiElementResponse,
  ClassTypesEnum,
  FormatValueEnum,
  boolLabelMap,
  formatBRL,
  formatCpfCnpj,
  formatData,
  formatEnum,
  mapearERemoverElementosNulosERepetidos,
} from '../../helpers';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import { loadClientes, selectObjectTodosClientes } from '../clientes/clientes.redux';
import { loadProdutos, selectObjectTodosProdutos } from '../produtos/produtos.redux';
import {
  loadContasCartao,
  selectContasCartaoByFilters,
  selectContasCartaoLoadingStateByFilters,
  selectTotalContasCartaoByFilters,
} from './contas-cartao.redux';

const smartFilters: SmartTableFilterProps[] = [
  {
    attribute: 'numero',
    label: 'Número da conta',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
  },
  {
    attribute: 'cliente',
    label: 'Dados do cliente',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
  },
  {
    attribute: 'cartao',
    label: 'Final do cartão',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
    minLength: 3,
  },
  {
    attribute: 'nomeBloqueio',
    label: 'Nome do bloqueio',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
  },
  {
    attribute: 'codigoBloqueio',
    label: 'Código do bloqueio',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
  },
  {
    attribute: 'diaVencimento',
    label: 'Dia do vencimento da fatura',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
  },
  {
    attribute: 'limiteCredito',
    label: 'Limite de crédito (R$)',
    controlType: SmartTableFilterControlTypesEnum.NUMBER_RANGE,
  },
  {
    attribute: 'limiteCreditoDisponivel',
    label: 'Limite de crédito disponível (R$)',
    controlType: SmartTableFilterControlTypesEnum.NUMBER_RANGE,
  },
  {
    attribute: 'limiteSaque',
    label: 'Limite para saque (R$)',
    controlType: SmartTableFilterControlTypesEnum.NUMBER_RANGE,
  },
];

const smartColumns = ({ clientes, produtos }: { clientes: any; produtos: any }): SmartTableColumnProps[] => [
  {
    label: 'Número',
    attribute: 'numero',
    className: 'text-left',
    sortable: true,
    format: (numero: string) => <>{numero}</>,
  },
  {
    label: 'Cliente',
    attribute: 'cliente',
    className: 'text-center',
    format: (cliente: any) => {
      return (
        <Link onClick={(e) => e.stopPropagation()} to={`/clientes/${cliente}`}>
          {clientes[cliente]?.nome}
        </Link>
      );
    },
  },
  {
    label: 'CPF/CNPJ',
    attribute: 'cliente',
    className: 'text-center text-nowrap',
    format: (cliente: any) => clientes[cliente]?.cpfCnpj && formatCpfCnpj(clientes[cliente]?.cpfCnpj),
  },
  {
    label: 'Produto',
    attribute: 'produto',
    className: 'text-center',
    format: (produto: any) => {
      if (!produto) {
        return <>-</>;
      }

      return (
        <Link onClick={(e) => e.stopPropagation()} to={`/produtos/${produto}`}>
          {produtos[produto]?.nome}
        </Link>
      );
    },
  },
  {
    label: 'Limite de crédito',
    attribute: 'limiteCredito',
    sortable: true,
    className: 'text-end',
    format: FormatValueEnum.BRL,
  },
  {
    label: 'Limite de crédito disponível',
    attribute: 'limiteCreditoDisponivel',
    sortable: true,
    className: 'text-end',
    format: (limiteCreditoDisponivel: number) => formatBRL(limiteCreditoDisponivel),
  },
  {
    label: 'Limite para saque',
    attribute: 'limiteCreditoSaque',
    sortable: true,
    className: 'text-end',
    format: FormatValueEnum.BRL,
  },
];
const csvHeaders = [
  { label: 'Cliente ', key: 'cliente' },
  { label: 'Produto ', key: 'produto' },
  { label: 'Limite de crédito ', key: 'limiteCredito' },
  { label: 'Limite de crédito disponível ', key: 'limiteCreditoDisponivel' },
  { label: 'Limite para saque ', key: 'limiteCreditoSaque' },
  { label: 'valor da margem consignada', key: 'valorMargemConsignada' },
  { label: 'Dia de vencimento', key: 'diaVencimento' },
  { label: 'tipo de arredondamento da fatura', key: 'tipoArredondamentoDoacaoFatura' },
  { label: 'credito futuro', key: 'creditoFuturo' },
  { label: 'numero', key: 'numero' },
  { label: 'Data da alteração do dia do vencimento', key: 'dataHoraAlteracaoDiaVencimento' },
  { label: 'data criacao', key: 'dataCriacao' },
  { label: 'compara valor do mes anterior? ', key: 'comparaValorMesAnterior' },
  { label: 'permite doacao arredondamento de fatura', key: 'permiteDoacaoArredondamentoFatura' },
  { label: 'permite doacao recorrente mensal', key: 'permiteDoacaoRecorrenteMensal' },
  { label: 'valor doacao recorrente mensal ', key: 'valorDoacaoRecorrenteMensal' },
  { label: 'valor total doado ', key: 'valorTotalDoado' },
];

const ContasCartaoPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const { query, maxItemsQuery } = useQuerystring();
  const navigate = useNavigate();
  const { showToast } = useToasts();

  const filters = useMemo(() => ({ ...query }), [query]);
  const contasCartao = useSelector((state) => selectContasCartaoByFilters(state, filters));
  const total = useSelector((state) => selectTotalContasCartaoByFilters(state, filters));
  const loadingState = useSelector((state) => selectContasCartaoLoadingStateByFilters(state, filters));

  const clientes = useSelector((state) => selectObjectTodosClientes(state));
  const produtos = useSelector((state) => selectObjectTodosProdutos(state));

  const contasCartaoExcel = contasCartao?.map(
    ({
      _id,
      contaBancaria,
      tiposBloqueios,
      bloqueioPrioritario,
      bloqueios,
      cliente,
      produto,
      beneficios,
      ...dadosContaCartao
    }: any) => {
      let novosDados = dadosContaCartao;

      novosDados['cliente'] = clientes?.[cliente]?.nome;
      novosDados['produto'] = produtos?.[produto]?.nome;
      novosDados['limiteCredito'] = formatBRL(dadosContaCartao.limiteCredito);
      novosDados['limiteCreditoDisponivel'] = formatBRL(dadosContaCartao.limiteCreditoDisponivel);
      novosDados['limiteCreditoDisponivelSaque'] = formatBRL(dadosContaCartao.limiteCreditoDisponivelSaque);
      novosDados['limiteCreditoSaque'] = formatBRL(dadosContaCartao.limiteCreditoSaque);
      novosDados['dataCriacao'] = formatData(dadosContaCartao.dataCriacao);
      novosDados['comparaValorMesAnterior'] = formatEnum(dadosContaCartao.comparaValorMesAnterior, boolLabelMap);

      return novosDados;
    }
  );

  const loadEntidadesComplementares = useCallback(
    (data: any[]) => {
      const clientes = mapearERemoverElementosNulosERepetidos(data, 'cliente');
      const produtos = mapearERemoverElementosNulosERepetidos(data, 'produto');

      dispatch(loadClientes({ query: { _id: { in: clientes }, ...maxItemsQuery } })).catch((error: Error) => error);
      dispatch(loadProdutos({ query: { _id: { in: produtos }, ...maxItemsQuery } })).catch((error: Error) => error);
    },
    [dispatch, maxItemsQuery]
  );

  const loadItems = useCallback(() => {
    dispatch(loadContasCartao({ query }))
      .then(({ payload: { data } }: ApiMultiElementResponse) => {
        if (Object.entries(query).length > 0 && data.length === 0) {
          showToast({
            message: 'Nenhuma conta encontada',
            type: ClassTypesEnum.WARNING,
          });
        }

        return loadEntidadesComplementares(data);
      })
      .catch((error: Error) => error);
  }, [dispatch, loadEntidadesComplementares, query, showToast]);

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_CONTA_CARTAO}>
      <div className="d-flex flex-column h-100">
        <Title>Contas</Title>

        <div className="row">
          <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-12 mb-4">
            <SmartTableFilters filters={smartFilters} />
          </div>

          <div className="col-xxl-10 col-xl-9 col-lg-8 col-md-12 mb-4">
            <BSCard>
              <BSCard.Body>
                <div className="d-flex align-items-center mb-2">
                  <ExportCSVButton data={contasCartaoExcel} filename="contas-cartao" headers={csvHeaders} />
                </div>

                <SmartTable
                  emptyMessage="Pesquise por uma conta"
                  errorMessage="Erro na listagem de contas"
                  usePagination={true}
                  loadItems={loadItems}
                  columns={smartColumns({ clientes, produtos })}
                  items={contasCartao}
                  loadingState={loadingState}
                  onRowClick={(doc) => navigate(`/contas-cartao/${doc._id}`)}
                  size={total}
                />
              </BSCard.Body>
            </BSCard>
          </div>
        </div>
      </div>
    </RbacPage>
  );
};

export default ContasCartaoPage;

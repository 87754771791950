import { useCallback, useEffect } from 'react';
import BSBadge from 'react-bootstrap/Badge';
import BSContainer from 'react-bootstrap/Container';
import BSNav from 'react-bootstrap/Nav';
import BSNavDropdown from 'react-bootstrap/NavDropdown';
import BSNavbar from 'react-bootstrap/Navbar';
import { BsBoxArrowRight, BsPersonCircle } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { logo } from '../assets';
import RbacElement from '../components/role-based-access-control/role-based-access-control-element';
import { ApiSingleElementResponse } from '../helpers';
import { ClassTypesEnum } from '../helpers/enums';
import { loadSessionUsuario, logout, selectSessionUsuario } from '../pages/session-usuario/session-usuario.redux';
import { loadUsuarioPorSession, selectUsuariosById } from '../pages/usuarios/usuarios.redux';
import { useAppDispatch } from '../store/hooks-redux';
import classes from './app-header.module.scss';
import { getMenu } from './menu';

const AppHeader: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [sessionUsuario] = useSelector((state) => selectSessionUsuario(state, {})) ?? [];
  const usuario = useSelector((state) => selectUsuariosById(state, sessionUsuario?.usuario));

  const dispatchLogout = useCallback(
    () => dispatch(logout({ params: {} })).then(() => setTimeout(() => navigate('/login'), 0)),
    [dispatch, navigate]
  );

  const _loadUsuario = useCallback(
    (sessionUsuario: any) => {
      dispatch(loadUsuarioPorSession({ sessionId: sessionUsuario._id })).catch((error: Error) => error);
    },
    [dispatch]
  );

  const _loadSession = useCallback(
    () =>
      dispatch(loadSessionUsuario({}))
        .then(({ payload: { data } }: ApiSingleElementResponse) => _loadUsuario(data))
        .catch((error: Error) => error),
    [dispatch, _loadUsuario]
  );

  useEffect(() => {
    _loadSession();
  }, [_loadSession]);

  return (
    <BSNavbar className={classes.navbar} expand="lg" sticky="top">
      <BSContainer>
        <BSNavbar.Brand className="me-5" role="button">
          <Link to="/home">
            <div className="d-flex align-items-center">
              <div className="me-1">
                <img src={logo} alt="logo" className={classes.logo} />
              </div>
              <h3 className="text-primary m-0">tos</h3>
            </div>
          </Link>
        </BSNavbar.Brand>
        <BSNavbar.Toggle aria-controls="navbar-nav" />
        <BSNavbar.Collapse id="navbar">
          <BSNav className="me-auto">
            {getMenu().map((itemMenu) => {
              return (
                <RbacElement acoesPermissao={itemMenu.acessoAcoesPermissao || []} key={itemMenu.label}>
                  {itemMenu.dropdown ? (
                    <BSNavDropdown title={itemMenu.label} className="px-3">
                      {itemMenu.links?.map((subItemMenu) => (
                        <RbacElement acoesPermissao={subItemMenu.acessoAcoesPermissao || []} key={subItemMenu.label}>
                          <BSNavDropdown.Item as={Link} to={subItemMenu.path ?? ''}>
                            {subItemMenu.label}
                          </BSNavDropdown.Item>
                        </RbacElement>
                      ))}
                    </BSNavDropdown>
                  ) : (
                    <BSNav.Link as={Link} className="px-3" to={itemMenu.path ?? ''}>
                      {itemMenu.label}
                    </BSNav.Link>
                  )}
                </RbacElement>
              );
            })}
          </BSNav>

          <BSNavDropdown
            align={{ lg: 'end' }}
            className="px-3 py-2"
            title={
              <>
                <BsPersonCircle className="mb-1 me-2" size={20} />
                <span className="text-muted">{usuario?.username}</span>
              </>
            }
          >
            <BSNavDropdown.ItemText>
              <div className="d-flex my-2">
                <div className="me-5">
                  <h5 className="m-0">{usuario?.username}</h5>
                  <small className="text-muted">{usuario?.email}</small>
                </div>

                <h5>
                  <BSBadge pill bg={ClassTypesEnum.WARNING}>
                    {sessionUsuario?.papelUsuario?.descricao}
                  </BSBadge>
                </h5>
              </div>
            </BSNavDropdown.ItemText>
            <BSNavDropdown.Divider />
            <BSNavDropdown.Item onClick={dispatchLogout} className="d-flex align-items-center justify-content-end">
              <span>Sair</span>
              <BsBoxArrowRight size={20} className="ms-2" />
            </BSNavDropdown.Item>
          </BSNavDropdown>
        </BSNavbar.Collapse>
      </BSContainer>
    </BSNavbar>
  );
};

export default AppHeader;

import { reducerFactory, actionFactory } from '../../store';

const context = 'simuladorAntecipacaoParcelas';

const { createOne } = actionFactory(context, {
  buildUrlCreateOne: ({ transacaoId }) => `/api/transacoes/${transacaoId}/simular-antecipacao-parcelas`,
});

export const simularAntecipacaoParcelas = createOne;

export const simuladorAntecipacaoParcelas = reducerFactory({ context });

import { createSelector } from '@reduxjs/toolkit';
import { PathParameters, QueryParameters, serializeFilters } from '../helpers';
import type { RootState } from './redux.types';

export const selectorFactory = ({ context }: { context: string }) => {
  const selectedContext = (state: RootState) => state?.[context as keyof typeof state];

  const selectByIds = createSelector(selectedContext, (state) => state?.byIds);

  const selectAllIds = createSelector(selectedContext, (state) => state?.allIds);

  const selectByFilters = createSelector(
    [selectedContext, (_, filters: PathParameters | QueryParameters) => serializeFilters(filters)],
    (state, serializedFilters) => state?.byFilters[serializedFilters]
  );

  return {
    selectOneById: createSelector([selectByIds, (_, id: string) => id], (byIds, id) => byIds?.[id]),
    selectAll: createSelector([selectByIds, selectAllIds], (byIds, allIds) => allIds?.map((id) => byIds?.[id])),
    selectObjectAllIds: selectByIds,
    selectMapAllByAttribute: createSelector(
      [selectByIds, selectAllIds, (_, attribute: string) => attribute],
      (byIds, allIds, attribute) => {
        const map = new Map();

        allIds?.forEach((id) => {
          const item = byIds?.[id];
          const attributeGroup = map.get(item[attribute]) || [];

          attributeGroup.push(item);
          map.set(item[attribute], attributeGroup);
        });

        return map;
      }
    ),
    selectManyByFilters: createSelector([selectByIds, selectByFilters], (byIds, filteredIds) =>
      filteredIds?.map((id: string) => byIds?.[id])
    ),
    selectTotalByFilters: createSelector(
      [selectedContext, (_, filters: PathParameters | QueryParameters) => serializeFilters(filters)],
      (state, serializedFilters) => state?.totalByFilters[serializedFilters]
    ),
    selectLoadingStateByFilters: createSelector(
      [selectedContext, (_, filters: PathParameters | QueryParameters) => serializeFilters(filters)],
      (state, serializedFilters) => state?.loadingStateByFilters[serializedFilters]
    ),
    selectLoadingStateByCreate: createSelector(
      [selectedContext, (_, requestId: string) => requestId],
      (state, requestId) => {
        return state?.loadingStateByCreate[requestId];
      }
    ),
    selectLoadingStateByUpdate: createSelector(
      [selectedContext, (_, filters: PathParameters | QueryParameters) => serializeFilters(filters)],
      (state, serializedFilters) => {
        return state?.loadingStateByUpdate[serializedFilters];
      }
    ),
    selectLoadingStateByDelete: createSelector(
      [selectedContext, (_, filters: PathParameters | QueryParameters) => serializeFilters(filters)],
      (state, serializedFilters) => {
        return state?.loadingStateByDelete[serializedFilters];
      }
    ),
  };
};

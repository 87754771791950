import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import { useCallback, useMemo } from 'react';
import BSCard from 'react-bootstrap/Card';
import { useNavigate } from 'react-router-dom';
import RbacElement from '../../components/role-based-access-control/role-based-access-control-element';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import { SmartTable } from '../../components/smart-table/smart-table';
import SmartTableButton from '../../components/smart-table/smart-table-button';
import SmartTableFilters from '../../components/smart-table/smart-table-filters/smart-table-filters';
import {
  SmartTableFilterControlTypesEnum,
  SmartTableFilterProps,
} from '../../components/smart-table/smart-table-filters/table-filter.types';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import Title from '../../components/title';
import { FormatValueEnum, SizesEnum, bandeiraLabelMap, designCartaoLabelMap } from '../../helpers';
import { useDialog } from '../../hooks/dialog/use-dialog';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import CadastrarParametroGeracaoCartaoDialog from './cadastrar-parametro-geracao-cartao';
import {
  loadParametrosGeracaoCartao,
  selectParametrosGeracaoCartaoLoadingStateByFilters,
  selectParametrosGeracaoCartaoPorFiltro,
  selectTotalParametrosGeracaoCartaoPorFiltro,
} from './parametros-geracao-cartao.redux';

const smartColumns = (): SmartTableColumnProps[] => [
  { label: 'Código', attribute: 'codigo', sortable: true, className: 'text-center' },
  {
    label: 'Bandeira',
    attribute: 'bandeira',
    sortable: true,
    className: 'text-center',
    format: FormatValueEnum.ENUM,
    map: bandeiraLabelMap,
  },
  { label: 'Bin', attribute: 'bin', sortable: true, className: 'text-center' },
  {
    label: 'Design do cartão',
    attribute: 'designCartao',
    sortable: true,
    className: 'text-center',
    format: FormatValueEnum.ENUM,
    map: designCartaoLabelMap,
  },
  { label: 'Produto bandeira', attribute: 'produtoBandeira', sortable: true, className: 'text-center' },
];

const smartFilters: SmartTableFilterProps[] = [
  {
    label: 'Código',
    attribute: 'codigo',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
  },
  {
    label: 'Design do Cartão',
    attribute: 'designCartao',
    controlType: SmartTableFilterControlTypesEnum.SELECT,
    map: designCartaoLabelMap,
  },
  {
    label: 'Bandeira',
    attribute: 'bandeira',
    controlType: SmartTableFilterControlTypesEnum.SELECT,
    map: bandeiraLabelMap,
  },
];

const ParametroGeracaoCartaoPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const { query } = useQuerystring();
  const navigate = useNavigate();
  const { closeDialog, showDialog } = useDialog();

  const onNovoParametroGeracaoCartaoHandler = () => {
    showDialog({
      component: <CadastrarParametroGeracaoCartaoDialog closeDialog={closeDialog} reload={_loadItems} />,
      size: SizesEnum.EXTRA_LARGE,
    });
  };

  const filters = useMemo(() => ({ ...query }), [query]);
  const parametrosGeracaoCartao = useSelector((state) => selectParametrosGeracaoCartaoPorFiltro(state, filters));
  const loadingState = useSelector((state) => selectParametrosGeracaoCartaoLoadingStateByFilters(state, filters));
  const total = useSelector((state) => selectTotalParametrosGeracaoCartaoPorFiltro(state, filters));

  const _loadItems = useCallback(() => {
    dispatch(loadParametrosGeracaoCartao({ query: filters })).catch((error: Error) => error);
  }, [dispatch, filters]);

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_PARAMETROS_GERACAO_CARTAO}>
      <div className="d-flex flex-column h-100">
        <Title>Parâmetros de geração de cartão </Title>
        <div className="row">
          <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-12 mb-4">
            <SmartTableFilters filters={smartFilters} />
          </div>

          <div className="col-xxl-10 col-xl-9 col-lg-8 col-md-12 mb-4">
            <BSCard>
              <BSCard.Body>
                <div className="d-flex align-items-center mb-2">
                  <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.CRIACAO_PARAMETROS_GERACAO_CARTAO}>
                    <SmartTableButton onClick={onNovoParametroGeracaoCartaoHandler}>Novo parâmetro</SmartTableButton>
                  </RbacElement>
                </div>
                <SmartTable
                  emptyMessage="Nenhum parâmetro de geração de cartão encontrado"
                  errorMessage="Erro ao listar parâmetros de geração de cartão"
                  loadItems={_loadItems}
                  usePagination={true}
                  columns={smartColumns()}
                  items={parametrosGeracaoCartao}
                  loadingState={loadingState}
                  onRowClick={(doc) => navigate(`/parametros-geracao-cartao/${doc._id}/`)}
                  size={total}
                />
              </BSCard.Body>
            </BSCard>
          </div>
        </div>
      </div>
    </RbacPage>
  );
};

export default ParametroGeracaoCartaoPage;

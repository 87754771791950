import { useCallback, useEffect, useMemo, useState } from 'react';
import BSAlert from 'react-bootstrap/Alert';
import BSBadge from 'react-bootstrap/Badge';
import BSButton from 'react-bootstrap/Button';
import BSCard from 'react-bootstrap/Card';
import { useNavigate, useParams } from 'react-router-dom';
import { AcaoPermissaoPapelUsuarioEnum, StatusSolicitacaoClienteEnum, TipoCreditoEnum } from '@tamborineapps/lib-enums';
import { DetailCard } from '../../components/details/datail-card';
import DetailElement from '../../components/details/detail-element';
import DetailSubTitle from '../../components/details/detail-subtitle';
import DetailTitle from '../../components/details/detail-title';
import { Loading } from '../../components/loading';
import RbacElement from '../../components/role-based-access-control/role-based-access-control-element';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import { SmartTable } from '../../components/smart-table/smart-table';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import {
  ApiMultiElementResponse,
  ApiSingleElementResponse,
  ClassTypesEnum,
  FormatValueEnum,
  SizesEnum,
  mapearERemoverElementosNulosERepetidos,
  naturezaTransacaoLabelMap,
  statusSolicitacaoClienteColorMap,
  statusSolicitacaoClienteLabelMap,
} from '../../helpers';
import { useDialog } from '../../hooks/dialog/use-dialog';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import { loadCartao, selectCartaoById } from '../cartoes/cartoes.redux';
import {
  loadCategoriasBeneficios,
  selectObjectTodasCategoriasBeneficiosById,
} from '../categorias-beneficio/categorias.beneficio.redux';
import { loadCliente, selectClienteById } from '../clientes/clientes.redux';
import { loadContaCartao, selectContaCartaoById } from '../contas-cartao/contas-cartao.redux';
import { loadProduto, selectProdutoById } from '../produtos/produtos.redux';
import { loadTiposTransacao, selectObjectTodosTiposTransacao } from '../tipos-transacao/tipos-transacao.redux';
import { loadTransacoes } from '../transacoes/transacoes.redux';
import { loadUsuario, loadUsuarios, selectObjectTodosUsuarios, selectUsuariosById } from '../usuarios/usuarios.redux';
import CadastrarInteracaoAjusteFinanceiroSolicitacaoClienteDialog from './cadastrar-interacao-ajuste-financeiro-solicitacao-cliente-dialog';
import CadastrarInteracaoSolicitacaoClienteDialog from './cadastrar-interacao-solicitacao-cliente-dialog';
import InteracaoSolicitacaoClienteCard from './interacao-solicitacao-cliente-card';
import {
  loadInteracoesSolicitacaoCliente,
  selectInteracaoSolicitacaoClienteLoadingStateByFilters,
  selectInteracoesSolicitacaoClienteByFilters,
} from './interacao-solicitacao-cliente.redux';
import {
  loadSolicitacaoCliente,
  selectSolicitacaoClienteById,
  selectSolicitacaoClienteLoadingStateByFilters,
} from './solicitacao-cliente.redux';

const smartColumns = ({ tiposTransacao }: { tiposTransacao: any }): SmartTableColumnProps[] => [
  {
    label: 'Data referência',
    attribute: 'dataReferencia',
    className: 'text-center',
    format: FormatValueEnum.DATE_TIME,
  },
  {
    label: 'Descrição',
    attribute: 'descricao',
    className: 'text-center',
  },
  {
    label: 'Estabelecimento',
    attribute: 'nomeEstabelecimento',
    className: 'text-center',
  },
  {
    label: 'Natureza',
    attribute: 'natureza',
    className: 'text-center',
    format: FormatValueEnum.ENUM,
    map: naturezaTransacaoLabelMap,
  },
  {
    label: 'Tipo de transação',
    attribute: 'tipoTransacao',
    className: 'text-center',
    format(tipoTransacao: keyof typeof tiposTransacao) {
      if (!tipoTransacao) {
        return '';
      }

      return tiposTransacao[tipoTransacao]?.descricao;
    },
  },
  {
    label: 'Pendente',
    attribute: 'pendente',
    className: 'text-center',
    format: FormatValueEnum.BOOL,
  },
  {
    label: 'Cancelada',
    attribute: 'cancelada',
    className: 'text-center',
    format: FormatValueEnum.BOOL,
  },
  {
    label: 'Valor (R$)',
    attribute: 'valor',
    className: 'text-end',
    format: FormatValueEnum.BRL,
  },
];

const DetalhesSolicitacaoClientePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const params = useParams();
  const solicitacaoClienteId = params.solicitacaoClienteId as string;

  const { showDialog, closeDialog } = useDialog();
  const navigate = useNavigate();
  const { maxItemsQuery } = useQuerystring();
  const [transacoes, setTransacoes] = useState<any>([]);

  const filter = useMemo(() => ({ solicitacaoClienteId }), [solicitacaoClienteId]);
  const solicitacaoCliente = useSelector((state) => selectSolicitacaoClienteById(state, solicitacaoClienteId));
  const loadingState = useSelector((state) => selectSolicitacaoClienteLoadingStateByFilters(state, filter));

  const filterInteracoesSolicitacaoCliente = useMemo(
    () => ({ sort: '-dataHoraInteracao', solicitacaoClienteId: solicitacaoClienteId, ...maxItemsQuery }),
    [maxItemsQuery, solicitacaoClienteId]
  );
  const interacoes = useSelector((state) =>
    selectInteracoesSolicitacaoClienteByFilters(state, filterInteracoesSolicitacaoCliente)
  );
  const loadingStateInteracoes = useSelector((state) =>
    selectInteracaoSolicitacaoClienteLoadingStateByFilters(state, filterInteracoesSolicitacaoCliente)
  );
  const cartao = useSelector((state) => selectCartaoById(state, solicitacaoCliente?.cartao));
  const contaCartao = useSelector((state) => selectContaCartaoById(state, solicitacaoCliente?.contaCartao));
  const cliente = useSelector((state) => selectClienteById(state, contaCartao?.cliente));
  const produto = useSelector((state) => selectProdutoById(state, contaCartao?.produto));
  const usuario = useSelector((state) => selectUsuariosById(state, solicitacaoCliente?.usuarioSolicitacao));
  const usuarios = useSelector((state) => selectObjectTodosUsuarios(state));
  const tiposTransacao = useSelector((state) => selectObjectTodosTiposTransacao(state));
  const categoriasBeneficios = useSelector((state) => selectObjectTodasCategoriasBeneficiosById(state));

  const _loadCliente = useCallback(
    (data: any) => dispatch(loadCliente({ clienteId: data.cliente })).catch((error: Error) => error),
    [dispatch]
  );

  const _loadCategoriasBeneficio = useCallback(() => {
    dispatch(loadCategoriasBeneficios({ query: maxItemsQuery })).catch((error: Error) => error);
  }, [dispatch, maxItemsQuery]);

  const _loadProduto = useCallback(
    (data: any) =>
      dispatch(loadProduto({ produtoId: data.produto }))
        .then(({ payload: { data } }: ApiSingleElementResponse) => {
          const produto = data;

          if (produto.tipoCredito === TipoCreditoEnum.MULTIBENEFICIO) {
            _loadCategoriasBeneficio();
          }
        })
        .catch((error: Error) => error),
    [_loadCategoriasBeneficio, dispatch]
  );

  const _loadTiposTransacao = useCallback(
    (transacoes: any[]) => {
      const tiposTransacao = mapearERemoverElementosNulosERepetidos(transacoes, 'tipoTransacao');

      dispatch(loadTiposTransacao({ query: { _id: { in: tiposTransacao }, ...maxItemsQuery } })).catch(
        (error: Error) => error
      );
    },
    [dispatch, maxItemsQuery]
  );

  const _loadEntidadesRelacionadas = useCallback(
    (solicitacaoCliente: any) => {
      if (solicitacaoCliente.cartao) {
        dispatch(loadCartao({ cartaoId: solicitacaoCliente.cartao })).catch((error: Error) => error);
      }

      if (solicitacaoCliente.transacoes) {
        dispatch(loadTransacoes({ query: { _id: { in: solicitacaoCliente.transacoes }, ...maxItemsQuery } }))
          .then(({ payload: { data } }: ApiMultiElementResponse) => {
            setTransacoes(data);
            _loadTiposTransacao(data);
          })
          .catch((error: Error) => error);
      }
      dispatch(loadUsuario({ usuarioId: solicitacaoCliente.usuarioSolicitacao })).catch((error: Error) => error);
      dispatch(loadContaCartao({ contaCartaoId: solicitacaoCliente.contaCartao }))
        .then(({ payload: { data } }: ApiSingleElementResponse) => {
          _loadCliente(data);
          _loadProduto(data);
        })
        .catch((error: Error) => error);
    },
    [dispatch, maxItemsQuery, _loadTiposTransacao, _loadCliente, _loadProduto]
  );

  const _loadUsuarios = useCallback(
    (data: any[]) => {
      const usuarios = mapearERemoverElementosNulosERepetidos(data, 'usuarioSolicitacao');

      dispatch(loadUsuarios({ query: { _id: { in: usuarios }, ...maxItemsQuery } })).catch((error: Error) => error);
    },
    [dispatch, maxItemsQuery]
  );

  const _loadInteracoesSolicitacaoCliente = useCallback(
    () =>
      dispatch(
        loadInteracoesSolicitacaoCliente({
          params: { solicitacaoClienteId },
          query: filterInteracoesSolicitacaoCliente,
        })
      )
        .then(({ payload: { data } }: ApiMultiElementResponse) => _loadUsuarios(data))
        .catch((error: Error) => error),
    [_loadUsuarios, dispatch, filterInteracoesSolicitacaoCliente, solicitacaoClienteId]
  );

  const _loadSolicitacaoCliente = useCallback(() => {
    dispatch(loadSolicitacaoCliente({ solicitacaoClienteId }))
      .then(({ payload: { data } }: ApiSingleElementResponse) => _loadEntidadesRelacionadas(data))
      .catch((error: Error) => error);
  }, [_loadEntidadesRelacionadas, dispatch, solicitacaoClienteId]);

  const loadItems = useCallback(() => {
    _loadSolicitacaoCliente();
    _loadInteracoesSolicitacaoCliente();
  }, [_loadInteracoesSolicitacaoCliente, _loadSolicitacaoCliente]);

  const reload = useCallback(() => {
    dispatch(loadSolicitacaoCliente({ solicitacaoClienteId }, { forceCall: true })).catch((error: Error) => error);

    dispatch(
      loadInteracoesSolicitacaoCliente(
        { params: { solicitacaoClienteId }, query: filterInteracoesSolicitacaoCliente },
        { forceCall: true }
      )
    ).catch((error: Error) => error);
  }, [dispatch, filterInteracoesSolicitacaoCliente, solicitacaoClienteId]);

  const exibirTabelaTransacoes = Boolean(solicitacaoCliente?.transacoes?.length);

  const { DEFERIDO, INDEFERIDO } = StatusSolicitacaoClienteEnum;

  const possuiRestricaoPorStatus = [DEFERIDO, INDEFERIDO].includes(solicitacaoCliente?.tipoStatus);

  const exibirBotaoAjusteFinanceiro =
    Boolean(solicitacaoCliente?.tipoSolicitacao?.permiteAjusteFinanceiro) &&
    !possuiRestricaoPorStatus &&
    Boolean(produto);

  useEffect(() => {
    loadItems();
  }, [loadItems]);

  if (!solicitacaoCliente) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Loading notFoundMessage="Solicitação não econtrada" loadingState={loadingState} />
      </div>
    );
  }

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_SOLICITACAO_CLIENTE}>
      <div className="mb-5">
        <DetailCard>
          <div className="row">
            <div className="col-md-8 col-sm-12 mb-3">
              <DetailTitle>
                Solicitação: {solicitacaoCliente.tipoSolicitacao.descricao} ({solicitacaoCliente.tipoSolicitacao.codigo}
                ) - Protocolo {solicitacaoCliente.numeroDoProtocolo}
              </DetailTitle>
            </div>

            <div className="col-md-4 col-sm-12 text-md-end text-sm-start mb-5">
              <h4 className="mb-0">
                <BSBadge
                  pill
                  bg={
                    statusSolicitacaoClienteColorMap[
                      solicitacaoCliente.tipoStatus as keyof typeof statusSolicitacaoClienteColorMap
                    ]
                  }
                >
                  {
                    statusSolicitacaoClienteLabelMap[
                      solicitacaoCliente.tipoStatus as keyof typeof statusSolicitacaoClienteLabelMap
                    ]
                  }
                </BSBadge>
              </h4>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="mb-3">
                <DetailSubTitle>Dados do cliente</DetailSubTitle>
              </div>

              <DetailElement descricao="Nome" valor={cliente?.nome} link={`/clientes/${cliente?._id}`} />
              <DetailElement
                descricao="Conta"
                valor={contaCartao?.numero}
                link={`/contas-cartao/${solicitacaoCliente.contaCartao}`}
              />
              <DetailElement descricao="Conta" valor={produto?.nome} link={`/produtos/${produto?._id}`} />
              <DetailElement
                descricao="Cartão"
                valor={cartao?.numeroTruncado}
                format={FormatValueEnum.CARTAO}
                link={`/contas-cartao/${solicitacaoCliente.contaCartao}/cartoes/${solicitacaoCliente.cartao}`}
              />
              <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_PAN_TOKEN_CARTAO}>
                <DetailElement descricao="Pan Token" valor={solicitacaoCliente.panToken} />
              </RbacElement>
              <DetailElement
                descricao="Data de nascimento"
                valor={cliente?.dataNascimento}
                format={FormatValueEnum.DATA}
              />
              <DetailElement descricao="CPF/CNPJ" valor={cliente?.cpfCnpj} format={FormatValueEnum.CPF_CNPJ} />
              <DetailElement descricao="Número e UF do RG" valor={cliente && `${cliente.numeroRg} - ${cliente.ufRg}`} />
              <DetailElement descricao="E-mail" valor={cliente?.email} />
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <div className="mb-3">
                <DetailSubTitle>Dados da solicitação</DetailSubTitle>
              </div>

              <DetailElement descricao="Atendente" valor={usuario?.username} />
              <DetailElement
                descricao="Data abertura"
                valor={solicitacaoCliente.dataAbertura}
                format={FormatValueEnum.DATE_TIME}
              />
              {solicitacaoCliente?.dataFinalResolucao && (
                <DetailElement
                  descricao="Data final resolução"
                  valor={solicitacaoCliente.dataFinalResolucao}
                  format={FormatValueEnum.DATA}
                />
              )}
              {solicitacaoCliente?.dataResolucao && (
                <DetailElement
                  descricao="Data resolução"
                  valor={solicitacaoCliente.dataResolucao}
                  format={FormatValueEnum.DATE_TIME}
                />
              )}
            </div>

            {exibirTabelaTransacoes && (
              <div className="mb-4">
                <div className="mb-2">
                  <span>Transações selecionadas</span>
                </div>
                <BSCard>
                  <BSCard.Body>
                    <small>
                      <SmartTable
                        columns={smartColumns({ tiposTransacao })}
                        emptyMessage="Nenhuma transação selecionada"
                        errorMessage="Erro ao listar transações"
                        items={transacoes}
                        onRowClick={(doc) =>
                          navigate(`/solicitacoes-clientes/${solicitacaoClienteId}/transacoes/${doc._id}`)
                        }
                      />
                    </small>
                  </BSCard.Body>
                </BSCard>
              </div>
            )}

            <div className="col-12-mb-4">
              <div className="mb-3">
                <DetailSubTitle>Descrição da solicitação</DetailSubTitle>
              </div>

              <BSAlert className="p-4" variant={ClassTypesEnum.PRIMARY} style={{ whiteSpace: 'pre-line' }}>
                {solicitacaoCliente.descricao}
              </BSAlert>
            </div>
          </div>
        </DetailCard>
      </div>

      <DetailCard>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <div className="me-5">
            <DetailSubTitle>Interações</DetailSubTitle>
          </div>

          <div className="d-flex">
            <RbacElement
              acoesPermissao={AcaoPermissaoPapelUsuarioEnum.CRIACAO_AJUSTE_FINANCEIRO_INTERACAO_SOLICITACAO_CLIENTE}
            >
              {exibirBotaoAjusteFinanceiro && (
                <div className="me-2">
                  <BSButton
                    onClick={() => {
                      solicitacaoClienteId &&
                        showDialog({
                          component: (
                            <CadastrarInteracaoAjusteFinanceiroSolicitacaoClienteDialog
                              closeDialog={closeDialog}
                              reload={reload}
                              solicitacaoClienteId={solicitacaoClienteId}
                              produto={produto}
                            />
                          ),
                          size: SizesEnum.EXTRA_LARGE,
                        });
                    }}
                  >
                    Ajuste financeiro
                  </BSButton>
                </div>
              )}
            </RbacElement>

            <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.CRIACAO_INTERACAO_SOLICITACAO_CLIENTE}>
              {!possuiRestricaoPorStatus && (
                <BSButton
                  onClick={() => {
                    solicitacaoClienteId &&
                      showDialog({
                        component: (
                          <CadastrarInteracaoSolicitacaoClienteDialog
                            closeDialog={closeDialog}
                            reload={reload}
                            solicitacaoClienteId={solicitacaoClienteId}
                          />
                        ),
                        size: SizesEnum.LARGE,
                      });
                  }}
                >
                  Nova interação
                </BSButton>
              )}
            </RbacElement>
          </div>
        </div>

        <Loading loadingState={loadingStateInteracoes} notFoundMessage="Erro ao listar interações" />

        {Boolean(interacoes?.length) &&
          loadingStateInteracoes === 'loaded' &&
          interacoes?.map((interacao: any) => (
            <div key={interacao._id} className="mb-4">
              <InteracaoSolicitacaoClienteCard
                interacao={interacao}
                usuarios={usuarios}
                contaCartao={contaCartao}
                categoriasBeneficios={categoriasBeneficios}
              />
            </div>
          ))}

        {!interacoes?.length && loadingStateInteracoes === 'loaded' && (
          <div className="row justify-content-center ">
            <h5> Nenhuma interação encontrada</h5>
          </div>
        )}
      </DetailCard>
    </RbacPage>
  );
};

export default DetalhesSolicitacaoClientePage;

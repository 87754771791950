import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';

interface IItemMenu {
  label: string;
  path?: string;
  icon?: string;
  dropdown?: boolean;
  links?: IItemMenu[];
  acessoAcoesPermissao?: AcaoPermissaoPapelUsuarioEnum | AcaoPermissaoPapelUsuarioEnum[];
}

export const getMenu = (): IItemMenu[] => {
  return [
    {
      label: 'Contas',
      path: 'contas-cartao',
      acessoAcoesPermissao: AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_CONTA_CARTAO,
    },
    {
      label: 'Consultas',
      dropdown: true,
      acessoAcoesPermissao: [
        AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_CLIENTE_PRE_APROVADO_PRODUTO,
        AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_CLIENT_CREDENTIALS,
        AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_HISTORICO_ATIVIDADE_EXTERNA,
        AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_HISTORICO_ATIVIDADE_USUARIO,
        AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_LANCAMENTO_CONTABIL,
        AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_PEDIDO_AUTORIZACAO,
        AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_PROTOCOLO_PROCESSAMENTO_CONTA,
        AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_PROTOCOLO_PROCESSAMENTO_LOTE,
        AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_SEQUENCIAL,
        AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_SOLICITACAO_ABERTURA_CONTA,
        AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_SOLICITACAO_CLIENTE,
        AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_USUARIO,
        AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_QMR,
        AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_EMBOSSING_CARTAO,
      ],
      links: [
        {
          label: 'Clientes pré-aprovados',
          path: 'clientes-pre-aprovados-produto',
          acessoAcoesPermissao: AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_CLIENTE_PRE_APROVADO_PRODUTO,
        },
        {
          label: 'Clients',
          path: 'credenciais-cliente',
          acessoAcoesPermissao: AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_CLIENT_CREDENTIALS,
        },
        {
          label: 'Embossing de cartões',
          path: 'embossing-cartoes',
          acessoAcoesPermissao: AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_EMBOSSING_CARTAO,
        },
        {
          label: 'Grupo de clientes credenciais',
          path: 'grupos-credenciais-cliente',
          acessoAcoesPermissao: AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_CLIENT_CREDENTIALS,
        },
        {
          label: 'Histórico de atividades externas',
          path: 'historicos-atividades-externas',
          acessoAcoesPermissao: AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_HISTORICO_ATIVIDADE_EXTERNA,
        },
        {
          label: 'Histórico de atividades de usuários',
          path: 'historicos-atividades-usuarios',
          acessoAcoesPermissao: AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_HISTORICO_ATIVIDADE_USUARIO,
        },
        {
          label: 'Lançamentos contábeis',
          path: 'lancamentos-contabeis',
          acessoAcoesPermissao: AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_LANCAMENTO_CONTABIL,
        },
        {
          label: 'Pedidos de autorização',
          path: 'pedidos-autorizacao',
          acessoAcoesPermissao: AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_PEDIDO_AUTORIZACAO,
        },
        {
          label: 'Processamento de contas cartão',
          path: 'protocolos-processamento-conta',
          acessoAcoesPermissao: AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_PROTOCOLO_PROCESSAMENTO_CONTA,
        },
        {
          label: 'Processamento de arquivos',
          path: 'protocolos-processamento-lote/arquivos',
          acessoAcoesPermissao: AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_PROTOCOLO_PROCESSAMENTO_LOTE,
        },
        {
          label: 'Processamento de relatórios de operação',
          path: 'protocolos-processamento-lote/relatorios-operacao',
          acessoAcoesPermissao: AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_PROTOCOLO_PROCESSAMENTO_LOTE,
        },
        {
          label: 'Processamento de rotinas',
          path: 'protocolos-processamento-lote/rotinas',
          acessoAcoesPermissao: AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_PROTOCOLO_PROCESSAMENTO_LOTE,
        },
        {
          label: 'Processamento de rotinas frequentes',
          path: 'protocolos-processamento-lote/rotinas-frequentes',
          acessoAcoesPermissao: AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_PROTOCOLO_PROCESSAMENTO_LOTE,
        },
        {
          label: 'Sequenciais',
          path: 'sequenciais',
          acessoAcoesPermissao: AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_SEQUENCIAL,
        },
        {
          label: 'Solicitações de abertura de conta',
          path: 'solicitacoes-abertura-conta',
          acessoAcoesPermissao: AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_SOLICITACAO_ABERTURA_CONTA,
        },
        {
          label: 'Solicitações de clientes',
          path: 'solicitacoes-clientes',
          acessoAcoesPermissao: AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_SOLICITACAO_CLIENTE,
        },
        {
          label: 'Usuários',
          path: 'usuarios',
          acessoAcoesPermissao: AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_USUARIO,
        },
        {
          label: 'QMR',
          path: 'qmr',
          acessoAcoesPermissao: AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_QMR,
        },
      ],
    },
    {
      label: 'Operação',
      dropdown: true,
      acessoAcoesPermissao: [
        AcaoPermissaoPapelUsuarioEnum.EXECUCAO_ROTINA,
        AcaoPermissaoPapelUsuarioEnum.GERACAO_ARQUIVO,
        AcaoPermissaoPapelUsuarioEnum.GERACAO_RELATORIO_OPERACAO,
        AcaoPermissaoPapelUsuarioEnum.IMPORTACAO_ARQUIVO,
        AcaoPermissaoPapelUsuarioEnum.SIMULACAO_JUROS_ROTATIVO,
      ],
      links: [
        {
          label: 'Executar rotina',
          path: 'operacao/rotina-periodica',
          acessoAcoesPermissao: AcaoPermissaoPapelUsuarioEnum.EXECUCAO_ROTINA,
        },
        {
          label: 'Gerar arquivo',
          path: 'operacao/gerar-arquivo',
          acessoAcoesPermissao: AcaoPermissaoPapelUsuarioEnum.GERACAO_ARQUIVO,
        },
        {
          label: 'Gerar relatório de operação',
          path: 'operacao/gerar-relatorio-operacao',
          acessoAcoesPermissao: AcaoPermissaoPapelUsuarioEnum.GERACAO_RELATORIO_OPERACAO,
        },
        {
          label: 'Importar arquivo',
          path: 'operacao/importar-arquivo',
          acessoAcoesPermissao: AcaoPermissaoPapelUsuarioEnum.IMPORTACAO_ARQUIVO,
        },
        {
          label: 'Simulador de juros rotativo',
          path: 'operacao/simulador-juros-rotativo',
          acessoAcoesPermissao: AcaoPermissaoPapelUsuarioEnum.SIMULACAO_JUROS_ROTATIVO,
        },
      ],
    },
    {
      label: 'Parametrizações',
      dropdown: true,
      acessoAcoesPermissao: [
        AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_CALENDARIO_CORTE,
        AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_CATEGORIA_BERNEFICIO,
        AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_CONTRATO_TERMOS_USO,
        AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_EMISSOR,
        AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_FAIXA_MCC,
        AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_FERIADO,
        AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_ITEM_DOACAO,
        AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_PAPEL_USUARIO,
        AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_PARAMETROS_GERACAO_BOLETO,
        AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_PARAMETROS_GERACAO_CARTAO,
        AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_PERGUNTA_FREQUENTE,
        AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_PERGUNTA_SEGURANCA,
        AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_PRAZO_CONFIRMACAO_AUTORIZACAO_MCC,
        AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_PRODUTO,
        AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_ROTEIRO_CONTABIL,
        AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_TARIFA,
        AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_TAXA_CAMBIO,
        AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_TAXA_MEDIA_SELIC,
        AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_TIPO_BLOQUEIO_CARTAO,
        AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_TIPO_BLOQUEIO_CONTA_CARTAO,
        AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_TIPO_SOLICITACAO_CLIENTE,
        AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_TIPO_TRANSACAO,
      ],
      links: [
        {
          label: 'Calendários de corte',
          path: 'calendarios-corte',
          acessoAcoesPermissao: AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_CALENDARIO_CORTE,
        },
        {
          label: 'Categorias de benefício',
          path: 'categorias-beneficio',
          acessoAcoesPermissao: AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_CATEGORIA_BERNEFICIO,
        },
        {
          label: 'Contrato de termos de uso',
          path: 'contratos-termos-uso',
          acessoAcoesPermissao: AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_CONTRATO_TERMOS_USO,
        },
        {
          label: 'Emissor',
          path: 'emissor',
          acessoAcoesPermissao: AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_EMISSOR,
        },
        {
          label: 'Faixas de MCC',
          path: 'faixas-mcc',
          acessoAcoesPermissao: AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_FAIXA_MCC,
        },
        {
          label: 'Feriados',
          path: 'feriados',
          acessoAcoesPermissao: AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_FERIADO,
        },
        {
          label: 'Itens de doação',
          path: 'itens-doacao',
          acessoAcoesPermissao: AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_ITEM_DOACAO,
        },
        {
          label: 'Papéis de usuários',
          path: 'papeis-usuarios',
          acessoAcoesPermissao: AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_PAPEL_USUARIO,
        },
        {
          label: 'Parâmetros de geração do boleto',
          path: 'parametros-geracao-boleto',
          acessoAcoesPermissao: AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_PARAMETROS_GERACAO_BOLETO,
        },
        {
          label: 'Parâmetros de geração do cartão',
          path: 'parametros-geracao-cartao',
          acessoAcoesPermissao: AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_PARAMETROS_GERACAO_CARTAO,
        },
        {
          label: 'Perguntas frequentes',
          path: 'perguntas-frequentes',
          acessoAcoesPermissao: AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_PERGUNTA_FREQUENTE,
        },
        {
          label: 'Perguntas de segurança',
          path: 'perguntas-seguranca',
          acessoAcoesPermissao: AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_PERGUNTA_SEGURANCA,
        },
        {
          label: 'Prazos confirmação autorização por MCC',
          path: 'prazos-confirmacao-autorizacao-mcc',
          acessoAcoesPermissao: AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_PRAZO_CONFIRMACAO_AUTORIZACAO_MCC,
        },
        {
          label: 'Produtos',
          path: 'produtos',
          acessoAcoesPermissao: AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_PRODUTO,
        },
        {
          label: 'Roteiros contábeis',
          path: 'roteiros-contabeis',
          acessoAcoesPermissao: AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_ROTEIRO_CONTABIL,
        },
        {
          label: 'Tarifas',
          path: 'tarifas',
          acessoAcoesPermissao: AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_TARIFA,
        },
        {
          label: 'Taxas de câmbio',
          path: 'taxas-cambio',
          acessoAcoesPermissao: AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_TAXA_CAMBIO,
        },
        {
          label: 'Taxa selic',
          path: 'taxas-medias-selic',
          acessoAcoesPermissao: AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_TAXA_MEDIA_SELIC,
        },
        {
          label: 'Tipos de bloqueio de cartão',
          path: 'tipos-bloqueio-cartao',
          acessoAcoesPermissao: AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_TIPO_BLOQUEIO_CARTAO,
        },
        {
          label: 'Tipos de bloqueio de conta cartão',
          path: 'tipos-bloqueio-conta-cartao',
          acessoAcoesPermissao: AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_TIPO_BLOQUEIO_CONTA_CARTAO,
        },
        {
          label: 'Tipos de solicitação do cliente',
          path: 'tipos-solicitacao-cliente',
          acessoAcoesPermissao: AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_TIPO_SOLICITACAO_CLIENTE,
        },
        {
          label: 'Tipos de transação',
          path: 'tipos-transacao',
          acessoAcoesPermissao: AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_TIPO_TRANSACAO,
        },
      ],
    },
    {
      label: 'Relatórios',
      dropdown: true,
      acessoAcoesPermissao: [AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_RELATORIO_OPERACAO_AUTORIZACOES],
      links: [
        {
          label: 'Autorizações',
          path: 'relatorios-operacao/autorizacoes',
          acessoAcoesPermissao: AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_RELATORIO_OPERACAO_AUTORIZACOES,
        },
      ],
    },
  ];
};

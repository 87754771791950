import { ModeloCalculoPagamentoMinimoEnum } from '@tamborineapps/lib-enums';
import BSButton from 'react-bootstrap/Button';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Form } from '../../components/form/form';
import { FormControl, MasksFormControlEnum } from '../../components/form/form-control';
import { FormSelect } from '../../components/form/form-select';
import { ClassTypesEnum, modeloCalculoPagamentoMinimoLabelMap } from '../../helpers';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch } from '../../store/hooks-redux';
import { updateProduto } from './produtos.redux';

type AtualizarPreviaProdutoFormFields = {
  modeloCalculoPagamentoMinimo: string;
  valorMinimoDescontoEmFolha: number;
  valorMinimoPagamentoMinimo: number;
};

type AtualizarPreviaProdutoTabProps = {
  produto: any;
};

const AtualizarPreviaProdutoTab: React.FC<AtualizarPreviaProdutoTabProps> = ({ produto }) => {
  const dispatch = useAppDispatch();
  const { showToast } = useToasts();
  const navigate = useNavigate();

  const form = useForm<AtualizarPreviaProdutoFormFields>({
    defaultValues: {
      modeloCalculoPagamentoMinimo: produto?.modeloCalculoPagamentoMinimo,
      valorMinimoDescontoEmFolha: Number(produto?.valorMinimoDescontoEmFolha),
      valorMinimoPagamentoMinimo: Number(produto?.valorMinimoPagamentoMinimo),
    },
  });
  const { control } = form;

  const onSubmitHandler = (data: AtualizarPreviaProdutoFormFields) => {
    if (Object.keys(data).length === 0) {
      return;
    }

    dispatch(updateProduto({ params: { produtoId: produto._id }, data }))
      .then(() => {
        showToast({
          message: 'Produto atualizado com sucesso',
          type: ClassTypesEnum.SUCCESS,
        });

        return navigate('/produtos/');
      })
      .catch((error: Error) => {
        showToast({
          message: error.message,
          type: ClassTypesEnum.DANGER,
        });
      });
  };

  return (
    <>
      <Form form={form} id="form" onSubmit={onSubmitHandler}>
        <div className="row">
          <FormSelect
            control={control}
            className="col-xl-4 col-md-6 col-sm-12 mb-3"
            name="modeloCalculoPagamentoMinimo"
            label="Modelo de cálculo do pagamento mínimo"
            options={Object.entries(ModeloCalculoPagamentoMinimoEnum)?.map(([key, value]) => ({
              key,
              value,
              label: modeloCalculoPagamentoMinimoLabelMap[value as keyof typeof modeloCalculoPagamentoMinimoLabelMap],
            }))}
            rules={{
              required: true,
            }}
          />

          <FormControl
            control={control}
            className="col-xl-4 col-md-6 col-sm-12 mb-3"
            label="Valor mínimo para desconto em folha"
            name="valorMinimoDescontoEmFolha"
            mask={MasksFormControlEnum.BRL}
          />

          <FormControl
            control={control}
            className="col-xl-4 col-md-6 col-sm-12 mb-3"
            label="Valor mínimo para pagamento mínimo"
            name="valorMinimoPagamentoMinimo"
            mask={MasksFormControlEnum.BRL}
          />

          <div className="d-flex justify-content-end">
            <BSButton variant="primary" type="submit" form="form">
              Atualizar
            </BSButton>
          </div>
        </div>
      </Form>
    </>
  );
};
export default AtualizarPreviaProdutoTab;

import { reducerFactory, actionFactory, selectorFactory } from '../../store';

const context = 'tiposBloqueioCartao';

const { createOne, loadMany, loadOne, updateOne } = actionFactory(context, {
  buildUrlCreateOne: () => 'api/tipos-bloqueio-cartao',
  buildUrlLoadMany: () => '/api/tipos-bloqueio-cartao',
  buildUrlLoadOne: ({ tipoBloqueioCartaoId }) => `/api/tipos-bloqueio-cartao/${tipoBloqueioCartaoId}`,
  buildUrlUpdateOne: ({ tipoBloqueioCartaoId }) => `/api/tipos-bloqueio-cartao/${tipoBloqueioCartaoId}`,
});

const {
  selectOneById,
  selectAll,
  selectManyByFilters,
  selectTotalByFilters,
  selectLoadingStateByFilters,
  selectObjectAllIds,
} = selectorFactory({
  context,
});

export const createTipoBloqueioCartao = createOne;
export const loadTiposBloqueiosCartao = loadMany;
export const loadTipoBloqueioCartao = loadOne;
export const updateTipoBloqueioCartao = updateOne;

export const selectTiposBloqueiosById = selectOneById;
export const selectTodosTiposBloqueioCartao = selectAll;
export const selectObjectTodosTiposBloqueioCartao = selectObjectAllIds;
export const selectTiposBloqueioCartaoByFilters = selectManyByFilters;
export const selectTotalTiposBloqueioCartaoByFilters = selectTotalByFilters;
export const selectTiposBloqueioCartaoLoadingStateByFilters = selectLoadingStateByFilters;

export const tiposBloqueioCartao = reducerFactory({ context });

import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import { useCallback, useMemo } from 'react';
import BSCard from 'react-bootstrap/Card';
import RbacElement from '../../components/role-based-access-control/role-based-access-control-element';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import ExportCSVButton from '../../components/smart-table/export-csv-button';
import { SmartTable } from '../../components/smart-table/smart-table';
import SmartTableButton from '../../components/smart-table/smart-table-button';
import SmartTableFilters from '../../components/smart-table/smart-table-filters/smart-table-filters';
import {
  SmartTableFilterControlTypesEnum,
  SmartTableFilterProps,
} from '../../components/smart-table/smart-table-filters/table-filter.types';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import Title from '../../components/title';
import { FormatValueEnum, mesLabelMap, tipoFeriadoLabelMap } from '../../helpers';
import { useDialog } from '../../hooks/dialog/use-dialog';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import CadastrarFeriadoDialog from './cadastrar-feriado-dialog';
import {
  loadFeriados,
  selectFeriadoByFilters,
  selectFeriadosLoadingStateByFilters,
  selectTotalFeriadosByFilters,
} from './feriados.redux';

const smartColumns = (): SmartTableColumnProps[] => [
  { label: 'Descrição', attribute: 'descricao', sortable: true, className: 'text-center' },
  { label: 'Data', attribute: 'data', sortable: true, format: FormatValueEnum.DATA, className: 'text-center' },
  {
    label: 'Estado',
    attribute: 'uf',
    format(uf: any) {
      if (!uf) {
        return '-';
      }
      return [uf];
    },

    sortable: true,
    className: 'text-center',
  },
  {
    label: 'Cidade',
    attribute: 'cidade',
    format(cidade) {
      if (!cidade) {
        return '-';
      }
      return cidade;
    },
    sortable: true,
    className: 'text-center ',
  },
];
const smartFilters = (): SmartTableFilterProps[] => [
  { label: 'Data', attribute: 'data', controlType: SmartTableFilterControlTypesEnum.DATE_INPUT },
  { label: 'Dia', attribute: 'dia', controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT },
  { label: 'Mês', attribute: 'mes', controlType: SmartTableFilterControlTypesEnum.SELECT, map: mesLabelMap },
  { label: 'Ano', attribute: 'ano', controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT },
  { label: 'Tipo', attribute: 'tipo', controlType: SmartTableFilterControlTypesEnum.SELECT, map: tipoFeriadoLabelMap },
  {
    label: 'Descrição',
    attribute: 'descricao',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
    minLength: 3,
  },
];
const csvHeaders = [
  { label: 'Descrição', key: 'descricao' },
  { label: 'Dia', key: 'dia' },
  { label: 'Mês', key: 'mes' },
  { label: 'Ano', key: 'ano' },
  { label: 'Tipo', key: 'tipo' },
];

const FeriadosPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { query } = useQuerystring();

  const useSelector = useAppSelector;
  const { showDialog, closeDialog } = useDialog();

  if (!query.sort) {
    query.sort = '-data';
  }

  const filters = useMemo(() => ({ ...query }), [query]);
  const feriados = useSelector((state) => selectFeriadoByFilters(state, filters));
  const total = useSelector((state) => selectTotalFeriadosByFilters(state, filters));
  const loadingState = useSelector((state) => selectFeriadosLoadingStateByFilters(state, filters));

  const loadItems = useCallback(
    () => dispatch(loadFeriados({ query: filters })).catch((error: Error) => error),
    [dispatch, filters]
  );
  const onNovoFeriadoClickHandler = () => {
    showDialog({ component: <CadastrarFeriadoDialog closeDialog={closeDialog} reload={loadItems} /> });
  };

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_FERIADO}>
      <div className="d-flex flex-column h-100">
        <Title>Feriados</Title>

        <div className="row">
          <div className="col-xxl-2 col-xl-3 ">
            <SmartTableFilters filters={smartFilters()} />
          </div>

          <div className="col-xxl-10 col-xl-9 col-lg-8 col-md-12 mb-4">
            <BSCard>
              <BSCard.Body>
                <div className="d-flex align-items-center mb-2">
                  <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.CRIACAO_FERIADO}>
                    <SmartTableButton onClick={onNovoFeriadoClickHandler}>Novo feriado</SmartTableButton>
                  </RbacElement>
                  <ExportCSVButton data={feriados} filename="feriados" headers={csvHeaders} />
                </div>
                <SmartTable
                  emptyMessage="Nenhum feriado encontrado"
                  errorMessage="Erro na listagem de feriados"
                  usePagination={true}
                  loadItems={loadItems}
                  columns={smartColumns()}
                  items={feriados}
                  loadingState={loadingState}
                  size={total}
                />
              </BSCard.Body>
            </BSCard>
          </div>
        </div>
      </div>
    </RbacPage>
  );
};

export default FeriadosPage;

import BSButton from 'react-bootstrap/Button';
import BSModal from 'react-bootstrap/Modal';

import { useForm } from 'react-hook-form';
import { Form } from '../../components/form/form';
import { FormCheck } from '../../components/form/form-check';
import { FormControl } from '../../components/form/form-control';
import { FormSelect } from '../../components/form/form-select';

import { codigoRespostaAutorizadorLabelMap, tipoBloqueioContaCartaoLabelMap, utilizacaoLabelMap } from '../../helpers';

type AtualizarTipoBloqueioContaCartaoFields = {
  codigo: string;
  descricao: string;
  tipo: string;
  respostaAutorizador: string;
  prioridade: number;
  operacao: number;
  antecipaDivida: boolean;
  cobraMultaPorAtraso: boolean;
  cobraJurosPorAtraso: boolean;
  acionaSistemaCobranca: boolean;
  permiteEmissaoSegundaVia: boolean;
  cobraEncargos: boolean;
  permiteAlterarVencimento: boolean;
  bloqueioDefinitivo: boolean;
  cancelaCartao: boolean;
};

type AtualizarTipoBloqueioContaCartaoDialogProps = {
  tipoBloqueioContaCartao: any;
  closeDialog: () => void;
  onUpdateHandler: (tipoBloqueioContaCartaoId: string, data: any) => void;
};

const AtualizarTipoBloqueioContaCartaoDialog: React.FC<AtualizarTipoBloqueioContaCartaoDialogProps> = ({
  closeDialog,
  tipoBloqueioContaCartao,
  onUpdateHandler,
}) => {
  const form = useForm<AtualizarTipoBloqueioContaCartaoFields>({ defaultValues: tipoBloqueioContaCartao });
  const { control } = form;

  const onSubmitHandler = (data: AtualizarTipoBloqueioContaCartaoFields) => {
    const { operacao } = data;

    if (operacao) {
      data.operacao = Number(operacao);
    }

    onUpdateHandler(tipoBloqueioContaCartao._id, data);
  };

  return (
    <>
      <BSModal.Header closeButton>
        <BSModal.Title>Atualizar tipo de bloqueio da conta cartão - {tipoBloqueioContaCartao.descricao}</BSModal.Title>
      </BSModal.Header>
      <BSModal.Body>
        <Form id="form" form={form} onSubmit={onSubmitHandler}>
          <div className="row">
            <FormControl
              control={control}
              className="col-md-6 mb-3"
              label="Código"
              name="codigo"
              type="text"
              rules={{ required: true }}
            />

            <FormControl
              control={control}
              className="col-md-6 mb-3"
              label="Descrição"
              name="descricao"
              type="text"
              rules={{ required: true }}
            />

            <FormSelect
              control={control}
              className="col-md-6 mb-3"
              label="Tipo"
              name="tipo"
              placeholder="Selecione o tipo"
              options={Object.entries(utilizacaoLabelMap).map((elem) => ({
                label: elem[1],
                value: elem[0],
              }))}
              rules={{ required: true }}
            />

            <FormSelect
              control={control}
              className="col-md-6 mb-3"
              label="Resposta autorizador"
              name="respostaAutorizador"
              placeholder="Selecione a resposta autorizador"
              options={Object.entries(codigoRespostaAutorizadorLabelMap).map((elem) => ({
                label: elem[1],
                value: elem[0],
              }))}
              rules={{ required: true }}
            />

            <FormControl
              control={control}
              className="col-md-6 mb-3"
              label="Prioridade"
              name="prioridade"
              type="number"
              rules={{ required: true }}
            />

            <FormSelect
              control={control}
              className="col-md-6 mb-3"
              label="Operação"
              name="operacao"
              placeholder="Selecione a operação"
              options={Object.entries(tipoBloqueioContaCartaoLabelMap).map((elem) => ({
                label: elem[1],
                value: elem[0],
              }))}
            />

            <FormCheck
              control={control}
              className="col-md-6 mb-3"
              name="antecipaDivida"
              label="Saldo deve ser antecipado"
              type="switch"
            />

            <FormCheck
              control={control}
              className="col-md-6 mb-3"
              name="cobraMultaPorAtraso"
              label="Cobra multa por atraso"
              type="switch"
            />

            <FormCheck
              control={control}
              className="col-md-6 mb-3"
              name="cobraJurosPorAtraso"
              label="Cobra juros por atraso"
              type="switch"
            />

            <FormCheck
              control={control}
              className="col-md-6 mb-3"
              name="acionaSistemaCobranca"
              label="Inicia processo cobrança com sistema externo"
              type="switch"
            />

            <FormCheck
              control={control}
              className="col-md-6 mb-3"
              name="permiteEmissaoSegundaVia"
              label="Permite emissão segunda via"
              type="switch"
            />

            <FormCheck
              control={control}
              className="col-md-6 mb-3"
              name="cobraEncargos"
              label="Permite cobrança de encargos"
              type="switch"
            />

            <FormCheck
              control={control}
              className="col-md-6 mb-3"
              name="permiteAlterarVencimento"
              label="Permite alterar vencimento"
              type="switch"
            />

            <FormCheck
              control={control}
              className="col-md-6 mb-3"
              name="bloqueioDefinitivo"
              label="É bloqueio definitivo"
              type="switch"
            />

            <FormCheck
              control={control}
              className="col-md-6 mb-3"
              name="cancelaCartao"
              label="Cartão pode ser cancelado"
              type="switch"
            />
          </div>
        </Form>
      </BSModal.Body>
      <BSModal.Footer>
        <BSButton variant="light" onClick={closeDialog}>
          Fechar
        </BSButton>
        <BSButton variant="primary" type="submit" form="form">
          Atualizar
        </BSButton>
      </BSModal.Footer>
    </>
  );
};

export default AtualizarTipoBloqueioContaCartaoDialog;

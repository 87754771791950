import { useCallback, useMemo } from 'react';
import BSBadge from 'react-bootstrap/Badge';
import { useNavigate } from 'react-router-dom';
import { SmartTable } from '../../components/smart-table/smart-table';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import { ClassTypesEnum, FormatValueEnum, contaCartaoRename } from '../../helpers';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import {
  loadHistoricoContaCartao,
  selectHistoricoContaCartaoByFilters,
  selectHistoricoContaCartaoLoadingStateByFilters,
  selectTotalHistoricoContaCartaoByFilters,
} from '../historicos-contas-cartao/historico-conta-cartao.redux';

const smartColumns = (): SmartTableColumnProps[] => [
  {
    label: 'Data do evento',
    attribute: 'dataAlteracao',
    className: 'text-start',
    format: FormatValueEnum.DATE_TIME,
  },
  { label: 'Origem', attribute: 'origemAlteracao', className: 'text-start' },
  {
    label: 'Campos alterados',
    attribute: 'alteracao',
    className: 'align-middle',
    format: (alteracao: any) => {
      if (!alteracao) {
        return '-';
      }

      const listaAlteracao = Object.keys(alteracao)
        .map((nameKey: string) => contaCartaoRename[nameKey as keyof typeof contaCartaoRename])
        .filter((el) => el);

      if (listaAlteracao.length > 10) {
        return (
          <h6 className="m-1">
            <BSBadge pill bg={ClassTypesEnum.LIGHT} text={ClassTypesEnum.PRIMARY}>
              Diversos campos alterados
            </BSBadge>
          </h6>
        );
      }

      return (
        <div className="d-flex flex-wrap">
          {listaAlteracao.map((alteracao: string, index: number) => (
            <h6 className="m-1" key={index}>
              <BSBadge pill bg={ClassTypesEnum.LIGHT} text={ClassTypesEnum.MUTED}>
                {alteracao}
              </BSBadge>
            </h6>
          ))}
        </div>
      );
    },
  },
];

type HistoricoContaCartaoTabProps = {
  contaCartaoId: string;
};

const HistoricoContaCartaoTab: React.FC<HistoricoContaCartaoTabProps> = ({ contaCartaoId }) => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const navigate = useNavigate();
  const { query } = useQuerystring();

  if (!query.sort) {
    query.sort = '-dataAlteracao';
  }

  const filters = useMemo(() => ({ contaCartaoId, ...query }), [contaCartaoId, query]);
  const total = useSelector((state) => selectTotalHistoricoContaCartaoByFilters(state, filters));
  const historicos = useSelector((state) => selectHistoricoContaCartaoByFilters(state, filters));
  const loadingState = useSelector((state) => selectHistoricoContaCartaoLoadingStateByFilters(state, filters));

  const loadItems = useCallback(
    () => dispatch(loadHistoricoContaCartao({ params: { contaCartaoId }, query })).catch((error: Error) => error),
    [contaCartaoId, dispatch, query]
  );

  return (
    <SmartTable
      emptyMessage="Nenhum registro encontrado"
      errorMessage="Erro ao listar histórico"
      loadItems={loadItems}
      usePagination={true}
      size={total}
      columns={smartColumns()}
      items={historicos}
      loadingState={loadingState}
      onRowClick={(doc) => navigate(`/contas-cartao/${contaCartaoId}/historicos/${doc._id}`)}
    />
  );
};

export default HistoricoContaCartaoTab;

import { reducerFactory, actionFactory, selectorFactory } from '../../store';

const context = 'embossingCartoes';

const { loadMany } = actionFactory(context, {
  buildUrlLoadMany: () => `/api/embossing-cartao`,
});

const { selectManyByFilters, selectTotalByFilters, selectLoadingStateByFilters } = selectorFactory({ context });

export const loadEmbossingCartoes = loadMany;

export const selectEmbossingCartoesByFilters = selectManyByFilters;
export const selectTotalEmbossingCartoesByFilters = selectTotalByFilters;
export const selectEmbossingCartoeLoadingStateByFilters = selectLoadingStateByFilters;

export const embossingCartoes = reducerFactory({ context });

import BSButton from 'react-bootstrap/Button';
import BSModal from 'react-bootstrap/Modal';

import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Form } from '../../components/form/form';
import { FormControl } from '../../components/form/form-control';
import { FormSelect } from '../../components/form/form-select';
import { ClassTypesEnum, tiposOperacaoBeneficioLabelMap } from '../../helpers';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch } from '../../store/hooks-redux';
import { createFaixaMcc } from './faixas-mcc.redux';

type CadastrarFaixaMccFormFields = {
  descricao: string;
  descricaoBandeira: string;
  inicioMcc: string;
  fimMcc: string;
  operacaoBeneficio: string;
};

type CadastrarFaixaMccDialogProps = {
  closeDialog: () => void;
  reload: () => void;
};

const CadastrarFaixaMccDialog: React.FC<CadastrarFaixaMccDialogProps> = ({ closeDialog, reload }) => {
  const dispatch = useAppDispatch();
  const { showToast } = useToasts();

  const form = useForm<CadastrarFaixaMccFormFields>();
  const { control } = form;

  const onSubmitHandler = useCallback(
    (data: CadastrarFaixaMccFormFields) => {
      dispatch(createFaixaMcc({ data }))
        .then(() => {
          closeDialog();
          showToast({ message: 'Faixa de MCC cadastrada com sucesso', type: ClassTypesEnum.SUCCESS });
          reload();
        })
        .catch((error: Error) => {
          showToast({ message: error.message, type: ClassTypesEnum.DANGER });
        });
    },
    [closeDialog, dispatch, reload, showToast]
  );

  return (
    <>
      <BSModal.Header closeButton>
        <BSModal.Title>Nova faixa de MCC</BSModal.Title>
      </BSModal.Header>

      <BSModal.Body>
        <Form id="form" form={form} onSubmit={onSubmitHandler}>
          <FormControl
            control={control}
            className="mb-3"
            label="Descrição"
            name="descricao"
            rules={{ required: true }}
          />

          <FormControl
            control={control}
            className="mb-3"
            label="Descrição da bandeira"
            name="descricaoBandeira"
            rules={{ required: true }}
          />

          <FormControl
            control={control}
            className="mb-3"
            label="Início da faixa"
            name="inicioMcc"
            rules={{ required: true }}
          />

          <FormControl
            control={control}
            className="mb-3"
            label="Fim da faixa"
            name="fimMcc"
            rules={{ required: true }}
          />

          <FormSelect
            control={control}
            className="col-md-6 mb-3"
            label="Operação do benefício"
            name="operacaoBeneficio"
            placeholder="Selecione a operação"
            options={Object.entries(tiposOperacaoBeneficioLabelMap).map((elem) => ({
              label: elem[1],
              value: elem[0],
            }))}
            rules={{ required: true }}
          />
        </Form>
      </BSModal.Body>

      <BSModal.Footer>
        <BSButton variant="light" onClick={closeDialog}>
          Fechar
        </BSButton>
        <BSButton variant="primary" type="submit" form="form">
          Cadastrar faixa de MCC
        </BSButton>
      </BSModal.Footer>
    </>
  );
};

export default CadastrarFaixaMccDialog;

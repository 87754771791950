import DetailElement from '../../components/details/detail-element';
import DetailSubTitle from '../../components/details/detail-subtitle';
import { SmartTable } from '../../components/smart-table/smart-table';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import { FormatValueEnum, formatPercentValues } from '../../helpers';
import { useAppSelector } from '../../store/hooks-redux';
import { selectProdutoById } from './produtos.redux';

const smartColumns = (): SmartTableColumnProps[] => [
  {
    label: 'Quantidade de parcelas',
    attribute: 'parcelas',
    className: 'text-center',
  },
  {
    label: 'Juros mensal sob a parcela',
    attribute: 'taxaMensalJuros',
    format: (taxaMensalJuros: number) => formatPercentValues(taxaMensalJuros),
    className: 'text-center',
  },
];

type OpcoesParcelamentoFaturaProdutoTabProps = {
  produtoId: string;
};

const OpcoesParcelamentoFaturaProdutoTab: React.FC<OpcoesParcelamentoFaturaProdutoTabProps> = ({ produtoId }) => {
  const useSelector = useAppSelector;

  const produto = useSelector((state) => selectProdutoById(state, produtoId));

  return (
    <>
      <div className="row">
        <div className="col-lg-4 col-md-6 mb-4">
          <DetailElement
            descricao="Número de ciclos de rotativo para parcelamento automático de fatura"
            valor={produto.ciclosRotativoParaParcelamentoAutomaticoFatura}
          />
          <DetailElement
            descricao="Número de faturas sem atraso para permissão de overlimit"
            valor={produto.numeroMaximoFaturasAtrasoOverlimit}
          />
          <DetailElement
            descricao="Máximo de parcelamentos simultâneos"
            valor={produto.maximoParcelamentosSimultaneos}
            dica={
              <>
                Indica o número máximo de parcelamentos de faturas simultâneos para impedir que sejam feitos em excesso.
              </>
            }
          />
        </div>

        <div className="col-lg-4 col-md-6 mb-4">
          <DetailElement
            descricao="Prazo máximo de aceite do parcelamento"
            valor={produto.prazoMaximoParcelamentoFatura}
          />
          <DetailElement
            descricao="Parcelamento automático"
            valor={produto.permiteParcelamentoAutomaticoFatura}
            format={FormatValueEnum.BOOL}
            dica={<>Indica se permite o parcelamento automático da fatura.</>}
          />
        </div>

        <div className="col-lg-4 col-md-6 mb-4">
          <DetailElement
            descricao="Valor mínimo da parcela do parcelamento de fatura"
            valor={produto.valorMinimoParcelaParcelamentoFatura}
            format={FormatValueEnum.BRL}
          />
          <DetailElement
            descricao="IOF do parcelamento de faturas financiável"
            valor={produto.iofParcelamentoFaturaFinanciavel}
            format={FormatValueEnum.BOOL}
            dica={<>Indica se o IOF do parcelamento de faturas será incluido no financiamento.</>}
          />
        </div>
      </div>

      <div className="mb-5">
        <hr />
      </div>

      <div className="mb-4">
        <DetailSubTitle>Opções de parcelamento de fatura</DetailSubTitle>
      </div>

      <SmartTable
        emptyMessage="Nenhuma opção de parcelamento de fatura encontrada"
        errorMessage="Erro ao listar opções de parcelamento da fatura"
        usePagination={true}
        size={produto.opcoesParcelamentoFatura.length}
        columns={smartColumns()}
        items={produto.opcoesParcelamentoFatura}
      />
    </>
  );
};

export default OpcoesParcelamentoFaturaProdutoTab;

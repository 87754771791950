import { Route, Routes } from 'react-router-dom';
import AtualizarProdutoPage from './atualizar-produto-page';
import DetalhesProdutoPage from './detalhes-produto-page';
import ProdutosPage from './produtos-page';

const ProdutosRoutes: React.FC = () => {
  return (
    <Routes>
      <Route index element={<ProdutosPage />} />
      <Route path=":produtoId/atualizar" element={<AtualizarProdutoPage />} />
      <Route path=":produtoId" element={<DetalhesProdutoPage />} />
    </Routes>
  );
};

export default ProdutosRoutes;

import { actionFactory, reducerFactory, selectorFactory } from '../../store';

const context = 'categoriasBeneficio';

const { loadMany, loadOne } = actionFactory(context, {
  buildUrlLoadMany: () => '/api/categorias-beneficio',
  buildUrlLoadOne: ({ categoriaBeneficioId }) => `/api/categorias-beneficio/${categoriaBeneficioId}`,
});

const {
  selectOneById,
  selectObjectAllIds,
  selectAll,
  selectManyByFilters,
  selectTotalByFilters,
  selectLoadingStateByFilters,
} = selectorFactory({
  context,
});

export const loadCategoriasBeneficios = loadMany;
export const loadCategoriaBeneficio = loadOne;

export const selectCategoriaBeneficioById = selectOneById;
export const selectTodasCategoriasBeneficios = selectAll;
export const selectCategoriaBeneficiosByFilters = selectManyByFilters;
export const selectObjectTodasCategoriasBeneficiosById = selectObjectAllIds;
export const selectCategoriasBeneficiosStateByFilters = selectLoadingStateByFilters;
export const selectTotalCategoriasBeneficiosByFilters = selectTotalByFilters;

export const categoriasBeneficio = reducerFactory({ context });

import { Route, Routes } from 'react-router-dom';
import TiposSolicitacaoClientePage from './tipos-solicitacao-cliente-page';

const TiposSolicitacaoClienteRoutes: React.FC = () => {
  return (
    <Routes>
      <Route index element={<TiposSolicitacaoClientePage />} />
    </Routes>
  );
};

export default TiposSolicitacaoClienteRoutes;

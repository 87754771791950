import { BandeiraEnum, DesignCartaoEnum, ProdutoBandeiraEnum } from '@tamborineapps/lib-enums';
import { useCallback } from 'react';
import BSButton from 'react-bootstrap/Button';
import BSModal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { Form } from '../../components/form/form';
import { FormControl } from '../../components/form/form-control';
import { FormSelect } from '../../components/form/form-select';
import { ClassTypesEnum } from '../../helpers';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch } from '../../store/hooks-redux';
import { createParametroGeracaocartao } from './parametros-geracao-cartao.redux';

type CadastrarParametroGeracaoCartaoFields = {
  bandeira: string;
  bin: string;
  codigo: string;
  designCartao: string;
  modeloPlastico: string;
  prazoValidadeCartaoEmAnos: string;
  produtoBandeira: string;
  tamanhoNumeroCartao: string;
  validadeCertificadoSeguranca: string;
};

type CadastrarParametroGeracaoCartaoProps = {
  closeDialog: () => void;
  reload: () => void;
};

const CadastrarParametroGeracaoCartaoDialog: React.FC<CadastrarParametroGeracaoCartaoProps> = ({
  closeDialog,
  reload,
}) => {
  const dispatch = useAppDispatch();
  const { showToast } = useToasts();
  const form = useForm<CadastrarParametroGeracaoCartaoFields>();
  const { control } = form;

  const onSubmitHandler = useCallback(
    (data: CadastrarParametroGeracaoCartaoFields) => {
      dispatch(createParametroGeracaocartao({ data }))
        .then(() => {
          closeDialog();
          showToast({ message: 'Parâmetro geração cartão cadastrado com sucesso', type: ClassTypesEnum.SUCCESS });
          reload();
        })
        .catch((error: Error) => {
          showToast({ message: error.message, type: ClassTypesEnum.DANGER });
        });
    },
    [closeDialog, dispatch, reload, showToast]
  );
  return (
    <>
      <BSModal.Header closeButton>
        <BSModal.Title>Cadastrar parâmetro geração cartão </BSModal.Title>
      </BSModal.Header>

      <BSModal.Body>
        <Form id="form" form={form} onSubmit={onSubmitHandler}>
          <div className="row">
            <FormControl
              control={control}
              className="col-xl-4 col-md-6 col-sm-12 mb-3"
              label="Código"
              name="codigo"
              type="text"
              rules={{ required: true }}
            />

            <FormSelect
              control={control}
              className="col-xl-4 col-md-6 col-sm-12 mb-3"
              label="Bandeira"
              name="bandeira"
              placeholder="Selecione a bandeira"
              rules={{ required: true }}
              options={Object.values(BandeiraEnum).map((bandeira) => ({
                label: BandeiraEnum[bandeira],
                value: bandeira,
              }))}
            />

            <FormControl
              control={control}
              className="col-xl-4 col-md-6 col-sm-12 mb-3"
              label="Bin"
              name="bin"
              type="text"
              rules={{ required: true }}
            />

            <FormSelect
              control={control}
              className="col-xl-4 col-md-6 col-sm-12 mb-3"
              label="Design do cartão"
              name="designCartao"
              placeholder="Selecione o design do cartão"
              rules={{ required: true }}
              options={Object.values(DesignCartaoEnum).map((designCartao) => ({
                label: DesignCartaoEnum[designCartao],
                value: designCartao,
              }))}
            />

            <FormControl
              control={control}
              className="col-xl-4 col-md-6 col-sm-12 mb-3"
              label="Modelo de plástico"
              name="modeloPlastico"
              type="text"
              rules={{ required: true }}
            />

            <FormControl
              control={control}
              className="col-xl-4 col-md-6 col-sm-12 mb-3"
              label="Prazo de validade do cartão em anos"
              name="prazoValidadeCartaoEmAnos"
              type="text"
              rules={{ required: true }}
            />

            <FormSelect
              control={control}
              className="col-xl-4 col-md-6 col-sm-12 mb-3"
              label="Produto bandeira"
              name="produtoBandeira"
              placeholder="Selecione o produto"
              rules={{ required: true }}
              options={Object.values(ProdutoBandeiraEnum).map((produtoBandeira) => ({
                label: ProdutoBandeiraEnum[produtoBandeira],
                value: produtoBandeira,
              }))}
            />

            <FormControl
              control={control}
              className="col-xl-4 col-md-6 col-sm-12 mb-3"
              label="Tamanho do número do cartão"
              name="tamanhoNumeroCartao"
              type="text"
              rules={{ required: true }}
            />

            <FormControl
              control={control}
              className="col-xl-4 col-md-6 col-sm-12 mb-3"
              label="Validade do certificado de segurança"
              name="validadeCertificadoSeguranca"
              type="date"
              rules={{ required: true }}
            />
          </div>
        </Form>
      </BSModal.Body>
      <BSModal.Footer>
        <BSButton variant="light" onClick={closeDialog}>
          Fechar
        </BSButton>
        <BSButton variant="primary" type="submit" form="form">
          Cadastrar
        </BSButton>
      </BSModal.Footer>
    </>
  );
};

export default CadastrarParametroGeracaoCartaoDialog;

import { Middleware } from '@reduxjs/toolkit';
import { ApiError, ApiResponse } from '../helpers';
import type { RootState } from './redux.types';

interface CallApiActionDispatch {
  error: ApiError;
  payload: { response: ApiResponse };
}

export const authorizationMiddleware: Middleware<Record<string, never>, RootState> = () => (next) => async (action) => {
  const returnValue = next(action) as CallApiActionDispatch;

  if (returnValue?.error?.status === 401) {
    const { pathname } = window.location;

    if (pathname === '/login') {
      return;
    }

    window.location.href = '/login';
  }
};

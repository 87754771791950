import { createContext, useCallback, useRef, useState } from 'react';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { Toast } from '../../components/toast';
import { ClassTypesEnum } from '../../helpers';

export type ToastContextProps = {
  showToast: (toast: ToastProps) => void;
  closeToast: (toastId: number) => void;
};

export const ToastContext = createContext<ToastContextProps | null>(null);

type ToastProps = {
  type?: ClassTypesEnum;
  delay?: number;
  className?: string;
  message: string;
  title?: string;
};

type IdentifiedToastProps = ToastProps & { id: number };

export const ToastProvider = ({ children }: { children: React.ReactNode }) => {
  const [toasts, setToasts] = useState<IdentifiedToastProps[]>([]);
  const toastIdRef = useRef<number>(0);

  const show = useCallback((toast: ToastProps) => {
    const identifiedToast = { ...toast, id: toastIdRef.current };

    setToasts((toasts) => [...toasts, identifiedToast]);

    toastIdRef.current += 1;
  }, []);

  const close = useCallback((toastId: number) => {
    setToasts((toasts) => toasts.filter((toast) => toast.id !== toastId));
  }, []);

  const onClose = (id: number) => () => close(id);

  return (
    <ToastContext.Provider value={{ showToast: show, closeToast: close }}>
      <ToastContainer position="top-end" className="position-fixed py-5 px-2" style={{ zIndex: 1090 }}>
        {toasts.map(({ id, ...toast }) => (
          <Toast key={id} {...toast} onClose={onClose(id)} />
        ))}
      </ToastContainer>
      {children}
    </ToastContext.Provider>
  );
};

import { reducerFactory, actionFactory } from '../../store';

const context = 'geracaoRelatorioOperacao';

const { createOne } = actionFactory(context, {
  buildUrlCreateOne: () => '/api/gerar-relatorio-operacao',
});

export const gerarRelatorioOperacao = createOne;

export const geracaoRelatorioOperacao = reducerFactory({ context });

import BSCard from 'react-bootstrap/Card';

type DetailCardProps = {
  children: React.ReactNode;
};

export const DetailCard: React.FC<DetailCardProps> = ({ children }) => {
  return (
    <BSCard data-testid="detail-card" className="p-md-5 p-3">
      {children}
    </BSCard>
  );
};

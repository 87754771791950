import { Route, Routes } from 'react-router-dom';

import ClientesPreAprovadosProduto from './cliente-pre-aprovado-produto-page';

const ClientesPreAprovadosProdutoRoutes: React.FC = () => {
  return (
    <Routes>
      <Route index element={<ClientesPreAprovadosProduto />} />
    </Routes>
  );
};

export default ClientesPreAprovadosProdutoRoutes;

import { reducerFactory, actionFactory, selectorFactory } from '../../../store';

const context = 'subBinsProduto';

const { loadMany } = actionFactory(context, {
  buildUrlLoadMany: ({ produtoId }) => `/api/produtos/${produtoId}/sub-bins`,
});

const { selectManyByFilters, selectTotalByFilters, selectLoadingStateByFilters } = selectorFactory({
  context,
});

export const loadSubBinsProdutoId = loadMany;

export const selectSubBinsByFilters = selectManyByFilters;
export const selectTotalSubBinsByFilters = selectTotalByFilters;
export const selectSubBinsLoadingStateByFilters = selectLoadingStateByFilters;

export const subBinsProduto = reducerFactory({ context });

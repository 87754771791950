import { useCallback } from 'react';
import BSButton from 'react-bootstrap/Button';
import BSModal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';

import { Form } from '../../components/form/form';
import { FormControl } from '../../components/form/form-control';
import { ClassTypesEnum } from '../../helpers';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch } from '../../store/hooks-redux';
import { createPerguntaResposta } from './perguntas-frequentes.redux';

type CadastrarPerguntasRespostasPerguntasFrequentesFields = {
  titulo: string;
  texto: string;
};

type CadastrarPerguntasRespostasPerguntasFrequentesDialogProps = {
  closeDialog: () => void;
  perguntaFrequenteId: any;
  topicoId: any;
  reload: () => void;
};

const CadastrarPerguntasRespostasPerguntasFrequentesDialog: React.FC<
  CadastrarPerguntasRespostasPerguntasFrequentesDialogProps
> = ({ closeDialog, perguntaFrequenteId, topicoId, reload }) => {
  const dispatch = useAppDispatch();
  const { showToast } = useToasts();

  const form = useForm<CadastrarPerguntasRespostasPerguntasFrequentesFields>();
  const { control } = form;

  const onSubmitHandler = useCallback(
    (data: CadastrarPerguntasRespostasPerguntasFrequentesFields) => {
      dispatch(createPerguntaResposta({ params: { perguntaFrequenteId, topicoId }, data }))
        .then(() => {
          closeDialog();
          showToast({ message: 'Cadastrada com sucesso', type: ClassTypesEnum.SUCCESS });
          reload();
        })
        .catch((error: Error) => {
          showToast({ message: error.message, type: ClassTypesEnum.DANGER });
        });
    },
    [closeDialog, dispatch, perguntaFrequenteId, reload, showToast, topicoId]
  );

  return (
    <>
      <BSModal.Header closeButton>
        <BSModal.Title>Cadastrar pergunta</BSModal.Title>
      </BSModal.Header>

      <BSModal.Body>
        <Form id="form" form={form} onSubmit={onSubmitHandler}>
          <FormControl control={control} className="mb-3" name="titulo" label="Pergunta" rules={{ required: true }} />

          <FormControl control={control} className="mb-3" name="texto" label="Resposta" rules={{ required: true }} />
        </Form>
      </BSModal.Body>

      <BSModal.Footer>
        <BSButton variant="light" onClick={closeDialog}>
          Fechar
        </BSButton>
        <BSButton variant="primary" type="submit" form="form">
          Cadastrar
        </BSButton>
      </BSModal.Footer>
    </>
  );
};

export default CadastrarPerguntasRespostasPerguntasFrequentesDialog;

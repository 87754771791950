import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { DetailCard } from '../../components/details/datail-card';
import DetailElement from '../../components/details/detail-element';
import DetailTitle from '../../components/details/detail-title';
import { Loading } from '../../components/loading';
import Page from '../../components/page';
import { FormatValueEnum, naturezaTransacaoLabelMap, tipoTransacaoLabelMap, utilizacaoLabelMap } from '../../helpers';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import {
  loadTipoTransacao,
  selectTiposTransacaoById,
  selectTiposTransacaoLoadingStateByFilters,
} from './tipos-transacao.redux';

const DetalhesTipoTransacaoPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const params = useParams();
  const tipoTransacaoId = params.tipoTransacaoId as string;

  const filters = useMemo(() => ({ tipoTransacaoId }), [tipoTransacaoId]);
  const tipoTransacao = useSelector((state) => selectTiposTransacaoById(state, tipoTransacaoId));
  const loadingState = useSelector((state) => selectTiposTransacaoLoadingStateByFilters(state, filters));

  useEffect(() => {
    dispatch(loadTipoTransacao({ tipoTransacaoId })).catch((error: Error) => error);
  }, [dispatch, tipoTransacaoId]);

  const formatOperacao = (operacao: string) =>
    operacao ? tipoTransacaoLabelMap[operacao as keyof typeof tipoTransacaoLabelMap] : '-';

  if (!tipoTransacao) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Loading notFoundMessage="Tipo de transação não encontrado" loadingState={loadingState} />
      </div>
    );
  }

  return (
    <Page>
      <div className="mb-5">
        <DetailCard>
          <div className="mb-5">
            <DetailTitle>
              Tipo de transacao: {tipoTransacao.descricao} ({tipoTransacao.codigo})
            </DetailTitle>
          </div>

          <div className="row mb-4">
            <div className="col-lg-4 col-md-6 mb-4">
              <DetailElement descricao="Operação" valor={formatOperacao(tipoTransacao.operacao)} />
              <DetailElement
                descricao="Aparece na fatura"
                valor={tipoTransacao.apareceNaFatura}
                format={FormatValueEnum.BOOL}
              />
              <DetailElement descricao="Descrição na fatura" valor={tipoTransacao.descricaoFatura} />
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <DetailElement
                descricao="Função"
                valor={tipoTransacao.funcao}
                format={FormatValueEnum.ENUM}
                map={naturezaTransacaoLabelMap}
              />
              <DetailElement
                descricao="Parcela não financiável"
                valor={tipoTransacao.parcelaNaoFinanciavel}
                format={FormatValueEnum.BOOL}
              />
              <DetailElement
                descricao="Prazo em dias para confirmação da transação"
                valor={tipoTransacao.prazoEmDiasParaConfirmacao}
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <DetailElement
                descricao="Nível de prioridade ao gerar os lançamentos de pagamento"
                valor={tipoTransacao.prioridadePagamentoContabil}
              />
              <DetailElement
                descricao="Utilização"
                valor={tipoTransacao.utilizacao}
                format={FormatValueEnum.ENUM}
                map={utilizacaoLabelMap}
              />
            </div>
          </div>
        </DetailCard>
      </div>
    </Page>
  );
};

export default DetalhesTipoTransacaoPage;

import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { SmartTable } from '../../components/smart-table/smart-table';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import { FormatValueEnum } from '../../helpers';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import {
  loadBoletosBancario,
  selectBoletosBancarioByFilters,
  selectBoletosBancariosLoadingStateByFilters,
  selectTotalBoletosBancarioByFilters,
} from '../boletos-bancarios/boletos-bancario.redux';

const smartColumns = (): SmartTableColumnProps[] => [
  {
    label: 'Data da emissão',
    attribute: 'dataEmissaoTitulo',
    sortable: true,
    className: 'text-center',
    format: FormatValueEnum.DATA,
  },
  {
    label: 'Data de vencimento',
    attribute: 'dataVencimentoTitulo',
    sortable: true,
    className: 'text-center',
    format: FormatValueEnum.DATA,
  },
  {
    label: 'Número do documento',
    attribute: 'numeroDocumento',
    className: 'text-center',
  },
  {
    label: 'Valor (R$)',
    attribute: 'valorNominalTitulo',
    className: 'text-right',
    format: FormatValueEnum.BRL,
  },
];

type BoletosFaturaTabProps = {
  faturaId: string;
};

const BoletosFaturaTab: React.FC<BoletosFaturaTabProps> = ({ faturaId }) => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const navigate = useNavigate();
  const { query } = useQuerystring();

  const filters = useMemo(() => ({ ...query, faturaId }), [faturaId, query]);
  const boletos = useSelector((state) => selectBoletosBancarioByFilters(state, filters));
  const total = useSelector((state) => selectTotalBoletosBancarioByFilters(state, filters));
  const loadingState = useSelector((state) => selectBoletosBancariosLoadingStateByFilters(state, filters));

  const _loadBoletosBancarios = useCallback(() => {
    dispatch(loadBoletosBancario({ query: filters })).catch((error: Error) => error);
  }, [dispatch, filters]);

  return (
    <SmartTable
      emptyMessage="Nenhum boleto bancário encontrado"
      errorMessage="Erro ao listar boletos bancários"
      loadItems={_loadBoletosBancarios}
      usePagination={true}
      size={total}
      columns={smartColumns()}
      items={boletos}
      loadingState={loadingState}
      onRowClick={(doc) =>
        navigate(`/contas-cartao/${doc.contaCartao}/faturas/${faturaId}/boletos-bancarios/${doc._id}`)
      }
    />
  );
};

export default BoletosFaturaTab;

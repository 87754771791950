import { Navigate, Route, Routes } from 'react-router-dom';
import GerarArquivoPage from './gerar-arquivo-page';
import GerarRelatorioOperacaoPage from './gerar-relatorio-operacao-page';
import ImportarArquivoPage from './importar-arquivo-page';
import RotinaPeriodicaPage from './rotina-periodica-page';
import SimuladorJurosRotativoPage from './simulador-juros-rotativo-page';

const OperacaoRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="rotina-periodica" />} />
      <Route path="gerar-arquivo" element={<GerarArquivoPage />} />
      <Route path="gerar-relatorio-operacao" element={<GerarRelatorioOperacaoPage />} />
      <Route path="importar-arquivo" element={<ImportarArquivoPage />} />
      <Route path="rotina-periodica" element={<RotinaPeriodicaPage />} />
      <Route path="simulador-juros-rotativo" element={<SimuladorJurosRotativoPage />} />
    </Routes>
  );
};

export default OperacaoRoutes;

import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import { useCallback, useEffect, useMemo, useState } from 'react';
import BSCard from 'react-bootstrap/Card';
import BSCollapse from 'react-bootstrap/Collapse';
import { useParams } from 'react-router-dom';
import { DetailCard } from '../../components/details/datail-card';
import DetailElement from '../../components/details/detail-element';
import DetailSubTitle from '../../components/details/detail-subtitle';
import DetailTitle from '../../components/details/detail-title';
import { Loading } from '../../components/loading';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import { ApiSingleElementResponse, FormatValueEnum } from '../../helpers';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import { loadUsuario, selectUsuariosById } from '../usuarios/usuarios.redux';
import {
  loadHistorico,
  selectHistoricoUsuarioById,
  selectHistoricoUsuarioLoadingStateByFilters,
} from './historico-atividade-usuario.redux';

const DetalhesHistoricoUsuarioPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const params = useParams();
  const historicoUsuarioId = params.historicoUsuarioId as string;

  const filters = useMemo(() => ({ historicoUsuarioId }), [historicoUsuarioId]);
  const historico = useSelector((state) => selectHistoricoUsuarioById(state, historicoUsuarioId));
  const loadingState = useSelector((state) => selectHistoricoUsuarioLoadingStateByFilters(state, filters));
  const usuario = useSelector((state) => selectUsuariosById(state, historico?.usuario));

  const loadEntidadesComplementares = useCallback(
    (historico: any) => {
      dispatch(loadUsuario({ usuarioId: historico.usuario })).catch((error: Error) => error);
    },
    [dispatch]
  );

  const _loadHistorico = useCallback(
    () =>
      dispatch(loadHistorico({ historicoUsuarioId }))
        .then(({ payload: { data } }: ApiSingleElementResponse) => loadEntidadesComplementares(data))
        .catch((error: Error) => error),
    [dispatch, historicoUsuarioId, loadEntidadesComplementares]
  );

  useEffect(() => {
    _loadHistorico();
  }, [_loadHistorico]);

  if (!historico) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Loading notFoundMessage="Registro de histórico não econtrado!" loadingState={loadingState} />;
      </div>
    );
  }

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_HISTORICO_ATIVIDADE_USUARIO}>
      <div className="mb-5">
        <DetailCard>
          <div className="mb-5">
            <DetailTitle>
              Registro de atividade do usuário: <strong>{usuario?.username}</strong>
            </DetailTitle>
          </div>

          <div className="row mb-4">
            <div className="col-lg-4 col-md-6 mb-3">
              <DetailElement
                descricao="Data da requisição"
                valor={historico.dataHoraRequisicao}
                format={FormatValueEnum.DATA}
              />
              <DetailElement descricao="URL" valor={historico.url} />
              <DetailElement descricao="Método HTTP" valor={historico.metodo} />
              <DetailElement descricao="IP" valor={historico.ip} />
              <DetailElement descricao="Origem da alteração" valor={historico.origemAlteracao} />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-6 mb-4">
              <DetailSubTitle>Session</DetailSubTitle>
              <DetailElement descricao="Usuário" valor={usuario?.username} />
              <DetailElement
                descricao="Data de criação"
                valor={historico?.session?.dataHoraCriacao}
                format={FormatValueEnum.DATA}
              />
              <DetailElement
                descricao="Data de expiração"
                valor={historico?.session?.dataHoraExpiracao}
                format={FormatValueEnum.DATA}
              />
            </div>
          </div>

          <hr className="mb-5" />

          {historico.body && Object.keys(historico.body).length !== 0 && (
            <div className="mb-3">
              <RequestParamCard requestParamProps={historico.body} title="Body" />
            </div>
          )}

          {historico.params && Object.keys(historico.params).length !== 0 && (
            <div className="mb-3">
              <RequestParamCard requestParamProps={historico.params} title="Params" />
            </div>
          )}

          {historico.query && Object.keys(historico.query).length !== 0 && (
            <div className="mb-3">
              <RequestParamCard requestParamProps={historico.query} title="Query" />
            </div>
          )}

          {historico.headers && Object.keys(historico.headers).length !== 0 && (
            <div className="mb-3">
              <RequestParamCard requestParamProps={historico.headers} title="Headers" isHeaders />
            </div>
          )}
        </DetailCard>
      </div>
    </RbacPage>
  );
};

type RequestParamCardProps = {
  requestParamProps: any;
  title: string;
  isHeaders?: boolean;
};

const RequestParamCard: React.FC<RequestParamCardProps> = ({ requestParamProps, title, isHeaders = false }) => {
  const [cardOpen, setCardOpen] = useState<boolean>(true);

  const formatString = (value: any, isHeaders: Boolean) => {
    if (value && Object.keys(value).length !== 0) {
      const valueStr = JSON.stringify(value);

      if (isHeaders) {
        return valueStr
          .split(/","/)
          .map((el) => el.replace(/[{"]/g, ' ').replace(/["}]/g, ' ').replace(/[":"]/g, ': '));
      }

      return valueStr.split(/,/).map((el) => el.replace(/[{"]/g, ' ').replace(/["}]/g, ' ').replace(/[":"]/g, ': '));
    }

    return [];
  };

  const onClickHandler = () => {
    setCardOpen((prev) => !prev);
  };

  return (
    <BSCard role="button" onClick={onClickHandler}>
      <BSCard.Body>
        <DetailSubTitle>{title}</DetailSubTitle>

        <BSCollapse in={cardOpen}>
          <div>
            <div className="mt-3 ps-3">
              <div className="row">
                {formatString(requestParamProps, isHeaders).map((value, index) => (
                  <div key={index} className="col-md-6">
                    <li>{value}</li>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </BSCollapse>
      </BSCard.Body>
    </BSCard>
  );
};

export default DetalhesHistoricoUsuarioPage;

import { reducerFactory, actionFactory } from '../../store';

const context = 'rotinaPeriodica';

const { createOne } = actionFactory(context, {
  buildUrlCreateOne: () => '/api/operacao/rotina-periodica',
});

export const executarRotinaPeriodica = createOne;

export const rotinaPeriodica = reducerFactory({ context });

import React from 'react';

import BSButton from 'react-bootstrap/Button';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Form } from '../../components/form/form';
import { FormControl, MasksFormControlEnum } from '../../components/form/form-control';
import { ClassTypesEnum } from '../../helpers';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch } from '../../store/hooks-redux';
import { updateProduto } from './produtos.redux';

type AtualizarPercentuaisProdutoFormFields = {
  percentualUtilizacaoAcimaLimite: number;
  percentualPagamentoMinimoBloqueioDefinitivo: number;
  percentualLimiteCreditoSaque: number;
  percentualToleranciaSaldoDevido: number;
  percentualPagamentoMinimo: number;
  percentualIndexador: number;
};

type AtualizarPercentuaisProdutoTabProps = {
  produto: any;
};

const AtualizarPercentuaisProdutoTab: React.FC<AtualizarPercentuaisProdutoTabProps> = ({ produto }) => {
  const dispatch = useAppDispatch();
  const { showToast } = useToasts();
  const navigate = useNavigate();

  const form = useForm<AtualizarPercentuaisProdutoFormFields>({
    defaultValues: {
      percentualUtilizacaoAcimaLimite: Number(produto.percentualUtilizacaoAcimaLimite),
      percentualPagamentoMinimoBloqueioDefinitivo: Number(produto.percentualPagamentoMinimoBloqueioDefinitivo),
      percentualLimiteCreditoSaque: Number(produto.percentualLimiteCreditoSaque),
      percentualToleranciaSaldoDevido: Number(produto.percentualToleranciaSaldoDevido),
      percentualPagamentoMinimo: Number(produto.percentualPagamentoMinimo),
      percentualIndexador: Number(produto.percentualIndexador),
    },
  });
  const { control } = form;

  const onSubmitHandler = (data: AtualizarPercentuaisProdutoFormFields) => {
    if (Object.keys(data).length === 0) {
      return;
    }

    dispatch(updateProduto({ params: { produtoId: produto._id }, data }))
      .then(() => {
        showToast({
          message: 'Produto atualizado com sucesso',
          type: ClassTypesEnum.SUCCESS,
        });

        return navigate('/produtos/');
      })
      .catch((error: Error) => {
        showToast({
          message: error.message,
          type: ClassTypesEnum.DANGER,
        });
      });
  };

  return (
    <>
      <Form form={form} id="form" onSubmit={onSubmitHandler}>
        <div className="row">
          <FormControl
            control={control}
            className="col-xl-4 col-md-6 col-sm-12 mb-3"
            label="Percentual de overlimit permitido"
            name="percentualUtilizacaoAcimaLimite"
            mask={MasksFormControlEnum.PERCENT}
          />

          <FormControl
            control={control}
            className="col-xl-4 col-md-6 col-sm-12 mb-3"
            label="Percentual de pagamento mínimo para bloq. definitivo"
            name="percentualPagamentoMinimoBloqueioDefinitivo"
            mask={MasksFormControlEnum.PERCENT}
          />

          <FormControl
            control={control}
            className="col-xl-4 col-md-6 col-sm-12 mb-3"
            label="Percentual de limite de crédito para saques"
            name="percentualLimiteCreditoSaque"
            mask={MasksFormControlEnum.PERCENT}
          />

          <FormControl
            control={control}
            className="col-xl-4 col-md-6 col-sm-12 mb-3"
            label="Percentual de tolerância para saldo devido"
            name="percentualToleranciaSaldoDevido"
            mask={MasksFormControlEnum.PERCENT}
          />

          <FormControl
            control={control}
            className="col-xl-4 col-md-6 col-sm-12 mb-3"
            label="Percentual de pagamento mínimo"
            name="percentualPagamentoMinimo"
            mask={MasksFormControlEnum.PERCENT}
          />

          <FormControl
            control={control}
            className="col-xl-4 col-md-6 col-sm-12 mb-3"
            label="Percentual indexador"
            name="percentualIndexador"
            mask={MasksFormControlEnum.PERCENT}
          />

          <div className="d-flex justify-content-end">
            <BSButton variant="primary" type="submit" form="form">
              Atualizar
            </BSButton>
          </div>
        </div>
      </Form>
    </>
  );
};
export default AtualizarPercentuaisProdutoTab;

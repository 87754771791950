import { actionFactory, reducerFactory, selectorFactory } from '../../store';

const context = 'usuarioCliente';

const { loadOne, updateOne } = actionFactory(context, {
  buildUrlLoadOne: ({ clienteId }) => `/api/clientes/${clienteId}/usuario-cliente`,
  buildUrlUpdateOne: ({ usuarioClienteId }) => `/api/clientes/${usuarioClienteId}/remocao-bloqueio`,
});

const { selectOneById, selectLoadingStateByFilters, selectManyByFilters } = selectorFactory({
  context,
});

export const loadUsuarioClientePorCliente = loadOne;
export const removerBloqueio = updateOne;

export const selectClienteUsuarioByFilters = selectManyByFilters;
export const selectClienteUsuarioById = selectOneById;
export const selectClientesUsuarioLoadingStateByFilters = selectLoadingStateByFilters;

export const usuarioCliente = reducerFactory({ context });

import { useCallback, useEffect, useMemo } from 'react';
import BSBadge from 'react-bootstrap/Badge';
import { useParams } from 'react-router-dom';
import DownloadFileButton from '../../components/button/download-file-button';
import { DetailCard } from '../../components/details/datail-card';
import DetailElement from '../../components/details/detail-element';
import DetailSubTitle from '../../components/details/detail-subtitle';
import DetailTitle from '../../components/details/detail-title';
import { Loading } from '../../components/loading';
import Page from '../../components/page';
import {
  ApiSingleElementResponse,
  ClassTypesEnum,
  FormatValueEnum,
  codigoBaixaDevolucaoBoletoLabelMap,
  formaCadastramentoBoletoLabelMap,
} from '../../helpers';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import { loadContaCartao, selectContaCartaoById } from '../contas-cartao/contas-cartao.redux';
import { loadEmissor, selectEmissor } from '../emissor/emissor.redux';
import {
  loadParametroGeracaoBoleto,
  selectParametroGeracaoBoletoById,
} from '../parametros-geracao-boleto/parametros-geracao-boleto.redux';
import {
  loadBoletoBancario,
  selectBoletoBancarioById,
  selectBoletosBancariosLoadingStateByFilters,
} from './boletos-bancario.redux';

const DetalhesBoletoBancarioPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const params = useParams();
  const boletoBancarioId = params?.boletoBancarioId as string;

  const loadInformacoesComplementares = useCallback(
    (boletoBancario: any) => {
      dispatch(loadContaCartao({ contaCartaoId: boletoBancario.contaCartao })).catch((error: Error) => error);
      dispatch(loadParametroGeracaoBoleto({ parametroGeracaoBoletoId: boletoBancario.parametrosGeracaoBoleto })).catch(
        (error: Error) => error
      );
      dispatch(loadEmissor({})).catch((error: Error) => error);
    },
    [dispatch]
  );

  const loadDetalhesBoletoBancario = useCallback(
    () =>
      dispatch(loadBoletoBancario({ boletoBancarioId }))
        .then(({ payload: { data } }: ApiSingleElementResponse) => loadInformacoesComplementares(data))
        .catch((error: Error) => error),
    [boletoBancarioId, dispatch, loadInformacoesComplementares]
  );

  const filters = useMemo(() => ({ boletoBancarioId }), [boletoBancarioId]);
  const boleto = useSelector((state) => selectBoletoBancarioById(state, boletoBancarioId));
  const loadingState = useSelector((state) => selectBoletosBancariosLoadingStateByFilters(state, filters));
  const contaCartao = useSelector((state) => selectContaCartaoById(state, boleto?.contaCartao));
  const [emissor] = useSelector((state) => selectEmissor(state, {})) ?? [];
  const parametroGeracaoBoleto = useSelector((state) =>
    selectParametroGeracaoBoletoById(state, boleto?.parametrosGeracaoBoleto)
  );

  useEffect(() => {
    loadDetalhesBoletoBancario();
  }, [loadDetalhesBoletoBancario]);

  if (!boleto) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Loading notFoundMessage="Boleto bancário não encontrado!" loadingState={loadingState} />
      </div>
    );
  }

  return (
    <Page>
      <div className="mb-5">
        <DetailCard>
          <div className="row mb-5">
            <div className="col-md-8 col-sm-12">
              <DetailTitle>Boleto bancário</DetailTitle>
            </div>

            {boleto?.pago && (
              <div className="col-md-4 col-sm-12 text-md-end text-sm-start mt-3">
                <h4 className="mb-0">
                  <BSBadge pill bg={ClassTypesEnum.SUCCESS}>
                    Pago
                  </BSBadge>
                </h4>
              </div>
            )}
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-6 mb-4">
              <DetailElement descricao="Sacado" valor={boleto.nomeSacado} />
              <DetailElement descricao="Número do documento" valor={boleto.numeroDocumento} />
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <DetailElement
                descricao="Data de pagamento do boleto"
                valor={boleto.dataPagamento}
                format={FormatValueEnum.DATA}
              />
              <DetailElement descricao="Boleto pago" valor={boleto.pago} format={FormatValueEnum.BOOL} />
              <DetailElement descricao="Valor pago do boleto" valor={boleto.valorPago} format={FormatValueEnum.BRL} />
              <DetailElement
                descricao="Valor pago parcial do boleto"
                valor={boleto.pagoParcialmente}
                format={FormatValueEnum.BOOL}
              />
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <DetailElement
                descricao="Valor nominal do título "
                valor={boleto.valorNominalTitulo}
                format={FormatValueEnum.BRL}
              />
              <DetailElement
                descricao="Data de vencimento do título"
                valor={boleto.dataVencimentoTitulo}
                format={FormatValueEnum.DATA}
              />
            </div>

            <div className="col-12">
              <DetailElement descricao="Linha digitavel" valor={boleto.linhaDigitavel} />
            </div>

            <div className="col-12 mb-4">
              <DetailElement descricao="Código de barras" valor={boleto.codigoBarras} />
            </div>

            <div className="col-lg-8 mb-4">
              <div className="mb-3">
                <DetailSubTitle>Encargos/Descontos</DetailSubTitle>
              </div>

              <div className="row">
                <div className="col-md-6 mb-4">
                  <DetailElement descricao="Código do juros de mora" valor={boleto.codigoJurosMora} />{' '}
                  {/* FIXME: enum */}
                  <DetailElement descricao="Código do desconto" valor={boleto.codigoDesconto} /> {/* FIXME: enum */}
                  <DetailElement
                    descricao="Data do juros de mora"
                    valor={boleto.dataJurosMora}
                    format={FormatValueEnum.DATA}
                  />
                  <DetailElement
                    descricao="Data de desconto"
                    valor={boleto.dataDesconto}
                    format={FormatValueEnum.DATA}
                  />
                </div>

                <div className="col-md-6 mb-4">
                  <DetailElement
                    descricao="Valor da mora/dia ou Taxa mensal"
                    valor={boleto.valorMoraDia}
                    format={FormatValueEnum.BRL}
                  />
                  <DetailElement
                    descricao="Valor do desconto concedido"
                    valor={boleto.desconto}
                    format={FormatValueEnum.BRL}
                  />
                  <DetailElement
                    descricao="Valor do abatimento"
                    valor={boleto.valorAbatimento}
                    format={FormatValueEnum.BRL}
                  />
                  <DetailElement
                    descricao="Valor do IOF a ser recolhido"
                    valor={boleto.iof}
                    format={FormatValueEnum.BRL}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <div className="mb-3">
                <DetailSubTitle>Procedimentos</DetailSubTitle>
              </div>
              <DetailElement descricao="Tipo de prazo" valor={boleto.codigoProtesto} /> {/* FIXME: enum */}
              <DetailElement descricao="Número de dias para protesto" valor={boleto.diasProtesto} />
              <DetailElement
                descricao="Tipo de procedimento a ser adotado"
                valor={boleto.codigoBaixaDevolucao}
                format={FormatValueEnum.ENUM}
                map={codigoBaixaDevolucaoBoletoLabelMap}
              />
              <DetailElement descricao="Número de dias para Baixa/Devolução" valor={boleto.diasBaixaDevolucao} />
            </div>

            <div className="col-lg-12">
              <div className="mb-3">
                <DetailSubTitle>Dados do cedente</DetailSubTitle>
              </div>

              <div className="row">
                <div className="col-md-4 mb-4">
                  <DetailElement descricao="Agência do banco cedente" valor={boleto.agenciaCedente} />
                  <DetailElement descricao="Dígito da agência do banco cedente" valor={boleto.digitoAgenciaCedente} />
                  <DetailElement descricao="Número da conta corrente" valor={boleto.contaCorrente} />
                  <DetailElement
                    descricao="Dígito verificador da conta"
                    valor={boleto.digitoVerificadorContaCorrente}
                  />
                  <DetailElement
                    descricao="Forma de Cadastramento"
                    valor={boleto.formaCadastramento}
                    format={FormatValueEnum.ENUM}
                    map={formaCadastramentoBoletoLabelMap}
                  />
                </div>
                <div className="col-md-4 mb-4">
                  <DetailElement descricao="Nosso número" valor={boleto.nossoNumero} />
                  <DetailElement descricao="Espécie do título" valor={boleto.especieTitulo} />
                  <DetailElement descricao="Espécie da moeda" valor={boleto.especieMoeda} />
                  <DetailElement descricao="Registrado" valor={boleto.registrado} format={FormatValueEnum.BOOL} />
                  <DetailElement descricao="Código da rejeição do cadastro" valor={boleto.codigoRejeicao} />
                </div>
                <div className="col-md-4 mb-4">
                  <DetailElement
                    descricao="Data da emissão do título"
                    valor={boleto.dataEmissaoTitulo}
                    format={FormatValueEnum.DATA}
                  />
                  <DetailElement descricao="Código do banco na compensação" valor={boleto.codigoBancoCompensacao} />
                  <DetailElement
                    descricao="Enviado nos arquivos cadoc"
                    valor={boleto.deveRegistrar}
                    format={FormatValueEnum.BOOL}
                  />
                  <DetailElement
                    descricao="Confirmação do registro do boleto"
                    valor={boleto.dataConfirmacao}
                    format={FormatValueEnum.DATA}
                  />
                  <DetailElement descricao="Motivo da rejeição do cadastro" valor={boleto.motivoRejeicao} />
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <div className="mb-3">
                <DetailSubTitle>Informações complementares</DetailSubTitle>
              </div>

              <DetailElement
                descricao="Conta Cartão"
                valor={contaCartao?.numero}
                link={`/contas-cartao/${boleto.contaCartao}`}
              />
              <DetailElement
                descricao="Fatura"
                valor={boleto.fatura ? 'Ver mais' : undefined}
                link={`/contas-cartao/${boleto.contaCartao}/faturas/${boleto.fatura}/`}
              />
              <DetailElement descricao="Emissor" valor={emissor?.nome} link={'/emissor'} />
              <DetailElement
                descricao="Parâmetros da geração de boletos"
                valor={parametroGeracaoBoleto?.nomeBancoCedente}
                link={`/parametros-geracao-boleto/${boleto.parametrosGeracaoBoleto}`}
              />
            </div>

            {boleto?.armazenamento?.referencia && (
              <div className="row">
                <div className="col-12 d-flex justify-content-end flex-wrap">
                  <div className="m-1">
                    <DownloadFileButton
                      contentType="application/pdf"
                      fileName={`${boletoBancarioId}.pdf`}
                      url={`/api/boletos-bancarios/${boletoBancarioId}/pdf`}
                    >
                      Download PDF
                    </DownloadFileButton>
                  </div>
                </div>
              </div>
            )}
          </div>
        </DetailCard>
      </div>
    </Page>
  );
};

export default DetalhesBoletoBancarioPage;

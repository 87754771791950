import { actionFactory, reducerFactory, selectorFactory } from '../../store';

const context = 'calendariosCorte';

const { loadMany, createOne } = actionFactory(context, {
  buildUrlLoadMany: () => '/api/calendarios-corte',
  buildUrlCreateOne: () => '/api/calendarios-corte',
});

const { selectManyByFilters, selectTotalByFilters, selectLoadingStateByFilters } = selectorFactory({ context });

export const saveCalendarioCorte = createOne;
export const loadCalendariosDeCorte = loadMany;

export const selectCalendariosCorteByFilters = selectManyByFilters;
export const selectTotalCalendariosCorteByFilters = selectTotalByFilters;
export const selectCalendarioCorteLoadingStateByFilters = selectLoadingStateByFilters;

export const calendariosCorte = reducerFactory({ context });

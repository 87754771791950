import { actionFactory, reducerFactory, selectorFactory } from '../../store';

const context = 'boletosBancarios';

const { loadMany, loadOne } = actionFactory(context, {
  buildUrlLoadOne: ({ boletoBancarioId }) => `/api/boletos-bancario/${boletoBancarioId}`,
  buildUrlLoadMany: () => '/api/boletos-bancario',
});

const { selectOneById, selectManyByFilters, selectTotalByFilters, selectLoadingStateByFilters } = selectorFactory({
  context,
});

export const loadBoletosBancario = loadMany;
export const loadBoletoBancario = loadOne;

export const selectBoletoBancarioById = selectOneById;
export const selectBoletosBancarioByFilters = selectManyByFilters;
export const selectTotalBoletosBancarioByFilters = selectTotalByFilters;
export const selectBoletosBancariosLoadingStateByFilters = selectLoadingStateByFilters;

export const boletosBancarios = reducerFactory({ context });

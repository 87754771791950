import { Route, Routes } from 'react-router-dom';
import CredenciaisClientePage from './credenciais-cliente-page';

const CredenciaisClienteRoutes: React.FC = () => {
  return (
    <Routes>
      <Route index element={<CredenciaisClientePage />} />
    </Routes>
  );
};

export default CredenciaisClienteRoutes;

import { actionFactory, selectorFactory, reducerFactory } from '../../store';

const context = 'papeisUsuarios';

const { loadMany, loadOne, createOne, updateOne } = actionFactory(context, {
  buildUrlLoadOne: ({ papelUsuarioId }) => `/api/papeis-usuarios/${papelUsuarioId}`,
  buildUrlLoadMany: () => '/api/papeis-usuarios',
  buildUrlCreateOne: () => '/api/papeis-usuarios',
  buildUrlUpdateOne: ({ papelUsuarioId }) => `/api/papeis-usuarios/${papelUsuarioId}`,
});

const { selectObjectAllIds, selectOneById, selectManyByFilters, selectTotalByFilters, selectLoadingStateByFilters } =
  selectorFactory({
    context,
  });

export const loadPapeisUsuarios = loadMany;
export const loadPapelUsuario = loadOne;
export const savePapelUsuario = createOne;
export const updatePapelUsuario = updateOne;

export const selectPapelUsuarioById = selectOneById;
export const selectPapeisUsuariosByFilters = selectManyByFilters;
export const selectTotalPapeisUsuariosByFilters = selectTotalByFilters;
export const selectPapeisUsuariosLoadingStateByFilters = selectLoadingStateByFilters;
export const selectObjectTodosPapeisUsuarios = selectObjectAllIds;

export const papeisUsuarios = reducerFactory({ context });

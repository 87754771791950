import { actionFactory, reducerFactory, selectorFactory } from '../../store';

const context = 'processoAlteracaoDadosUsuarioCliente';

const { loadMany, loadOne } = actionFactory(context, {
  buildUrlLoadMany: ({ usuarioClienteId }) =>
    `/api/usuarios-clientes/${usuarioClienteId}/processo-alteracao-dados-usuario-cliente`,
  buildUrlLoadOne: ({ processoAlteracaoDadosUsuarioClienteId }) =>
    `/api/processo-alteracao-dados-usuario-cliente/${processoAlteracaoDadosUsuarioClienteId}`,
});

const { selectManyByFilters, selectTotalByFilters, selectLoadingStateByFilters, selectOneById } = selectorFactory({
  context,
});

export const loadProcessosAlteracaoDadosUsuarioCliente = loadMany;
export const loadProcessoAlteracaoDadosUsuarioCliente = loadOne;
export const selectProcessoAlteracaoDadosUsuarioClienteById = selectOneById;
export const selectProcessosAlteracaoDadosUsuarioClienteByFilters = selectManyByFilters;
export const selectTotalProcessosAlteracaoDadosUsuarioClienteByFilters = selectTotalByFilters;
export const selectProcessosAlteracaoDadosUsuarioClienteLoadingStateByFilters = selectLoadingStateByFilters;

export const processoAlteracaoDadosUsuarioCliente = reducerFactory({ context });

import { Provider } from 'react-redux';
import { store } from './store/store';
import { ToastProvider } from './hooks/toast/toast-context';
import AppRoutes from './pages/app.routes';
import { DialogProvider } from './hooks/dialog/dialog-context';
import { BrowserRouter } from 'react-router-dom';

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ToastProvider>
          <DialogProvider>
            <AppRoutes />
          </DialogProvider>
        </ToastProvider>
      </BrowserRouter>
    </Provider>
  );
};

export default App;

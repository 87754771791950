import { Route, Routes } from 'react-router-dom';
import DetalhesTipoBloqueioContaCartaoPage from './detalhes-tipo-bloqueio-conta-cartao-page';
import TiposBloqueioContaCartaoPage from './tipos-bloqueio-conta-cartao-page';

const TiposBloqueioContaCartaoRoutes: React.FC = () => {
  return (
    <Routes>
      <Route index element={<TiposBloqueioContaCartaoPage />} />
      <Route path=":tipoBloqueioContaCartaoId" element={<DetalhesTipoBloqueioContaCartaoPage />} />
    </Routes>
  );
};

export default TiposBloqueioContaCartaoRoutes;

import { reducerFactory, actionFactory, selectorFactory } from '../../store';

const context = 'faturas';

const { loadMany, loadOne } = actionFactory(context, {
  buildUrlLoadOne: ({ faturaId }) => `/api/faturas/${faturaId}`,
  buildUrlLoadMany: ({ contaCartaoId }) => `/api/contas-cartao/${contaCartaoId}/faturas`,
});

const { loadOne: loadOneAberta } = actionFactory(context, {
  buildUrlLoadOne: ({ contaCartaoId }) => `/api/contas-cartao/${contaCartaoId}/fatura-aberta`,
});

const { loadOne: loadOneFechada } = actionFactory(context, {
  buildUrlLoadOne: ({ contaCartaoId }) => `/api/contas-cartao/${contaCartaoId}/fatura-fechada`,
});

const { selectOneById, selectAll, selectManyByFilters, selectTotalByFilters, selectLoadingStateByFilters } =
  selectorFactory({ context });

export const loadFaturaAberta = loadOneAberta;
export const loadUltimaFaturaFechada = loadOneFechada;

export const loadFatura = loadOne;
export const loadFaturas = loadMany;

export const selectFaturaById = selectOneById;
export const selectTodasFaturas = selectAll;
export const selectFaturasByFilters = selectManyByFilters;
export const selectTotalFaturasByFilters = selectTotalByFilters;
export const selectFaturasLoadingStateByFilters = selectLoadingStateByFilters;

export const faturas = reducerFactory({ context });

import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import { useCallback, useMemo } from 'react';
import BSCard from 'react-bootstrap/Card';
import { BsPencilSquare } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import IconButton from '../../components/button/icon-button';
import RbacElement from '../../components/role-based-access-control/role-based-access-control-element';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import ExportCSVButton from '../../components/smart-table/export-csv-button';
import { SmartTable } from '../../components/smart-table/smart-table';
import SmartTableButton from '../../components/smart-table/smart-table-button';
import SmartTableFilters from '../../components/smart-table/smart-table-filters/smart-table-filters';
import {
  SmartTableFilterControlTypesEnum,
  SmartTableFilterProps,
} from '../../components/smart-table/smart-table-filters/table-filter.types';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import Title from '../../components/title';
import {
  ClassTypesEnum,
  FormatValueEnum,
  SizesEnum,
  boolLabelMap,
  codigoRespostaAutorizadorLabelMap,
  formatEnum,
} from '../../helpers';
import { DialogProps } from '../../hooks/dialog/dialog-context';
import { useDialog } from '../../hooks/dialog/use-dialog';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import CadastrarTipoBloqueioCartaoDialog from '../tipos-bloqueio-cartao/cadastrar-tipo-bloqueio-cartao-dialog';
import AtualizarTipoBloqueioCartaoDialog from './atualizar-tipo-bloqueio-cartao';
import {
  loadTiposBloqueiosCartao,
  selectTiposBloqueioCartaoByFilters,
  selectTiposBloqueioCartaoLoadingStateByFilters,
  selectTotalTiposBloqueioCartaoByFilters,
  updateTipoBloqueioCartao,
} from './tipos-bloqueio-cartao.redux';

const smartFilters: SmartTableFilterProps[] = [
  {
    label: 'Código',
    attribute: 'codigo',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
  },
];

const smartColumns = ({
  showDialog,
  closeDialog,
  onUpdateTipoBloqueioCartaoHandler,
}: {
  showDialog: (dialogProps: DialogProps) => void;
  closeDialog: () => void;
  onUpdateTipoBloqueioCartaoHandler: (tipoBloqueioCartaoId: string, data: any) => void;
}): SmartTableColumnProps[] => [
  {
    label: 'Código',
    attribute: 'codigo',
    sortable: true,
    className: 'text-center',
  },
  { label: 'Descrição', attribute: 'descricao', className: 'text-center' },
  {
    label: 'Resposta autorizador',
    attribute: 'respostaAutorizador',
    className: 'text-center',
    format: FormatValueEnum.ENUM,
    map: codigoRespostaAutorizadorLabelMap,
  },
  {
    label: 'Permite Segunda via',
    attribute: 'permiteEmissaoSegundaVia',
    className: 'text-center',
    format: FormatValueEnum.BOOL,
  },
  {
    label: 'Bloqueio definitivo',
    attribute: 'bloqueioDefinitivo',
    sortable: true,
    className: 'text-center',
    format: FormatValueEnum.BOOL,
  },
  {
    label: 'Ações',
    format: (_, tipoBloqueioCartao) => {
      return (
        <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.ALTERACAO_TIPO_BLOQUEIO_CARTAO}>
          <div className="d-flex justify-content-center">
            <div className="mx-1">
              <IconButton
                Icon={BsPencilSquare}
                type={ClassTypesEnum.PRIMARY}
                onClick={() => {
                  showDialog({
                    component: (
                      <AtualizarTipoBloqueioCartaoDialog
                        closeDialog={closeDialog}
                        tipoBloqueioCartao={tipoBloqueioCartao}
                        onUpdateHandler={onUpdateTipoBloqueioCartaoHandler}
                      />
                    ),
                    size: SizesEnum.LARGE,
                  });
                }}
              />
            </div>
          </div>
        </RbacElement>
      );
    },
  },
];

const csvHeaders = [
  { label: 'Código', key: 'codigo' },
  { label: 'Descrição', key: 'descricao' },
  { label: 'Resposta autorizador', key: 'respostaAutorizador' },
  { label: 'Permite segunda via', key: 'permiteEmissaoSegundaVia' },
  { label: 'Bloqueio definitivo', key: 'bloqueioDefinitivo' },
  { label: 'Indica cartão não emitido pelo pescaben', key: 'indicaCartaoNaoEmitidoPescaben' },
  { label: 'Operação', key: 'operacao' },
  { label: 'Permite consultar saldo', key: 'permiteConsultarSaldo' },
];
const TiposBloqueioCartaoPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const { query } = useQuerystring();
  const navigate = useNavigate();
  const { closeDialog, showDialog } = useDialog();
  const { showToast } = useToasts();

  const filters = useMemo(() => ({ ...query }), [query]);
  const total = useSelector((state) => selectTotalTiposBloqueioCartaoByFilters(state, filters));
  const tiposBloqueioCartao = useSelector((state) => selectTiposBloqueioCartaoByFilters(state, filters));
  const loadingState = useSelector((state) => selectTiposBloqueioCartaoLoadingStateByFilters(state, filters));

  const onNovoTipoBloqueioCartapHandler = () => {
    showDialog({
      component: <CadastrarTipoBloqueioCartaoDialog closeDialog={closeDialog} reload={loadItems} />,
      size: SizesEnum.LARGE,
    });
  };

  const onUpdateTipoBloqueioCartaoHandler = (tipoBloqueioCartaoId: string, data: any) => {
    if (!data) {
      return;
    }

    dispatch(updateTipoBloqueioCartao({ params: { tipoBloqueioCartaoId }, data }))
      .then(() => {
        closeDialog();

        showToast({
          message: 'Tipo de bloqueio de cartão atualizado com sucesso',
          type: ClassTypesEnum.SUCCESS,
        });

        return loadItems();
      })
      .catch((error: Error) => {
        showToast({
          message: error.message,
          type: ClassTypesEnum.DANGER,
        });
      });
  };

  const loadItems = useCallback(
    () => dispatch(loadTiposBloqueiosCartao({ query: filters })).catch((error: Error) => error),
    [dispatch, filters]
  );

  const tipoBloqueioExcel = tiposBloqueioCartao?.map(
    ({
      _id,

      ...dadosTipoBloqueio
    }: any) => {
      let novosDados = dadosTipoBloqueio;
      novosDados['indicaCartaoNaoEmitidoPescaben'] = formatEnum(
        dadosTipoBloqueio.indicaCartaoNaoEmitidoPescaben,
        boolLabelMap
      );
      novosDados['permiteConsultarSaldo'] = formatEnum(dadosTipoBloqueio.permiteConsultarSaldo, boolLabelMap);
      novosDados['permiteEmissaoSegundaVia'] = formatEnum(dadosTipoBloqueio.permiteEmissaoSegundaVia, boolLabelMap);
      novosDados['bloqueioDefinitivo'] = formatEnum(dadosTipoBloqueio.bloqueioDefinitivo, boolLabelMap);

      return novosDados;
    }
  );

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_TIPO_BLOQUEIO_CARTAO}>
      <div className="d-flex flex-column h-100">
        <Title>Tipos de bloqueio de cartão</Title>

        <div className="row">
          <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-12 mb-4">
            <SmartTableFilters filters={smartFilters} />
          </div>

          <div className="col-xxl-10 col-xl-9 col-lg-8 col-md-12 mb-4">
            <BSCard>
              <BSCard.Body>
                <div className="d-flex align-items-center mb-2">
                  <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.CRIACAO_TIPO_BLOQUEIO_CARTAO}>
                    <SmartTableButton onClick={onNovoTipoBloqueioCartapHandler}>
                      Novo tipo de bloqueio de cartão
                    </SmartTableButton>
                  </RbacElement>
                  <ExportCSVButton data={tipoBloqueioExcel} headers={csvHeaders} filename="tipos-bloqueio-de-cartao" />
                </div>

                <SmartTable
                  emptyMessage="Nenhum tipo de bloqueio de cartão encontrado"
                  errorMessage="Erro na listagem de tipos de bloqueio de cartão"
                  usePagination={true}
                  loadItems={loadItems}
                  columns={smartColumns({
                    closeDialog,
                    showDialog,
                    onUpdateTipoBloqueioCartaoHandler,
                  })}
                  items={tiposBloqueioCartao}
                  loadingState={loadingState}
                  size={total}
                  onRowClick={(doc) => navigate(doc._id)}
                />
              </BSCard.Body>
            </BSCard>
          </div>
        </div>
      </div>
    </RbacPage>
  );
};

export default TiposBloqueioCartaoPage;

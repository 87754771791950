import BSButton from 'react-bootstrap/Button';
import { BsXOctagon } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import Page from '../../components/page';
import variables from '../../_export.scss';

const AccessDeniedPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Page>
      <div className="d-flex flex-column justify-content-center align-items-center h-100">
        <h1 className="mb-4">Oops!</h1>

        <div className="mb-4">
          <BsXOctagon size={100} color={variables.warning} />
        </div>

        <h2 className="mb-4">Acesso negado</h2>

        <h4 className="mb-4">Você não tem permissão para acessar esse recurso.</h4>

        <BSButton onClick={() => navigate('/')}>Ir para página inicial</BSButton>
      </div>
    </Page>
  );
};

export default AccessDeniedPage;

import { reducerFactory, actionFactory, selectorFactory } from '../../store';

const context = 'sequenciais';

const { loadMany } = actionFactory(context, {
  buildUrlLoadMany: () => '/api/sequenciais',
});

const { selectManyByFilters, selectLoadingStateByFilters, selectTotalByFilters } = selectorFactory({ context });

export const loadSequenciais = loadMany;
export const selectSequenciais = selectManyByFilters;
export const selectTotalSequenciaisByFilters = selectTotalByFilters;
export const selectLoadingStateByFiltersSequencial = selectLoadingStateByFilters;

export const sequenciais = reducerFactory({ context });

import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import { useCallback, useEffect, useState } from 'react';
import BSButton from 'react-bootstrap/Button';
import BSModal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { Form } from '../../components/form/form';
import { FormControl } from '../../components/form/form-control';
import { FormSelect } from '../../components/form/form-select';
import RbacElement from '../../components/role-based-access-control/role-based-access-control-element';
import { ApiSingleElementResponse, ClassTypesEnum, statusSolicitacaoClienteLabelMap } from '../../helpers';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch } from '../../store/hooks-redux';
import { saveInteracaoSolicitacaoCliente } from './interacao-solicitacao-cliente.redux';
import { gerarProtocoloSolicitacao } from './protocolo-solicitacao-cliente.redux';

type CadastrarInteracaoSolicitacaoClienteFormFields = {
  descricao: string;
  status: string;
};

type CadastrarInteracaoSolicitacaoClienteDialogProps = {
  solicitacaoClienteId: string;
  closeDialog: () => void;
  reload: () => void;
};

const CadastrarInteracaoSolicitacaoClienteDialog: React.FC<CadastrarInteracaoSolicitacaoClienteDialogProps> = ({
  solicitacaoClienteId,
  closeDialog,
  reload,
}) => {
  const dispatch = useAppDispatch();

  const { showToast } = useToasts();

  const form = useForm<CadastrarInteracaoSolicitacaoClienteFormFields>();
  const { control } = form;

  // TODO: reavalidar uso dos useState
  const [numeroDoProtocolo, setNumeroDoProtocolo] = useState<string | null>();
  const [deveGerarProtocolo, setDeveGerarProtocolo] = useState<boolean>(true);

  const onSubmitHandler = (data: any) => {
    if (!data) {
      return;
    }

    setDeveGerarProtocolo(true);

    dispatch(
      saveInteracaoSolicitacaoCliente({
        params: { solicitacaoClienteId },
        data: { ...data, numeroDoProtocolo },
      })
    )
      .then(() => {
        closeDialog();

        showToast({
          message: 'Interação incluída com sucesso',
          type: ClassTypesEnum.SUCCESS,
        });

        return reload();
      })
      .catch((error: Error) => {
        showToast({
          message: error.message,
          type: ClassTypesEnum.DANGER,
        });
      });
  };

  const loadNumeroProtocolo = useCallback(() => {
    dispatch(gerarProtocoloSolicitacao({ data: { deveGerarProtocolo } }))
      .then(({ payload: { data } }: ApiSingleElementResponse) => {
        setDeveGerarProtocolo(false);
        setNumeroDoProtocolo(data.protocoloSolicitacao as string);

        return data;
      })
      .catch((error: Error) => error);
  }, [deveGerarProtocolo, dispatch]);

  useEffect(() => {
    loadNumeroProtocolo();
  }, [loadNumeroProtocolo]);

  return (
    <>
      <BSModal.Header closeButton>
        <BSModal.Title>Cadastrar interação de solicitação de cliente</BSModal.Title>
      </BSModal.Header>

      <BSModal.Body>
        <Form id="form" form={form} onSubmit={onSubmitHandler}>
          <div className="mb-4 d-flex">
            <h5>Protocolo {numeroDoProtocolo}</h5>
          </div>

          <FormControl
            control={control}
            className="mb-3"
            name="descricao"
            label="Descrição"
            as="textarea"
            rows={7}
            rules={{ required: true }}
          />

          <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.ALTERACAO_STATUS_SOLICITACAO_CLIENTE}>
            <FormSelect
              control={control}
              className="mb-3"
              name="status"
              label="Novo status da solicitação"
              placeholder="Selecione o novo status"
              options={Object.entries(statusSolicitacaoClienteLabelMap)?.map(([chave, valor]) => ({
                key: chave,
                value: chave,
                label: valor,
              }))}
              rules={{ required: true }}
            />
          </RbacElement>
        </Form>
      </BSModal.Body>

      <BSModal.Footer>
        <BSButton variant="light" onClick={closeDialog}>
          Fechar
        </BSButton>
        <BSButton variant="primary" type="submit" form="form">
          Realizar interação
        </BSButton>
      </BSModal.Footer>
    </>
  );
};

export default CadastrarInteracaoSolicitacaoClienteDialog;

import { useEffect, useState } from 'react';
import BSForm from 'react-bootstrap/Form';
import BSInputGroup from 'react-bootstrap/InputGroup';

import { FormControlProps as BSFormControlProps } from 'react-bootstrap/FormControl';
import { AsProp } from 'react-bootstrap/helpers';
import { FieldValue, FieldValues, useController, UseControllerProps } from 'react-hook-form';
import {
  NumberFormatBase,
  NumberFormatBaseProps,
  NumberFormatValues,
  NumericFormat,
  NumericFormatProps,
  PatternFormat,
  PatternFormatProps,
} from 'react-number-format';
import { getOnlyNumbers } from '../../helpers';
import FormControlValidationFeedback from './form-control-validation-feedback';
import FormLabel from './form-label';

export enum MasksFormControlEnum {
  PERCENT = 'percent',
  BRL = 'brl',
  CPF_CNPJ = 'cpfCnpj',
  COTACAO = 'cotacao',
  MCC = 'mcc',
}

type TextAreaProps = {
  rows?: number;
  cols?: number;
  wrap?: 'hard' | 'soft';
};

interface FormControlProps<TFormValues extends FieldValues>
  extends Omit<BSFormControlProps, 'defaultValue' | 'name'>,
    TextAreaProps,
    UseControllerProps<TFormValues> {
  label?: string;
  mask?: MasksFormControlEnum;
  prefixComponent?: JSX.Element;
  suffixComponent?: JSX.Element;
  dica?: string | JSX.Element;
}

export const FormControl = <TFormValues extends FieldValues>({
  control,
  name,
  label,
  rules,
  className,
  shouldUnregister,
  mask,
  prefixComponent = <></>,
  suffixComponent = <></>,
  dica,
  ...props
}: FormControlProps<TFormValues>): JSX.Element => {
  const defaultValue = '' as FieldValue<TFormValues>;

  const {
    field: { onChange, onBlur, name: fieldName, value },
    fieldState: { error },
  } = useController<TFormValues>({
    name,
    control,
    rules,
    defaultValue: defaultValue,
    shouldUnregister,
  });

  const [displayValue, setdisplayValue] = useState<string>(value as string);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const eventValue = event.target.value;

    if (!mask) {
      setdisplayValue(eventValue);
      props.onChange?.(event);
      onChange(eventValue);

      return;
    }

    const unmaskedValue = unmaskValue(eventValue, mask);

    props.onChange?.({
      ...event,
      target: { ...event.target, value: unmaskedValue.toString() },
    });
    onChange(unmaskedValue);
  };

  const onBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
    props.onBlur?.(event);
    onBlur();
  };

  const getFormControlMaskProps = (
    mask: MasksFormControlEnum,
    controlValue: FieldValue<TFormValues>
  ): (
    | Omit<NumericFormatProps, 'size' | 'defaultValue'>
    | Omit<PatternFormatProps, 'size' | 'defaultValue'>
    | Omit<NumberFormatBaseProps, 'size' | 'defaultValue'>
  ) &
    AsProp => {
    const onValueChangeHandler = (values: NumberFormatValues) => {
      setdisplayValue(values?.formattedValue ?? '');
    };

    switch (mask) {
      case MasksFormControlEnum.BRL:
        return {
          as: NumberFormatBase,
          onValueChange: onValueChangeHandler,
          format: (value: string) => {
            const isNullLike = value === '' || value === '00';

            if (isNullLike) {
              return '';
            }

            return new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(parseInt(value) / 100);
          },
        };

      case MasksFormControlEnum.CPF_CNPJ: {
        const cpfLength = 11;
        const unmaskedValueLength = getOnlyNumbers(controlValue)?.length;
        const exactCpfLength = unmaskedValueLength === cpfLength;

        return {
          as: PatternFormat,
          format: exactCpfLength
            ? '###.###.###-###'
            : unmaskedValueLength && unmaskedValueLength < cpfLength
              ? '###.###.###-##'
              : '##.###.###/####-##',
          mask: exactCpfLength ? '' : '_',
        };
      }

      case MasksFormControlEnum.PERCENT: {
        return {
          as: NumericFormat,
          suffix: '%',
          decimalSeparator: ',',
          thousandSeparator: '.',
          allowedDecimalSeparators: [',', '.'],
        };
      }

      case MasksFormControlEnum.MCC:
        return {
          as: NumberFormatBase,
          onValueChange: onValueChangeHandler,
          format: (value: string) => {
            const isNullValue = value === '' || value === '0';

            if (isNullValue) {
              return '';
            }
            const numericValue = value.toString().slice(-4);

            return numericValue.padStart(4, '0');
          },
        };

      case MasksFormControlEnum.COTACAO: {
        return {
          as: NumericFormat,
          prefix: 'R$: ',
          decimalSeparator: ',',
          thousandSeparator: '.',
          decimalScale: 4,
          allowedDecimalSeparators: [',', '.'],
        };
      }
      default:
        return {};
    }
  };

  const unmaskValue = (value: string, format: MasksFormControlEnum): number | string => {
    if (value === '') {
      return '';
    }

    switch (format) {
      case MasksFormControlEnum.BRL:
        return Number(getOnlyNumbers(value));

      case MasksFormControlEnum.CPF_CNPJ:
        return getOnlyNumbers(value);

      case MasksFormControlEnum.PERCENT:
        return parseFloat(value.replace(',', '.'));

      case MasksFormControlEnum.COTACAO:
        return parseFloat(value.replace(',', '.'));

      case MasksFormControlEnum.MCC:
        return getOnlyNumbers(value);

      default:
        return value;
    }
  };

  useEffect(() => {
    if (!value) {
      setdisplayValue('');
    }
  }, [value]);

  return (
    <BSForm.Group data-testid="form-control" className={className}>
      {label && <FormLabel label={label} required={Boolean(rules?.required)} dica={dica} />}

      <BSInputGroup>
        {prefixComponent}

        <BSForm.Control
          {...props}
          {...(mask && { ...getFormControlMaskProps(mask, value) })}
          aria-invalid={Boolean(error)}
          onChange={onChangeHandler}
          onBlur={onBlurHandler}
          isInvalid={Boolean(error)}
          value={displayValue}
          name={fieldName}
        />

        {suffixComponent}

        <FormControlValidationFeedback error={error} rules={rules} />
      </BSInputGroup>
    </BSForm.Group>
  );
};

import BSButton from 'react-bootstrap/Button';
import BSModal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { Form } from '../../components/form/form';
import { FormCheck } from '../../components/form/form-check';
import { FormControl } from '../../components/form/form-control';
import { FormSelect } from '../../components/form/form-select';
import {
  labelOperacaoTransacaoLabelMap,
  tipoEventosContabeisLabelMap,
  tipoRoteiroContabilLabelMap,
} from '../../helpers';
import { useAppSelector } from '../../store/hooks-redux';
import { selectTodosTiposTransacao } from '../tipos-transacao/tipos-transacao.redux';

type AtualizarRoteiroContabilFormFields = {
  descricao: string;
  contaContabilDebito: string;
  contaContabilCredito: string;
  habilitado: boolean;
  statusConta: string;
  tipoTransacao: string;
  eventoContabil: string;
  tipoRoteiro: string;
  labelTipoTransacao: string;
};

type AtualizarRoteiroContabilProps = {
  roteiroContabil: any;
  closeDialog: () => void;
  onUpdateHandler: (roteiroContabilId: string, data: any) => void;
};

const AtualizarRoteiroContabilDialog: React.FC<AtualizarRoteiroContabilProps> = ({
  closeDialog,
  roteiroContabil,
  onUpdateHandler,
}) => {
  const useSelector = useAppSelector;

  const form = useForm<AtualizarRoteiroContabilFormFields>({ defaultValues: roteiroContabil });
  const { control } = form;
  const onSubmitHandler = (data: AtualizarRoteiroContabilFormFields) => {
    if (!Object.values(data).length) {
      return;
    }
    onUpdateHandler(roteiroContabil._id, data);
  };

  const tiposTransacoes = useSelector((state) => selectTodosTiposTransacao(state));
  return (
    <>
      <BSModal.Header closeButton>
        <BSModal.Title>Atualizar Roteiro contábil</BSModal.Title>
      </BSModal.Header>

      <BSModal.Body>
        <Form id="form" form={form} onSubmit={onSubmitHandler}>
          <div className="row">
            <FormControl
              control={control}
              className="col-md-6 mb-3"
              name="descricao"
              label="Descrição"
              placeholder="Descrição"
              rules={{ required: true }}
            />
            <FormControl
              control={control}
              className="col-md-6 mb-3"
              name="contaContabilDebito"
              label="Conta Contabil Débito"
              placeholder="Conta Contabil Débito "
              rules={{ required: true }}
            />
            <FormControl
              control={control}
              className="col-md-6 mb-3"
              name="contaContabilCredito"
              label="Conta Contabil Credito"
              placeholder="Conta Contabil Crédito  "
              rules={{ required: true }}
            />
            <FormControl
              control={control}
              className="col-md-6 mb-3"
              name="statusConta"
              label="Status da conta"
              placeholder="Status da conta "
              rules={{ required: true }}
            />
            <FormSelect
              control={control}
              name="tipoTransacao"
              placeholder="Tipo de transação"
              label="Selecione o tipo de transação"
              className="col-md-6 mb-3"
              options={tiposTransacoes?.map((tipoTransacao: any) => ({
                key: tipoTransacao._id,
                value: tipoTransacao._id,
                label: `${tipoTransacao.descricao}`,
              }))}
              rules={{ required: true }}
            />
            <FormSelect
              control={control}
              name="labelTipoTransacao"
              placeholder="Label de tipo de transação"
              label="Label de tipo de transação"
              className="col-md-6 mb-3"
              options={Object.entries(labelOperacaoTransacaoLabelMap).map(([key, value]) => ({
                key,
                label: value,
                value: key,
              }))}
              rules={{ required: true }}
            />
            <FormSelect
              control={control}
              name="eventoContabil"
              placeholder="Tipo de evento contábil"
              label="Tipo de evento contábil"
              className="col-md-6 mb-3"
              options={Object.entries(tipoEventosContabeisLabelMap).map(([key, value]) => ({
                key,
                label: value,
                value: key,
              }))}
              rules={{ required: true }}
            />

            <FormSelect
              control={control}
              name="tipoRoteiro"
              placeholder="Tipo roteiro"
              label="Tipo roteiro"
              className="col-md-6 mb-3 "
              options={Object.entries(tipoRoteiroContabilLabelMap).map(([key, value]) => ({
                key,
                label: value,
                value: key,
              }))}
              rules={{ required: true }}
            />

            <FormCheck control={control} className="col-md-6 mb-3" name="habilitado" label="habilitado" type="switch" />
          </div>
        </Form>
      </BSModal.Body>
      <BSModal.Footer>
        <BSButton variant="light" onClick={closeDialog}>
          Fechar
        </BSButton>
        <BSButton variant="primary" type="submit" form="form">
          Atualizar Roteiro Contábil
        </BSButton>
      </BSModal.Footer>
    </>
  );
};

export default AtualizarRoteiroContabilDialog;

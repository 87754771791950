import { actionFactory, reducerFactory, selectorFactory } from '../../store';

const context = 'sessionUsuario';

const { loadOne, deleteOne, createOne } = actionFactory(context, {
  buildUrlLoadOne: () => '/api/session',
  buildUrlDeleteOne: () => '/api/session',
  buildUrlCreateOne: () => '/api/session',
});

const { selectLoadingStateByFilters, selectManyByFilters } = selectorFactory({ context });

export const loadSessionUsuario = loadOne;
export const logout = deleteOne;
export const auth = createOne;

export const selectSessionUsuario = selectManyByFilters;
export const selectSessionUsuarioLoadingStateByFilter = selectLoadingStateByFilters;

export const sessionUsuario = reducerFactory({ context });

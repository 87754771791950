import { useEffect, useMemo } from 'react';
import BSAlert from 'react-bootstrap/Alert';
import BSBadge from 'react-bootstrap/Badge';
import { useParams } from 'react-router-dom';
import { DetailCard } from '../../components/details/datail-card';
import DetailElement from '../../components/details/detail-element';
import DetailSubTitle from '../../components/details/detail-subtitle';
import DetailTitle from '../../components/details/detail-title';
import { Loading } from '../../components/loading';
import Page from '../../components/page';
import {
  ClassTypesEnum,
  FormatValueEnum,
  codigoConfirmacaoClienteLabelMap,
  statusProcessoAlteracaoDadosUsuarioClienteColorMap,
  statusProcessoAlteracaoDadosUsuarioClienteLabelMap,
  statusProcessamentoDocumentacaoProcessoAlteracaoDadosUsuarioClienteLabelMap,
  statusProcessamentoLivenessProcessoAlteracaoDadosUsuarioClienteLabelMap,
} from '../../helpers';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import {
  loadProcessoAlteracaoDadosUsuarioCliente,
  selectProcessoAlteracaoDadosUsuarioClienteById,
  selectProcessosAlteracaoDadosUsuarioClienteLoadingStateByFilters,
} from './processo-alteracao-dados-usuario-cliente.redux';

const DetalhesProcessoAlteracaoDadosUsuarioClientePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const params = useParams();
  const processoAlteracaoDadosUsuarioClienteId = params.processoAlteracaoDadosUsuarioClienteId as string;

  const filters = useMemo(() => ({ processoAlteracaoDadosUsuarioClienteId }), [processoAlteracaoDadosUsuarioClienteId]);
  const processoAlteracaoDadosAcessoUsuarioCliente = useSelector((state) =>
    selectProcessoAlteracaoDadosUsuarioClienteById(state, processoAlteracaoDadosUsuarioClienteId)
  );
  const loadingState = useSelector((state) =>
    selectProcessosAlteracaoDadosUsuarioClienteLoadingStateByFilters(state, filters)
  );

  useEffect(() => {
    dispatch(loadProcessoAlteracaoDadosUsuarioCliente({ processoAlteracaoDadosUsuarioClienteId })).catch(
      (error: Error) => error
    );
  }, [processoAlteracaoDadosUsuarioClienteId, dispatch]);

  if (!processoAlteracaoDadosAcessoUsuarioCliente) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Loading notFoundMessage="Alteração de dados de acesso não encontrada" loadingState={loadingState} />
      </div>
    );
  }

  return (
    <Page>
      <DetailCard>
        <div className="row">
          <div className="col-md-8 col-sm-12 mb-3">
            <DetailTitle>Alteração de dados de acesso ao aplicativo móvel</DetailTitle>
          </div>

          <div className="col-md-4 col-sm-12 text-md-end text-sm-start mb-5">
            <h5 className="mb-0">
              <BSBadge
                pill
                bg={
                  statusProcessoAlteracaoDadosUsuarioClienteColorMap[
                    processoAlteracaoDadosAcessoUsuarioCliente.status as keyof typeof statusProcessoAlteracaoDadosUsuarioClienteColorMap
                  ]
                }
              >
                {
                  statusProcessoAlteracaoDadosUsuarioClienteLabelMap[
                    processoAlteracaoDadosAcessoUsuarioCliente.status as keyof typeof statusProcessoAlteracaoDadosUsuarioClienteLabelMap
                  ]
                }
              </BSBadge>
            </h5>
          </div>

          <div className="row">
            <div className="col-lg-5 col-md-6 mb-4">
              <DetailElement
                descricao="Tipo da alteração"
                valor={processoAlteracaoDadosAcessoUsuarioCliente.tipoAlteracao}
                format={FormatValueEnum.ENUM}
                map={codigoConfirmacaoClienteLabelMap}
              />
              <DetailElement
                descricao="Data e hora do início do processo"
                valor={processoAlteracaoDadosAcessoUsuarioCliente.dataHoraInicio}
                format={FormatValueEnum.DATE_TIME}
              />
              <DetailElement
                descricao="Data e hora de finalização do processo"
                valor={processoAlteracaoDadosAcessoUsuarioCliente.dataHoraFinalizacao}
                format={FormatValueEnum.DATE_TIME}
              />
              <DetailElement
                descricao="Código de confirmação validado por e-mail"
                valor={processoAlteracaoDadosAcessoUsuarioCliente.emailValidado ?? false}
                format={FormatValueEnum.BOOL}
              />
              <DetailElement
                descricao="Código de confirmação validado SMS/telefone"
                valor={processoAlteracaoDadosAcessoUsuarioCliente.telefoneValidado ?? false}
                format={FormatValueEnum.BOOL}
              />
            </div>

            <div className="row">
              {Boolean(processoAlteracaoDadosAcessoUsuarioCliente?.processamentoLiveness) && (
                <div className="col-lg-6 col-md-6 mb-4">
                  <div className="mb-3">
                    <DetailSubTitle>Processamento do Liveness</DetailSubTitle>
                  </div>

                  <DetailElement
                    descricao="Data e hora do início do processamento"
                    valor={
                      processoAlteracaoDadosAcessoUsuarioCliente.processamentoLiveness?.dataHoraInicioProcessamento
                    }
                    format={FormatValueEnum.DATE_TIME}
                  />
                  <DetailElement
                    descricao="Data e hora de finalização do processamento"
                    valor={processoAlteracaoDadosAcessoUsuarioCliente.processamentoLiveness?.dataHoraFimProcessamento}
                    format={FormatValueEnum.DATE_TIME}
                  />
                  <DetailElement
                    descricao="Status"
                    valor={processoAlteracaoDadosAcessoUsuarioCliente.processamentoLiveness?.status}
                    format={FormatValueEnum.ENUM}
                    map={statusProcessamentoLivenessProcessoAlteracaoDadosUsuarioClienteLabelMap}
                  />
                  <DetailElement
                    descricao="Número de acesso à documentação"
                    valor={processoAlteracaoDadosAcessoUsuarioCliente.processamentoLiveness?.numeroAcessoDocumentacao}
                  />
                  <DetailElement
                    descricao="Token de validação do SDK"
                    valor={processoAlteracaoDadosAcessoUsuarioCliente.processamentoLiveness?.tokenValidacaoSDK}
                  />
                </div>
              )}

              {Boolean(processoAlteracaoDadosAcessoUsuarioCliente?.processamentoDocumentacao) && (
                <div className="col-lg-6 col-md-6 mb-4">
                  <div className="mb-3">
                    <DetailSubTitle>Processamento da documentação</DetailSubTitle>
                  </div>

                  <DetailElement
                    descricao="Data e hora do início do processamento"
                    valor={
                      processoAlteracaoDadosAcessoUsuarioCliente.processamentoDocumentacao?.dataHoraInicioProcessamento
                    }
                    format={FormatValueEnum.DATE_TIME}
                  />
                  <DetailElement
                    descricao="Data e hora de finalização do processamento"
                    valor={
                      processoAlteracaoDadosAcessoUsuarioCliente.processamentoDocumentacao?.dataHoraFimProcessamento
                    }
                    format={FormatValueEnum.DATE_TIME}
                  />
                  <DetailElement
                    descricao="Status"
                    valor={processoAlteracaoDadosAcessoUsuarioCliente.processamentoDocumentacao?.status}
                    format={FormatValueEnum.ENUM}
                    map={statusProcessamentoDocumentacaoProcessoAlteracaoDadosUsuarioClienteLabelMap}
                  />
                  <DetailElement
                    descricao="Número de acesso à documentação"
                    valor={
                      processoAlteracaoDadosAcessoUsuarioCliente.processamentoDocumentacao?.numeroAcessoDocumentacao
                    }
                  />
                  <DetailElement
                    descricao="Token de validação do SDK"
                    valor={processoAlteracaoDadosAcessoUsuarioCliente.processamentoDocumentacao?.tokenValidacaoSDK}
                  />
                </div>
              )}
            </div>

            {Boolean(processoAlteracaoDadosAcessoUsuarioCliente?.motivoReprovacao) && (
              <BSAlert variant={ClassTypesEnum.DANGER}>
                <DetailElement
                  descricao="Motivo da reprovação"
                  valor={processoAlteracaoDadosAcessoUsuarioCliente.motivoReprovacao}
                />
              </BSAlert>
            )}
          </div>
        </div>
      </DetailCard>
    </Page>
  );
};

export default DetalhesProcessoAlteracaoDadosUsuarioClientePage;

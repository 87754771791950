import BSButton from 'react-bootstrap/Button';
import BSModal from 'react-bootstrap/Modal';

import { useForm } from 'react-hook-form';
import { Form } from '../../components/form/form';
import { MasksFormControlEnum, FormControl } from '../../components/form/form-control';

type AtualizarItemDoacaoFormFields = {
  descricao: string;
  valor: string;
  limiteMinimoFaixa: string;
  limiteMaximoFaixa?: string;
};

type AtualizarItemDoacaoDialogProps = {
  itemDoacao: any;
  closeDialog: () => void;
  onUpdateHandler: (itemDoacaoId: string, data: any) => void;
};

const AtualizarItemDoacaoDialog: React.FC<AtualizarItemDoacaoDialogProps> = ({
  closeDialog,
  itemDoacao,
  onUpdateHandler,
}) => {
  const form = useForm<AtualizarItemDoacaoFormFields>({ defaultValues: itemDoacao });
  const { control } = form;

  const onSubmitHandler = (data: AtualizarItemDoacaoFormFields) => {
    onUpdateHandler(itemDoacao._id, data);
  };

  return (
    <>
      <BSModal.Header closeButton>
        <BSModal.Title>Atualizar item de doação - {itemDoacao.descricao}</BSModal.Title>
      </BSModal.Header>

      <BSModal.Body>
        <Form id="form" form={form} onSubmit={onSubmitHandler}>
          <FormControl
            control={control}
            className="mb-3"
            label="Descrição"
            name="descricao"
            rules={{ required: true }}
          />

          <FormControl
            control={control}
            className="mb-3"
            label="Valor unitário"
            name="valor"
            rules={{ required: true }}
            mask={MasksFormControlEnum.BRL}
          />

          <FormControl
            control={control}
            className="mb-3"
            label="Limite mínimo da faixa"
            name="limiteMinimoFaixa"
            rules={{ required: true }}
            mask={MasksFormControlEnum.BRL}
          />

          <FormControl
            control={control}
            className="mb-3"
            label="Limite máximo da faixa"
            name="limiteMaximoFaixa"
            mask={MasksFormControlEnum.BRL}
          />
        </Form>
      </BSModal.Body>

      <BSModal.Footer>
        <BSButton variant="light" onClick={closeDialog}>
          Fechar
        </BSButton>
        <BSButton variant="primary" type="submit" form="form">
          Atualizar item de doação
        </BSButton>
      </BSModal.Footer>
    </>
  );
};

export default AtualizarItemDoacaoDialog;

import { Route, Routes } from 'react-router-dom';

import CategoriasBeneficiosPage from './categorias-beneficios-page';

const CategoriasBeneficiosRoutes: React.FC = () => {
  return (
    <Routes>
      <Route index element={<CategoriasBeneficiosPage />} />
    </Routes>
  );
};

export default CategoriasBeneficiosRoutes;

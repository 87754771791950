import BSAlert from 'react-bootstrap/Alert';
import BSBadge from 'react-bootstrap/Badge';
import BSCard from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import DetailElement from '../../components/details/detail-element';
import DetailSubTitle from '../../components/details/detail-subtitle';
import {
  ClassTypesEnum,
  statusSolicitacaoClienteColorMap,
  statusSolicitacaoClienteLabelMap,
  formatDateTime,
  FormatValueEnum,
} from '../../helpers';

type InteracaoSolicitacaoClienteCardProps = {
  interacao: any;
  usuarios?: any;
  contaCartao?: any;
  categoriasBeneficios?: { [key: string]: any };
};

const InteracaoSolicitacaoClienteCard: React.FC<InteracaoSolicitacaoClienteCardProps> = ({
  interacao,
  usuarios = {},
  contaCartao,
  categoriasBeneficios,
}) => {
  return (
    <BSCard className="p-md-5 p-3">
      <div className="row">
        <div className="col-md-5 col-sm-12 mb-3">
          <span>
            <strong>Protocolo {interacao.numeroDoProtocolo}</strong>
          </span>
        </div>

        <div className="col-md-5 col-sm-12 mb-3">
          <DetailElement className="mb-0" descricao="Origem alteração" valor={interacao.origemAlteracao} />
        </div>

        <div className="col-md-2 col-sm-12 mb-4 text-md-end text-sm-start">
          <h5>
            <BSBadge
              pill
              bg={statusSolicitacaoClienteColorMap[interacao.status as keyof typeof statusSolicitacaoClienteColorMap]}
            >
              {statusSolicitacaoClienteLabelMap[interacao.status as keyof typeof statusSolicitacaoClienteColorMap]}
            </BSBadge>
          </h5>
        </div>
      </div>

      <div className="mb-4">
        <p>{interacao.descricao}</p>
      </div>

      {interacao.transacaoAjusteFinanceiro && (
        <BSAlert variant={ClassTypesEnum.SECONDARY} className="mb-4">
          <div className="ps-3">
            <div className="mb-2">
              <Link to={`/contas-cartao/${contaCartao?._id}/transacoes/${interacao.transacaoAjusteFinanceiro._id}`}>
                <DetailSubTitle>Transação de ajuste financeiro</DetailSubTitle>
              </Link>
            </div>

            {interacao.transacaoAjusteFinanceiro.transacaoOrigemId && (
              <DetailElement
                descricao="Transação origem"
                valor="Detalhes"
                link={`/contas-cartao/${contaCartao?._id}/transacoes/${interacao.transacaoAjusteFinanceiro.transacaoOrigemId}`}
              />
            )}
            <DetailElement
              descricao="Tipo da transação"
              valor={interacao.transacaoAjusteFinanceiro.tipoTransacao.descricao}
            />
            {interacao.transacaoAjusteFinanceiro.categoriaBeneficio && (
              <DetailElement
                descricao="Categoria de benefício"
                valor={categoriasBeneficios?.[interacao.transacaoAjusteFinanceiro.categoriaBeneficio]?.nome}
              />
            )}
            <DetailElement
              descricao="Valor"
              valor={interacao.transacaoAjusteFinanceiro.valor}
              format={FormatValueEnum.BRL}
            />
            {interacao.transacaoAjusteFinanceiro.valorIntransferivel && (
              <DetailElement
                descricao="Valor intransferível"
                valor={interacao.transacaoAjusteFinanceiro.valorIntransferivel}
                format={FormatValueEnum.BRL}
              />
            )}
            <DetailElement
              descricao="Limite de crédito afetado"
              valor={interacao.transacaoAjusteFinanceiro.limiteCreditoAfetado}
              format={FormatValueEnum.BOOL}
            />
            <DetailElement
              descricao="Faturada"
              valor={interacao.transacaoAjusteFinanceiro.faturada}
              format={FormatValueEnum.BOOL}
            />
          </div>
        </BSAlert>
      )}

      <div className="d-flex ">
        <span className="text-hint me-1">
          <em>{usuarios[interacao.usuarioId as keyof typeof usuarios]?.username}</em>
        </span>
        <span className="text-hint text-muted me-1">
          - atualizado em: {formatDateTime(interacao.dataHoraInteracao)}
        </span>
      </div>
    </BSCard>
  );
};

export default InteracaoSolicitacaoClienteCard;

import { Route, Routes } from 'react-router-dom';

import DetalhesPedidoAutorizacaoPage from './detalhes-pedido-autorizacao-page';
import PedidosAutorizacaoPage from './pedidos-autorizacao-page';

const PedidosAutorizacaoRoutes: React.FC = () => {
  return (
    <Routes>
      <Route index element={<PedidosAutorizacaoPage />} />
      <Route path=":pedidoAutorizacaoId" element={<DetalhesPedidoAutorizacaoPage />} />
    </Routes>
  );
};

export default PedidosAutorizacaoRoutes;

import { useCallback, useMemo } from 'react';
import BSCard from 'react-bootstrap/Card';

import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import { SmartTable } from '../../components/smart-table/smart-table';
import SmartTableFilters from '../../components/smart-table/smart-table-filters/smart-table-filters';
import {
  SmartTableFilterControlTypesEnum,
  SmartTableFilterProps,
} from '../../components/smart-table/smart-table-filters/table-filter.types';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import Title from '../../components/title';
import { FormatValueEnum } from '../../helpers';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import {
  loadTaxasMediaSelic,
  selectTaxasMediasSelicByFilters,
  selectTaxasMediasSelicLoadingStateByFilters,
  selectTotalTaxasMediasSelicByFilters,
} from './taxas-medias-selic.redux';

const smartFilters = (): SmartTableFilterProps[] => [
  {
    label: 'Data',
    attribute: 'data',
    controlType: SmartTableFilterControlTypesEnum.DATE_INPUT,
  },
];

const smartColumns = (): SmartTableColumnProps[] => [
  {
    label: 'Data',
    attribute: 'data',
    format: FormatValueEnum.DATA,
    sortable: true,
    className: 'text-center',
  },
  {
    label: 'Percentual',
    attribute: 'percentual',
    format: FormatValueEnum.PERCENT,
    sortable: true,
    className: 'text-right',
  },
];

const TaxasMediasSelicPage = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const { query } = useQuerystring();

  if (!query.sort) {
    query.sort = '-data';
  }

  const filters = useMemo(() => ({ ...query }), [query]);
  const total = useSelector((state) => selectTotalTaxasMediasSelicByFilters(state, filters));
  const taxasMediasSelic = useSelector((state) => selectTaxasMediasSelicByFilters(state, filters));
  const loadingState = useSelector((state) => selectTaxasMediasSelicLoadingStateByFilters(state, filters));

  const loadItems = useCallback(
    () => dispatch(loadTaxasMediaSelic({ query: filters })).catch((error: Error) => error),
    [dispatch, filters]
  );

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_TAXA_MEDIA_SELIC}>
      <div className="d-flex flex-column h-100">
        <Title>Taxa selic</Title>

        <div className="row">
          <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-12 mb-4">
            <SmartTableFilters filters={smartFilters()} />
          </div>

          <div className="col-xxl-10 col-xl-9 col-lg-8 col-md-12 mb-4">
            <BSCard>
              <BSCard.Body>
                <SmartTable
                  emptyMessage="Nenhuma taxa selic encontrada"
                  errorMessage="Erro na listagem de taxas selic"
                  usePagination={true}
                  loadItems={loadItems}
                  columns={smartColumns()}
                  items={taxasMediasSelic}
                  loadingState={loadingState}
                  size={total}
                />
              </BSCard.Body>
            </BSCard>
          </div>
        </div>
      </div>
    </RbacPage>
  );
};

export default TaxasMediasSelicPage;

import BSButton, { ButtonProps as BSButtonProps } from 'react-bootstrap/Button';

const SmartTableButton: React.FC<BSButtonProps> = ({ children, ...props }) => {
  return (
    <div className="me-1 mb-1">
      <BSButton data-testid="smart-table-button" {...props}>
        {children}
      </BSButton>
    </div>
  );
};

export default SmartTableButton;

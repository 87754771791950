import { Route, Routes } from 'react-router-dom';

import CalendariosCortePage from './calendario-corte-page';

const CalendariosCortesRoutes: React.FC = () => {
  return (
    <Routes>
      <Route index element={<CalendariosCortePage />} />
    </Routes>
  );
};

export default CalendariosCortesRoutes;

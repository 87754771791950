import { useEffect } from 'react';
import BSTable from 'react-bootstrap/Table';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { Loading } from '../loading';
import { Pagination } from './pagination/pagination';
import { SmartTableBody } from './smart-table-body';
import { SmartTableColumnProps, SmartTableHeader } from './smart-table-header';
import { QueryResult } from './query-result';
import { LoadingStateType } from '../../helpers';

type SmartTableProps = InnerTableProps & {
  loadItems?: () => void;
  usePagination?: boolean;
  size?: number;
};

export const SmartTable: React.FC<SmartTableProps> = ({ loadItems, ...props }) => {
  const { query } = useQuerystring();

  useEffect(() => {
    loadItems?.();
  }, [loadItems, query]);

  return (
    <div data-testid="smart-table">
      <InnerTable {...props} />

      {Boolean(props.size) && <QueryResult size={props.size} />}

      {Boolean(props.usePagination && props.size) && <Pagination size={props.size ?? 0} />}
    </div>
  );
};

type InnerTableProps = {
  columns: SmartTableColumnProps[];
  items?: any[];
  actions?: any;
  onRowClick?: (doc: any) => any;
  loadingState?: LoadingStateType;
  errorMessage?: string;
  emptyMessage?: string | JSX.Element;
};

const InnerTable: React.FC<InnerTableProps> = ({
  columns,
  items = [],
  onRowClick,
  loadingState,
  errorMessage,
  emptyMessage,
}) => {
  const loadedWithItems = (loadingState === 'loaded' && items?.length > 0) || (!loadingState && items?.length > 0);
  const loadedWithoutItems = (loadingState === 'loaded' && !items?.length) || (!loadingState && !items?.length);
  const loadingOrFailure = loadingState && ['failure', 'loading'].includes(loadingState);

  return (
    <div>
      {loadingOrFailure && (
        <div className="d-flex align-items-center justify-content-center my-5">
          <Loading loadingState={loadingState} notFoundMessage={errorMessage} />
        </div>
      )}

      {loadedWithItems && (
        <BSTable hover={Boolean(onRowClick)} striped responsive>
          <SmartTableHeader columns={columns} />
          <SmartTableBody columns={columns} docs={items} onRowClick={onRowClick} />
        </BSTable>
      )}

      {loadedWithoutItems &&
        (typeof emptyMessage === 'string' ? (
          <div className="d-flex justify-content-center my-5">
            <h5 className="mb-0">{emptyMessage || 'Nenhum item encontrado'}</h5>
          </div>
        ) : (
          emptyMessage
        ))}
    </div>
  );
};

import { useCallback } from 'react';
import BSButton from 'react-bootstrap/Button';
import BSModal from 'react-bootstrap/Modal';

import { TipoFeriadoEnum, UfEnum } from '@tamborineapps/lib-enums';
import { useForm } from 'react-hook-form';
import { Form } from '../../components/form/form';
import { FormControl } from '../../components/form/form-control';
import { FormSelect } from '../../components/form/form-select';
import { ClassTypesEnum } from '../../helpers';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch } from '../../store/hooks-redux';
import { saveFeriado } from './feriados.redux';

type CadastrarFeriadoFormFields = {
  data: string;
  descricao: string;
  tipo: string;
  cidade?: string;
  uf?: string;
};

type CadastrarFeriadoDialogProps = {
  closeDialog: () => void;
  reload: () => void;
};

const CadastrarFeriadoDialog: React.FC<CadastrarFeriadoDialogProps> = ({ closeDialog, reload }) => {
  const dispatch = useAppDispatch();
  const { showToast } = useToasts();

  const form = useForm<CadastrarFeriadoFormFields>();
  const { control } = form;
  const { watch } = form;

  const tipoFeriado = watch('tipo');

  const onSubmitHandler = useCallback(
    (data: CadastrarFeriadoFormFields) => {
      dispatch(saveFeriado({ data }))
        .then(() => {
          closeDialog();
          showToast({ message: 'Feriado cadastrado com sucesso', type: ClassTypesEnum.SUCCESS });
          reload();
        })
        .catch((error: Error) => {
          showToast({ message: error.message, type: ClassTypesEnum.DANGER });
        });
    },
    [closeDialog, dispatch, reload, showToast]
  );

  return (
    <>
      <BSModal.Header closeButton>
        <BSModal.Title>Novo feriado</BSModal.Title>
      </BSModal.Header>

      <BSModal.Body>
        <Form id="form" form={form} onSubmit={onSubmitHandler}>
          <FormControl
            className="mb-3"
            control={control}
            name="data"
            label="Data do feriado"
            rules={{ required: true }}
            type="date"
          />

          <FormControl
            className="mb-3"
            control={control}
            name="descricao"
            label="Descrição"
            rules={{ required: true }}
          />

          <FormSelect
            control={control}
            className="mb-3"
            name="tipo"
            label="Tipo do feriado"
            placeholder="Selecione"
            options={[TipoFeriadoEnum.MUNICIPAL, TipoFeriadoEnum.ESTADUAL, TipoFeriadoEnum.FEDERAL].map(
              (tipoFeriado: string) => ({
                key: tipoFeriado,
                value: tipoFeriado,
                label: tipoFeriado,
              })
            )}
            rules={{ required: true }}
          />
          {[TipoFeriadoEnum.MUNICIPAL, TipoFeriadoEnum.ESTADUAL] && (
            <>
              <FormControl
                className="mb-3"
                control={control}
                name="cidade"
                label="Cidade"
                rules={{ required: tipoFeriado === TipoFeriadoEnum.MUNICIPAL }}
              />

              <FormSelect
                className="mb-3"
                control={control}
                name="uf"
                label="Estado"
                rules={{ required: tipoFeriado === TipoFeriadoEnum.MUNICIPAL || TipoFeriadoEnum.ESTADUAL }}
                options={Object.values(UfEnum).map((uf) => ({
                  key: uf,
                  value: uf,
                  label: uf,
                }))}
              />
            </>
          )}
        </Form>
      </BSModal.Body>

      <BSModal.Footer>
        <BSButton variant="light" onClick={closeDialog}>
          Fechar
        </BSButton>
        <BSButton variant="primary" type="submit" form="form">
          Cadastrar feriado
        </BSButton>
      </BSModal.Footer>
    </>
  );
};

export default CadastrarFeriadoDialog;

import React, { useCallback, useState } from 'react';
import BSButton from 'react-bootstrap/Button';
import BSListGroup from 'react-bootstrap/ListGroup';
import { Control, useForm } from 'react-hook-form';
import { BsTrash } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import variables from '../../_export.scss';
import { Form } from '../../components/form/form';
import { FormControl } from '../../components/form/form-control';
import { ClassTypesEnum } from '../../helpers';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch } from '../../store/hooks-redux';
import { updateProduto } from './produtos.redux';

type VencimentoDisponivelFormFields = {
  dia: string;
};

type AtualizarVencimentosDisponiveisProdutoTabProps = {
  produto: any;
};

const AtualizarVencimentosDisponiveisProdutoTab: React.FC<AtualizarVencimentosDisponiveisProdutoTabProps> = ({
  produto,
}) => {
  const dispatch = useAppDispatch();
  const { showToast } = useToasts();
  const navigate = useNavigate();
  const [vencimentosDisponiveis, setVencimentosDisponiveis] = useState<Set<string>>(
    new Set(produto?.vencimentosDisponiveis ?? [])
  );

  const form = useForm<VencimentoDisponivelFormFields>();
  const { control, reset } = form;

  const incluirVenciementoDisponivel = useCallback(
    (data: VencimentoDisponivelFormFields) => {
      reset();
      const dia = data.dia.padStart(2, '0');

      setVencimentosDisponiveis((prev) => new Set([...prev, dia].sort((a, b) => Number(a) - Number(b))));
    },
    [reset]
  );

  const removerVencimentoDisponivel = useCallback((dia: string) => {
    setVencimentosDisponiveis((prev) => {
      prev.delete(dia);

      return new Set(prev);
    });
  }, []);

  const onSubmitHandler = () => {
    dispatch(
      updateProduto({
        params: { produtoId: produto._id },
        data: { vencimentosDisponiveis: Array.from(vencimentosDisponiveis) },
      })
    )
      .then(() => {
        showToast({
          message: 'Produto atualizado com sucesso',
          type: ClassTypesEnum.SUCCESS,
        });

        return navigate('/produtos/');
      })
      .catch((error: Error) => {
        showToast({
          message: error.message,
          type: ClassTypesEnum.DANGER,
        });
      });
  };

  return (
    <>
      <div className="mb-3">
        <span>
          <strong>Vencimentos disponíveis</strong>
        </span>
      </div>

      {!Array.from(vencimentosDisponiveis).length && <span>Nenhum vencimento disponível</span>}

      <div className="row mb-4">
        <div className="col-xl-6 col-md-12">
          <BSListGroup className="mb-3">
            {Array.from(vencimentosDisponiveis).map((dia, index) => (
              <BSListGroup.Item key={index}>
                <div className="d-flex justify-content-between align-items-center">
                  <span>{dia}</span>

                  <BsTrash role="button" color={variables.danger} onClick={() => removerVencimentoDisponivel(dia)} />
                </div>
              </BSListGroup.Item>
            ))}
          </BSListGroup>
        </div>
      </div>

      <Form form={form} onSubmit={incluirVenciementoDisponivel} id="form">
        <div className="row">
          <div className="col-xl-6 col-md-12">
            <FormControl
              className="mb-3"
              control={control as unknown as Control<VencimentoDisponivelFormFields>}
              label="Dia de vencimento"
              name="dia"
              type="number"
              rules={{
                required: true,
                validate: (value) => {
                  const numberDia = Number(value);

                  if (numberDia < 1 || numberDia > 28) {
                    return 'O dia deve ser um valor entre 1 e 28';
                  }
                },
              }}
            />
          </div>
        </div>

        <div className="col-xl-6 col-md-12">
          <BSButton form="form" type="submit" variant={ClassTypesEnum.PRIMARY}>
            Adicionar vencimento
          </BSButton>
        </div>
      </Form>

      <div className="my-4">
        <hr />
      </div>

      <div className="d-flex justify-content-end">
        <BSButton variant="primary" form="form" onClick={onSubmitHandler}>
          Atualizar
        </BSButton>
      </div>
    </>
  );
};

export default AtualizarVencimentosDisponiveisProdutoTab;

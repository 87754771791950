import { TipoCreditoEnum } from '@tamborineapps/lib-enums';
import { useCallback, useEffect, useMemo, useState } from 'react';
import BSButton from 'react-bootstrap/Button';
import BSCard from 'react-bootstrap/Card';
import BSForm from 'react-bootstrap/Form';
import BSModal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { BsTrash } from 'react-icons/bs';
import variables from '../../_export.scss';
import { Form } from '../../components/form/form';
import { FormCheck } from '../../components/form/form-check';
import { FormControl, MasksFormControlEnum } from '../../components/form/form-control';
import { FormSelect } from '../../components/form/form-select';
import { SmartTable } from '../../components/smart-table/smart-table';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import { ApiSingleElementResponse, ClassTypesEnum, FormatValueEnum, naturezaTransacaoLabelMap } from '../../helpers';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import {
  loadCategoriasBeneficios,
  selectTodasCategoriasBeneficios,
} from '../categorias-beneficio/categorias.beneficio.redux';
import { loadTiposTransacao, selectObjectTodosTiposTransacao } from '../tipos-transacao/tipos-transacao.redux';
import { saveInteracaoSolicitacaoCliente } from './interacao-solicitacao-cliente.redux';
import { gerarProtocoloSolicitacao } from './protocolo-solicitacao-cliente.redux';
import TransacaoOrigemAjusteFinanceiroControl from './transacao-origem-ajuste-financeiro-control';

const smartColumns = (): SmartTableColumnProps[] => [
  {
    label: 'Data referência',
    attribute: 'dataReferencia',
    className: 'text-center',
    format: FormatValueEnum.DATE_TIME,
  },
  {
    label: 'Tipo de transação',
    attribute: 'tipoTransacao',
    className: 'text-center',
    format: (tipoTransacao: any) => {
      if (!tipoTransacao) {
        return '';
      }

      return tipoTransacao?.descricao;
    },
  },
  {
    label: 'Estabelecimento',
    attribute: 'nomeEstabelecimento',
    className: 'text-center',
  },
  {
    label: 'Natureza',
    attribute: 'natureza',
    className: 'text-center',
    format: FormatValueEnum.ENUM,
    map: naturezaTransacaoLabelMap,
  },
  {
    label: 'Valor (R$)',
    attribute: 'valor',
    className: 'text-end',
    format: FormatValueEnum.BRL,
  },
];

type CadastrarInteracaoAjusteFinanceiroSolicitacaoClienteFormFields = {
  descricao: string;
  tipoTransacaoId: string;
  valor: number;
  faturada: boolean;
  limiteCreditoAfetado: boolean;
  categoriaBeneficio: string;
  valorIntransferivel: boolean;
};

type CadastrarInteracaoSolicitacaoClienteDialogProps = {
  solicitacaoClienteId: string;
  closeDialog: () => void;
  reload: () => void;
  produto?: any;
};

enum InfoTransacaoOrigem {
  NAO_INFORMADO = 'NI',
  POSSUI = 'P',
  NAO_POSSUI = 'NP',
}

const CadastrarInteracaoAjusteFinanceiroSolicitacaoClienteDialog: React.FC<
  CadastrarInteracaoSolicitacaoClienteDialogProps
> = ({ solicitacaoClienteId, closeDialog, reload, produto }) => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const { maxItemsQuery } = useQuerystring();

  const { showToast } = useToasts();

  const [transacaoOrigem, setTransacaoOrigem] = useState<any>();
  const [infoTransacaoOrigem, setInfoTransacaoOrigem] = useState<InfoTransacaoOrigem>(
    InfoTransacaoOrigem.NAO_INFORMADO
  );

  const possuiTransacaoOrigem = infoTransacaoOrigem === InfoTransacaoOrigem.POSSUI;
  const houveInteracaoComSwitch = infoTransacaoOrigem !== InfoTransacaoOrigem.NAO_INFORMADO;
  const produtoPossuiFatura = ![TipoCreditoEnum.MULTIBENEFICIO, TipoCreditoEnum.PRE_PAGO].includes(produto.tipoCredito);

  const tiposTransacao = useSelector((state) => selectObjectTodosTiposTransacao(state));
  const categoriasBeneficio = useSelector((state) => selectTodasCategoriasBeneficios(state));

  const form = useForm<CadastrarInteracaoAjusteFinanceiroSolicitacaoClienteFormFields>({
    defaultValues: {
      faturada: true,
      limiteCreditoAfetado: true,
    },
  });
  const { control, watch } = form;

  const tipoTransacaoSelecionadoId = watch('tipoTransacaoId');
  const tipoTransacaoSelecionado = tiposTransacao?.[tipoTransacaoSelecionadoId];

  // TODO: reavalidar uso dos useState
  const [numeroDoProtocolo, setNumeroDoProtocolo] = useState<string | null>();
  const [deveGerarProtocolo, setDeveGerarProtocolo] = useState<boolean>(true);

  const onSubmitHandler = (data: any) => {
    if (!data) {
      return;
    }

    setDeveGerarProtocolo(true);

    dispatch(
      saveInteracaoSolicitacaoCliente({
        params: { solicitacaoClienteId },
        data: {
          ...data,
          ...(!produtoPossuiFatura && { faturada: false }),
          numeroDoProtocolo,
          transacaoOrigemId: transacaoOrigem?._id,
        },
      })
    )
      .then(() => {
        closeDialog();

        showToast({
          message: 'Ajuste financeiro incluído com sucesso',
          type: ClassTypesEnum.SUCCESS,
        });

        return reload();
      })
      .catch((error: Error) => {
        showToast({
          message: error.message,
          type: ClassTypesEnum.DANGER,
        });
      });
  };

  const loadNumeroProtocolo = useCallback(() => {
    dispatch(gerarProtocoloSolicitacao({ data: { deveGerarProtocolo } }))
      .then(({ payload: { data } }: ApiSingleElementResponse) => {
        setDeveGerarProtocolo(false);
        setNumeroDoProtocolo(data.protocoloSolicitacao as string);

        return data;
      })
      .catch((error: Error) => error);
  }, [deveGerarProtocolo, dispatch]);

  const tiposTransacaoQuery = useMemo(() => ({ ...maxItemsQuery, sort: 'descricao' }), [maxItemsQuery]);
  const categoriasBeneficioQuery = useMemo(() => ({ ...maxItemsQuery }), [maxItemsQuery]);

  useEffect(() => {
    dispatch(loadTiposTransacao({ query: tiposTransacaoQuery })).catch((error: Error) => error);
    dispatch(loadCategoriasBeneficios({ query: categoriasBeneficioQuery })).catch((error: Error) => error);
    loadNumeroProtocolo();
  }, [categoriasBeneficioQuery, dispatch, loadNumeroProtocolo, tiposTransacaoQuery]);

  return (
    <>
      <BSModal.Header closeButton>
        <BSModal.Title>Cadastrar interação de ajuste financeiro</BSModal.Title>
      </BSModal.Header>

      <BSModal.Body>
        <Form id="form" form={form} onSubmit={onSubmitHandler} submitPristine>
          <div className="mb-4 d-flex">
            <h5>Protocolo {numeroDoProtocolo}</h5>
          </div>

          <FormControl
            as="textarea"
            className="mb-3"
            control={control}
            label="Descrição"
            name="descricao"
            rows={7}
            rules={{ required: true }}
          />

          <div className="d-flex align-items-center mb-3">
            <div className="me-3">
              <span>Possui transação origem?</span> <span className="text-danger">*</span>
            </div>

            <BSForm.Check
              inline
              label="Sim"
              type="radio"
              checked={infoTransacaoOrigem === InfoTransacaoOrigem.POSSUI}
              onChange={() => setInfoTransacaoOrigem(InfoTransacaoOrigem.POSSUI)}
            />
            <BSForm.Check
              inline
              label="Não"
              checked={infoTransacaoOrigem === InfoTransacaoOrigem.NAO_POSSUI}
              type="radio"
              onChange={() => setInfoTransacaoOrigem(InfoTransacaoOrigem.NAO_POSSUI)}
            />
          </div>

          {Boolean(transacaoOrigem) && possuiTransacaoOrigem && (
            <>
              <div className="mb-3">
                <span>Transacao origem:</span>
              </div>
              <BSCard className="px-3 mb-3">
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <SmartTable
                      emptyMessage="Nenhuma transação encontrada"
                      errorMessage="Erro ao listar transações"
                      usePagination={false}
                      columns={smartColumns()}
                      items={[transacaoOrigem]}
                    />
                  </div>

                  <div className="d-flex align-items-center px-3">
                    <BsTrash
                      role="button"
                      size={20}
                      color={variables.danger}
                      onClick={() => setTransacaoOrigem(undefined)}
                    />
                  </div>
                </div>
              </BSCard>
            </>
          )}

          {((houveInteracaoComSwitch && !possuiTransacaoOrigem) || Boolean(transacaoOrigem)) && (
            <>
              <FormSelect
                className="mb-3"
                control={control}
                label="Tipo de transação do ajuste"
                name="tipoTransacaoId"
                options={Object.values(tiposTransacao ?? {})?.map((tipoTransacao: any) => ({
                  key: tipoTransacao._id,
                  value: tipoTransacao._id,
                  label: tipoTransacao.descricao,
                }))}
                placeholder="Selecione o tipo de transação"
                rules={{ required: true }}
              />

              {produto.tipoCredito === TipoCreditoEnum.MULTIBENEFICIO && (
                <FormSelect
                  className="mb-3"
                  control={control}
                  label="Categoria do benefício"
                  name="categoriaBeneficio"
                  options={Object.values(categoriasBeneficio ?? {})?.map((categoriaBeneficio: any) => ({
                    key: categoriaBeneficio._id,
                    value: categoriaBeneficio._id,
                    label: categoriaBeneficio.nome,
                  }))}
                  placeholder="Selecione a categoria"
                  rules={{ required: true }}
                />
              )}

              <FormControl
                className="mb-3"
                control={control}
                label="Valor do ajuste"
                mask={MasksFormControlEnum.BRL}
                name="valor"
                placeholder="R$ 0,00"
                rows={1}
                rules={{ required: true }}
              />

              {produto.tipoCredito === TipoCreditoEnum.MULTIBENEFICIO && tipoTransacaoSelecionado?.funcao === 'C' && (
                <FormControl
                  className="mb-3"
                  control={control}
                  label="Valor intransferível"
                  mask={MasksFormControlEnum.BRL}
                  name="valorIntransferivel"
                  placeholder="R$ 0,00"
                  rules={{ required: true }}
                />
              )}

              <FormCheck
                control={control}
                className="mb-3"
                name="limiteCreditoAfetado"
                label="Deve afetar limite de crédito disponível"
                type="switch"
              />

              {produtoPossuiFatura && (
                <FormCheck control={control} className="mb-3" name="faturada" label="Deve ser faturada" type="switch" />
              )}
            </>
          )}
        </Form>

        {houveInteracaoComSwitch && possuiTransacaoOrigem && !transacaoOrigem && (
          <div className="mb-5">
            <div className="mb-3">
              <span>Selecione a transação origem:</span>
            </div>

            <BSCard>
              <BSCard.Body>
                <TransacaoOrigemAjusteFinanceiroControl
                  setTransacaoOrigem={setTransacaoOrigem}
                  solicitacaoClienteId={solicitacaoClienteId}
                />
              </BSCard.Body>
            </BSCard>
          </div>
        )}
      </BSModal.Body>

      <BSModal.Footer>
        <BSButton variant="light" onClick={closeDialog}>
          Fechar
        </BSButton>
        <BSButton
          variant="primary"
          type="submit"
          form="form"
          disabled={!houveInteracaoComSwitch || (possuiTransacaoOrigem && !transacaoOrigem)}
        >
          Realizar ajuste
        </BSButton>
      </BSModal.Footer>
    </>
  );
};

export default CadastrarInteracaoAjusteFinanceiroSolicitacaoClienteDialog;

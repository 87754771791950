import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import DetailSubTitle from '../../components/details/detail-subtitle';
import { SmartTable } from '../../components/smart-table/smart-table';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import { FormatValueEnum } from '../../helpers';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import {
  loadParametrosGeracaoCartao,
  selectObjectTodosParametrosGeracaoCartao,
} from '../parametros-geracao-cartao/parametros-geracao-cartao.redux';
import { selectProdutoById } from './produtos.redux';

const smartColumns = ({ parametrosGeracaoCartaoMap }: { parametrosGeracaoCartaoMap: any }): SmartTableColumnProps[] => [
  {
    label: 'Código',
    attribute: 'parametrosGeracaoCartaoId',
    className: 'text-center',
    format: (parametrosGeracaoCartaoId) => {
      return (
        <Link to={`/parametros-geracao-cartao/${parametrosGeracaoCartaoId}`}>
          {parametrosGeracaoCartaoMap[parametrosGeracaoCartaoId]?.codigo}
        </Link>
      );
    },
  },
  {
    label: 'Início da vigência',
    attribute: 'dataInicio',
    className: 'text-center',
    format: FormatValueEnum.DATA,
  },
  {
    label: 'Fim da vigência',
    attribute: 'dataFim',
    className: 'text-center',
    format: FormatValueEnum.DATA,
  },
];

type ParametrosGeracaoCartaoProdutoTabProps = {
  produtoId: string;
};

const ParametrosGeracaoCartaoProdutoTab: React.FC<ParametrosGeracaoCartaoProdutoTabProps> = ({ produtoId }) => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;
  const { maxItemsQuery } = useQuerystring();

  const produto = useSelector((state) => selectProdutoById(state, produtoId));
  const parametrosGeracaoCartao = useSelector((state) => selectObjectTodosParametrosGeracaoCartao(state));

  const _loadParametroGeracaoCartao = useCallback(() => {
    const parametrosIds = produto?.historicoParametrosGeracaoCartao?.map(
      (historico: any) => historico.parametrosGeracaoCartaoId
    );

    dispatch(loadParametrosGeracaoCartao({ query: { _id: { in: parametrosIds }, ...maxItemsQuery } })).catch(
      (error: Error) => error
    );
  }, [dispatch, maxItemsQuery, produto?.historicoParametrosGeracaoCartao]);

  const loadItems = useCallback(() => {
    if (produto) {
      _loadParametroGeracaoCartao();
    }
  }, [_loadParametroGeracaoCartao, produto]);

  return (
    <>
      <div className="mb-4">
        <DetailSubTitle>Histórico</DetailSubTitle>
      </div>

      <SmartTable
        emptyMessage="Nenhuma parâmetro de geração de cartão encontrado"
        errorMessage="Erro ao listar parâmetros de geração de cartão"
        usePagination={true}
        columns={smartColumns({ parametrosGeracaoCartaoMap: parametrosGeracaoCartao })}
        loadItems={loadItems}
        items={produto.historicoParametrosGeracaoCartao}
        size={produto.historicoParametrosGeracaoCartao.length}
      />
    </>
  );
};

export default ParametrosGeracaoCartaoProdutoTab;

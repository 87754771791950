import BSButton from 'react-bootstrap/Button';
import BSModal from 'react-bootstrap/Modal';

import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Form } from '../../components/form/form';
import { FormControl, MasksFormControlEnum } from '../../components/form/form-control';
import { ClassTypesEnum } from '../../helpers';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch } from '../../store/hooks-redux';
import { saveItemDoacao } from './itens-doacao.redux';

type CadastrarItemDoacaoFormFields = {
  descricao: string;
  valor: string;
  limiteMinimoFaixa: string;
  limiteMaximoFaixa: string;
};

type CadastrarItemDoacaoDialogProps = {
  closeDialog: () => void;
  reload: () => void;
};

const CadastrarItemDoacaoDialog: React.FC<CadastrarItemDoacaoDialogProps> = ({ closeDialog, reload }) => {
  const dispatch = useAppDispatch();
  const { showToast } = useToasts();

  const form = useForm<CadastrarItemDoacaoFormFields>();
  const { control } = form;

  const onSubmitHandler = useCallback(
    (data: CadastrarItemDoacaoFormFields) => {
      dispatch(saveItemDoacao({ data }))
        .then(() => {
          closeDialog();
          showToast({ message: 'Item de doação cadastrado com sucesso', type: ClassTypesEnum.SUCCESS });
          reload();
        })
        .catch((error: Error) => {
          showToast({ message: error.message, type: ClassTypesEnum.DANGER });
        });
    },
    [closeDialog, dispatch, reload, showToast]
  );

  return (
    <>
      <BSModal.Header closeButton>
        <BSModal.Title>Novo item de doação</BSModal.Title>
      </BSModal.Header>

      <BSModal.Body>
        <Form id="form" form={form} onSubmit={onSubmitHandler}>
          <FormControl
            control={control}
            className="mb-3"
            label="Descrição"
            name="descricao"
            rules={{ required: true }}
          />

          <FormControl
            control={control}
            className="mb-3"
            label="Valor unitário"
            name="valor"
            rules={{ required: true }}
            mask={MasksFormControlEnum.BRL}
          />

          <FormControl
            control={control}
            className="mb-3"
            label="Limite mínimo da faixa"
            name="limiteMinimoFaixa"
            rules={{ required: true }}
            mask={MasksFormControlEnum.BRL}
          />

          <FormControl
            control={control}
            className="mb-3"
            label="Limite máximo da faixa"
            name="limiteMaximoFaixa"
            mask={MasksFormControlEnum.BRL}
          />
        </Form>
      </BSModal.Body>

      <BSModal.Footer>
        <BSButton variant="light" onClick={closeDialog}>
          Fechar
        </BSButton>
        <BSButton variant="primary" type="submit" form="form">
          Cadastrar item de doação
        </BSButton>
      </BSModal.Footer>
    </>
  );
};

export default CadastrarItemDoacaoDialog;

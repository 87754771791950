import { Route, Routes } from 'react-router-dom';
import AtualizarPapelUsuarioPage from './atualizar-papel-usuario-page';
import CadastrarPapelUsuarioPage from './cadastrar-papel-usuario-page';
import DetalhesPapelUsuarioPage from './detalhes-papel-usuario-page';
import PapeisUsuariosPage from './papeis-usuarios-page';

const PapeisUsuariosRoutes: React.FC = () => {
  return (
    <Routes>
      <Route index element={<PapeisUsuariosPage />} />
      <Route path=":papelUsuarioId" element={<DetalhesPapelUsuarioPage />} />
      <Route path=":papelUsuarioId/atualizar" element={<AtualizarPapelUsuarioPage />} />
      <Route path="cadastrar" element={<CadastrarPapelUsuarioPage />} />
    </Routes>
  );
};

export default PapeisUsuariosRoutes;

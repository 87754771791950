import BSOverlayTrigger from 'react-bootstrap/OverlayTrigger';
import BSTooltip from 'react-bootstrap/Tooltip';
import { BsQuestionCircle } from 'react-icons/bs';
import { ClassTypesEnum, SizesEnum } from '../helpers/enums';
import IconButton from './button/icon-button';

type HelpTooltipProps = {
  dica?: string | JSX.Element;
};

const HelpTooltip: React.FC<HelpTooltipProps> = ({ dica }) => {
  return (
    <BSOverlayTrigger placement="bottom" delay={400} overlay={<BSTooltip>{dica}</BSTooltip>}>
      {({ ref, ...triggerHandler }) => (
        <div ref={ref} {...triggerHandler}>
          <IconButton
            Icon={BsQuestionCircle}
            size={SizesEnum.SMALL}
            className="d-flex align-items-center"
            type={ClassTypesEnum.PRIMARY}
          />
        </div>
      )}
    </BSOverlayTrigger>
  );
};

export default HelpTooltip;

import { reducerFactory, actionFactory } from '../../store';

const context = 'geracaoArquivo';

const { createOne } = actionFactory(context, {
  buildUrlCreateOne: () => '/api/gerar-arquivo',
});

export const gerarArquivo = createOne;

export const geracaoArquivo = reducerFactory({ context });

import React from 'react';

import BSButton from 'react-bootstrap/Button';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Form } from '../../components/form/form';
import { FormControl } from '../../components/form/form-control';
import { ClassTypesEnum } from '../../helpers';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch } from '../../store/hooks-redux';
import { updateProduto } from './produtos.redux';

type AtualizarCartaoProdutoFormFields = {
  bin: string;
  prazoValidadeCartaoEmAnos: number;
  tamanhoNumeroCartao: number;
  limiteCartoesVirtuaisPorConta: number;
  limiteMaximoCartoesAdicionaisPorConta: number;
  modeloPlastico: number;
};

type AtualizarCartoesProdutoTabProps = {
  produto: any;
};

const AtualizarCartoesProdutoTab: React.FC<AtualizarCartoesProdutoTabProps> = ({ produto }) => {
  const dispatch = useAppDispatch();
  const { showToast } = useToasts();
  const navigate = useNavigate();

  const form = useForm<AtualizarCartaoProdutoFormFields>({
    defaultValues: {
      bin: produto.bin,
      prazoValidadeCartaoEmAnos: produto.prazoValidadeCartaoEmAnos,
      tamanhoNumeroCartao: produto.tamanhoNumeroCartao,
      limiteCartoesVirtuaisPorConta: produto.limiteCartoesVirtuaisPorConta,
      limiteMaximoCartoesAdicionaisPorConta: produto.limiteMaximoCartoesAdicionaisPorConta,
      modeloPlastico: produto.modeloPlastico,
    },
  });
  const { control } = form;

  const onSubmitHandler = (data: AtualizarCartaoProdutoFormFields) => {
    if (Object.keys(data).length === 0) {
      return;
    }

    dispatch(updateProduto({ params: { produtoId: produto._id }, data }))
      .then(() => {
        showToast({
          message: 'Produto atualizado com sucesso',
          type: ClassTypesEnum.SUCCESS,
        });

        return navigate('/produtos/');
      })
      .catch((error: Error) => {
        showToast({
          message: error.message,
          type: ClassTypesEnum.DANGER,
        });
      });
  };

  return (
    <>
      <Form form={form} id="form" onSubmit={onSubmitHandler}>
        <div className="row">
          <FormControl
            control={control}
            className="col-xl-4 col-md-6 col-sm-12 mb-3"
            label="BIN"
            name="bin"
            type="number"
            rules={{ required: true }}
          />

          <FormControl
            control={control}
            className="col-xl-4 col-md-6 col-sm-12 mb-3"
            label="Tamanho do número do cartão"
            name="tamanhoNumeroCartao"
            type="number"
            rules={{ required: true }}
          />

          <FormControl
            control={control}
            className="col-xl-4 col-md-6 col-sm-12 mb-3"
            label="Prazo de validade do cartão em anos"
            name="prazoValidadeCartaoEmAnos"
            type="number"
            rules={{ required: true }}
          />

          <FormControl
            control={control}
            className="col-xl-4 col-md-6 col-sm-12 mb-3"
            label="Limite de cartões virtuais por conta"
            name="limiteCartoesVirtuaisPorConta"
            type="number"
          />

          <FormControl
            control={control}
            className="col-xl-4 col-md-6 col-sm-12 mb-3"
            label="Limite de cartões adicionais por conta"
            name="limiteMaximoCartoesAdicionaisPorConta"
            type="number"
          />

          <FormControl
            control={control}
            className="col-xl-4 col-md-6 col-sm-12 mb-3"
            label="Modelo do plástico"
            name="modeloPlastico"
          />

          <div className="d-flex justify-content-end">
            <BSButton variant="primary" type="submit" form="form">
              Atualizar
            </BSButton>
          </div>
        </div>
      </Form>
    </>
  );
};
export default AtualizarCartoesProdutoTab;

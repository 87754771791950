import { actionFactory, reducerFactory, selectorFactory } from '../../store';

const context = 'contratosTermosUso';

const { loadOne, loadMany, createOne, uploadFile } = actionFactory(context, {
  buildUrlUploadFile: () => '/api/contratos-termos-uso',
  buildUrlCreateOne: () => '/api/contratos-termos-uso',
  buildUrlLoadMany: () => '/api/contratos-termos-uso',
  buildUrlLoadOne: ({ contratoTermosUsoId }) => `/api/contratos-termos-uso/${contratoTermosUsoId}`,
});

const { selectManyByFilters, selectOneById, selectTotalByFilters, selectLoadingStateByFilters } = selectorFactory({
  context,
});
export const createContratoTermosUso = createOne;
export const uploadArquivoPDF = uploadFile;
export const loadContratoTermosUso = loadOne;
export const loadContratosTermosUso = loadMany;

export const selectContratoTermosUsoById = selectOneById;
export const selectContratosTermosUsoByFilters = selectManyByFilters;
export const selectTotalContratosTermosUsoByFilters = selectTotalByFilters;
export const selectContratosTermosUsoLoadingStateByFilters = selectLoadingStateByFilters;

export const contratosTermosUso = reducerFactory({ context });

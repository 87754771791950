import { useCallback, useMemo } from 'react';
import BSCard from 'react-bootstrap/Card';
import { BsPencilSquare } from 'react-icons/bs';

import IconButton from '../../components/button/icon-button';
import RbacElement from '../../components/role-based-access-control/role-based-access-control-element';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import { SmartTable } from '../../components/smart-table/smart-table';
import SmartTableButton from '../../components/smart-table/smart-table-button';
import SmartTableFilters from '../../components/smart-table/smart-table-filters/smart-table-filters';
import {
  SmartTableFilterControlTypesEnum,
  SmartTableFilterProps,
} from '../../components/smart-table/smart-table-filters/table-filter.types';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import Title from '../../components/title';
import { ClassTypesEnum, FormatValueEnum, tiposOperacaoBeneficioLabelMap } from '../../helpers';
import { DialogProps } from '../../hooks/dialog/dialog-context';
import { useDialog } from '../../hooks/dialog/use-dialog';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import {
  loadFaixasMcc,
  selectFaixasMccByFilters,
  selectFaixasMccLoadingStateByFilters,
  selectTotalFaixasMccByFilters,
  updateFaixaMcc,
} from './faixas-mcc.redux';

import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import AtualizarFaixaMccDialog from './atualizar-faixa-mcc-dialog';
import CadastrarFaixaMccDialog from './cadastrar-faixa-mcc-dialog';

const smartFilters: SmartTableFilterProps[] = [
  {
    label: 'Operação do benefício',
    attribute: 'operacaoBeneficio',
    controlType: SmartTableFilterControlTypesEnum.SELECT,
    map: tiposOperacaoBeneficioLabelMap,
  },
  {
    label: 'Descrição',
    attribute: 'descricao',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
  },
];

const smartColumns = ({
  showDialog,
  closeDialog,
  onUpdateFaixaMccHandler,
}: {
  showDialog: (dialogProps: DialogProps) => void;
  closeDialog: () => void;
  onUpdateFaixaMccHandler: (faixaMccId: string, data: any) => void;
}): SmartTableColumnProps[] => [
  { label: 'Início da faixa', attribute: 'inicioMcc', className: 'text-center' },
  { label: 'Fim da faixa', attribute: 'fimMcc', className: 'text-center' },
  { label: 'Descrição', attribute: 'descricao', className: 'text-center' },
  {
    label: 'Descrição da bandeira',
    attribute: 'descricaoBandeira',
    className: 'text-center',
  },
  {
    label: 'Operação do benefício',
    attribute: 'operacaoBeneficio',
    className: 'text-center',
    format: FormatValueEnum.ENUM,
    map: tiposOperacaoBeneficioLabelMap,
  },
  {
    label: 'Ações',
    format: (_, faixaMcc) => {
      return (
        <div className="d-flex justify-content-center">
          <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.ATUALIZACAO_FAIXA_MCC}>
            <div className="mx-1">
              <IconButton
                Icon={BsPencilSquare}
                type={ClassTypesEnum.PRIMARY}
                onClick={() => {
                  showDialog({
                    component: (
                      <AtualizarFaixaMccDialog
                        closeDialog={closeDialog}
                        faixaMcc={faixaMcc}
                        onUpdateHandler={onUpdateFaixaMccHandler}
                      />
                    ),
                  });
                }}
              />
            </div>
          </RbacElement>
        </div>
      );
    },
  },
];

const FaixasMccPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const { query } = useQuerystring();
  const { closeDialog, showDialog } = useDialog();
  const { showToast } = useToasts();

  const filters = useMemo(() => ({ ...query }), [query]);
  const total = useSelector((state) => selectTotalFaixasMccByFilters(state, filters));
  const faixasMcc = useSelector((state) => selectFaixasMccByFilters(state, filters));
  const loadingState = useSelector((state) => selectFaixasMccLoadingStateByFilters(state, filters));

  const loadItems = useCallback(
    () => dispatch(loadFaixasMcc({ query })).catch((error: Error) => error),
    [dispatch, query]
  );

  const onUpdateFaixaMccHandler = (faixaMccId: string, data: any) => {
    if (!data) {
      return;
    }

    dispatch(updateFaixaMcc({ params: { faixaMccId }, data }))
      .then(() => {
        closeDialog();

        showToast({
          message: 'Faixa de MCC atualizada com sucesso',
          type: ClassTypesEnum.SUCCESS,
        });

        return loadItems();
      })
      .catch((error: Error) => {
        showToast({
          message: error.message,
          type: ClassTypesEnum.DANGER,
        });
      });
  };

  const onNovaFaixaMccClickHandler = () => {
    showDialog({
      component: <CadastrarFaixaMccDialog closeDialog={closeDialog} reload={loadItems} />,
    });
  };

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_FAIXA_MCC}>
      <div className="d-flex flex-column h-100">
        <Title>Faixas de MCC </Title>

        <div className="row">
          <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-12 mb-4">
            <SmartTableFilters filters={smartFilters} />
          </div>

          <div className="col-xxl-10 col-xl-9 col-lg-8 col-md-12 mb-4">
            <BSCard>
              <BSCard.Body>
                <div className="d-flex align-items-center mb-2">
                  <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.CRIACAO_FAIXA_MCC}>
                    <SmartTableButton onClick={onNovaFaixaMccClickHandler}>Nova faixa de MCC</SmartTableButton>
                  </RbacElement>
                </div>
                <SmartTable
                  emptyMessage="Nenhuma faixa de mcc encontrada"
                  errorMessage="Erro na listagem de faixas de mcc"
                  usePagination={true}
                  loadItems={loadItems}
                  columns={smartColumns({
                    closeDialog,
                    showDialog,
                    onUpdateFaixaMccHandler,
                  })}
                  items={faixasMcc}
                  loadingState={loadingState}
                  size={total}
                />
              </BSCard.Body>
            </BSCard>
          </div>
        </div>
      </div>
    </RbacPage>
  );
};

export default FaixasMccPage;

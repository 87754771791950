import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { SmartTable } from '../../components/smart-table/smart-table';
import SmartTableFilters from '../../components/smart-table/smart-table-filters/smart-table-filters';
import {
  SmartTableFilterControlTypesEnum,
  SmartTableFilterProps,
} from '../../components/smart-table/smart-table-filters/table-filter.types';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import { FormatValueEnum } from '../../helpers';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import {
  loadProtocolosProcessamentoContaPorContaCartao,
  selectLoadingStateByFiltersProtocoloProcessamentoConta,
  selectProtocolosProcessamentoContaByFilters,
  selectTotalProtocolosProcessamentoContaByFilters,
} from '../protocolo-processamento-conta/protocolo-processamento-conta.redux';

const smartFilters: SmartTableFilterProps[] = [
  {
    attribute: 'data',
    label: 'Data referência',
    controlType: SmartTableFilterControlTypesEnum.DATE_RANGE,
  },
  {
    label: 'Processado com sucesso',
    attribute: 'processadoComSucesso',
    controlType: SmartTableFilterControlTypesEnum.BOOL_RADIO,
  },
];

const smartColumns = (): SmartTableColumnProps[] => [
  {
    label: 'Data referência',
    attribute: 'data',
    className: 'text-center',
    sortable: true,
    format: FormatValueEnum.DATA,
  },
  {
    label: 'Processado com sucesso',
    attribute: 'processadoComSucesso',
    sortable: true,
    className: 'text-center',
    format: FormatValueEnum.BOOL,
  },
  {
    label: 'Tentativas',
    attribute: 'numeroTentativas',
    sortable: true,
    className: 'text-center',
  },
];

type ProtocolosProcessamentoContaCartaoTabProps = {
  contaCartaoId: string;
};

export const ProtocolosProcessamentoContaTab: React.FC<ProtocolosProcessamentoContaCartaoTabProps> = ({
  contaCartaoId,
}) => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const { query } = useQuerystring();
  const navigate = useNavigate();

  if (!query.sort) {
    query.sort = '-data';
  }

  const filter = useMemo(() => ({ ...query, contaCartaoId }), [query, contaCartaoId]);
  const total = useSelector((state) => selectTotalProtocolosProcessamentoContaByFilters(state, filter));
  const protocolosProcessamentoConta = useSelector((state) =>
    selectProtocolosProcessamentoContaByFilters(state, filter)
  );
  const loadingState = useSelector((state) => selectLoadingStateByFiltersProtocoloProcessamentoConta(state, filter));

  const loadItems = useCallback(() => {
    dispatch(loadProtocolosProcessamentoContaPorContaCartao({ params: { contaCartaoId }, query })).catch(
      (error: Error) => error
    );
  }, [dispatch, contaCartaoId, query]);

  return (
    <>
      <div className="row">
        <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-12 mb-4">
          <SmartTableFilters filters={smartFilters} />
        </div>

        <div className="col-xxl-10 col-xl-9 col-lg-8 col-md-12 mb-4">
          <SmartTable
            emptyMessage="Pesquise por protocolos de processamento"
            errorMessage="Erro na listagem de protocolos de processamento de conta cartão"
            usePagination={true}
            loadItems={loadItems}
            columns={smartColumns()}
            items={protocolosProcessamentoConta}
            loadingState={loadingState}
            onRowClick={(doc) => navigate(`/contas-cartao/${contaCartaoId}/protocolos-processamento-conta/${doc._id}`)}
            size={total}
          />
        </div>
      </div>
    </>
  );
};

import { reducerFactory, actionFactory, selectorFactory } from '../../store';

const context = 'historicosContasCartao';

const { loadMany, loadOne } = actionFactory(context, {
  buildUrlLoadMany: ({ contaCartaoId }) => `/api/contas-cartao/${contaCartaoId}/historico`,
  buildUrlLoadOne: ({ historicoContaCartaoId }) => `/api/historicos-conta-cartao/${historicoContaCartaoId}`,
});

const { selectLoadingStateByFilters, selectManyByFilters, selectOneById, selectTotalByFilters } = selectorFactory({
  context,
});

export const loadHistorico = loadOne;
export const loadHistoricoContaCartao = loadMany;

export const selectHistoricoContaCartaoLoadingStateByFilters = selectLoadingStateByFilters;
export const selectHistoricoContaCartaoByFilters = selectManyByFilters;
export const selectHistoricoContaCartaoById = selectOneById;
export const selectTotalHistoricoContaCartaoByFilters = selectTotalByFilters;

export const historicosContasCartao = reducerFactory({ context });

import { reducerFactory, actionFactory, selectorFactory } from '../../store';

const context = 'historicosCartao';

const { loadMany, loadOne } = actionFactory(context, {
  buildUrlLoadMany: ({ cartaoId }) => `/api/cartoes/${cartaoId}/historicos`,
  buildUrlLoadOne: ({ historicoCartaoId }) => `/api/historicos-cartao/${historicoCartaoId}`,
});

const { selectManyByFilters, selectOneById, selectTotalByFilters, selectLoadingStateByFilters } = selectorFactory({
  context,
});

export const loadHistoricoCartao = loadMany;
export const loadHistorico = loadOne;

export const selectHistoricoCartaoById = selectOneById;
export const selectHistoricosCartaoByFilters = selectManyByFilters;
export const selectTotalHistoricosCartaoByFilters = selectTotalByFilters;
export const selectHistoricoCartaoLoadingStateByFilters = selectLoadingStateByFilters;

export const historicosCartao = reducerFactory({ context });

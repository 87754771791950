import { reducerFactory, actionFactory, selectorFactory } from '../../store';

const context = 'tiposSolicitacaoCliente';

const { createOne, loadMany } = actionFactory(context, {
  buildUrlCreateOne: () => '/api/tipos-solicitacao-cliente',
  buildUrlLoadMany: () => '/api/tipos-solicitacao-cliente',
});

const { selectOneById, selectAll, selectManyByFilters, selectTotalByFilters, selectLoadingStateByFilters } =
  selectorFactory({
    context,
  });

export const createTipoSolicitacaoCliente = createOne;
export const loadTiposSolicitacoesClientes = loadMany;

export const selectTipoSolicitacaoClienteById = selectOneById;
export const selectTodosTiposSolicitacaoCliente = selectAll;
export const selectTiposSolicitacaoClienteByFilters = selectManyByFilters;
export const selectTotalTiposSolicitacaoClienteByFilters = selectTotalByFilters;
export const selectTipoSolicitacaoClienteLoadingStateByFilters = selectLoadingStateByFilters;

export const tiposSolicitacaoCliente = reducerFactory({ context });

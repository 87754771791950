import { reducerFactory, actionFactory, selectorFactory } from '../../store';

const context = 'qmr';

const { loadOne, loadMany } = actionFactory(context, {
  buildUrlLoadMany: () => '/api/qmr',
  buildUrlLoadOne: ({ qmrId }) => `/api/qmr/${qmrId}`,
});

const { selectManyByFilters, selectTotalByFilters, selectLoadingStateByFilters, selectOneById } = selectorFactory({
  context,
});

export const loadQmr = loadOne;
export const loadQMRs = loadMany;
export const selectQMRsporFiltro = selectManyByFilters;
export const selectQMRsLoadingStateByFilters = selectLoadingStateByFilters;
export const selectTotalQMRsByFilters = selectTotalByFilters;
export const selectQMRporId = selectOneById;
export const qmr = reducerFactory({ context });

import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import DetailTitle from '../../components/details/detail-title';
import { Loading } from '../../components/loading';
import Page from '../../components/page';
import { useAppDispatch } from '../../store/hooks-redux';
import { loadQmr, selectQMRporId, selectQMRsLoadingStateByFilters } from './qmr.redux';
import TableQMR from './table-qmr';

const DetalhesQMR: React.FC = () => {
  const dispatch = useAppDispatch();

  const params = useParams();
  const qmrId = params.qmrId as string;

  const filters = useMemo(() => ({ qmrId }), [qmrId]);
  const qmr = useSelector((state) => selectQMRporId(state, qmrId));
  const loadingState = useSelector((state) => selectQMRsLoadingStateByFilters(state, filters));

  const loadItem = useCallback(() => dispatch(loadQmr({ qmrId })).catch((error: Error) => error), [dispatch, qmrId]);

  useEffect(() => {
    loadItem();
  }, [loadItem]);

  if (!qmr) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Loading notFoundMessage="QMR não encontrado" loadingState={loadingState} />
      </div>
    );
  }
  const cartoesInternacionais = qmr?.atividadesCartao?.compras?.cartoesInternacionais;
  const cartoesUsoExclusivoNacional = qmr?.atividadesCartao?.compras?.cartoesUsoExclusivoNacional;
  const contasCartaoUsoApenasNacional = qmr?.atividadesCartao?.contas?.contasCartaoUsoApenasNacional;
  const contasCartoesInternacional = qmr?.atividadesCartao?.contas?.contasCartoesInternacional;
  const estornoCredito = qmr?.atividadesCartao?.estornos?.estornoCredito;
  const estornoCreditoCartaoUsoApenasNacional = qmr?.atividadesCartao?.estornos?.estornoCreditoCartaoUsoApenasNacional;
  const saquesApenasUsoNacional = qmr?.atividadesCartao?.saques?.saquesApenasUsoNacional;
  const saquesCaixas = qmr?.atividadesCartao?.saques?.saquesCaixas;
  const detalhesRecursoCartao = qmr?.detalhesRecursoCartao;
  const totalOutstandings = qmr?.pendencias?.A.totalOutstandings;
  const perdasPorFraude = qmr?.perdasCobradas?.A?.perdasPorFraude;
  const outrasPerdas = qmr?.perdasCobradas?.B?.outrasPerdas;
  const totalPerdasCobradas = qmr?.perdasCobradas?.C?.totalPerdasCobradas;
  return (
    <Page>
      <div className="mb-5">
        <DetailTitle>QMR - Quarterly MasterCard Reporting</DetailTitle>
      </div>

      <div className="container align-self-center d-col justify-content-center border ">
        <h5 className="text-primary">I. Cardholder Activity</h5>
        <TableQMR
          data={cartoesInternacionais}
          cabecalhos={['A. Purchases - International', 'Transactions', 'Volume R$']}
        />
        <TableQMR
          data={cartoesUsoExclusivoNacional}
          cabecalhos={['Purchases on Local Use Only Cards', 'Transactions', 'Volume R$']}
        />
        <TableQMR data={saquesCaixas} cabecalhos={['B Cash Disbursements', 'Transactions', 'Volume R$']} />
        <TableQMR
          data={saquesApenasUsoNacional}
          cabecalhos={['Cash Disbursements Local-Use Only', 'Transactions', 'Volume R$']}
        />
        <TableQMR data={estornoCredito} cabecalhos={['C Refounds Returns Credits', 'Transactions', 'Volume R$']} />
        <TableQMR
          data={estornoCreditoCartaoUsoApenasNacional}
          cabecalhos={['Refounds Returns Credits on Local Use Only Cards', 'Transactions', 'Volume R$']}
        />
        <TableQMR
          data={contasCartoesInternacional}
          cabecalhos={['D. Accounts/ Cards INTERNATIONAL - Use', 'Open', 'Blocked', 'Total Und']}
        />
        <TableQMR
          data={contasCartaoUsoApenasNacional}
          cabecalhos={['Accounts/ Cards LOCAL - Use Only - Use', 'Open', 'Blocked', 'Total Und']}
        />
        <h5 className="text-primary">II. OutStandings</h5>
        <TableQMR data={totalOutstandings} cabecalhos={['A-Total Outstandings', 'Accounts', 'Balances R$']} />
        <h5 className="text-primary"> III. Charged-Off Losses</h5>
        <TableQMR
          data={perdasPorFraude}
          cabecalhos={['A. Fraud Losses (including counterfeit losses)', 'Accounts', 'Balances R$']}
        />
        <TableQMR data={outrasPerdas} cabecalhos={['B. Other Losses', 'Accounts', 'Balances R$']} />
        <TableQMR data={totalPerdasCobradas} cabecalhos={['C. Total Charged-Off Losses', 'Accounts', 'Balances R$']} />
        <TableQMR
          data={detalhesRecursoCartao}
          cabecalhos={['IV. Card Feature Details', 'Cards', 'Transactions', 'Volume R$']}
        />
      </div>
    </Page>
  );
};

export default DetalhesQMR;

import BSForm from 'react-bootstrap/Form';
import { FieldError, FieldValues, RegisterOptions, ValidationValueMessage } from 'react-hook-form';

type FormControlValidationFeedbackProps<TFormValues extends FieldValues> = {
  error?: FieldError;
  rules?: RegisterOptions<TFormValues>;
};

const FormControlValidationFeedback = <TFormValues extends FieldValues>({
  error,
  rules,
}: FormControlValidationFeedbackProps<TFormValues>) => {
  const getDefaultErrorMessage = (error: FieldError) => {
    const rule = rules?.[error.type as keyof Partial<RegisterOptions>];
    const value = (rule as ValidationValueMessage)?.value ?? rule;

    switch (error.type) {
      case 'required':
        return 'Campo obrigatório';

      case 'min':
        return `O valor mínimo do campo é ${value}`;

      case 'max':
        return `O valor máximo do campo é ${value}`;

      case 'maxLength':
        return `O tamanho máximo do campo é ${value}`;

      case 'minLength':
        return `O tamanho mínimo do campo é ${value}`;

      case 'pattern':
        return 'O campo deve obedecer à um padrão';

      default:
        return 'Campo inválido';
    }
  };

  return error ? (
    <BSForm.Control.Feedback data-testid="form-control-validation-feedback" type="invalid">
      {error?.message || getDefaultErrorMessage(error)}
    </BSForm.Control.Feedback>
  ) : (
    <></>
  );
};

export default FormControlValidationFeedback;

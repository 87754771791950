import React, { useEffect } from 'react';
import BSCard from 'react-bootstrap/Card';
import BSNav from 'react-bootstrap/Nav';
import BSTab from 'react-bootstrap/Tab';
import { useParams } from 'react-router-dom';
import { DetailCard } from '../../components/details/datail-card';
import DetailTitle from '../../components/details/detail-title';
import { Loading } from '../../components/loading';
import Page from '../../components/page';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import AtualizarBloqueiosProdutoTab from './atualizar-bloqueios-produto-tab';
import AtualizarCalendarioCorteProdutoTab from './atualizar-calendario-corte-produto-tab';
import AtualizarCartoesProdutoTab from './atualizar-cartoes-produto-tab';
import AtualizarDadosGeraisProdutoTab from './atualizar-dados-gerais-produto-tab';
import AtualizarDoacaoProdutoTab from './atualizar-doacao-produto-tab';
import AtualizarFaixasEtariasProdutoTab from './atualizar-faixas-etarias-produto-tab';
import AtualizarFaturaProdutoTab from './atualizar-fatura-produto-tab';
import AtualizarJurosProdutoTab from './atualizar-juros-produto-tab';
import AtualizarLimitesValoresProdutoTab from './atualizar-limites-valores-produto-tab';
import AtualizarOperacoesPermitidasProdutoTab from './atualizar-operacoes-permitidas-produto-tab';
import AtualizarParcelamentoFaturaProdutoTab from './atualizar-parcelamento-fatura-tab';
import AtualizarPercentuaisProdutoTab from './atualizar-percentuais-produto-tab';
import AtualizarPermissoesProdutoTab from './atualizar-permissoes-produto-tab';
import AtualizarPreviaProdutoTab from './atualizar-previa-produto-tab';
import AtualizarVencimentosDisponiveisProdutoTab from './atualizar-vencimentos-disponiveis-produto-tab';
import { loadProduto, selectProdutoById, selectProdutosLoadingStateByFilters } from './produtos.redux';

const AtualizarProdutoPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const params = useParams();
  const produtoId = params.produtoId as string;

  const produto = useSelector((state) => selectProdutoById(state, produtoId));
  const loadingState = useSelector((state) => selectProdutosLoadingStateByFilters(state, { produtoId }));

  useEffect(() => {
    dispatch(loadProduto({ produtoId })).catch((error: Error) => error);
  }, [dispatch, produtoId]);

  if (!produto) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Loading notFoundMessage="Produto não enconstrado" loadingState={loadingState} />
      </div>
    );
  }

  return (
    <Page>
      <div className="mb-5">
        <DetailCard>
          <div className="mb-5">
            <DetailTitle>
              Atualizar produto - {produto.nome} ({produto.codigo})
            </DetailTitle>
          </div>

          <BSTab.Container defaultActiveKey="dadosGerais" unmountOnExit>
            <div className="row">
              <div className="col-xl-2">
                <BSCard>
                  <BSCard.Body>
                    <BSNav variant="pills" defaultActiveKey="dadosGerais" className="flex-column">
                      <BSNav.Item className="mb-1">
                        <BSNav.Link eventKey="dadosGerais">Dados gerais</BSNav.Link>
                      </BSNav.Item>
                      <BSNav.Item className="mb-1">
                        <BSNav.Link eventKey="cartoes">Cartões</BSNav.Link>
                      </BSNav.Item>
                      <BSNav.Item className="mb-1">
                        <BSNav.Link eventKey="calendarioCorte">Calendário de corte</BSNav.Link>
                      </BSNav.Item>
                      <BSNav.Item className="mb-1">
                        <BSNav.Link eventKey="limitesValores">Limites e valores</BSNav.Link>
                      </BSNav.Item>
                      <BSNav.Item className="mb-1">
                        <BSNav.Link eventKey="fatura">Fatura</BSNav.Link>
                      </BSNav.Item>
                      <BSNav.Item className="mb-1">
                        <BSNav.Link eventKey="parcelamentoFatura">Parcelamento de fatura</BSNav.Link>
                      </BSNav.Item>
                      <BSNav.Item className="mb-1">
                        <BSNav.Link eventKey="previa">Prévia</BSNav.Link>
                      </BSNav.Item>
                      <BSNav.Item className="mb-1">
                        <BSNav.Link eventKey="juros">Juros</BSNav.Link>
                      </BSNav.Item>
                      <BSNav.Item className="mb-1">
                        <BSNav.Link eventKey="percentuais">Percentuais</BSNav.Link>
                      </BSNav.Item>
                      <BSNav.Item className="mb-1">
                        <BSNav.Link eventKey="bloqueios">Bloqueios</BSNav.Link>
                      </BSNav.Item>
                      <BSNav.Item className="mb-1">
                        <BSNav.Link eventKey="doacao">Doação</BSNav.Link>
                      </BSNav.Item>
                      <BSNav.Item className="mb-1">
                        <BSNav.Link eventKey="permissoes">Permissões</BSNav.Link>
                      </BSNav.Item>
                      <BSNav.Item className="mb-1">
                        <BSNav.Link eventKey="faixasEtarias">Faixas etárias</BSNav.Link>
                      </BSNav.Item>
                      <BSNav.Item className="mb-1">
                        <BSNav.Link eventKey="vencimentosDisponiveis">Vencimentos disponíveis</BSNav.Link>
                      </BSNav.Item>
                      <BSNav.Item className="mb-1">
                        <BSNav.Link eventKey="operacoesPermitidas">Operações permitidas</BSNav.Link>
                      </BSNav.Item>
                    </BSNav>
                  </BSCard.Body>
                </BSCard>
              </div>

              <div className="col-xl-10">
                <BSTab.Content>
                  <BSTab.Pane eventKey="dadosGerais">
                    <AtualizarDadosGeraisProdutoTab produto={produto} />
                  </BSTab.Pane>

                  <BSTab.Pane eventKey="cartoes">
                    <AtualizarCartoesProdutoTab produto={produto} />
                  </BSTab.Pane>

                  <BSTab.Pane eventKey="calendarioCorte">
                    <AtualizarCalendarioCorteProdutoTab produto={produto} />
                  </BSTab.Pane>

                  <BSTab.Pane eventKey="limitesValores">
                    <AtualizarLimitesValoresProdutoTab produto={produto} />
                  </BSTab.Pane>

                  <BSTab.Pane eventKey="fatura">
                    <AtualizarFaturaProdutoTab produto={produto} />
                  </BSTab.Pane>

                  <BSTab.Pane eventKey="parcelamentoFatura">
                    <AtualizarParcelamentoFaturaProdutoTab produto={produto} />
                  </BSTab.Pane>

                  <BSTab.Pane eventKey="previa">
                    <AtualizarPreviaProdutoTab produto={produto} />
                  </BSTab.Pane>

                  <BSTab.Pane eventKey="juros">
                    <AtualizarJurosProdutoTab produto={produto} />
                  </BSTab.Pane>

                  <BSTab.Pane eventKey="percentuais">
                    <AtualizarPercentuaisProdutoTab produto={produto} />
                  </BSTab.Pane>

                  <BSTab.Pane eventKey="bloqueios">
                    <AtualizarBloqueiosProdutoTab produto={produto} />
                  </BSTab.Pane>

                  <BSTab.Pane eventKey="doacao">
                    <AtualizarDoacaoProdutoTab produto={produto} />
                  </BSTab.Pane>

                  <BSTab.Pane eventKey="permissoes">
                    <AtualizarPermissoesProdutoTab produto={produto} />
                  </BSTab.Pane>

                  <BSTab.Pane eventKey="faixasEtarias">
                    <AtualizarFaixasEtariasProdutoTab produto={produto} />
                  </BSTab.Pane>

                  <BSTab.Pane eventKey="vencimentosDisponiveis">
                    <AtualizarVencimentosDisponiveisProdutoTab produto={produto} />
                  </BSTab.Pane>

                  <BSTab.Pane eventKey="operacoesPermitidas">
                    <AtualizarOperacoesPermitidasProdutoTab produto={produto} />
                  </BSTab.Pane>
                </BSTab.Content>
              </div>
            </div>
          </BSTab.Container>
        </DetailCard>
      </div>
    </Page>
  );
};
export default AtualizarProdutoPage;

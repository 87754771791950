import { useEffect } from 'react';
import BSButton from 'react-bootstrap/Button';
import BSModal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { Form } from '../../components/form/form';
import { FormSelect } from '../../components/form/form-select';
import { Loading } from '../../components/loading';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import {
  loadPapeisUsuarios,
  selectPapeisUsuariosByFilters,
  selectPapeisUsuariosLoadingStateByFilters,
} from '../papeis-usuarios/papeis-usuarios.redux';

type AtualizarPapelUsuarioFormFields = {
  papelUsuario: string;
};

type AtualizarPapelUsuarioUsuarioDialogProps = {
  usuario: any;
  closeDialog: () => void;
  onSubmitHandler: (data: any) => void;
};

const AtualizarPapelUsuarioUsuarioDialog: React.FC<AtualizarPapelUsuarioUsuarioDialogProps> = ({
  usuario,
  closeDialog,
  onSubmitHandler,
}) => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const { maxItemsQuery } = useQuerystring();

  const defaultValues: AtualizarPapelUsuarioFormFields = {
    papelUsuario: usuario.papelUsuario,
  };

  const form = useForm<AtualizarPapelUsuarioFormFields>({ defaultValues });
  const { control, setValue } = form;

  const papeisUsuarios = useSelector((state) => selectPapeisUsuariosByFilters(state, maxItemsQuery));
  const loadingState = useSelector((state) => selectPapeisUsuariosLoadingStateByFilters(state, maxItemsQuery));

  useEffect(() => {
    dispatch(loadPapeisUsuarios({ query: maxItemsQuery }))
      .then(() => {
        setValue('papelUsuario', usuario.papelUsuario);
      })
      .catch((error: Error) => error);
  }, [dispatch, maxItemsQuery, setValue, usuario.papelUsuario]);

  return (
    <>
      <BSModal.Header closeButton>
        <BSModal.Title>Atualizar papel do usuário</BSModal.Title>
      </BSModal.Header>

      {!papeisUsuarios ? (
        <BSModal.Body className="d-flex justify-content-center my-5">
          <Loading notFoundMessage="Papeis de usuário não encontrados" loadingState={loadingState} />
        </BSModal.Body>
      ) : (
        <>
          <BSModal.Body>
            <Form id="form" form={form} onSubmit={onSubmitHandler}>
              <div className="mb-3">
                Usuário: <strong>{usuario.username}</strong>
              </div>

              <FormSelect
                control={control}
                className="mb-3"
                name="papelUsuario"
                label="Papel do usuário"
                options={papeisUsuarios?.map((papelUsuario: any) => ({
                  key: papelUsuario._id,
                  value: papelUsuario._id,
                  label: papelUsuario.descricao,
                }))}
                rules={{ required: true }}
              />
            </Form>
          </BSModal.Body>

          <BSModal.Footer>
            <BSButton variant="light" onClick={closeDialog}>
              Fechar
            </BSButton>
            <BSButton variant="primary" type="submit" form="form">
              Atualizar papel do usuário
            </BSButton>
          </BSModal.Footer>
        </>
      )}
    </>
  );
};

export default AtualizarPapelUsuarioUsuarioDialog;

import BSForm from 'react-bootstrap/Form';
import { ClassTypesEnum } from '../../helpers';
import HelpTooltip from '../help-tooltip';

type FormLabelProps = {
  label: string;
  required?: boolean;
  dica?: string | JSX.Element;
};

const FormLabel: React.FC<FormLabelProps> = ({ label, required, dica }) => {
  return (
    <BSForm.Label data-testid="form-label" className="d-flex align-items-center" aria-label={label}>
      {label}
      {required && <span className={`text-${ClassTypesEnum.DANGER}`}> *</span>}
      {dica && <HelpTooltip dica={dica} />}
    </BSForm.Label>
  );
};

export default FormLabel;

import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { DetailCard } from '../../components/details/datail-card';
import DetailElement from '../../components/details/detail-element';
import DetailTitle from '../../components/details/detail-title';
import { Loading } from '../../components/loading';
import Page from '../../components/page';
import {
  ApiSingleElementResponse,
  FormatValueEnum,
  tipoEventosContabeisLabelMap,
  tipoRoteiroContabilLabelMap,
} from '../../helpers';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import { loadTipoTransacao, selectTiposTransacaoById } from '../tipos-transacao/tipos-transacao.redux';
import {
  loadRoteiroContabil,
  selectLoadingStateByFiltersRoteiroContabil,
  selectRoteiroContabilById,
} from './roteiro-contabil.redux';

const DetalhesRoteiroContabilPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const params = useParams();
  const roteiroContabilId = params.roteiroContabilId as string;

  const filters = useMemo(() => ({ roteiroContabilId }), [roteiroContabilId]);
  const roteiroContabil = useSelector((state) => selectRoteiroContabilById(state, roteiroContabilId));
  const loadingState = useSelector((state) => selectLoadingStateByFiltersRoteiroContabil(state, filters));

  const tipoTransacao = useSelector((state) => selectTiposTransacaoById(state, roteiroContabil?.tipoTransacao));

  const loadEntidadesRelacionadas = useCallback(
    (roteiroContabil: any) => {
      const { tipoTransacao } = roteiroContabil;

      if (!tipoTransacao) {
        return;
      }

      dispatch(loadTipoTransacao({ tipoTransacaoId: tipoTransacao })).catch((error: Error) => error);
    },
    [dispatch]
  );

  const _loadRoteiroContabil = useCallback(
    () =>
      dispatch(loadRoteiroContabil({ roteiroContabilId }))
        .then(({ payload: { data } }: ApiSingleElementResponse) => loadEntidadesRelacionadas(data))
        .catch((error: Error) => error),
    [roteiroContabilId, dispatch, loadEntidadesRelacionadas]
  );

  useEffect(() => {
    _loadRoteiroContabil();
  }, [_loadRoteiroContabil]);

  if (!roteiroContabil) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Loading notFoundMessage="Roteiro contábil não encontrado!" loadingState={loadingState} />
      </div>
    );
  }

  return (
    <Page>
      <div className="mb-5">
        <DetailCard>
          <div className="mb-5">
            <DetailTitle>Roteiro contábil: {roteiroContabil.descricao}</DetailTitle>
          </div>

          <div className="row mb-4">
            <div className="col-lg-4 col-md-6 mb-4">
              <DetailElement descricao="Conta de débito" valor={roteiroContabil.contaContabilDebito} />
              <DetailElement descricao="Status da conta" valor={roteiroContabil.statusConta} />
              <DetailElement
                descricao="Tipo transação"
                valor={tipoTransacao?.descricao}
                link={tipoTransacao ? `/tipos-transacao/${roteiroContabil.tipoTransacao}` : ''}
              />
              <DetailElement
                descricao="Evento contábil"
                valor={roteiroContabil.eventoContabil}
                format={FormatValueEnum.ENUM}
                map={tipoEventosContabeisLabelMap}
              />
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <DetailElement descricao="Conta de crédito" valor={roteiroContabil.contaContabilCredito} />
              <DetailElement
                descricao="Em utilizaçãp"
                valor={roteiroContabil.habilitado}
                format={FormatValueEnum.BOOL}
              />
              <DetailElement
                descricao="Tipo de roteiro"
                valor={roteiroContabil.tipoRoteiro}
                format={FormatValueEnum.ENUM}
                map={tipoRoteiroContabilLabelMap}
              />
              <DetailElement
                descricao="Tipo transação"
                valor={tipoTransacao?.descricao}
                link={`/tipos-transacao/${roteiroContabil.tipoTransacao}`}
              />
            </div>
          </div>
        </DetailCard>
      </div>
    </Page>
  );
};

export default DetalhesRoteiroContabilPage;

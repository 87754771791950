import { useCallback, useEffect } from 'react';
import BSButton from 'react-bootstrap/Button';
import BSModal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { Form } from '../../components/form/form';
import { FormCheck } from '../../components/form/form-check';
import { FormControl } from '../../components/form/form-control';
import { FormSelect } from '../../components/form/form-select';
import { Loading } from '../../components/loading';
import { ClassTypesEnum } from '../../helpers';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import {
  loadPapeisUsuarios,
  selectPapeisUsuariosByFilters,
  selectPapeisUsuariosLoadingStateByFilters,
} from '../papeis-usuarios/papeis-usuarios.redux';
import { saveUsuario } from './usuarios.redux';

type CadastrarUsuarioFormFields = {
  username: string;
  email: string;
  password: string;
  papelUsuario: string;
  ativo: boolean;
};

type CadastrarUsuarioDialogProps = {
  closeDialog: () => void;
  reload: () => void;
};

const CadastrarUsuarioDialog: React.FC<CadastrarUsuarioDialogProps> = ({ closeDialog, reload }) => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const { maxItemsQuery } = useQuerystring();

  const { showToast } = useToasts();

  const form = useForm<CadastrarUsuarioFormFields>();
  const { control } = form;

  const papeisUsuarios = useSelector((state) => selectPapeisUsuariosByFilters(state, maxItemsQuery));
  const loadingState = useSelector((state) => selectPapeisUsuariosLoadingStateByFilters(state, maxItemsQuery));

  useEffect(() => {
    dispatch(loadPapeisUsuarios({ query: maxItemsQuery })).catch((error: Error) => error);
  }, [dispatch, maxItemsQuery]);

  const onSubmitHandler = useCallback(
    (data: any) => {
      dispatch(saveUsuario({ data }))
        .then(() => {
          closeDialog();
          showToast({ message: 'Usuário cadastrado com sucesso', type: ClassTypesEnum.SUCCESS });
          reload();
        })
        .catch((error: Error) => {
          showToast({ message: error.message, type: ClassTypesEnum.DANGER });
        });
    },
    [closeDialog, dispatch, reload, showToast]
  );

  return (
    <>
      <BSModal.Header closeButton>
        <BSModal.Title>Novo usuário</BSModal.Title>
      </BSModal.Header>

      {!papeisUsuarios ? (
        <BSModal.Body className="d-flex justify-content-center my-5">
          <Loading notFoundMessage="Papeis de usuário não encontrados" loadingState={loadingState} />
        </BSModal.Body>
      ) : (
        <>
          <BSModal.Body>
            <Form id="form" form={form} onSubmit={onSubmitHandler}>
              <FormControl control={control} className="mb-3" name="username" label="Nome" rules={{ required: true }} />

              <FormControl
                control={control}
                className="mb-3"
                name="email"
                label="E-mail"
                rules={{ required: true }}
                type="email"
              />

              <FormControl
                control={control}
                className="mb-3"
                name="password"
                label="Senha"
                rules={{ required: true }}
                type="password"
              />

              <FormSelect
                control={control}
                className="mb-3"
                name="papelUsuario"
                label="Papel do usuário"
                placeholder="Selecione o papel de usuário"
                options={papeisUsuarios?.map((papelUsuario: any) => ({
                  key: papelUsuario._id,
                  value: papelUsuario._id,
                  label: papelUsuario.descricao,
                }))}
                rules={{ required: true }}
              />
              <FormCheck control={control} className="mb-3" name="ativo" label="Ativo" type="switch" />
            </Form>
          </BSModal.Body>

          <BSModal.Footer>
            <BSButton variant="light" onClick={closeDialog}>
              Fechar
            </BSButton>
            <BSButton variant="primary" type="submit" form="form">
              Cadastrar usuário
            </BSButton>
          </BSModal.Footer>
        </>
      )}
    </>
  );
};

export default CadastrarUsuarioDialog;

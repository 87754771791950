import { ErrorBoundary } from '../pages/error-boundary/error-boundary';
import classes from './app-content.module.scss';

type Props = {
  children: JSX.Element;
};

const AppContent: React.FC<Props> = ({ children }) => (
  <main className={`${classes.content} flex-grow-1 `}>
    <ErrorBoundary>{children}</ErrorBoundary>
  </main>
);

export default AppContent;

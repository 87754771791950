import { AnyAction } from '@reduxjs/toolkit';
import {
  ApiResponse,
  BodyRequest,
  PathParameters,
  QueryParameters,
  camelToSnakeUpperCase,
  generateUniqueId,
  http,
  serializeFilters,
} from '../helpers';
import type { RootState } from './redux.types';

type BuildUrlFn = (params: PathParameters) => string;

export interface ActionBuildUrlProps {
  buildUrlCreateOne?: BuildUrlFn;
  buildUrlLoadMany?: BuildUrlFn;
  buildUrlLoadOne?: BuildUrlFn;
  buildUrlDeleteOne?: BuildUrlFn;
  buildUrlUpdateOne?: BuildUrlFn;
  buildUrlUploadFile?: () => string;
}

export interface ActionOptionsProps {
  forceCall?: boolean;
}

export interface CreateActionProps {
  data: BodyRequest;
  params?: PathParameters;
  requestId?: string;
}

export interface LoadActionProps {
  params?: PathParameters;
  query?: QueryParameters;
}

interface DeleteActionProps {
  params?: PathParameters;
}

export interface UpdateActionProps {
  data: BodyRequest;
  params?: PathParameters;
}

export interface UploadFileActionProps {
  data: FormData;
}

export interface CallApiAction extends AnyAction {
  types?: [string, string, string];
  shouldSkipApiCall?: (state: RootState) => false | ApiResponse;
  callApi?: () => Promise<ApiResponse>;
  payload: {
    params?: PathParameters;
    filters?: QueryParameters | PathParameters;
    data?: BodyRequest | FormData;
    requestId?: string;
  };
}

export const actionFactory = (
  context: string,
  {
    buildUrlCreateOne,
    buildUrlLoadMany,
    buildUrlLoadOne,
    buildUrlDeleteOne,
    buildUrlUpdateOne,
    buildUrlUploadFile,
  }: ActionBuildUrlProps
) => {
  const snakeUppercaseContext = camelToSnakeUpperCase(context);

  return {
    createOne({ params = {}, data, requestId }: CreateActionProps): CallApiAction {
      return {
        type: 'createOne',
        types: [
          `CREATE_${snakeUppercaseContext}_REQUEST`,
          `CREATE_${snakeUppercaseContext}_SUCCESS`,
          `CREATE_${snakeUppercaseContext}_FAILURE`,
        ],
        callApi: async () => http.post((buildUrlCreateOne as BuildUrlFn)(params), data),
        payload: { params, data: { ...data }, requestId: requestId ?? generateUniqueId() },
      };
    },
    loadMany({ params = {}, query = {} }: LoadActionProps = {}, options: ActionOptionsProps = {}): CallApiAction {
      return {
        type: `LOAD_${snakeUppercaseContext}`,
        types: [
          `LOAD_${snakeUppercaseContext}_REQUEST`,
          `LOAD_${snakeUppercaseContext}_SUCCESS`,
          `LOAD_${snakeUppercaseContext}_FAILURE`,
        ],
        callApi: () => http.get((buildUrlLoadMany as BuildUrlFn)(params), query),
        shouldSkipApiCall: (state: RootState) => {
          if (options?.forceCall) {
            return false;
          }

          const data = selectManyByFiltersFromCache({
            context,
            state,
            filters: { ...params, ...query },
          });
          const alreadyLoaded = Boolean(data);

          if (!alreadyLoaded) {
            return false;
          }

          const total = selectTotalByFiltersFromCache({
            context,
            state,
            filters: { ...params, ...query },
          }) as number;

          return {
            status: 200,
            payload: {
              data: data as any[],
              total: total as number,
            },
          };
        },
        payload: { filters: { ...query, ...params } },
      };
    },
    loadOne(params: PathParameters, options: ActionOptionsProps = {}): CallApiAction {
      return {
        type: `LOAD_${snakeUppercaseContext}`,
        types: [
          `LOAD_${snakeUppercaseContext}_REQUEST`,
          `LOAD_${snakeUppercaseContext}_SUCCESS`,
          `LOAD_${snakeUppercaseContext}_FAILURE`,
        ],
        callApi: () => {
          const url = (buildUrlLoadOne as BuildUrlFn)(params);

          return http.get(url);
        },

        shouldSkipApiCall: (state: RootState) => {
          if (options?.forceCall) {
            return false;
          }

          const [id] = Object.values(params);
          const data = selectOneByIdFromCache({
            context,
            state,
            id,
          });

          const alreadyLoaded = Boolean(data);

          if (!alreadyLoaded) {
            return false;
          }

          return { status: 200, payload: { data: data as any } };
        },
        payload: { filters: params },
      };
    },
    deleteOne({ params = {} }: DeleteActionProps): CallApiAction {
      return {
        type: `DELETE_${snakeUppercaseContext}`,
        types: [
          `DELETE_${snakeUppercaseContext}_REQUEST`,
          `DELETE_${snakeUppercaseContext}_SUCCESS`,
          `DELETE_${snakeUppercaseContext}_FAILURE`,
        ],
        callApi: () => http.delete((buildUrlDeleteOne as BuildUrlFn)(params)),
        payload: { params, requestId: serializeFilters(params) },
      };
    },
    updateOne({ data, params = {} }: UpdateActionProps): CallApiAction {
      return {
        type: `UPDATE_${snakeUppercaseContext}`,
        types: [
          `UPDATE_${snakeUppercaseContext}_REQUEST`,
          `UPDATE_${snakeUppercaseContext}_SUCCESS`,
          `UPDATE_${snakeUppercaseContext}_FAILURE`,
        ],
        callApi: () => http.put((buildUrlUpdateOne as BuildUrlFn)(params), data),
        payload: { params, data: { ...data }, requestId: serializeFilters(params) },
      };
    },
    uploadFile({ data }: UploadFileActionProps): CallApiAction {
      return {
        type: `UPLOAD_${snakeUppercaseContext}`,
        types: [
          `UPLOAD_${snakeUppercaseContext}_REQUEST`,
          `UPLOAD_${snakeUppercaseContext}_SUCCESS`,
          `UPLOAD_${snakeUppercaseContext}_FAILURE`,
        ],
        callApi: () => http.postFile((buildUrlUploadFile as () => string)(), data),
        payload: { data: { ...data } },
      };
    },
  };
};

const selectOneByIdFromCache = ({ context, state, id }: { context: string; state: RootState; id: string }) => {
  const selectedContext = state?.[context as keyof typeof state];
  const byIds = selectedContext?.byIds;

  return byIds?.[id];
};

const selectManyByFiltersFromCache = ({
  context,
  state,
  filters,
}: {
  context: string;
  state: RootState;
  filters: PathParameters | QueryParameters;
}) => {
  const selectedContext = state?.[context as keyof typeof state];
  const serializedFilters = serializeFilters(filters);

  const filteredIds = selectedContext?.byFilters[serializedFilters];
  const byIds = selectedContext?.byIds;

  return filteredIds?.map((id: string) => byIds?.[id]);
};

const selectTotalByFiltersFromCache = ({
  context,
  state,
  filters,
}: {
  context: string;
  state: RootState;
  filters: PathParameters | QueryParameters;
}) => {
  const selectedContext = state?.[context as keyof typeof state];
  const serializedFilters = serializeFilters(filters);

  return selectedContext?.totalByFilters[serializedFilters];
};

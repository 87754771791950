import { ButtonProps } from 'react-bootstrap/Button';
import { BsDownload } from 'react-icons/bs';
import { http } from '../../helpers';
import IconLabelButton from './icon-label-button';

type DownloadFileButtonProps = ButtonProps & { url: string; contentType?: string; fileName: string };

const DownloadFileButton: React.FC<DownloadFileButtonProps> = ({
  url,
  contentType = 'application/octet-stream',
  fileName,
  children,
  ...props
}) => {
  const download = async () => {
    const response = await http.downloadFile(url, contentType);
    const blob = await response.blob();

    const link = document.createElement('a');

    link.setAttribute('href', window.URL.createObjectURL(new Blob([blob])));
    link.setAttribute('download', fileName);

    document.body.appendChild(link);

    link.click();

    link?.parentNode?.removeChild(link);
  };

  return (
    <IconLabelButton data-testid="download-file-button" {...props} icon={BsDownload} onClick={download}>
      {children}
    </IconLabelButton>
  );
};

export default DownloadFileButton;

import classes from './app-footer.module.scss';

export const AppFooter: React.FC = () => {
  return (
    <footer className={`${classes.footer} py-3`}>
      <div className="d-flex justify-content-center align-items-center mb-2">
        <span>TOS - Tamborine Operation System - {new Date().getFullYear()}</span>
      </div>

      <div className="d-flex justify-content-center align-items-center">
        <span className="small text-muted">v{process.env.REACT_APP_VERSION}</span>
      </div>
    </footer>
  );
};

export default AppFooter;

import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import ExportCSVButton from '../../components/smart-table/export-csv-button';
import { SmartTable } from '../../components/smart-table/smart-table';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import { FormatValueEnum, boolLabelMap, formatBRL, formatData, formatEnum } from '../../helpers';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import {
  loadFaturas,
  selectFaturasByFilters,
  selectFaturasLoadingStateByFilters,
  selectTotalFaturasByFilters,
} from '../faturas/faturas.redux';
import { selectContaCartaoById } from './contas-cartao.redux';

const smartColumns = (): SmartTableColumnProps[] => [
  {
    label: 'Início',
    attribute: 'dataInicioPeriodo',
    sortable: true,
    format: FormatValueEnum.DATA,
    className: 'text-center',
  },
  {
    label: 'Corte',
    attribute: 'dataCorte',
    sortable: true,
    className: 'text-center',
    format: FormatValueEnum.DATA,
  },
  {
    label: 'Prévia',
    attribute: 'dataPrevia',
    sortable: true,
    className: 'text-center',
    format: FormatValueEnum.DATA,
  },
  {
    label: 'Vencimento',
    attribute: 'dataVencimento',
    sortable: true,
    className: 'text-center',
    format: FormatValueEnum.DATA,
  },
  {
    label: 'Total (R$)',
    attribute: 'valorTotal',
    sortable: true,
    className: 'text-end',
    format: FormatValueEnum.BRL,
  },
  {
    label: 'Está fechada',
    attribute: 'estaFechada',
    className: 'text-center',
    format: FormatValueEnum.BOOL,
  },
];

type FaturasContaCartaoTabProps = {
  contaCartaoId: string;
};
const csvHeaders = [
  { label: 'Conta Cartão', key: 'contaCartao' },
  { label: 'CET rotativo anual', key: 'cetRotativoAnual' },
  { label: 'CET rotativo periodo', key: 'cetRotativoPeriodo' },
  { label: 'CET saque periodo', key: 'cetSaquePeriodo' },
  { label: 'Ciclos rotativos', key: 'ciclosRotativo' },
  { label: 'CET saque anual', key: 'cetRotativoAnual' },
  { label: 'Início', key: 'dataInicioPeriodo' },
  { label: 'Data prévia', key: 'dataPrevia' },
  { label: 'Data vencimento', key: 'dataVencimento' },
  { label: 'Corte', key: 'dataCorte' },
  { label: 'Total (R$)', key: 'valorTotal' },
  { label: 'Está fechada', key: 'estaFechada' },
  { label: 'Deve ser enviada', key: 'deveSerEnviada' },
  { label: 'Compara com o valor do mês anterior', key: 'comparaValorMesAnterior' },
  { label: 'Data da melhor commpra', key: 'dataMelhorCompra' },
  { label: 'Impedir bloqueio de atraso', key: 'impedirBloqueioAtrasoNoPeriodo' },
  { label: 'Valor de compras', key: 'valorCompras' },
  { label: 'Valor de débitos internacional em dólar', key: 'valorDebitosInternacionalDolar' },
  { label: 'Valor de desconto em folha', key: 'valorDescontoEmFolha' },
  { label: 'Valor em disputa', key: 'valorEmDisputa' },
  { label: 'Valor do estoque parcelado', key: 'valorEstoqueParceladoEmissor' },
  { label: 'Valor do estoque parcelado lojista', key: 'valorEstoqueParceladoLojista' },
  { label: 'Valor do pagamento complementar', key: 'valorPagamentoComplementar' },
  { label: 'Valor do pagamento mínimo', key: 'valorPagamentoMinimo' },
  { label: 'Valor para comparação', key: 'valorParaComparacao' },
  { label: 'Valor das parcelas não financiáveis', key: 'valorParcelasNaoFinanciaveis' },
  { label: 'Valor projecão rotativo proximo período', key: 'valorProjecaoRotativoProximoPeriodo' },
  { label: 'Valor subtotal de créditos', key: 'valorSubtotalCreditos ' },
  { label: 'Valor subtotal de débitos', key: 'valorSubtotalDebitos' },
  { label: 'Valor subtotal de debitos internacionais', key: 'valorSubtotalDebitosInternacional' },
  { label: 'Valor subtotal de encargos', key: 'valorSubtotalEncargos' },
];
const FaturasContaCartaoTab: React.FC<FaturasContaCartaoTabProps> = ({ contaCartaoId }) => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const navigate = useNavigate();
  const { query } = useQuerystring();

  if (!query.sort) {
    query.sort = '-dataInicioPeriodo';
  }

  const filters = useMemo(() => ({ ...query, contaCartaoId }), [contaCartaoId, query]);
  const faturas = useSelector((state) => selectFaturasByFilters(state, filters));
  const loadingState = useSelector((state) => selectFaturasLoadingStateByFilters(state, filters));
  const total = useSelector((state) => selectTotalFaturasByFilters(state, filters));
  const contaCartao = useSelector((state) => selectContaCartaoById(state, contaCartaoId));

  const _loadFaturas = useCallback(
    () => dispatch(loadFaturas({ params: { contaCartaoId }, query })).catch((error: Error) => error),
    [contaCartaoId, dispatch, query]
  );

  const faturasExcel = faturas?.map(({ _id, boleto, ...dadosFatura }: any) => {
    let novosDados = dadosFatura;
    novosDados['contaCartao'] = contaCartao?.numero;
    novosDados['comparaValorMesAnterior'] = formatEnum(dadosFatura.comparaValorMesAnterior, boolLabelMap);
    novosDados['deveSerEnviada'] = formatEnum(dadosFatura.deveSerEnviada, boolLabelMap);
    novosDados['estaFechada'] = formatEnum(dadosFatura.estaFechada, boolLabelMap);
    novosDados['impedirBloqueioAtrasoNoPeriodo'] = formatEnum(dadosFatura.impedirBloqueioAtrasoNoPeriodo, boolLabelMap);
    novosDados['dataCorte'] = formatData(dadosFatura.dataCorte);
    novosDados['dataInicioPeriodo'] = formatData(dadosFatura.dataInicioPeriodo);
    novosDados['dataPrevia'] = formatData(dadosFatura.dataPrevia);
    novosDados['dataVencimento'] = formatData(dadosFatura.dataVencimento);
    novosDados['dataVencimentoPeriodoAnterior'] = formatData(dadosFatura.dataVencimentoPeriodoAnterior);
    novosDados['valorTotal'] = formatBRL(dadosFatura.valorTotal);
    novosDados['valorCompras'] = formatBRL(dadosFatura.valorCompras);
    novosDados['valorEstoqueParceladoEmissor'] = formatBRL(dadosFatura.valorEstoqueParceladoEmissor);
    novosDados['valorEstoqueParceladoLojista'] = formatBRL(dadosFatura.valorEstoqueParceladoLojista);
    novosDados['valorPagamentoComplementar'] = formatBRL(dadosFatura.valorPagamentoComplementar);
    novosDados['valorPagamentoMinimo'] = formatBRL(dadosFatura.valorPagamentoMinimo);
    novosDados['valorParaComparacao'] = formatBRL(dadosFatura.valorParaComparacao);
    novosDados['valorParcelasNaoFinanciaveis'] = formatBRL(dadosFatura.valorParcelasNaoFinanciaveis);
    novosDados['valorProjecaoRotativoProximoPeriodo'] = formatBRL(dadosFatura.valorProjecaoRotativoProximoPeriodo);
    novosDados['valorSubtotalCreditos'] = formatBRL(dadosFatura.valorSubtotalCreditos);
    novosDados['valorSubtotalDebitos'] = formatBRL(dadosFatura.valorSubtotalDebitos);
    novosDados['valorSubtotalDebitosInternacional'] = formatBRL(dadosFatura.valorSubtotalDebitosInternacional);
    novosDados['valorSubtotalEncargos'] = formatBRL(dadosFatura.valorSubtotalEncargos);
    novosDados['valorDebitosInternacionalDolar'] = formatBRL(dadosFatura.valorDebitosInternacionalDolar);
    novosDados['valorDescontoEmFolha'] = formatBRL(dadosFatura.valorDescontoEmFolha);
    novosDados['valorEmDisputa'] = formatBRL(dadosFatura.valorEmDisputa);

    return novosDados;
  });
  return (
    <div className="row">
      <div className="col-xxl-10 col-xl-9 col-lg-8 col-md-12 mb-4">
        <div className="d-flex align-items-center mb-2">
          <ExportCSVButton data={faturasExcel} filename="faturas-conta-cartao" headers={csvHeaders} />
        </div>
      </div>

      <SmartTable
        emptyMessage="Nenhuma fatura encontrada"
        errorMessage="Erro ao listar faturas"
        size={total}
        columns={smartColumns()}
        items={faturas}
        loadItems={_loadFaturas}
        loadingState={loadingState}
        usePagination={true}
        onRowClick={(doc) => navigate(`/contas-cartao/${doc.contaCartao}/faturas/${doc._id}`)}
      />
    </div>
  );
};

export default FaturasContaCartaoTab;

import { fullLogo, authImg } from '../../assets';
import classes from './auth-branding.module.scss';

const AuthBranding: React.FC = () => {
  return (
    <div className={`${classes.background} d-flex flex-column justify-content-center align-items-center py-4 h-100`}>
      <div className="d-flex flex-column mb-5">
        <div className="mb-5">
          <img className={classes.brand} src={fullLogo} alt="brand" />
          <h1 className="text-white m-0">Operation System</h1>
        </div>
      </div>

      <div>
        <img className={classes.img} src={authImg} alt="brand" />
      </div>
    </div>
  );
};

export default AuthBranding;

import { actionFactory, reducerFactory, selectorFactory } from '../../store';

const context = 'contasCartao';

const {
  loadMany,
  loadOne,
  updateOne: updateOneBloqueio,
} = actionFactory(context, {
  buildUrlLoadOne: ({ contaCartaoId }) => `/api/contas-cartao/${contaCartaoId}`,
  buildUrlLoadMany: () => '/api/contas-cartao',
  buildUrlUpdateOne: ({ contaCartaoId }) => `/api/contas-cartao/${contaCartaoId}/bloqueio`,
});

const { updateOne: updateOneLimiteCredito } = actionFactory(context, {
  buildUrlUpdateOne: ({ contaCartaoId }) => `/api/contas-cartao/${contaCartaoId}/limite-credito`,
});

const { loadMany: loadManyCliente, updateOne: updateOneDesbloqueio } = actionFactory(context, {
  buildUrlLoadMany: ({ clienteId }) => `/api/clientes/${clienteId}/contas-cartao`,
  buildUrlUpdateOne: ({ contaCartaoId }) => `/api/contas-cartao/${contaCartaoId}/remover-bloqueio`,
});

const { updateOne: updateOneDiaVencimento } = actionFactory(context, {
  buildUrlUpdateOne: ({ contaCartaoId }) => `/api/contas-cartao/${contaCartaoId}/dia-vencimento`,
});

const {
  selectOneById,
  selectAll,
  selectManyByFilters,
  selectTotalByFilters,
  selectLoadingStateByFilters,
  selectObjectAllIds,
} = selectorFactory({
  context,
});

export const loadContaCartao = loadOne;
export const loadContasCartao = loadMany;
export const loadContasCartaoCliente = loadManyCliente;

export const atualizarDiaVencimento = updateOneDiaVencimento;
export const atualizarLimiteCredito = updateOneLimiteCredito;
export const incluirBloqueioContaCartao = updateOneBloqueio;
export const removerBloqueioContaCartao = updateOneDesbloqueio;

export const selectContaCartaoById = selectOneById;
export const selectTodasContasCartao = selectAll;
export const selectObjectTodasContasCartao = selectObjectAllIds;
export const selectContasCartaoByFilters = selectManyByFilters;
export const selectTotalContasCartaoByFilters = selectTotalByFilters;
export const selectContasCartaoLoadingStateByFilters = selectLoadingStateByFilters;

export const contasCartao = reducerFactory({ context });

import { AcaoPermissaoPapelUsuarioEnum, TipoCartaoEnum } from '@tamborineapps/lib-enums';
import { useCallback, useEffect, useMemo } from 'react';
import {
  BsArrowDownCircle,
  BsArrowUpCircle,
  BsBarChart,
  BsCheckCircle,
  BsCurrencyDollar,
  BsCurrencyExchange,
  BsPercent,
  BsXCircle,
} from 'react-icons/bs';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import variables from '../../../_export.scss';
import { InfoIconCard } from '../../../components/card/info-icon-card';
import { TitledCard } from '../../../components/card/titled-card';
import { DetailCard } from '../../../components/details/datail-card';
import DetailElement from '../../../components/details/detail-element';
import DetailSubTitle from '../../../components/details/detail-subtitle';
import DetailTitle from '../../../components/details/detail-title';
import { Loading } from '../../../components/loading';
import RbacPage from '../../../components/role-based-access-control/role-based-access-control-page';
import { SmartTable } from '../../../components/smart-table/smart-table';
import { SmartTableColumnProps } from '../../../components/smart-table/smart-table-header';
import {
  ApiSingleElementResponse,
  ClassTypesEnum,
  FormatValueEnum,
  formatBRL,
  formatData,
  tipoCartaoLabelMap,
} from '../../../helpers';
import { useQuerystring } from '../../../hooks/router/use-querystring';
import { useAppDispatch, useAppSelector } from '../../../store/hooks-redux';
import { loadUsuarios, selectObjectTodosUsuarios } from '../../usuarios/usuarios.redux';
import {
  loadRelatorioOperacaoAutorizacoes,
  selectRelatorioOperacaoAutorizacoesById,
  selectRelatoriosOperacaoAutorizacoesLoadingStateByFilters,
} from './relatorio-operacao-autorizacoes.redux';

const smartColumnsPedidosAutorizacaoNegados = (): SmartTableColumnProps[] => [
  {
    attribute: 'codigoRespostaAutorizador',
    label: 'Código de resposta',
    className: 'text-center',
  },
  {
    attribute: 'motivo',
    label: 'Motivo',
    className: 'text-center',
  },
  {
    label: 'Quantidade de rejeições',
    attribute: 'total',
    className: 'text-center',
  },
];

const DetalhesRelatorioOperacaoAutorizacoesPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;
  const { maxItemsQuery } = useQuerystring();

  const params = useParams();
  const relatorioOperacaoId = params.relatorioOperacaoId as string;

  const navigate = useNavigate();

  const filters = useMemo(() => ({ relatorioOperacaoId }), [relatorioOperacaoId]);
  const relatorioOperacao = useSelector((state) => selectRelatorioOperacaoAutorizacoesById(state, relatorioOperacaoId));
  const loadingState = useSelector((state) =>
    selectRelatoriosOperacaoAutorizacoesLoadingStateByFilters(state, filters)
  );
  const usuarios = useSelector((state) => selectObjectTodosUsuarios(state));

  const calcularPorcentagemAprocacaoPedidosAutorizacao = (relatorioOperacao: any) => {
    if (relatorioOperacao?.resultado.quantidadePedidosAutorizacaoNaoAutorizados === 0) {
      return 100;
    }

    if (relatorioOperacao?.quantidadePedidosAutorizacaoAutorizados === 0) {
      return 0;
    }

    return (
      (relatorioOperacao?.resultado.quantidadePedidosAutorizacaoAutorizados /
        relatorioOperacao?.resultado.quantidadePedidosAutorizacao) *
      100
    ).toFixed(2);
  };

  const formatarDadosQtdeTransacoesPorData = (quantidadeTransacoesPorData: { data: string; total: number }[]) =>
    quantidadeTransacoesPorData.map((item) => ({ ...item, data: formatData(item.data) }));

  const formatarDadosQtdeTransacoesPorTipoCartao = (
    quantidadeTransacoesPorData: { tipoCartao: TipoCartaoEnum; total: number }[]
  ) => quantidadeTransacoesPorData.map((item) => ({ ...item, name: formatData(tipoCartaoLabelMap[item.tipoCartao]) }));

  const loadEntidadesComplementares = useCallback(
    (relatorioOperacao: any) => {
      dispatch(loadUsuarios({ query: { _id: { in: [relatorioOperacao.usuarioId] }, ...maxItemsQuery } })).catch(
        (error: Error) => error
      );
    },
    [dispatch, maxItemsQuery]
  );

  const todosDadosNulos = (data: { total: number }[]) => {
    return data.every(({ total }) => total === 0);
  };

  const onTipoTransacaoClickHandler = (data: any) => {
    const { tipoTransacaoId } = data.payload;
    navigate(`/tipos-transacao/${tipoTransacaoId}`);
  };

  const _loadRelatorioOperacaoAutorizacoes = useCallback(
    () =>
      dispatch(loadRelatorioOperacaoAutorizacoes({ relatorioOperacaoId }))
        .then(({ payload: { data } }: ApiSingleElementResponse) => loadEntidadesComplementares(data))
        .catch((error: Error) => error),
    [dispatch, loadEntidadesComplementares, relatorioOperacaoId]
  );

  useEffect(() => {
    _loadRelatorioOperacaoAutorizacoes();
  }, [_loadRelatorioOperacaoAutorizacoes]);

  if (!relatorioOperacao) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Loading notFoundMessage="Relatório de operação de autorizações não encontrado" loadingState={loadingState} />
      </div>
    );
  }

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_RELATORIO_OPERACAO_AUTORIZACOES}>
      <div className="mb-5">
        <DetailCard>
          <div className="mb-5">
            <DetailTitle>Relatório de operação de autorizações ({relatorioOperacao.codigo})</DetailTitle>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="mb-3">
                <DetailSubTitle>Parâmetros</DetailSubTitle>
              </div>

              <DetailElement
                descricao="Data de início"
                valor={relatorioOperacao.parametros.dataInicio}
                format={FormatValueEnum.DATA}
              />
              <DetailElement
                descricao="Data de início"
                valor={relatorioOperacao.parametros.dataFim}
                format={FormatValueEnum.DATA}
              />

              <DetailElement descricao="Usuário" valor={usuarios?.[relatorioOperacao?.usuarioId]?.username ?? '-'} />
            </div>
          </div>
        </DetailCard>
      </div>

      <div className="mb-5">
        <div className="mb-4">
          <h5>Pedidos de autorização</h5>
        </div>

        <div className="row">
          <div className="d-flex col-12 col-md-6 col-xxl-3">
            <InfoIconCard
              className="mb-3"
              Icon={BsBarChart}
              title={relatorioOperacao.resultado?.quantidadePedidosAutorizacao}
              subtitle="Total de pedidos de autorização"
              variant={ClassTypesEnum.PRIMARY}
            />
          </div>

          <div className="d-flex col-12 col-md-6 col-xxl-3">
            <InfoIconCard
              className="mb-3"
              Icon={BsCheckCircle}
              title={relatorioOperacao.resultado?.quantidadePedidosAutorizacaoAutorizados}
              subtitle="Pedidos autorizados"
              variant={ClassTypesEnum.SUCCESS}
            />
          </div>

          <div className="d-flex col-12 col-md-6 col-xxl-3">
            <InfoIconCard
              className="mb-3"
              Icon={BsXCircle}
              title={relatorioOperacao.resultado?.quantidadePedidosAutorizacaoNaoAutorizados}
              subtitle="Pedidos não-autorizados"
              variant={ClassTypesEnum.DANGER}
            />
          </div>

          <div className="d-flex col-12 col-md-6 col-xxl-3">
            <InfoIconCard
              className="mb-3"
              Icon={BsPercent}
              title={`${calcularPorcentagemAprocacaoPedidosAutorizacao(relatorioOperacao)} %`}
              subtitle="Porcentagem de aprovação"
              variant={ClassTypesEnum.SUCCESS}
            />
          </div>
        </div>
      </div>

      <div className="mb-5">
        <TitledCard title="Pricipais motivos de rejeição de pedidos de autorização">
          <SmartTable
            columns={smartColumnsPedidosAutorizacaoNegados()}
            items={relatorioOperacao.resultado?.quantidadePedidosNaoAutorizadosPorCodigoResposta}
            usePagination={false}
            emptyMessage="Nenhum pedido de autorização rejeitado no período"
          />
        </TitledCard>
      </div>

      <div className="mb-5">
        <div className="mb-4">
          <h5>Transações</h5>
        </div>

        <div className="row">
          <div className="d-flex col-12 col-md-6 col-xxl-3">
            <InfoIconCard
              className="mb-3"
              Icon={BsCurrencyDollar}
              title={formatBRL(relatorioOperacao.resultado?.valorMedioTransacao)}
              subtitle="Ticket médio"
              variant={ClassTypesEnum.PRIMARY}
            />
          </div>

          <div className="d-flex col-12 col-md-6 col-xxl-3">
            <InfoIconCard
              className="mb-3"
              Icon={BsArrowUpCircle}
              title={formatBRL(relatorioOperacao.resultado?.valorTotalCompras)}
              subtitle="Valor total de compras"
              variant={ClassTypesEnum.SUCCESS}
            />
          </div>

          <div className="d-flex col-12 col-md-6 col-xxl-3">
            <InfoIconCard
              className="mb-3"
              Icon={BsArrowDownCircle}
              title={formatBRL(relatorioOperacao.resultado?.valorTotalEstornos)}
              subtitle="Valor total de estornos"
              variant={ClassTypesEnum.DANGER}
            />
          </div>

          <div className="d-flex col-12 col-md-6 col-xxl-3">
            <InfoIconCard
              className="mb-3"
              Icon={BsCurrencyExchange}
              title={formatBRL(relatorioOperacao.resultado?.valorTotalAutorizacoes)}
              subtitle="Valor total de autorizações"
              variant={ClassTypesEnum.PRIMARY}
            />
          </div>
        </div>
      </div>

      <div className="mb-5">
        <TitledCard title="Quantidade de transações por data">
          <div className="mb-4">
            <DetailElement
              descricao="Total de transações no período"
              valor={relatorioOperacao.resultado?.quantidadeTransacoes}
            />
          </div>

          <ResponsiveContainer className="p-1" height={400} width="100%">
            <AreaChart
              data={formatarDadosQtdeTransacoesPorData(relatorioOperacao.resultado.quanitdadeTransacoesPorData)}
            >
              <defs>
                <linearGradient id="colorTotal" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor={variables.primary} stopOpacity={0.8} />
                  <stop offset="95%" stopColor={variables.primary} stopOpacity={0} />
                </linearGradient>
              </defs>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="data" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Area
                connectNulls
                type="monotone"
                dataKey="total"
                stroke={variables.primary}
                activeDot={{ r: 5 }}
                fillOpacity={1}
                fill="url(#colorTotal)"
              />
            </AreaChart>
          </ResponsiveContainer>
        </TitledCard>
      </div>

      <div className="row">
        <div className="col-sm-12 col-xl-6 mb-5">
          <TitledCard title="Quantidade de transações por tipo de cartão">
            {todosDadosNulos(relatorioOperacao.resultado.quantidadeTransacoesPorTipoCartao) ? (
              <div className="d-flex justify-content-center align-items-center" style={{ height: 400 }}>
                <span>Nenhuma transação</span>
              </div>
            ) : (
              <ResponsiveContainer className="p-1" height={400} width="100%">
                <PieChart>
                  <Pie
                    dataKey="total"
                    data={formatarDadosQtdeTransacoesPorTipoCartao(
                      relatorioOperacao.resultado.quantidadeTransacoesPorTipoCartao
                    )}
                    fill={variables.primary}
                    label
                  >
                    {relatorioOperacao.resultado.quantidadeTransacoesPorTipoCartao.map(
                      ({ tipoCartao }: { tipoCartao: TipoCartaoEnum }, index: number) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={tipoCartao === TipoCartaoEnum.FISICO ? variables.primary : variables.secondary}
                        />
                      )
                    )}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            )}
          </TitledCard>
        </div>

        <div className="col-sm-12 col-xl-6 mb-5">
          <TitledCard title="Quantidade de transações por tipo de transação">
            {todosDadosNulos(relatorioOperacao.resultado.quantidadeTransacoesPorTipoTransacao) ? (
              <div className="d-flex justify-content-center align-items-center" style={{ height: 400 }}>
                <span>Nenhuma transação</span>
              </div>
            ) : (
              <ResponsiveContainer className="p-1" height={400} width="100%">
                <BarChart data={relatorioOperacao.resultado.quantidadeTransacoesPorTipoTransacao}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="descricao" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar
                    dataKey="total"
                    fill={variables.primary}
                    radius={[10, 10, 0, 0]}
                    onClick={onTipoTransacaoClickHandler}
                    barSize={100}
                  />
                </BarChart>
              </ResponsiveContainer>
            )}
          </TitledCard>
        </div>
      </div>
    </RbacPage>
  );
};

export default DetalhesRelatorioOperacaoAutorizacoesPage;

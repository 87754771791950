import BSPagination from 'react-bootstrap/Pagination';
import { useEffect } from 'react';

type SizesType = {
  onChangeSize: (pageSize: number) => void;
  options: number[];
  pageSize: number;
};

const Sizes: React.FC<SizesType> = ({ onChangeSize, options, pageSize }: SizesType) => {
  const _pageSize = pageSize ? pageSize : 10;

  useEffect(() => {
    if (_pageSize < options[0] || _pageSize > options[options.length - 1]) {
      onChangeSize(options[0]);
    }
  });

  return (
    <BSPagination data-testid="sizes">
      {options.map((option: number, index: number) => (
        <BSPagination.Item key={index} onClick={() => onChangeSize(option)} active={_pageSize === option}>
          {option}
        </BSPagination.Item>
      ))}
    </BSPagination>
  );
};

export default Sizes;

import React, { useCallback, useEffect, useMemo } from 'react';
import BSAlert from 'react-bootstrap/Alert';
import BSBadge from 'react-bootstrap/Badge';
import BSButton from 'react-bootstrap/Button';
import BSListGroup from 'react-bootstrap/ListGroup';
import BSTab from 'react-bootstrap/Tab';
import BSTabs from 'react-bootstrap/Tabs';
import { useParams } from 'react-router-dom';
import { DetailCard } from '../../components/details/datail-card';
import DetailElement from '../../components/details/detail-element';
import DetailTitle from '../../components/details/detail-title';
import { Loading } from '../../components/loading';
import {
  AfirmacaoEnum,
  ApiSingleElementResponse,
  ClassTypesEnum,
  FormatValueEnum,
  resultadoProcessamentoDocumentacaoClienteLabelMap,
  sexoLabelMap,
  statusProcessamentoDocumentacaoSolicitacaoAberturaContaLabelMap,
  statusSolicitacaoAberturaContaColorMap,
  statusSolicitacaoAberturaContaLabelMap,
  tipoDocumentoLabelMap,
  tipoEnderecoLabelMap,
  tipoPessoaLabelMap,
} from '../../helpers';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';

import {
  AcaoPermissaoPapelUsuarioEnum,
  ResultadoProcessamentoDocumentacaoClienteEnum,
  StatusSolicitacaoAberturaContaEnum,
} from '@tamborineapps/lib-enums';
import DetailSubTitle from '../../components/details/detail-subtitle';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import {
  loadSolicitacaoAberturaConta,
  selectSolicitacaoAberturaContaById,
  selectSolicitacaoAberturaContaLoadingStateByFilters,
} from './solicitacao-abertura-conta.redux';
import { loadProduto, selectProdutoById } from '../produtos/produtos.redux';

const { REPROVADO, REQUER_ANALISE } = StatusSolicitacaoAberturaContaEnum;
const { REQUER_ANALISE: DOC_REQUER_ANALISE } = ResultadoProcessamentoDocumentacaoClienteEnum;

const DetalhesSolicitacaoAberturaContaPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const params = useParams();
  const solicitacaoAberturaContaId = params?.solicitacaoAberturaContaId as string;

  const openInNewTab = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const filters = useMemo(() => ({ solicitacaoAberturaContaId }), [solicitacaoAberturaContaId]);
  const solicitacao = useSelector((state) => selectSolicitacaoAberturaContaById(state, solicitacaoAberturaContaId));
  const loadingState = useSelector((state) => selectSolicitacaoAberturaContaLoadingStateByFilters(state, filters));
  const produto = useSelector((state) => selectProdutoById(state, solicitacao?.produtoId));

  const loadEntidadesRelacionadas = useCallback(
    (solicitacao: any) => {
      dispatch(loadProduto({ produtoId: solicitacao?.produtoId })).catch((error: Error) => error);
    },
    [dispatch]
  );

  const _loadSolicitacaoAberturaConta = useCallback(
    () =>
      dispatch(loadSolicitacaoAberturaConta({ solicitacaoAberturaContaId }))
        .then(({ payload: { data } }: ApiSingleElementResponse) => loadEntidadesRelacionadas(data))
        .catch((error: Error) => error),
    [dispatch, loadEntidadesRelacionadas, solicitacaoAberturaContaId]
  );

  useEffect(() => {
    _loadSolicitacaoAberturaConta();
  }, [_loadSolicitacaoAberturaConta]);

  const possuiRelatorioGerado = solicitacao?.documentacao?.numeroAcessoDocumentacao;

  const necessitaAnaliseManualDashboardIdwall =
    solicitacao?.status === REQUER_ANALISE && solicitacao?.documentacao?.resultado === DOC_REQUER_ANALISE;

  if (!solicitacao) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Loading notFoundMessage="Cliente não encontrado" loadingState={loadingState} />
      </div>
    );
  }

  const contratosTermosUsoAceitos = solicitacao?.contratosTermosUsoAceitos;
  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_SOLICITACAO_ABERTURA_CONTA}>
      <div className="mb-5">
        <DetailCard>
          <div className="row mb-5">
            <div className="col-md-8 col-sm-12 mb-3">
              <DetailTitle>
                Solicitação de abertura de conta -{' '}
                {solicitacao.cliente.nomeSocial ? solicitacao.cliente.nomeSocial : solicitacao.cliente.nome}
              </DetailTitle>
            </div>

            <div className="col-md-4 col-sm-12 text-md-end text-sm-start">
              <h4 className="mb-0">
                <BSBadge
                  pill
                  bg={
                    statusSolicitacaoAberturaContaColorMap[
                      solicitacao.status as keyof typeof statusSolicitacaoAberturaContaColorMap
                    ]
                  }
                >
                  {
                    statusSolicitacaoAberturaContaLabelMap[
                      solicitacao.status as keyof typeof statusSolicitacaoAberturaContaLabelMap
                    ]
                  }
                </BSBadge>
              </h4>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-6 mb-4">
              <DetailElement descricao="Nome social" valor={solicitacao.cliente.nomeSocial} />
              <DetailElement descricao="Nome completo" valor={solicitacao.cliente.nome} />
              <DetailElement
                descricao="Sexo"
                valor={solicitacao.cliente.sexo}
                format={FormatValueEnum.ENUM}
                map={sexoLabelMap}
              />
              <DetailElement
                descricao="Data de Nascimento"
                valor={solicitacao.cliente.dataNascimento}
                format={FormatValueEnum.DATA}
              />
              <DetailElement descricao="Nome da Mãe" valor={solicitacao.cliente.nomeMae} />
              <DetailElement
                descricao="Tipo de Cliente"
                valor={solicitacao.cliente.tipo}
                format={FormatValueEnum.ENUM}
                map={tipoPessoaLabelMap}
              />
              <DetailElement
                descricao="CPF/CNPJ "
                valor={solicitacao.cliente.cpfCnpj}
                format={FormatValueEnum.CPF_CNPJ}
              />
              <DetailElement descricao="RG " valor={solicitacao.cliente.numeroRg} />
              <DetailElement descricao="UF do RG" valor={solicitacao.cliente.ufRg} />
              <DetailElement
                descricao="Possui deficiência visual"
                valor={solicitacao.cliente.ehDeficienteVisual}
                format={FormatValueEnum.BOOL}
              />
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <DetailElement descricao="Produto" valor={produto?.nome} />
              <DetailElement descricao="Dia do vencimento" valor={solicitacao.diaVencimento} />
              <DetailElement
                descricao="Limite de crédito"
                valor={solicitacao.limiteCredito}
                format={FormatValueEnum.BRL}
              />
              <DetailElement descricao="Código do cliente" valor={solicitacao.cliente.codigo} />
              <DetailElement
                descricao="Autoriza consulta de dados"
                valor={solicitacao.cliente.autorizacaoConsulta}
                format={FormatValueEnum.ENUM}
                map={AfirmacaoEnum}
              />
              <DetailElement descricao="Porte" valor={solicitacao.cliente.porteCliente} />
            </div>

            <div className="col-lg-4 col-md-6">
              <DetailElement
                descricao="Início do onboarding"
                valor={solicitacao.dataHoraInicioOnboarding}
                format={FormatValueEnum.DATE_TIME}
              />
              <DetailElement
                descricao="Finalização do onboarding"
                valor={solicitacao.dataHoraFinalizacaoOnboarding}
                format={FormatValueEnum.DATE_TIME}
              />
              <DetailElement
                descricao="Data e hora da resposta"
                valor={solicitacao.dataHoraResposta}
                format={FormatValueEnum.DATE_TIME}
              />
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <div className="mb-3">
                <DetailSubTitle>Endereço</DetailSubTitle>
              </div>

              <DetailElement
                descricao="Tipo"
                valor={solicitacao.endereco.tipo}
                map={tipoEnderecoLabelMap}
                format={FormatValueEnum.ENUM}
              />
              <DetailElement descricao="Logradouro" valor={solicitacao.endereco.logradouro} />
              <DetailElement descricao="Número" valor={solicitacao.endereco.numero} />
              <DetailElement descricao="Bairro" valor={solicitacao.endereco.bairro} />
              <DetailElement descricao="Complemeto" valor={solicitacao.endereco.complemento} />
              <DetailElement descricao="Cidade" valor={solicitacao.endereco.cidade} />
              <DetailElement descricao="CEP" valor={solicitacao.endereco.cep} />
              <DetailElement descricao="UF" valor={solicitacao.endereco.uf} />
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <div className="mb-3 ">
                <DetailSubTitle>Comunicação</DetailSubTitle>
              </div>

              <DetailElement descricao="Email" valor={solicitacao.dadosComunicacaoCliente.email} />
              <DetailElement
                descricao="Telefone"
                valor={solicitacao?.dadosComunicacaoCliente?.telefone}
                format={FormatValueEnum.TELEFONE}
              />
              <DetailElement
                descricao="Identificação dispositivo móvel"
                valor={solicitacao.dadosComunicacaoCliente.dispositivoMovel.tokenFCM}
              />
            </div>
          </div>

          {solicitacao?.status === REPROVADO && (
            <BSAlert variant={ClassTypesEnum.DANGER}>
              <DetailElement className="mb-0" descricao="Motivo da reprovação" valor={solicitacao.motivoReprovacao} />
            </BSAlert>
          )}

          {necessitaAnaliseManualDashboardIdwall && (
            <BSAlert variant={ClassTypesEnum.WARNING}>
              <DetailElement
                className="mb-0"
                descricao="Atenção"
                valor="Solicitação necessita de análise do relatório pelo dashboard da IdWall"
              />
            </BSAlert>
          )}

          {possuiRelatorioGerado && (
            <div className="d-flex justify-content-end mt-4">
              <BSButton
                variant="primary"
                type="button"
                onClick={() =>
                  openInNewTab(
                    `https://dashboard-beta.idwall.co/report-details/?reportConfig=sdk_tamborine_ocr_fm_bgc&id=${solicitacao?.documentacao?.numeroAcessoDocumentacao}`
                  )
                }
              >
                Acessar relatório IdWall
              </BSButton>
            </div>
          )}
        </DetailCard>
      </div>

      <DetailCard>
        <BSTabs defaultActiveKey="documentacao" fill unmountOnExit>
          <BSTab eventKey="documentacao" title="Documentação">
            <div className="row">
              <div className="col-xl-6 col-lg-12 mb-4">
                <div className="mb-3">
                  <DetailSubTitle>IdWall</DetailSubTitle>
                </div>

                <DetailElement
                  descricao="Status do processamento da documentação "
                  valor={solicitacao?.documentacao.statusProcessamento}
                  format={FormatValueEnum.ENUM}
                  map={statusProcessamentoDocumentacaoSolicitacaoAberturaContaLabelMap}
                />
                <DetailElement
                  descricao="Resultado"
                  valor={solicitacao?.documentacao.resultado}
                  format={FormatValueEnum.ENUM}
                  map={resultadoProcessamentoDocumentacaoClienteLabelMap}
                />
                <DetailElement
                  descricao="Identificador para acesso ao relatório"
                  valor={solicitacao?.documentacao?.numeroAcessoDocumentacao}
                />
                <DetailElement
                  descricao="Token de validação do SDK"
                  valor={solicitacao?.documentacao?.tokenValidacaoSDK}
                />
                <DetailElement
                  descricao="Início do processamento do relatório"
                  valor={solicitacao?.documentacao?.dataHoraInicioProcessamento}
                  format={FormatValueEnum.DATE_TIME}
                />
                <DetailElement
                  descricao="Finalização do processamento do relatório"
                  valor={solicitacao?.documentacao?.dataHoraFimProcessamento}
                  format={FormatValueEnum.DATE_TIME}
                />
                <DetailElement
                  descricao="Finalização completa da documentação"
                  valor={solicitacao?.documentacao?.dataHoraFinalizacao}
                  format={FormatValueEnum.DATE_TIME}
                />
                <DetailElement
                  descricao="Data e hora da obtenção dos dados pela IdWall"
                  valor={solicitacao?.documentacao?.dataHoraObtencaoDados}
                  format={FormatValueEnum.DATE_TIME}
                />
              </div>

              <div className="col-xl-6 col-lg-12 mb-4">
                <div className="mb-3">
                  <DetailSubTitle>Dados OCR</DetailSubTitle>
                </div>

                <DetailElement descricao="CPF" valor={solicitacao?.documentacao?.dadosOCR?.cpf} />
                <DetailElement
                  descricao="Data de Nascimento"
                  valor={solicitacao?.documentacao?.dadosOCR?.dataNascimento}
                  format={FormatValueEnum.DATA}
                />
                <DetailElement descricao="Nome" valor={solicitacao?.documentacao?.dadosOCR?.nome} />
                <DetailElement descricao="RG" valor={solicitacao?.documentacao?.dadosOCR?.rg} />
              </div>

              <div className="col-xl-6 col-lg-12 mb-4">
                <div className="mb-3">
                  <DetailSubTitle>Documento</DetailSubTitle>
                </div>

                <DetailElement
                  descricao="Tipo do documento "
                  valor={solicitacao?.documentacao?.documento?.tipo}
                  format={FormatValueEnum.ENUM}
                  map={tipoDocumentoLabelMap}
                />
                <DetailElement descricao="URL documento" valor={''} />
                {solicitacao?.documentacao?.documento?.referencias && (
                  <BSListGroup variant="flush">
                    {solicitacao?.documentacao?.documento?.referencias.map((url: any, index: any) => (
                      <BSListGroup.Item key={index}>{url}</BSListGroup.Item>
                    ))}
                  </BSListGroup>
                )}

                <DetailElement descricao="URL imagem" valor={''} />
                {solicitacao?.documentacao?.imagemFacematch?.referencias && (
                  <BSListGroup variant="flush">
                    {solicitacao?.documentacao?.imagemFacematch?.referencias.map((url: any, index: any) => (
                      <BSListGroup.Item key={index}>{url}</BSListGroup.Item>
                    ))}
                  </BSListGroup>
                )}
              </div>
            </div>
          </BSTab>

          <BSTab eventKey="contratos" title="Contratos">
            <div className="row">
              <div className="col-xl-6 col-lg-12 mb-4">
                <div className="mb-3">
                  <DetailSubTitle>Termos de uso aceitos</DetailSubTitle>
                </div>
                {contratosTermosUsoAceitos && (
                  <BSListGroup variant="flush">
                    {contratosTermosUsoAceitos.map((item: any, index: any) => (
                      <BSListGroup.Item key={index}>{item.tipo}</BSListGroup.Item>
                    ))}
                  </BSListGroup>
                )}
              </div>
            </div>
          </BSTab>
        </BSTabs>
      </DetailCard>
    </RbacPage>
  );
};

export default DetalhesSolicitacaoAberturaContaPage;

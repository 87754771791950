import { reducerFactory, actionFactory } from '../../store';

const context = 'protocoloSolicitacaoCliente';

const { createOne } = actionFactory(context, {
  buildUrlCreateOne: () => '/api/solicitacoes-clientes/protocolo',
});

export const gerarProtocoloSolicitacao = createOne;

export const protocoloSolicitacaoCliente = reducerFactory({ context });

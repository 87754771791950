import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { DetailCard } from '../../components/details/datail-card';
import DetailElement from '../../components/details/detail-element';
import DetailSubTitle from '../../components/details/detail-subtitle';
import DetailTitle from '../../components/details/detail-title';
import { Loading } from '../../components/loading';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import {
  FormatValueEnum,
  codigoRespostaAutorizadorLabelMap,
  tipoBloqueioContaCartaoLabelMap,
  utilizacaoLabelMap,
} from '../../helpers';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import {
  loadTipoBloqueioContaCartao,
  selectTiposBloqueioContaCartaoById,
  selectTiposBloqueioContaCartaoLoadingStateByFilters,
} from './tipos-bloqueio-conta-cartao.redux';

const DetalhesTipoBloqueioContaCartaoPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const params = useParams();
  const tipoBloqueioContaCartaoId = params.tipoBloqueioContaCartaoId as string;

  const filters = useMemo(() => ({ tipoBloqueioContaCartaoId }), [tipoBloqueioContaCartaoId]);
  const tipoBloqueioContaCartao = useSelector((state) =>
    selectTiposBloqueioContaCartaoById(state, tipoBloqueioContaCartaoId)
  );
  const loadingState = useSelector((state) => selectTiposBloqueioContaCartaoLoadingStateByFilters(state, filters));

  useEffect(() => {
    dispatch(loadTipoBloqueioContaCartao({ tipoBloqueioContaCartaoId })).catch((error: Error) => error);
  }, [dispatch, tipoBloqueioContaCartaoId]);

  if (!tipoBloqueioContaCartao) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Loading notFoundMessage="Tipo de bloqueio de conta-cartão não encontrado" loadingState={loadingState} />
      </div>
    );
  }

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_TIPO_BLOQUEIO_CONTA_CARTAO}>
      <div className="mb-5">
        <DetailCard>
          <div className="mb-5">
            <DetailTitle>
              Tipo de bloqueio de conta cartão: {tipoBloqueioContaCartao.descricao} ({tipoBloqueioContaCartao.codigo})
            </DetailTitle>
          </div>

          <div className="row mb-4">
            <div className="col-lg-4 col-md-6 mb-4">
              <DetailElement
                descricao="Resposta autorizador"
                valor={tipoBloqueioContaCartao.respostaAutorizado}
                format={FormatValueEnum.ENUM}
                map={codigoRespostaAutorizadorLabelMap}
              />
              <DetailElement descricao="Prioridade" valor={tipoBloqueioContaCartao.prioridade} />
              <DetailElement
                descricao="Operação"
                valor={tipoBloqueioContaCartao.operacao}
                format={FormatValueEnum.ENUM}
                map={tipoBloqueioContaCartaoLabelMap}
              />
              <DetailElement
                descricao="Tipo"
                valor={tipoBloqueioContaCartao.tipo}
                format={FormatValueEnum.ENUM}
                map={utilizacaoLabelMap}
              />
              <DetailElement
                descricao="Saldo deve ser antecipado"
                valor={tipoBloqueioContaCartao.antecipaDivida}
                format={FormatValueEnum.BOOL}
              />
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <div className="mb-3">
                <DetailSubTitle>Permissões</DetailSubTitle>
              </div>

              <DetailElement
                descricao="Permite emissão segunda via"
                valor={tipoBloqueioContaCartao.permiteEmissaoSegundaVia}
                format={FormatValueEnum.BOOL}
              />
              <DetailElement
                descricao="Permite cobrança de encargos"
                valor={tipoBloqueioContaCartao.cobraEncargos}
                format={FormatValueEnum.BOOL}
              />
              <DetailElement
                descricao="Permite alterar vencimento"
                valor={tipoBloqueioContaCartao.permiteAlterarVencimento}
                format={FormatValueEnum.BOOL}
              />
              <DetailElement
                descricao="É bloqueio definitivo"
                valor={tipoBloqueioContaCartao.bloqueioDefinitivo}
                format={FormatValueEnum.BOOL}
              />
              <DetailElement
                descricao="Cartão pode ser cancelado"
                valor={tipoBloqueioContaCartao.cancelaCartao}
                format={FormatValueEnum.BOOL}
              />
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <div className="mb-3">
                <DetailSubTitle>Cobrança</DetailSubTitle>
              </div>

              <DetailElement
                descricao="Cobra multa por atraso"
                valor={tipoBloqueioContaCartao.cobraMultaPorAtraso}
                format={FormatValueEnum.BOOL}
              />
              <DetailElement
                descricao="Cobra juros por atraso"
                valor={tipoBloqueioContaCartao.cobraJurosPorAtraso}
                format={FormatValueEnum.BOOL}
              />
              <DetailElement
                descricao="Inicia processo cobrança com sistema externo"
                valor={tipoBloqueioContaCartao.acionaSistemaCobranca}
                format={FormatValueEnum.BOOL}
              />
            </div>
          </div>
        </DetailCard>
      </div>
    </RbacPage>
  );
};

export default DetalhesTipoBloqueioContaCartaoPage;

import { reducerFactory, actionFactory, selectorFactory } from '../../store';

const context = 'perguntasSeguranca';

const { createOne, loadMany, updateOne } = actionFactory(context, {
  buildUrlCreateOne: () => '/api/perguntas-seguranca',
  buildUrlLoadMany: () => '/api/perguntas-seguranca',
  buildUrlUpdateOne: ({ perguntaSegurancaId }) => `/api/perguntas-seguranca/${perguntaSegurancaId}`,
});

const { selectOneById, selectManyByFilters, selectTotalByFilters, selectLoadingStateByFilters } = selectorFactory({
  context,
});

export const createPerguntaSeguranca = createOne;
export const loadPerguntasSeguranca = loadMany;
export const updatePerguntaSeguranca = updateOne;

export const selectPerguntasSegurancaById = selectOneById;
export const selectPerguntasSegurancaByFilters = selectManyByFilters;
export const selectTotalPerguntasSegurancaByFilters = selectTotalByFilters;
export const selectPerguntasSegurancaLoadingStateByFilters = selectLoadingStateByFilters;

export const perguntasSeguranca = reducerFactory({ context });

import { useCallback } from 'react';
import BSButton from 'react-bootstrap/Button';
import BSModal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { Form } from '../../components/form/form';
import { FormCheck } from '../../components/form/form-check';
import { FormControl } from '../../components/form/form-control';
import { FormSelect } from '../../components/form/form-select';
import { ClassTypesEnum, naturezaTransacaoLabelMap, tipoTransacaoLabelMap, utilizacaoLabelMap } from '../../helpers';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch } from '../../store/hooks-redux';
import { createTipoTransacao } from './tipos-transacao.redux';

type CadastrarTiposTransacaoFormFields = {
  codigo: string;
  descricao: string;
  descricaoFatura: string;
  prazoEmDiasParaConfirmacao: number;
  operacao: string;
  funcao: string;
  utilizacao: string;
  prioridadePagamentoContabil: number;
  apareceNaFatura: boolean;
  parcelaNaoFinanciavel: boolean;
};

type CadastrarTiposTransacaoProps = {
  closeDialog: () => void;
  reload: () => void;
};

const CadastrarTiposTransacaoDialog: React.FC<CadastrarTiposTransacaoProps> = ({ closeDialog, reload }) => {
  const dispatch = useAppDispatch();
  const { showToast } = useToasts();

  const form = useForm<CadastrarTiposTransacaoFormFields>();
  const { control } = form;

  const onSubmitHandler = useCallback(
    (data: CadastrarTiposTransacaoFormFields) => {
      dispatch(createTipoTransacao({ data }))
        .then(() => {
          closeDialog();
          showToast({ message: 'Tipo de transação cadastrado com sucesso', type: ClassTypesEnum.SUCCESS });
          reload();
        })
        .catch((error: Error) => {
          showToast({ message: error.message, type: ClassTypesEnum.DANGER });
        });
    },
    [closeDialog, dispatch, reload, showToast]
  );

  return (
    <>
      <BSModal.Header closeButton>
        <BSModal.Title>Novo tipo de transação</BSModal.Title>
      </BSModal.Header>

      <BSModal.Body>
        <Form id="form" form={form} onSubmit={onSubmitHandler}>
          <div className="row">
            <FormControl
              control={control}
              className="col-md-6 mb-3"
              label="Código"
              name="codigo"
              type="text"
              rules={{ required: true }}
            />

            <FormControl
              control={control}
              className="col-md-6 mb-3"
              label="Descrição"
              name="descricao"
              type="text"
              rules={{ required: true }}
            />

            <FormControl
              control={control}
              className="col-md-6 mb-3"
              label="Descrição que aparece na fatura"
              name="descricaoFatura"
              type="text"
            />

            <FormControl
              control={control}
              className="col-md-6 mb-3"
              label="Dias para confirmação"
              name="prazoEmDiasParaConfirmacao"
              type="number"
              rules={{ required: false, min: 0 }}
            />

            <FormSelect
              control={control}
              className="col-md-6 mb-3"
              label="Operação"
              name="operacao"
              placeholder="Selecione a operação"
              options={Object.entries(tipoTransacaoLabelMap)
                .map((elem) => ({
                  label: `${elem[1]}`,
                  value: `${elem[0]}`,
                }))
                .sort((a, b) => a.label.localeCompare(b.label))}
            />

            <FormSelect
              control={control}
              className="col-md-6 mb-3"
              label="Função"
              name="funcao"
              placeholder="Selecione a função"
              options={Object.entries(naturezaTransacaoLabelMap).map((elem) => ({
                label: `${elem[1]}`,
                value: `${elem[0]}`,
              }))}
              rules={{ required: true }}
            />

            <FormSelect
              control={control}
              className="col-md-6 mb-3"
              label="Utilização"
              name="utilizacao"
              placeholder="Selecione a utilização"
              options={Object.entries(utilizacaoLabelMap).map((elem) => ({
                label: `${elem[1]}`,
                value: `${elem[0]}`,
              }))}
              rules={{ required: true }}
            />

            <FormControl
              control={control}
              className="col-md-6 mb-3"
              label="Prioridade pagamento contábil"
              name="prioridadePagamentoContabil"
              type="number"
              rules={{ min: 0 }}
            />

            <FormCheck
              control={control}
              className="col-md-6 mb-3"
              name="apareceNaFatura"
              label="Aparece na fatura"
              type="switch"
            />

            <FormCheck
              control={control}
              className="col-md-6 mb-3"
              name="parcelaNaoFinanciavel"
              label="Parcela não financiável"
              type="switch"
            />
          </div>
        </Form>
      </BSModal.Body>

      <BSModal.Footer>
        <BSButton variant="light" onClick={closeDialog}>
          Fechar
        </BSButton>
        <BSButton variant="primary" type="submit" form="form">
          Cadastrar
        </BSButton>
      </BSModal.Footer>
    </>
  );
};

export default CadastrarTiposTransacaoDialog;

import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import { useCallback, useMemo } from 'react';
import BSCard from 'react-bootstrap/Card';
import { BsPencilSquare } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import IconButton from '../../components/button/icon-button';
import RbacElement from '../../components/role-based-access-control/role-based-access-control-element';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import ExportCSVButton from '../../components/smart-table/export-csv-button';
import { SmartTable } from '../../components/smart-table/smart-table';
import SmartTableButton from '../../components/smart-table/smart-table-button';
import SmartTableFilters from '../../components/smart-table/smart-table-filters/smart-table-filters';
import {
  SmartTableFilterControlTypesEnum,
  SmartTableFilterProps,
} from '../../components/smart-table/smart-table-filters/table-filter.types';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import Title from '../../components/title';
import {
  ApiMultiElementResponse,
  ClassTypesEnum,
  FormatValueEnum,
  SizesEnum,
  mapearERemoverElementosNulosERepetidos,
  tipoEventosContabeisLabelMap,
} from '../../helpers';
import { DialogProps } from '../../hooks/dialog/dialog-context';
import { useDialog } from '../../hooks/dialog/use-dialog';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import { loadTiposTransacao, selectObjectTodosTiposTransacao } from '../tipos-transacao/tipos-transacao.redux';
import AtualizarRoteiroContabilDialog from './atualizar-roteiro-contabil-dialog';
import CadastrarRoteiroContabilDialog from './cadastrar-roteiros-contabeis-dialog';
import {
  loadRoteirosContabeis,
  selectLoadingStateByFiltersRoteiroContabil,
  selectRoteirosContabeisByFilters,
  selectTotalRoteirosContabeisByFilters,
  updateRoteiroContabil,
} from './roteiro-contabil.redux';

const smartFilters = (): SmartTableFilterProps[] => [
  {
    label: 'Descrição',
    attribute: 'descricao',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
  },
  {
    label: 'Conta de crédito',
    attribute: 'contaContabilCredito',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
  },
  {
    label: 'Conta de débito',
    attribute: 'contaContabilDebito',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
  },
];

const smartColumns = ({
  showDialog,
  closeDialog,
  onUpdateRoteiroContabilHandler,
  tiposTransacao,
}: {
  showDialog: (dialogProps: DialogProps) => void;
  closeDialog: () => void;
  onUpdateRoteiroContabilHandler: (roteiroContabilId: string, data: any) => void;
  tiposTransacao: any;
}): SmartTableColumnProps[] => [
  {
    label: 'Descrição',
    attribute: 'descricao',
    className: 'text-center',
    sortable: true,
  },
  {
    label: 'Conta de crédito',
    attribute: 'contaContabilCredito',
    className: 'text-center',
    sortable: true,
  },
  {
    label: 'Conta de débito',
    attribute: 'contaContabilDebito',
    className: 'text-center',
    sortable: true,
  },
  {
    label: 'Habilitado',
    attribute: 'habilitado',
    className: 'text-center',
    format: FormatValueEnum.BOOL,
    sortable: true,
  },
  {
    label: 'Tipo de transação',
    attribute: 'tipoTransacao',
    className: 'text-center',
    format(tipoTransacao) {
      if (!tipoTransacao) {
        return '-';
      }

      return tiposTransacao[tipoTransacao]?.codigo ? (
        <Link onClick={(e) => e.stopPropagation()} to={`/tipos-transacao/${tipoTransacao}`}>
          {tiposTransacao[tipoTransacao]?.codigo} - {tiposTransacao[tipoTransacao]?.descricao}
        </Link>
      ) : (
        '-'
      );
    },
  },
  {
    label: 'Ações',
    format: (_id, roteiroContabil) => {
      ('');
      return (
        <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.ALTERACAO_ROTEIRO_CONTABIL}>
          <div className="d-flex justify-content-center">
            <div className="mx-1">
              <IconButton
                Icon={BsPencilSquare}
                type={ClassTypesEnum.PRIMARY}
                onClick={() => {
                  showDialog({
                    component: (
                      <AtualizarRoteiroContabilDialog
                        closeDialog={closeDialog}
                        roteiroContabil={roteiroContabil}
                        onUpdateHandler={onUpdateRoteiroContabilHandler}
                      />
                    ),
                    size: SizesEnum.LARGE,
                  });
                }}
              />
            </div>
          </div>
        </RbacElement>
      );
    },
  },
];

const csvHeaders = [
  { label: ' Conta contábil credito', key: 'contaContabilCredito' },
  { label: ' Conta contábil debito', key: 'contaContabilDebito' },
  { label: ' Descrição', key: 'descricao' },
  { label: ' Habilitado', key: 'habilitado' },
  { label: 'Evento contabil', key: 'eventoContabil' },
  { label: 'Status da conta', key: 'statusConta' },
];
const RoteirosContabeisPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;
  const { showDialog, closeDialog } = useDialog();

  const navigate = useNavigate();
  const { showToast } = useToasts();
  const { query, maxItemsQuery } = useQuerystring();

  if (!query.sort) {
    query.sort = 'descricao';
  }

  const filters = useMemo(() => ({ ...query }), [query]);
  const total = useSelector((state) => selectTotalRoteirosContabeisByFilters(state, filters));
  const roteirosContabeis = useSelector((state) => selectRoteirosContabeisByFilters(state, filters));
  const loadingState = useSelector((state) => selectLoadingStateByFiltersRoteiroContabil(state, filters));

  const tiposTransacao = useSelector((state) => selectObjectTodosTiposTransacao(state));

  const _loadTiposTransacao = useCallback(
    (roetirosContabeis: any[]) => {
      const tiposTransacao = mapearERemoverElementosNulosERepetidos(roetirosContabeis, 'tipoTransacao');

      dispatch(loadTiposTransacao({ query: { _id: { in: tiposTransacao }, ...maxItemsQuery } })).catch(
        (error: Error) => error
      );
    },
    [dispatch, maxItemsQuery]
  );

  const loadItems = useCallback(
    () =>
      dispatch(loadRoteirosContabeis({ query: filters }))
        .then(({ payload: { data } }: ApiMultiElementResponse) => _loadTiposTransacao(data))
        .catch((error: Error) => error),
    [dispatch, filters, _loadTiposTransacao]
  );
  const onNovoRoteiroContabilHandler = () => {
    showDialog({
      component: <CadastrarRoteiroContabilDialog closeDialog={closeDialog} reload={loadItems} />,
      size: SizesEnum.LARGE,
    });
  };
  const onUpdateRoteiroContabilHandler = (roteiroContabilId: string, data: any) => {
    if (!data) {
      return;
    }

    dispatch(updateRoteiroContabil({ params: { roteiroContabilId }, data }))
      .then(() => {
        closeDialog();

        showToast({
          message: 'Roteiro contábil atualizado com sucesso',
          type: ClassTypesEnum.SUCCESS,
        });
        return loadItems();
      })
      .catch((error: Error) => {
        showToast({
          message: error.message,
          type: ClassTypesEnum.DANGER,
        });
      });
  };
  const roteirosContabeisExcel = roteirosContabeis?.map(({ _id, ...dadosRoteirosContabeis }: any) => {
    let novosDados = dadosRoteirosContabeis;
    novosDados['eventoContabil'] =
      tipoEventosContabeisLabelMap[novosDados.eventoContabil as keyof typeof tipoEventosContabeisLabelMap];
    return novosDados;
  });

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_ROTEIRO_CONTABIL}>
      <div className="d-flex flex-column h-100">
        <Title>Roteiros contábeis</Title>

        <div className="row">
          <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-12 mb-4">
            <SmartTableFilters filters={smartFilters()} />
          </div>

          <div className="col-xxl-10 col-xl-9 col-lg-8 col-md-12 mb-4">
            <BSCard>
              <BSCard.Body>
                <div className="d-flex align-items-center mb-2">
                  <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.CRIACAO_ROTEIRO_CONTABIL}>
                    <SmartTableButton onClick={onNovoRoteiroContabilHandler}>Novo Roteiro Contábil</SmartTableButton>
                  </RbacElement>
                  <ExportCSVButton data={roteirosContabeisExcel} filename="roteiros-contabeis" headers={csvHeaders} />
                </div>
                <SmartTable
                  emptyMessage="Nenhum roteiro contábil foi encontrado"
                  errorMessage="Erro na listagem de roteiros contábeis"
                  usePagination={true}
                  loadItems={loadItems}
                  columns={smartColumns({ closeDialog, showDialog, tiposTransacao, onUpdateRoteiroContabilHandler })}
                  items={roteirosContabeis}
                  loadingState={loadingState}
                  size={total}
                  onRowClick={(doc) => navigate(`/roteiros-contabeis/${doc._id}`)}
                />
              </BSCard.Body>
            </BSCard>
          </div>
        </div>
      </div>
    </RbacPage>
  );
};

export default RoteirosContabeisPage;

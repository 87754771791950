import BSForm from 'react-bootstrap/Form';

import { FormCheckProps as BSFormCheckProps } from 'react-bootstrap/FormCheck';
import { FieldValue, FieldValues, useController, UseControllerProps } from 'react-hook-form';

interface FormCheckProps<TFormValues extends FieldValues>
  extends Omit<BSFormCheckProps, 'defaultValue' | 'name'>,
    UseControllerProps<TFormValues> {
  label?: string;
}

export const FormCheck = <TFormValues extends FieldValues>({
  control,
  name,
  label,
  rules,
  shouldUnregister,
  className,
  ...props
}: FormCheckProps<TFormValues>): JSX.Element => {
  const defaultValue = false as FieldValue<TFormValues>;

  const {
    field: { onChange, onBlur, name: fieldName, value },
    fieldState: { error },
  } = useController<TFormValues>({
    name,
    control,
    rules,
    defaultValue,
    shouldUnregister,
  });

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange?.(event);
    onChange(props.value ? event.target.value : event.target.checked);
  };

  const onBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
    props.onBlur?.(event);
    onBlur();
  };

  return (
    <div data-testid="form-check" className={className}>
      <BSForm.Check
        className={props.type === 'checkbox' ? 'form-checkbox' : ''}
        {...props}
        id={name}
        label={label}
        feedbackType="invalid"
        feedback={Boolean(error) && (error?.message || 'Campo inválido')}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
        checked={props.value ? value === props.value : value}
        name={fieldName}
        role={props.disabled ? '' : 'button'}
      />
    </div>
  );
};

import { useCallback } from 'react';

import DetailElement from '../../components/details/detail-element';
import DetailSubTitle from '../../components/details/detail-subtitle';
import { SmartTable } from '../../components/smart-table/smart-table';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import { formatBool, formatEnum, tipoCartaoLabelMap } from '../../helpers';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import { selectProdutoById } from './produtos.redux';
import {
  loadSubBinsProdutoId,
  selectSubBinsLoadingStateByFilters,
  selectSubBinsByFilters,
  selectTotalSubBinsByFilters,
} from './sub-bins/sub-bins.redux';

const smartColumns = (): SmartTableColumnProps[] => [
  {
    label: 'Sub-BIN',
    attribute: 'subBin',
    sortable: true,
    className: 'text-center',
  },
  {
    label: 'Tipo de cartão',
    attribute: 'tipoCartao',
    sortable: true,
    format: (tipoCartao: string) => formatEnum(tipoCartao, tipoCartaoLabelMap),
    className: 'text-center',
  },
  {
    label: 'Permite utilização ',
    attribute: 'permiteUtilizacao',
    sortable: true,
    format: (permiteUtilizacao: boolean) => formatBool(permiteUtilizacao),
    className: 'text-center',
  },
];

type CartoesProdutoTabProps = {
  produtoId: string;
};

const CartoesProdutoTab: React.FC<CartoesProdutoTabProps> = ({ produtoId }) => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const { query } = useQuerystring();

  if (!query.sort) {
    query.sort = 'subBin';
  }

  const filters = { ...query, produtoId };

  const produto = useSelector((state) => selectProdutoById(state, produtoId));
  const total = useSelector((state) => selectTotalSubBinsByFilters(state, filters));
  const subBins = useSelector((state) => selectSubBinsByFilters(state, filters));
  const loadingState = useSelector((state) => selectSubBinsLoadingStateByFilters(state, filters));

  const loadItems = useCallback(
    () => dispatch(loadSubBinsProdutoId({ params: { produtoId }, query })).catch((error: Error) => error),
    [dispatch, produtoId, query]
  );

  return (
    <>
      <div className="row">
        <div className="col-lg-4 col-md-6 mb-4">
          <DetailElement
            descricao="Limite de cartões Virtuais por Conta"
            valor={produto.limiteCartoesVirtuaisPorConta}
          />
          <DetailElement
            descricao="Limite de cartões adicionais por conta"
            valor={produto.limiteMaximoCartoesAdicionaisPorConta}
          />
        </div>
      </div>

      <div className="mb-5">
        <hr />
      </div>

      <div className="mb-4">
        <DetailSubTitle>Sub-BINs</DetailSubTitle>
      </div>

      <SmartTable
        columns={smartColumns()}
        errorMessage="Erro ao listar sub-bins"
        items={subBins}
        emptyMessage="Nenhum registro de sub-bin encontrado"
        loadingState={loadingState}
        loadItems={loadItems}
        size={total}
        usePagination={true}
      />
    </>
  );
};

export default CartoesProdutoTab;

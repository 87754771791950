import DetailElement from '../../components/details/detail-element';
import { useAppSelector } from '../../store/hooks-redux';
import { selectEmissor } from './emissor.redux';

const ContabilEmissorTab: React.FC = () => {
  const useSelector = useAppSelector;
  const [emissor] = useSelector((state) => selectEmissor(state, {})) ?? [];

  return (
    <div className="row">
      <div className="col-lg-4 col-md-6 mb-4">
        <DetailElement descricao="Nome do responsável" valor={emissor.nomeResponsavelContabil} />
        <DetailElement descricao="Email do responsável" valor={emissor.emailResponsavelContabil} />
        <DetailElement descricao="Telefone do responsável" valor={emissor.telefoneResponsavelContabil} />
      </div>
    </div>
  );
};

export default ContabilEmissorTab;

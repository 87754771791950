import BSContainer from 'react-bootstrap/Container';

type PageProps = {
  children: React.ReactNode;
};

const Page: React.FC<PageProps> = ({ children }) => {
  return (
    <BSContainer className="px-md-5 px-2 pt-4 pb-5" fluid>
      {children}
    </BSContainer>
  );
};

export default Page;

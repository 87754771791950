import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import React, { useCallback, useMemo } from 'react';
import BSCard from 'react-bootstrap/Card';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import { SmartTable } from '../../components/smart-table/smart-table';
import SmartTableFilters from '../../components/smart-table/smart-table-filters/smart-table-filters';
import {
  SmartTableFilterControlTypesEnum,
  SmartTableFilterProps,
} from '../../components/smart-table/smart-table-filters/table-filter.types';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import Title from '../../components/title';
import { FormatValueEnum } from '../../helpers';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import {
  loadSequenciais,
  selectLoadingStateByFiltersSequencial,
  selectSequenciais,
  selectTotalSequenciaisByFilters,
} from './sequenciais.redux';

const smartFilters = (): SmartTableFilterProps[] => [
  {
    label: 'Data de modificação',
    attribute: 'dataModificacao',
    controlType: SmartTableFilterControlTypesEnum.DATE_INPUT,
  },
  {
    label: 'Atributo',
    attribute: 'atributo',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
  },
  {
    label: 'Id atual',
    attribute: 'idAtual',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
  },
];

const smartColumns = (): SmartTableColumnProps[] => [
  {
    label: 'Atributo',
    attribute: 'atributo',
    className: 'text-center',
    sortable: true,
  },
  {
    label: 'Data de modificação',
    attribute: 'dataModificacao',
    className: 'text-center',
    format: FormatValueEnum.DATE_TIME,
    sortable: true,
  },
  {
    label: 'Id atual',
    attribute: 'idAtual',
    className: 'text-center',
    sortable: true,
  },
];

const SequenciaisPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const { query } = useQuerystring();

  if (!query.sort) {
    query.sort = '-dataModificacao';
  }

  const filters = useMemo(() => ({ ...query }), [query]);
  const total = useSelector((state) => selectTotalSequenciaisByFilters(state, filters));
  const sequenciais = useSelector((state) => selectSequenciais(state, filters));
  const loadingState = useSelector((state) => selectLoadingStateByFiltersSequencial(state, filters));

  const loadSequenciaisOnTable = useCallback(
    () => dispatch(loadSequenciais({ query: filters })).catch((error: Error) => error),
    [dispatch, filters]
  );

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_SEQUENCIAL}>
      <div className="d-flex flex-column h-100">
        <Title>Sequenciais</Title>

        <div className="row">
          <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-12 mb-4">
            <SmartTableFilters filters={smartFilters()} />
          </div>

          <div className="col-xxl-10 col-xl-9 col-lg-8 col-md-12 mb-4">
            <BSCard>
              <BSCard.Body>
                <SmartTable
                  emptyMessage="Nenhum sequencial encontrado"
                  errorMessage="Erro na listagem de sequenciais"
                  usePagination={true}
                  loadItems={loadSequenciaisOnTable}
                  columns={smartColumns()}
                  items={sequenciais}
                  loadingState={loadingState}
                  size={total}
                />
              </BSCard.Body>
            </BSCard>
          </div>
        </div>
      </div>
    </RbacPage>
  );
};
export default SequenciaisPage;

import { useCallback, useEffect, useState } from 'react';
import BSButton from 'react-bootstrap/Button';
import BSCard from 'react-bootstrap/Card';
import { useParams } from 'react-router-dom';
import { DetailCard } from '../../components/details/datail-card';
import DetailElement from '../../components/details/detail-element';
import DetailSubTitle from '../../components/details/detail-subtitle';
import DetailTitle from '../../components/details/detail-title';
import ConfirmationDialog from '../../components/dialog/confirmation-dialog';
import Page from '../../components/page';
import { ApiMultiElementResponse, ClassTypesEnum, FormatValueEnum } from '../../helpers';
import { useDialog } from '../../hooks/dialog/use-dialog';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch } from '../../store/hooks-redux';
import { simularAntecipacaoParcelas } from './simulador-antecipacao-parcelas.redux';
import { anteciparTransacoes } from './transacoes.redux';

const SimulacaoAntecipacaoParcelasPage: React.FC = () => {
  const dispatch = useAppDispatch();

  const params = useParams();
  const transacaoId = params.transacaoId as string;

  const [opcoesAntecipacao, SetOpcoesAntecipacao] = useState<any[]>([]);
  const { showToast } = useToasts();
  const { showDialog, closeDialog } = useDialog();

  const loadSimulacaoParcelas = useCallback(
    () =>
      dispatch(simularAntecipacaoParcelas({ params: { transacaoId }, data: {} }))
        .then(({ payload: { data } }: ApiMultiElementResponse) => SetOpcoesAntecipacao(data))
        .catch((error: Error) => error),
    [dispatch, transacaoId]
  );

  const onSubmitAntecipacaoParcelaHandler = (data: any) => {
    const { quantidadeParcelas } = data;

    if (!quantidadeParcelas) {
      return showToast({
        message: 'Proposta não selecionada',
        type: ClassTypesEnum.DANGER,
      });
    }

    dispatch(anteciparTransacoes({ params: { transacaoId }, data: { quantidadeParcelas } }))
      .then(() => {
        showToast({
          message: 'Antecipação realizada com sucesso!',
          type: ClassTypesEnum.SUCCESS,
        });

        window.history.back();
      })
      .catch((error: Error) => {
        showToast({ message: error.message, type: ClassTypesEnum.DANGER });
      });
  };

  useEffect(() => {
    loadSimulacaoParcelas();
  }, [loadSimulacaoParcelas]);

  return (
    <Page>
      <div className="mb-5">
        <DetailCard>
          <div className="mb-5">
            <DetailTitle>Antecipação de parcelas</DetailTitle>
          </div>

          <div className="row">
            {opcoesAntecipacao.map((opcao: any, index: number) => (
              <div className="col-lg-4 col-md-6 mb-4" key={index}>
                <BSCard className="p-5">
                  <div className="mb-4">
                    <DetailSubTitle>Opção {index + 1}</DetailSubTitle>
                  </div>

                  <div className="mb-4">
                    <DetailElement descricao="Quantidade de parcelas" valor={opcao.quantidadeParcelas} />
                    <DetailElement descricao="Desconto" valor={opcao.valorDesconto} format={FormatValueEnum.BRL} />
                    <DetailElement
                      descricao="Valor total de parcelas"
                      valor={opcao.valorTotal}
                      format={FormatValueEnum.BRL}
                    />
                  </div>

                  <BSButton
                    variant="primary"
                    onClick={() => {
                      const message = (
                        <span>
                          Tem certeza que deseja selecionar a <strong>Opção {index + 1}</strong> para antecipar as
                          parcelas da transação?
                        </span>
                      );

                      showDialog({
                        component: (
                          <ConfirmationDialog
                            onConfirmation={() => onSubmitAntecipacaoParcelaHandler(opcao)}
                            onHide={closeDialog}
                            confirmationLabel="Realizar antecipação de parcelas"
                            message={message}
                          />
                        ),
                      });
                    }}
                  >
                    Confirmar antecipação de parcelas
                  </BSButton>
                </BSCard>
              </div>
            ))}
          </div>
        </DetailCard>
      </div>
    </Page>
  );
};

export default SimulacaoAntecipacaoParcelasPage;

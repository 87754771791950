import { Route, Routes } from 'react-router-dom';
import SequenciaisPage from './sequenciais-page';

const SequenciaisRoutes: React.FC = () => {
  return (
    <Routes>
      <Route index element={<SequenciaisPage />} />
    </Routes>
  );
};

export default SequenciaisRoutes;

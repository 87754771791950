import BSButton from 'react-bootstrap/Button';
import BSCard from 'react-bootstrap/Card';
import DetailElement from '../../components/details/detail-element';
import DetailSubTitle from '../../components/details/detail-subtitle';
import DetailTitle from '../../components/details/detail-title';

import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { DetailCard } from '../../components/details/datail-card';
import { Form } from '../../components/form/form';
import { FormControl, MasksFormControlEnum } from '../../components/form/form-control';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import { ApiSingleElementResponse, formatBRL } from '../../helpers';
import { useDialog } from '../../hooks/dialog/use-dialog';
import { useAppDispatch } from '../../store/hooks-redux';
import InformarErroCalculoDialog from './informar-erro-calculo-dialog';
import { simularJurosRotativo } from './simulador-juros-rotativo.redux';

type SimuladorJurosRotativoFormFields = {
  intervaloDias: string;
  taxaJuros: string;
  valorBase: string;
};

const SimuladorJurosRotativoPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { showDialog, closeDialog } = useDialog();

  const form = useForm<SimuladorJurosRotativoFormFields>();
  const { control, reset } = form;

  const [resultadoCalculo, setResultadoCalculo] = useState<any | null>();

  const onSubmitHandler = useCallback(
    (data: SimuladorJurosRotativoFormFields) => {
      dispatch(simularJurosRotativo({ data }))
        .then(({ payload: { data } }: ApiSingleElementResponse) => {
          const dadosCalculados = data;

          setResultadoCalculo(dadosCalculados);

          return dadosCalculados;
        })
        .catch((erro: Error) => erro);
    },
    [dispatch]
  );

  const onCancelHandler = () => {
    reset();
    setResultadoCalculo(null);
  };

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.SIMULACAO_JUROS_ROTATIVO}>
      <DetailCard>
        <div className="mb-5">
          <DetailTitle renderBackButton={false}>Simulador de juros rotativo</DetailTitle>
        </div>

        <Form form={form} onSubmit={onSubmitHandler}>
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-4">
              <FormControl
                control={control}
                name="intervaloDias"
                label="Intervalo de dias"
                rules={{ required: true }}
              />
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <FormControl
                control={control}
                name="taxaJuros"
                label="Taxa de juros"
                rules={{ required: true, min: 0 }}
                mask={MasksFormControlEnum.PERCENT}
              />
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <FormControl
                control={control}
                name="valorBase"
                label="Valor base"
                rules={{ required: true, min: 0 }}
                mask={MasksFormControlEnum.BRL}
              />
            </div>

            <div className="col-12 d-flex">
              <BSButton className="mx-1" variant="light" onClick={onCancelHandler}>
                Limpar
              </BSButton>
              <BSButton className="mx-1 px-4" variant="primary" type="submit">
                Simular
              </BSButton>
            </div>
          </div>
        </Form>

        {resultadoCalculo && (
          <>
            <div className="mb-4 mt-5">
              <DetailSubTitle>Resultado</DetailSubTitle>
            </div>

            <div>
              <BSCard>
                <BSCard.Body>
                  <DetailElement
                    descricao="IOF"
                    valor={resultadoCalculo?.valorIof ? formatBRL(resultadoCalculo.valorIof) : '-'}
                  />

                  <DetailElement
                    descricao="IOF adicional"
                    valor={resultadoCalculo?.valorIofAdicional ? formatBRL(resultadoCalculo.valorIofAdicional) : '-'}
                  />

                  <DetailElement
                    descricao="Juros"
                    valor={resultadoCalculo?.valorJuros ? formatBRL(resultadoCalculo.valorJuros) : '-'}
                  />

                  <DetailElement
                    descricao="Total encargos"
                    valor={resultadoCalculo?.valorTotal ? formatBRL(resultadoCalculo.valorTotal) : '-'}
                  />
                </BSCard.Body>
              </BSCard>
            </div>

            <div className="mt-5">
              <BSButton
                onClick={() => {
                  showDialog({
                    component: (
                      <InformarErroCalculoDialog
                        logSimuladorId={resultadoCalculo.logSimuladorId}
                        closeDialog={closeDialog}
                      />
                    ),
                  });
                }}
              >
                Informar erro de cálculo
              </BSButton>
            </div>
          </>
        )}
      </DetailCard>
    </RbacPage>
  );
};

export default SimuladorJurosRotativoPage;

import { useCallback, useEffect, useMemo } from 'react';
import BSBadge from 'react-bootstrap/Badge';
import BSCard from 'react-bootstrap/Card';
import { Link, useNavigate } from 'react-router-dom';

import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import { SmartTable } from '../../components/smart-table/smart-table';
import SmartTableFilters from '../../components/smart-table/smart-table-filters/smart-table-filters';
import {
  SmartTableFilterControlTypesEnum,
  SmartTableFilterProps,
} from '../../components/smart-table/smart-table-filters/table-filter.types';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import Title from '../../components/title';
import {
  ApiMultiElementResponse,
  FormatValueEnum,
  acaoEmbossingLabelMap,
  formatCpfCnpj,
  mapearERemoverElementosNulosERepetidos,
  statusEmbossingLabelColorMap,
  statusEmbossingLabelMap,
} from '../../helpers';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import { loadContasCartao, selectObjectTodasContasCartao } from '../contas-cartao/contas-cartao.redux';
import {
  loadTiposBloqueiosCartao,
  selectTiposBloqueioCartaoByFilters,
} from '../tipos-bloqueio-cartao/tipos-bloqueio-cartao.redux';
import {
  loadEmbossingCartoes,
  selectEmbossingCartoeLoadingStateByFilters,
  selectEmbossingCartoesByFilters,
  selectTotalEmbossingCartoesByFilters,
} from './embossing-cartoes.redux';

const smartColumns = ({
  contasCartao,
  tiposBloqueioCartaoIdsEnum,
  statusEmbossingLabelMap,
}: {
  contasCartao: any;
  statusEmbossingLabelMap: any;
  tiposBloqueioCartaoIdsEnum: any[];
}): SmartTableColumnProps[] => [
  {
    label: 'Conta cartão',
    attribute: 'contaCartao',
    format: (contaCartao: any) => {
      return contasCartao[contaCartao]?.numero;
    },
    className: 'text-center',
  },
  { label: 'Número', attribute: 'numeroTruncado', format: FormatValueEnum.CARTAO },
  {
    label: 'Vencimento',
    attribute: 'dataVencimento',
    sortable: true,
    className: 'text-center',
    format: FormatValueEnum.DATA,
  },
  {
    label: 'CPF/CNPJ',
    attribute: 'portadorCpf',
    className: 'text-center text-nowrap',
    sortable: true,
    format: (portadorCpf: any) => formatCpfCnpj(portadorCpf),
  },
  {
    label: 'Bloqueio',
    attribute: 'tipoBloqueio',
    sortable: true,
    className: 'text-center',
    format: (tipoBloqueio: any) => {
      if (!tipoBloqueio) {
        return '-';
      }
      const bloqueio = tiposBloqueioCartaoIdsEnum[tipoBloqueio];

      return bloqueio ? (
        <Link onClick={(e: React.MouseEvent) => e.stopPropagation()} to={`/tipos-bloqueio-cartao/${tipoBloqueio}`}>
          {`${bloqueio}`}
        </Link>
      ) : (
        <></>
      );
    },
  },
  {
    label: 'Data embossing',
    attribute: 'dataEmbossing',
    sortable: true,
    className: 'text-center',
    format: FormatValueEnum.DATA,
  },
  {
    label: 'Status embossing',
    attribute: 'statusEmbossing',
    sortable: true,
    className: 'text-center',
    format: (status: string) => {
      return (
        <h5 className="m-0">
          <BSBadge pill bg={statusEmbossingLabelColorMap[status as keyof typeof statusEmbossingLabelColorMap]}>
            {statusEmbossingLabelMap[status]}
          </BSBadge>
        </h5>
      );
    },
  },
  {
    label: 'Ação embossing',
    attribute: 'acaoEmbossing',
    sortable: true,
    className: 'text-center',
    format: FormatValueEnum.ENUM,
    map: acaoEmbossingLabelMap,
  },
];

const smartFilters = (): SmartTableFilterProps[] => [
  {
    label: 'Número conta cartão',
    attribute: 'contaCartao',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
  },
  {
    label: 'Final do cartão',
    attribute: 'quatroUltimosDigitos',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
  },

  {
    label: 'Status embossing',
    attribute: 'statusEmbossing',
    controlType: SmartTableFilterControlTypesEnum.SELECT,
    map: statusEmbossingLabelMap,
  },

  {
    label: 'Ação embossing',
    attribute: 'acaoEmbossing',
    controlType: SmartTableFilterControlTypesEnum.SELECT,
    map: acaoEmbossingLabelMap,
  },
  { label: 'Data do embossing', attribute: 'dataEmbossing', controlType: SmartTableFilterControlTypesEnum.DATE_RANGE },
];

const EmbossingCartoesPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const { query, maxItemsQuery } = useQuerystring();
  const navigate = useNavigate();

  if (!query.sort) {
    query.sort = '-dataEmbossing';
  }

  const filters = useMemo(() => ({ ...query }), [query]);
  const total = useSelector((state) => selectTotalEmbossingCartoesByFilters(state, filters));
  const cartoes = useSelector((state) => selectEmbossingCartoesByFilters(state, filters));
  const loadingState = useSelector((state) => selectEmbossingCartoeLoadingStateByFilters(state, filters));
  const contasCartao = useSelector((state) => selectObjectTodasContasCartao(state));
  const tiposBloqueioCartao = useSelector((state) => selectTiposBloqueioCartaoByFilters(state, maxItemsQuery));

  const tiposBloqueioCartaoIdsEnum = (tiposBloqueioCartao || []).reduce(
    (obj: any, tipoBloqueioCartao: any) => ({
      ...obj,
      [tipoBloqueioCartao._id]: tipoBloqueioCartao.descricao,
    }),
    {}
  );

  const loadEntidadesComplementares = useCallback(
    (data: any[]) => {
      const contasCartaoIds = mapearERemoverElementosNulosERepetidos(data, 'contaCartao');

      dispatch(loadContasCartao({ query: { _id: { in: contasCartaoIds }, ...maxItemsQuery } })).catch(
        (error: Error) => error
      );
    },
    [dispatch, maxItemsQuery]
  );

  const loadItems = useCallback(() => {
    dispatch(loadEmbossingCartoes({ query }))
      .then(({ payload: { data } }: ApiMultiElementResponse) => loadEntidadesComplementares(data))
      .catch((error: Error) => error);
  }, [dispatch, loadEntidadesComplementares, query]);

  useEffect(() => {
    dispatch(loadTiposBloqueiosCartao({ query: maxItemsQuery })).catch((error: Error) => error);
  }, [dispatch, maxItemsQuery]);

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_EMBOSSING_CARTAO}>
      <div className="d-flex flex-column h-100">
        <Title>Embossing de cartões</Title>

        <div className="row">
          <div className="col-xxl-2 col-xl-3 ">
            <SmartTableFilters filters={smartFilters()} />
          </div>

          <div className="col-xxl-10 col-xl-9 col-lg-8 col-md-12 mb-4">
            <BSCard>
              <BSCard.Body>
                <SmartTable
                  emptyMessage="Nenhum cartão encontrado"
                  errorMessage="Erro na listagem de cartões"
                  usePagination={true}
                  loadItems={loadItems}
                  columns={smartColumns({ contasCartao, tiposBloqueioCartaoIdsEnum, statusEmbossingLabelMap })}
                  items={cartoes}
                  loadingState={loadingState}
                  size={total}
                  onRowClick={(doc) => navigate(`/contas-cartao/${doc.contaCartao}/cartoes/${doc._id}`)}
                />
              </BSCard.Body>
            </BSCard>
          </div>
        </div>
      </div>
    </RbacPage>
  );
};

export default EmbossingCartoesPage;

import { reducerFactory, actionFactory, selectorFactory } from '../../store';

const context = 'historicosNoDiaCliente';

const { loadOne } = actionFactory(context, {
  buildUrlLoadOne: ({ historicoClienteId }) => `/api/historicos-cliente/${historicoClienteId}/dia`,
});

const { selectOneById, selectLoadingStateByFilters } = selectorFactory({
  context,
});

export const loadHistoricoNoDiaCliente = loadOne;

export const selectHistoricoNoDiaClienteById = selectOneById;
export const selectHistoricoNoDiaClienteLoadingStateByFilters = selectLoadingStateByFilters;

export const historicosNoDiaCliente = reducerFactory({ context });

import BSButton from 'react-bootstrap/Button';
import BSModal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';

import { Form } from '../../components/form/form';
import { FormCheck } from '../../components/form/form-check';
import { FormControl, MasksFormControlEnum } from '../../components/form/form-control';
import { ClassTypesEnum, sexoLabelMap } from '../../helpers';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch } from '../../store/hooks-redux';
import { solicitarCartaoAdicionalContaCartao } from '../cartoes/cartoes.redux';

type SolicitarCartaoAdicionalFormFields = {
  portadorCpf: string;
  portadorSexo: string;
  portadorDataNascimento: string;
  portadorNome: string;
};

type SolicitarCartaoAdicionalDialogProps = {
  contaCartaoId: any;
  closeDialog: () => void;
  reload: () => void;
};

const SolicitarCartaoAdicionalDialog: React.FC<SolicitarCartaoAdicionalDialogProps> = ({
  contaCartaoId,
  closeDialog,
  reload,
}) => {
  const dispatch = useAppDispatch();

  const { showToast } = useToasts();

  const form = useForm<SolicitarCartaoAdicionalFormFields>();
  const { control } = form;

  const onSubmitSolicitarCartaoAdicional = (data: SolicitarCartaoAdicionalFormFields) => {
    return dispatch(solicitarCartaoAdicionalContaCartao({ params: { contaCartaoId }, data }))
      .then(() => {
        showToast({
          message: 'Cartão adicional solicitado com sucesso!',
          type: ClassTypesEnum.SUCCESS,
        });

        reload();

        return closeDialog();
      })
      .catch((error: Error) => {
        showToast({
          message: error.message,
          type: ClassTypesEnum.DANGER,
        });
      });
  };

  return (
    <>
      <BSModal.Header closeButton>
        <BSModal.Title>Solicitar cartão adicional</BSModal.Title>
      </BSModal.Header>

      <BSModal.Body>
        <Form id="form" form={form} onSubmit={onSubmitSolicitarCartaoAdicional} submitPristine>
          <FormControl
            control={control}
            className="mb-3"
            name="portadorCpf"
            label="CPF do portador"
            rules={{ required: true }}
            mask={MasksFormControlEnum.CPF_CNPJ}
          />

          <FormControl
            control={control}
            className="mb-3"
            name="portadorNome"
            label="Nome do portador"
            rules={{ required: true }}
          />

          <FormControl
            control={control}
            className="mb-3"
            name="portadorDataNascimento"
            label="Data de nascimento do portador"
            rules={{ required: true }}
            type="date"
          />

          <div className="d-flex mb-3">
            <div className="me-3">
              <span>Sexo</span> <span className="text-danger">*</span>
            </div>

            {Object.entries(sexoLabelMap).map(([key, value]) => (
              <FormCheck
                key={key}
                control={control}
                className="me-3"
                name="portadorSexo"
                label={value}
                value={key}
                rules={{ required: true }}
                type="radio"
              />
            ))}
          </div>
        </Form>
      </BSModal.Body>

      <BSModal.Footer>
        <BSButton variant="light" onClick={closeDialog}>
          Fechar
        </BSButton>
        <BSButton variant="primary" type="submit" form="form">
          Solicitar cartão adicional
        </BSButton>
      </BSModal.Footer>
    </>
  );
};

export default SolicitarCartaoAdicionalDialog;

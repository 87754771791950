import { useCallback } from 'react';
import BSButton from 'react-bootstrap/Button';
import BSModal from 'react-bootstrap/Modal';
import BSTab from 'react-bootstrap/Tab';
import BSTabs from 'react-bootstrap/Tabs';

import { useForm } from 'react-hook-form';
import { Form } from '../../components/form/form';
import { FormControl } from '../../components/form/form-control';
import { FormSelect } from '../../components/form/form-select';
import { ClassTypesEnum, moedaMap, subtipoCnabLabelMap, tipoCnabLabelMap } from '../../helpers';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch } from '../../store/hooks-redux';
import { createParametroGeracaoBoleto } from './parametros-geracao-boleto.redux';

type CadastrarParametroGeracaoBoletoFields = {
  tipoCnab: string;
  subtipoCnab: string;
  localPagamento: string;
  cip: string;
  pagamentoParcial: string;
  especieDocumento: string;
  codigoBaixaDevolucao: string;
  moeda: string;
  carteira: string;
  aceite: string;
  codigoEmpresa: string;
  instrucoes: string;
  agenciaBeneficiario: string;
  digitoAgenciaBeneficiario: string;
  contaBeneficiario: string;
  digitoContaBeneficiario: string;
  descricaoBeneficiario: string;
  codigoBancoCedente: string;
  nomeBancoCedente: string;
  convenioCedente: string;
  cnpj: string;
  endereco: string;
  cidade: string;
};

type CadastrarParametroGeracaoBoletoProps = {
  closeDialog: () => void;
  reload: () => void;
};

const CadastrarParametroGeracaoBoletoDialog: React.FC<CadastrarParametroGeracaoBoletoProps> = ({
  closeDialog,
  reload,
}) => {
  const dispatch = useAppDispatch();
  const { showToast } = useToasts();

  const form = useForm<CadastrarParametroGeracaoBoletoFields>();
  const { control } = form;

  const onSubmitHandler = useCallback(
    (data: CadastrarParametroGeracaoBoletoFields) => {
      dispatch(createParametroGeracaoBoleto({ data }))
        .then(() => {
          closeDialog();
          showToast({ message: 'Parâmetro geração boleto cadastrado com sucesso', type: ClassTypesEnum.SUCCESS });
          reload();
        })
        .catch((error: Error) => {
          showToast({ message: error.message, type: ClassTypesEnum.DANGER });
        });
    },
    [closeDialog, dispatch, reload, showToast]
  );

  return (
    <>
      <BSModal.Header closeButton>
        <BSModal.Title>Novo parâmetro de geração de boleto</BSModal.Title>
      </BSModal.Header>
      <BSModal.Body>
        <Form id="form" form={form} onSubmit={onSubmitHandler}>
          <BSTabs defaultActiveKey="dadosGerais" fill>
            <BSTab eventKey="dadosGerais" title="Dados gerais">
              <div className="row">
                <FormSelect
                  control={control}
                  className="col-xl-4 col-md-6 col-sm-12 mb-3"
                  label="Tipo CNAB"
                  name="tipoCnab"
                  placeholder="Selecione o tipo CNAB"
                  options={Object.entries(tipoCnabLabelMap).map((elem) => ({
                    label: elem[1],
                    value: elem[0],
                  }))}
                  rules={{ required: true }}
                />

                <FormSelect
                  control={control}
                  className="col-xl-4 col-md-6 col-sm-12 mb-3"
                  label="Subtipo CNAB"
                  name="subtipoCnab"
                  placeholder="Selecione o subtipo CNAB"
                  options={Object.entries(subtipoCnabLabelMap).map((elem) => ({
                    label: elem[1],
                    value: elem[0],
                  }))}
                  rules={{ required: true }}
                />

                <FormControl
                  control={control}
                  className="col-xl-4 col-md-6 col-sm-12 mb-3"
                  label="Local do pagamento"
                  name="localPagamento"
                  type="text"
                  rules={{ required: true }}
                />

                <FormControl
                  control={control}
                  className="col-xl-4 col-md-6 col-sm-12 mb-3"
                  label="CIP"
                  name="cip"
                  type="text"
                  rules={{ required: true }}
                />

                <FormControl
                  control={control}
                  className="col-xl-4 col-md-6 col-sm-12 mb-3"
                  label="Máximo de pagamentos para um boleto"
                  name="pagamentoParcial"
                  type="text"
                  rules={{ required: true }}
                />

                <FormControl
                  control={control}
                  className="col-xl-4 col-md-6 col-sm-12 mb-3"
                  label="Espécie do documento"
                  name="especieDocumento"
                  type="text"
                  rules={{ required: true }}
                />

                <FormControl
                  control={control}
                  className="col-xl-4 col-md-6 col-sm-12 mb-3"
                  label="Código para baixa/devolução"
                  name="codigoBaixaDevolucao"
                  type="text"
                  rules={{ required: true }}
                />

                <FormSelect
                  control={control}
                  className="col-xl-4 col-md-6 col-sm-12 mb-3"
                  label="Moeda"
                  name="moeda"
                  placeholder="Selecione a moeda"
                  options={Object.values(moedaMap)?.map((moeda) => ({
                    key: moeda.value,
                    value: moeda.value,
                    label: moeda.label,
                  }))}
                  rules={{ required: true }}
                />

                <FormControl
                  control={control}
                  className="col-xl-4 col-md-6 col-sm-12 mb-3"
                  label="Carteira"
                  name="carteira"
                  type="text"
                  rules={{ required: true }}
                />

                <FormControl
                  control={control}
                  className="col-xl-4 col-md-6 col-sm-12 mb-3"
                  label="Aceite"
                  name="aceite"
                  type="text"
                  rules={{ required: true }}
                />

                <FormControl
                  control={control}
                  className="col-xl-4 col-md-6 col-sm-12 mb-3"
                  label="Código da empresa"
                  name="codigoEmpresa"
                  type="text"
                  rules={{ required: true }}
                />

                <FormControl
                  control={control}
                  className="col-12 mb-3"
                  label="Instruções"
                  name="instrucoes"
                  type="text"
                  as="textarea"
                  rules={{ required: true }}
                  rows={3}
                />
              </div>
            </BSTab>

            <BSTab eventKey="beneficiario" title="Beneficiário">
              <div className="row">
                <FormControl
                  control={control}
                  className="col-xl-4 col-md-6 col-sm-12 mb-3"
                  label="Agência"
                  name="agenciaBeneficiario"
                  type="text"
                  rules={{ required: true }}
                />

                <FormControl
                  control={control}
                  className="col-xl-4 col-md-6 col-sm-12 mb-3"
                  label="Dígito agência"
                  name="digitoAgenciaBeneficiario"
                  type="text"
                  rules={{ required: true }}
                />

                <FormControl
                  control={control}
                  className="col-xl-4 col-md-6 col-sm-12 mb-3"
                  label="Conta"
                  name="contaBeneficiario"
                  type="text"
                  rules={{ required: true }}
                />

                <FormControl
                  control={control}
                  className="col-xl-4 col-md-6 col-sm-12 mb-3"
                  label="Dígito da conta"
                  name="digitoContaBeneficiario"
                  type="text"
                  rules={{ required: true }}
                />

                <FormControl
                  control={control}
                  className="col-xl-8 col-md-12 mb-3"
                  label="Descrição"
                  name="descricaoBeneficiario"
                  type="text"
                  rules={{ required: true }}
                />
              </div>
            </BSTab>

            <BSTab eventKey="cedente" title="Cedente">
              <div className="row">
                <FormControl
                  control={control}
                  className="col-xl-4 col-md-6 col-sm-12 mb-3"
                  label="Código do banco"
                  name="codigoBancoCedente"
                  type="text"
                  rules={{ required: true }}
                />

                <FormControl
                  control={control}
                  className="col-xl-4 col-md-6 col-sm-12 mb-3"
                  label="Nome do banco"
                  name="nomeBancoCedente"
                  type="text"
                  rules={{ required: true }}
                />

                <FormControl
                  control={control}
                  className="col-xl-4 col-md-6 col-sm-12 mb-3"
                  label="Número do convênio com o emissor"
                  name="convenioCedente"
                  type="text"
                  rules={{ required: true }}
                />

                <FormControl
                  control={control}
                  className="col-xl-4 col-md-6 col-sm-12 mb-3"
                  label="CNPJ"
                  name="cnpj"
                  type="text"
                  rules={{ required: true }}
                />

                <FormControl
                  control={control}
                  className="col-xl-4 col-md-6 col-sm-12 mb-3"
                  label="Endereço"
                  name="endereco"
                  type="text"
                  rules={{ required: true }}
                />

                <FormControl
                  control={control}
                  className="col-xl-4 col-md-6 col-sm-12 mb-3"
                  label="Cidade"
                  name="cidade"
                  type="text"
                  rules={{ required: true }}
                />
              </div>
            </BSTab>
          </BSTabs>
        </Form>
      </BSModal.Body>
      <BSModal.Footer>
        <BSButton variant="light" onClick={closeDialog}>
          Fechar
        </BSButton>
        <BSButton variant="primary" type="submit" form="form">
          Cadastrar
        </BSButton>
      </BSModal.Footer>
    </>
  );
};

export default CadastrarParametroGeracaoBoletoDialog;

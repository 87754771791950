export const calendariosDeCorteRename = {
  ano: 'Ano',
  mes: 'Mês',
  dia: 'Dia',
  produto: 'Produto',
  dataCorte: 'Data de corte',
  dataPrevia: 'Data de prévia',
  dataVencimento: 'Data de vencimento',
};

export const cartoesRename = {
  acaoEmbossing: 'Ação embossing',
  contaCartao: 'Conta cartão',
  contadorTransacoesChip: 'Contador transações chip',
  conteudoQuartaLinhaEmbossing: 'Nota embossing',
  credito: 'Crédito',
  dataBloqueio: 'Data de bloqueio',
  dataCriacao: 'Data de emissão do cartão',
  dataEmbossing: 'Data embossing',
  dataVencimento: 'Data de vencimento',
  debito: 'Débito',
  ehSegundaVia: 'Segunda via do cartão',
  foiComunicadoBandeira: 'Comunicado ao banco de dados do ABU/Mastercard',
  foiEnviadoCartaSenha: 'Senha atual foi enviada na carta de senhas',
  formaEnvio: 'Forma de envio do cartão',
  localEntrega: 'Local entrega',
  modeloPlastico: 'Modelo plástico',
  nomeEmbossing: 'Nome embossing',
  notaEmbossing: 'Nota embossing',
  numeroNoEmissor: 'Número no emissor',
  numeroTruncado: 'Número truncado',
  panToken: 'PAN token',
  permiteCompraContactless: 'Permite compra contactless',
  permiteCompraInternacional: 'Permite compra internacional',
  permiteCompraOnlineInternacional: 'Permite compra online internacional',
  permiteCompraOnlineNacional: 'Permite compra online nacional',
  permiteDoacaoArredondamentoCompra: 'Permite doação por arredondamento da compra',
  permiteUsarOverlimit: 'Permite overlimit',
  portadorCpf: 'CPF do portador',
  portadorDataNascimento: 'Data de nascimento do portador',
  portadorSexo: 'Sexo do portador',
  quantidadeCVCInvalido: 'Quantidade de tentativas com CVC inválido',
  quantidadeSenhaInvalida: 'Quantidade de tentativas com senha inválida',
  quatroUltimosDigitos: 'Quatro últimos dígitos',
  statusEmbossing: 'Status do embossing do cartao',
  substituidoPor: 'Substituido por',
  tipoArredondamentoDoacaoCompra: 'Tipo arredondamento',
  tipoBloqueio: 'Tipo bloqueio',
  tipoCartao: 'Tipo cartão',
  titularidade: 'Titularidade',
};

export const clienteRename = {
  autorizacaoConsulta: 'Autorização consulta',
  bairro: 'Bairro',
  cep: 'CEP',
  cidade: 'Cidade',
  complemento: 'Complemento',
  conglomeradoEconomico: 'Conglomerado econômico',
  codigo: 'Código único',
  codigoArea: 'Código de Área',
  cpfCnpj: 'CPF ou CNPJ',
  dataHora: 'Data em que o aceite foi realizado',
  dataNascimento: 'Data de nascimento',
  ehDeficienteVisual: 'Deficiente visual',
  email: 'Email principal',
  faturamentoAnual: 'Faturamento anual',
  inicioPendencia: 'Data em que o contrato foi encaminhado para o cliente',
  logradouro: 'Logradouro',
  nome: 'Nome completo',
  nomeMae: 'Nome completo da mãe',
  numero: 'Número',
  numeroRg: 'Número do RG',
  pendente: 'Pendente',
  porteCliente: 'Porte cliente',
  sexo: 'Sexo',
  tipo: 'Tipo',
  tipoControlePJ: 'Tipo controle PJ',
  uf: 'Unidade Federativa',
  ufRg: 'Estado emissor do RG',
};

export const contaCartaoRename = {
  acaoEnvioFatura: 'Ação de envio da Fatura',
  bloqueioPrioritario: 'Bloqueio prioritário',
  bloqueios: 'Bloqueios',
  cliente: 'Cliente',
  comparaValorMesAnterior: 'Comparação de valor com o mês anterior',
  contaBancaria: 'Conta bancária',
  dataAtivacao: 'Data de confirmação da primeira transação',
  dataCriacao: 'Data de criação da conta',
  dataInclusao: 'Data de inclusão do bloqueio',
  dataInicioAtraso: 'Data de início do atraso',
  dataProposta: 'Data de proposta de abertura da conta',
  diaVencimento: 'Dia de vencimento',
  formaEnvioFatura: 'Forma de envio da fatura',
  funcaoAtiva: 'Função ativa do cartão',
  limiteCredito: 'Limite de crédito',
  limiteCreditoDisponivel: 'Limite de crédito disponível',
  limiteCreditoDisponivelSaque: 'Limite de crédito disponível para saque',
  limiteCreditoSaque: 'Limite de crédito para saque',
  matricula: 'Matrícula',
  numero: 'Número',
  numeroNoEmissor: 'Emissor',
  origemComercial: 'Origem comercial',
  parceria: 'Parceria',
  permiteDoacaoArredondamentoFatura: 'Permite arredondar o valor doado',
  permiteDoacaoRecorrenteMensal: 'Permite doação recorrente mensal',
  planoCredito: 'Plano de crédito',
  produto: 'Produto',
  status: 'Status da conta',
  tipoArredondamentoDoacaoFatura: 'Tipo do arredondamento para doação',
  tipoBloqueio: 'Tipo do bloqueio',
  valorDoacaoRecorrenteMensal: 'Valor da doação recorrente mensal',
  valorMargemConsignada: 'Valor de margem consignada',
  valorTotalDoado: 'Valor total doado',
};

export const faturasRename = {
  boleto: 'Boleto',
  cetRotativoAnual: 'Valor do CET rotativo anual',
  cetRotativoPeriodo: 'CET rotativo período',
  cetSaqueAnual: 'CET saque anual',
  cetSaquePeriodo: 'CET saque período',
  ciclosRotativo: 'Ciclos rotativo',
  comparaValorMesAnterior: 'Compara valor mês anterior',
  contaCartao: 'Conta Cartao',
  dataCorte: 'Data de corte',
  dataInicioPeriodo: 'Data de inicio do periodo',
  dataPrevia: 'Data de previa',
  dataVencimento: 'Data de vencimento',
  dataVencimentoPeriodoAnterior: 'Data de vencimento da fatura anterior',
  deveSerEnviada: 'Deve ser enviada',
  estaFechada: 'Esta fechada',
  impedirBloqueioAtrasoNoPeriodo: 'Impede bloqueio de atraso no periodo',
  valorCompras: 'Valor de compras',
  valorDebitosInternacionalDolar: 'Valor subtotal débitos internacionais dólar',
  valorDescontoEmFolha: 'Valor para desconto em folha',
  valorEmDisputa: 'Valor em disputa',
  valorEstoqueParceladoEmissor: 'Valor estoque parcelado emissor',
  valorEstoqueParceladoLojista: 'Valor estoque parcelado lojista',
  valorPagamentoComplementar: 'Valor para pagamento complementar',
  valorPagamentoMinimo: 'Valor do pagamento minimo',
  valorParaComparacao: 'Valor para comparação com mês anterior',
  valorParcelasNaoFinanciaveis: 'Valor das parcelas não financiáveis',
  valorProjecaoRotativoProximoPeriodo: 'Valor do rotativo para o próximo período',
  valorSubtotalCreditos: 'Valor subtotal creditos',
  valorSubtotalDebitos: 'Valor subtotal debitos',
  valorSubtotalDebitosInternacional: 'Valor subtotal débitos internacionais',
  valorSubtotalEncargos: 'Valor de subtotal de juros',
  valorTotal: 'Valor total da fatura',
  valorTotalAnterior: 'Valor total da fatura anterior',
};

export const interacaoSolicitacaoClienteRename = {
  codigo: 'Código',
  dataHoraInteracao: 'Data da interação',
  descricao: 'Descrição',
  natureza: 'Natureza',
  numeroDoProtocolo: 'Número do protocolo',
  operacoes: 'Operações',
  origemAlteracao: 'Origem da alteração',
  solicitacao: 'Solicitação',
  tipoStatusSolicitacaoCliente: 'Tipo status da solicitação do cliente',
  tipoTransacao: 'Tipo da transação',
  transacaoAjusteFinanceiro: 'Transação de ajuste financeiro',
  usuarioSolicitacao: 'Usuário interação',
  valor: 'Valor',
};

export const lancamentosRename = {
  contaCartao: 'Conta cartao',
  contaContabil: 'Conta contabil',
  contaContraPartida: 'Conta contra partida',
  dataClassificacao: 'Data de classificacao',
  dataLancamento: 'Data de lancamento',
  descricao: 'Descricao',
  natureza: 'Natureza',
  roteiroContabil: 'Roteiro contabil',
  transacao: 'Transação',
  valor: 'Valor',
};

export const parametrosGeracaoBoletoRename = {
  aceite: 'Aceite',
  agenciaBeneficiario: 'Agência do beneficiário',
  carteira: 'Carteira',
  cip: 'Cip',
  cidade: 'Cidade',
  cnpj: 'CNPJ',
  codigoBaixaDevolucao: 'Código para baixa/devolução',
  codigoBancoCedente: 'Código do banco cedente',
  codigoEmpresa: 'Código da empresa',
  contaBeneficiario: 'Conta do beneficiário',
  convenioCedente: 'Convênio cedente',
  descricaoBeneficiario: 'Descrição do beneficiário',
  digitoAgenciaBeneficiario: 'Dígito da agência do beneficiário',
  digitoContaBeneficiario: 'Digito da conta do beneficiário',
  especieDocumento: 'Tipo do documento',
  endereco: 'Endereço',
  instrucoes: 'Instruções',
  localPagamento: 'Local de pagamento',
  moeda: 'Moeda',
  nomeBancoCedente: 'Nome do banco cedente',
  pagamentoParcial: 'Número máximo de pagamentos para um boleto',
  subtipoCnab: 'Subtipo Cnab',
  tipoCnab: 'Tipo Cnab',
};

export const produtosRename = {
  bin: 'bin',
  ciclosRotativoParaParcelamentoAutomaticoFatura: 'Ciclos rotativo para parcelamento automático da fatura',
  cobrarTarifaNovaViaCartao: 'Cobrar tarifa de nova via de cartão',
  cobrarTarifaOverlimit: 'Cobrar tarifa de overlimit',
  cobrarTarifaSaqueCredito: 'Cobrar tarifa de saque crédito',
  codigo: 'Código',
  diaCorte: 'Dia de corte',
  diaPrevia: 'Dia de previa',
  diasParaBloqueioCreli: 'Dias para bloqueio Creli',
  diasParaBloqueioPreCreli: 'Dias para bloqueio pré Creli',
  diasParaBloqueioPrejuizo: 'Dias para bloqueio prejuizo',
  diasToleranciaParaBloqueioAtraso: 'Dias de tolerância para bloqueio',
  diaVencimento: 'Dia de vencimento',
  distanciaCorteVencimentoEmDias:
    'Dias entre a data de corte e de vencimento nos calendários de corte gerados automaticamente',
  exclusivoClientesPreAprovados: 'Exclusivo clientes pré aprovados',
  faixasEtarias: 'Faixas etárias',
  geracaoAutomaticaCalendariosCorte: 'Geração automática de calendários de corte',
  iofParcelamentoFaturaFinanciavel: 'IOF parcelamento fatura financiável',
  limiteCartoesVirtuaisPorConta: 'Limite cartões virtuais por conta',
  limiteCreditoMaximoPorConta: 'Limite crédito máximo por conta',
  limiteCreditoMinimoPorConta: 'Limite crédito mínimo por conta',
  limiteCreditoPadrao: 'Limite crédito padrão',
  maximoParcelamentosSimultaneos: 'Máximo de parcelamentos simultâneos',
  modeloCalculoPagamentoMinimo: 'Modelo de cálculo para pagamento mínimo',
  nome: 'Nome',
  numeroMaximoFaturasAtrasoOverlimit: 'Número máximo de faturas atrasadas para permitir overlimit',
  opcoesParcelamentoFatura: 'Opções parcelamento fatura',
  origemRecursos: 'Origem recursos',
  parametrosGeracaoBoleto: 'Parâmetros de geração de boleto',
  parcelas: 'Parcelas',
  percentualIndexador: 'Percentual indexador',
  percentualLimiteCreditoSaque: 'Percentual para calcular o limite de crédito',
  percentualPagamentoMinimo: 'Percentual de pagamento mínimo padrão',
  percentualPagamentoMinimoBloqueioDefinitivo: 'Percentual pagamento mínimo para cartão com bloqueio definitivo',
  percentualToleranciaSaldoDevido: 'Percentual de tolerancia para o saldo devido',
  percentualUtilizacaoAcimaLimite: 'Percentual de excesso de limite permitido',
  permiteDoacaoArredondamentoCompra: 'Permite doação por arredondamento de compra',
  permiteDoacaoArredondamentoFatura: 'Permite doação por arredondamento de fatura',
  permiteDoacaoEspontanea: 'Permite doação espontânea',
  permiteDoacaoRecorrenteMensal: 'Permite doação recorrente mensal',
  permiteOverlimit: 'Permite overlimit',
  permiteParcelamentoAutomaticoFatura: 'Permite parcelamento automático de fatura',
  permiteSaqueAutorizado: 'Permite saque autorizado',
  permiteSaqueBandeira: 'Permite transacao de saque na bandeira',
  permiteSaqueComplementar: 'Permite saque complementar',
  prazoMaximoParcelamentoFatura: 'Prazo máximo para parcelamento de fatura',
  prazoValidadeCartaoEmAnos: 'Validade do cartão em anos',
  saldoMinimoParaCobranca: 'Saldo mínimo para cobrança',
  subOrigem: 'Sub origem',
  subTaxa: 'Sub taxa',
  tamanhoNumeroCartao: 'Tamanho do número do cartão a ser gerado',
  taxaEfetivaAnual: 'Taxa efetiva anual',
  taxaJurosCompraParceladaEmissor: 'Taxa de juros do emissor para compra parcelada',
  taxaJurosRotativo: 'Taxa de juros do valor de rotativo',
  taxaJurosSaque: 'Taxa de juros do valor de juros de um saque',
  taxaMensalJuros: 'Taxa de juros mensal',
  taxaReferencial: 'Taxa referencial',
  tipoCredito: 'Tipo crédito',
  tipoProcessamento: 'Tipo de processamento',
  utilizaPagamentoComplementar: 'Utiliza pagamento complementar',
  valorMinimoDescontoEmFolha: 'Valor mínimo de desconto em folha',
  valorMinimoEnvioFatura: 'Valor mínimo para envio de fatura',
  valorMinimoPagamentoMinimo: 'Valor mínimo para pagamento mínimo',
  valorMinimoParcelaParcelamentoFatura: 'Valor mínimo da parcela',
  valorToleranciaSaldoDevido: 'Valor de tolerância para o saldo devido',
  valorTotalDoacoes: 'Valor total de doações',
  vencimentosDisponiveis: 'Vencimentos disponíveis',
};

export const roteirosContabeisRename = {
  contaContabilCredito: 'Conta contábil crédito',
  contaContabilDebito: 'Conta contábil débito',
  descricao: 'Descrição',
  eventoContabil: 'Evento contábil',
  habilitado: 'Habilitado',
  labelTipoTransacao: 'Label tipo transação',
  statusConta: 'Status da conta',
  tipoRoteiro: 'Tipo roteiro',
  tipoTransacao: 'Tipo da transação',
};

export const solicitacaoClienteRename = {
  canalSolicitacao: 'Canal solicitação',
  cartao: 'Cartão',
  contaCartao: 'Conta cartão',
  dataAbertura: 'Data de abertura',
  dataFinalResolucao: 'Data final para resolução',
  dataResolucao: 'Data de resolução',
  descricao: 'Descrição',
  numeroDoProtocolo: 'Número do protocolo',
  solicitacao: 'Solicitação',
  tipoSolicitacao: 'Tipo solicitação',
  tipoStatus: 'Tipo status',
  usuarioSolicitacao: 'Usuário solicitação',
};

export const tarifasRename = {
  codigo: 'Código',
  descricao: 'Descrição',
  produto: 'Produto',
  quantidadeParcelas: 'Quantidade de parcelas',
  recorrencia: 'Recorrência',
  tipoTarifa: 'Tipos da tarifa',
  tipoTransacao: 'Tipo transação',
  valor: 'Valor',
};

export const tiposDeBloqueioDeCartaoRename = {
  codigo: 'Código',
  descricao: 'Descrição',
  indicaCartaoNaoEmitidoPescaben: 'Cartao considerado emitido para PESBACEN',
  operacao: 'Operacao',
  permiteConsultarSaldo: 'Permite consultar saldo',
  permiteEmissaoSegundaVia: 'Permite emissao de segunda via',
  respostaAutorizador: 'Resposta do autorizador',
  bloqueioDefinitivo: 'Bloqueio definitivo',
};

export const tiposDeBloqueioDeContaRename = {
  acionaSistemaCobranca: 'Aciona sistema de cobrança',
  antecipaDivida: 'Saldo da divida deve ser antecipado',
  bloqueioDefinitivo: 'Bloqueio definitivo',
  cancelaCartao: 'Cartao pode ser cancelado',
  cobraEncargos: 'Cobra encargos',
  cobraJurosPorAtraso: 'Cobra juros por atraso',
  cobraMultaPorAtraso: 'Cobra multa por atraso',
  codigo: 'Código',
  descricao: 'Descrição',
  operacao: 'Operação',
  permiteAlterarVencimento: 'Permite alterar dia do vencimento da fatura',
  permiteEmissaoSegundaVia: 'Permite emissao de segunda via',
  prioridade: 'Prioridade',
  respostaAutorizador: 'Resposta do autorizador',
  tipo: 'Tipo',
};

export const tiposDeTransacaoRename = {
  apareceNaFatura: 'Aparece na fatura',
  codigo: 'Código',
  descricao: 'Descrição',
  descricaoFatura: 'Descrição da fatura',
  funcao: 'Função',
  operacao: 'Operação',
  parcelaNaoFinanciavel: 'Parcela não financiável',
  prazoEmDiasParaConfirmacao: 'Prazo em dias para confirmação',
  prioridadePagamentoContabil: 'Prioridade pagamento contábil',
  utilizacao: 'Utilização',
};

export const transacoesFuturasRename = {
  atualizacaoLimiteDisponivelPendente: 'Atualização de limite disponivel pendente',
  cancelada: 'Cancelada',
  cartao: 'Cartão',
  classificacaoContabil: 'Classificação Contábil',
  contaCartao: 'Conta cartão',
  dataHoraTransacao: 'Data e hora da transação',
  dataReferencia: 'Data e hora de referência',
  descricao: 'Descrição',
  moedaOrigem: 'Moeda de Origem',
  natureza: 'Natureza',
  nomeEstabelecimento: 'Nome do estabelecimento',
  parcelaAtual: 'Parcela atual',
  pendente: 'Pendente',
  quantidadeParcelas: 'Quantidade de parcelas',
  tipoTransacao: 'Tipo da transação',
  transacaoOrigem: 'Transação de origem',
  valor: 'Valor',
  valorLiquidacao: 'Valor liquidação',
};

export const transacoesRename = {
  ajusteFinanceiro: 'Ajuste financeiro',
  atualizacaoLimiteDisponivelPendente: 'Atualizacao limite disponível pendente',
  cancelada: 'Cancelada',
  cartao: 'Cartão',
  cicloClearing: 'Ciclo clearing',
  classificacaoContabil: 'Classificacao contábil',
  clearing: 'Clearing',
  codigoAutorizacao: 'Código autorização',
  codigoMotivoMensagem: 'Código motivo mensagem',
  codigoPais: 'Código país',
  codigoTipoTaxa: 'Código tipo taxa',
  confirmacao: 'Confirmação',
  contaCartao: 'Conta cartão',
  cotacao: 'Cotação',
  data: 'Data',
  dataConfirmacao: 'Data confirmação',
  dataHoraTransacao: 'Data da transação',
  dataReferencia: 'Data de referência',
  descricao: 'Descrição',
  disputada: 'Disputada',
  fatura: 'Fatura',
  identificadorAutorizacao: 'Identificador da autorização',
  identificadorBandeira: 'Identificador bandeira',
  liquidacao: 'Liquidação',
  mcc: 'MCC',
  moedaOrigem: 'Moeda de origem',
  natureza: 'Natureza',
  nomeEstabelecimento: 'Nome do estabelecimento',
  origemTransacao: 'Origem da transação',
  parcelaAtual: 'Parcela atual',
  pedidoAutorizacao: 'Pedido autorização',
  pendente: 'Pendente',
  quantidadeParcelas: 'Quantidade de parcelas',
  solicitacaoCliente: 'Solicitação cliente',
  taxaJuros: 'Taxa juros',
  tipoTransacao: 'Tipo da transacao',
  transacaoOrigem: 'Transação origem',
  valor: 'Valor',
  valoresTaxa: 'Valores taxa',
  valorJuros: 'Valor juros',
  valorLiquidacao: 'Valor liquidação',
  valorMoedaOrigem: 'Valor moeda origem',
  valorReconciliacao: 'Valor reconciliação',
  valorTaxa: 'Valor taxa',
  valorTotalIof: 'Valor total IOF',
  valorTotalParcelado: 'Valor total parcelado',
};

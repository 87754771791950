import { reducerFactory, actionFactory } from '../../store';

const context = 'simuladorParcelamentoFatura';

const { createOne } = actionFactory(context, {
  buildUrlCreateOne: ({ contaCartaoId }) => `/api/contas-cartao/${contaCartaoId}/simular-parcelamento`,
});

export const simularParcelamentoFatura = createOne;

export const simuladorParcelamentoFatura = reducerFactory({ context });

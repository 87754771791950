import { reducerFactory, actionFactory, selectorFactory } from '../../store';

const context = 'roteirosContabeis';

const { loadMany, loadOne, createOne, updateOne } = actionFactory(context, {
  buildUrlLoadOne: ({ roteiroContabilId }) => `/api/roteiros-contabeis/${roteiroContabilId}`,
  buildUrlLoadMany: () => '/api/roteiros-contabeis',
  buildUrlCreateOne: () => '/api/roteiros-contabeis',
  buildUrlUpdateOne: ({ roteiroContabilId }) => `/api/roteiros-contabeis/${roteiroContabilId}`,
});

const { selectManyByFilters, selectLoadingStateByFilters, selectTotalByFilters, selectOneById } = selectorFactory({
  context,
});

export const saveRoteiroContabil = createOne;

export const loadRoteirosContabeis = loadMany;
export const loadRoteiroContabil = loadOne;
export const updateRoteiroContabil = updateOne;

export const selectRoteirosContabeisByFilters = selectManyByFilters;
export const selectTotalRoteirosContabeisByFilters = selectTotalByFilters;
export const selectRoteiroContabilById = selectOneById;

export const selectLoadingStateByFiltersRoteiroContabil = selectLoadingStateByFilters;

export const roteirosContabeis = reducerFactory({ context });

import { useCallback } from 'react';
import BSButton from 'react-bootstrap/Button';
import BSModal from 'react-bootstrap/Modal';

import { useForm } from 'react-hook-form';
import { Form } from '../../components/form/form';
import { FormCheck } from '../../components/form/form-check';
import { FormControl } from '../../components/form/form-control';
import { ClassTypesEnum } from '../../helpers';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch } from '../../store/hooks-redux';
import { createTipoSolicitacaoCliente } from './tipo-solicitacao-cliente.redux';

type CadastrarTiposSolicitacaoClienteFormFields = {
  codigo: string;
  descricao: string;
  vinculadoACartao: boolean;
  permiteAjusteFinanceiro: boolean;
  resolucaoImediata: boolean;
  prazoParaResolucaoEmDiasUteis: string;
};

type CadastrarTiposSolicitacaoClienteProps = {
  closeDialog: () => void;
  reload: () => void;
};

const CadastrarTiposSolicitacaoClienteDialog: React.FC<CadastrarTiposSolicitacaoClienteProps> = ({
  closeDialog,
  reload,
}) => {
  const dispatch = useAppDispatch();
  const { showToast } = useToasts();

  const form = useForm<CadastrarTiposSolicitacaoClienteFormFields>();
  const { control, watch } = form;

  const ehResolucaoImediata = watch('resolucaoImediata');

  const onSubmitHandler = useCallback(
    (data: CadastrarTiposSolicitacaoClienteFormFields) => {
      dispatch(createTipoSolicitacaoCliente({ data }))
        .then(() => {
          closeDialog();
          showToast({ message: 'Tipo de solicitação do cliente cadastrada com sucesso', type: ClassTypesEnum.SUCCESS });

          reload();
        })
        .catch((error: Error) => {
          showToast({ message: error.message, type: ClassTypesEnum.DANGER });
        });
    },
    [closeDialog, dispatch, reload, showToast]
  );

  return (
    <>
      <BSModal.Header closeButton>
        <BSModal.Title>Novo tipo de solicitação</BSModal.Title>
      </BSModal.Header>

      <BSModal.Body>
        <Form id="form" form={form} onSubmit={onSubmitHandler}>
          <FormControl
            control={control}
            className="mb-3"
            label="Código"
            name="codigo"
            type="text"
            rules={{ required: true }}
          />

          <FormControl
            control={control}
            className="mb-3"
            label="Descrição"
            name="descricao"
            type="text"
            rules={{ required: true }}
          />

          <FormCheck
            control={control}
            className="mb-3"
            name="vinculadoACartao"
            label="Vinculado à cartão"
            type="switch"
          />

          <FormCheck
            control={control}
            className="mb-3"
            name="permiteAjusteFinanceiro"
            label="Permite ajuste financeiro"
            type="switch"
          />

          <FormCheck
            control={control}
            className="mb-3"
            name="resolucaoImediata"
            label="Resolução imediata"
            type="switch"
          />

          {!ehResolucaoImediata && (
            <FormControl
              control={control}
              className="mb-3"
              label="Prazo para resolução em dias úteis"
              name="prazoParaResolucaoEmDiasUteis"
              type="number"
              rules={{ required: true, min: 1 }}
            />
          )}
        </Form>
      </BSModal.Body>

      <BSModal.Footer>
        <BSButton variant="light" onClick={closeDialog}>
          Fechar
        </BSButton>
        <BSButton variant="primary" type="submit" form="form">
          Cadastrar
        </BSButton>
      </BSModal.Footer>
    </>
  );
};

export default CadastrarTiposSolicitacaoClienteDialog;

import BSAlert from 'react-bootstrap/Alert';
import BSButton from 'react-bootstrap/Button';
import BSModal from 'react-bootstrap/Modal';

import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import DetailElement from '../../components/details/detail-element';
import { Form } from '../../components/form/form';
import { FormCheck } from '../../components/form/form-check';
import { FormControl } from '../../components/form/form-control';
import { ApiSingleElementResponse, ClassTypesEnum } from '../../helpers';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch } from '../../store/hooks-redux';
import { createCredencialCliente } from './credenciais-cliente.redux';

type CadastrarCredencialClienteFormFields = {
  nome: string;
  ativo: boolean;
};

type CadastrarCredencialClienteDialogProps = {
  closeDialog: () => void;
  reload: () => void;
};

const CadastrarCredencialClienteDialog: React.FC<CadastrarCredencialClienteDialogProps> = ({ closeDialog, reload }) => {
  const dispatch = useAppDispatch();
  const { showToast } = useToasts();

  const form = useForm<CadastrarCredencialClienteFormFields>();
  const { control } = form;

  const [showDialog, setShowDialog] = useState(true);
  const [clientId, setClientId] = useState('');
  const [clientSecret, setClientSecret] = useState('');

  const onSubmitHandler = useCallback(
    (data: CadastrarCredencialClienteFormFields) => {
      dispatch(createCredencialCliente({ data }))
        .then(({ payload: { data } }: ApiSingleElementResponse) => {
          setClientId(data.clientId as string);
          setClientSecret(data.clientSecret as string);
          setShowDialog(false);

          showToast({ message: 'Client credential cadastrado com sucesso!', type: ClassTypesEnum.SUCCESS });
          reload();
        })
        .catch((error: Error) => {
          showToast({ message: error.message, type: ClassTypesEnum.DANGER });
        });
    },
    [dispatch, reload, showToast]
  );

  return (
    <>
      {clientId !== '' && clientSecret !== '' && (
        <>
          <BSModal.Header closeButton>
            <BSModal.Title>Novo client credential</BSModal.Title>
          </BSModal.Header>
          <BSModal.Body>
            <div className="col-lg-12 col-md-12 mb-4">
              <BSAlert variant="danger">
                <span>Atenção !</span>
                <p>
                  Essas são as credenciais geradas para o acesso criado. Após fechar essa caixa de diálogo, não será
                  possível consultar novamente as credenciais, portanto armazene-as em um local seguro para utlização
                  posterior.
                </p>
              </BSAlert>
            </div>
            <DetailElement descricao="Client ID" valor={clientId} />
            <DetailElement descricao="Client secret" valor={clientSecret} />
          </BSModal.Body>
          <BSModal.Footer>
            <BSButton variant="light" onClick={closeDialog}>
              Fechar
            </BSButton>
          </BSModal.Footer>
        </>
      )}

      {showDialog && (
        <>
          <BSModal.Header closeButton>
            <BSModal.Title>Novo client credential</BSModal.Title>
          </BSModal.Header>
          <BSModal.Body>
            <Form id="form" form={form} onSubmit={onSubmitHandler}>
              <FormControl control={control} className="mb-3" label="Nome" name="nome" rules={{ required: true }} />
              <FormCheck control={control} className="mb-3" name="ativo" label="Ativo" type="switch" />
            </Form>
          </BSModal.Body>
          <BSModal.Footer>
            <BSButton variant="light" onClick={closeDialog}>
              Fechar
            </BSButton>
            <BSButton variant="primary" type="submit" form="form">
              Cadastrar client credential
            </BSButton>
          </BSModal.Footer>
        </>
      )}
    </>
  );
};

export default CadastrarCredencialClienteDialog;

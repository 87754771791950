import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import { useCallback, useEffect, useMemo } from 'react';
import BSBadge from 'react-bootstrap/Badge';
import BSButton from 'react-bootstrap/Button';
import { useParams } from 'react-router-dom';

import { DetailCard } from '../../components/details/datail-card';
import DetailElement from '../../components/details/detail-element';
import DetailSubTitle from '../../components/details/detail-subtitle';
import DetailTitle from '../../components/details/detail-title';
import ConfirmationDialog from '../../components/dialog/confirmation-dialog';
import { Loading } from '../../components/loading';
import RbacElement from '../../components/role-based-access-control/role-based-access-control-element';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import {
  ApiSingleElementResponse,
  ClassTypesEnum,
  FormatValueEnum,
  moedaLabelMap,
  statusPedidoAutorizacaoColorMap,
  statusPedidoAutorizacaoLabelMap,
  tipoTransacaoLabelMap,
} from '../../helpers';
import { useDialog } from '../../hooks/dialog/use-dialog';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import { loadCartao, selectCartaoById } from '../cartoes/cartoes.redux';
import {
  confirmarPedidoAutorizacao,
  loadPedidoAutorizacao,
  selectPedidoAutorizacaoById,
  selectPedidosAutorizacaoLoadingStateByFilters,
} from './pedidos-autorizacao.redux';

const DetalhesPedidoAutorizacaoPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const params = useParams();
  const pedidoAutorizacaoId = params?.pedidoAutorizacaoId as string;

  const { showDialog, closeDialog } = useDialog();
  const { showToast } = useToasts();

  const filters = useMemo(() => ({ pedidoAutorizacaoId }), [pedidoAutorizacaoId]);
  const pedidoAutorizacao = useSelector((state) => selectPedidoAutorizacaoById(state, pedidoAutorizacaoId));
  const loadingState = useSelector((state) => selectPedidosAutorizacaoLoadingStateByFilters(state, filters));
  const cartao = useSelector((state) => selectCartaoById(state, pedidoAutorizacao?.cartao));

  const _loadCartao = useCallback(
    (pedidoAutorizacao: any) =>
      dispatch(loadCartao({ cartaoId: pedidoAutorizacao?.cartao })).catch((error: Error) => error),
    [dispatch]
  );

  const _loadPedidoAutorizacao = useCallback(
    () =>
      dispatch(loadPedidoAutorizacao({ pedidoAutorizacaoId }))
        .then(({ payload: { data } }: ApiSingleElementResponse) => _loadCartao(data))
        .catch((error: Error) => error),
    [_loadCartao, dispatch, pedidoAutorizacaoId]
  );

  const onConfirmHandler = () =>
    dispatch(confirmarPedidoAutorizacao({ params: { pedidoAutorizacaoId }, data: {} }))
      .then(() => {
        showToast({ message: 'Confirmado!', type: ClassTypesEnum.SUCCESS });
        _loadPedidoAutorizacao();
      })
      .catch((error: Error) => {
        showToast({ message: error.message, type: ClassTypesEnum.DANGER });
      });

  useEffect(() => {
    _loadPedidoAutorizacao();
  }, [_loadPedidoAutorizacao]);

  if (!pedidoAutorizacao) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Loading notFoundMessage="Pedido de autorização não encontrado" loadingState={loadingState} />
      </div>
    );
  }

  const selectCurrencyFormat = (codigoMoeda: string): FormatValueEnum => {
    const codigoPaisMoeda = moedaLabelMap[Number(codigoMoeda) as keyof typeof moedaLabelMap];

    switch (codigoPaisMoeda) {
      case moedaLabelMap[986]:
        return FormatValueEnum.BRL;
      case moedaLabelMap[840]:
        return FormatValueEnum.USD;
      default:
        return FormatValueEnum.CURRENCY;
    }
  };

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_PEDIDO_AUTORIZACAO}>
      <DetailCard>
        <div className="row mb-5">
          <div className="col-md-8 col-sm-12 mb-3">
            <DetailTitle>Pedido de autorização - {pedidoAutorizacao.motivo}</DetailTitle>
          </div>

          <div className="col-md-4 col-sm-12 text-md-end text-sm-start">
            <h4 className="mb-0">
              <BSBadge
                pill
                bg={
                  statusPedidoAutorizacaoColorMap[
                    pedidoAutorizacao.status as keyof typeof statusPedidoAutorizacaoColorMap
                  ]
                }
              >
                {pedidoAutorizacao.status}
              </BSBadge>
            </h4>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-4 col-md-6 mb-4">
            <DetailElement
              descricao="Data do pedido"
              valor={pedidoAutorizacao.dataHoraPedido}
              format={FormatValueEnum.DATE_TIME}
            />
            <DetailElement
              descricao="Autorizado"
              valor={pedidoAutorizacao.foiAutorizado}
              format={FormatValueEnum.BOOL}
            />
            <DetailElement
              descricao="Cartão"
              valor={cartao?.numeroTruncado}
              format={FormatValueEnum.CARTAO}
              link={`/contas-cartao/${cartao?.contaCartao}/cartoes/${cartao?._id}`}
            />
          </div>

          <div className="col-lg-4 col-md-6 mb-4">
            <DetailElement
              descricao="Data da transmissão"
              valor={pedidoAutorizacao.mensagem.dataHoraTransmissão}
              format={FormatValueEnum.DATE_TIME}
            />
            <DetailElement
              descricao="Identificador da autorização"
              valor={pedidoAutorizacao.identificadorAutorizacao}
            />
            <DetailElement
              descricao="Operação"
              valor={pedidoAutorizacao.operacao}
              format={FormatValueEnum.ENUM}
              map={tipoTransacaoLabelMap}
            />
          </div>

          <div className="col-lg-4 col-md-6 mb-4">
            <DetailElement descricao="Status" valor={pedidoAutorizacao.status} />
            <DetailElement descricao="Identificador único da transação" valor={pedidoAutorizacao.mensagem.stan} />
          </div>

          <div className="col-lg-4 col-md-6 mb-4">
            <div className="mb-3">
              <DetailSubTitle>Códigos</DetailSubTitle>
            </div>

            <DetailElement
              descricao="Moeda de transação"
              valor={
                moedaLabelMap[pedidoAutorizacao.mensagem.codigoMoedaTransacao as keyof typeof moedaLabelMap] ||
                pedidoAutorizacao.mensagem.codigoMoedaTransacao
              }
            />
            <DetailElement
              descricao="Moeda de liquidação"
              valor={moedaLabelMap[pedidoAutorizacao.mensagem.codigoMoedaLiquidacao as keyof typeof moedaLabelMap]}
            />
            <DetailElement
              descricao="Moeda do faturamento"
              valor={moedaLabelMap[pedidoAutorizacao.mensagem.codigoMoedaFaturamento as keyof typeof moedaLabelMap]}
            />
          </div>

          <div className="col-lg-4 col-md-6 mb-4">
            <div className="mb-3">
              <DetailSubTitle>Valores</DetailSubTitle>
            </div>

            <DetailElement
              descricao="Transação"
              valor={pedidoAutorizacao.mensagem.valorTransacao}
              format={selectCurrencyFormat(pedidoAutorizacao.mensagem.codigoMoedaTransacao)}
            />
            <DetailElement
              descricao="Liquidação"
              valor={pedidoAutorizacao.mensagem.valorLiquidacao}
              format={selectCurrencyFormat(pedidoAutorizacao.mensagem.codigoMoedaLiquidacao)}
            />
            <DetailElement
              descricao="Faturamento"
              valor={pedidoAutorizacao.mensagem.valorFaturamento}
              format={FormatValueEnum.BRL}
            />
            <DetailElement
              descricao="Taxa de transação"
              valor={pedidoAutorizacao.mensagem.valorTaxaTransacao}
              format={FormatValueEnum.BRL}
            />
          </div>

          <div className="col-lg-4 col-md-6 mb-4">
            <div className="mb-3">
              <DetailSubTitle>Informações da compra</DetailSubTitle>
            </div>

            <DetailElement descricao="Lojista" valor={pedidoAutorizacao.mensagem.nomeLojista} />
            <DetailElement descricao="Localização do lojista" valor={pedidoAutorizacao.mensagem.localizacaoLojista} />
            <DetailElement descricao="MCC" valor={pedidoAutorizacao.mensagem.mcc} />
            <DetailElement descricao="Forma de parcelamento" valor={pedidoAutorizacao.mensagem.formaParcelamento} />
            <DetailElement descricao="Número de parcelas" valor={pedidoAutorizacao.mensagem.numeroParcelas} />
          </div>
        </div>

        {pedidoAutorizacao.status === statusPedidoAutorizacaoLabelMap.Pendente && (
          <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.CONFIRMACAO_PEDIDO_AUTORIZACAO}>
            <div className="row">
              <div className="col-12 d-flex justify-content-end flex-wrap">
                <div className="m-1">
                  <BSButton
                    onClick={() =>
                      showDialog({
                        component: (
                          <ConfirmationDialog
                            onConfirmation={() => onConfirmHandler()}
                            message={`Deseja confirmar o pedido de autorização pendente: ${pedidoAutorizacao.identificadorAutorizacao}?`}
                            confirmationLabel="Confirmar"
                            title="Confirmar pedido de autorização"
                            onHide={closeDialog}
                          />
                        ),
                      })
                    }
                  >
                    Confirmar
                  </BSButton>
                </div>
              </div>
            </div>
          </RbacElement>
        )}
      </DetailCard>
    </RbacPage>
  );
};

export default DetalhesPedidoAutorizacaoPage;

import { Navigate, Route, Routes } from 'react-router-dom';
import DetalhesRelatorioOperacaoAutorizacoesPage from './autorizacoes/detalhes-relatorio-operacao-autorizacoes-page';
import RelatoriosOperacaoAutorizacoesPage from './autorizacoes/relatorios-operacao-autorizacoes-page';

const RelatoriosOperacaoRoutes: React.FC = () => {
  return (
    <Routes>
      <Route index element={<Navigate to="autorizacoes" />} />
      <Route path="autorizacoes" element={<RelatoriosOperacaoAutorizacoesPage />} />
      <Route path="autorizacoes/:relatorioOperacaoId" element={<DetalhesRelatorioOperacaoAutorizacoesPage />} />
    </Routes>
  );
};

export default RelatoriosOperacaoRoutes;

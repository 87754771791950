import BSCard from 'react-bootstrap/Card';

type TitledCardProps = {
  title: string;
  className?: string;
  children?: React.ReactNode;
};

export const TitledCard: React.FC<TitledCardProps> = ({ title, className, children }) => {
  return (
    <BSCard className={className}>
      <BSCard.Body>
        <div className="mb-4">
          <h5>{title}</h5>
        </div>

        {children}
      </BSCard.Body>
    </BSCard>
  );
};

import { reducerFactory, actionFactory, selectorFactory } from '../../store';

const context = 'tarifas';

const { createOne, loadMany, loadOne, updateOne } = actionFactory(context, {
  buildUrlCreateOne: () => '/api/tarifas',
  buildUrlLoadMany: () => '/api/tarifas',
  buildUrlLoadOne: ({ tarifaId }) => `/api/tarifas/${tarifaId}`,
  buildUrlUpdateOne: ({ tarifaId }) => `/api/tarifas/${tarifaId}`,
});

const { selectOneById, selectAll, selectManyByFilters, selectTotalByFilters, selectLoadingStateByFilters } =
  selectorFactory({ context });

export const createTarifa = createOne;
export const loadTarifas = loadMany;
export const loadTarifa = loadOne;
export const updateTarifa = updateOne;

export const selectTarifaById = selectOneById;
export const selectTodosTarifas = selectAll;
export const selectTarifasByFilters = selectManyByFilters;
export const selectTotalTarifasByFilters = selectTotalByFilters;
export const selectTarifasLoadingStateByFilters = selectLoadingStateByFilters;

export const tarifas = reducerFactory({ context });

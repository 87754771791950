import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { DetailCard } from '../../components/details/datail-card';
import DetailElement from '../../components/details/detail-element';
import DetailSubTitle from '../../components/details/detail-subtitle';
import DetailTitle from '../../components/details/detail-title';
import { Loading } from '../../components/loading';
import Page from '../../components/page';
import { FormatValueEnum } from '../../helpers';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import {
  loadParametroGeracaoBoleto,
  selectParametroGeracaoBoletoById,
  selectParametrosGeracaoBoletoLoadingStateByFilters,
} from './parametros-geracao-boleto.redux';

const DetalhesParametroGeracaoBoletoPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const params = useParams();
  const parametroGeracaoBoletoId = params.parametroGeracaoBoletoId as string;

  const filters = useMemo(() => ({ parametroGeracaoBoletoId }), [parametroGeracaoBoletoId]);
  const parametroGeracaoBoleto = useSelector((state) =>
    selectParametroGeracaoBoletoById(state, parametroGeracaoBoletoId)
  );
  const loadingState = useSelector((state) => selectParametrosGeracaoBoletoLoadingStateByFilters(state, filters));

  const _loadParametroGeracaoBoleto = useCallback(
    () => dispatch(loadParametroGeracaoBoleto({ parametroGeracaoBoletoId })).catch((error: Error) => error),
    [dispatch, parametroGeracaoBoletoId]
  );

  useEffect(() => {
    _loadParametroGeracaoBoleto();
  }, [_loadParametroGeracaoBoleto]);

  if (!parametroGeracaoBoleto) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Loading notFoundMessage="Parâmetro Geração Boleto não encontrado" loadingState={loadingState} />
      </div>
    );
  }

  return (
    <Page>
      <div className="mb-5">
        <DetailCard>
          <div className="mb-5">
            <DetailTitle>Parâmetro de geração de boleto</DetailTitle>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-6 mb-4">
              <DetailElement descricao="Tipo CNAB" valor={parametroGeracaoBoleto.tipoCnab} />
              <DetailElement descricao="Espécie do documento" valor={parametroGeracaoBoleto.especieDocumento} />
              <DetailElement
                descricao="Máximo de pagamentos para um boleto"
                valor={parametroGeracaoBoleto.pagamentoParcial}
              />
              <DetailElement descricao="Local do pagamento" valor={parametroGeracaoBoleto.localPagamento} />
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <DetailElement descricao="Subtipo CNAB" valor={parametroGeracaoBoleto.subtipoCnab} />
              <DetailElement
                descricao="Código para baixa/devolução"
                valor={parametroGeracaoBoleto.codigoBaixaDevolucao}
              />
              <DetailElement descricao="Código da empresa" valor={parametroGeracaoBoleto.codigoEmpresa} />
              <DetailElement descricao="CIP" valor={parametroGeracaoBoleto.cip} />
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <DetailElement descricao="Moeda" valor={parametroGeracaoBoleto.moeda} />
              <DetailElement descricao="Carteira" valor={parametroGeracaoBoleto.carteira} />
              <DetailElement descricao="Aceite" valor={parametroGeracaoBoleto.aceite} />
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-12 mb-4">
              <DetailElement descricao="Instruções" valor={parametroGeracaoBoleto.instrucoes} />
            </div>
          </div>

          <div className="mb-4">
            <hr />
          </div>

          <div className="row mb-4">
            <div className="col-lg-6 col-md-6 mb-4">
              <div className="mb-3">
                <DetailSubTitle>Informações do Banco Cedente</DetailSubTitle>
              </div>
              <DetailElement descricao="Banco Cedente" valor={parametroGeracaoBoleto.bancoCedente} />
              <DetailElement descricao="Código" valor={parametroGeracaoBoleto.codigoBancoCedente} />
              <DetailElement descricao="Nome" valor={parametroGeracaoBoleto.nomeBancoCedente} />
              <DetailElement
                descricao="Número do convênio com o emissor"
                valor={parametroGeracaoBoleto.convenioCedente}
              />
              <DetailElement descricao="Endereço" valor={parametroGeracaoBoleto.endereco} />
              <DetailElement descricao="Cidade" valor={parametroGeracaoBoleto.cidade} />
              <DetailElement descricao="CNPJ" valor={parametroGeracaoBoleto.cnpj} format={FormatValueEnum.CPF_CNPJ} />
            </div>
            <div className="col-lg-6 col-md-6 mb-4">
              <div className="mb-3">
                <DetailSubTitle>Informações do Beneficiário</DetailSubTitle>
              </div>
              <DetailElement descricao="Agência" valor={parametroGeracaoBoleto.agenciaBeneficiario} />
              <DetailElement descricao="Dígito agência" valor={parametroGeracaoBoleto.digitoAgenciaBeneficiario} />
              <DetailElement descricao="Conta" valor={parametroGeracaoBoleto.contaBeneficiario} />
              <DetailElement descricao="Dígito da conta" valor={parametroGeracaoBoleto.digitoContaBeneficiario} />
              <DetailElement descricao="Descrição" valor={parametroGeracaoBoleto.descricaoBeneficiario} />
            </div>
          </div>
        </DetailCard>
      </div>
    </Page>
  );
};

export default DetalhesParametroGeracaoBoletoPage;

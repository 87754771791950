import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import { useCallback, useEffect } from 'react';
import BSButton from 'react-bootstrap/Button';
import BSTab from 'react-bootstrap/Tab';
import BSTabs from 'react-bootstrap/Tabs';
import { Link } from 'react-router-dom';
import { DetailCard } from '../../components/details/datail-card';
import DetailElement from '../../components/details/detail-element';
import DetailSubTitle from '../../components/details/detail-subtitle';
import DetailTitle from '../../components/details/detail-title';
import { Loading } from '../../components/loading';
import RbacElement from '../../components/role-based-access-control/role-based-access-control-element';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import { ApiSingleElementResponse, FormatValueEnum } from '../../helpers';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import { loadTipoBloqueioCartao, selectTiposBloqueiosById } from '../tipos-bloqueio-cartao/tipos-bloqueio-cartao.redux';
import AliquotasEmissorTab from './aliquotas-emissor-tab';
import ContabilEmissorTab from './contabil-emissor-tab';
import DecredEmissorTab from './decred-emissor.tab';
import { loadEmissor, selectEmissor, selectLoadingStateByFiltersEmissor } from './emissor.redux';
import PermissoesEmissorTab from './permissoes-emissor-tab';

const DetalhesEmissorPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const _loadTipoBloqueioCartao = useCallback(
    (emissor: any) => {
      dispatch(loadTipoBloqueioCartao({ tipoBloqueioCartaoId: emissor.bloqueioInicialCartao })).catch(
        (error: Error) => error
      );
    },
    [dispatch]
  );

  const _loadEmissor = useCallback(
    () =>
      dispatch(loadEmissor({}))
        .then(({ payload: { data } }: ApiSingleElementResponse) => _loadTipoBloqueioCartao(data))
        .catch((error: Error) => error),
    [_loadTipoBloqueioCartao, dispatch]
  );

  useEffect(() => {
    _loadEmissor();
  }, [_loadEmissor]);

  const [emissor] = useSelector((state) => selectEmissor(state, {})) ?? [];
  const loadingState = useSelector((state) => selectLoadingStateByFiltersEmissor(state, {}));
  const tipoBloqueioInicial = useSelector((state) => selectTiposBloqueiosById(state, emissor?.bloqueioInicialCartao));

  if (!emissor) {
    return (
      <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_EMISSOR}>
        <div className="d-flex justify-content-center align-items-center h-100">
          <Loading notFoundMessage="Emissor não econtrado" loadingState={loadingState} />
        </div>
      </RbacPage>
    );
  }

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_EMISSOR}>
      <div className="mb-5">
        <DetailCard>
          <div className="mb-5">
            <DetailTitle>
              Emissor {emissor.nome} ({emissor.codigo})
            </DetailTitle>
          </div>

          <div className="row mb-4">
            <div className="col-lg-4 col-md-6 mb-4">
              <DetailElement descricao="Nome do emissor" valor={emissor.nome} />
              <DetailElement descricao="CNPJ" valor={emissor.cnpj} format={FormatValueEnum.CPF_CNPJ} />
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <DetailElement descricao="Emissor na Mastercard" valor={emissor.numeroEmissorMastercard} />
              <DetailElement descricao="CPNJ processadora" valor={emissor.cnpjProcessadora} />
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <div className="mb-3">
                <DetailSubTitle>Códigos</DetailSubTitle>
              </div>
              <DetailElement descricao="Emissor" valor={emissor.codigo} />
              <DetailElement descricao="Empresa" valor={emissor.codigoEmpresa} />
              <DetailElement descricao="Processadora" valor={emissor.codigoProcessadora} />
              <DetailElement descricao="Filial" valor={emissor.filial} />
              <DetailElement descricao="Aplicação" valor={emissor.codigoAplicacao} />
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <div className="mb-3">
                <DetailSubTitle>Parâmetros</DetailSubTitle>
              </div>
              <DetailElement
                descricao="Bloqueio inicial cartão"
                valor={tipoBloqueioInicial?.descricao}
                link={`/tipos-bloqueio-cartao/${emissor.bloqueioInicialCartao}`}
              />
              <DetailElement descricao="Renovação cartão em dias" valor={emissor.prazoRenovacaoCartaoEmDias} />
              <DetailElement
                descricao="Spread cotação dólar"
                valor={emissor.percentualSpreadCotacaoDolar}
                format={FormatValueEnum.PERCENT}
              />
              <DetailElement
                descricao="Juros mora diário"
                valor={emissor.taxaJurosMoraDiario}
                format={FormatValueEnum.PERCENT}
              />
              <DetailElement
                descricao="Multa atraso"
                valor={emissor.percentualMultaAtraso}
                format={FormatValueEnum.PERCENT}
              />
              <DetailElement descricao="Codificação de quebra de linha" valor={emissor.codificacaoQuebraLinha} />
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <div className="mb-3">
                <DetailSubTitle>Parâmetros diários</DetailSubTitle>
              </div>
              <DetailElement
                descricao="Limite para bloqueio por erro de senha"
                valor={emissor.limiteErrosSenhaDiario}
              />
              <DetailElement descricao="Limite para bloqueio por erro de CVC" valor={emissor.limiteErrosCVCDiario} />
              <DetailElement
                descricao="Zerar contador após senha correta"
                valor={emissor.zerarContadorAposSenhaCorreta}
                format={FormatValueEnum.BOOL}
              />
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <div className="mb-3">
                <DetailSubTitle>Endereço</DetailSubTitle>
              </div>
              <DetailElement descricao="Logradouro" valor={emissor.endereco} />
              <DetailElement descricao="Cidade" valor={emissor.cidade} />
              <DetailElement descricao="UF" valor={emissor.uf} />
              <DetailElement descricao="CEP" valor={emissor.cep} />
            </div>
          </div>
          <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.ALTERACAO_EMISSOR}>
            <div className="d-flex justify-content-end">
              <Link to="/emissor/atualizar">
                <BSButton>Atualizar dados</BSButton>
              </Link>
            </div>
          </RbacElement>
        </DetailCard>
      </div>

      <DetailCard>
        <BSTabs defaultActiveKey="aliquotas" fill unmountOnExit>
          <BSTab eventKey="aliquotas" title="Alíquotas">
            <AliquotasEmissorTab />
          </BSTab>
          <BSTab eventKey="decred" title="Decred">
            <DecredEmissorTab />
          </BSTab>
          <BSTab eventKey="permissoes" title="Permissões">
            <PermissoesEmissorTab />
          </BSTab>
          <BSTab eventKey="contabil" title="Contábil">
            <ContabilEmissorTab />
          </BSTab>
        </BSTabs>
      </DetailCard>
    </RbacPage>
  );
};

export default DetalhesEmissorPage;

import { reducerFactory, actionFactory } from '../../store';

const context = 'simuladorJurosRotativo';

const { createOne, updateOne } = actionFactory(context, {
  buildUrlCreateOne: () => '/api/operacao/simulador-juros-rotativo',
  buildUrlUpdateOne: ({ logSimuladorId }) => `/api/operacao/simulador-juros-rotativo/${logSimuladorId}`,
});

export const simularJurosRotativo = createOne;
export const updateLog = updateOne;

export const simuladorJurosRotativo = reducerFactory({ context });

import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import { useCallback, useMemo } from 'react';
import BSCard from 'react-bootstrap/Card';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import { SmartTable } from '../../components/smart-table/smart-table';
import SmartTableFilters from '../../components/smart-table/smart-table-filters/smart-table-filters';
import {
  SmartTableFilterControlTypesEnum,
  SmartTableFilterProps,
} from '../../components/smart-table/smart-table-filters/table-filter.types';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import Title from '../../components/title';
import { tiposOperacaoBeneficioLabelMap } from '../../helpers';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import {
  loadCategoriasBeneficios,
  selectCategoriaBeneficiosByFilters,
  selectCategoriasBeneficiosStateByFilters,
  selectTotalCategoriasBeneficiosByFilters,
} from './categorias.beneficio.redux';

const smartColumns = (): SmartTableColumnProps[] => [
  { label: 'Nome', attribute: 'nome', className: 'text-center' },
  {
    label: 'Operação',
    attribute: 'operacoes',
    className: 'text-center',
    format: (operacoes: string[]) => {
      return operacoes
        .map((operacao) => tiposOperacaoBeneficioLabelMap[operacao as keyof typeof tiposOperacaoBeneficioLabelMap])
        .join(', ');
    },
  },
];
const smartFilters = (): SmartTableFilterProps[] => [
  { label: 'Nome', attribute: 'nome', controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT },
  {
    label: 'Operação',
    attribute: 'operacoes',
    controlType: SmartTableFilterControlTypesEnum.SELECT,
    map: tiposOperacaoBeneficioLabelMap,
  },
];

const CategoriasBeneficiosPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;
  const { query } = useQuerystring();

  const filters = useMemo(() => ({ ...query }), [query]);
  const categoriasBeneficios = useSelector((state) => selectCategoriaBeneficiosByFilters(state, filters));
  const total = useSelector((state) => selectTotalCategoriasBeneficiosByFilters(state, filters));
  const loadingState = useSelector((state) => selectCategoriasBeneficiosStateByFilters(state, filters));

  const loadItems = useCallback(
    () => dispatch(loadCategoriasBeneficios({ query })).catch((error: Error) => error),
    [dispatch, query]
  );
  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_CATEGORIA_BERNEFICIO}>
      <div className="d-flex flex-column h-100">
        <Title>Categorias de benefício</Title>

        <div className="row">
          <div className="col-xxl-2 col-xl-3 ">
            <SmartTableFilters filters={smartFilters()} />
          </div>

          <div className="col-xxl-10 col-xl-9 col-lg-8 col-md-12 mb-4">
            <BSCard>
              <BSCard.Body>
                <SmartTable
                  emptyMessage="Pesquise por uma categoria de benefício"
                  errorMessage="Erro na listagem de categorias de benefícios"
                  usePagination={true}
                  loadItems={loadItems}
                  columns={smartColumns()}
                  items={categoriasBeneficios}
                  loadingState={loadingState}
                  size={total}
                />
              </BSCard.Body>
            </BSCard>
          </div>
        </div>
      </div>
    </RbacPage>
  );
};

export default CategoriasBeneficiosPage;

import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import { useCallback, useMemo } from 'react';
import BSBadge from 'react-bootstrap/Badge';
import BSCard from 'react-bootstrap/Card';
import { useNavigate } from 'react-router-dom';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import { SmartTable } from '../../components/smart-table/smart-table';
import SmartTableFilters from '../../components/smart-table/smart-table-filters/smart-table-filters';
import {
  SmartTableFilterControlTypesEnum,
  SmartTableFilterProps,
} from '../../components/smart-table/smart-table-filters/table-filter.types';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import Title from '../../components/title';
import {
  ClassTypesEnum,
  FormatValueEnum,
  formatCpfCnpj,
  statusSolicitacaoAberturaContaColorMap,
  statusSolicitacaoAberturaContaLabelMap,
} from '../../helpers';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import {
  loadSolicitacoesAberturaConta,
  selectSolicitacaoAberturaContaLoadingStateByFilters,
  selectSolicitacoesAberturaContaByFilters,
  selectTotalSolicitacoesAberturaContaByFilters,
} from './solicitacao-abertura-conta.redux';

const smartColumns = (): SmartTableColumnProps[] => [
  {
    label: 'Código do cliente',
    attribute: 'cliente',
    format: (cliente: any) => cliente.codigo,
    sortable: true,
    className: 'text-center',
  },
  {
    label: 'CPF / CNPJ',
    attribute: 'cliente',
    className: 'text-center',
    format: (cliente: any) => formatCpfCnpj(cliente.cpfCnpj),
  },
  {
    label: 'Nome',
    attribute: 'cliente',
    sortable: true,
    className: 'text-center',
    format: (cliente: any) => cliente.nome,
  },
  {
    label: 'Status',
    attribute: 'status',
    className: 'text-center',
    format: (status: string) => {
      const colorKey = status as keyof typeof statusSolicitacaoAberturaContaColorMap;
      const descritivoKey = status as keyof typeof statusSolicitacaoAberturaContaLabelMap;

      return (
        <h5 className="m-0">
          <BSBadge pill bg={statusSolicitacaoAberturaContaColorMap[colorKey]}>
            {statusSolicitacaoAberturaContaLabelMap[descritivoKey]}
          </BSBadge>
        </h5>
      );
    },
  },
  {
    label: 'Início',
    attribute: 'dataHoraInicioOnboarding',
    sortable: true,
    className: 'text-center',
    format: FormatValueEnum.DATE_TIME,
  },
  {
    label: 'Finalização',
    attribute: 'dataHoraFinalizacaoOnboarding',
    sortable: true,
    className: 'text-center',
    format: FormatValueEnum.DATE_TIME,
  },
  {
    label: 'Resposta',
    attribute: 'dataHoraResposta',
    sortable: true,
    className: 'text-center',
    format: FormatValueEnum.DATE_TIME,
  },
];

const smartFilters: SmartTableFilterProps[] = [
  {
    label: 'Nome do cliente',
    attribute: 'cliente.nome',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
    minLength: 4,
  },
  {
    label: 'CPF / CNPJ',
    attribute: 'cliente.cpfCnpj',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
    minLength: 10,
  },
  {
    label: 'Status',
    attribute: 'status',
    controlType: SmartTableFilterControlTypesEnum.SELECT,
    map: statusSolicitacaoAberturaContaLabelMap,
  },
  {
    label: 'Início',
    attribute: 'dataHoraInicioOnboarding',
    controlType: SmartTableFilterControlTypesEnum.DATE_TIME_RANGE,
  },
  {
    label: 'Finalização',
    attribute: 'dataHoraFinalizacaoOnboarding',
    controlType: SmartTableFilterControlTypesEnum.DATE_TIME_RANGE,
  },
  {
    label: 'Resposta',
    attribute: 'dataHoraResposta',
    controlType: SmartTableFilterControlTypesEnum.DATE_TIME_RANGE,
  },
];

const SolicitacoesAberturaContaPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const { query } = useQuerystring();
  const { showToast } = useToasts();
  const navigate = useNavigate();

  const filters = useMemo(() => ({ ...query }), [query]);
  const solicitacoesAberturaConta = useSelector((state) => selectSolicitacoesAberturaContaByFilters(state, filters));
  const total = useSelector((state) => selectTotalSolicitacoesAberturaContaByFilters(state, filters));
  const loadingState = useSelector((state) => selectSolicitacaoAberturaContaLoadingStateByFilters(state, filters));

  const loadItems = useCallback(
    () =>
      dispatch(loadSolicitacoesAberturaConta({ query: filters })).catch(() => {
        showToast({
          message: 'Ocorreu um erro ao listar as solicitações de abertura de conta',
          type: ClassTypesEnum.DANGER,
        });
      }),
    [dispatch, filters, showToast]
  );

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_SOLICITACAO_ABERTURA_CONTA}>
      <div className="d-flex flex-column h-100">
        <Title>Solicitações de abertura de conta</Title>

        <div className="row">
          <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-12 mb-4">
            <SmartTableFilters filters={smartFilters} />
          </div>

          <div className="col-xxl-10 col-xl-9 col-lg-8 col-md-12 mb-4">
            <BSCard>
              <BSCard.Body>
                <SmartTable
                  emptyMessage="Pesquise por uma solicitação de abertura de conta"
                  errorMessage="Erro na listagem de solicitações de abertura de conta"
                  usePagination={true}
                  loadItems={loadItems}
                  columns={smartColumns()}
                  items={solicitacoesAberturaConta}
                  loadingState={loadingState}
                  size={total}
                  onRowClick={(doc) => navigate(`/solicitacoes-abertura-conta/${doc._id}`)}
                />
              </BSCard.Body>
            </BSCard>
          </div>
        </div>
      </div>
    </RbacPage>
  );
};

export default SolicitacoesAberturaContaPage;

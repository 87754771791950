import { reducerFactory, actionFactory, selectorFactory } from '../../store';

const context = 'lancamentosContabeis';

const { createOne, loadMany, loadOne } = actionFactory(context, {
  buildUrlCreateOne: () => '/api/lancamentos-contabeis',
  buildUrlLoadMany: () => '/api/lancamentos-contabeis',
  buildUrlLoadOne: ({ lancamentoContabilId }) => `/api/lancamentos-contabeis/${lancamentoContabilId}`,
});

const { loadMany: loadManyTransacao } = actionFactory(context, {
  buildUrlLoadMany: ({ transacaoId }) => `/api/transacoes/${transacaoId}/lancamentos-contabeis/pagamento`,
});
const { selectLoadingStateByFilters, selectManyByFilters, selectOneById, selectTotalByFilters } = selectorFactory({
  context,
});

export const loadLancamentosContabeis = loadMany;
export const loadLancamentoContabil = loadOne;
export const loadLancamentosContabeisPorTransacao = loadManyTransacao;

export const selectLancamentoContabilById = selectOneById;
export const selectLancamentosContabeisByFilters = selectManyByFilters;
export const selectTotalLancamentosContabeisByFilters = selectTotalByFilters;
export const selectLancamentoContabilLoadingStateByFilters = selectLoadingStateByFilters;

export const saveLancamentoContabil = createOne;

export const lancamentosContabeis = reducerFactory({ context });

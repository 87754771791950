import { useCallback, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { SmartTable } from '../../components/smart-table/smart-table';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import { ApiMultiElementResponse, FormatValueEnum, mapearERemoverElementosNulosERepetidos } from '../../helpers';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import {
  loadDoacoesPendentes,
  selectDoacoesCartaoByFilters,
  selectDoacoesCartaoLoadingStateByFilters,
  selectTotalDoacoesCartaoByFilters,
} from '../doacoes-cartao/doacoes-cartao.redux';
import { loadTiposTransacao, selectObjectTodosTiposTransacao } from '../tipos-transacao/tipos-transacao.redux';

const smartColumns = ({ tiposTransacao }: { tiposTransacao: any }): SmartTableColumnProps[] => [
  {
    label: 'Data',
    attribute: 'data',
    format: FormatValueEnum.DATA,
    sortable: true,
    className: 'text-center',
  },
  {
    label: 'Descrição',
    attribute: 'descricao',
    sortable: true,
  },
  {
    label: 'Tipo de transação',
    attribute: 'tipoTransacao',
    format(tipoTransacao) {
      if (!tipoTransacao) {
        return '';
      }

      return (
        <Link onClick={(e) => e.stopPropagation()} to={`/tipos-transacao/${tipoTransacao}`}>
          {tiposTransacao[tipoTransacao]?.descricao}
        </Link>
      );
    },
  },
  {
    label: 'Valor (R$)',
    attribute: 'valor',
    format: FormatValueEnum.BRL,
    sortable: true,
    className: 'text-end',
  },
];

type DoacoesCartaoTabProps = {
  contaCartaoId: string;
};

const DoacoesCartaoPendentesTab: React.FC<DoacoesCartaoTabProps> = ({ contaCartaoId }) => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const navigate = useNavigate();
  const { query, maxItemsQuery } = useQuerystring();
  query.pendente = 'true';

  const filters = useMemo(() => ({ ...query, contaCartaoId }), [contaCartaoId, query]);
  const doacoesPendentes = useSelector((state) => selectDoacoesCartaoByFilters(state, filters));
  const total = useSelector((state) => selectTotalDoacoesCartaoByFilters(state, filters));
  const loadingState = useSelector((state) => selectDoacoesCartaoLoadingStateByFilters(state, filters));
  const tiposTransacao = useSelector((state) => selectObjectTodosTiposTransacao(state));

  const _loadTiposTransacao = useCallback(
    (doacoes: any[]) => {
      const tiposTransacao = mapearERemoverElementosNulosERepetidos(doacoes, 'tipoTransacao');

      dispatch(loadTiposTransacao({ query: { _id: { in: tiposTransacao }, ...maxItemsQuery } })).catch(
        (error: Error) => error
      );
    },
    [dispatch, maxItemsQuery]
  );

  const loadItems = useCallback(
    () =>
      dispatch(loadDoacoesPendentes({ params: { contaCartaoId }, query }))
        .then(({ payload: { data } }: ApiMultiElementResponse) => _loadTiposTransacao(data))
        .catch((error: Error) => error),
    [dispatch, contaCartaoId, query, _loadTiposTransacao]
  );

  return (
    <SmartTable
      emptyMessage="Nenhuma doação encontrada"
      errorMessage="Erro ao listar doações"
      loadItems={loadItems}
      usePagination={true}
      size={total}
      columns={smartColumns({ tiposTransacao })}
      items={doacoesPendentes}
      loadingState={loadingState}
      onRowClick={(doc) => navigate(`/contas-cartao/${doc.contaCartao}/doacoes-cartao/${doc._id}/`)}
    />
  );
};

export default DoacoesCartaoPendentesTab;

import { Route, Routes } from 'react-router-dom';
import LancamentosContabeisPage from './lancamentos-contabeis-page';
import DetalhesLancamentoContabilPage from './detalhes-lancamento-contabil-page';
import DetalhesTransacaoPage from '../transacoes/detalhes-transacao-page';

const LancamentosContabeisRoutes: React.FC = () => {
  return (
    <Routes>
      <Route index element={<LancamentosContabeisPage />} />
      <Route path=":lancamentoContabilId" element={<DetalhesLancamentoContabilPage />} />
      <Route path=":lancamentoContabilId/transacoes/:transacaoId" element={<DetalhesTransacaoPage />} />
    </Routes>
  );
};

export default LancamentosContabeisRoutes;

import { Route, Routes } from 'react-router-dom';
import GrupoCredenciaisClientePage from './grupo-credenciais-cliente-page';
import DetalhesGrupoCredencialPage from './detalhes-grupo-credencial-page';
const GrupoCredenciaisClienteRoutes: React.FC = () => {
  return (
    <Routes>
      <Route index element={<GrupoCredenciaisClientePage />} />
      <Route path=":grupoCredencialId" element={<DetalhesGrupoCredencialPage />} />
    </Routes>
  );
};
export default GrupoCredenciaisClienteRoutes;

import { Route, Routes } from 'react-router-dom';
import TaxasMediasSelicPage from './taxas-medias-selic-page';

const TaxasMediasSelicRoutes: React.FC = () => {
  return (
    <Routes>
      <Route index element={<TaxasMediasSelicPage />} />
    </Routes>
  );
};

export default TaxasMediasSelicRoutes;

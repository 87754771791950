import BSButton from 'react-bootstrap/Button';
import BSModal from 'react-bootstrap/Modal';

import { useForm } from 'react-hook-form';
import { Form } from '../../components/form/form';
import { FormCheck } from '../../components/form/form-check';
import { FormControl } from '../../components/form/form-control';
import { FormSelect } from '../../components/form/form-select';

import { codigoRespostaAutorizadorLabelMap, tipoBloqueioCartaoLabelMap } from '../../helpers';

type AtualizarTipoBloqueioCartaoFields = {
  codigo: string;
  descricao: string;
  indicaCartaoNaoEmitidoPescaben: boolean;
  operacao: number;
  permiteConsultarSaldo: boolean;
  permiteEmissaoSegundaVia: boolean;
  respostaAutorizador: string;
  bloqueioDefinitivo: boolean;
};

type AtualizarTipoBloqueioCartaoDialogProps = {
  tipoBloqueioCartao: any;
  closeDialog: () => void;
  onUpdateHandler: (tipoBloqueioCartaoId: string, data: any) => void;
};

const AtualizarTipoBloqueioCartaoDialog: React.FC<AtualizarTipoBloqueioCartaoDialogProps> = ({
  closeDialog,
  tipoBloqueioCartao,
  onUpdateHandler,
}) => {
  const form = useForm<AtualizarTipoBloqueioCartaoFields>({ defaultValues: tipoBloqueioCartao });
  const { control } = form;

  const onSubmitHandler = (data: AtualizarTipoBloqueioCartaoFields) => {
    const { operacao } = data;

    if (operacao) {
      data.operacao = Number(operacao);
    }

    onUpdateHandler(tipoBloqueioCartao._id, data);
  };

  return (
    <>
      <BSModal.Header closeButton>
        <BSModal.Title>Atualizar tipo de bloqueio do cartão - {tipoBloqueioCartao.descricao}</BSModal.Title>
      </BSModal.Header>
      <BSModal.Body>
        <Form id="form" form={form} onSubmit={onSubmitHandler}>
          <div className="row">
            <FormControl
              control={control}
              className="col-md-6 mb-3"
              label="Código"
              name="codigo"
              type="text"
              rules={{ required: true }}
            />

            <FormControl
              control={control}
              className="col-md-6 mb-3"
              label="Descrição"
              name="descricao"
              type="text"
              rules={{ required: true }}
            />

            <FormSelect
              control={control}
              className="col-md-6 mb-3"
              label="Operação"
              name="operacao"
              placeholder="Selecione a operação"
              options={Object.entries(tipoBloqueioCartaoLabelMap).map((elem) => ({
                label: elem[1],
                value: elem[0],
              }))}
            />

            <FormSelect
              control={control}
              className="col-md-6 mb-3"
              label="Resposta autorizador"
              name="respostaAutorizador"
              placeholder="Selecione a resposta autorizador"
              options={Object.entries(codigoRespostaAutorizadorLabelMap).map((elem) => ({
                label: elem[1],
                value: elem[0],
              }))}
              rules={{ required: true }}
            />

            <FormCheck
              control={control}
              className="col-md-6 mb-3"
              name="bloqueioDefinitivo"
              label="É bloqueio definitivo"
              type="switch"
            />

            <FormCheck
              control={control}
              className="col-md-6 mb-3"
              name="indicaCartaoNaoEmitidoPescaben"
              label="Cartão emitido para PESBACEN"
              type="switch"
            />

            <FormCheck
              control={control}
              className="col-md-6 mb-3"
              name="permiteConsultarSaldo"
              label="Permite consultar saldo"
              type="switch"
            />

            <FormCheck
              control={control}
              className="col-md-6 mb-3"
              name="permiteEmissaoSegundaVia"
              label="Permite emissão de segunda via"
              type="switch"
            />
          </div>
        </Form>
      </BSModal.Body>
      <BSModal.Footer>
        <BSButton variant="light" onClick={closeDialog}>
          Fechar
        </BSButton>
        <BSButton variant="primary" type="submit" form="form">
          Atualizar
        </BSButton>
      </BSModal.Footer>
    </>
  );
};

export default AtualizarTipoBloqueioCartaoDialog;

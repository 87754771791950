import { reducerFactory, actionFactory, selectorFactory } from '../../store';

const context = 'prazosConfirmacaoAutorizacaoMCC';

const { createOne, loadMany, updateOne } = actionFactory(context, {
  buildUrlLoadMany: () => '/api/prazos-confirmacao-autorizacao-mcc',
  buildUrlCreateOne: () => '/api/prazos-confirmacao-autorizacao-mcc',
  buildUrlUpdateOne: ({ prazoConfirmacaoMccId }) => `/api/prazos-confirmacao-autorizacao-mcc/${prazoConfirmacaoMccId}`,
});

const {
  selectOneById,
  selectAll,
  selectManyByFilters,
  selectTotalByFilters,
  selectLoadingStateByFilters,
  selectObjectAllIds,
} = selectorFactory({
  context,
});

export const loadPrazosConfirmacaoAutorizacaoMCC = loadMany;
export const savePrazoConfirmacaoAutorizacaoMCC = createOne;
export const updatePrazoConfirmacaoAutorizacaoMCC = updateOne;

export const selectPrazoConfirmacaoAutorizacaoMCCById = selectOneById;
export const selectTodosPrazosConfirmacaoAutorizacaoMCC = selectAll;
export const selectObjectTodosPrazosConfirmacaoAutorizacaoMCC = selectObjectAllIds;
export const selectPrazosConfirmacaoAutorizacaoMCCByFilters = selectManyByFilters;
export const selectTotalPrazosConfirmacaoAutorizacaoMCCByFilters = selectTotalByFilters;
export const selectPrazosConfirmacaoAutorizacaoMCCLoadingStateByFilters = selectLoadingStateByFilters;

export const prazosConfirmacaoAutorizacaoMCC = reducerFactory({ context });

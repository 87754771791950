import { actionFactory, reducerFactory, selectorFactory } from '../../store';

const context = 'emissor';

const { loadOne, updateOne } = actionFactory(context, {
  buildUrlLoadOne: () => '/api/emissor',
  buildUrlUpdateOne: () => '/api/emissor',
});

const { selectManyByFilters, selectLoadingStateByFilters } = selectorFactory({
  context,
});

export const loadEmissor = loadOne;
export const updateEmissor = updateOne;

export const selectEmissor = selectManyByFilters;
export const selectLoadingStateByFiltersEmissor = selectLoadingStateByFilters;

export const emissor = reducerFactory({ context });

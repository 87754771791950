import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import { useCallback, useMemo } from 'react';
import BSCard from 'react-bootstrap/Card';
import { useNavigate } from 'react-router-dom';
import RbacPage from '../../../components/role-based-access-control/role-based-access-control-page';
import { SmartTable } from '../../../components/smart-table/smart-table';
import SmartTableFilters from '../../../components/smart-table/smart-table-filters/smart-table-filters';
import {
  SmartTableFilterControlTypesEnum,
  SmartTableFilterProps,
} from '../../../components/smart-table/smart-table-filters/table-filter.types';
import { SmartTableColumnProps } from '../../../components/smart-table/smart-table-header';
import Title from '../../../components/title';
import { ApiMultiElementResponse, formatData, mapearERemoverElementosNulosERepetidos } from '../../../helpers';
import { useQuerystring } from '../../../hooks/router/use-querystring';
import { useAppDispatch, useAppSelector } from '../../../store/hooks-redux';
import { loadUsuarios, selectObjectTodosUsuarios } from '../../usuarios/usuarios.redux';
import {
  loadRelatoriosOperacaoAutorizacoes,
  selectRelatoriosOperacaoAutorizacoesByFilters,
  selectRelatoriosOperacaoAutorizacoesLoadingStateByFilters,
  selectTotalRelatoriosOperacaoAutorizacoesByFilters,
} from './relatorio-operacao-autorizacoes.redux';

const smartFilters = (): SmartTableFilterProps[] => [
  {
    label: 'Código',
    attribute: 'codigo',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
  },
  {
    label: 'Data de início',
    attribute: 'dataInicio',
    controlType: SmartTableFilterControlTypesEnum.DATE_INPUT,
  },
  {
    label: 'Data final',
    attribute: 'dataFim',
    controlType: SmartTableFilterControlTypesEnum.DATE_INPUT,
  },
];

const smartColumns = ({ usuarios }: { usuarios: any }): SmartTableColumnProps[] => [
  {
    label: 'Código',
    attribute: 'codigo',
    className: 'text-center',
  },
  {
    label: 'Data de início',
    format: (_, doc) => <span>{formatData(doc.parametros.dataInicio)}</span>,
    className: 'text-center',
  },
  {
    label: 'Data final',
    format: (_, doc) => <span>{formatData(doc.parametros.dataFim)}</span>,
    className: 'text-center',
  },
  {
    label: 'Usuário',
    attribute: 'usuarioId',
    className: 'text-center',
    format(usuario) {
      if (!usuario) {
        return '-';
      }

      return usuarios[usuario] ? usuarios[usuario].username : '';
    },
  },
];

const RelatoriosOperacaoAutorizacoesPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const { query, maxItemsQuery } = useQuerystring();
  const navigate = useNavigate();

  const filters = useMemo(() => ({ ...query }), [query]);
  const total = useSelector((state) => selectTotalRelatoriosOperacaoAutorizacoesByFilters(state, filters));
  const relatoriosOperacao = useSelector((state) => selectRelatoriosOperacaoAutorizacoesByFilters(state, filters));
  const loadingState = useSelector((state) =>
    selectRelatoriosOperacaoAutorizacoesLoadingStateByFilters(state, filters)
  );
  const usuarios = useSelector((state) => selectObjectTodosUsuarios(state));

  const loadEntidadesComplementares = useCallback(
    (relatoriosAutorizacao: any[]) => {
      const usuarios = mapearERemoverElementosNulosERepetidos(relatoriosAutorizacao, 'usuarioId');

      dispatch(loadUsuarios({ query: { _id: { in: usuarios }, ...maxItemsQuery } })).catch((error: Error) => error);
    },
    [dispatch, maxItemsQuery]
  );

  const loadItems = useCallback(
    () =>
      dispatch(loadRelatoriosOperacaoAutorizacoes({ query: filters }))
        .then(({ payload: { data } }: ApiMultiElementResponse) => loadEntidadesComplementares(data))
        .catch((error: Error) => error),
    [dispatch, filters, loadEntidadesComplementares]
  );

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_RELATORIO_OPERACAO_AUTORIZACOES}>
      <div className="d-flex flex-column h-100">
        <Title>Relatórios de operação de autorizações</Title>

        <div className="row">
          <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-12 mb-4">
            <SmartTableFilters filters={smartFilters()} />
          </div>

          <div className="col-xxl-10 col-xl-9 col-lg-8 col-md-12 mb-4">
            <BSCard>
              <BSCard.Body>
                <SmartTable
                  emptyMessage="Nenhum relatório de operação de autorizações encontrado"
                  errorMessage="Erro na listagem de relatório de operação de autorizações"
                  usePagination={true}
                  loadItems={loadItems}
                  columns={smartColumns({ usuarios })}
                  items={relatoriosOperacao}
                  loadingState={loadingState}
                  size={total}
                  onRowClick={(doc) => navigate(`/relatorios-operacao/autorizacoes/${doc._id}`)}
                />
              </BSCard.Body>
            </BSCard>
          </div>
        </div>
      </div>
    </RbacPage>
  );
};

export default RelatoriosOperacaoAutorizacoesPage;

import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import { useCallback, useEffect } from 'react';
import BSButton from 'react-bootstrap/Button';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { DetailCard } from '../../components/details/datail-card';
import DetailSubTitle from '../../components/details/detail-subtitle';
import DetailTitle from '../../components/details/detail-title';
import { Form } from '../../components/form/form';
import { FormControl } from '../../components/form/form-control';
import { Loading } from '../../components/loading';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import { ClassTypesEnum } from '../../helpers';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import { loadPapelUsuario, selectPapelUsuarioById, updatePapelUsuario } from './papeis-usuarios.redux';
import PermissoesPapelUsuarioTabs, { PapelUsuarioFormFields } from './permissoes-papel-usuario-tabs';

const AtualizarPapelUsuarioPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const params = useParams();
  const papelUsuarioId = params.papelUsuarioId as string;
  const { showToast } = useToasts();

  const papelUsuario = useSelector((state) => selectPapelUsuarioById(state, papelUsuarioId));
  const loadingState = useSelector((state) => selectPapelUsuarioById(state, papelUsuarioId));

  const defaultValues: PapelUsuarioFormFields = {
    descricao: papelUsuario?.descricao,
    permissoes: papelUsuario?.permissoes,
  };

  const form = useForm<PapelUsuarioFormFields>({ defaultValues });
  const { control, watch } = form;

  const permissoes = watch('permissoes');

  const onSubmitHandler = useCallback(
    (data: PapelUsuarioFormFields) => {
      dispatch(updatePapelUsuario({ params: { papelUsuarioId }, data }))
        .then(() => {
          showToast({
            message: 'Papel de usuário alterado com sucesso',
            type: ClassTypesEnum.SUCCESS,
          });

          window.history.back();
        })
        .catch((error: Error) => {
          showToast({ message: error.message, type: ClassTypesEnum.DANGER });
        });
    },
    [dispatch, papelUsuarioId, showToast]
  );

  const _loadPapelUsuario = useCallback(
    () => dispatch(loadPapelUsuario({ papelUsuarioId })).catch((error: Error) => error),
    [papelUsuarioId, dispatch]
  );

  useEffect(() => {
    _loadPapelUsuario();
  }, [_loadPapelUsuario]);

  if (!papelUsuario) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Loading notFoundMessage="Papel de usuário não encontrado" loadingState={loadingState} />
      </div>
    );
  }

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.ALTERACAO_PAPEL_USUARIO}>
      <div className="mb-5">
        <DetailCard>
          <div className="mb-5">
            <DetailTitle>Editar papel de usuário</DetailTitle>
          </div>

          <Form form={form} onSubmit={onSubmitHandler} submitPristine>
            <FormControl
              control={control}
              className="mb-3"
              name="descricao"
              label="Descrição"
              rules={{ required: true }}
              placeholder="Administrador, Operador, etc."
            />
            <div className="mb-3">
              <DetailSubTitle>Permissões</DetailSubTitle>
            </div>

            <PermissoesPapelUsuarioTabs permissoes={permissoes} formControl={control} />

            <div className="d-flex justify-content-end">
              <div className="m-2">
                <BSButton variant="light" onClick={() => window.history.back()}>
                  Cancelar
                </BSButton>
              </div>

              <div className="m-2">
                <BSButton type="submit">Salvar</BSButton>
              </div>
            </div>
          </Form>
        </DetailCard>
      </div>
    </RbacPage>
  );
};

export default AtualizarPapelUsuarioPage;

import BSPagination from 'react-bootstrap/Pagination';
import { useEffect } from 'react';
import { limitadorContadorQuery } from '../smart-table.utils';

type PagesProps = {
  onChangePage: (page: number) => void;
  page: number;
  numPages: number;
  minimumSize: number;
  size: number;
};

type PageProps = {
  actualPage: number;
  numPages: number;
  numDisplayedPages: number;
  onChangePage: (page: number) => void;
  size: number;
};

const Pages: React.FC<PagesProps> = ({ onChangePage, page, numPages, size }) => {
  const numDisplayedPages = numPages > 5 ? 5 : numPages;

  useEffect(() => {
    const isInvalidPage = page < 1 || (numPages !== 0 && page > numPages);

    if (isInvalidPage) {
      onChangePage(1);
    }
  });

  const getPages = (actualPage: number, numPages: number): number[] => {
    let first = actualPage - Math.floor(numDisplayedPages / 2);
    const last = actualPage + Math.floor(numDisplayedPages / 2);

    if (last > numPages) {
      first -= last - numPages;
    }

    if (first <= 0) {
      first = 1;
    }

    let pages = Array.from({ length: numDisplayedPages }) as number[];

    pages = pages.map((_, i) => first + i);

    return pages;
  };

  const links = getPages(page, numPages);

  return (
    <BSPagination>
      <FirstPage
        actualPage={page}
        numPages={numPages}
        numDisplayedPages={numDisplayedPages}
        onChangePage={onChangePage}
        size={size}
      />
      <PreviousPage
        actualPage={page}
        numPages={numPages}
        numDisplayedPages={numDisplayedPages}
        onChangePage={onChangePage}
        size={size}
      />
      {links.map((link: number, index: number) => {
        return (
          <BSPagination.Item key={index} onClick={() => onChangePage(link)} active={link === page}>
            {link}
          </BSPagination.Item>
        );
      })}
      <NextPage
        actualPage={page}
        numPages={numPages}
        numDisplayedPages={numDisplayedPages}
        onChangePage={onChangePage}
        size={size}
      />
      <LastPage
        actualPage={page}
        numPages={numPages}
        numDisplayedPages={numDisplayedPages}
        onChangePage={onChangePage}
        size={size}
      />
    </BSPagination>
  );
};

const FirstPage: React.FC<PageProps> = ({ actualPage, numPages, numDisplayedPages, onChangePage }: PageProps) => {
  if (numPages <= numDisplayedPages || numPages === 0) {
    return <></>;
  }

  return (
    <BSPagination.First data-testid="pages" onClick={() => onChangePage(1)} disabled={actualPage === 1}>
      1°
    </BSPagination.First>
  );
};

const LastPage: React.FC<PageProps> = ({ actualPage, numPages, numDisplayedPages, onChangePage, size }: PageProps) => {
  if (numPages <= numDisplayedPages || numPages === 0 || size >= limitadorContadorQuery) {
    return <></>;
  }

  return (
    <BSPagination.Last onClick={() => onChangePage(numPages)} disabled={actualPage === numPages}>
      {numPages}°
    </BSPagination.Last>
  );
};

const PreviousPage: React.FC<PageProps> = ({ actualPage, numPages, onChangePage }: PageProps) => {
  return (
    <BSPagination.Prev onClick={() => onChangePage(actualPage - 1)} disabled={actualPage === 1 || numPages === 0} />
  );
};

const NextPage: React.FC<PageProps> = ({ actualPage, numPages, onChangePage, size }: PageProps) => {
  return (
    <>
      {size >= limitadorContadorQuery && <BSPagination.Ellipsis disabled />}

      <BSPagination.Next
        onClick={() => onChangePage(actualPage + 1)}
        disabled={actualPage === numPages || numPages === 0}
      />
    </>
  );
};

export default Pages;

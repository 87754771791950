import { reducerFactory, actionFactory, selectorFactory } from '../../store';

const context = 'solicitacoesCliente';

const { loadMany, loadOne, createOne } = actionFactory(context, {
  buildUrlCreateOne: () => '/api/solicitacoes-clientes',
  buildUrlLoadMany: () => '/api/solicitacoes-clientes',
  buildUrlLoadOne: ({ solicitacaoClienteId }) => `/api/solicitacoes-clientes/${solicitacaoClienteId}`,
});

const { selectOneById, selectAll, selectManyByFilters, selectTotalByFilters, selectLoadingStateByFilters } =
  selectorFactory({ context });

export const loadSolicitacaoCliente = loadOne;
export const loadSolicitacoesClientes = loadMany;
export const saveSolicitacaoCliente = createOne;

export const selectSolicitacaoClienteById = selectOneById;
export const selectSolicitacoesClientes = selectAll;
export const selectSolicitacoesClientesByFilters = selectManyByFilters;
export const selectTotalSolicitacoesClientesByFilters = selectTotalByFilters;
export const selectSolicitacaoClienteLoadingStateByFilters = selectLoadingStateByFilters;

export const solicitacoesCliente = reducerFactory({ context });

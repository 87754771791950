import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import { useCallback, useMemo } from 'react';
import BSCard from 'react-bootstrap/Card';
import { BsPencilSquare } from 'react-icons/bs';
import IconButton from '../../components/button/icon-button';
import RbacElement from '../../components/role-based-access-control/role-based-access-control-element';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import { SmartTable } from '../../components/smart-table/smart-table';
import SmartTableButton from '../../components/smart-table/smart-table-button';
import SmartTableFilters from '../../components/smart-table/smart-table-filters/smart-table-filters';
import {
  SmartTableFilterControlTypesEnum,
  SmartTableFilterProps,
} from '../../components/smart-table/smart-table-filters/table-filter.types';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import Title from '../../components/title';
import { ClassTypesEnum } from '../../helpers';
import { DialogProps } from '../../hooks/dialog/dialog-context';
import { useDialog } from '../../hooks/dialog/use-dialog';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import AlterarPrazoConfirmacaoMCCDialog from './alterar-prazo-confirmacao-autorizacao-mcc-dialog';
import CadastrarPrazoConfirmacaoMCCDialog from './cadastrar-prazo-confirmacao-autorizacao-mcc-dialog';
import {
  loadPrazosConfirmacaoAutorizacaoMCC,
  selectPrazosConfirmacaoAutorizacaoMCCByFilters,
  selectPrazosConfirmacaoAutorizacaoMCCLoadingStateByFilters,
  selectTotalPrazosConfirmacaoAutorizacaoMCCByFilters,
  updatePrazoConfirmacaoAutorizacaoMCC,
} from './prazos-confirmacao-autorizacao-mcc.redux';

const smartFilters = (): SmartTableFilterProps[] => [
  {
    label: 'MCC',
    attribute: 'mcc',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
    exactLength: 4,
  },
  {
    label: 'Categoria do negócio',
    attribute: 'nomeCategoriaNegocio',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
  },
];

const smartColumns = ({
  showDialog,
  closeDialog,
  onUpdatePrazoConfirmacaoAutorizacaoMCCHandler,
}: {
  showDialog: (dialogProps: DialogProps) => void;
  closeDialog: () => void;
  onUpdatePrazoConfirmacaoAutorizacaoMCCHandler: (itemDoacaoId: string, data: any) => void;
}): SmartTableColumnProps[] => [
  {
    label: 'MCC',
    attribute: 'mcc',
    sortable: true,
    className: 'text-center',
    format: (value) => {
      return value.toString().padStart(4, '0');
    },
  },
  {
    label: 'Nome da categoria do negócio',
    attribute: 'nomeCategoriaNegocio',
    sortable: true,
    className: 'text-center',
  },
  {
    label: 'Prazo em dias para confirmação',
    attribute: 'prazoEmDiasParaConfirmacao',
    sortable: true,
    className: 'text-center',
  },
  {
    label: 'Ações',
    format: (_, prazoConfirmacaoMCC) => {
      return (
        <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.ALTERACAO_PRAZO_CONFIRMACAO_AUTORIZACAO_MCC}>
          <div className="d-flex justify-content-center">
            <div className="mx-1">
              <IconButton
                Icon={BsPencilSquare}
                type={ClassTypesEnum.PRIMARY}
                onClick={() => {
                  showDialog({
                    component: (
                      <AlterarPrazoConfirmacaoMCCDialog
                        closeDialog={closeDialog}
                        prazoConfirmacaoMCC={prazoConfirmacaoMCC}
                        onUpdateHandler={onUpdatePrazoConfirmacaoAutorizacaoMCCHandler}
                      />
                    ),
                  });
                }}
              />
            </div>
          </div>
        </RbacElement>
      );
    },
  },
];

const PrazosConfirmacaoAutorizacaoMCCPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const { query } = useQuerystring();
  const { closeDialog, showDialog } = useDialog();
  const { showToast } = useToasts();

  if (!query.sort) {
    query.sort = '-data';
  }

  const filters = useMemo(() => ({ ...query }), [query]);
  const total = useSelector((state) => selectTotalPrazosConfirmacaoAutorizacaoMCCByFilters(state, filters));
  const prazosConfirmacaoAutorizacaoMCC = useSelector((state) =>
    selectPrazosConfirmacaoAutorizacaoMCCByFilters(state, filters)
  );
  const loadingState = useSelector((state) =>
    selectPrazosConfirmacaoAutorizacaoMCCLoadingStateByFilters(state, filters)
  );

  const loadItems = useCallback(
    () => dispatch(loadPrazosConfirmacaoAutorizacaoMCC({ query: filters })).catch((error: Error) => error),
    [dispatch, filters]
  );

  const onPrazoConfirmacaoAutorizacaoMCCHandler = () => {
    showDialog({
      component: <CadastrarPrazoConfirmacaoMCCDialog closeDialog={closeDialog} reload={loadItems} />,
    });
  };

  const onUpdatePrazoConfirmacaoAutorizacaoMCCHandler = (prazoConfirmacaoMccId: string, data: any) => {
    if (!data) {
      return;
    }

    dispatch(updatePrazoConfirmacaoAutorizacaoMCC({ params: { prazoConfirmacaoMccId }, data }))
      .then(() => {
        closeDialog();

        showToast({
          message: 'Prazo confirmação autorização MCC atualizado com sucesso',
          type: ClassTypesEnum.SUCCESS,
        });

        return loadItems();
      })
      .catch((error: Error) => {
        showToast({
          message: error.message,
          type: ClassTypesEnum.DANGER,
        });
      });
  };

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_PRAZO_CONFIRMACAO_AUTORIZACAO_MCC}>
      <div className="d-flex flex-column h-100">
        <Title>Prazos confirmação autorização por MCC</Title>

        <div className="row">
          <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-12 mb-4">
            <SmartTableFilters filters={smartFilters()} />
          </div>

          <div className="col-xxl-10 col-xl-9 col-lg-8 col-md-12 mb-4">
            <BSCard>
              <BSCard.Body>
                <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.CRIACAO_PRAZO_CONFIRMACAO_AUTORIZACAO_MCC}>
                  <div className="d-flex align-items-center mb-2">
                    <SmartTableButton onClick={onPrazoConfirmacaoAutorizacaoMCCHandler}>
                      Cadastrar novo prazo
                    </SmartTableButton>
                  </div>
                </RbacElement>
                <SmartTable
                  emptyMessage="Nenhum prazo de confirmação autorização por MCC encontrado"
                  errorMessage="Erro na listagem de prazos de confirmação por MCC"
                  usePagination={true}
                  loadItems={loadItems}
                  columns={smartColumns({
                    closeDialog,
                    showDialog,
                    onUpdatePrazoConfirmacaoAutorizacaoMCCHandler,
                  })}
                  items={prazosConfirmacaoAutorizacaoMCC}
                  loadingState={loadingState}
                  size={total}
                />
              </BSCard.Body>
            </BSCard>
          </div>
        </div>
      </div>
    </RbacPage>
  );
};

export default PrazosConfirmacaoAutorizacaoMCCPage;

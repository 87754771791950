import { dateToStr, getDiferencaDias } from '@tamborineapps/utils/src/date';
import BSBadge from 'react-bootstrap/Badge';
import { SmartTable } from '../../components/smart-table/smart-table';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import {
  ClassTypesEnum,
  FormatValueEnum,
  LoadingStateType,
  formatData,
  statusSolicitacaoClienteColorMap,
  statusSolicitacaoClienteLabelMap,
} from '../../helpers';

const smartColumns = ({ usuarios }: { usuarios: any }): SmartTableColumnProps[] => {
  return [
    {
      label: 'Número do Protocolo',
      attribute: 'numeroDoProtocolo',
      className: 'text-center align-middle',
      sortable: true,
    },
    {
      label: 'Data de abertura',
      attribute: 'dataAbertura',
      className: 'text-center align-middle',
      sortable: true,
      format: FormatValueEnum.DATE_TIME,
    },
    {
      label: 'Data final para resolução',
      attribute: 'dataFinalResolucao',
      className: 'text-center align-middle',
      format: formatarDataFinalResolucao,
      sortable: true,
    },
    {
      label: 'Data resolução',
      attribute: 'dataResolucao',
      className: 'text-center align-middle',
      sortable: true,
      format: FormatValueEnum.DATE_TIME,
    },
    {
      label: 'Usuário solicitação',
      attribute: 'usuarioSolicitacao',
      className: 'text-center align-middle',
      sortable: true,
      format: (usuarioSolicitacaoId: keyof typeof usuarios) => {
        return !usuarioSolicitacaoId ? '-' : usuarios[usuarioSolicitacaoId]?.username ?? '-';
      },
    },
    {
      label: 'Status',
      attribute: 'tipoStatus',
      className: 'text-center align-middle',
      sortable: true,
      format: (tipoStatus: any) => {
        const colorKey = tipoStatus as keyof typeof statusSolicitacaoClienteColorMap;

        return (
          <h5 className="m-0">
            <BSBadge pill bg={statusSolicitacaoClienteColorMap[colorKey]}>
              {statusSolicitacaoClienteLabelMap[tipoStatus as keyof typeof statusSolicitacaoClienteLabelMap]}
            </BSBadge>
          </h5>
        );
      },
    },
    {
      label: 'Tipo solicitação',
      attribute: 'tipoSolicitacao',
      className: 'text-center align-middle',
      sortable: true,
      format(tipoSolicitacao) {
        return `(${tipoSolicitacao.codigo}) ${tipoSolicitacao.descricao}`;
      },
    },
  ];
};

type SolicitacoesClienteSmartTableProps = {
  loadItems: () => void;
  loadingState?: LoadingStateType;
  size?: number;
  items?: any[];
  navigateTo: (doc: any) => void;
  usuarios?: any;
};

const SolicitacoesClienteSmartTable: React.FC<SolicitacoesClienteSmartTableProps> = ({
  loadItems,
  size,
  items,
  navigateTo,
  loadingState,
  usuarios,
}) => {
  return (
    <SmartTable
      emptyMessage="Nenhuma solicitação de cliente encontrada"
      errorMessage="Erro ao listar solicitações de cliente"
      loadItems={loadItems}
      usePagination={true}
      size={size}
      columns={smartColumns({ usuarios: usuarios ?? {} })}
      items={items}
      loadingState={loadingState}
      onRowClick={navigateTo}
    />
  );
};

const formatarDataFinalResolucao = (dataFinalResolucao: string, dados: any) => {
  if (!dataFinalResolucao) {
    return '-';
  }

  const dataFinalResolucaoStr = dateToStr(dataFinalResolucao);
  const hojeStr = dateToStr(new Date());
  const ehSolicitacaoVencida = hojeStr > dataFinalResolucaoStr;
  const isTipoStatusValid = dados.tipoStatus !== 'D' && dados.tipoStatus !== 'I';
  if (ehSolicitacaoVencida && isTipoStatusValid) {
    return (
      <h5 className="m-0">
        <BSBadge pill bg={ClassTypesEnum.DANGER}>
          {formatData(dataFinalResolucaoStr)}
        </BSBadge>
      </h5>
    );
  }

  const umDia = 1;
  const ehSolicitacaoPrestesAVencer =
    hojeStr === dataFinalResolucaoStr || getDiferencaDias(dataFinalResolucaoStr, hojeStr) === umDia;

  if (ehSolicitacaoPrestesAVencer && isTipoStatusValid) {
    return (
      <h5 className="m-0">
        <BSBadge pill bg={ClassTypesEnum.WARNING}>
          {formatData(dataFinalResolucaoStr)}
        </BSBadge>
      </h5>
    );
  }

  return formatData(dataFinalResolucaoStr);
};

export default SolicitacoesClienteSmartTable;

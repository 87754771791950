import { Route, Routes } from 'react-router-dom';
import ItensDoacaoPage from './itens-doacao-page';

const ItensDoacaoRoutes: React.FC = () => {
  return (
    <Routes>
      <Route index element={<ItensDoacaoPage />} />
    </Routes>
  );
};

export default ItensDoacaoRoutes;

import { useCallback, useEffect, useMemo } from 'react';
import BSAlert from 'react-bootstrap/Alert';
import BSBadge from 'react-bootstrap/Badge';
import BSButton from 'react-bootstrap/Button';
import BSTab from 'react-bootstrap/Tab';
import BSTabs from 'react-bootstrap/Tabs';
import { useParams } from 'react-router-dom';

import { AcaoPermissaoPapelUsuarioEnum, TipoCartaoEnum } from '@tamborineapps/lib-enums';
import { DetailCard } from '../../components/details/datail-card';
import DetailElement from '../../components/details/detail-element';
import DetailSubTitle from '../../components/details/detail-subtitle';
import DetailTitle from '../../components/details/detail-title';
import ConfirmationDialog from '../../components/dialog/confirmation-dialog';
import InfoDialog from '../../components/dialog/info-dialog';
import { Loading } from '../../components/loading';
import Page from '../../components/page';
import RbacElement from '../../components/role-based-access-control/role-based-access-control-element';
import {
  ApiSingleElementResponse,
  ClassTypesEnum,
  FormatValueEnum,
  SizesEnum,
  acaoEmbossingLabelMap,
  formaEnvioCartaoLabelMap,
  formatCartao,
  formatCpfCnpj,
  formatData,
  formatEnum,
  localEntregaCartaoLabelMap,
  sexoLabelMap,
  statusEmbossingLabelMap,
  tipoCartaoLabelMap,
  tipoTitularidadeCartaoLabelMap,
} from '../../helpers';
import { useDialog } from '../../hooks/dialog/use-dialog';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import IncluirBloqueioCartaoDialog from '../cartoes/incluir-bloqueio-cartao-dialog';
import CadastrarSolicitacaoClienteDialog from '../contas-cartao/cadastrar-solicitacao-cliente-dialog';
import { loadContaCartao, selectContaCartaoById } from '../contas-cartao/contas-cartao.redux';
import { selectTiposBloqueiosById } from '../tipos-bloqueio-cartao/tipos-bloqueio-cartao.redux';
import AlterarPermissoesCartaoDialog from './alterar-permissoes-cartao-dialog';
import {
  loadCartao,
  selectCartaoById,
  selectCartaoLoadingStateByFilters,
  updateBloqueioCartao,
  updateDesbloqueioCartao,
  updateNovaCartaSenha,
  updatePermissoesCartao,
  updateSolicitarNovaVia,
} from './cartoes.redux';
import HistoricoCartaoTab from './historico-cartao-tab';

const DetalhesCartaoPage = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const params = useParams();
  const cartaoId = params?.cartaoId as string;

  const { showToast } = useToasts();
  const { showDialog, closeDialog } = useDialog();

  const filters = useMemo(() => ({ cartaoId }), [cartaoId]);
  const cartao = useSelector((state) => selectCartaoById(state, cartaoId));
  const loadingState = useSelector((state) => selectCartaoLoadingStateByFilters(state, filters));
  const tipoBloqueioCartao = useSelector((state) => selectTiposBloqueiosById(state, cartao?.tipoBloqueio));
  const contaCartao = useSelector((state) => selectContaCartaoById(state, cartao?.contaCartao));

  const ehCartaoVirtual = cartao?.tipoCartao === TipoCartaoEnum.VIRTUAL;

  const _loadContaCartao = useCallback(
    (cartao: any) => {
      dispatch(loadContaCartao({ contaCartaoId: cartao.contaCartao })).catch((error: Error) => error);
    },
    [dispatch]
  );

  const _loadCartao = useCallback(
    () =>
      dispatch(loadCartao({ cartaoId }))
        .then(({ payload: { data } }: ApiSingleElementResponse) => _loadContaCartao(data))
        .catch((error: Error) => error),
    [cartaoId, dispatch, _loadContaCartao]
  );

  const handleError = (error: Error) => {
    showToast({
      message: error.message,
      type: ClassTypesEnum.DANGER,
    });
  };

  const onNovaCartaSenhaConfirmationHandler = (cartaoId: string) => {
    dispatch(updateNovaCartaSenha({ params: { cartaoId }, data: {} }))
      .then(() =>
        showToast({
          message: 'Solicitação de nova carta senha realizada com sucesso',
          type: ClassTypesEnum.SUCCESS,
        })
      )
      .catch(handleError);
  };

  const onNovaViaConfirmationHandler = (cartaoId: string) => {
    dispatch(updateSolicitarNovaVia({ params: { cartaoId }, data: { acaoEmbossing: 2 } }))
      .then(() =>
        showToast({
          message: 'Solicitação de nova via realizada com sucesso',
          type: ClassTypesEnum.SUCCESS,
        })
      )
      .catch(handleError);
  };

  const onAlterarPermissoesHandler = (cartaoId: string, data: any) => {
    dispatch(updatePermissoesCartao({ params: { cartaoId }, data }))
      .then(() => {
        showToast({
          message: 'Permissões alteradas com sucesso',
          type: ClassTypesEnum.SUCCESS,
        });

        closeDialog();
      })
      .catch(handleError);
  };

  const onBloqueioHandler = (cartaoId: string, data: any) => {
    if (!data) {
      return;
    }

    return dispatch(updateBloqueioCartao({ params: { cartaoId }, data }))
      .then(() => {
        showToast({
          message: 'Bloqueio alterado com sucesso',
          type: ClassTypesEnum.SUCCESS,
        });

        closeDialog();

        return _loadCartao();
      })
      .catch((error: Error) => {
        showToast({
          message: error.message,
          type: ClassTypesEnum.DANGER,
        });
      });
  };

  const onDesbloqueioHandler = (cartaoId: string) => {
    return dispatch(updateDesbloqueioCartao({ params: { cartaoId }, data: {} }))
      .then(() => {
        showToast({
          message: 'Desbloqueio com sucesso',
          type: ClassTypesEnum.SUCCESS,
        });

        closeDialog();

        return _loadCartao();
      })
      .catch((error: Error) => {
        showToast({
          message: error.message,
          type: ClassTypesEnum.DANGER,
        });
      });
  };

  const selectComponentDesbloqueio = ({ cartaoPossuiSegundaVia }: { cartaoPossuiSegundaVia: boolean }) => {
    if (cartaoPossuiSegundaVia) {
      return (
        <InfoDialog
          message="Não é possível remover bloqueios de cartões que possuem segunda-via"
          onHide={closeDialog}
        />
      );
    }

    return (
      <ConfirmationDialog
        onConfirmation={() => onDesbloqueioHandler(cartao._id)}
        message="Tem certeza que deseja remover o bloqueio do cartão?"
        confirmationLabel="Remover bloqueio"
        title="Remover bloqueio"
        onHide={closeDialog}
      />
    );
  };

  useEffect(() => {
    _loadCartao();
  }, [_loadCartao]);

  if (!cartao) {
    return <Loading notFoundMessage="Cartão não encontrado" loadingState={loadingState} />;
  }

  return (
    <Page>
      <div className="mb-5">
        <DetailCard>
          <div className="row">
            <div className="col-md-8 col-sm-12 mb-3">
              <DetailTitle>Cartão {formatCartao(cartao.numeroTruncado)}</DetailTitle>
            </div>

            <div className="col-md-4 col-sm-12 text-md-end text-sm-start mb-5">
              <h4 className="ms-4 mb-0">
                <BSBadge pill bg="primary">
                  Cartão {formatEnum(cartao.tipoCartao, tipoCartaoLabelMap)}
                </BSBadge>
              </h4>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-6 mb-4">
              <DetailElement
                descricao="Conta"
                valor={contaCartao?.numero}
                link={`/contas-cartao/${cartao.contaCartao}/`}
              />
              <DetailElement
                descricao="Titularidade"
                valor={cartao.titularidade}
                format={FormatValueEnum.ENUM}
                map={tipoTitularidadeCartaoLabelMap}
              />
              <DetailElement
                descricao="Segunda via do cartão"
                valor={cartao.ehSegundaVia}
                format={FormatValueEnum.BOOL}
              />
              {cartao?.substituidoPor && (
                <DetailElement
                  descricao="Substituto por solicitação de segunda via"
                  valor="Cartão anterior"
                  link={`/contas-cartao/${cartao.contaCartao}/cartoes/${cartao.substituidoPor}/`}
                />
              )}
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <DetailElement
                descricao="Data de emissão do cartão"
                valor={cartao.dataCriacao}
                format={FormatValueEnum.DATA}
              />
              <DetailElement
                descricao="Local entrega"
                valor={cartao.localEntrega}
                format={FormatValueEnum.ENUM}
                map={localEntregaCartaoLabelMap}
              />
              <DetailElement
                descricao="Forma de envio do cartão"
                valor={cartao.formaEnvio}
                format={FormatValueEnum.ENUM}
                map={formaEnvioCartaoLabelMap}
              />
              <DetailElement
                descricao="Senha atual foi enviada na carta de senhas"
                valor={cartao.foiEnviadoCartaSenha}
                format={FormatValueEnum.BOOL}
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <DetailElement
                descricao="Data de vencimento"
                valor={cartao.dataVencimento}
                format={FormatValueEnum.DATA}
              />
              <DetailElement
                descricao="Quantidade de tentativas com senha inválida"
                valor={cartao.quantidadeSenhaInvalida}
              />
              <DetailElement
                descricao="Quantidade de tentativas com CVC inválido"
                valor={cartao.quantidadeCVCInvalido}
              />
              <DetailElement
                descricao="Comunicado ao banco de dados do ABU/Mastercard"
                valor={cartao.foiComunicadoBandeira}
                format={FormatValueEnum.BOOL}
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="mb-3">
                <DetailSubTitle>Embossing</DetailSubTitle>
              </div>

              <DetailElement
                descricao="Status embossing"
                valor={cartao.statusEmbossing}
                format={FormatValueEnum.ENUM}
                map={statusEmbossingLabelMap}
              />
              <DetailElement
                descricao="Ação"
                valor={cartao.acaoEmbossing}
                format={FormatValueEnum.ENUM}
                map={acaoEmbossingLabelMap}
              />
              <DetailElement descricao="Nome embossing" valor={cartao.nomeEmbossing} />
              <DetailElement descricao="Data" valor={cartao.dataEmbossing} format={FormatValueEnum.DATA} />
              <DetailElement descricao="Conteúdo quarta linha" valor={cartao.conteudoQuartaLinhaEmbossing} />
              <DetailElement descricao="Nota" valor={cartao.notaEmbossing} />
              <DetailElement descricao="Modelo plástico" valor={cartao.modeloPlastico} />
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="mb-3">
                <DetailSubTitle>Informações do portador</DetailSubTitle>
              </div>

              <DetailElement descricao="CPF" valor={formatCpfCnpj(cartao.portadorCpf)} />
              <DetailElement descricao="Nome" valor={formatCpfCnpj(cartao.portadorNome)} />
              <DetailElement
                descricao="Sexo"
                valor={cartao.portadorSexo}
                format={FormatValueEnum.ENUM}
                map={sexoLabelMap}
              />
              <DetailElement
                descricao="Data de nascimento"
                valor={cartao.portadorDataNascimento}
                format={FormatValueEnum.DATA}
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="mb-3">
                <DetailSubTitle>Permissões</DetailSubTitle>
              </div>

              <DetailElement
                descricao="Permite compra online nacional"
                valor={cartao.permiteCompraOnlineNacional}
                format={FormatValueEnum.BOOL}
              />
              <DetailElement
                descricao="Permite compra online internacional"
                valor={cartao.permiteCompraOnlineInternacional}
                format={FormatValueEnum.BOOL}
              />
              <DetailElement
                descricao="Permite compra internacional"
                valor={cartao.permiteCompraInternacional}
                format={FormatValueEnum.BOOL}
              />
              <DetailElement
                descricao="Permite compra contactless"
                valor={cartao.permiteCompraContactless}
                format={FormatValueEnum.BOOL}
              />
              <DetailElement
                descricao="Permite overlimit"
                valor={cartao.permiteUsarOverlimit}
                format={FormatValueEnum.BOOL}
              />
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <div className="mb-3">
                <DetailSubTitle>Doações</DetailSubTitle>
              </div>

              <DetailElement
                descricao="Permite doação por arredondamento da compra"
                valor={cartao.permiteDoacaoArredondamentoCompra}
                format={FormatValueEnum.BOOL}
              />
              <DetailElement descricao="Tipo arredondamento" valor={cartao.tipoArredondamentoDoacaoCompra} />
            </div>

            {ehCartaoVirtual && (
              <div className="col-lg-4 col-md-6 mb-4">
                <div className="mb-3">
                  <DetailSubTitle>Parâmetros</DetailSubTitle>
                </div>

                <DetailElement descricao="Apelido" valor={cartao.apelido} />
                <DetailElement
                  descricao="Cadastrado em card on file"
                  valor={cartao.cardOnFile}
                  format={FormatValueEnum.BOOL}
                />
                <DetailElement
                  descricao="Data última visualização"
                  valor={cartao.dataHoraUltimaVisualizacao}
                  format={FormatValueEnum.DATE_TIME}
                />
                <DetailElement descricao="Nome do lojista" valor={cartao.nomeLojista} />
                <DetailElement
                  descricao="Lojistas não autorizados"
                  valor={cartao.lojistasNaoAutorizados
                    ?.map(({ nomeLojista }: { nomeLojista: string }) => nomeLojista)
                    ?.join(', ')}
                />
              </div>
            )}
          </div>

          {cartao?.tipoBloqueio && (
            <div className="row">
              <div className="col-lg-4 col-md-6 mb-4">
                <BSAlert variant="danger">
                  <DetailElement
                    className="mb-0"
                    descricao="Bloqueio"
                    valor={`${tipoBloqueioCartao?.descricao} - incluído em ${formatData(cartao.dataBloqueio)}`}
                    link={`/tipos-bloqueio-cartao/${cartao.tipoBloqueio}`}
                  />
                </BSAlert>
              </div>
            </div>
          )}

          <div className="row">
            <div className="col-12 d-flex justify-content-end flex-wrap">
              {cartao.tipoBloqueio && (
                <div className="m-1">
                  <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.REMOCAO_BLOQUEIO_CARTAO}>
                    <BSButton
                      onClick={() => {
                        showDialog({
                          component: selectComponentDesbloqueio({ cartaoPossuiSegundaVia: cartao.ehSegundaVia }),
                        });
                      }}
                    >
                      Desbloquear
                    </BSButton>
                  </RbacElement>
                </div>
              )}

              {!cartao.tipoBloqueio && (
                <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.INCLUSAO_BLOQUEIO_CARTAO}>
                  <div className="m-1">
                    <BSButton
                      onClick={() => {
                        showDialog({
                          component: (
                            <IncluirBloqueioCartaoDialog
                              cartao={cartao}
                              closeDialog={closeDialog}
                              onUpsertHandler={onBloqueioHandler}
                            />
                          ),
                        });
                      }}
                    >
                      Bloquear
                    </BSButton>
                  </div>
                </RbacElement>
              )}

              <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.ALTERACAO_PERMISSOES_CARTAO}>
                <div className="m-1">
                  <BSButton
                    onClick={() => {
                      showDialog({
                        component: (
                          <AlterarPermissoesCartaoDialog
                            cartao={cartao}
                            closeDialog={closeDialog}
                            onSubmitHandler={onAlterarPermissoesHandler}
                          />
                        ),
                      });
                    }}
                  >
                    Alterar permissões
                  </BSButton>
                </div>
              </RbacElement>

              <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.SOLICITACAO_SEGUNDA_VIA_CARTAO}>
                <div className="m-1">
                  <BSButton
                    disabled={ehCartaoVirtual}
                    onClick={() =>
                      showDialog({
                        component: (
                          <ConfirmationDialog
                            onConfirmation={() => onNovaViaConfirmationHandler(cartao._id)}
                            onHide={closeDialog}
                            title="Nova via de cartão"
                            message="Tem certeza que deseja solicitar uma nova via de cartão?"
                            confirmationLabel="Solicitar nova via"
                          />
                        ),
                      })
                    }
                  >
                    Nova via
                  </BSButton>
                </div>
              </RbacElement>

              <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.SOLICITACAO_CARTA_SENHA_CARTAO}>
                <div className="m-1">
                  <BSButton
                    disabled={ehCartaoVirtual}
                    onClick={() =>
                      showDialog({
                        component: (
                          <ConfirmationDialog
                            onConfirmation={() => onNovaCartaSenhaConfirmationHandler(cartao._id)}
                            onHide={closeDialog}
                            title="Carta senha de cartão"
                            message="Tem certeza que deseja solicitar uma carta senha de cartão?"
                            confirmationLabel="Solicitar carta senha"
                          />
                        ),
                      })
                    }
                  >
                    Nova carta senha
                  </BSButton>
                </div>
              </RbacElement>

              {contaCartao && (
                <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.CRIACAO_SOLICITACAO_CLIENTE}>
                  <div className="m-1">
                    <BSButton
                      onClick={() => {
                        showDialog({
                          component: (
                            <CadastrarSolicitacaoClienteDialog
                              closeDialog={closeDialog}
                              contaCartaoId={contaCartao._id}
                              transacoes={[]}
                            />
                          ),
                          size: SizesEnum.EXTRA_LARGE,
                        });
                      }}
                    >
                      Abrir solicitação
                    </BSButton>
                  </div>
                </RbacElement>
              )}
            </div>
          </div>
        </DetailCard>
      </div>

      <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_HISTORICO_CARTAO}>
        <DetailCard>
          <BSTabs defaultActiveKey="historico" fill unmountOnExit>
            <BSTab eventKey="historico" title="Histórico">
              {Boolean(cartao && contaCartao) && (
                <HistoricoCartaoTab cartaoId={cartao._id} contaCartaoId={contaCartao._id} />
              )}
            </BSTab>
          </BSTabs>
        </DetailCard>
      </RbacElement>
    </Page>
  );
};

export default DetalhesCartaoPage;

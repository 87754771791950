import { reducerFactory, actionFactory, selectorFactory } from '../../store';

const context = 'saldosFatura';

const { loadOne } = actionFactory(context, {
  buildUrlLoadOne: ({ faturaId }) => `/api/faturas/${faturaId}/saldos`,
});

const { selectOneById } = selectorFactory({ context });

export const loadSaldosFatura = loadOne;

export const selectSaldosFaturaById = selectOneById;

export const saldosFatura = reducerFactory({ context });

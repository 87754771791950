import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useSearchParams } from 'react-router-dom';
import { BsX } from 'react-icons/bs';
import React, { useEffect, useState } from 'react';
import { SmartTableFilterComponentProps } from './table-filter.types';

const SearchRadioFilter: React.FC<SmartTableFilterComponentProps> = ({
  filter,
  removeAttributeFromSearchParams,
  upsertAttributeInSearchParams,
}) => {
  const [searchParams] = useSearchParams();
  const [formControlValue, setFormControlValue] = useState<string>('');
  const { attribute, label, className } = filter;

  const searchAttribute = `${attribute}[selectBool]`;

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target as HTMLInputElement;

    setFormControlValue(value);

    return upsertAttributeInSearchParams(searchAttribute, value);
  };

  const clearFilterHandler = () => {
    setFormControlValue('');

    removeAttributeFromSearchParams(searchAttribute);
  };

  useEffect(() => {
    const value = searchParams.get(searchAttribute);
    setFormControlValue(value ?? '');
  }, [searchAttribute, searchParams]);

  return (
    <div data-testid="search-radio-filter" className={className ?? 'mb-3'}>
      <div className="mb-2">
        <span className="mb-2">{label}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <div className="py-1">
          <Form.Check
            data-testid="checkbox-sim"
            inline
            label="Sim"
            value="true"
            name="boolRadio"
            type="radio"
            checked={formControlValue === 'true'}
            onChange={onChangeHandler}
          />
          <Form.Check
            data-testid="checkbox-nao"
            inline
            label="Não"
            value="false"
            name="boolRadio"
            checked={formControlValue === 'false'}
            type="radio"
            onChange={onChangeHandler}
          />
        </div>

        {searchParams.get(searchAttribute) && (
          <Button size="sm" variant="outline-primary" onClick={clearFilterHandler}>
            <BsX size={20} />
          </Button>
        )}
      </div>
    </div>
  );
};

export default SearchRadioFilter;

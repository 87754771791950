import qs from 'qs';
import BSCard from 'react-bootstrap/Card';
import { useForm } from 'react-hook-form';
import { BsSearch } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import IconButton from '../../components/button/icon-button';
import { Form } from '../../components/form/form';
import { FormControl } from '../../components/form/form-control';
import { ClassTypesEnum, SizesEnum } from '../../helpers';
import classes from './search-box-card.module.scss';

type SearchBoxFormFields = {
  search: string;
};

const SearchBoxCard: React.FC = () => {
  const navigate = useNavigate();

  const form = useForm<SearchBoxFormFields>();
  const { control } = form;

  const onSubmitHandler = (data: any) => {
    const { search } = data;

    if (search) {
      const query = qs.stringify({ cliente: { search: search.toString() } });

      navigate(`/contas-cartao?${query}`);
    }
  };

  return (
    <BSCard className="px-3 py-2">
      <Form form={form} onSubmit={onSubmitHandler}>
        <div className="d-flex align-items-center">
          <div className="flex-grow-1 me-4">
            <FormControl
              control={control}
              className={classes.input}
              name="search"
              placeholder="CPF/CNPJ, nome, email ou código do cliente"
            />
          </div>

          <button className={classes['submit-btn']} type="submit">
            <IconButton
              Icon={BsSearch}
              size={SizesEnum.MEDIUM}
              type={ClassTypesEnum.PRIMARY}
              preventClickEventDefaults={false}
            />
          </button>
        </div>
      </Form>
    </BSCard>
  );
};

export default SearchBoxCard;

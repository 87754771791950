import { reducerFactory, actionFactory, selectorFactory } from '../../store';

const context = 'usuarios';

const {
  createOne,
  loadMany,
  loadOne,
  updateOne: updateOneSenha,
} = actionFactory(context, {
  buildUrlCreateOne: () => 'api/usuarios',
  buildUrlLoadMany: () => '/api/usuarios',
  buildUrlLoadOne: ({ usuarioId }) => `/api/usuarios/${usuarioId}`,
  buildUrlUpdateOne: ({ usuarioId }) => `/api/usuarios/${usuarioId}/senha`,
});

const { updateOne: updateOnePapelUsuario, loadOne: loadOneSession } = actionFactory(context, {
  buildUrlUpdateOne: ({ usuarioId }) => `/api/usuarios/${usuarioId}/papel-usuario`,
  buildUrlLoadOne: ({ sessionId }) => `/api/session/${sessionId}/usuario`,
});

const { updateOne: updateOneStatusUsuario } = actionFactory(context, {
  buildUrlUpdateOne: ({ usuarioId }) => `/api/usuarios/${usuarioId}/status`,
});

const { selectManyByFilters, selectLoadingStateByFilters, selectObjectAllIds, selectOneById, selectTotalByFilters } =
  selectorFactory({
    context,
  });

export const saveUsuario = createOne;
export const updateUsuarioSenha = updateOneSenha;
export const updateUsuarioPapelUsuario = updateOnePapelUsuario;
export const updateUsuarioStatus = updateOneStatusUsuario;

export const loadUsuario = loadOne;
export const loadUsuarioPorSession = loadOneSession;
export const loadUsuarios = loadMany;

export const selectObjectTodosUsuarios = selectObjectAllIds;
export const selectUsuarios = selectManyByFilters;
export const selectTotalUsuariosByFilters = selectTotalByFilters;
export const selectUsuariosById = selectOneById;
export const selectLoadingStateByFiltersUsuarios = selectLoadingStateByFilters;

export const usuarios = reducerFactory({ context });

import { useCallback } from 'react';
import BSButton from 'react-bootstrap/Button';
import BSModal from 'react-bootstrap/Modal';

import { useForm } from 'react-hook-form';
import { Form } from '../../components/form/form';
import { FormCheck } from '../../components/form/form-check';
import { FormControl } from '../../components/form/form-control';
import { ClassTypesEnum } from '../../helpers';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch } from '../../store/hooks-redux';

import { createPerguntaSeguranca } from './perguntas-seguranca.redux';

type CadastrarPerguntaSegurancaFormFields = {
  ativaParaNovoUsuarios: boolean;
  titulo: string;
};

type CadastrarPerguntaSegurancaProps = {
  closeDialog: () => void;
  reload: () => void;
};

const CadastrarPerguntaSegurancaDialog: React.FC<CadastrarPerguntaSegurancaProps> = ({ closeDialog, reload }) => {
  const dispatch = useAppDispatch();
  const { showToast } = useToasts();

  const form = useForm<CadastrarPerguntaSegurancaFormFields>();
  const { control } = form;

  const onSubmitHandler = useCallback(
    (data: CadastrarPerguntaSegurancaFormFields) => {
      dispatch(createPerguntaSeguranca({ data }))
        .then(() => {
          closeDialog();
          showToast({
            message: 'Pergunta de segurança cadastrada com sucesso',
            type: ClassTypesEnum.SUCCESS,
          });
          reload();
        })
        .catch((error: Error) => {
          showToast({ message: error.message, type: ClassTypesEnum.DANGER });
        });
    },
    [closeDialog, dispatch, reload, showToast]
  );

  return (
    <>
      <BSModal.Header closeButton>
        <BSModal.Title>Nova pergunta de segurança</BSModal.Title>
      </BSModal.Header>
      <BSModal.Body>
        <Form id="form" form={form} onSubmit={onSubmitHandler}>
          <div className="row">
            <FormControl control={control} className="mb-3" label="Título" name="titulo" rules={{ required: true }} />
            <FormCheck
              control={control}
              className="mb-3"
              name="ativaParaNovoUsuarios"
              label="Ativa para novos usuários"
              type="switch"
            />
          </div>
        </Form>
      </BSModal.Body>
      <BSModal.Footer>
        <BSButton variant="light" onClick={closeDialog}>
          Fechar
        </BSButton>
        <BSButton variant="primary" type="submit" form="form">
          Cadastrar
        </BSButton>
      </BSModal.Footer>
    </>
  );
};

export default CadastrarPerguntaSegurancaDialog;

import { Route, Routes } from 'react-router-dom';
import DetalhesBoletoBancarioPage from '../boletos-bancarios/detalhes-boleto-bancario-page';
import DetalhesCartaoPage from '../cartoes/detalhes-cartao-page';
import DetalhesDoacaoCartaoPage from '../doacoes-cartao/detalhes-doacoes-cartao-page';
import DetalhesFaturaPage from '../faturas/detalhes-fatura-page';
import DetalhesHistoricoCartaoPage from '../historicos-cartao/detalhes-historico-cartao-page';
import DetalhesHistoricoContaCartaoPage from '../historicos-contas-cartao/detalhes-historico-conta-cartao-page';
import DetalhesPedidoAutorizacaoPage from '../pedidos-autorizacao/detalhes-pedido-autorizacao-page';
import DetalhesSolicitacaoClientePage from '../solicitacoes-cliente/detalhes-solicitacao-cliente-page';
import DetalhesSolicitacaoParcelamentoPage from '../solicitacoes-parcelamento/detalhes-solicitacao-parcelamento-page';
import SimulacaoParcelamentoFaturaPage from '../solicitacoes-parcelamento/simulacao-parcelamento-fatura-page';
import DetalhesTransacaoPage from '../transacoes/detalhes-transacao-page';
import SimulacaoAntecipacaoParcelasPage from '../transacoes/simulacao-antecipacao-parcelas-page';
import ContasCartaoPage from './contas-cartao-page';
import DetalhesContaCartaoPage from './detalhes-conta-cartao-page';
import DetalhesTipoBloqueioContaCartaoPage from '../tipos-bloqueio-conta-cartao/detalhes-tipo-bloqueio-conta-cartao-page';
import DetalhesProtocoloProcessamentoContaPage from '../protocolo-processamento-conta/detalhes-processamento-conta-page';

const ContasCartaoRoutes: React.FC = () => {
  return (
    <Routes>
      <Route index element={<ContasCartaoPage />} />
      <Route path=":contaCartaoId" element={<DetalhesContaCartaoPage />} />
      <Route path=":contaCartaoId/cartoes/:cartaoId" element={<DetalhesCartaoPage />} />

      <Route path=":contaCartaoId/doacoes-cartao/:doacaoCartaoId" element={<DetalhesDoacaoCartaoPage />} />
      <Route
        path=":contaCartaoId/faturas/:faturaId/doacoes-cartao/:doacaoCartaoId"
        element={<DetalhesDoacaoCartaoPage />}
      />

      <Route
        path=":contaCartaoId/cartoes/:cartaoId/historicos/:historicoCartaoId"
        element={<DetalhesHistoricoCartaoPage />}
      />
      <Route path=":contaCartaoId/faturas/:faturaId" element={<DetalhesFaturaPage />} />
      <Route path=":contaCartaoId/faturas/:faturaId/transacoes/:transacaoId" element={<DetalhesTransacaoPage />} />
      <Route path=":contaCartaoId/transacoes/:transacaoId" element={<DetalhesTransacaoPage />} />
      <Route
        path=":contaCartaoId/pedidos-autorizacao/:pedidoAutorizacaoId"
        element={<DetalhesPedidoAutorizacaoPage />}
      />
      <Route
        path=":contaCartaoId/faturas/:faturaId/transacoes/:transacaoId/pedidos-autorizacao/:pedidoAutorizacaoId"
        element={<DetalhesPedidoAutorizacaoPage />}
      />
      <Route
        path=":contaCartaoId/faturas/:faturaId/boletos-bancarios/:boletoBancarioId"
        element={<DetalhesBoletoBancarioPage />}
      />
      <Route
        path=":contaCartaoId/faturas/:faturaId/simulacao-parcelamento-fatura"
        element={<SimulacaoParcelamentoFaturaPage />}
      />
      <Route
        path=":contaCartaoId/faturas/:faturaId/solicitacoes-parcelamento/:solicitacaoParcelamentoId"
        element={<DetalhesSolicitacaoParcelamentoPage />}
      />
      <Route
        path=":contaCartaoId/faturas/:faturaId/transacoes/:transacaoId/antecipacao-parcelas"
        element={<SimulacaoAntecipacaoParcelasPage />}
      />
      <Route path=":contaCartaoId/historicos/:historicoContaCartaoId" element={<DetalhesHistoricoContaCartaoPage />} />
      <Route
        path=":contaCartaoId/solicitacoes-cliente/:solicitacaoClienteId"
        element={<DetalhesSolicitacaoClientePage />}
      />
      <Route
        path=":contaCartaoId/tipos-bloqueio-conta/:tipoBloqueioContaCartaoId"
        element={<DetalhesTipoBloqueioContaCartaoPage />}
      />
      <Route
        path=":contaCartaoId/protocolos-processamento-conta/:protocoloProcessamentoContaId"
        element={<DetalhesProtocoloProcessamentoContaPage />}
      />
    </Routes>
  );
};

export default ContasCartaoRoutes;

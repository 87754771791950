import { combineReducers } from '@reduxjs/toolkit';
import { boletosBancarios } from '../pages/boletos-bancarios/boletos-bancario.redux';
import { calendariosCorte } from '../pages/calendario-corte/calendarios-corte.redux';
import { cartoes } from '../pages/cartoes/cartoes.redux';
import { categoriasBeneficio } from '../pages/categorias-beneficio/categorias.beneficio.redux';
import { clientesPreAprovadosProduto } from '../pages/cliente-pre-aprovado-produto/cliente-pre-aprovado.redux';
import { clientes } from '../pages/clientes/clientes.redux';
import { contasBancarias } from '../pages/contas-bancarias/contas-bancarias.redux';
import { contasCartao } from '../pages/contas-cartao/contas-cartao.redux';
import { diasAtrasoContaCartao } from '../pages/contas-cartao/dias-atraso-conta-cartao.redux';
import { saldoTotalDevedorContaCartao } from '../pages/contas-cartao/saldo-total-devedor-conta-cartao.redux';
import { contratosTermosUso } from '../pages/contratos-termos-uso/contratos-termos-uso.redux';
import { credenciaisCliente } from '../pages/credenciais-cliente/credenciais-cliente.redux';
import { doacoesCartao } from '../pages/doacoes-cartao/doacoes-cartao.redux';
import { embossingCartoes } from '../pages/embossing-cartao/embossing-cartoes.redux';
import { emissor } from '../pages/emissor/emissor.redux';
import { enderecos } from '../pages/enderecos/enderecos.redux';
import { faixasMcc } from '../pages/faixas-mcc/faixas-mcc.redux';
import { faturas } from '../pages/faturas/faturas.redux';
import { saldosFatura } from '../pages/faturas/saldos-fatura.redux';
import { feriados } from '../pages/feriados/feriados.redux';
import { gruposCredenciaisCliente } from '../pages/grupo-credenciais-cliente/grupo-credenciais-cliente.redux';
import { historicosUsuario } from '../pages/historicos-atividade-usuarios/historico-atividade-usuario.redux';
import { historicosAtividadesExternas } from '../pages/historicos-atividades-externas/historico-atividade-externa.redux';
import { historicosCartao } from '../pages/historicos-cartao/historicos-cartao.redux';
import { historicosNoDiaCartao } from '../pages/historicos-cartao/recuperar-historico-dia.redux';
import { historicosNoDiaCliente } from '../pages/historicos-cliente/historico-cliente-dia.redux';
import { historicosCliente } from '../pages/historicos-cliente/historico-cliente.redux';
import { historicosContasCartao } from '../pages/historicos-contas-cartao/historico-conta-cartao.redux';
import { historicosNoDiaContaCartao } from '../pages/historicos-contas-cartao/recuperar-historico-dia.redux';
import { itensDoacao } from '../pages/item-doacao/itens-doacao.redux';
import { lancamentosContabeis } from '../pages/lancamentos-contabeis/lancamento-contabil.redux';
import { geracaoArquivo } from '../pages/operacao/gerar-arquivo.redux';
import { geracaoRelatorioOperacao } from '../pages/operacao/gerar-relatorio-operacao.redux';
import { importarArquivo } from '../pages/operacao/importar-arquivo.redux';
import { rotinaPeriodica } from '../pages/operacao/rotina-periodica.redux';
import { simuladorJurosRotativo } from '../pages/operacao/simulador-juros-rotativo.redux';
import { papeisUsuarios } from '../pages/papeis-usuarios/papeis-usuarios.redux';
import { parametrosGeracaoBoleto } from '../pages/parametros-geracao-boleto/parametros-geracao-boleto.redux';
import { parametrosGeracaoCartao } from '../pages/parametros-geracao-cartao/parametros-geracao-cartao.redux';
import { pedidosAutorizacao } from '../pages/pedidos-autorizacao/pedidos-autorizacao.redux';
import { perguntasFrequentes } from '../pages/perguntas-frequentes/perguntas-frequentes.redux';
import { perguntasSeguranca } from '../pages/perguntas-seguranca/perguntas-seguranca.redux';
import { prazosConfirmacaoAutorizacaoMCC } from '../pages/prazos-confirmacao-autorizacao-mcc/prazos-confirmacao-autorizacao-mcc.redux';
import { processoAlteracaoDadosUsuarioCliente } from '../pages/processo-alteracao-dados-usuario-cliente/processo-alteracao-dados-usuario-cliente.redux';
import { produtos } from '../pages/produtos/produtos.redux';
import { subBinsProduto } from '../pages/produtos/sub-bins/sub-bins.redux';
import { protocolosProcessamentoConta } from '../pages/protocolo-processamento-conta/protocolo-processamento-conta.redux';
import { protocolosProcessamentoLote } from '../pages/protocolo-processamento-lote/protocolo-processamento-lote.redux';
import { qmr } from '../pages/qmr/qmr.redux';
import { relatoriosOperacaoAutorizacoes } from '../pages/relatorio-operacao/autorizacoes/relatorio-operacao-autorizacoes.redux';
import { roteirosContabeis } from '../pages/roteiros-contabeis/roteiro-contabil.redux';
import { sequenciais } from '../pages/sequenciais/sequenciais.redux';
import { sessionUsuario } from '../pages/session-usuario/session-usuario.redux';
import { solicitacaoAberturaConta } from '../pages/solicitacao-abertura-conta/solicitacao-abertura-conta.redux';
import { interacaoSolicitacaoCliente } from '../pages/solicitacoes-cliente/interacao-solicitacao-cliente.redux';
import { protocoloSolicitacaoCliente } from '../pages/solicitacoes-cliente/protocolo-solicitacao-cliente.redux';
import { solicitacoesCliente } from '../pages/solicitacoes-cliente/solicitacao-cliente.redux';
import { simuladorParcelamentoFatura } from '../pages/solicitacoes-parcelamento/simulador-parcelamento-fatura.redux';
import { solicitacoesParcelamento } from '../pages/solicitacoes-parcelamento/solicitacoes-parcelamento.redux';
import { tarifas } from '../pages/tarifas/tarifas.redux';
import { taxasCambio } from '../pages/taxa-cambio/taxas-cambio.redux';
import { taxasMediasSelic } from '../pages/taxa-media-selic/taxas-medias-selic.redux';
import { telefones } from '../pages/telefones/telefones.redux';
import { tiposBloqueioCartao } from '../pages/tipos-bloqueio-cartao/tipos-bloqueio-cartao.redux';
import { tiposBloqueioContaCartao } from '../pages/tipos-bloqueio-conta-cartao/tipos-bloqueio-conta-cartao.redux';
import { tiposSolicitacaoCliente } from '../pages/tipos-solicitacao-cliente/tipo-solicitacao-cliente.redux';
import { tiposTransacao } from '../pages/tipos-transacao/tipos-transacao.redux';
import { simuladorAntecipacaoParcelas } from '../pages/transacoes/simulador-antecipacao-parcelas.redux';
import { transacoes } from '../pages/transacoes/transacoes.redux';
import { usuarioCliente } from '../pages/usuarios-clientes/usuario-cliente.redux';
import { usuarios } from '../pages/usuarios/usuarios.redux';

export const rootReducer = combineReducers({
  boletosBancarios,
  calendariosCorte,
  cartoes,
  categoriasBeneficio,
  clientes,
  clientesPreAprovadosProduto,
  contasBancarias,
  contasCartao,
  contratosTermosUso,
  credenciaisCliente,
  diasAtrasoContaCartao,
  doacoesCartao,
  embossingCartoes,
  emissor,
  enderecos,
  faixasMcc,
  faturas,
  feriados,
  gruposCredenciaisCliente,
  geracaoArquivo,
  geracaoRelatorioOperacao,
  historicosAtividadesExternas,
  historicosCartao,
  historicosCliente,
  historicosContasCartao,
  historicosNoDiaCartao,
  historicosNoDiaCliente,
  historicosNoDiaContaCartao,
  historicosUsuario,
  importarArquivo,
  interacaoSolicitacaoCliente,
  itensDoacao,
  lancamentosContabeis,
  papeisUsuarios,
  parametrosGeracaoBoleto,
  parametrosGeracaoCartao,
  pedidosAutorizacao,
  perguntasFrequentes,
  perguntasSeguranca,
  prazosConfirmacaoAutorizacaoMCC,
  processoAlteracaoDadosUsuarioCliente,
  produtos,
  protocoloSolicitacaoCliente,
  protocolosProcessamentoConta,
  protocolosProcessamentoLote,
  qmr,
  relatoriosOperacaoAutorizacoes,
  roteirosContabeis,
  rotinaPeriodica,
  saldoTotalDevedorContaCartao,
  saldosFatura,
  sequenciais,
  sessionUsuario,
  simuladorAntecipacaoParcelas,
  simuladorJurosRotativo,
  simuladorParcelamentoFatura,
  solicitacaoAberturaConta,
  solicitacoesCliente,
  solicitacoesParcelamento,
  subBinsProduto,
  tarifas,
  taxasCambio,
  taxasMediasSelic,
  telefones,
  tiposBloqueioCartao,
  tiposBloqueioContaCartao,
  tiposSolicitacaoCliente,
  tiposTransacao,
  transacoes,
  usuarioCliente,
  usuarios,
});

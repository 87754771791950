import { reducerFactory, actionFactory, selectorFactory } from '../../store';

const context = 'telefones';

const { loadMany: loadManyCliente } = actionFactory(context, {
  buildUrlLoadMany: ({ clienteId }) => `/api/clientes/${clienteId}/telefones`,
});

const { selectManyByFilters, selectMapAllByAttribute } = selectorFactory({
  context,
});

export const loadTelefonesCliente = loadManyCliente;

export const selectMapTodosTelefonesPorAtributo = selectMapAllByAttribute;
export const selectTelefonesByFilters = selectManyByFilters;

export const telefones = reducerFactory({ context });

import { reducerFactory, actionFactory, selectorFactory } from '../../store';

const context = 'pedidosAutorizacao';

const { loadMany, loadOne, updateOne } = actionFactory(context, {
  buildUrlLoadMany: () => '/api/pedidos-autorizacao',
  buildUrlLoadOne: ({ pedidoAutorizacaoId }) => `/api/pedidos-autorizacao/${pedidoAutorizacaoId}`,
  buildUrlUpdateOne: ({ pedidoAutorizacaoId }) => `/api/pedidos-autorizacao/${pedidoAutorizacaoId}/confirmar`,
});

const { loadMany: loadManyPedidosAutorizacaoContaCartao } = actionFactory(context, {
  buildUrlLoadMany: ({ contaCartaoId }) => `/api/contas-cartao/${contaCartaoId}/pedidos-autorizacao`,
});

const { selectManyByFilters, selectLoadingStateByFilters, selectTotalByFilters, selectOneById } = selectorFactory({
  context,
});

export const confirmarPedidoAutorizacao = updateOne;

export const loadPedidoAutorizacao = loadOne;
export const loadPedidosAutorizacao = loadMany;
export const loadPedidosAutorizacaoContaCartao = loadManyPedidosAutorizacaoContaCartao;

export const selectPedidosAutorizacaoByFilters = selectManyByFilters;
export const selectTotalPedidosAutorizacaoByFilters = selectTotalByFilters;
export const selectPedidoAutorizacaoById = selectOneById;
export const selectPedidosAutorizacaoLoadingStateByFilters = selectLoadingStateByFilters;

export const pedidosAutorizacao = reducerFactory({ context });

import BSButton from 'react-bootstrap/Button';
import BSModal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { Form } from '../../components/form/form';
import { FormCheck } from '../../components/form/form-check';
import { FormControl } from '../../components/form/form-control';
import { FormSelect } from '../../components/form/form-select';
import { naturezaTransacaoLabelMap, tipoTransacaoLabelMap, utilizacaoLabelMap } from '../../helpers';

type AtualizarTiposTransacaoFormFields = {
  codigo: string;
  descricao: string;
  descricaoFatura: string;
  prazoEmDiasParaConfirmacao: number;
  operacao: string;
  funcao: string;
  utilizacao: string;
  prioridadePagamentoContabil: number;
  apareceNaFatura: boolean;
  parcelaNaoFinanciavel: boolean;
};

type AtualizarTiposTransacaoProps = {
  tipoTransacao: any;
  closeDialog: () => void;
  onUpdateHandler: (transacaoId: string, data: any) => void;
};

const AtualizarTiposTransacaoDialog: React.FC<AtualizarTiposTransacaoProps> = ({
  tipoTransacao,
  closeDialog,
  onUpdateHandler,
}) => {
  const form = useForm<AtualizarTiposTransacaoFormFields>({ defaultValues: tipoTransacao });
  const { control } = form;

  const onSubmitHandler = (data: AtualizarTiposTransacaoFormFields) => {
    if (!Object.values(data).length) {
      return;
    }

    onUpdateHandler(tipoTransacao._id, data);
  };

  return (
    <>
      <BSModal.Header closeButton>
        <BSModal.Title>Atualizar tipo de transação - {tipoTransacao.descricao}</BSModal.Title>
      </BSModal.Header>

      <BSModal.Body>
        <Form id="form" form={form} onSubmit={onSubmitHandler}>
          <div className="row">
            <FormControl
              control={control}
              className="col-md-6 mb-3"
              label="Código"
              name="codigo"
              type="text"
              rules={{ required: true }}
            />

            <FormControl
              control={control}
              className="col-md-6 mb-3"
              label="Descrição"
              name="descricao"
              type="text"
              rules={{ required: true }}
            />

            <FormControl
              control={control}
              className="col-md-6 mb-3"
              label="Descrição que aparece na fatura"
              name="descricaoFatura"
              type="text"
            />

            <FormControl
              control={control}
              className="col-md-6 mb-3"
              label="Dias para confirmação"
              name="prazoEmDiasParaConfirmacao"
              type="number"
              rules={{ required: false, min: 0 }}
            />

            <FormSelect
              control={control}
              className="col-md-6 mb-3"
              label="Operação"
              name="operacao"
              placeholder="Selecione a operação"
              options={Object.entries(tipoTransacaoLabelMap)
                .map(([key, value]) => ({
                  key: key,
                  label: value,
                  value: key,
                }))
                .sort((a, b) => a.label.localeCompare(b.label))}
            />

            <FormSelect
              control={control}
              className="col-md-6 mb-3"
              label="Função"
              name="funcao"
              placeholder="Selecione a função"
              options={Object.entries(naturezaTransacaoLabelMap).map(([key, value]) => ({
                key: key,
                label: value,
                value: key,
              }))}
              rules={{ required: true }}
            />

            <FormSelect
              control={control}
              className="col-md-6 mb-3"
              label="Utilização"
              name="utilizacao"
              placeholder="Selecione a utilização"
              options={Object.entries(utilizacaoLabelMap).map(([key, value]) => ({
                key: key,
                label: value,
                value: key,
              }))}
              rules={{ required: true }}
            />

            <FormControl
              control={control}
              className="col-md-6 mb-3"
              label="Prioridade pagamento contábil"
              name="prioridadePagamentoContabil"
              type="number"
              rules={{ min: 0 }}
            />

            <FormCheck
              control={control}
              className="col-md-6 mb-3"
              name="apareceNaFatura"
              label="Aparece na fatura"
              type="switch"
            />

            <FormCheck
              control={control}
              className="col-md-6 mb-3"
              name="parcelaNaoFinanciavel"
              label="Parcela não financiável"
              type="switch"
            />
          </div>
        </Form>
      </BSModal.Body>

      <BSModal.Footer>
        <BSButton variant="light" onClick={closeDialog}>
          Fechar
        </BSButton>
        <BSButton variant="primary" type="submit" form="form">
          Atualizar
        </BSButton>
      </BSModal.Footer>
    </>
  );
};

export default AtualizarTiposTransacaoDialog;

import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import { useCallback, useMemo } from 'react';
import BSCard from 'react-bootstrap/Card';
import { useNavigate } from 'react-router-dom';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import SmartTableFilters from '../../components/smart-table/smart-table-filters/smart-table-filters';
import {
  SmartTableFilterControlTypesEnum,
  SmartTableFilterProps,
} from '../../components/smart-table/smart-table-filters/table-filter.types';
import Title from '../../components/title';
import {
  ApiMultiElementResponse,
  mapearERemoverElementosNulosERepetidos,
  statusSolicitacaoClienteLabelMap,
} from '../../helpers';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import {
  loadTiposSolicitacoesClientes,
  selectTodosTiposSolicitacaoCliente,
} from '../tipos-solicitacao-cliente/tipo-solicitacao-cliente.redux';
import { loadUsuarios, selectObjectTodosUsuarios } from '../usuarios/usuarios.redux';
import {
  loadSolicitacoesClientes,
  selectSolicitacaoClienteLoadingStateByFilters,
  selectSolicitacoesClientesByFilters,
  selectTotalSolicitacoesClientesByFilters,
} from './solicitacao-cliente.redux';
import SolicitacoesClienteSmartTable from './solicitacoes-cliente-smart-table';

const smartFilters = ({ tipoSolicitacaoMap }: { tipoSolicitacaoMap: any }): SmartTableFilterProps[] => [
  {
    attribute: 'numeroDoProtocolo',
    label: 'Protocolo',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
  },
  {
    label: 'Tipo solicitação',
    attribute: 'tipoSolicitacao',
    controlType: SmartTableFilterControlTypesEnum.SELECT,
    map: tipoSolicitacaoMap,
  },
  {
    label: 'Status',
    attribute: 'tipoStatus',
    controlType: SmartTableFilterControlTypesEnum.SELECT,
    map: statusSolicitacaoClienteLabelMap,
  },
  {
    attribute: 'dataAbertura',
    label: 'Data de abertura',
    controlType: SmartTableFilterControlTypesEnum.DATE_INPUT,
  },
  {
    attribute: 'dataFinalResolucao',
    label: 'Data final para resolução',
    controlType: SmartTableFilterControlTypesEnum.DATE_INPUT,
  },
  {
    attribute: 'contaCartao',
    label: 'Número da conta',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
  },
  {
    attribute: 'cpfCnpjCliente',
    label: 'CPF/CNPJ',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
    minLength: 10,
  },
  {
    attribute: 'nomeCliente',
    label: 'Nome do cliente',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
    minLength: 3,
  },
];

const SolicitacoesClientesPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const { query, maxItemsQuery } = useQuerystring();
  const navigate = useNavigate();

  if (!query.sort) {
    query.sort = '-dataAbertura';
  }

  const filter = useMemo(() => ({ ...query }), [query]);
  const solicitacoesCliente = useSelector((state) => selectSolicitacoesClientesByFilters(state, filter));
  const total = useSelector((state) => selectTotalSolicitacoesClientesByFilters(state, filter));
  const loadingState = useSelector((state) => selectSolicitacaoClienteLoadingStateByFilters(state, filter));
  const usuarios = useSelector((state) => selectObjectTodosUsuarios(state));
  const tiposSolicitacao = useSelector((state) => selectTodosTiposSolicitacaoCliente(state));

  const tipoSolicitacaoMap = tiposSolicitacao?.reduce(
    (obj: any, tipoSolicitacao: any) => ({
      ...obj,
      [tipoSolicitacao._id]: tipoSolicitacao.descricao,
    }),
    {}
  );

  const loadEntidadesComplementares = useCallback(
    (data: any[]) => {
      const usuarios = mapearERemoverElementosNulosERepetidos(data, 'usuarioSolicitacao');

      dispatch(loadUsuarios({ query: { _id: { in: usuarios }, ...maxItemsQuery } })).catch((error: Error) => error);
    },
    [dispatch, maxItemsQuery]
  );

  const loadItems = useCallback(() => {
    dispatch(loadSolicitacoesClientes({ query }))
      .then(({ payload: { data } }: ApiMultiElementResponse) => loadEntidadesComplementares(data))
      .catch((error: Error) => error);
    dispatch(loadTiposSolicitacoesClientes({ query: maxItemsQuery })).catch((error: Error) => error);
  }, [dispatch, loadEntidadesComplementares, maxItemsQuery, query]);

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_SOLICITACAO_CLIENTE}>
      <div className="d-flex flex-column h-100">
        <Title>Solicitações de clientes</Title>

        <div className="row">
          <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-12 mb-4">
            <SmartTableFilters filters={smartFilters({ tipoSolicitacaoMap })} />
          </div>

          <div className="col-xxl-10 col-xl-9 col-lg-8 col-md-12 mb-4">
            <BSCard>
              <BSCard.Body>
                <SolicitacoesClienteSmartTable
                  items={solicitacoesCliente}
                  loadingState={loadingState}
                  loadItems={loadItems}
                  navigateTo={(doc) => navigate(`/solicitacoes-clientes/${doc._id}`)}
                  size={total}
                  usuarios={usuarios}
                />
              </BSCard.Body>
            </BSCard>
          </div>
        </div>
      </div>
    </RbacPage>
  );
};

export default SolicitacoesClientesPage;

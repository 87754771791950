import BSToast from 'react-bootstrap/Toast';
import { ClassTypesEnum } from '../helpers/enums';

type ToastContentProps = {
  type?: ClassTypesEnum;
  delay?: number;
  className?: string;
  message: string;
  title?: string;
  onClose: () => void;
};

export const Toast: React.FC<ToastContentProps> = ({
  type = ClassTypesEnum.INFO,
  delay = 5000,
  className,
  message,
  title,
  onClose,
}) => {
  const getToastTitle = (type: ClassTypesEnum) => {
    switch (type) {
      case ClassTypesEnum.SUCCESS:
        return 'Sucesso';
      case ClassTypesEnum.DANGER:
        return 'Erro';
      case ClassTypesEnum.WARNING:
        return 'Alerta';
      default:
        return 'Notificação';
    }
  };

  return (
    <BSToast autohide={true} onClose={onClose} delay={delay} className={className} bg={type}>
      <BSToast.Header>
        <strong className="me-auto">{title ?? getToastTitle(type)}</strong>
      </BSToast.Header>
      <BSToast.Body>{message}</BSToast.Body>
    </BSToast>
  );
};

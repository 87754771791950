import { Route, Routes } from 'react-router-dom';
import SolicitacoesAberturaContaPage from './solicitacoes-abertura-conta-page';
import DetalhesSolicitacaoAberturaContaPage from './detalhes-solicitacao-abertura-conta';

const SolicitacoesAberturaContaRoutes: React.FC = () => {
  return (
    <Routes>
      <Route index element={<SolicitacoesAberturaContaPage />} />
      <Route path=":solicitacaoAberturaContaId" element={<DetalhesSolicitacaoAberturaContaPage />} />
    </Routes>
  );
};

export default SolicitacoesAberturaContaRoutes;
